var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "广告详情",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "position_name",
                      label: "广告位",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "type",
                      label: "已完成/总量",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.advert_exposure_num) +
                                "/" +
                                _vm._s(scope.row.advert_max_exposure_num) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }