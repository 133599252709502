<template>
  <el-dialog
    :visible.sync="showadd"
    width="40%">
    <div>
      <el-form ref="form" :model="form" label-width="120px" label-suffix="：">
        <el-form-item label="系统">
          <el-select v-model="form.type">
            <el-option :value="11" label="android"/>
            <el-option :value="12" label="ios"/>
          </el-select>
        </el-form-item>
        <el-form-item label="app名称">
          <el-input v-model="form.appname"/>
        </el-form-item>
        <el-form-item label="版本">
          <el-input v-model="form.version"/>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input v-model="form.buildnum"/>
        </el-form-item>
        <el-form-item label="强制升级">
          <el-switch
            v-model="form.upgrade"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="显示">
          <el-switch
            v-model="form.isshow"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="升级描述">
          <el-input v-model="form.info" type="textarea"
                    :rows="4"/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.memo" type="textarea"
                    :rows="2"/>
        </el-form-item>
        <el-form-item label="下载路径">
          <el-input v-model="form.url"/>
        </el-form-item>
        <el-form-item label="包体大小">
          <el-input v-model="form.size"/>
        </el-form-item>
        <el-form-item label="升级时间">
          <el-date-picker
            v-model="form.releasedate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="定时发布">
          <el-date-picker
            v-model="form.push_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      showadd:false,
      form:{
        id:'',
        appname:'',
        type:'',
        version:'',
        buildnum:'',
        upgrade:1,
        isshow:1,
        releasedate:'',
        url:'',
        size:'',
        info:'',
        memo:'',
        push_time:'',
      }
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
            id:'',
            appname:'',
            type:'',
            version:'',
            buildnum:'',
            upgrade:1,
            isshow:1,
            releasedate:'',
            url:'',
            size:'',
            info:'',
            memo:'',
            push_time:'',
        }
      }
    }
  },
  methods:{
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
    },
    handlersave(){
      postAction("/api/system/version/edit",this.form).then(res=>{
        this.showadd=false
        this.$message({
          type:'success',
          message:res.msg
        })
        this.$emit("getlist")
      })
    }
  }
};
</script>

<style scoped>

</style>
