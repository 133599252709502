<template>
  <div class="index-container">
    <h2 style="text-align: center">调查问卷（2024春糖）统计</h2>
    <el-form ref="form" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listLoading"
      border
      :data="tabledata"
      :span-method="objectTplSpanMethod"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template v-if="item.label == '操作'" #default="{ row, $index }">
          <el-button type="text" @click="handlerInit(row)">答题详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <info ref="info" />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import info from '@/views/pages/datastatis/survey/chuntang/chuntang2024/components/info'
  import * as excel from '@/utils/excel'
  export default {
    name: '',
    components: { info },
    data() {
      return {
        listLoading: true,
        form: {
          list: [],
        },
        tabledata: [],
        columns: [
          {
            label: '问题',
            prop: 'question',
            width: '220',
          },
          {
            label: '答案',
            prop: 'answer',
            width: '',
          },
          {
            label: '人数',
            prop: 'num',
            width: '',
          },
          {
            label: '本题占比',
            prop: 'percent',
            width: '',
          },
          {
            label: '操作',
            prop: '',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlerExport() {
        let param = { question_id: 26 }
        postAction('/api/form/research-spring/form-info-export', param).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      handlerInit(row) {
        console.log('详情-', row)
        this.$refs.info.form.answer_id = row.answer_id
        this.$refs.info.handlerlist()
        this.$refs.info.show = true
      },
      handlerlist() {
        let param = {
          question_id: 26,
        }
        getAction('/api/form/research-spring/answer-tj', param).then((res) => {
          this.tabledata = res.data
          // this.sumtotal = res.data.sum
          this.total = Number(res.totalCount)
          this.listLoading = false
        })
      },
      /**
       * 合并列
       */
      objectTplSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.tabledata).one[rowIndex]
          console.log('合并之后，', _row)
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
       * 合并单元格
       */
      flitterData(arr) {
        let spanOneArr = [],
          concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.question === arr[index - 1].question) {
              //第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },
    },
  }
</script>
