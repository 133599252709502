<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="900px"
    top="8vh"
    :close-on-click-modal="false"
    v-loading="loading"
  >
    <div class="orderTest-container">
      <el-form
        :model="form"
        ref="form"
        label-position="right"
        label-width="130px"
        :rules="rules"
        label-suffix=":"
      >
        <el-form-item label="任务名称" prop="name">
          <el-input class="input" clearable v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="任务描述" prop="name_desc">
          <el-input class="input" clearable v-model="form.name_desc"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="img">
          <uploadImage ref="uploadImage" :showInfo="false" :limit="1" :maxSize="3"></uploadImage>
        </el-form-item>
        <el-form-item label="任务奖励云币数" prop="num">
          <el-input-number
            v-model="form.num"
            :min="0"
            step-strictly
          ></el-input-number>
        </el-form-item>
        <el-form-item label="每日次数上限" prop="count">
          <el-input-number
            v-model="form.count"
            :min="0"
            step-strictly
          ></el-input-number>
        </el-form-item>
        <el-form-item label="任务说明弹窗" prop="desc">
          <wang-editor ref="editor" height="300"></wang-editor>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <!-- 0否，1是 -->
          <el-select
            style="width: 300px"
            v-model="form.status"
            placeholder=""
            @change="freeTypeChange"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input-number
            :min="0"
            v-model="form.sort_order"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="安卓" prop="is_android">
          <el-switch
            v-model="form.is_android"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="苹果" prop="is_ios">
          <el-switch
            v-model="form.is_ios"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="小程序" prop="is_mini">
          <el-switch
            v-model="form.is_mini"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-button center">
      <el-button @click="handleSubmit" type="primary">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import WangEditor from "@/components/WangEditor.vue"
import uploadImage from "@/components/uploadImg.vue"
import { getAction, postAction } from "@/api/request";
export default {
  name: "AddEdit",
  components: { WangEditor, uploadImage },
  data() {
    return {
      type: 1,
      id: "",
      loading: false,
      showDialog: false,
      // 分类，1行业报告，2线上课程，3爆款好物
      form: {
        name:'',
      },
      typeSelect: [
        { id: '1', name: "行业报告" },
        { id: '2', name: "线上课程" },
        { id: '3', name: "爆款好物" },
      ],
      rules: {
        // goods_name: [{ required: true, message: "请输入商品名称" }],
        // type: [{ required: true, message: "请选择分类" }],
        // keyword: [{ required: true, message: "请输入公众号的关键字" }],
        // goods_info: [{ required: true, message: "请输入简介" }],
        // num: [{ required: true, message: "请输入商品数量" }],
        // cdkeyhead: [{ required: true, message: "请选择关联库码" }],
        // desc: [{ validator: this.verifyXiangxijieshao, required: true, message: "请输入" }],
        // goods_note: [
        //   { validator: this.verifyDuihuanshuoming, required: true, message: "请输入兑换说明" },
        // ],
        // img: [{ validator: this.verifyImg, required: true, message: "请选择商品图片" }],
        // integral: [{ required: true, message: "请输入兑换所需云币" }],
        // sort_order: [{ required: true, message: "请输入排序" }],
        // status: [{ required: true, message: "请选择状态" }],
      },
    }
  },
  created() {

  },
  mounted() {
    postAction("/user/index/cate",{pid:0}).then(res=>{
      this.classSelect=res.data
    })
    this.initSelect()
  },
  computed: {
    // 未兑换总计
    allT() {
      let num = 0
      this.form.cdkey_list.forEach((i) => {
        if (i.cdkeyhead) {
          num += Number(i.yun_left)
        }
      })
      return num
    },
    title() {
      if (this.type == 1) {
        return "添加"
      } else {
        return "编辑"
      }
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        if (this.type == 2) {
          this.fetchData()
        }
      } else {
        this.clearForm()
      }
    },
    // 'form.type'(val){
    //
    // }
  },
  methods: {
    async fetchData() {
      this.loading = true
      let res = await getAction("/api/system/integral/job-info", { id: this.id })
      this.form = res.data
      // 设置编辑器内容
      console.log("设置编辑器内容")
      this.$refs.editor.setHtml(this.form.desc) // desc
      // 设置默认图片
      this.$refs.uploadImage.img = [this.form.icon]
      this.loading = false
    },
    async initSelect() {
      let res = await getAction("/api/system/integral/cdkey-list")
      this.linkCodeSelect = res.data
    },

    clearForm() {
      this.form.status = 1
      this.$refs.form.resetFields()
      // this.$refs.form.clearValidate()
      this.$refs.editor.clearHtml()
      this.$refs.uploadImage.img = []
    },
    getHtmlM() {
      let a = this.$refs.editor.getHtmlM()
      console.log("a", a)
    },
    handleSubmit() {
      let data = { ...this.form }
      if (this.type == 1) {
        // 新增
        data.id = 0
      } else {
        // 编辑
        data.id = this.id
      }
      data.desc = this.$refs.editor.getHtmlM()
      data.icon = this.$refs.uploadImage.img[0]
      this.$refs.form.validate((v) => {
        if (v) {
          postAction("/api/system/integral/job-edit", data).then((re) => {
            this.$message.success(this.type == 1 ? "添加成功" : "修改成功")
            this.$emit("refresh")
            this.showDialog = false
          })
        }
      })
    },
    verifyXiangxijieshao(rule, val, cb) {
      if (this.$refs.editor.isKong()) {
        cb(new Error("请输入详细介绍"))
      } else {
        cb()
      }
    },
    verifyImg(rule, val, cb) {
      if (this.$refs.uploadImage.img[0]) {
        cb()
      } else {
        cb(new Error("请选择商品图片"))
      }
    },
    freeTypeChange(v) {
      if (v == 1) {
        this.form.integral = 0
      }
    },
    typeChange(v) {
      if (v == 2 && this.form.is_free === 1) {
        this.form.integral = 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input {
  width: 300px;
}
</style>
