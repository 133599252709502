<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="120px" >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="名称" style="width: 160px"/>
        </el-form-item>
        <el-form-item label="分享海报图" prop="cover_img">
          <upload-img ref="share_poster" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'share_poster')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="小程序分享图" prop="share_mini_pic">
          <upload-img ref="share_mini_pic" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'share_mini_pic')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="金刚区排列方式" prop="name">
          <el-select v-model="form.rows" placeholder="请选择">
            <el-option
              label="一行显示"
              :value="1"/>
              <el-option
                label="两行显示"
                :value="2"/>
          </el-select>

        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import uploadImg from "@/components/uploadImg";
import { nextTick } from "vue";

export default {
  name: "AddEdit",
  components:{
    uploadImg,
  },
  data(){
    return{
      showadd:false,
      title:'',
      shopSelect:[],
      form:{
        id:null,
        name:'',
        share_poster:'',
        share_mini_pic:'',
        cat_ids:'',
        rows:2
      },
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
          id:null,
          name:'',
          share_poster:'',
          share_mini_pic:'',
          cat_ids:'',
          rows:2
        }
        this.closeimg('share_poster')
        this.closeimg('share_mini_pic')
        this.$refs.form.resetFields()
      }
    }
  },
  methods:{
    handlerinfo(row){
      this.form= Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(row.share_poster,'share_poster')
        this.setImg(row.share_mini_pic,'share_mini_pic')
      })

    },
    handlersave(){
          postAction("/api/system/category-act/edit",this.form).then(res=>{
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$refs.form.resetFields()
            this.$emit("getlist")
            this.showadd=false
          })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
