<template>
  <div>
    <el-dialog :title="title" :visible.sync="addbanner" width="50%">
      <div>
        <el-form :model="form" label-width="120px" ref="form">
          <el-form-item label="版别" prop="type">
            <el-select
              v-model="form.type"
              clearable
              style="width: 160px"
              placeholder="版别"
              @change="handlerjuptype"
            >
              <el-option
                v-for="list in identitySelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="首页logo" prop="logo">
            <upload-img
              ref="logo"
              :infoText="logo_text"
              :limit="1"
              @getImgs="getGoods_imgImg($event, 'logo')"
              :maxSize="100"
            />
          </el-form-item>
          <el-form-item label="首页背景图" prop="pic_content">
            <upload-img
              ref="pic_content"
              :infoText="''"
              :limit="5"
              @getImgs="getGoods_imgImgs($event, 'pic_content')"
              :maxSize="100"
            />
          </el-form-item>
          <el-form-item label="背景色" prop="bg_color">
            <el-input
              v-model="form.bg_color"
              placeholder="背景色"
              style="width: 160px"
            />
            <p style="color: red; font-size: 12px">{{ bg_color_text }}</p>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              style="width: 160px"
              placeholder="状态"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addbanner = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    props: {
      identitySelect: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      uploadImg,
    },
    data() {
      return {
        title: '新增',
        addbanner: false,
        form: {
          id: 0,
          type: '',
          logo: '',
          bg_pic: '',
          bg_color: '',
          status: '1',
        },
        logo_text: '',
        bg_color_text: '',
        bg_pic_text: '',
        /*  rules:{
        title:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        type:[
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
        pic:[
          { required: true, message: '请上传图片', trigger: 'blur' },
        ],
        jump_type:[
          { required: true, message: '请选择跳转方式', trigger: 'blur' },
        ],
        jump_object:[
          {required: true, validator: checkjump, trigger: 'blur' },
        ],
        sort:[
          { required: true, message: '请输入排序顺序', trigger: 'blur' },
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'blur' },
        ],

      },*/
        statusSelect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '2',
            name: '禁用',
          },
        ],
        storSelect: [],
        goodsSelect: [],
      }
    },
    watch: {
      addbanner(val) {
        if (!val) {
          this.resetForm()
          this.title = '新增'
        }
      },
    },
    mounted() {},
    methods: {
      resetForm() {
        this.$refs.form.resetFields()
        this.closeimg('logo')
        this.closeimg('bg_pic')
        this.form = {
          id: 0,
          type: '',
          logo: '',
          bg_pic: '',
          bg_color: '',
          status: '1',
        }
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
        this.form.type = Number(this.form.type)
        setTimeout(() => {
          this.setImg(row.logo, 'logo')
          this.setImgArray(row.pic_content, 'pic_content')
        }, 100)
      },
      handlersave() {
        console.log('------------', this.form)

        postAction('/api/system/skin/edit', this.form).then((res) => {
          if (res.request_code == 200) {
            this.addbanner = false
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
      handlerjuptype(e) {
        this.logo_text = this.identitySelect.filter((list) => list.id == e)[0]
          ? this.identitySelect.filter((list) => list.id == e)[0].logo_text
          : ''
        this.bg_pic_text = this.identitySelect.filter((list) => list.id == e)[0]
          ? this.identitySelect.filter((list) => list.id == e)[0].bg_pic_text
          : ''
        this.bg_color_text = this.identitySelect.filter(
          (list) => list.id == e
        )[0]
          ? this.identitySelect.filter((list) => list.id == e)[0].bg_color_text
          : ''
      },
    },
  }
</script>

<style scoped></style>
