<template>
  <div class="index-container">
    <el-form :inline="true" :model="formstaff" class="demo-form-inline">
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
      <el-form-item label="活动报名">
        <el-switch v-model="formstaff.value"
                   :active-value="1"
                   :inactive-value="0"
                   active-text="开"
                   inactive-text="关"
                   @change="handlerStatus"
        />
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else-if="item.label == '活动类型'">
            {{ row.type == 1 ? '话题' : '投票' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handlerdelete(row)"
          >
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="editposter" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit },
  data() {
    return {
      form: {
        page: 1,
        limit: 10,
      },
      formstaff:{
        value: 1
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '栏目名称',
          prop: 'type_name',
          width: '',
        },
        {
          label: '排序',
          prop: 'sort_order',
          width: '',
        },
        {
          label: '状态',
          prop: 'status',
          width: '',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerAdd() {
      this.$refs.editposter.showEditPoster = true
    },
    handlerStatus(){
      postAction('/api/system/conf/set', this.formstaff).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    handlerlist() {
      getAction('/api/system/staff-console-type/lists', this.form).then((res) => {
        this.tabledata = res.data.list
        this.formstaff = res.data.staff_console_activity_door
        console.log(res.data,this.tabledata)
        this.total = Number(res.totalCount)
      })
    },
    handlerdelete(row) {
      postAction('/api/system/staff-console-type/delete', { id: row.id }).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    // 编辑分类
    handleredit(row) {
      this.$refs.editposter.showEditPoster = true
      this.$refs.editposter.handlerinfo(row)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
};
</script>

<style scoped>

</style>
