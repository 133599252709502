<template>
  <el-dialog
    content
    :visible.sync="showEditPoster"
    width="400px">
    <div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input placeholder="标题" v-model="form.title" clearable style="width: 160px"/>
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <upload-video ref="video" :limit="1" info-text="" @getVideos="getVideos($event, 'video')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input placeholder="排序" v-model="form.sort_order" clearable style="width: 160px"/>
        </el-form-item>
        <el-form-item label="标题" prop="status">
          <el-select
            v-model="form.status"
            clearable
            placeholder="状态"
            style="width: 160px"
          >
            <el-option
              v-for="list in shopSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";
import UploadVideo from "@/components/uploadVideo";
import { nextTick } from "vue";

export default {
  name: "addedit",
  components: { UploadVideo },
  data(){
    return{
      showEditPoster:false,
      form:{
        title:'',
        video:'',
        sort_order:'',
        status:'',
        id:'',
      },
      rules:{
        title:[{ required: true, message: '请输入标题', trigger: 'blur' }],
        video:[{ required: true, message: '请上传视频文件', trigger: 'blur' }],
        sort_order:[{ required: true, message: '请输入排序', trigger: 'blur' }],
        status:[{ required: true, message: '请输入状态', trigger: 'blur' }],
      },
      shopSelect: [
        {
          id: '1',
          name: '启用',
        },
        {
          id: '0',
          name: '禁用',
        },
      ],
    }
  },
  watch:{
    showEditPoster(val){
      if(!val){
        this.$refs.form.resetFields()
        this.form={
          title:'',
          video:'',
          sort_order:'',
          status:'',
          id:'',
        }
        this.closeVideo('video')
      }
    },
  },
  methods:{
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          getAction("/api/system/study-video/edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.$emit("getlist")
            this.showEditPoster=false
          })
        }
      })

    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      nextTick(()=>{
        this.setVideo(this.form.video,"video")
      })
    },
    getVideos(v, prop){
      console.log(v)
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setVideo(v, prop) {
      if (v) this.$refs[prop].Video = [v]
    },
    closeVideo(prop){
      this.$refs[prop].Video=[]
    },
  }
};
</script>

<style scoped>

</style>
