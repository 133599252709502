<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata"
              style="width: 100%"
              ref="ElTable">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '类型'">
            {{row[item.prop]==1?'春糖':'秋糖'}}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '开启' : '关闭' }}
          </div>
          <div v-else-if="item.label == '详情页分享图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '列表分享图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="500">
        <template #default="{ row }">
<!--          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdelete(row)"
          >
            删除
          </el-button>-->
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerbanner(row)"
          >
            专区管理
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerDirect(row)"
          >
            关联直播间
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerUpdate(row)"
          >
            酒店列表
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerrecommend(row)"
          >
            精选布展企业
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerproduct(row)"
          >
            广告位
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-hotel ref="addedit" @getlist="handlerInquire" />
    <zone ref="zone" />
    <live-room ref="live" />
    <hotel-list ref="hotel" />
    <ent-list ref="cat" @getlist="handlerInquire"/>
    <bannerlist ref="banner" />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddHotel from "./components/AddHotel";
import   Zone from './components/Zone'
import LiveRoom from './components/LiveRoom'
import HotelList from "./components/HotelList";
import entList from "./components/entList";
// import Bannerlist from '@views/pages/activity/category/components/bannerlist'
import Bannerlist from '../../activity/category/components/bannerlist'
export default {
  name: "index",
  components: { AddHotel,Zone,LiveRoom,HotelList,entList,Bannerlist},
  data(){
    return{
      form: {
        limit: 10,
        page: 1,
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: '展馆ID',
          prop: 'id',
          width: '110',
          align:'center'
        },
        {
          label: '名称',
          prop: 'title',
          width: '',
          align:'center'
        },
        {
          label: '列表分享图',
          prop: 'list_shareimg',
          width: '',
          align:'center'
        },
        {
          label: '详情页分享图',
          prop: 'info_shareimg',
          width: '',
          align:'center'
        },
        {
          label: '状态',
          prop: 'status',
          width: '',
          align:'center'
        },
        {
          label: '类型',
          prop: 'type',
          width: '',
          align:'center'
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerdelete(row){
      this.$confirm('此操作将删除该品类节, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/system/category-act/delete",{id:row.id}).then(res=>{
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerbanner(row) {
      this.$refs.zone.zoneshow = true
      this.$refs.zone.form.conf_id = row.id
      this.$refs.zone.handlerInquire()
    },
    handlerDirect(row) {
      this.$refs.live.zoneshow = true
      this.$refs.live.form.conf_id = row.id
      this.$refs.live.handlerInquire()
    },
    handlerUpdate(row) {
      this.$refs.hotel.zoneshow = true
      this.$refs.hotel.form.conf_id = row.id
      this.$refs.hotel.handlerInquire()
    },
    handlerrecommend(row) {
      this.$refs.cat.zoneshow = true
      this.$refs.cat.form.conf_id = row.id
      this.$refs.cat.handlerInquire()
    },
    handlerproduct(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.advert_no
      this.$refs.banner.title='广告位'
      this.$refs.banner.handlerInquire()
    },


    handlerAdd(){
      this.$refs.addedit.showhotel=true
    },
    handleredit(row){
      this.$refs.addedit.showhotel=true
      this.$refs.addedit.handlerinfo(row)
    },
    handlerlist() {
      getAction('/api/hotel/config/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
