<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="提报人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          :default-time="['12:00:00']"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          style="width: 340px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.depart_id"
          clearable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="list in departselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    data() {
      return {
        form: {
          name: '',
          page: 1,
          limit: 10,
          start_time: '',
          end_time: '',
          depart_id: '',
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '提报人',
            prop: 'name',
            width: '',
          },
          {
            label: '需求数量',
            prop: 'need_num',
            width: '',
          },
          {
            label: '已通过需求',
            prop: 'pass_need_num',
            width: '',
          },
          {
            label: '未通过需求',
            prop: 'no_pass_need_num',
            width: '',
          },
          {
            label: '待定',
            prop: 'be_pending_need_num',
            width: '',
          },
          {
            label: '待完善',
            prop: 'be_improved_need_num',
            width: '',
          },
          {
            label: '失效需求',
            prop: 'invalid_need_num',
            width: '',
          },
          {
            label: '已过期',
            prop: 'expire_num',
            width: '',
          },
          {
            label: '待审核需求',
            prop: 'not_audit_need_num',
            width: '',
          },
          {
            label: '发送选品方案数',
            prop: 'send_plan_num',
            width: '',
          },
          {
            label: '已确认客户数',
            prop: 'confirm_num',
            width: '',
          },
          {
            label: '会议完成数',
            prop: 'meeting_complete_num',
            width: '',
          },
          {
            label: '会议待完成数',
            prop: 'meeting_incomplete_num',
            width: '',
          },

          // {
          //   label: '合计',
          //   prop: 'heji',
          //   width: '',
          // },
        ],
        total: 0,
        sumtotal: '',
        departselect: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getDepart()
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '提报人') {
            sums[index] = '共计'
          }
          if (column.label == '需求数量') {
            sums[index] = this.sumtotal.need_num
          }
          if (column.label == '已通过需求') {
            sums[index] = this.sumtotal.pass_need_num
          }
          if (column.label == '未通过需求') {
            sums[index] = this.sumtotal.no_pass_need_num
          }
          if (column.label == '待定') {
            sums[index] = this.sumtotal.be_pending_need_num
          }
          if (column.label == '待完善') {
            sums[index] = this.sumtotal.be_improved_need_num
          }
          if (column.label == '失效需求') {
            sums[index] = this.sumtotal.invalid_need_num
          }
          if (column.label == '已过期') {
            sums[index] = this.sumtotal.expire_num
          }
          if (column.label == '待审核需求') {
            sums[index] = this.sumtotal.not_audit_need_num
          }
          if (column.label == '发送选品方案数') {
            sums[index] = this.sumtotal.send_plan_num
          }
          if (column.label == '已确认客户数') {
            sums[index] = this.sumtotal.confirm_num
          }
          if (column.label == '会议完成数') {
            sums[index] = this.sumtotal.meeting_complete_num
          }
          if (column.label == '会议待完成数') {
            sums[index] = this.sumtotal.meeting_incomplete_num
          }
          console.log('合计数', this.sumtotal)
          // if (column.label === '合计') {
          //   sums[index] = this.sumtotal
          // }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      getDepart() {
        postAction('/audit/biz-clue/diy-department').then((res) => {
          if (res.request_code == 200) {
            this.departselect = res.data
          }
        })
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-reporter', {
          name: this.form.name,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          depart_id: this.form.depart_id,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        await postAction('/audit/biz-clue/reporter-lists', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          }
        )
        await postAction('/audit/biz-clue/reporter-all', {
          name: this.form.name,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          depart_id: this.form.depart_id,
        }).then((res) => {
          this.sumtotal = res.data
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
