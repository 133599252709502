var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editTitle,
            visible: _vm.is_show,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.is_show = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "edit" },
            [
              _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择题目类型" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.form.data.is_single,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.data, "is_single", $$v)
                            },
                            expression: "form.data.is_single",
                          },
                        },
                        _vm._l(_vm.typelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "题目", prop: "type" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { placeholder: "请输入问题" },
                        model: {
                          value: _vm.form.data.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.data, "title", $$v)
                          },
                          expression: "form.data.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.data.is_single == 1 || _vm.form.data.is_single == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "type" } },
                        _vm._l(_vm.form.data.answer, function (item, index) {
                          return _c(
                            "p",
                            { key: index, staticClass: "answer" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "二级题目", prop: "type" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: item.title,
                                      callback: function ($$v) {
                                        _vm.$set(item, "title", $$v)
                                      },
                                      expression: "item.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "答案", prop: "type" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请输入答案" },
                                    model: {
                                      value: item.reply,
                                      callback: function ($$v) {
                                        _vm.$set(item, "reply", $$v)
                                      },
                                      expression: "item.reply",
                                    },
                                  }),
                                ],
                                1
                              ),
                              index == 0
                                ? _c("e", {
                                    staticClass:
                                      "icon el-icon-circle-plus-outline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addanswer()
                                      },
                                    },
                                  })
                                : _vm._e(),
                              index != 0
                                ? _c("e", {
                                    staticClass: "icon el-icon-remove-outline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.plusanswer(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.form.data.is_single == 0 && _vm.form.data.is_single != ""
                    ? _c("p", { staticClass: "tips" }, [
                        _vm._v(" 导入品类库品类 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: { "margin-top": "60px", "text-align": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }