<template>
  <el-dialog
    :title="title"
    :visible.sync="showtow"
    width="600"
    append-to-body>
    <div>
      <el-form ref="form" :model="form" label-width="160px" label-suffix="：">
        <el-form-item label="标题" >
          <el-input placeholder="标题" v-model="form.menu_title"/>
        </el-form-item>
        <el-form-item label="副标题" >
          <el-input placeholder="副标题" v-model="form.menu_subtitle"/>
        </el-form-item>

        <el-form-item label="二级页banner" >
          <upload-img ref="menu_banner_pic" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'menu_banner_pic')"/>
        </el-form-item>

        <el-form-item label="首页排版样式" >
          <el-select v-model="form.style" placeholder="样式" clearable  style="width: 160px;">
            <el-option label="横版" value="1"/>
            <el-option label="竖版" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input placeholder="排序" v-model="form.sort_order"/>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "addTow",
  components: { UploadImg },
  data(){
    return{
      title:'添加二级分类',
      showtow:false,
      form:{
        policy_id:"",
        id:0,
        menu_title:'',
        menu_subtitle:'',
        menu_banner_pic:'',
        style:'',
        sort_order:'',
      }
    }
  },
  watch:{
    showtow(val){
      if(!val){
        this.close()
      }
    }
  },
  methods:{
    handlerinfo(row){
      nextTick(()=>{
        this.setImg(row.menu_banner_pic,'menu_banner_pic')
      })
      this.form=Object.assign(this.form,row)
    },
    handlersave(){
      postAction("/api/system/policy/menu-edit",this.form).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.close()
        this.$emit("getlist")
      })
    },
    show(){
      this.showtow=true
    },
    close(){
      this.showtow=false
      this.form={
        actid:"",
        id:0,
        button_text_color:'',
        type:'',
        title:'',
        share_title:'',
        sort_order:'',
        share_desc:'',
        button_color:'',
        button_text:'',
      }
      this.closeimg('menu_banner_pic')
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
