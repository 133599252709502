var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "8vh",
        visible: _vm.showDialog,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-suffix": ":",
                "label-width": "130px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "goods_name" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input",
                      attrs: { clearable: "", placeholder: "请选择分类" },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { label: i.name, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "报告所属行业", prop: "class_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "input",
                          attrs: {
                            clearable: "",
                            placeholder: "请选择所属行业",
                          },
                          model: {
                            value: _vm.form.class_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "class_id", $$v)
                            },
                            expression: "form.class_id",
                          },
                        },
                        _vm._l(_vm.classSelect, function (i) {
                          return _c("el-option", {
                            key: i.id,
                            attrs: { label: i.name, value: i.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "公众号的关键字", prop: "keyword" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "keyword", $$v)
                          },
                          expression: "form.keyword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "is_free" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "" },
                          on: { change: _vm.freeTypeChange },
                          model: {
                            value: _vm.form.is_free,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_free", $$v)
                            },
                            expression: "form.is_free",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "免费", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "付费", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              [
                _vm.form.type == 2 && _vm.form.is_free == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "关联码库", prop: "cdkeyhead" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "input",
                            attrs: {
                              clearable: "",
                              placeholder: "请选择关联码库",
                            },
                            on: { change: _vm.linkCodeChange },
                            model: {
                              value: _vm.form.cdkeyhead,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cdkeyhead", $$v)
                              },
                              expression: "form.cdkeyhead",
                            },
                          },
                          _vm._l(_vm.linkCodeSelect, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: {
                                disabled: i.disabled,
                                label: i.name,
                                value: i.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              _c(
                "el-form-item",
                { attrs: { label: "简介", prop: "goods_info" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.goods_info,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_info", $$v)
                      },
                      expression: "form.goods_info",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细介绍", prop: "goods_desc" } },
                [
                  _c("wang-editor", {
                    ref: "editor",
                    attrs: { height: "300" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑换说明", prop: "goods_note" } },
                [
                  _c("wang-editor", {
                    ref: "editor2",
                    attrs: { height: "300" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片", prop: "img" } },
                [
                  _c("uploadImage", {
                    ref: "uploadImage",
                    attrs: { limit: 1, "max-size": 3, "show-info": false },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品数量", prop: "num" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.form.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "num", $$v)
                      },
                      expression: "form.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑换所需云币", prop: "integral" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      disabled: _vm.form.type == 2 && _vm.form.is_free == 1,
                      min: 0,
                      step: 1,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.form.integral,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "integral", $$v)
                      },
                      expression: "form.integral",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最多兑换次数", prop: "" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.form.add_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "add_count", $$v)
                      },
                      expression: "form.add_count",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort_order" } },
                [
                  _c("el-input-number", {
                    attrs: { step: 1 },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "active-value": 1,
                      "inactive-color": "#ccc",
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-button center" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }