<template>
  <div>
    <el-dialog  :title="title"
                :visible.sync="showadd"
                width="650px"
                top="15vh"
    >
      <div>
        <el-form ref="form" :model="form" label-width="220px" :rules="rules">
          <el-form-item label="标题：" prop="title">
            <el-input v-model="form.title" maxlength="30" style="width: 220px"/>
          </el-form-item>
          <el-form-item label="关联直播间：" prop="live_name">
            <el-autocomplete
              v-model="form.live_name"
              :fetch-suggestions="querySearchAsync"
              clearable
              placeholder="请输入内容"
              suffix-icon="el-icon-arrow-down"
              value-key="title"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>

              <el-form-item label="banner：" prop="banner">
                <upload-img  ref="uploadImgshare" :infoText="'尺寸750*220'" :limit="1" @uploadImg="uploadImgshare" :maxSize="100"/>
              </el-form-item>
          <el-form-item label="banner显示：" prop="banner_show">
                        <el-switch v-model="form.banner_show"
                        :active-value="1"
                        :inactive-value="0"></el-switch>

          </el-form-item>
          <el-form-item label="背景颜色:" prop="bg_color">
            <el-color-picker v-model="form.bg_color"></el-color-picker>
          </el-form-item>
          <el-form-item label="开始时间:" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" prop="end_time">
            <el-date-picker
              v-model="form.end_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="分享图：" prop="share_img">
            <upload-img ref="uploadImage" infoText="尺寸750*600" :limit="1" @uploadImg="uploadImg" :maxSize="100"/>
          </el-form-item>
          <el-form-item label="菜单名称1：" prop="menu1">
            <el-input v-model="form.menu1" style="width: 220px" />
          </el-form-item>
          <el-form-item label="菜单名称2：" prop="menu2">
            <el-input v-model="form.menu2" style="width: 220px" />
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-select v-model="form.status">
              <el-option :value="0" label="禁用"/>
              <el-option :value="1" label="启用"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="showadd=!showadd">取消</el-button>
        <el-button type="primary" @click="handlersave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UploadImg from '@/components/uploadImg.vue'
import { getAction, postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  components:{
    UploadImg
  },
  props:{
    title:{
      type:String,
      default:()=>{
        return "新增"
      }
    },
  },
  data(){
    return{
      showadd:false,
      searchtext:'',
      form:{
        id:'',
        title:'',
        live_id:'',
        banner:'',
        live_name:'',
        start_time:'',
        bg_color:'',
        end_time:'',
        banner_show:1,
        menu1:'',
        menu2:'',
        share_img:'',
        status:1
      },
      rules:{
        title:[
          { required: true, message: '请填写名称', trigger: 'change' }
        ],
        live_name:[
          { required: true, message: '请填选择关联直播间', trigger: 'change' }
        ],
        banner:[
          { required: true, message: '请上传banner图', trigger: 'change' }
        ],
        banner_show:[
          { required: true, message: '请设置banner图状态', trigger: 'change' }
        ],
        bg_color:[
          { required: true, message: '请设置背景色', trigger: 'change' }
        ],
        start_time:[
          { required: true, message: '请设置开始时间', trigger: 'change' }
        ],
        end_time:[
          { required: true, message: '请设置结束时间', trigger: 'change' }
        ],
        share_img:[
          { required: true, message: '请上传分享图', trigger: 'change' }
        ],
        status:[
          { required: true, message: '请设置状态', trigger: 'change' }
        ]

      }

    }
  },
  watch:{
    showadd(val){
      if(!val){
        console.log(val,'走了吗')

        this.$refs.form.resetFields()
        this.form.id=''
        // this.form.poster=''
        this.$refs.uploadImage.img=[]
        this.$refs.uploadImgshare.img=[]
      }
    },
    'form.live_name'(old){
      if(!old){
        this.form.live_id=''
      }
    }
  },
  mounted() {
    // this.livehandlerlist()
  },
  methods:{
    handlerinfo(row){
        this.form=Object.assign(this.form,row,{menu1:row.menu_name1,menu2:row.menu_name2})
      nextTick(()=>{
        if(this.form.share_img){
          this.$refs.uploadImage.img=[this.form.share_img]
        }
        if(this.form.banner){
          this.$refs.uploadImgshare.img=[this.form.banner]
        }
      })



    },
    handleSelect(v){
      console.log(v,"")
      this.searchtext=v.name
      this.form.live_id=v.id
    },
    querySearchAsync(queryString,cb){
      this.createStateFilter(queryString).then(res=>{
        queryString?cb(res):cb([]);
        // cb(res)
      })
    },
    createStateFilter(queryString){
    return new Promise((resolve,reject)=>{
      getAction("/live/index/search-live",{searchtext:queryString}).then(res=>{
         console.log(res)
         resolve(res.data)
       }).catch(err=>{
         reject(err)
      })
     })
    },
    handlersave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          postAction("/live/index/edit-arealive", this.form).then(res => {
            this.$message.success(res.msg)
            this.showadd = false
            this.$emit("getlist")
          })
        }
      })
    },
    uploadImgshare(val){
      if(val.length<1){
        this.form.banner=''
      }else{
        this.form.banner=val[0]
      }
    },
    uploadImg(val){
      console.log(val)
      if(val.length<1){
        this.form.share_img=''
      }else{
        this.form.share_img=val[0]
      }

    },
  },
}

</script>
<style></style>
