<template>
  <el-dialog append-to-body :title="title" :visible.sync="showedit" width="30%">
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="所属店铺">
        <el-autocomplete
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          placeholder="请输入店铺名称"
          style="width: 160px"
          suffix-icon="el-icon-arrow-down"
          value-key="mall_name"
          @select="handleSelectmall"
        >
          <template slot-scope="{ item }">
            <div :class="item.is_pay == 1 ? 'payname' : ''">
              {{ item.mall_name }}
            </div>
            <!-- <span class="addr">{{ item.id }}</span> -->
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="选择产品">
        <el-autocomplete
          v-model="form.goods_name"
          :disabled="form.mall_id == ''"
          :fetch-suggestions="handlergoods"
          placeholder="请输入产品名称"
          style="width: 160px"
          suffix-icon="el-icon-arrow-down"
          value-key="goods_name"
          @select="handleSelectgoods"
        />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.sort" label="排序" :min="0" />
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in mealselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showedit = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'AddSelect',

    data() {
      return {
        showedit: false,
        title: '新增',
        mealselect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '2',
            name: '禁用',
          },
        ],
        form: {
          id: '',
          plan_id: '',
          goods_id: '',
          mall_id: '',
          goods_name: '',
          mall_name: '',
          sort: 0,
          status: '1',
        },
      }
    },
    watch: {
      showedit(val) {
        console.log(val, 'xianshi')
        if (!val) {
          this.form = {
            id: '',
            goods_id: '',
            mall_id: '',
            plan_id: '',
            goods_name: '',
            mall_name: '',
            sort: 0,
            status: '1',
          }
          this.title = '新增'
        }
      },
    },
    methods: {
      handlersave() {
        getAction('/audit/biz-clue/add-plan', this.form).then((res) => {
          if (res.request_code == 200) {
            this.showedit = false
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
      handlerinfo(row) {
        console.log('父传子-', row)
        this.form = Object.assign(this.form, row)
      },
      handlermell(queryString, cb) {
        console.log('表单数据《', this.form.id)
        getAction('/audit/biz-clue/sel-mall', {
          id: this.form.id,
          keyword: queryString,
        }).then((res) => {
          cb(res.data)
        })
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.mall_id = list.id
        this.mall_name = list.mall_name
      },
      handlergoods(queryString, cb) {
        getAction('/audit/biz-clue/sel-goods', {
          id: this.form.mall_id,
          keyword: queryString,
        }).then((res) => {
          cb(res.data)
        })
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.goods_id = list.id
        this.goods_name = list.goods_name
      },
    },
  }
</script>

<style scoped>
  .payname {
    color: #f00;
  }
</style>
