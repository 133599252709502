<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="主题活动" clearable v-model="form.name" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='首页banner'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
                    <div v-else-if="item.label=='海报'">
                      <el-image
                        style="width: 70px; height: 70px"
                        :src="row[item.prop]"
                        :preview-src-list="[row[item.prop]]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
          <div v-else-if="item.label=='分享图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="280">
        <template  #default="{row}">
          <el-button
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small">
            编辑
          </el-button>
<!--          <el-button
            @click.native.prevent="handlerdelete(row)"
            type="text"
            size="small">
            删除
          </el-button>-->
          <el-button
            @click.native.prevent="handlersecondary(row)"
            type="text"
            size="small">
            栏目设置
          </el-button>
          <el-button
            @click.native.prevent="handlerbanner(row)"
            type="text"
            size="small">
            预览海报
          </el-button>
          <el-button
            @click.native.prevent="handleReview(row)"
            type="text"
            size="small">
            小程序码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <secondary ref="secondary"/>
    <add-edit ref="add" @getlist="handlerInquire"/>
<!--    <bannerlist ref="banner"/>-->
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddEdit from "@/views/pages/systemSet/policy/components/AddEdit";
import Secondary from "./components/secondary";

export default {
  name: "index",
  components: { AddEdit ,Secondary},
  data(){
    return{
      form: {
        name:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "id",
          prop: "id",
          width: "",
        },

        {
          label: "活动名称",
          prop: "name",
          width: "160",
        },

        {
          label: "首页banner",
          prop: "banner_pic",
          width: "",
        },
        {
          label: "首页背景色",
          prop: "bg_color",
          width: "",
        },

        {
          label: "首页栏目背景色",
          prop: "menu_bg_color",
          width: "",
        },{
          label: "首页栏目标题文字颜色",
          prop: "menu_title_color",
          width: "",
        },
        {
          label: "首页栏目副标题文字颜色",
          prop: "menu_subtitle_color",
          width: "",
        },

        {
          label: "二级页背景色",
          prop: "sub_bg_color",
          width: "",
        }, {
          label: "海报",
          prop: "poster_pic",
          width: "",
        }
        , {
          label: "分享标题",
          prop: "share_title",
          width: "",
        }, {
          label: "分享图",
          prop: "share_pic",
          width: "",
        }, {
          label: "二维码横坐标",
          prop: "qrcode_x",
          width: "",
        },{
          label: "二维码纵坐标",
          prop: "qrcode_y",
          width: "",
        },
        {
          label: "二维码宽度",
          prop: "qrcode_width",
          width: "",
        },{
          label: "二维码高度",
          prop: "qrcode_height",
          width: "",
        },
      ],
      total: 0,
    }
  },
  methods:{
    handlerbanner(row){
      let code= row.poster_pic
      this.$alert(`<div style="width: 380px;height: 380px;display: flex;justify-content: center;align-items: center"><img src="${code}" style="max-width: 100%;max-height: 100%"></div>`, '海报', {
        dangerouslyUseHTMLString: true
      });
    },
    handleredit(row){
      console.log(row)
      this.$refs.add.show()
      this.$refs.add.title='编辑'
      this.$refs.add.handlerinfo(row)
    },
    handlerAdd(){
      this.$refs.add.show()
      this.$refs.add.title='新增'
    },
    handleReview(row){
      getAction("/api/system/policy/mini-code",{id:row.id}).then(res=>{
        console.log(res)
        let code= res.data.url
        this.$alert(`<img src="${code}" style="width: 380px;height: 380px">`, '小程序码', {
          dangerouslyUseHTMLString: true
        });
      })

    },
    handlersecondary(row){
      this.$refs.secondary.form.policy_id=row.id
      this.$refs.secondary.show()
      this.$refs.secondary.handlerInquire()
    },
    handlerdelete(row){
      postAction("/api/theme/act/delete",{id:row.id}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerInquire()
      })
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/system/policy/conf-lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
  mounted() {
    this.handlerInquire()
  }
};
</script>

<style scoped>
.round{
  width: 380px;
  height: 380px;
}
.img{

  max-width:100%;
}
</style>
