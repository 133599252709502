var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showadd, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题：", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      placeholder: "标题名称",
                      clearable: "",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报图：", prop: "poster_pic" } },
                [
                  _c("upload-img", {
                    ref: "poster_pic",
                    attrs: {
                      infoText: "(尺寸750*1025像素)",
                      limit: 1,
                      maxSize: 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "poster_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享图：", prop: "share_pic" } },
                [
                  _c("upload-img", {
                    ref: "share_pic",
                    attrs: {
                      infoText: "(尺寸325*105像素)",
                      limit: 1,
                      maxSize: 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "浮动图标：", prop: "float_pic" } },
                [
                  _c("upload-img", {
                    ref: "float_pic",
                    attrs: {
                      infoText: "(尺寸240*240像素)",
                      limit: 1,
                      maxSize: 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "float_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景颜色：", prop: "bg_color" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "背景颜色", clearable: "" },
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "按钮颜色：", prop: "button_color" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "按钮颜色", clearable: "" },
                    model: {
                      value: _vm.form.button_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "button_color", $$v)
                      },
                      expression: "form.button_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主题文字颜色：", prop: "title_color" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "主题文字颜色", clearable: "" },
                    model: {
                      value: _vm.form.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title_color", $$v)
                      },
                      expression: "form.title_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序：", prop: "sort_order" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-switch", {
                    attrs: { "inactive-value": "2", "active-value": "1" },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }