var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.addShow,
        width: "70%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.addShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "add" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "调查主题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入问卷名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面主图" } },
                [
                  _c("upload-img", {
                    ref: "main_img",
                    attrs: {
                      disab: _vm.disabled,
                      limit: 1,
                      "info-text": "（图片尺寸：1080*2200）",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "main_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主题图" } },
                [
                  _c("upload-img", {
                    ref: "title_img",
                    attrs: {
                      disab: _vm.disabled,
                      limit: 1,
                      "info-text": "（图片尺寸：1080*450）",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "title_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "抽奖图" } },
                [
                  _c("upload-img", {
                    ref: "award_img",
                    attrs: {
                      disab: _vm.disabled,
                      limit: 1,
                      "info-text": "（图片尺寸：1080*410 透明背景）",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "award_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "按钮背景图" } },
                [
                  _c("upload-img", {
                    ref: "button_img",
                    attrs: {
                      disab: _vm.disabled,
                      limit: 1,
                      "info-text": "（图片尺寸：232*80",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "button_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "抽奖链接" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.award_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "award_url", $$v)
                      },
                      expression: "form.award_url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享主题" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.share_app_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_app_title", $$v)
                      },
                      expression: "form.share_app_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享描述" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.share_app_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_app_content", $$v)
                      },
                      expression: "form.share_app_content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享图" } },
                [
                  _c("upload-img", {
                    ref: "share_app_img",
                    attrs: { disab: _vm.disabled, limit: 1, "info-text": "" },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "share_app_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享主题" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.share_mini_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_mini_title", $$v)
                      },
                      expression: "form.share_mini_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享图" } },
                [
                  _c("upload-img", {
                    ref: "share_mini_img",
                    attrs: { disab: _vm.disabled, limit: 1, "info-text": "" },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "share_mini_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报" } },
                [
                  _c("upload-img", {
                    ref: "poster_img",
                    attrs: {
                      disab: _vm.disabled,
                      limit: 1,
                      "info-text": "（图片尺寸：1080*1728）",
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getfloatimg($event, "poster_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "zuobiao",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "海报二维码横坐标" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "默认406" },
                        model: {
                          value: _vm.form.qcode_x,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "qcode_x", $$v)
                          },
                          expression: "form.qcode_x",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "纵坐标" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "默认1020" },
                        model: {
                          value: _vm.form.qcode_y,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "qcode_y", $$v)
                          },
                          expression: "form.qcode_y",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: "1" } }),
                      _c("el-option", { attrs: { label: "禁用", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否填写表单" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.is_form,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_form", $$v)
                        },
                        expression: "form.is_form",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }