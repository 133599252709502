<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <!-- <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="名称">
        <el-input
          v-model="form.name"
          clearable
          placeholder="海报名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row.bg_pic]"
              :src="row.bg_pic"
              style="width: 70px; height: 150px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-popconfirm
            v-show="row.status == 1"
            title="确认禁用？"
            @confirm="deleteRow(row, 0)"
          >
            <el-button slot="reference" size="small" type="text">
              禁用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.status != 1"
            title="确认启用？"
            @confirm="deleteRow(row, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              启用
            </el-button>
          </el-popconfirm>
          <el-popconfirm title="确认删除该分类？" @confirm="handlerdelete(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 设置海报弹窗 -->
    <edit ref="edit" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import edit from './components/edit'
  export default {
    name: 'Index',
    components: { edit },
    data() {
      return {
        form: {
          type: 1,
          status: '', //''-全部  1-启用 0-禁用
          title: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '名称',
            prop: 'name',
            width: '',
          },
          {
            label: '海报背景',
            prop: 'bg_pic',
            width: '',
          },
          {
            label: '排序',
            prop: 'sort_order',
            width: '80',
          },

          {
            label: '状态',
            prop: 'status',
            width: '80',
          },
        ],
        shopSelect: [
          {
            id: '',
            name: '全部',
          },
          {
            id: 1,
            name: '启用',
          },
          {
            id: 0,
            name: '禁用',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.edit.showEdit = true
      },
      // 设置海报，如果状态设置为 禁用，则不能设置海报
      handlerposter(row) {
        if (row.status == 2) {
          this.$message({
            type: 'error',
            message: '您设置了禁用，开启后可以设置海报',
          })
          return
        } else {
          this.$refs.setposter.showSetPoster = true
          this.$refs.setposter.handlerlist(row)
        }
      },
      handlerlist() {
        getAction('/api/system/product-poster/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/api/system/product-poster/delete', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            }
          }
        )
      },
      // 设置禁用or启用
      deleteRow(row, status) {
        let params = {
          id: row.id,
          name: row.name,
          status,
          sort_order: row.sort_order,
          bg_pic: row.bg_pic,
        }
        postAction('/api/system/product-poster/edit', params).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      // 编辑
      handleredit(row) {
        this.$refs.edit.showEdit = true
        row.status = String(row.status)
        this.$refs.edit.handlerinfo(row)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
