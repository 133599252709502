<template>
  <el-dialog
    v-loading="loading"
    append-to-body
    :title="'创建云会议' + '' + form.title"
    :visible.sync="showcloud"
    width="30%"
  >
    <div>
      <el-form
        ref="form"
        label-position="right"
        label-suffix="："
        label-width="140px"
        :model="form"
      >
        <el-form-item label="需求客户" prop="total">
          {{ form.external_user_name }}
        </el-form-item>
        <el-form-item label="会议管理员" prop="read">
          <el-select v-model="form.admin_id" @change="handlerSelect">
            <el-option
              v-for="list in form.admin_list"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="参会客户" prop="is_jump">
          {{ form.external_user_name }}
        </el-form-item>
        <el-form-item label="需求提报人" prop="click">
          {{ form.staff_user_name }}
        </el-form-item>
        <el-form-item label="会议标题" prop="click">
          {{ form.sub_title || '暂无' }}
        </el-form-item>
        <el-form-item label="会议开始时间" prop="click">
          {{ form.meeting_start || '暂无' }}
        </el-form-item>
        <el-form-item label="会议持续时间" prop="click">
          {{ form.meeting_duration || '暂无' }}
        </el-form-item>
        <el-form-item label="会议描述" prop="click">
          {{ form.desc || '暂无' }}
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'AddCloud',
    data() {
      return {
        showcloud: false,
        loading: false,
        form: {
          id: '',
        },
      }
    },
    watch: {
      showcloud(val) {
        if (val) {
          this.hanlderinfo()
        } else {
          this.form = {
            id: '',
            admin_id: '',
            admin_name: '',
          }
        }
      },
    },
    methods: {
      handlerSelect(e) {
        this.form.admin_list.forEach((item, index) => {
          if (item.id == e) {
            this.form.admin_name = item.name
          }
        })
      },
      hanlderinfo() {
        this.loading = true
        postAction('/audit/biz-clue/meeting-info', {
          id: this.form.id,
        })
          .then((res) => {
            this.form = res.data
            this.showcloud = true
            this.loading = false
          })
          .finally(() => {
            this.loading = false
          })
      },
      handlersave() {
        let { id, admin_id, admin_name } = this.form
        let params = {
          id,
          admin_id,
          admin_name,
        }
        postAction('/audit/biz-clue/edit-meeting', params).then((res) => {
          if (res.request_code == 200) {
            this.showcloud = false
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
    },
  }
</script>

<style scoped></style>
