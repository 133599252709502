var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showtow,
        width: "600",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showtow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "160px",
                "label-suffix": "：",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.menu_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_title", $$v)
                      },
                      expression: "form.menu_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "副标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "副标题" },
                    model: {
                      value: _vm.form.menu_subtitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_subtitle", $$v)
                      },
                      expression: "form.menu_subtitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二级页banner" } },
                [
                  _c("upload-img", {
                    ref: "menu_banner_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "menu_banner_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页排版样式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "样式", clearable: "" },
                      model: {
                        value: _vm.form.style,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "style", $$v)
                        },
                        expression: "form.style",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "横版", value: "1" } }),
                      _c("el-option", { attrs: { label: "竖版", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "排序" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }