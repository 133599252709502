var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.showinfo,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showinfo = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "130px",
                    "label-suffix": "：",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.form.company_name)),
                  ]),
                  _c("el-form-item", { attrs: { label: "店铺名称" } }, [
                    _vm._v(_vm._s(_vm.form.mall_name)),
                  ]),
                  _c("el-form-item", { attrs: { label: "店铺简称" } }, [
                    _vm._v(_vm._s(_vm.form.mall_name_short)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属行业" } },
                    _vm._l(_vm.form.trade, function (list) {
                      return _c("span", { key: list }, [
                        _vm._v(_vm._s(list) + "、"),
                      ])
                    }),
                    0
                  ),
                  _c("el-form-item", { attrs: { label: "招商省份" } }, [
                    _vm._v(_vm._s(_vm.form.province)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺logo" } },
                    [
                      _c(
                        "el-image",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.logo,
                              expression: "form.logo",
                            },
                          ],
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.form.logo,
                            "preview-src-list": [_vm.form.logo],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "placeholder" },
                              slot: "placeholder",
                            },
                            [
                              _vm._v(" 加载中"),
                              _c("span", { staticClass: "dot" }, [
                                _vm._v("..."),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺背景" } },
                    [
                      _c(
                        "el-image",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.bg_image,
                              expression: "form.bg_image",
                            },
                          ],
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.form.bg_image,
                            "preview-src-list": [_vm.form.bg_image],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "placeholder" },
                              slot: "placeholder",
                            },
                            [
                              _vm._v(" 加载中"),
                              _c("span", { staticClass: "dot" }, [
                                _vm._v("..."),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业介绍" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.introduction) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业展厅" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.honors) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "扶持政策" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: {
                          innerHTML: _vm._s(_vm.form.supportive_policy),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "企业视频" } }, [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.video_url,
                          expression: "form.video_url",
                        },
                      ],
                      staticStyle: { width: "400px", height: "200px" },
                      attrs: { src: _vm.form.video_url, controls: "" },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频封面" } },
                    [
                      _c(
                        "el-image",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.video_cover,
                              expression: "form.video_cover",
                            },
                          ],
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.form.video_cover,
                            "preview-src-list": [_vm.form.video_cover],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "placeholder" },
                              slot: "placeholder",
                            },
                            [
                              _vm._v(" 加载中"),
                              _c("span", { staticClass: "dot" }, [
                                _vm._v("..."),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产线展示" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.line_display) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺海报" } },
                    [
                      _c(
                        "el-image",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.share_img,
                              expression: "form.share_img",
                            },
                          ],
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.form.share_img,
                            "preview-src-list": [_vm.form.share_img],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "placeholder" },
                              slot: "placeholder",
                            },
                            [
                              _vm._v(" 加载中"),
                              _c("span", { staticClass: "dot" }, [
                                _vm._v("..."),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.status != 1,
                      expression: "form.status!=1",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(_vm.form, 3)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.status != 1,
                      expression: "form.status!=1",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(_vm.form, 1)
                    },
                  },
                },
                [_vm._v("通过")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }