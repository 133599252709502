import { render, staticRenderFns } from "./browse.vue?vue&type=template&id=09041f10&scoped=true"
import script from "./browse.vue?vue&type=script&lang=js"
export * from "./browse.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09041f10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\yunjiaohui\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09041f10')) {
      api.createRecord('09041f10', component.options)
    } else {
      api.reload('09041f10', component.options)
    }
    module.hot.accept("./browse.vue?vue&type=template&id=09041f10&scoped=true", function () {
      api.rerender('09041f10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/enterprise/productMan/components/browse.vue"
export default component.exports