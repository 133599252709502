<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="广告类型">
        <el-select
          v-model="form.advert_no"
          clearable
          placeholder="请选择"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.advert_no"
            :label="list.position_name"
            :value="list.advert_no"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="所属店铺：">
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="请输入"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图片'">
            <el-image
              :preview-src-list="[row.img]"
              :src="row.img"
              style="width: 70px; height: 30px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <!--                    <div v-else-if="item.label=='位置'">-->
          <!--                      {{typeSelect.filter(list=>list.advert_no==row[item.prop])[0].position_name}}-->
          <!--                    </div>-->
          <!--          <div v-else-if="item.label=='跳转方式'">-->
          <!--            {{toSelect.filter(list=>list.jump_id==row[item.prop])[0].jump_page}}-->
          <!--          </div>-->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  // import Addbanner from './components/addbanner'
  export default {
    name: 'Index',
    components: {},
    data() {
      return {
        form: {
          advert_no: null,
          title: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '所属店铺',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '位置',
            prop: 'position_name',
            width: '',
          },
          {
            label: '广告图片',
            prop: 'img',
            width: '160',
          },
          {
            label: '跳转方式',
            prop: 'advert_jump_page',
            width: '',
          },
          {
            label: '跳转产品',
            prop: 'ref_name',
            width: '80',
          },
          {
            label: '曝光量',
            prop: 'exposure_num',
            width: '',
          },
          {
            label: '设置时间',
            prop: 'created_txt',
            width: '80',
          },
        ],
        shopSelect: [],
        total: 0,
        typeSelect: [],
        toSelect: [],
      }
    },
    async mounted() {
      await this.handlerSelect()
      await this.handlerInquire()
    },
    methods: {
      async handlerSelect() {
        await getAction('/audit/advert/position-config').then((res) => {
          this.shopSelect = res.data
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/audit/advert/enterprise-statistics', this.form).then(
          (res) => {
            // this.tabledata=res.data
            this.tabledata = res.data.map((item) => {
              this.typeSelect.forEach((list) => {
                if (list.advert_no == item.advert_no) {
                  item.position_name = list.position_name
                }
              })
              this.toSelect.forEach((list) => {
                if (list.jump_id == item.advert_jump) {
                  item.jump_page = list.jump_page
                }
              })
              // item.position_name=this.typeSelect.filter(list=>list.advert_no==item.advert_no)[0]['position_name']
              // item.jump_page=this.toSelect.filter(list=>list.jump_id==item.advert_jump)[0]['jump_page']
              return item
            })
            this.total = Number(res.totalCount)
          }
        )
      },
      handlerdelete(row) {
        postAction('/audit/advert/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
