var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        content: "",
        visible: _vm.showEditPoster,
        "close-on-click-modal": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "140px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名称", maxlength: "50" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "share_title" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "分享标题",
                      rows: "4",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "设置栏目" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeSelect, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享小程序图", prop: "share_mini_pic" } },
                [
                  _c("upload-img", {
                    ref: "share_mini_pic",
                    attrs: {
                      "info-text": "分享小程序图，建议尺寸 500x400",
                      limit: 1,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_mini_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "朋友圈分享图", prop: "share_friend_pic" } },
                [
                  _c("upload-img", {
                    ref: "share_friend_pic",
                    attrs: {
                      "info-text": "朋友圈分享图，建议尺寸400x400",
                      limit: 1,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_friend_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容图片", prop: "pics" } },
                [
                  _c("upload-img-video", {
                    ref: "pics",
                    attrs: {
                      maxSize: 500,
                      "info-text":
                        "内容图片、视频支持拖拽排序，排序方式为最左边第一个显示为起始图片或视频以此类推",
                      limit: 1000,
                    },
                    on: {
                      getImgVideos: function ($event) {
                        return _vm.getGoods_VideoImgs($event, "pics")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享海报显示开关", prop: "poster_door" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.poster_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "poster_door", $$v)
                        },
                        expression: "form.poster_door",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.poster_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "poster_door", $$v)
                        },
                        expression: "form.poster_door",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm.form.poster_door == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示权限", prop: "poster_auth" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.poster_auth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "poster_auth", $$v)
                                },
                                expression: "form.poster_auth",
                              },
                            },
                            _vm._l(
                              [
                                { id: "0", name: "所有人" },
                                { id: "1", name: "员工" },
                              ],
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "分享海报图标", prop: "poster_icon" },
                        },
                        [
                          _c("upload-img", {
                            ref: "poster_icon",
                            attrs: {
                              "info-text": "建议尺寸：300*300",
                              limit: 1,
                            },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getGoods_imgImg(
                                  $event,
                                  "poster_icon"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "海报图片", prop: "poster_pic" } },
                        [
                          _c("upload-img", {
                            ref: "poster_pic",
                            attrs: {
                              "info-text": "建议尺寸：350*1500，图片大小500k",
                              limit: 1,
                            },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getGoods_imgImg($event, "poster_pic")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "二维码横坐标", prop: "jump_url" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入二维码横坐标",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.poster_x,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "poster_x", $$v)
                              },
                              expression: "form.poster_x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "二维码纵坐标", prop: "jump_url" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入二维码纵坐标",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.poster_y,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "poster_y", $$v)
                              },
                              expression: "form.poster_y",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "二维码宽度", prop: "jump_url" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入二维码宽度",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.poster_qrcode_width,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "poster_qrcode_width", $$v)
                              },
                              expression: "form.poster_qrcode_width",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "二维码高度", prop: "jump_url" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入二维码高度",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.poster_qrcode_height,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "poster_qrcode_height", $$v)
                              },
                              expression: "form.poster_qrcode_height",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预览" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.preview },
                            },
                            [_vm._v("预览")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "邀请记录显示开关" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.invite_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invite_door", $$v)
                        },
                        expression: "form.invite_door",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.invite_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invite_door", $$v)
                        },
                        expression: "form.invite_door",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm.form.invite_door == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示权限" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.invite_auth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "invite_auth", $$v)
                                },
                                expression: "form.invite_auth",
                              },
                            },
                            _vm._l(
                              [
                                { id: "0", name: "所有人" },
                                { id: "1", name: "员工" },
                              ],
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "邀请记录图标", prop: "invite_icon" },
                        },
                        [
                          _c("upload-img", {
                            ref: "invite_icon",
                            attrs: {
                              "info-text": "建议尺寸：300*300",
                              limit: 1,
                            },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getGoods_imgImg(
                                  $event,
                                  "invite_icon"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "悬浮图标开关", prop: "float_door" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.float_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "float_door", $$v)
                        },
                        expression: "form.float_door",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.float_door,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "float_door", $$v)
                        },
                        expression: "form.float_door",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm.form.float_door == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "悬浮图标", prop: "float_icon" } },
                        [
                          _c("upload-img", {
                            ref: "float_icon",
                            attrs: {
                              "info-text": "建议尺寸：300*300",
                              limit: 1,
                            },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getGoods_imgImg($event, "float_icon")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.jump_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jump_type", $$v)
                                },
                                expression: "form.jump_type",
                              },
                            },
                            _vm._l(
                              [
                                { id: "0", name: "不跳转" },
                                { id: "1", name: "H5链接" },
                                { id: "2", name: "小程序链接" },
                              ],
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转链接" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入跳转链接" },
                            model: {
                              value: _vm.form.jump_url,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_url", $$v)
                              },
                              expression: "form.jump_url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditPoster = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }