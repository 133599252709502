<template>
<div>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="300px">
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item  label="所属模块" >
          <el-select v-model="form.type" clearable style="width: 160px;" placeholder="所属模块">
            <el-option v-for="list in typeSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>
        <el-form-item  label="关联文章" >
          <el-autocomplete
            style="width: 160px;"
            v-model="form.title"
            :fetch-suggestions="handlerarticle"
            value-key="title"
            placeholder="逛逛文章标题"
            @select="handleSelectarticle"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item  label="状态">
          <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
            <el-option
              value="1"
              label="启用"/>
            <el-option
              value="0"
              label="禁用"/>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order" >
          <el-input-number v-model="form.sort_order" style="width: 160px;" :min="0"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>`
</div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      title:'',
      showadd:false,
      form:{
        article_id:'',
        type:'',
        status:'1',
        sort_order:0,
        id:''
      },
      typeSelect: [
        {
          id: '1',
          name: '首页'
        }, {
          id: '2',
          name: '万商解读'
        }, {
          id: '3',
          name: '万商名企'
        }, {
          id: '4',
          name: '万商活动'
        }
      ],
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
          article_id:'',
          type:'',
          status:'1',
          sort_order:0,
          id:''
        }
      }
    }
  },
  methods:{
    handlersave(){
      postAction("/api/afbc/article/edit",this.form).then(res=>{
        this.$message.success(res.msg)
        this.showadd = false
        this.$emit("getlist")
      })
    },
    handlerarticle(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.article_id=''
      }else{
        getAction("/api/afbc/article/search",{title:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectarticle(list){
      console.log(list)
      this.form.article_id=list.id
      this.form.title=list.title
    },
    show(){
      this.showadd=true
    }
  }
};
</script>

<style scoped>

</style>
