<template>
  <el-dialog content :visible.sync="showEditPoster" width="400px">
    <div>
      <el-form ref="form" :model="form">
        <el-form-item label="产品标题" prop="code">
          <el-input
            v-model="form.code"
            placeholder="搜索云交会产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="热搜指数" prop="name">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showEditPoster = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'addedit',
    data() {
      return {
        showEditPoster: false,
        form: {
          code: '',
          value: '',
          name: '',
          id: '',
        },
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          this.form = {
            code: '',
            value: '',
            name: '',
            id: '',
          }
        }
      },
    },
    methods: {
      handlersave() {
        getAction('/api/system/conf/set', this.form).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.$emit('getlist')
          this.showEditPoster = false
        })
      },
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
      },
    },
  }
</script>

<style scoped></style>
