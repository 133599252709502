<template>
  <div class="index-container">
    <el-row>
      <el-col :span="12">
        <el-table border :data="tablelist" style="width: 100%">
          <el-table-column label="id" prop="id" width="" />
          <el-table-column label="名称" prop="name" width="" />
          <el-table-column label="时间" prop="value" width="180px" />
          <el-table-column label="操作" prop="" width="">
            <template #default="{ row }">
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="编辑" :visible.sync="showedit" width="30%">
      <div>
        <el-form ref="form" label-width="40px" :model="form">
          <el-form-item label="时间">
            <!-- <el-input v-model="form.value" style="width: 80px" type="number" /> -->
            <el-select
              v-model="form.value"
              clearable
              placeholder="时间"
              style="width: 80px"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :label="list.id"
                :value="list.id"
              />
            </el-select>
            <div style="display: inline-block; margin-left: 10px">单位：天</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  // import WangEditor from '@/components/WangEditor'

  export default {
    name: 'Index',
    // components: { WangEditor },
    data() {
      return {
        tablelist: [],
        showedit: false,
        form: {},
        statusSelect: [
          {
            id: '1',
          },
          {
            id: '2',
          },
          {
            id: '3',
          },
          {
            id: '7',
          },
          {
            id: '30',
          },
        ],
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handleredit(row) {
        this.showedit = true
        this.form = JSON.parse(JSON.stringify(row))
      },
      handlerlist() {
        getAction('/api/system/hot/conf', { code: 'hot_chuntang_day' }).then(
          (res) => {
            this.tablelist = [res.data]
          }
        )
      },
      handlersave() {
        let params = {
          code: this.form.code,
          value: this.form.value,
        }
        postAction('/api/system/hot/set-conf', params)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showedit = false
            this.handlerlist()
          })
          .catch((err) => {
            console.error(err)
          })
      },
    },
  }
</script>

<style scoped></style>
