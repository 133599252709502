<template>
  <el-dialog
    title="数据统计"
    :visible.sync="showDialog"
    width="1250px"
    top="9vh"
    :close-on-click-modal="false"
    :modal="true"
    append-to-body
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form :model="form" ref="form" label-position="right" label-width="140px" label-suffix=":">
        <el-form-item label="接收人数" prop="total">{{ form.receive_nums }}</el-form-item>
        <el-form-item label="阅读人数" prop="read">{{ form.receive_read_nums }}</el-form-item>
        <el-form-item label="有无跳转链接" prop="is_jump">{{ form.is_jump }}</el-form-item>
        <el-form-item label="链接点击人数" prop="click">{{ form.click_nums }}</el-form-item>
<!--        <el-form-item label="链接点击人数(首页)" prop="click">{{ form.click_index }}</el-form-item>
        <el-form-item label="链接点击人数(我的)" prop="click">{{ form.click_my }}</el-form-item>-->
        <el-form-item label="链接点击率" prop="click_rate"
          >{{ form.click_rate }}
          <span style="color: #ccc; margin-left: 30px"
            >（计算公式：链接点击人数/阅读人数）</span
          ></el-form-item
        >
      </el-form>
<!--      <div style="font-size: 18px; margin-bottom: 10px" class="center">链接点击人明细</div>-->
<!--      <el-form :model="form2" inline ref="form" label-position="right" label-suffix=":">
        <el-form-item label="姓名" prop="">
          <el-input v-model="form2.name"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="">
          <area-select ref="AreaSelect" @select="changeCity" style="width: 200px"></area-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="">
          <el-input v-model="form2.mobile"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="">
          <el-input v-model="form2.company"></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>-->
<!--      <el-table border :data="list" v-loading="Tloading">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="form2.page"
        :layout="layout"
        :page-size="form2.limit"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        style="text-align: center"
      ></el-pagination>-->
    </div>
  </el-dialog>
</template>
<script>
// import AreaSelect from "@/components/areaSelect"
import { getAction, postAction } from "@/api/request";
// import { exportFile } from "@/utils/downloadFile"

export default {
  name: "DataSummary",
  // components: { AreaSelect },
  data() {
    return {
      id: null,
      loading: false,
      Tloading: false,
      showDialog: false,
      form: {},
      total: 0,
      list: [],
      layout: "total, sizes, prev, pager, next, jumper",
      columns: [
        {
          order: 1,
          label: "姓名",
          prop: "name",
          width: "",
        },
        {
          order: 2,
          label: "地区",
          prop: "region",
          width: "",
        },
        {
          order: 3,
          label: "手机号码",
          prop: "mobile",
          width: "",
        },
        {
          order: 4,
          label: "公司名称",
          prop: "company",
          width: "",
        },
        {
          order: 5,
          label: "点击位置",
          prop: "position",
          width: "",
        },
      ],
      form2: {
        page: 1,
        limit: 10,
      },
    }
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    showDialog(v) {
      if (v) {
        this.fetchData()
        // this.fetchData2()
      } else {
        this.form = {}
        this.form2 = { page: 1, limit: 10 }
        this.$refs.AreaSelect.value = []
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        let data = await getAction("/user/message/census", { id: this.id })
        this.form = data.data
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    // async fetchData2() {
    //   this.Tloading = true
    //   try {
    //     let data = await postAction("/Notice/Click_data", { ...{ id: this.id }, ...this.form2 })
    //     this.total = Number(data.extra)
    //     this.list = data.result
    //     this.Tloading = false
    //   } catch (err) {
    //     this.Tloading = false
    //   }
    // },
    handleCurrentChange(val) {
      this.form2.page = val
      this.fetchData()
    },
    handleSizeChange(val) {
      this.form2.limit = val
      this.fetchData()
    },
    handleQuery() {
      this.form2.page = 1
      this.fetchData2()
    },
    // handleExport() {
    //   exportFile({ ...this.form2, ...{ id: this.id } }, "/Notice/Click_data_export")
    // },
    changeCity(e) {
      Object.assign(this.form2, e)
    },
  },
}
</script>
<style lang="scss" scoped></style>
