<template>
  <el-dialog :visible.sync="show" width="40%">
    <div>
      <el-form ref="form" label-width="120px" :model="form" :rules="rules">
        <el-form-item label="店铺名称" prop="mall_name">
          <el-autocomplete
            v-model="form.mall_name"
            :fetch-suggestions="handlermell"
            placeholder="请输入店铺名称"
            style="width: 160px"
            value-key="mall_name"
            @select="handleSelectmall"
          />
        </el-form-item>
        <el-form-item label="公司名称">
          {{ company_name }}
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-autocomplete
            v-model="form.phone"
            :fetch-suggestions="handlerphone"
            placeholder="请输入手机号"
            style="width: 160px"
            value-key="phone"
            @select="handleSelectphone"
          />
        </el-form-item>
        <el-form-item label="管理员姓名" prop="name">
          <el-input
            v-model="form.name"
            clearable
            placeholder="管理员姓名"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="管理员职务" prop="job">
          <el-input
            v-model="form.job"
            clearable
            placeholder="管理员职务"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="是否主管理员" prop="is_master">
          <el-select
            v-model="form.is_master"
            clearable
            placeholder="是否主管理员"
          >
            <el-option label="是" value="1" />
            <el-option label="否" value="0" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'AddEdit',
    data() {
      return {
        show: false,
        form: {
          phone: '',
          name: '',
          job: '',
          is_master: '',
          mall_id: '',
          id: '',
        },
        rules:{
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              pattern: /^1[23456789]\d{9}$/,
              message: '请输入正确的手机号',
              trigger: 'blur',
            },
          ],
          name: [
            { required: true, message: '请输入管理员姓名', trigger: 'blur' },
          ],
          job: [
            { required: true, message: '请输入管理员职务', trigger: 'blur' },
          ],
          is_master: [
            { required: true, message: '请选择是否主管理员', trigger: 'blur' },
          ],
          mall_name: [
            { required: true, message: '请输入店铺名称', trigger: 'blur' },
          ],
        },
        company_name: '',
      }
    },
    watch: {
      show(val) {
        if (!val) {
          this.form = {
            phone: '',
            name: '',
            job: '',
            is_master: '',
            mall_id: '',
            id: '',
          }
          this.company_name = ''
        }
      },
    },
    methods: {
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
        this.company_name = row.company_name
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/mall/manager/edit', this.form).then((res) => {
              this.$emit('getlist')
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.show = false
            })
          }
      })

      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.mall_id = list.id
        this.company_name = list.certification.company_name
      },
      handlerphone(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.user_id = ''
        } else {
          getAction('/user/index/search-user', { phone: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectphone(list) {
        console.log(list)
        this.form.user_id = list.id
      },
    },
  }
</script>

<style scoped></style>
