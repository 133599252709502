var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "900px",
        top: "7vh",
        "close-on-click-modal": false,
        modal: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
          staticStyle: { width: "800px", margin: "0 auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "160px",
                rules: _vm.rules,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "消息标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "30",
                      "show-word-limit": "",
                      placeholder: "最多30个字",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "4",
                      placeholder: "最多200个字",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接收方", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "请选择接收方" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.receiveSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接收地区", prop: "area" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange },
                      model: {
                        value: _vm.form.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "area", $$v)
                        },
                        expression: "form.area",
                      },
                    },
                    _vm._l(_vm.citySelect, function (city, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: city.id } },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接收行业", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate2 },
                      on: { change: _vm.handleCheckAllChange2 },
                      model: {
                        value: _vm.checkAll2,
                        callback: function ($$v) {
                          _vm.checkAll2 = $$v
                        },
                        expression: "checkAll2",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange2 },
                      model: {
                        value: _vm.form.industry,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "industry", $$v)
                        },
                        expression: "form.industry",
                      },
                    },
                    _vm._l(_vm.hangyeSelect, function (city, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: city.id } },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接收渠道", prop: "channel" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate4 },
                      on: { change: _vm.handleCheckAllChange4 },
                      model: {
                        value: _vm.checkAll4,
                        callback: function ($$v) {
                          _vm.checkAll4 = $$v
                        },
                        expression: "checkAll4",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange4 },
                      model: {
                        value: _vm.form.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channel", $$v)
                        },
                        expression: "form.channel",
                      },
                    },
                    _vm._l(_vm.qudaoSelect, function (sf, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: sf.id } },
                        [_vm._v(_vm._s(sf.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型", prop: "jump_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择跳转类型",
                      },
                      on: { change: _vm.jumpTypeChange },
                      model: {
                        value: _vm.form.jump_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jump_type", $$v)
                        },
                        expression: "form.jump_type",
                      },
                    },
                    _vm._l(_vm.jumpTypeSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.jump_id, label: i.jump_page },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.jump_type != 6
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "跳转链接文字内容",
                        prop: "button_title",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "最多20个字",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.form.button_title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "button_title", $$v)
                          },
                          expression: "form.button_title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.jump_type == 2 ||
              _vm.form.jump_type == 10 ||
              _vm.form.jump_type == 1 ||
              _vm.form.jump_type == 3 ||
              _vm.form.jump_type == 12
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.jumpLabel, prop: "jump_val" } },
                    [
                      _vm.form.jump_type == 3 || _vm.form.jump_type == 12
                        ? _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              clearable: "",
                              placeholder: _vm.jump_name,
                            },
                            model: {
                              value: _vm.form.jump_val,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_val", $$v)
                              },
                              expression: "form.jump_val",
                            },
                          })
                        : _vm._e(),
                      _vm.form.jump_type == 1
                        ? _c("el-autocomplete", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.handlermell,
                              "value-key": "mall_name",
                              placeholder: "请输入店铺名称",
                              "suffix-icon": "el-icon-arrow-down",
                            },
                            on: { select: _vm.handleSelectmall },
                            model: {
                              value: _vm.form.jump_val_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_val_name", $$v)
                              },
                              expression: "form.jump_val_name",
                            },
                          })
                        : _vm.form.jump_type == 2
                        ? _c("el-autocomplete", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.handlergoods,
                              "value-key": "goods_name",
                              placeholder: "请输入商品名称",
                              "suffix-icon": "el-icon-arrow-down",
                            },
                            on: { select: _vm.handleSelectgoods },
                            model: {
                              value: _vm.form.jump_val_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_val_name", $$v)
                              },
                              expression: "form.jump_val_name",
                            },
                          })
                        : _vm.form.jump_type == 10
                        ? _c("el-autocomplete", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.handlerhouse,
                              "value-key": "house_name",
                              placeholder: "请输入展馆名称",
                              "suffix-icon": "el-icon-arrow-down",
                            },
                            on: { select: _vm.handleSelecthouse },
                            model: {
                              value: _vm.form.jump_val_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_val_name", $$v)
                              },
                              expression: "form.jump_val_name",
                            },
                          })
                        : _c("div"),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发送类型", prop: "send_type" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.send_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "send_type", $$v)
                        },
                        expression: "form.send_type",
                      },
                    },
                    [_vm._v("立即发送")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.form.send_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "send_type", $$v)
                        },
                        expression: "form.send_type",
                      },
                    },
                    [_vm._v("定时发送")]
                  ),
                ],
                1
              ),
              _vm.form.send_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "定时发送时间", prop: "send_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          clearable: "",
                          placeholder: "选择发送时间",
                          "default-time": "12:00:00",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.form.send_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "send_time", $$v)
                          },
                          expression: "form.send_time",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.handlePreview },
                },
                [_vm._v("预览")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
          _c("Preview", { ref: "preview" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }