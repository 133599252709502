import request from '@/utils/request'

export function getConfigList(params) {
  return request({
    url: '/auth/settings/index',
    method: 'post',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/auth/settings/create',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/auth/settings/delete',
    method: 'post',
    data,
  })
}

export function doSingleEdit(data) {
  return request({
    url: '/auth/settings/create',
    method: 'post',
    data,
  })
}
