var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { content: "", visible: _vm.showEditPoster, width: "400px" },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "标题", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "视频", prop: "video" } },
                [
                  _c("upload-video", {
                    ref: "video",
                    attrs: { limit: 1, "info-text": "", maxSize: 100 },
                    on: {
                      getVideos: function ($event) {
                        return _vm.getVideos($event, "video")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort_order" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "排序", clearable: "" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.shopSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditPoster = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }