var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "900px",
        top: "7vh",
        "close-on-click-modal": false,
        modal: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "180px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "活动名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页banner", prop: "banner_pic" } },
                [
                  _c("upload-img", {
                    ref: "banner_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "banner_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页背景色", prop: "bg_color" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "首页背景色" },
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页栏目背景色", prop: "menu_bg_color" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "首页栏目背景色" },
                    model: {
                      value: _vm.form.menu_bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_bg_color", $$v)
                      },
                      expression: "form.menu_bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "首页栏目标题文字颜色",
                    prop: "menu_title_color",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "首页栏目标题文字颜色",
                    },
                    model: {
                      value: _vm.form.menu_title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_title_color", $$v)
                      },
                      expression: "form.menu_title_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "首页栏目副标题文字颜色",
                    prop: "menu_subtitle_color",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "首页栏目副标题文字颜色",
                    },
                    model: {
                      value: _vm.form.menu_subtitle_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menu_subtitle_color", $$v)
                      },
                      expression: "form.menu_subtitle_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二级页背景色", prop: "sub_bg_color" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "二级页背景色" },
                    model: {
                      value: _vm.form.sub_bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sub_bg_color", $$v)
                      },
                      expression: "form.sub_bg_color",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报", prop: "poster_pic" } },
                [
                  _c("upload-img", {
                    ref: "poster_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "poster_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "share_title" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "分享标题" },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享图", prop: "share_pic" } },
                [
                  _c("upload-img", {
                    ref: "share_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二维码横坐标", prop: "qrcode_x" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      clearable: "",
                      placeholder: "二维码横坐标",
                    },
                    model: {
                      value: _vm.form.qrcode_x,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "qrcode_x", $$v)
                      },
                      expression: "form.qrcode_x",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二维码纵坐标", prop: "qrcode_y" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      clearable: "",
                      placeholder: "二维码纵坐标",
                    },
                    model: {
                      value: _vm.form.qrcode_y,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "qrcode_y", $$v)
                      },
                      expression: "form.qrcode_y",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二维码宽度", prop: "qrcode_width" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      clearable: "",
                      placeholder: "二维码宽度",
                    },
                    model: {
                      value: _vm.form.qrcode_width,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "qrcode_width", $$v)
                      },
                      expression: "form.qrcode_width",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二维码高度", prop: "qrcode_height" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      clearable: "",
                      placeholder: "二维码高度",
                    },
                    model: {
                      value: _vm.form.qrcode_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "qrcode_height", $$v)
                      },
                      expression: "form.qrcode_height",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }