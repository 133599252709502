<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 210px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 210px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          :default-time="['12:00:00']"
          end-placeholder="提交结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="提交开始日期"
          style="width: 340px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.type" clearable style="width: 160px;" placeholder="类型">
          <el-option v-for="list in statusSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.operation_type" clearable style="width: 160px;" placeholder="变动方式">
          <el-option label="增加" value="1"/>
          <el-option label="减少" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item  prop="operation_position">
        <el-select v-model="form.operation_position" clearable style="width: 160px;" placeholder="变动类型">
          <el-option label="套餐内" value="1"/>
          <el-option label="赠送" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.remark" clearable placeholder="备注" style="width: 160px"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row.img]"
              :src="row.img"
              style="width: 70px; height: 30px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片留存'">
            <el-row :gutter="10">
             <el-col :span="6" v-for="(item, index) in row.pic" :key="index">
               <el-image
                 :preview-src-list="[item]"
                 :src="item"
                 style="width: 70px; height: 30px"
               >
                 <div slot="placeholder" class="image-slot">
                   加载中
                   <span class="dot">...</span>
                 </div>
               </el-image>
             </el-col>
            </el-row>
          </div>
          <div v-else-if="item.label == '类型'">
            {{ statusSelect.filter(list=>list.id == row[item.prop])[0].name }}
          </div>
          <div v-else-if="item.label == '变动类型'">
            <span v-show="row[item.prop] == 1">套餐内</span>
            <span v-show="row[item.prop] == 2">赠送</span>
          </div>
          <div v-else-if="item.label == '状态'">
            <span v-show="row[item.prop] == 1">有效</span>
            <span v-show="row[item.prop] == 2">失效</span>
            <span v-show="row[item.prop] == 3">已过期</span>
            <span v-show="row[item.prop] == 4">已完成</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <div v-if="row.status == 1">
            <el-popconfirm title="确认取消？" @confirm="handlerdelete(row)">
              <el-button slot="reference" size="small" type="text">
                取消
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="addedit" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import AddEdit from './components/AddEdit'
  export default {
    components: { AddEdit },
    data() {
      return {
        form: {
          start_date: '',
          end_date: '',
          company_name: '',
          mall_name: '',
          remark:'',
          operation_type:'',
          operation_position:'',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        time: [],
        columns: [
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司名称',
            prop: 'company_name',
            width: '160',
          },
          {
            label: '数量',
            prop: 'total_num',
            width: '',
          },
          {
            label: '类型',
            prop: 'type',
            width: '',
          },
          {
            label: '变动方式',
            prop: 'operation_type',
            width: '',
          },
          {
            label: '变动类型',
            prop: 'operation_position',
            width: '',
          },
          {
            label: '添加时间',
            prop: 'created',
            width: '',
          },
          {
            label: '过期时间',
            prop: 'expire_time',
            width: '',
          },
          {
            label: '添加人',
            prop: 'operator_name',
            width: '',
          },
          {
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            label: '图片留存',
            prop: 'pic',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
        ],
        total: 0,
        typeSelect: [],
        toSelect: [],
        statusSelect:[
          {
            id:1,
            name:'匹配'
          },
          {
            id:6,
            name:'云会议'
          },
          {
            id:5,
            name:'大商直通车'
          },
        ]
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    async mounted() {
      await this.handlerInquire()
    },
    methods: {
      async handlerSelect() {
        await getAction('/audit/identity/identity').then((res) => {
          this.identitySelect = res.data
        })
        await getAction('/audit/advert/position').then((res) => {
          this.typeSelect = res.data
        })
        let typeSelect = await getAction('/audit/advert/jump-type')
        console.log(typeSelect.data)
        this.toSelect = typeSelect.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.addedit.showedit = true
      },
      handlerlist() {
        getAction('/mall/give/lists', this.form).then((res) => {
          this.tabledata = res.data.map(list=>{
            if (list.pic.length>0){
              list.pic=list.pic.split(',')
            }else{
              list.pic=[]
            }
            return list
          })
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/mall/give/cancel', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
