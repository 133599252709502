<template>
  <el-dialog title="预览" :visible.sync="show" width="90%">
    <div>
      <h4 style="color: #f56c6c" v-if="shoutext=='是'">产品重新上架，建议仔细核查</h4>
      <el-descriptions border :column="1">
        <el-descriptions-item label="编号" v-if="form.goods_sn">
          {{ form.goods_sn }}
        </el-descriptions-item>
        <el-descriptions-item label="品类" v-if="form.cat_txt">
          {{ form.cat_txt }}
        </el-descriptions-item>
        <el-descriptions-item label="细分品类" v-if="form.cat_p_txt">
          {{ form.cat_p_txt }}
        </el-descriptions-item>
        <el-descriptions-item label="产品名称" v-if="form.goods_name">
          {{ form.goods_name }}
        </el-descriptions-item>
        <el-descriptions-item label="产品标签" v-if="form.goods_labels">
          <span v-for="list in form.goods_labels" style="margin-left: 10px">
            {{ list.name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="渠道" v-if="form.channel_name">
          <span v-for="list in form.goods_channel" style="margin-left: 10px">
            {{ list.channel_name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="规格" v-if="form.goods_specifications">
          <span
            v-for="list in form.goods_specifications"
            style="margin-left: 10px"
          >
            {{ list.attr_name }}-{{ list.attr_value }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="产品主图" v-if="form.goods_img">
          <el-image
            :preview-src-list="[form.goods_img]"
            :src="form.goods_img"
            style="width: 100px; height: 100px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="产品相册" v-if="form.goods_list_img">
          <div v-for="list in form.goods_list_img">
            <el-image
              :preview-src-list="[list]"
              :src="list"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="产品描述" v-if="form.goods_desc">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.goods_desc"
          />
        </el-descriptions-item>
        <el-descriptions-item label="产品价格" v-if="form.market_price">
          {{ form.market_price }}
        </el-descriptions-item>
        <el-descriptions-item label="视频" v-if="form.video">
          <video :src="form.video" style="height: 100px; width: 200px" controls></video>
        </el-descriptions-item>
        <el-descriptions-item label="规格-品类" v-if="form.attr_category">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.attr_category"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-包装规格" v-if="form.attr_spec">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.attr_spec"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-保质期" v-if="form.attr_date">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.attr_date"
          />
        </el-descriptions-item>
        <el-descriptions-item label="规格-招商区域" v-if="form.attr_area">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.attr_area"
          />
        </el-descriptions-item>
        <el-descriptions-item label="渠道到岸价" v-if="form.channel_price">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.channel_price"
          />
        </el-descriptions-item>
        <el-descriptions-item label="产品亮点" v-if="form.short_point">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.short_point"
          />
        </el-descriptions-item>
        <el-descriptions-item label="摘要" v-if="form.remark">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.remark"
          />
        </el-descriptions-item>
        <el-descriptions-item label="卖点" v-if="form.point">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.point"
          />
        </el-descriptions-item>
        <el-descriptions-item label="招商政策" v-if="form.policy">
          <el-input
            disabled
            :rows="6"
            style="overflow: auto"
            type="textarea"
            v-html="form.policy"
          />
        </el-descriptions-item>
        <el-descriptions-item label="排序" v-if="form.sort_order">
          {{ form.sort_order }}
        </el-descriptions-item>
        <el-descriptions-item label="是否可以申请样品" v-if="form.demo_apply">
          <el-tag v-if="form.demo_apply==1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="样品库存" v-if="form.goods_number">
          {{ form.goods_number }}
        </el-descriptions-item>
        <el-descriptions-item label="是否上架" v-if="form.is_on_sale">
          <el-tag v-if="form.is_on_sale==1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="show = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'Browse',
    data() {
      return {
        show: false,
        form: {},
      }
    },
    methods: {
      diashow() {
        this.show = true
      },
      clean() {
        this.show = false
        this.form= {}
      },
      handlerinfo(row) {
        this.shoutext = row.is_on_sale
        postAction('/audit/shop/goods-material-detail', { id: row.id }).then((res) => {
          this.form = res.data
        })
      },
    },
  }
</script>

<style scoped></style>
