<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">

      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="标题"

        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.author"
          clearable
          placeholder="作者"

        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>

    </el-form>

    <el-table border :data="tabledata"
              style="width: 100%"
              :row-key="getRowKey"
              @selection-change="setSelectRows"
    ref="ElTable">
      <el-table-column type="selection"
                       width="55"
                       align="center"
                       :reserve-selection="true">
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
           {{row[item.prop]==1?'已通过':'未通过'}}
          </div>
          <div v-else-if="item.label == '拉取状态'">
            {{row[item.prop]==1?'已拉取':'未拉取'}}
          </div>
          <div v-else-if="item.label == '图片1'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片2'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片3'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            拉取
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" align="middle" justify="space-between">
      <el-col :span="6">
        <el-button type="warning" @click="handlerPulling">批量拉取</el-button>
      </el-col>
      <el-col :span="12">
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: 'Index',
  data() {
    return {
      form: {
        is_check: -1,
        class_id: '',
        name: '',
        mobile: '',
        province_id: '',
        page: 1,
        limit: 10,
      },
      checkselect: [
        {
          id: -1,
          name: '全部',
        },
        {
          id: 0,
          name: '未审',
        },
        {
          id: 1,
          name: '已审',
        },
      ],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: 'ID',
          prop: 'article_id',
          width: '80',
        },
        {
          label: '标题',
          prop: 'title',
          width: '220px',
        },
        {
          label: '作者',
          prop: 'author',
          width: '',
        },
        {
          label: '状态',
          prop: 'is_check',
          width: '80',
        },
        {
          label: '创建时间',
          prop: 'created',
          width: '',
        },
        {
          label: '发布时间',
          prop: 'first_pubtime',
          width: '',
        },
        {
          label: '拉取状态',
          prop: 'is_push',
          width: '120',
        },
      ],
      total: 0,
      areaselect: [],
      classselect: [],
      selectRows:[],
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handleredit(row){
        postAction("/api/stroll/article/la-qu",{ids:[row.article_id]}).then(res=>{
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
    },
    handlerPulling(){
      if(this.selectRows.length>0){
        postAction("/api/stroll/article/la-qu",{ids:this.selectRows}).then(res=>{
          this.$refs.ElTable.clearSelection()
          this.selectRows=[]
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
      }else{
        this.$message({
          type: 'warning',
          message: '请先选择数据',
        })
      }

    },
    // async handlerSelect() {
    //   const { data } = await getAction('/user/index/area', { pid: 0 })
    //   this.areaselect = data
    //   await getAction('/user/index/cate').then((res) => {
    //     this.classselect = res.data
    //   })
    // },
    getRowKey(row){
      return row.article_id
    },
    setSelectRows(val){
      this.selectRows = val.map(list=>{
        return list.article_id
      })
    },
    handlerlist() {
      getAction('/api/stroll/article/ht-lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
