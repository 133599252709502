<template>
  <div>
    <el-dialog :title="title" :visible.sync="showEditSetPoster" width="50%">
      <div>
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="海报名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="文案" prop="desc">
            <el-input
              v-model="form.desc"
              placeholder="请输入"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="模板" prop="template_no">
            <el-select
              v-model="form.template_no"
              clearable
              placeholder="跳转方式"
              style="width: 160px"
            >
              <el-option
                v-for="list in templatelist"
                :key="list.no"
                :label="list.name"
                :value="list.no"
              />
            </el-select>
          </el-form-item>
          <el-form-item  label="海报背景" prop="bg_image">
            <upload-img ref="bg_image" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'bg_image')"/>
          </el-form-item>
          <el-form-item label="排序" prop="sort_order">
            <el-input
              v-model="form.sort_order"
              placeholder="排序"
              style="width: 160px"
              type="number"
            />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditSetPoster = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import uploadImg from "@/components/uploadImg";
  import { getAction, postAction } from '@/api/request'
  import { nextTick } from "vue";
  export default {
    components: {uploadImg},
    props: {
      typeid: {
        type: [String, Number],
        default: () => {
          return ''
        },
      },
    },
    data() {
      return {
        title: '',
        showEditSetPoster: false,
        form: {
          id: null,
          type_id: this.typeid,
          name: '',
          desc: '',
          template_no: '',
          sort_order: '',
          status: '1',
        },
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          desc: [{ required: true, message: '请输入文案', trigger: 'blur' }],
          template_no: [
            { required: true, message: '请选择模板', trigger: 'blur' },
          ],
          sort_order: [
            { required: true, message: '请输入排序顺序', trigger: 'blur' },
          ],
          status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
          bg_image: [{ required: true, message: '请上传海报背景', trigger: 'blur' }],
        },
        statusSelect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '2',
            name: '禁用',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        templatelist: [],
      }
    },
    watch: {
      typeid(val) {
        this.form.type_id = val.toString()
      },
      showEditSetPoster(val) {
        if (!val) {
          this.resetForm()
          this.title = '新增'
        }
      },
    },
    mounted() {
      this.getTemplatelist()
    },
    methods: {
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ""
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop){
        this.$refs[prop].img=[]
      },
      // 获取海报模板列表
      getTemplatelist() {
        getAction('/api/poster/index/tpl', null).then((res) => {
          this.templatelist = res.data
        })
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.closeimg('bg_image')
        this.form = {
          id: null,
          type_id: this.typeid,
          name: '',
          desc: '',
          template_no: '',
          sort_order: '',
          status: '1',
        }
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
        nextTick(()=>{
          this.setImg(this.form.template.bg_image,"bg_image")
        })

        this.form.status = String(row.status)
        this.form.type_id = this.typeid
      },
      handlersave() {
        this.form.type_id = this.form.type_id.toString()
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/poster/index/edit', this.form).then((res) => {
              if (res.request_code == 200) {
                this.showEditSetPoster = false
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.$emit('getlist')
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
