<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showadd"
    width="50%"
  >
    <div>
      <el-form ref="form" label-width="120px" :model="form">
        <el-form-item label="产品名称" prop="goods_id">
          <el-autocomplete
            v-model="form.goods_name"
            clearable
            :fetch-suggestions="fetchGoods"
            placeholder="请输入店铺名称"
            style="width: 160px"
            value-key="goods_name"
            @select="handleSelectGoods"
          />

          <span>（可输入关键字模糊搜索）</span>
        </el-form-item>
        <el-form-item v-if="form.cat_name" label="品类">
          <span>{{ form.cat_name }}</span>
        </el-form-item>

        <el-form-item v-if="form.goods_name" label="店铺名称">
          {{ form.goods_name }}
        </el-form-item>
        <el-form-item v-if="form.goods_img" label="封面图">
          <img
            alt=""
            :src="form.goods_img"
            style="width: 150px; height: 150px"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="form.sort_order"
            placeholder="排序"
            style="width: 160px"
            type="number"
          />
          <p>
            （默认后上传的排在上边。支持手动排序，数字小的在前，大的在后。最小有效数字为1。）
          </p>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="form.status"
            clearable
            placeholder="设置状态"
            style="width: 160px"
          >
            <el-option label="启用" value="1" />
            <el-option label="禁用" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.add_time"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          <span>（默认为当前时间，可修改）</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import { nextTick } from 'vue'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
    },
    props: {
      cateSelect: {
        type: Array,
        default: [],
      },
      sourceSelect: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        showadd: false,
        title: '爆品推荐',
        shopSelect: [],
        form: {
          id: null,
          cat_id: '',
          source: '',
          title: '',
          url: '',
          sort_order: '',
          status: '1',
          add_time: '',
          pic: '',
        },
        id: '',
      }
    },
    watch: {
      showadd(val) {
        if (!val) {
          this.form = {
            id: null,
            cat_id: '',
            source: '',
            title: '',
            url: '',
            sort_order: '',
            status: '',
            add_time: '',
            pic: '',
          }
          this.closeimg('pic')
          this.$refs.form.resetFields()
        }
      },
    },
    methods: {
      fetchGoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.goods_id = ''
        } else {
          getAction('/api/system/wechat-article/goods-select', {
            title: queryString,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleSelectGoods(goods) {
        console.log('handleSelectGoods', goods)
        this.form.goods_id = goods.goods_id
        this.form.mall_name = goods.mall_name
        this.form.goods_img = goods.goods_img
        this.form.cat_name = goods.cat_name
      },
      handlerinfo(row) {
        this.id = row.id
        // this.form = Object.assign(this.form, row)
        this.fetchDetail()
        nextTick(() => {
          this.setImg(row.pic, 'pic')
        })
      },
      fetchDetail() {
        getAction('/api/system/wechat-article/goods-info', {
          id: this.id,
        }).then((res) => {
          if (res.request_code == 200) {
            this.form = res.data
          }
        })
      },
      handlersave() {
        postAction('/api/system/wechat-article/goods-edit', this.form).then(
          (res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$refs.form.resetFields()
            this.$emit('getlist')
            this.showadd = false
          }
        )
      },
      // 上传图片
      uploadImg(v, prop) {
        console.log('删除，', v)
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
        // if (val.length < 1) {
        //   console.log(1111)
        //   this.form.pic = ''
        // } else {
        //   console.log(1112)
        //   this.form.pic = val[0]
        // }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>

<style scoped></style>
