var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("FormMaker", {
        ref: "formMaker",
        attrs: {
          labels: _vm.formOpt,
          resetBtn: true,
          "label-width": 80,
          disabled: false,
        },
        on: { getFormData: _vm.handleQuery },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleAdd },
                      },
                      [_vm._v("新增")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.prop == "send_type"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1
                                      ? "立即发送"
                                      : "定时发送"
                                  ) +
                                  " "
                              ),
                            ])
                          : item.prop == "type"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1
                                      ? "企业"
                                      : row[item.prop] == 0
                                      ? "全部"
                                      : row[item.prop] == 2
                                      ? "经销商"
                                      : "其他"
                                  ) +
                                  " "
                              ),
                            ])
                          : item.prop == "jump_type"
                          ? _c("div", [
                              _vm._v(" " + _vm._s(row.jump.jump_page) + " "),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "160",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleData(row)
                          },
                        },
                      },
                      [_vm._v("数据统计")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "margin-top": "10px", "text-align": "center" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.pagesize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("AddEdit", { ref: "addEdit", on: { refresh: _vm.fetchData } }),
      _c("DataSummary", { ref: "dataSummary" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }