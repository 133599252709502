<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.title" clearable placeholder="页面名称"/>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '平台内跳转'">{{ row[item.prop] }} ({{row.pv1_ratio}})</div>
          <div v-else-if="item.label == '短信'">{{ row[item.prop] }} ({{row.pv2_ratio}})</div>
          <div v-else-if="item.label == '公众号信息'">{{ row[item.prop] }} ({{row.pv3_ratio}})</div>
          <div v-else-if="item.label == '海报二维码'">{{ row[item.prop] }} ({{row.pv5_ratio}})</div>
          <div v-else-if="item.label == '分享链接'">{{ row[item.prop] }} ({{row.pv6_ratio}})</div>
          <div v-else-if="item.label == '朋友圈'">{{ row[item.prop] }} ({{row.pv7_ratio}})</div>
          <div v-else-if="item.label == 'H5唤起'">{{ row[item.prop] }} ({{row.pv4_ratio}})</div>
          <div v-else-if="item.label == '其他'">{{ row[item.prop] }} ({{row.pv8_ratio}})</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import dayjs from "dayjs";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        title:'',
        start_date: dayjs(new Date().getTime()-1000*3600*24*30).format('YYYY-MM-DD'),
        end_date:dayjs(new Date()).format('YYYY-MM-DD'),
        page: 1,
        limit: 10,
      },
      time:[dayjs(new Date().getTime()-1000*3600*24*30).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '页面名称',
          prop: 'title',
          width: '',
        },

        {
          label: '总访问次数',
          prop: 'pv',
          width: '',
        },

        {
          label: '总访问人数',
          prop: 'uv',
          width: '',
        },
        {
          label: '平台内跳转',
          prop: 'pv1',
          width: '',
        },
        {
          label: '短信',
          prop: 'pv2',
          width: '',
        },

        {
          label: '公众号消息',
          prop: 'pv3',
          width: '',
        },

        {
          label: '海报二维码',
          prop: 'pv5',
          width: '',
        },
        {
          label: '分享链接',
          prop: 'pv6',
          width: '',
        },
        {
          label: '朋友圈',
          prop: 'pv7',
          width: '',
        },

        {
          label: 'H5唤起',
          prop: 'pv4',
          width: '',
        },

        {
          label: '其他',
          prop: 'pv8',
          width: '',
        },
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    // this.handlerSelect()
    // console.log('-')
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerSelect(){
      getAction("/api/form/form/wechat-select").then(res=>{
        console.log(res)
        this.staffSelect=res.data
      })
    },
    handlerlist() {
      getAction('/api/statistics/buried-point/lists', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      postAction('/api/statistics/buried-point/lists-export', this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
