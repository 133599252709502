<template>
  <el-dialog :title="title" :visible.sync="showedit" width="60%">
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="指定店铺">
        <el-autocomplete
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          placeholder="可输入关键词模糊搜索"
          style="width: 300px"
          value-key="mall_name"
          @select="handleSelectmall"
        />
      </el-form-item>
      <el-form-item label="一级品类" v-if="form.mall_id == ''">
        <el-select
          v-model="form.one_cate"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类" v-if="form.mall_id == ''">
        <el-select
          v-model="form.two_cate"
          clearable
          placeholder="二级分类"
          style="width: 160px"
          multiple
          @change="handlertwo"
        >
          <el-option
            v-for="list in twocatselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showpricelist" label="价格带">
        <el-select
          v-model="form.price_id"
          clearable
          placeholder="价格带"
          style="width: 160px"
          multiple
        >
          <el-option
            v-for="list in pricelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="需求详情">
        <el-input
          type="textarea"
          v-model="form.detail"
          placeholder="此信息对企业可见，请规范填写：我在（地区），做（渠道），想找（价位、细分品类）的产品。"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onclose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        title: '创建选品方案',
        form: {
          mall_id: '',
          mall_name: '',
          one_cate: '',
          two_cate: '',
          detail: '',
          price_id: '',
        },
        cateselect: [],
        showedit: false,
        pricelist: [],
        showpricelist: false,
        twocatselect: [],
      }
    },
    mounted() {
      // 获取一级品类
      getAction('/user/index/cate', { pid: 0 }).then((res) => {
        this.cateselect = res.data
      })
      this.getWinePrice()
    },
    methods: {
      handlertwo(e) {
        if (this.form.two_cate.length > 3) {
          this.$message.info('最多选择3个品类')
          this.form.two_cate.pop()
        }
      },
      onclose() {
        this.showedit = false
        this.showpricelist = false
        this.form = {
          mall_name: '',
          one_cate: '',
          two_cate: '',
          detail: '',
          price_id: '',
          mall_id: '',
        }
      },
      // 保存
      handlersave() {
        console.log('参数========', this.form)
        if (this.form.price_id && this.form.price_id.length > 0) {
          this.form.price_id = this.form.price_id.join(',')
        }
        if (this.form.two_cate && this.form.two_cate.length > 0) {
          this.form.two_cate = this.form.two_cate.join(',')
        }
        getAction('/api/work/sel-plan/create-selplanmass', this.form).then(
          (res) => {
            this.$message.success('创建成功！')
            this.onclose()
            this.$emit('getlist')
          }
        )
      },
      // 选择品类
      handlercat(e) {
        console.log('选择的品类', e)
        if (e == 1) {
          //白酒类型 显示价格带
          this.showpricelist = true
        } else {
          this.showpricelist = false
        }
        this.form.two_cate = ''
        this.twocatselect = []
        this.getTwocate()
      },
      // 获取二级品类
      getTwocate() {
        getAction('/user/index/cate', { pid: this.form.one_cate }).then(
          (res) => {
            this.twocatselect = res.data
          }
        )
      },
      // 获取价格带数据
      getWinePrice() {
        postAction('/audit/biz-clue/wine-price', {}).then((res) => {
          console.log('价格带-', res)
          if (res.request_code == 200) {
            this.pricelist = res.data
          }
        })
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectmall(list) {
        this.form.mall_id = list.id
        // if (list.sel_is_like == 1) {
        //   this.mallLimit[this.currentid].name = ''
        //   this.$message.error('客户已匹配该店铺，请勿重复匹配。')
        // } else {
        //   this.mallidlist[this.currentid] = list.id
        // }
      },
    },
  }
</script>
