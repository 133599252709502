var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "企微昵称",
              prop: "corp_staff_name",
              width: "220",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "云交会账号",
              prop: "user_name",
              width: "450",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      [
                        _c("el-autocomplete", {
                          staticStyle: { width: "380px" },
                          attrs: {
                            "fetch-suggestions": _vm.querySearchAsync,
                            placeholder: "请输入内容",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.handleSelect($event, row)
                            },
                          },
                          model: {
                            value: row.user_name,
                            callback: function ($$v) {
                              _vm.$set(row, "user_name", $$v)
                            },
                            expression: "row.user_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }