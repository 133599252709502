<template>
  <el-dialog
    :visible.sync="showEditPoster"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="页面">
          <el-select v-model="form.type" style="width: 220px;">
            <el-option :value="1" label="经销商"/>
            <el-option :value="2" label="企业"/>
          </el-select>
        </el-form-item>
        <el-form-item label="分享标题">
          <el-input v-model="form.share_title" style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="分享图">
          <upload-img ref="share_img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'share_img')" :maxSize="100"/>
        </el-form-item>
      </el-form>

    </div>

    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { nextTick } from "vue";
import { getAction } from "@/api/request";
export default {
  name: "edit",
  components:{
    UploadImg
  },
  data(){
    return{
      showEditPoster:false,
      form:{
        id:'',
        type:'',
        share_img:'',
        share_title:''
      }
    }
  },
  methods:{
    handlersave(){
      getAction("/api/system/direction/conf-edit",this.form).then(res=>{
        if(res.request_code==200){
          this.$emit("getlist")
          this.showEditPoster=false
        }
      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(this.form.share_img,"share_img")
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
