<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="showCheckinfo"
    width="30%"
  >
    <div id="copy_text" class="clipwrap">
      <p v-if="form.company != ''">公司名称：{{ form.company }}</p>
      <p v-if="form.name != ''">客户姓名：{{ form.name }}</p>
      <p v-if="form.area != ''">区域：{{ form.area }}</p>
      <p v-if="form.channel_name != ''">渠道：{{ form.channel_name }}</p>
      <p v-if="form.proxy_three != ''">代理知名品牌：{{ form.proxy_three }}</p>
      <p v-if="form.draw_proxy != ''">代理品牌：{{ form.draw_proxy }}</p>
      <p v-if="form.remark != ''">备注{{ form.remark }}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        class="copybtn"
        :data-clipboard-text="sourcecontent"
        type="primary"
        @click="copy"
      >
        一键复制
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Clipboard from 'clipboard'

  export default {
    name: 'Checkinfo',

    data() {
      return {
        showCheckinfo: false,
        title: '信息复核',
        form: {},
        sourcecontent: '',
      }
    },
    watch: {
      sourcecontent(val) {
        console.log('监听--------------', val)
      },
    },
    methods: {
      copy() {
        // 获取将要复制的文案信息
        let text = document.getElementById('copy_text').innerText
        this.sourcecontent = text
        // 执行复制操作
        var clipboard = new Clipboard('.copybtn')
        console.log('复制', clipboard)
        clipboard.on('success', (e) => {
          console.log(1, e)
          this.$message.success('复制成功')
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          console.log(2, e)
          // 不支持复制
          this.$message.error('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy()
        })
      },

      handlerlist(row) {
        console.log(row)
        this.form = Object.assign(this.form, row)
      },
    },
  }
</script>

<style scoped>
  .payname {
    color: #f00;
  }
</style>
