<template>
  <el-dialog
    :title="title"
    :visible.sync="addShow"
    width="70%"
    :before-close="handleClose"
  >
    <div class="add">
      <el-form ref="form" :model="form" :rules="rules" label-width="130px">
        <el-form-item label="调查主题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入问卷名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面主图">
          <upload-img
            :disab="disabled"
            ref="main_img"
            :limit="1"
            info-text="（图片尺寸：1080*2200）"
            @getImgs="getfloatimg($event, 'main_img')"
          />
        </el-form-item>
        <el-form-item label="主题图">
          <upload-img
            :disab="disabled"
            ref="title_img"
            :limit="1"
            info-text="（图片尺寸：1080*450）"
            @getImgs="getfloatimg($event, 'title_img')"
          />
        </el-form-item>
        <el-form-item label="抽奖图">
          <upload-img
            :disab="disabled"
            ref="award_img"
            :limit="1"
            info-text="（图片尺寸：1080*410 透明背景）"
            @getImgs="getfloatimg($event, 'award_img')"
          />
        </el-form-item>
        <el-form-item label="按钮背景图">
          <upload-img
            :disab="disabled"
            ref="button_img"
            :limit="1"
            info-text="（图片尺寸：232*80"
            @getImgs="getfloatimg($event, 'button_img')"
          />
        </el-form-item>
        <el-form-item label="背景色">
          <el-input v-model="form.bg_color"></el-input>
        </el-form-item>
        <el-form-item label="抽奖链接">
          <el-input v-model="form.award_url"></el-input>
        </el-form-item>
        <el-form-item label="H5分享主题">
          <el-input v-model="form.share_app_title"></el-input>
        </el-form-item>
        <el-form-item label="H5分享描述">
          <el-input v-model="form.share_app_content"></el-input>
        </el-form-item>
        <el-form-item label="H5分享图">
          <upload-img
            :disab="disabled"
            ref="share_app_img"
            :limit="1"
            info-text=""
            @getImgs="getfloatimg($event, 'share_app_img')"
          />
        </el-form-item>
        <el-form-item label="小程序分享主题">
          <el-input v-model="form.share_mini_title"></el-input>
        </el-form-item>
        <el-form-item label="小程序分享图">
          <upload-img
            :disab="disabled"
            ref="share_mini_img"
            :limit="1"
            info-text=""
            @getImgs="getfloatimg($event, 'share_mini_img')"
          />
        </el-form-item>
        <el-form-item label="海报">
          <upload-img
            :disab="disabled"
            ref="poster_img"
            :limit="1"
            info-text="（图片尺寸：1080*1728）"
            @getImgs="getfloatimg($event, 'poster_img')"
          />
        </el-form-item>
        <div class="zuobiao" style="display: flex; align-items: center">
          <el-form-item label="海报二维码横坐标">
            <el-input v-model="form.qcode_x" placeholder="默认406"></el-input>
          </el-form-item>
          <el-form-item label="纵坐标">
            <el-input v-model="form.qcode_y" placeholder="默认1020"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="状态">
          <el-select v-model="form.status">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否填写表单">
          <el-select v-model="form.is_form">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <p slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </p>
    </div>
  </el-dialog>
</template>
<script>
  import UploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'add',
    components: {
      UploadImg,
    },
    props: {
      addShow: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '新增',
      },
    },
    data() {
      return {
        form: {
          id: '',
          title: '',
          title_img: '',
          main_img: '',
          award_img: '',
          award_url: '',
          button_img: '',
          bg_color: '',
          poster_img: '',
          qcode_x: '',
          qcode_y: '',
          share_mini_img: '',
          share_mini_title: '',
          share_app_img: '',
          share_app_title: '',
          share_app_content: '',
          status: '1',
          is_form: '0',
        },
        rules: {
          title: [{ required: true, message: '', trigger: 'blur' }],
        },
      }
    },
    watch: {
      addShow(val) {
        if (!val) {
          this.form = {
            id: '',
            title: '',
            title_img: '',
            main_img: '',
            award_img: '',
            award_url: '',
            button_img: '',
            bg_color: '',
            poster_img: '',
            qcode_x: '',
            qcode_y: '',
            share_mini_img: '',
            share_mini_title: '',
            share_app_img: '',
            share_app_title: '',
            share_app_content: '',
            status: '1',
            is_form: '0',
          }

          this.$refs.main_img.img = []
          this.$refs.title_img.img = []
          this.$refs.award_img.img = []
          this.$refs.button_img.img = []
          this.$refs.poster_img.img = []
          this.$refs.share_app_img.img = []
          this.$refs.share_mini_img.img = []
        }
      },
    },
    methods: {
      handleClose() {
        let that = this
        that.addShow = false
      },
      getinfo(id) {
        // 获取详情
        let that = this
        postAction('/api/system/research/conf-info', { id: id }).then((res) => {
          if (res.code == 0) {
            that.form = res.data
            that.form.status = String(res.data.status)
            that.form.is_form = String(res.data.is_form)
            if (res.data.main_img != '') {
              this.$refs.main_img.img = []
              this.$refs.main_img.img.push(res.data.main_img)
            } else {
              this.$refs.main_img.img = []
            }

            if (res.data.title_img != '') {
              this.$refs.title_img.img = []
              this.$refs.title_img.img.push(res.data.title_img)
            } else {
              this.$refs.title_img.img = []
            }

            if (res.data.award_img != '') {
              this.$refs.award_img.img = []
              this.$refs.award_img.img.push(res.data.award_img)
            } else {
              this.$refs.award_img.img = []
            }

            if (res.data.button_img != '') {
              this.$refs.button_img.img = []
              this.$refs.button_img.img.push(res.data.button_img)
            } else {
              this.$refs.button_img.img = []
            }

            if (res.data.poster_img != '') {
              this.$refs.poster_img.img = []
              this.$refs.poster_img.img.push(res.data.poster_img)
            } else {
              this.$refs.poster_img.img = []
            }

            if (res.data.share_app_img != '') {
              this.$refs.share_app_img.img = []
              this.$refs.share_app_img.img.push(res.data.share_app_img)
            } else {
              this.$refs.share_app_img.img = []
            }

            if (res.data.share_mini_img != '') {
              this.$refs.share_mini_img.img = []
              this.$refs.share_mini_img.img.push(res.data.share_mini_img)
            } else {
              this.$refs.share_mini_img.img = []
            }
          }
        })
      },
      getfloatimg(v, prop) {
        let that = this
        console.log(v, prop)
        // this.form.prop = v[0]
        if (v.length == 0) {
          this.$set(this.form, prop, '')
          return
        }
        this.$set(this.form, prop, v[0])
        console.log(this.form)
      },
      submit() {
        let that = this
        if (that.form.title == '') {
          that.$message.error('请输入调查主题')
          that.addShow = true
          return
        }
        if (that.form.qcode_x == '') {
          that.form.qcode_x = '406'
        }
        if (that.form.qcode_y == '') {
          that.form.qcode_y = '1020'
        }
        console.log(that.form)
        // return
        postAction('/api/system/research/conf-edit', that.form).then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '操作成功',
              type: 'success',
            })
            this.$emit('getlist')
            that.addShow = false
          } else {
            that.$message.error(res.msg)
            that.addShow = true
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  :deep() .orderTest-container {
    display: flex;
    align-items: center;
  }
  :deep() .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 115px;
    margin-right: 10px;
  }
  :deep() .imgList .el-image {
    width: 100px !important;
    height: 100px !important;
  }
  .el-input {
    width: 300px;
  }
  .zuobiao .el-input {
    width: 100px;
  }
  .el-select {
    width: 140px;
  }
  .dialog-footer {
    margin-top: 40px;
    margin-left: 130px;
  }
</style>
