var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.showadd,
            width: "650px",
            top: "15vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "220px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题：", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联直播间：", prop: "live_name" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.querySearchAsync,
                          clearable: "",
                          placeholder: "请输入内容",
                          "suffix-icon": "el-icon-arrow-down",
                          "value-key": "title",
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.form.live_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "live_name", $$v)
                          },
                          expression: "form.live_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "banner：", prop: "banner" } },
                    [
                      _c("upload-img", {
                        ref: "uploadImgshare",
                        attrs: {
                          infoText: "尺寸750*220",
                          limit: 1,
                          maxSize: 100,
                        },
                        on: { uploadImg: _vm.uploadImgshare },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "banner显示：", prop: "banner_show" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.banner_show,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "banner_show", $$v)
                          },
                          expression: "form.banner_show",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "背景颜色:", prop: "bg_color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.form.bg_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bg_color", $$v)
                          },
                          expression: "form.bg_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间:", prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间",
                        },
                        model: {
                          value: _vm.form.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间:", prop: "end_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间",
                        },
                        model: {
                          value: _vm.form.end_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "end_time", $$v)
                          },
                          expression: "form.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享图：", prop: "share_img" } },
                    [
                      _c("upload-img", {
                        ref: "uploadImage",
                        attrs: {
                          infoText: "尺寸750*600",
                          limit: 1,
                          maxSize: 100,
                        },
                        on: { uploadImg: _vm.uploadImg },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称1：", prop: "menu1" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        model: {
                          value: _vm.form.menu1,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menu1", $$v)
                          },
                          expression: "form.menu1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称2：", prop: "menu2" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        model: {
                          value: _vm.form.menu2,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menu2", $$v)
                          },
                          expression: "form.menu2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "禁用" },
                          }),
                          _c("el-option", {
                            attrs: { value: 1, label: "启用" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = !_vm.showadd
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }