<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-input placeholder="标题" v-model="form.title" clearable style="width: 220px;"/>
      </el-form-item>
      <el-form-item >
        <el-select placeholder="状态" v-model="form.status" clearable style="width: 220px;">
          <el-option value="1" label="启用"></el-option>
          <el-option value="0" label="禁用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select placeholder="日程日期" v-model="form.date_id" clearable style="width: 220px;">
          <el-option v-for="list in Datelist" :key="list.id" :value="list.id" :label="list.title"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='日期'">
<!--            <span v-for="list in row.business_url">
              <el-image
                style="width: 100px; height: 100px"
                :src="list"
                :preview-src-list="[list]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            </span>-->
            <span>{{ row[item.prop]?row[item.prop].title:'' }}</span>
<!--                <span>{{Datelist.filter(list=>list.id==row.date_id)[0].title}}</span>-->
          </div>
                    <div v-else-if="item.label=='详情页图片'">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row[item.prop]"
                        :preview-src-list="[row[item.prop]]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
          <div v-else-if="item.label=='状态'">
            <p v-show="row.status==1">启用</p>
            <p v-show="row.status==0">禁用</p>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
          <el-popconfirm
            title="确认删除？"
            @confirm="handlerdelete(row.id)"
          >
            <el-button
              slot="reference"
              type="text"
              style="margin-right: 10px"
              size="small" >
              删除
            </el-button>
          </el-popconfirm>

            <el-button
              slot="reference"
              type="text"
              @click="handleredit(row)"
              size="small">
              编辑
            </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <addedit ref="add" :data-list="Datelist" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit },
  data(){
    return{
      form:{
        title:'',
        date_id:'',
        status:'',
      },
      Datelist:[],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "80",
        },

        {
          label: "日期",
          prop: "date",
          width: "",
        },

        {
          label: "标题",
          prop: "title",
          width: "",
        },
        {
          label: "举办时间",
          prop: "time",
          width: "",
        },

        {
          label: "举办地点",
          prop: "address",
          width: "",
        },
        {
          label: "详情页图片",
          prop: "img_detail",
          width: "",
        },{
          label: "会议亮点",
          prop: "meet_light",
          width: "",
        },

        {
          label: "排序",
          prop: "sort",
          width: "",
        },

        {
          label: "状态",
          prop: "status",
          width: "",
        },

      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerSelect()
    this.handlerInquire()
  },
  methods:{
    handleradd(){
      this.$refs.add.showadd=true
    },
    handleredit(row){
      this.$refs.add.handlerinfo(row)
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerSelect(){
      getAction("/api/afbc/date/get-lists").then(res=>{
        this.Datelist=res.data
      })
    },
    handlerdelete(id){
      postAction("/api/afbc/schedule/delete",{id:id}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerlist()
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.limit = val
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/afbc/schedule/index",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    }
  }
};
</script>

<style scoped>

</style>
