<template>
  <div class="index-container">
    <h3>产品热搜榜</h3>
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-button @click="handlerStatis">统计</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlerDelete">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlerInquire">刷新</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 15px; font-size: 16px">
      产品热搜榜开关：
      <el-switch
        v-model="search_goods_rank_door.value"
        :active-value="'1'"
        :inactive-value="'0'"
        active-color="#13ce66"
        inactive-color="#ccc"
        @change="handlerStatus"
      ></el-switch>
    </div>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else-if="item.label == '活动类型'">
            {{ row.type == 1 ? '话题' : '投票' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerdel(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="editposter" @getlist="handlerInquire" />
    <deleteCom ref="deletecom" />
    <statis ref="statis" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Addedit from './components/addedit'
  import deleteCom from './components/deleteCom'
  import statis from './components/statis'

  export default {
    name: 'index',
    components: { Addedit, deleteCom, statis },
    data() {
      return {
        form: {
          type: '',
          status: '', //  1-启用 2-禁用
          is_del: '0',
          title: '',
          page: 1,
          limit: 10,
        },
        search_goods_rank_door: {
          id: '',
          value: '1',
        }, //1 开 0 关
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '排名',
            prop: 'id',
            width: '',
          },
          {
            label: '产品标题',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '品类',
            prop: 'pcat_name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '浏览次数',
            prop: 'click_num',
            width: '',
          },
          {
            label: '搜索次数',
            prop: 'search_num',
            width: '',
          },
          {
            label: '热搜指数',
            prop: 'index_num',
            width: '',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      // 统计
      handlerStatis() {
        this.$refs.statis.isShowStatis = true
        this.$refs.statis.handlerlist()
      },
      // 删除
      handlerDelete() {
        this.$refs.deletecom.isShowDelete = true
        this.$refs.deletecom.handlerlist()
      },
      // 刷新
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // 设置开关
      handlerStatus() {
        postAction('/api/system/conf/set', this.search_goods_rank_door).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            }
          }
        )
      },
      handlerAdd() {
        this.$refs.editposter.showEditPoster = true
      },
      handlerlist() {
        // this.form.search_goods_rank_door = search_goods_rank_door ? 1 : 0
        getAction('/api/form/search-rank/lists', this.form).then((res) => {
          this.tabledata = res.data.list
          this.search_goods_rank_door = res.data.search_goods_rank_door
          console.log(res.data, this.tabledata)
          this.total = Number(res.totalCount)
        })
      },
      handlerdel(row) {
        this.$confirm('是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postAction('/api/form/search-rank/delete', { id: row.id }).then(
            (res) => {
              if (res.request_code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.handlerInquire()
              }
            }
          )
        })
      },
      // 编辑分类
      handleredit(row) {
        this.$refs.editposter.showEditPoster = true
        this.$refs.editposter.handlerinfo(row)
      },

      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
