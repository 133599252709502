var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { border: "1px solid #ccc" },
    },
    [
      _c("Toolbar", {
        staticStyle: { "border-bottom": "1px solid #ccc" },
        attrs: {
          editor: _vm.editor,
          defaultConfig: _vm.toolbarConfig,
          mode: _vm.mode,
        },
      }),
      _c("Editor", {
        staticStyle: { "overflow-y": "hidden" },
        style: "height: " + _vm.height + "px;",
        attrs: {
          defaultContent: _vm.defaultContent,
          defaultConfig: _vm.editorConfig,
          mode: _vm.mode,
        },
        on: { onCreated: _vm.onCreated },
        model: {
          value: _vm.html,
          callback: function ($$v) {
            _vm.html = $$v
          },
          expression: "html",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }