<template>
  <div>
    <el-dialog :title="title" :visible.sync="addkong" width="50%">
      <div>
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="form.title"
              maxlength="20"
              placeholder="最多输入20个字"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="图标" prop="pic">
            <upload-img
              ref="uploadImage"
              info-text="尺寸：120*120"
              :limit="1"
              :max-size="100"
              @uploadImg="uploadImg"
            />
          </el-form-item>
          <el-form-item label="跳转方式" prop="jump_type">
            <el-select
              v-model="form.jump_type"
              clearable
              placeholder="跳转方式"
              style="width: 160px"
              @change="handlerjuptype"
            >
              <el-option
                v-for="list in toSelect"
                :key="list.jump_id"
                :label="list.jump_page"
                :value="list.jump_id"
              />
            </el-select>
          </el-form-item>
<!--          <el-form-item
            v-if="form.jump_type == 3"
            label="输入H5链接"
            prop="jump_object"
          >
            <el-input
              v-model="form.jump_object"
              placeholder="输入H5链接"
              style="width: 160px"
            />
          </el-form-item>-->
           <el-form-item
            v-if="form.jump_type == 12 || form.jump_type == 3|| form.jump_type == 22"
            :label="jump_name"
            prop="jump_object"
          >
            <el-input
              v-model="form.jump_object"
              :placeholder="jump_name"
              style="width: 160px"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 1"
            label="选择店铺"
            prop="jump_object"
          >
            <el-autocomplete
              v-model="form.jump_object_name"
              :fetch-suggestions="handlermell"
              placeholder="请输入店铺名称"
              value-key="mall_name"
              @select="handleSelectmall"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 18"
            label="品类"
            prop="jump_object"
          >
            <el-select
              v-model="form.jump_object"
              clearable
              placeholder="请选择品类"
              style="width: 160px"
            >
              <el-option
                v-for="list in pllist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 10"
            label="选择特色展馆"
            prop="jump_object"
          >
            <el-autocomplete
              v-model="form.jump_object_name"
              :fetch-suggestions="handlerhuose"
              placeholder="请输入特色展馆名称"
              value-key="house_name"
              @select="handleSelecthuose"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 2"
            label="选择商品"
            prop="jump_object"
          >
            <el-autocomplete
              v-model="form.jump_object_name"
              :fetch-suggestions="handlergoods"
              placeholder="请输入商品名称"
              value-key="goods_name"
              @select="handleSelectgoods"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 20"
            label="选择选品日"
            prop="jump_object"
          >
            <el-autocomplete
              v-model="form.jump_object_name"
              :fetch-suggestions="handlerarea"
              placeholder="请输入选品日名称"
              style="width: 160px"
              clearable
              value-key="title"
              @select="handleSelectarea"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 21"
            label="线上品类节"
            prop="jump_object"
          >
            <el-autocomplete
              v-model="form.jump_object_name"
              :fetch-suggestions="handleract"
              placeholder="请输入线上品类节"
              style="width: 160px"
              clearable
              value-key="name"
              @select="handleSelectact"
            />
          </el-form-item>
          <el-form-item
            v-else-if="form.jump_type == 18||form.jump_type == 7"
            label="品类"
            prop="jump_object"
          >
            <!-- @change="handlerjuptype" -->
            <el-select
              v-model="form.jump_object"
              clearable
              placeholder="请选择品类"
              style="width: 160px"
            >
              <el-option
                v-for="list in pllist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            v-else-if="form.jump_type == 5"
            label="身份认证"
            prop="jump_object"
          >
            <el-select
              v-model="form.jump_object"
              clearable
              placeholder="请选择认证类型"
              style="width: 160px"
            >
              <el-option
                v-for="list in renlist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="添加类型" prop="type">
            <el-select
              v-model="form.type"
              clearable
              placeholder="类型"
              style="width: 160px"
            >
              <el-option
                v-for="list in typeSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input
              v-model="form.sort"
              placeholder="排序"
              style="width: 160px"
              type="number"
            />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addkong = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: {
      uploadImg,
    },
    data() {
      var checkjump = (rule, value, callback) => {
        if (this.form.jump_type == 1) {
          if (value == '') {
            callback(new Error('请选择店铺'))
          } else {
            callback()
          }
        } else if (this.form.jump_type == 2) {
          if (value == '') {
            return callback(new Error('请选择产品'))
          } else {
            callback()
          }
        } else if (this.form.jump_type == 10) {
          if (value == '') {
            return callback(new Error('请选择特色展馆'))
          } else {
            callback()
          }
        } else if (this.form.jump_type == 3) {
          if (value == '') {
            return callback(new Error('请输入H5链接'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
      return {
        title: '新增',
        addkong: false,
        jump_name: '',
        form: {
          id: null,
          type: '',
          title: '',
          pic: '',
          jump_type: '',
          jump_object: '',
          jump_object_name: '',
          sort: '',
          status: '1',
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
          pic: [{ required: true, message: '请上传图片', trigger: 'blur' }],
          jump_type: [
            { required: true, message: '请选择跳转方式', trigger: 'blur' },
          ],
          jump_object: [
            { required: true, validator: checkjump, trigger: 'blur' },
          ],
          sort: [
            { required: true, message: '请输入排序顺序', trigger: 'blur' },
          ],
          status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        },
        toSelect: [
          // {
          //   id: '1',
          //   name: '店铺首页'
          // }, {
          //   id: '2',
          //   name: '产品详情页'
          // }, {
          //   id: '3',
          //   name: 'H5链接'
          // }, {
          //   id: '6',
          //   name: '无跳转'
          // }, {
          //   id: '4',
          //   name: '开通店铺'
          // }, {
          //   id: '5',
          //   name: '身份认证'
          // }, {
          //   id: '7',
          //   name: '经销商找品'
          // }, {
          //   id: '8',
          //   name: '云交会名片'
          // }, {
          //   id: '9',
          //   name: '转发统计'
          // }, {
          //   id: '10',
          //   name: '特色展馆'
          // }, {
          //   id: '11',
          //   name: '热品榜'
          // }
        ],
        typeSelect: [
          {
            id: '1',
            name: '企业版',
          },
          {
            id: '2',
            name: '经销商版',
          },
          {
            id: '3',
            name: '综合版',
          },
        ],
        statusSelect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '0',
            name: '禁用',
          },
        ],
        storSelect: [],
        goodsSelect: [],
        pllist: [],
        renlist:[],
      }
    },
    watch: {
      addkong(val) {
        if (!val) {
          console.log()
          this.resetForm()
          this.title = '新增'
          this.$refs.uploadImage.img = []
        }
      },
    },
    mounted() {
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        let typeSelect = await getAction('/audit/advert/jump-type')
        console.log(typeSelect.data)
        this.toSelect = typeSelect.data
        let pinlei = await getAction('/user/index/cate')
        this.pllist = pinlei.data
        let renzheng = await getAction("/audit/identity/cert-tree")
        this.renlist= renzheng.data
      },
      handlerhuose(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/api/index/house', { house_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/index/mall', { mall_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handlerarea(queryString, cb){
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/live/index/search-area', { title: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleract(queryString, cb){
        if (queryString == '') {
          cb([])
          this.form.jump_object = ''
        } else {
          getAction('/api/system/category-act/select', { name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelecthuose(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectarea(list) {
        console.log(list)
        this.form.jump_object = list.id
      },
      handleSelectact(list){
        console.log(list)
        this.form.jump_object = list.id
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.form = {
          id: null,
          type: '',
          title: '',
          pic: '',
          jump_type: '',
          jump_object: '',
          jump_object_name: '',
          sort: '',
          status: '1',
        }
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.pic = ''
        } else {
          this.form.pic = val[0]
        }
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.pic]
        }, 100)
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/index/menu-edit', this.form).then((res) => {
              if (res.request_code == 200) {
                this.addkong = false
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.$emit('getlist')
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handlerjuptype(e) {
        this.form.jump_object=''
        this.form.jump_object_name=''
        // this.jump_name = this.toSelect.filter(
        //   (list) => list.jump_id == e
        // )[0].jump_page

        let data = this.toSelect.filter(
          (list) => list.jump_id == e
        )
        if(data.length>0){
          this.jump_name = this.toSelect.filter(
            (list) => list.jump_id == e
          )[0].jump_page
        }else{
          this.jump_name = ''
        }
      },
    },
  }
</script>

<style scoped></style>
