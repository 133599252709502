<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="65%">
    <vab-query-form>
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
            创建任务
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>
    <el-table v-loading="listLoading" :data="list" border>
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        width="80"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="增加虚拟浏览量"
        prop="num"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="任务模式">
        <template #default="{ row }">
          <span v-if="row.plan_type == 1">单次任务</span>
          <span v-else>分时任务</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="执行模式">
        <template #default="{ row }">
          <span v-if="row.model == 1">立即执行</span>
          <span v-else>延时执行</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="延时时间"
        prop="delay_time"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="任务时长"
        prop="time"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="任务状态">
        <template #default="{ row }">
          <el-tag v-if="row.status == 0" type="warning">待执行</el-tag>
          <el-tag v-if="row.status == 1">执行中</el-tag>
          <el-tag v-if="row.status == 2" type="success">执行完毕</el-tag>
          <el-tag v-if="row.status == 3" type="info">执行终止</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="执行时间"
        prop="execute_time"
        show-overflow-tooltip
      />
      <el-table-column align="center" width="160" label="结束时间">
        <template #default="{ row }">
          <span v-if="row.status == 2">
            {{ row.finish_time }}
          </span>
          <span v-if="row.status == 3">
            {{ row.abort_time }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="created"
        width="160"
      />
      <el-table-column align="center" label="操作" width="150">
        <template #default="{ row }">
          <!-- <el-button
            v-if="row.status == 0 && row.model == 2"
            type="text"
            @click="handleEdit(row)"
          >
            编辑任务
          </el-button> -->
          <el-button
            v-if="row.status == 0 || row.status == 1"
            type="text"
            @click="handleDel(row)"
          >
            终止任务
          </el-button>
          <el-button
            v-if="row.plan_type == 2"
            type="text"
            @click="handlequeueLog(row)"
          >
            队列详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          :src="require('@/assets/empty_images/data_empty.png')"
          class="vab-data-empty"
        />
      </template>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <queueLog ref="queueLog" />
    <edit-live-task ref="editLiveTask" @fetch-data="fetchData" />
  </el-dialog>
</template>
<script>
  // import { delLiveTask, getLiveTaskList } from '@/api/toutiao/live'
  import queueLog from './queueLog'
  import editLiveTask from './editLiveTask'
  import { getAction } from '@/api/request'
  export default {
    name: 'LiveTask',
    components: {
      queueLog,
      editLiveTask,
    },
    data() {
      return {
        list: [],
        title: '任务记录',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          page: 1,
          limit: 10,
          data_id: '',
          task_type: 1,
        },
        mall_name: '',
        views_num: 0,
        virtual_num: 0,
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      handleEdit(row) {
        if (row.id) {
          // row.task_type = this.queryForm.task_type
          // row.data_id = this.queryForm.data_id
          // ;(row.title = this.mall_name),
          //   (row.views_num = this.views_num),
          //   (row.virtual_num = this.virtual_num),
          //   this.$refs['editLiveTask'].showEdit(row)
        } else {
          console.log('=====row,', this.queryForm)
          this.$refs['editLiveTask'].showEdit({
            task_type: this.queryForm.task_type,
            data_id: this.queryForm.data_id,
            title: this.mall_name,
            views_num: this.views_num,
            virtual_num: this.virtual_num,
          })
        }
      },
      handleDel(row) {
        this.$baseConfirm(
          '任务终止后未执行队列将作废，已执行的不受影响，确定要终止吗',
          null,
          async () => {
            const loading = this.$loading({
              lock: true,
              text: '任务终止中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })

            getAction('/api/task/view-plan-task/abort-task', {
              task_id: row.id,
              data_id: row.data_id,
            }).then((res) => {
              this.$baseMessage(
                res.msg,
                res.request_code == 200 ? 'success' : 'error',
                false,
                res.request_code == 200
                  ? 'vab-hey-message-success'
                  : 'vab-hey-message-error'
              )
              if (res.request_code == 200) {
                this.fetchData()
              }
            })
            loading.close()
          }
        )
      },
      handlequeueLog(row) {
        this.$refs.queueLog.showData({
          task_id: row.id,
        })
      },
      async fetchData() {
        // const { data, totalCount } = await getLiveTaskList(this.queryForm)
        getAction('/api/task/view-plan-task/task-list', this.queryForm).then(
          (res) => {
            if (res.code == 0) {
              this.list = res.data.list
              this.total = res.totalCount
              this.listLoading = false
            }
          }
        )
      },
      showData(row) {
        this.dialogFormVisible = true
        this.listLoading = true
        this.queryForm.data_id = row.data_id
        this.queryForm.task_type = row.task_type
        this.mall_name = row.title
        this.views_num = row.views_num
        this.virtual_num = row.virtual_num
        this.fetchData()
      },
    },
  }
</script>
