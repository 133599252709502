var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerlist } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleradd } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tabledata },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "ID",
                      align: "center",
                      width: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "标题",
                      align: "center",
                      width: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pic", align: "center", label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-image",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src: row.pic,
                                  "preview-src-list": [row.pic],
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder",
                                  },
                                  [
                                    _vm._v(" 加载中"),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("..."),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sort_order",
                      label: "排序",
                      align: "center",
                      width: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerdelete(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { "text-align": "center", "margin-top": "10px" },
                attrs: {
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("add", { ref: "add", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }