<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 210px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 210px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row.img]"
              :src="row.img"
              style="width: 70px; height: 30px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '状态'">
            <span v-show="row[item.prop] == 1">启用</span>
            <span v-show="row[item.prop] == 2">禁用</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="handlerdelete(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="addedit" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import AddEdit from './components/AddEdit'
  export default {
    components: { AddEdit },
    data() {
      return {
        form: {
          company_id: '',
          mall_id: '',
          company_name: '',
          mall_name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司',
            prop: 'company_name',
            width: '160',
          },
          {
            label: '排序',
            prop: 'sort',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
        ],
        total: 0,
        typeSelect: [],
        toSelect: [],
      }
    },
    async mounted() {
      await this.handlerInquire()
    },
    methods: {
      async handlerSelect() {
        await getAction('/audit/identity/identity').then((res) => {
          this.identitySelect = res.data
        })
        await getAction('/audit/advert/position').then((res) => {
          this.typeSelect = res.data
        })
        let typeSelect = await getAction('/audit/advert/jump-type')
        console.log(typeSelect.data)
        this.toSelect = typeSelect.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.addedit.showedit = true
      },
      handlerlist() {
        getAction('/mall/plate-product/mall-list', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/mall/plate-product/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleredit(row) {
        console.log(row)
        this.$refs.addedit.showedit = true
        this.$refs.addedit.handlerinfo(row)
        this.$refs.addedit.title = '编辑'
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
