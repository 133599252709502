<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="店铺名称" clearable v-model="form.mall_name" style="width: 210px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="公司名称" clearable v-model="form.company_name" style="width: 210px;"/>
      </el-form-item>

      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>


    </el-form>
    <el-row :gutter="10" >
     <el-col :span="6"><h3>总邀请人数：{{click_num}}</h3></el-col>
      <el-col :span="6"><h3>总报名成功人数：{{form_num}}</h3></el-col>
      <el-col :span="6"><h3>总签到成功人数：{{sign_num}}</h3></el-col>
    </el-row>
    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='广告图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row.img"
              :preview-src-list="[row.img]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label=='已使用'">
            {{row.total_num-row.last_num}}
          </div>
          <div v-else-if="item.label=='状态'">
            <span v-show="row[item.prop]==1">有效</span>
            <span v-show="row[item.prop]==2">失效</span>
            <span v-show="row[item.prop]==3">已过期</span>
            <span v-show="row[item.prop]==4">已完成</span>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
export default {
  data(){
    return {
      form: {
        company_name:'',
        mall_name:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      time:[],
      columns: [
        {
          label: "店铺名称",
          prop: "mall_name",
          width: "",
        },

        {
          label: "公司名称",
          prop: "company_name",
          width: "",
        },
        {
          label: "邀请链接点击人数",
          prop: "click_num",
          width: "",
        },
        {
          label: "报名成功人数",
          prop: "form_num",
          width: "",
        },
        {
          label: "签到成功人数",
          prop: "sign_num",
          width: "",
        }
      ],
      total: 0,
      typeSelect:[],
      toSelect:[],
      click_num:0,
      form_num:0,
      sign_num:0
    }
  },
  async mounted() {
    await this.handlerInquire()
  },
  methods:{

    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },

    handlerlist(){
      getAction("/api/form/form/afbc-lists",this.form).then(res=>{
        this.tabledata=res.data.list
        this.total= Number(res.totalCount)
        this.click_num=res.data.click_num,
          this.form_num=res.data.form_num,
          this.sign_num=res.data.sign_num
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
