var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品名称" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "300px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  placeholder: "请输入产品名称",
                  "value-key": "goods_name",
                },
                on: { select: _vm.handleSelectmall },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
              _c("span", [_vm._v("可输入关键词模糊搜索")]),
            ],
            1
          ),
          _vm.pinlei_name.length > 0
            ? _c("el-form-item", { attrs: { label: "品类" } }, [
                _vm._v(" " + _vm._s(_vm.pinlei_name) + " "),
              ])
            : _vm._e(),
          _vm.pinlei_name.length > 0
            ? _c("el-form-item", { attrs: { label: "店铺名称" } }, [
                _vm._v(" " + _vm._s(_vm.mall_name) + " "),
              ])
            : _vm._e(),
          _vm.pinlei_name.length > 0
            ? _c("el-form-item", { attrs: { label: "企业名称" } }, [
                _vm._v(" " + _vm._s(_vm.company_name) + " "),
              ])
            : _vm._e(),
          _vm.pinlei_name.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "封面图" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.coverimg },
                  }),
                  _c("span", [_vm._v("默认显示封面图")]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                attrs: { label: "排序", min: 0 },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
              _c("span", [
                _vm._v(
                  " 默认后上传的排在上边。支持手动排序，数字小的在前，大的在后。最小的有效数字为1。 "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.mealselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  format: "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间",
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.form.created_txt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "created_txt", $$v)
                  },
                  expression: "form.created_txt",
                },
              }),
              _c("span", [_vm._v("默认为当前时间，可修改")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }