export function base64ToFile(data) {
  // 将base64 的图片转换成file对象上传 atob将ascii码解析成binary数据
  let binary = atob(data.split(',')[1]);
  let mime = data.split(',')[0].match(/:(.*?);/)[1];
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  let fileData = new Blob([new Uint8Array(array)], {
    type: mime,
  });
  let file = new File([fileData], `${new Date().getTime()}.png`, {
    type: mime
  });
  return file;

}


export function FileToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    let fileResult = ""
    reader.readAsDataURL(file)
    //开始转
    reader.onload = function () {
      fileResult = reader.result
    }
    //转 失败
    reader.onerror = function (error) {
      reject(error)
    }
    //转 结束  咱就 resolve 出去
    reader.onloadend = function () {
      resolve(fileResult)
    }
  })
}
