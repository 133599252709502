var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.show, width: "1200px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  sortable: item.sort,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          item.label == "图片"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "大商凭证"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "会议截图"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "类型"
                            ? _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")])
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }