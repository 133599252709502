<template>
  <el-dialog
    title="预览"
    :visible.sync="showDialog"
    width="540px"
    top="19vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <div class="wrap">
        <div class="header">
          <div class="logo">
            <el-image
              style="width: 45px; height: 45px"
              src="https://res.httoutiao.com/%E4%BA%91%E4%BA%A4%E4%BC%9Alogo.png"
            ></el-image>
            <div class="title">华糖云交会</div>
          </div>
          <div class="time">{{ nowTime }}</div>
        </div>
        <div class="content">
          <span class="tit">【{{ form.title }}】</span><span>{{ form.content }}</span
          ><span style="margin-left: 5px; text-decoration: underline; color: #409eff">{{
            form.button_title
          }}</span>
        </div>
      </div>
      <div style="margin-top: 10px; text-align: center">
        <el-button @click="showDialog = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { dateForm } from "@/utils/time.js"
export default {
  name: "Preview",
  components: {},
  data() {
    return {
      showDialog: false,
      form: {},
    }
  },
  created() {},
  mounted() {},
  computed: {
    nowTime() {
      if (this.form.send_type == 1) {
        return dateForm(new Date())
      } else {
        return this.form.send_time
      }
    },
  },
  watch: {
    showDialog(v) {},
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px;
  background-color: #f1eff2;
}
.header,
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 20px;
    color: rgb(59, 66, 68);
    margin-left: 10px;
  }
  .time {
    font-size: 14px;
    color: rgb(59, 66, 68);
  }
}
.content {
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 1.5;
  margin-top: 5px;
  margin-left: 55px;
  .tit {
    color: #f60;
    margin-right: 5px;
  }
}
</style>
