<template>
  <div class="index-container">
    <el-form :modal="form" inline>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新增</el-button>
      </el-form-item>
      <el-form-item label="标题：">
        <el-input placeholder="标题" v-model="form.title"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" v-loading="loading" >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template v-if="item.label=='banner图'" #default="{row}">
          <el-image
            v-if="row.banner"
            style="width: 150px; height: 70px"
            :src="row.banner"
            :preview-src-list="[row.banner]">
          </el-image>
        </template>
        <template v-else-if="item.label=='分享图'" #default="{row}">
          <el-image
            v-if="row[item.prop]"
            style="width: 80px; height: 80px"
            :src="row[item.prop]"
            :preview-src-list="[row[item.prop]]">
          </el-image>
        </template>

        <template v-else-if="item.label=='操作'" #default="{row}">

          <el-button type="text" @click="handlerEdit(row)">编辑</el-button>
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handlerdelet(row)"
          >
            <el-button style="margin:0px 10px" slot="reference" type="text">删除</el-button>
          </el-popconfirm>
          <el-button type="text"  @click="handlerview(row)">栏目设置</el-button>
          <el-button type="text"  @click="handlerstatus(row)">小程序码</el-button>
        </template>
        <template v-else #default="{row}">
          {{row[item.prop]}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add-edit ref="addedit" :title="title" @getlist="handlerInquire"></add-edit>
    <listinfo ref="listinfo"/>
    <el-dialog
      title="小程序码"
      :visible.sync="qrcodeshow"
      width="30%"
      center>
      <div style="display: flex;align-items: center;justify-content: center">
        <el-image
          style="width: 180px; height: 180px"
          :src="qrcode"
          :preview-src-list="[qrcode]">
        </el-image>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrcodeshow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

import AddEdit from "./components/AddEdit"
import Listinfo from "./components/columnlist"
export default {
  name: "index",
  components:{
    // Videolist,
    Listinfo,
    AddEdit
  },
  data(){

    return{
      loading:false,
      qrcode:'',
      qrcodeshow:false,
      form:{
        page: 1,
        limit: 10,
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata:[],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "60px",
        },
        {
          label: "标题",
          prop: "title",
          width: "",
        },
        {
          label: "关联直播间",
          prop: "live_name",
          width: "220px",
        },
        {
          label: "banner图",
          prop: "banner",
          width: "180px",
        },
        {
          label: "背景色",
          prop: "bg_color",
          width: "160px",
        },{
          label: "开始时间",
          prop: "start_time",
          width: "160px",
        },

        {
          label: "结束时间",
          prop: "end_time",
          width: "160px",
        },
        {
          label: "状态",
          prop: "status_txt",
          width: "",
        },
        {
          label: "分享图",
          prop: "share_img",
          width: "110px",
        },{
          label: "banner隐藏开关",
          prop: "banner_show_txt",
          width: "120px",
        },
        // {
        //   label: "小程序码接口地址",
        //   prop: "mini_qrcode",
        //   width: "120px",
        // },
        {
          label: "操作",
          prop: "sort_order",
          width: "220px",
        },

      ],
      total: 0,
      title:'新增',
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handlerInquire(){
      this.loading=true
      this.form.page=1
      this.handlerlist()
    },
    handleradd(){
      this.$refs.addedit.showadd=true
      this.title="新增"
    },
    handlerlist(){
      getAction("/live/index/area-lists",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
        this.loading=false
      })
    },
    handlerEdit(row){
      this.$refs.addedit.showadd=true
      this.title="编辑"
      this.$refs.addedit.handlerinfo(row)
    },
    handlerview(row){
      this.$refs.listinfo.form.area_id=row.id
      this.$refs.listinfo.handlerlist()
      this.$refs.listinfo.showvideo=true
    },
    handlerstatus(row){
      // getAction(row.mini_qrcode,{}).then(res=>{
      //   console.log(res.data.info.qrcode)
        this.qrcode=row.mini_qrcode
        this.qrcodeshow=true
      // })
    },
    handlerdelet(row){
      postAction("/live/index/del-arealive",{id:row.id}).then(res=>{
        this.$message.success(res.msg)
        this.handlerlist()
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
