var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showadd,
        "append-to-body": true,
        width: "650px",
        top: "15vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "220px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "栏目标题模板：", prop: "title_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      on: { change: _vm.handlertitletype },
                      model: {
                        value: _vm.form.title_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "title_type", $$v)
                        },
                        expression: "form.title_type",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "仅主题", value: "1" } },
                        [_vm._v("仅主题")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "主标题和副标题", value: "2" } },
                        [_vm._v("主标题和副标题")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "图片", value: "3" } },
                        [_vm._v("图片")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.title_type == 1 || _vm.form.title_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "栏目主标题：", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { maxlength: "30", clearable: "" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.title_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "栏目副标题：", prop: "subtitle" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { maxlength: "30", clearable: "" },
                        model: {
                          value: _vm.form.subtitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subtitle", $$v)
                          },
                          expression: "form.subtitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.title_type == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "栏目标题图片：", prop: "title_pic" } },
                    [
                      _c("upload-img", {
                        ref: "uploadImgpic",
                        attrs: {
                          imgstyle:
                            "width: 100px; height: 100px; margin-right: 10px",
                          infoText: "",
                          limit: 1,
                          maxSize: 100,
                        },
                        on: { uploadImg: _vm.uploadImg },
                      }),
                      _c("p", [_vm._v("尺寸: 750*120")]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: { label: "栏目内容排列方式：", prop: "display_type" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.display_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "display_type", $$v)
                        },
                        expression: "form.display_type",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "一行四列", value: "1" } },
                        [_vm._v("一行四列")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "一行三列", value: "2" } },
                        [_vm._v("一行三列")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "一行两列", value: "3" } },
                        [_vm._v("一行两列")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "一行一列", value: "4" } },
                        [_vm._v("一行一列")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联类型：", prop: "content_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.content_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "content_type", $$v)
                        },
                        expression: "form.content_type",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "产品", value: "1" } },
                        [_vm._v("产品")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "店铺", value: "2" } },
                        [_vm._v("店铺")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "政策大放送", value: "3" } },
                        [_vm._v("政策大放送")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "主题活动", value: "4" } },
                        [_vm._v("主题活动")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序：", prop: "sort_order" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "", min: "0" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "1", label: "禁用" } }),
                      _c("el-option", { attrs: { value: "0", label: "启用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = !_vm.showadd
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }