<template>
  <el-dialog
    title="关联数据"
    :visible.sync="secondaryshow"
    width="70%"
    append-to-body>
    <div>
      <el-form ref="form" :model="form" label-suffix=":" inline>
        <el-form-item >
          <el-input placeholder="名称" clearable v-model="form.title" style="width: 160px;"/>
        </el-form-item>
<!--        <el-form-item >
          <el-select v-model="form.type" placeholder="关联类型" clearable  style="width: 160px;">
            <el-option label="产品" value="1"/>
            <el-option label="店铺" value="2"/>
          </el-select>
        </el-form-item>-->
        <el-form-item >
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item >
                    <el-button type="primary" @click="handlerAdd">新 增</el-button>
        </el-form-item>
      </el-form>

      <el-table
        border
        :data="tabledata"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{row}">
            <div v-if="item.label=='底部logo'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label=='小程序分享图'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label=='H5分享图'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else>{{row[item.prop]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="280">
          <template  #default="{row}">

            <el-button
              @click.native.prevent="handlerdelete(row)"
              type="text"
              size="small">
              删除
            </el-button>
            <el-button
              @click.native.prevent="handlersecondary(row)"
              type="text"
              size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <el-dialog
        title="添加"
        :visible.sync="showadd"
        width="300px"
        append-to-body
        :modal-append-to-body="false">
        <div>
          <el-form ref="form" :model="formtow" :rules="rules" label-width="80px">
            <el-form-item label="选择产品" prop="data_id" v-if="type==1">
              <el-autocomplete
                v-model="formtow.name"
                clearable
                :fetch-suggestions="handlergoods"
                value-key="goods_name"
                placeholder="请输入商品名称"
                @select="handleSelectgoods"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="选择店铺" prop="data_id" v-else-if="type==2">
              <el-autocomplete
                v-model="formtow.name"
                clearable
                :fetch-suggestions="handlermell"
                value-key="mall_name"
                placeholder="请输入店铺名称"
                @select="handleSelectmall"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="排序" prop="sort_order" >
              <el-input v-model="formtow.sort_order" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showadd = false">取 消</el-button>
          <el-button type="primary" @click="handlersave">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="secondaryshow = false">取 消</el-button>
    <el-button type="primary" @click="secondaryshow = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "association",
  data(){
    var handlerobject=(rule, value, callback)=>{
      if(this.type==1){
        if(!value){
          callback(new Error("请选择产品"))
        }else{
          callback()
        }
      }else{
        if(!value){
          callback(new Error("请选择店铺"))
        }else{
          callback()
        }
      }
    };
    return{
      secondaryshow:false,
      showadd:false,
      formtow:{
        id:0,
        sort_order:'',
        name:'',
        data_id:''
      },
      rules:{
        data_id:[
          { required: true,validator:handlerobject,  trigger: 'change' }
        ]
      },
      form:{
        menuid:'',
        page: 1,
        limit: 10
      },
      type:'',
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "id",
          prop: "id",
          width: "",
        },

        {
          label: "名称",
          prop: "ref_name",
          width: "",
        },

        {
          label: "排序",
          prop: "sort_order",
          width: "",
        },

      ],
      total: 0,
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.formtow={
          id:0,
          sort_order:'',
          name:'',
          data_id:''
        }
      }
    }
  },
  methods:{
    handlerAdd(){
      this.showadd=true
    },
    handlersave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          postAction("/api/theme/ref/edit", { ...this.formtow,menuid:this.form.menuid }).then(res => {
            this.$message.success(res.msg)
            this.showadd = false
            this.handlerInquire()

          })
        }
      })
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.formtow.data_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.formtow.data_id=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.formtow.data_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.formtow.data_id=list.id
    },


    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerdelete(row){
      postAction("/api/theme/ref/delete",{id:row.id,menuid:this.form.menuid}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerInquire()
      })
    },
    handlersecondary(row){
      this.showadd=true
      this.formtow=Object.assign(this.formtow,row)
      this.formtow.name=row.ref_name
      if(row.type==1){
        this.formtow.data_id=row.goods_id
      }else{
        this.formtow.data_id=row.mall_id
      }
    },
    handlerlist(){
      getAction("/api/theme/ref/lists",this.form).then(res=>{
        this.tabledata=res.data.list
        this.type=res.data.type
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    show(){
      this.secondaryshow=true
    },
    close(){
      this.secondaryshow=false
      this.form={
        menuid:'',
        page: 1,
        limit: 10
      }
    }
  }
};
</script>

<style scoped>

</style>
