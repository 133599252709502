<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="姓名" clearable v-model="form.realname" style="width: 210px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="昵称" clearable v-model="form.nickname" style="width: 210px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="电话" clearable v-model="form.phone" style="width: 210px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='广告图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row.img"
              :preview-src-list="[row.img]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label=='已使用'">
            {{row.total_num-row.last_num}}
          </div>
          <div v-else-if="item.label=='状态'">
            <span v-show="row[item.prop]==1">有效</span>
            <span v-show="row[item.prop]==2">失效</span>
            <span v-show="row[item.prop]==3">已过期</span>
            <span v-show="row[item.prop]==4">已完成</span>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "index",
  data(){
    return {
      form: {
        realname:'',
        nickname:'',
        phone:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "",
        },

        {
          label: "姓名",
          prop: "realname",
          width: "",
        },
        {
          label: "昵称",
          prop: "nickname",
          width: "",
        },
        {
          label: "电话",
          prop: "phone",
          width: "",
        },
        {
          label: "积分",
          prop: "integral",
          width: "",
        }
      ],
      total: 0,
    }
  },
  async mounted() {
    await this.handlerInquire()
  },
  methods:{

    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },

    handlerlist(){
      postAction("/api/system/integral/rand-list",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
