var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.showinfo,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showinfo = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "130px",
                    "label-suffix": "：",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "公司名称" } }, [
                    _vm._v(_vm._s(_vm.form.company_name)),
                  ]),
                  _c("el-form-item", { attrs: { label: "店铺名称" } }, [
                    _vm._v(_vm._s(_vm.form.mall_name)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营业执照" } },
                    [
                      _vm.form.business_url
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.business_url,
                                "preview-src-list": [_vm.form.business_url],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业员工身份证明材料" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                          attrs: { gutter: 10 },
                        },
                        _vm._l(
                          _vm.form.employee_materials_url,
                          function (list, index) {
                            return _c(
                              "span",
                              { key: index },
                              [
                                list
                                  ? _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          "preview-src-list": [list],
                                          src: list,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "佐证材料" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                          attrs: { gutter: 10 },
                        },
                        _vm._l(_vm.form.evidence_url, function (list, index) {
                          return _c(
                            "span",
                            { key: index },
                            [
                              list
                                ? _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        "preview-src-list": [list],
                                        src: list,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "行业分类" } }, [
                    _vm._v(_vm._s(_vm.form.trade_txt)),
                  ]),
                  _c("el-form-item", { attrs: { label: "主营品牌" } }, [
                    _vm._v(_vm._s(_vm.form.brand)),
                  ]),
                  _c("el-form-item", { attrs: { label: "认证标签" } }, [
                    _vm._v(_vm._s(_vm.form.tags)),
                  ]),
                  _c("el-form-item", { attrs: { label: "申请人姓名" } }, [
                    _vm._v(_vm._s(_vm.form.apply_name)),
                  ]),
                  _c("el-form-item", { attrs: { label: "申请人职务" } }, [
                    _vm._v(_vm._s(_vm.form.apply_job)),
                  ]),
                  _c("el-form-item", { attrs: { label: "主管理员手机号" } }, [
                    _vm._v(_vm._s(_vm.form.mobile)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺logo" } },
                    [
                      _vm.form.logo
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.logo,
                                "preview-src-list": [_vm.form.logo],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "所在地址" } }, [
                    _vm._v(_vm._s(_vm.form.address)),
                  ]),
                  _c("el-form-item", { attrs: { label: "创立时间" } }, [
                    _vm._v(_vm._s(_vm.form.created_time)),
                  ]),
                  _c("el-form-item", { attrs: { label: "工厂面积" } }, [
                    _vm._v(_vm._s(_vm.form.plant_area)),
                  ]),
                  _c("el-form-item", { attrs: { label: "员工人数" } }, [
                    _vm._v(_vm._s(_vm.form.staffs_number)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "授权书/合作协议" } },
                    [
                      _vm.form.agreement_url
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.agreement_url,
                                "preview-src-list": [_vm.form.agreement_url],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商标注册证/商标注册申请受理通知" } },
                    [
                      _vm.form.trademark_url
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.trademark_url,
                                "preview-src-list": [_vm.form.trademark_url],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "食品经营许可证" } },
                    [
                      _vm.form.food_blicense_url
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.food_blicense_url,
                                "preview-src-list": [
                                  _vm.form.food_blicense_url,
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "食品生产许可证" } },
                    [
                      _vm.form.food_plicense_url
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.form.food_plicense_url,
                                "preview-src-list": [
                                  _vm.form.food_plicense_url,
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _vm._v(" 加载中 "),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("..."),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "样板市场" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.model_market) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业介绍" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.introduction) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌荣誉" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.honors) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "荣获专利" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: { innerHTML: _vm._s(_vm.form.patent) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "扶持政策" } },
                    [
                      _c("el-input", {
                        staticStyle: { overflow: "auto" },
                        attrs: { type: "textarea", rows: 6 },
                        domProps: {
                          innerHTML: _vm._s(_vm.form.supportive_policy),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.status != 1,
                      expression: "form.status != 1",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(_vm.form, 3)
                    },
                  },
                },
                [_vm._v(" 拒绝 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.status != 1,
                      expression: "form.status != 1",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(_vm.form, 1)
                    },
                  },
                },
                [_vm._v(" 通过 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }