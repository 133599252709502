<template>
  <div class="index-container">
    <el-row>
      <el-col :span="8">
        <el-table
          :data="tablelist"
          border
          style="width: 100%">
          <el-table-column
            prop="title"
            label="名称"
            width="">
          </el-table-column>
          <el-table-column
            prop="name"
            label="操作"
            width="">
            <template #default="{row}">
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog
      title="编辑"
      :visible.sync="showedit"
      width="30%">
      <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="展示内容">
              <wang-editor ref="wangcon" :height="300" :upload-img-size="300"/>
            </el-form-item>
          <el-form-item label="服务协议">
            <wang-editor ref="wangxie" :height="300" :upload-img-size="300"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import WangEditor from "@/components/WangEditor";

export default {
  name: "index",
  components: { WangEditor },
  data(){
    return{
      tablelist:[],
      showedit:false,
      form:{}
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleredit(row){
      this.showedit=true
      this.form=JSON.parse(JSON.stringify(row))
      setTimeout(()=>{
        this.$refs.wangcon.editor.setHtml(row.content)
        this.$refs.wangxie.editor.setHtml(row.agreement)
      },100)


    },
    handlerlist(){
      getAction("/mall/index/meal-list").then(res=>{
        this.tablelist=res.data
      })
    },
    handlersave(){
      this.form.content=this.$refs.wangcon.getHtmlM()
      this.form.agreement=this.$refs.wangxie.getHtmlM()
      postAction("/mall/index/edit-meal",this.form).then(res=>{
        console.log(res)
        this.$message({
          type:'success',
          message:res.msg
        })
        this.showedit=false
        this.handlerlist()
      }).catch(err=>{
        console.error(err)
      })
    }
  }
};
</script>

<style scoped>

</style>
