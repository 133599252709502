var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "报告标题" },
                model: {
                  value: _vm.form.report_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "report_title", $$v)
                  },
                  expression: "form.report_title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "报告类型",
                  },
                  model: {
                    value: _vm.form.report_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "report_type", $$v)
                    },
                    expression: "form.report_type",
                  },
                },
                _vm._l(_vm.staffSelect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      item.label == "总访问次数"
                        ? _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "用户访问报告的次数，同一用户访问N次记录N次，即PV数"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "总访问次数 = 所有邀请人邀请来的PV之和"
                                      ),
                                    ]
                                  ),
                                  _c("i", { staticClass: "el-icon-warning" }),
                                ]
                              ),
                            ],
                            1
                          )
                        : item.label == "总访问人数"
                        ? _c(
                            "div",
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "访问报告的用户人数，同一用户访问N次只记录1人，即UV数"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "总访问人数（去重数据） ≠ 所有邀请人邀请来的UV之和"
                                      ),
                                    ]
                                  ),
                                  _c("i", { staticClass: "el-icon-warning" }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v(" " + _vm._s(item.label) + " ")]),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "海报背景"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-image",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "70px",
                                  },
                                  attrs: {
                                    "preview-src-list": [row[item.prop]],
                                    src: row[item.prop],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "placeholder" },
                                      slot: "placeholder",
                                    },
                                    [
                                      _vm._v(" 加载中 "),
                                      _c("span", { staticClass: "dot" }, [
                                        _vm._v("..."),
                                      ]),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : item.label == "操作"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerinfo(row)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("report-info", { ref: "info" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }