<script>
import { getAction } from "@/api/request";

export default {
  name: "guanggao",
  data() {
    return {
      dialogVisible: false,
      tableData: []
    }
  },
  methods: {
   async handlerlist(id) {
     console.log(id)
     await getAction("/mall/setmeal/advert-exposure", {
        id: id
      }).then(res => {
        this.tableData = res.data
        this.dialogVisible = true
      })
    }
  }
};
</script>

<template>
<div>
  <el-dialog
    title="广告详情"
    :visible.sync="dialogVisible"
    width="30%">
    <span>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="position_name"
          label="广告位"
          align="center">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center">
        </el-table-column>
        <el-table-column
          prop="type"
          label="已完成/总量"
          align="center">
          <template slot-scope="scope">
            {{scope.row.advert_exposure_num}}/{{scope.row.advert_max_exposure_num}}
          </template>
        </el-table-column>
      </el-table>
    </span>
  </el-dialog>
</div>
</template>

<style scoped>

</style>
