<!--专区-->
<template>
  <el-dialog
    title="关联直播间"
    :visible.sync="zoneshow"
    append-to-body
    width="80%">
    <div>
      <el-form ref="form" :model="form" label-width="80px" inline>
        <el-form-item>
          <el-button type="primary" @click="handlerAdd">新 增</el-button>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.live_name"
            clearable
            placeholder="直播名称"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tabledata"
                style="width: 100%"
                ref="ElTable">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '类型'">
              {{row[item.prop]==1?'春糖':'秋糖'}}
            </div>
            <div v-else-if="item.label == '状态'">
              {{ row[item.prop] == 1 ? '开启' : '关闭' }}
            </div>
            <div v-else-if="item.label == '报名跳转图片'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else-if="item.label == '列表分享图'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template #default="{ row }">
            <el-button
              size="small"
              style="margin-left: 10px"
              type="text"
              @click.native.prevent="handleredit(row)"
            >
              编辑
            </el-button>
            <el-button
              size="small"
              style="margin-left: 10px"
              type="text"
              @click.native.prevent="handlerdelete(row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <add-live ref="addzone" @handlerlist="handlerInquire"/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="zoneshow = false">取 消</el-button>
      <el-button type="primary" @click="zoneshow = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddLive from "./components/AddLive";

export default {
  name: "index",
  components: { AddLive },
  data(){
    return{
      zoneshow:false,
      form: {
        conf_id:'',
        live_name:'',
        limit: 10,
        page: 1,
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: '直播间名称',
          prop: 'live_name',
          width: '',
          align:'center'
        },

      ],
      total: 0,
    }
  },watch:{
    zoneshow(val){
      if(!val){
        this.form={
          conf_id:'',
          live_name:'',
          limit: 10,
          page: 1,
        }
      }
    }
  },
  methods:{
    handlerAdd(){
      this.$refs.addzone.form.conf_id=this.form.conf_id
      this.$refs.addzone.showadd=true
    },
    handleredit(row){
      this.$refs.addzone.handlerinfo(row)
      this.$refs.addzone.showadd=true
    },
    handlerdelete(row){
      this.$confirm('此操作将删除该直播间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/hotel/live/delete",{id:row.id}).then(res=>{
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/hotel/live/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }

};
</script>

<style scoped>

</style>
