<template>
  <div>
    <el-dialog :title="title" :visible.sync="showEdit" width="50%">
      <div>
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="" style="width: 220px" />
          </el-form-item>
          <el-form-item label="海报背景" prop="bg_pic">
            <upload-img
              ref="uploadImage"
              info-text="图片格式： jpg、png"
              :limit="1"
              :max-size="100"
              @uploadImg="uploadImg"
            />
          </el-form-item>
          <el-form-item label="排序" prop="sort_order">
            <el-input
              v-model="form.sort_order"
              placeholder="排序"
              style="width: 160px"
              type="number"
            />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEdit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import uploadImg from '@/components/uploadImg'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: {
      uploadImg,
    },
    data() {
      return {
        title: '新增',
        showEdit: false,
        form: {
          id: null,
          name: '',
          bg_pic: '',
          sort_order: '0',
          status: '1', // 1-启用 0-禁用
        },
        rules: {
          name: [{ required: true, message: '请填写名称', trigger: 'blur' }],
          sort_order: [
            { required: true, message: '请填写排序', trigger: 'blur' },
          ],
          bg_pic: [{ required: true, message: '请上传海报', trigger: 'blur' }],
          status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        },
        statusSelect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '0',
            name: '禁用',
          },
        ],
        storSelect: [],
        goodsSelect: [],
      }
    },
    watch: {
      showEdit(val) {
        if (!val) {
          this.resetForm()
          this.$refs.uploadImage.img = []
          this.title = '新增'
        }
      },
    },
    mounted() {
      // this.handlerSelect()
    },
    methods: {
      resetForm() {
        this.$refs.form.resetFields()
        this.form = {
          id: null,
          name: '',
          bg_pic: '',
          sort_order: '0',
          status: '1',
        }
      },
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.bg_pic = ''
        } else {
          this.form.bg_pic = val[0]
        }
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.bg_pic]
        }, 100)
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/system/product-poster/edit', this.form).then(
              (res) => {
                if (res.request_code == 200) {
                  this.showEdit = false
                  this.$message({
                    type: 'success',
                    message: res.msg,
                  })
                  this.$emit('getlist')
                }
              }
            )
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
