<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item >
        <el-select v-model="form.depart_id" clearable filterable style="width: 160px;" placeholder="部门">
          <el-option v-for="list in deptSelect"
                     :key="list.htyun_dept"
                     :value="list.htyun_dept"
                     :label="list.htyun_dept"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.inviter_name" clearable filterable style="width: 160px;" placeholder="邀请人">
          <el-option v-for="list in staffSelect"
                     :key="list.htyun_name"
                     :value="list.htyun_name"
                     :label="list.htyun_name"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          style="width: 340px;"
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          :default-time="['12:00:00']">
        </el-date-picker>
      </el-form-item>

      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>

    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='公司名称'">
            {{row.mall[item.prop]}}
          </div>
          <div v-else-if="item.label=='订单内容'">
            {{row.meal[item.prop]}}
          </div>
          <div v-else-if="item.label=='店铺名称'">
            {{row.mall[item.prop]}}
          </div>
          <div v-else-if="item.label=='状态'">
            <p v-show="row.status==0">待支付</p>
            <p v-show="row.status==1">已支付</p>
            <p v-show="row.status==2">已取消</p>
          </div>
          <div v-else-if="item.label=='订单类型'">
            <el-tag v-show="row[item.prop]==1">前台购买</el-tag>
            <el-tag v-show="row[item.prop]==2" type="success">后台添加</el-tag>
          </div>

          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
export default {
  name: "index",
  data(){
    return {
      form: {
        inviter_name:'',
        start_time: '',
        end_time:'',
        depart_id: '',
        page: 1,
        limit: 10
      },
      time:[],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "部门",
          prop: "htyun_dept",
          width: "",
        },

        {
          label: "邀请人",
          prop: "htyun_name",
          width: "",
        },

        {
          label: "已提交店铺数量",
          prop: "submit_num",
          width: "",
        },
        {
          label: "店铺审核通过数量",
          prop: "check_num",
          width: "",
        },
        {
          label: "店铺审核拒绝数量",
          prop: "refuse_num",
          width: "",
        },
        {
          label: "店铺待审核数量",
          prop: "wait_num",
          width: "",
        },
        {
          label: "已上传产品店铺数量",
          prop: "goods_num",
          width: "",
        }
      ],
      deptSelect: [],
      staffSelect: [],
      total: 0,
    }
  },
  watch:{
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ""
        this.form.end_time = ""
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
  },
  methods:{
   async handlerSelect(){
      const dept =await getAction("/form/form/dept")
     this.deptSelect=dept.data
     const staff =await getAction("/form/form/staff")
     this.staffSelect=staff.data
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/form/form/invite-mall-lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
