var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showadd,
        "destroy-on-close": "",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                "label-suffix": "：",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "展馆名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "展馆名称",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.form.house_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "house_name", $$v)
                      },
                      expression: "form.house_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目1" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "栏目1",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.form.house_column1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "house_column1", $$v)
                      },
                      expression: "form.house_column1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目2" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "栏目2",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.form.house_column2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "house_column2", $$v)
                      },
                      expression: "form.house_column2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目3" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "栏目3",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.form.house_column3,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "house_column3", $$v)
                      },
                      expression: "form.house_column3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色值" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "背景色值",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.form.house_color1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "house_color1", $$v)
                      },
                      expression: "form.house_color1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景图" } },
                [
                  _c("upload-img", {
                    ref: "background",
                    attrs: { disab: _vm.disabled, limit: 1, "info-text": "" },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "background")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "金刚区行数" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        placeholder: "金刚区行数",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.form.menu_columns,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "menu_columns", $$v)
                        },
                        expression: "form.menu_columns",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "1", label: "一行" } }),
                      _c("el-option", { attrs: { value: "2", label: "两行" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        placeholder: "状态",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.form.is_check,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_check", $$v)
                        },
                        expression: "form.is_check",
                      },
                    },
                    _vm._l(_vm.shopSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { value: list.id, label: list.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.disabled },
              on: { click: _vm.handlersave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }