<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix="：" inline>
      <el-form-item >
        <el-input placeholder="公司名称" clearable v-model="form.company_name" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="店铺名称" clearable v-model="form.mall_name" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
<!--        <el-input placeholder="业务对接人" clearable v-model="form.invite_id" style="width: 160px;"/>-->
        <el-select v-model="form.invite_id" clearable filterable style="width: 160px;" placeholder="业务对接人">
          <el-option v-for="list in staffSelect"
                     :key="list.htyun_name"
                     :value="list.htyun_name"
                     :label="list.htyun_name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="订单添加人" clearable v-model="form.op_name" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
<!--        <el-input placeholder="企业服务人员" clearable v-model="form.service_name" style="width: 160px;"/>-->
        <el-select v-model="form.service_name" clearable filterable style="width: 160px;" placeholder="企业服务人员">
          <el-option v-for="list in staffSelect"
                     :key="list.htyun_name"
                     :value="list.htyun_name"
                     :label="list.htyun_name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="订单编号" clearable v-model="form.order_sn" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.set_meal_id" clearable style="width: 160px;" placeholder="订单内容">
          <el-option v-for="list in mealselect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.title"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.status" clearable style="width: 160px;" placeholder="订单状态">
          <el-option v-for="list in statusSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.cat_id" clearable style="width: 160px;" placeholder="店铺品类">
          <el-option v-for="list in catSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          style="width: 340px;"
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          :default-time="['12:00:00']">
        </el-date-picker>
      </el-form-item>
      <el-form-item >
        <el-date-picker
          style="width: 340px;"
          v-model="time1"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="付款开始日期"
          end-placeholder="付款结束日期"
          :default-time="['12:00:00']">
        </el-date-picker>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handleradd">添 加</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='公司名称'">
            {{row.mall[item.prop]}}
          </div>
          <div v-else-if="item.label=='订单内容'">
            {{row.meal[item.prop]}}
          </div>
          <div v-else-if="item.label=='店铺名称'">
            {{row.mall[item.prop]}}
          </div>
          <div v-else-if="item.label=='店铺品类'">
            {{row.mall[item.prop]}}
          </div>
          <div v-else-if="item.label=='状态'">
            <p v-show="row.status==0">待支付</p>
            <p v-show="row.status==1">已支付</p>
            <p v-show="row.status==2">已取消</p>
          </div>
          <div v-else-if="item.label=='订单类型'">
            <el-tag v-show="row[item.prop]==1">前台购买</el-tag>
            <el-tag v-show="row[item.prop]==2" type="success">后台添加</el-tag>
          </div>

          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
          <el-button
            slot="reference"
            type="text"
            @click="handleredit(row)"
            size="small" >
            编辑
          </el-button>
<!--          <el-button
            slot="reference"
            type="text"
            @click="handlerinfo(row)"
            size="small" >
            详情
          </el-button>-->

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <infocom ref="info" @getlist="handlerInquire"/>
    <addorder ref="add" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
import Infocom from "@/views/pages/datastatis/packagestatis/packagedata/components/infocom";
import Addorder from "@/views/pages/datastatis/orderlist/components/addorder";
export default {
  name: "index",
  components: { Addorder, Infocom},
  data(){
    return {
      form: {
        create_etime:'',
        status: '',
        service_name:'',
        cat_id:'',
        create_stime:'',
        order_sn: '',
        pay_stime:'',
        pay_etime: "",
        page: 1,
        limit: 10
      },
      time:[],
      time1:[],
      mealselect:[],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "订单号",
          prop: "order_sn",
          width: "",
        },

        {
          label: "订单金额",
          prop: "order_price",
          width: "120",
        },

        {
          label: "订单内容",
          prop: "title",
          width: "110",
        },
        {
          label: "店铺名称",
          prop: "mall_name",
          width: "110",
        },

        {
          label: "公司名称",
          prop: "company_name",
          width: "",
        },
        {
          label: "店铺品类",
          prop: "cat_name",
          width: "",
        },
        {
          label: "订单类型",
          prop: "type",
          width: "",
        },
        {
          label: "订单添加人",
          prop: "op_name",
          width: "",
        },
        {
          label: "业务对接人",
          prop: "invite_id",
          width: "",
        },
        {
          label: "企业服务人员",
          prop: "service_name",
          width: "",
        },
        {
          label: "订单提交时间",
          prop: "created",
          width: "",
        },
        {
          label: "订单付款时间",
          prop: "pay_time",
          width: "110",
        },

        {
          label: "状态",
          prop: "status",
          width: "80",
        },
        {
          label: "备注",
          prop: "remarks",
          width: "",
        },
      ],
      catSelect:[],
      statusSelect: [//是否过期
        {
          id: 2,
          name: '已取消'
        }, {
          id: 1,
          name: '已支付'
        },{
          id: 0,
          name: '待支付'
        },
      ],
      total: 0,
      staffSelect:[]
    }
  },
  watch:{
    time(v) {
      if (v) {
        this.form.create_stime = v[0]
        this.form.create_etime = v[1]
      } else {
        this.form.create_stime = ""
        this.form.create_etime = ""
      }
    },
    time1(v) {
      if (v) {
        this.form.pay_stime = v[0]
        this.form.pay_etime = v[1]
      } else {
        this.form.pay_stime = ""
        this.form.pay_etime = ""
      }
    },
  },
 async mounted() {
    this.handlerInquire()
    getAction("/mall/index/meal").then(res=>{
      this.mealselect=res.data
    })
    getAction("/user/index/cate").then(res=>{
      this.catSelect=res.data
    })
    const staff =await getAction("/form/form/staff")
    this.staffSelect=staff.data
  },
  methods:{
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handleradd(){
      this.$refs.add.addshow=true
    },
    handlerlist(){
      getAction("/mall/order/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleredit(row){
      console.log(row)
      this.$refs.add.addshow=true
      this.$refs.add.edit=true
      this.$refs.add.id=row.id
      this.$refs.add.company_name=row.mall.company_name
      this.$refs.add.form={
        set_meal_id:row.meal.id,
        number:'',
        name:row.mall.mall_name,
        mall_id:row.mall.id,
        service_name:row.service_name,
        invite_id:row.invite_id,
        remarks:row.remarks
      }
      // this.$refs.edit.form={
      //   id:row.id,
      //   setmeal_referrals_num:row.meal.setmeal_referrals_num,
      //   surplus_referrals_num:row.meal.surplus_referrals_num,
      //   setmeal_through_train_num:row.meal.setmeal_through_train_num,
      //   surplus_through_train_num:row.meal.surplus_through_train_num,
      //   referrals:0,
      //   through_train:0
      // }
    },
    handlerinfo(row){
      this.$refs.info.showinfo=true

      this.$refs.info.handlerinfo(row.id)
    },

    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
