var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "报告统计详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "htyun_kind" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "一级事业部",
                      },
                      on: { change: _vm.setNameSelect },
                      model: {
                        value: _vm.form.htyun_kind,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "htyun_kind", $$v)
                        },
                        expression: "form.htyun_kind",
                      },
                    },
                    _vm._l(_vm.deptSelect, function (list, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          value: list.htyun_kind,
                          label: list.htyun_kind,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "htyun_dept" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "二级部门",
                      },
                      model: {
                        value: _vm.form.htyun_dept,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "htyun_dept", $$v)
                        },
                        expression: "form.htyun_dept",
                      },
                    },
                    _vm._l(_vm.deptTowSelect, function (list, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          value: list.htyun_dept,
                          label: list.htyun_dept,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "员工姓名",
                      },
                      model: {
                        value: _vm.form.htyun_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "htyun_name", $$v)
                        },
                        expression: "form.htyun_name",
                      },
                    },
                    _vm._l(_vm.nameSelect, function (list, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          value: list.htyun_name,
                          label: list.htyun_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "手机号" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "昵称" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否员工" },
                      model: {
                        value: _vm.form.is_staff,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_staff", $$v)
                        },
                        expression: "form.is_staff",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "340px" },
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerExport },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
              on: { "sort-change": _vm.setSort },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                  sortable: item.sort,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          item.label == "邀请来PV"
                            ? _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [
                                  _vm._v(" " + _vm._s(item.label) + " "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "用户访问报告的次数，同一用户访问N次记录N次，即PV数"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "转发他人链接，他人邀请来的PV数加1,"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "N个邀请人邀请同一用户，邀请来的PV数各加1"
                                          ),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : item.label == "邀请来UV"
                            ? _c(
                                "div",
                                [
                                  _vm._v(" " + _vm._s(item.label) + " "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "访问报告的用户人数，同一用户访问N次只记录1人，即UV数"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "转发他人链接，他人邀请来的UV数加1,"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "N个邀请人邀请同一用户，邀请来的UV数各加1"
                                          ),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(" " + _vm._s(item.label) + " "),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }