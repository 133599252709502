<template>
  <el-dialog
    content
    :visible.sync="showEditPoster"
    width="400px">
    <div>
      <el-form ref="form" :model="form" >
        <el-form-item label="活动标题" prop="title">
          <el-autocomplete
            v-model="form.title"
            :fetch-suggestions="handlermell"
            value-key="title"
            placeholder="请输入活动标题"
            @select="handleSelectmall"
          ></el-autocomplete>

        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      showEditPoster:false,
      form:{
        title:'',
        vote_id:'',
        id:'',
      }
    }
  },
  watch:{
    showEditPoster(val){
      if(!val){
        this.form={
          title:'',
          vote_id:'',
          id:'',
        }
      }
    },
  },
  methods:{
    handlersave(){
      getAction("/api/system/vote/edit",this.form).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.$emit("getlist")
        this.showEditPoster=false
      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
    },
    handleSelectmall(list){
      console.log(list)
      this.form.vote_id=list.id
      this.form.title=list.title
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.vote_id=''
      }else{
        getAction("/api/system/vote/vote",{title:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
  }
};
</script>

<style scoped>

</style>
