<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.external_user_name"
          clearable
          placeholder="需求客户"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.telephone"
          clearable
          maxlength="11"
          placeholder="需求电话"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.tibao_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.shenhe_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.time_type"
          clearable
          placeholder="查询时间"
          style="width: 160px"
        >
          <el-option
            v-for="list in timetypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <!--      <el-form-item >
        <el-select v-model="form.type" clearable style="width: 160px;" placeholder="需求分类">
          <el-option v-for="list in checkselect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-select
          v-model="form.root_cat_id"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通截图'">
            <el-row :gutter="10">
              <span v-for="(list, index) in row[item.prop]" :key="index">
                <el-image
                  :preview-src-list="[list]"
                  :src="list"
                  style="width: 100px; height: 100px"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
              </span>
            </el-row>
          </div>
          <!--          <div v-else-if="item.label=='相册'">
            <el-image
              style="width: 100px; height: 100px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>-->
          <div v-else-if="item.label == '需求分类'">
            <el-tag v-show="row[item.prop] == 1">选品需求</el-tag>
            <el-tag v-show="row[item.prop] == 2" type="success">
              会议需求
            </el-tag>
          </div>
          <!--          <div v-else-if="item.label=='状态'">
            <el-tag v-show="row[item.prop]==1">待审核</el-tag>
            <el-tag v-show="row[item.prop]==2" type="success">审核通过</el-tag>
            <el-tag v-show="row[item.prop]==3" type="info">审核拒绝</el-tag>
          </div>-->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <div v-if="row.status == 1">
            <el-button
              size="small"
              type="text"
              @click.native.prevent="handlerexamine(row)"
            >
              拒绝
            </el-button>
            <el-popconfirm
              title="确认审核通过？"
              @confirm="handlertong(row, 2)"
            >
              <el-button
                size="small"
                style="margin: 10px"
                slot="reference"
                type="text"
              >
                通过
              </el-button>
            </el-popconfirm>
          </div>

          <div v-if="row.status == 2 && row.is_invalid == 1">
            <el-popconfirm title="确认失效？" @confirm="handlertong(row, 4)">
              <el-button
                size="small"
                style="margin: 10px"
                slot="reference"
                type="text"
              >
                失效
              </el-button>
            </el-popconfirm>
          </div>
          <el-button
            v-show="row.is_meet"
            size="small"
            type="text"
            @click.native.prevent="handlercloud(row)"
          >
            会议详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import dateFormat from '@/utils/Timeformat'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        form: {
          external_user_name: '',
          status: '',
          telephone: '',
          root_cat_id: '',
          cat_id: '',
          page: 1,
          limit: 10,
          tibao_name: '',
          shenhe_name: '',
          time_type: '',
          start_time: dateFormat(new Date() - 3600 * 1000 * 24 * 30),
          end_time: dateFormat(new Date()),
        },
        timelist: [],
        mallSelsect: [],
        showSelsect: [
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '审核通过',
          },
          {
            id: 3,
            name: '审核拒绝',
          },
          {
            id: 4,
            name: '已失效',
          },
          {
            id: 5,
            name: '已过期',
          },
          {
            id: 6,
            name: '匹配完',
          },
        ],
        checkselect: [
          {
            id: 1,
            name: '选品需求',
          },
          {
            id: 2,
            name: '会议需求',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        timetypelist: [
          {
            id: 1,
            name: '审核时间',
          },
          {
            id: 2,
            name: '提报时间',
          },
        ],
        columns: [
          {
            label: '需求客户',
            prop: 'external_user_name',
            width: '',
          },

          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },
          {
            label: '客户电话',
            prop: 'telephone',
            width: '115',
          },
          {
            label: '需求详情',
            prop: 'detail',
            width: '',
          },
          {
            label: '沟通截图',
            prop: 'screenshot',
            width: '600',
          },
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            label: '审核人姓名',
            prop: 'check_user_name',
            width: '',
          },
        ],
        total: 0,
        cateselect: [],
        catselect: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handlercloud(row) {
        this.$router.push({ path: '/review/CloudConf', query: { id: row.id } })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlerexamine(row) {
        this.$confirm('是否确认审核不通过？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postAction('/audit/biz-clue/audit', { id: row.id, status: 3 }).then(
            (res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            }
          )
        })
      },
      handlertong(row, status) {
        postAction('/audit/biz-clue/audit', {
          id: row.id,
          status: status,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerlist() {
        postAction('/audit/biz-clue/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        getAction('/mall/proxy/export-lists', this.form).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: '代理意向',
          })
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
