var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showAddstore,
        width: "350px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showAddstore = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "选择产品", prop: "goods_id" } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      clearable: "",
                      "fetch-suggestions": _vm.handlergoods,
                      "value-key": "goods_name",
                      placeholder: "请输入商品名称",
                    },
                    on: { select: _vm.handleSelectgoods },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              )
            : _vm.form.type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "选择企业", prop: "mall_id" } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      clearable: "",
                      "fetch-suggestions": _vm.handlermell,
                      "value-key": "mall_name",
                      placeholder: "请输入企业名称",
                    },
                    on: { select: _vm.handleSelectmall },
                    model: {
                      value: _vm.form.mall_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mall_name", $$v)
                      },
                      expression: "form.mall_name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "policy" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "220px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.is_check,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_check", $$v)
                    },
                    expression: "form.is_check",
                  },
                },
                _vm._l(_vm.shopSelect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { value: list.id, label: list.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort_order" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "220px" },
                attrs: { min: 0 },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showAddstore = !_vm.showAddstore
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }