<template>
  <el-dialog
    title="编辑"
    :visible.sync="showedit"
    width="600px">
    <div>
      <wang-editor ref="wangxie" :height="300" :upload-img-size="300"/>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showedit = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import WangEditor from "@/components/WangEditor";
import { postAction } from "@/api/request";

export default {
  components:{
    WangEditor
  },
  name: "edit",
  data(){
    return{
      showedit:false,
      form:{
        id:'',
        value:'',
      }
    }
  },
  watch:{
    showedit(val){
      if(!val){
        this.form={
          id:'',
          value:'',
        }
      }
    },
  },
  methods:{
    handlerinfo(row){
      this.form[row.value]=row.content
      this.form.value=row.value
      this.form.id=row.id
      setTimeout(()=>{
        this.$refs.wangxie.editor.setHtml(row.content)
      },100)
    },
    handlersave(){
      this.form[this.form.value]=this.$refs.wangxie.getHtmlM()
      postAction("/api/system/integral/edit-conf",this.form).then(res=>{
        this.$message.success(res.msg)
        this.showedit = false
        this.$emit("getlist")
      })
    }
  }
};
</script>

<style scoped>

</style>
