<template>
<div>
  <el-dialog :visible.sync="showinfo" width="500px" >
  <el-form :model="form" ref="form" label-width="100px" :rules="rules">
    <el-form-item label="选择用户" prop="user_id">
      <el-autocomplete
        v-model="form.phone"
        :fetch-suggestions="handlerphone"
        placeholder="请输入手机号"
        style="width: 160px"
        value-key="phone"
        @select="handleSelectphone"
      />
    </el-form-item>
    <el-form-item label="公司名称" prop="enterprise_name">
      <el-input v-model="form.enterprise_name" placeholder="公司名称"></el-input>
    </el-form-item>
    <el-form-item label="营业执照" prop="business_url">
      <upload-img  ref="business_url" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'business_url')" :maxSize="100"/>
    </el-form-item>
    <el-form-item label="logo" prop="logo">
      <upload-img  ref="logo" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'logo')" :maxSize="100"/>
    </el-form-item>
    <el-form-item label="经营品类" prop="hy">
      <el-cascader
        v-model="form.hy"
        popper-class="ssaassd"
        placeholder="经营品类"
        :options="cateSelect"
        clearable
        :show-all-levels="false"
        :props="{ multiple:true,emitPath:false, value: 'id',label:'name', }"
        @change="change"></el-cascader>
    </el-form-item>
    <el-form-item label="申请人姓名" prop="apply_name">
      <el-input v-model="form.apply_name" placeholder="申请人姓名"></el-input>
    </el-form-item>
    <el-form-item label="申请人职务" prop="apply_job">
      <el-input v-model="form.apply_job" placeholder="申请人职务"></el-input>
    </el-form-item>
    <el-form-item label="申请状态" prop="status">
      <el-select v-model="form.status" placeholder="请选择">
        <el-option
          v-for="item in [{label:'待审核',value:'0'},{label:'已审核',value:'1'},{label:'已拒绝',value:'2'}]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showinfo = false">取 消</el-button>
      <el-button type="primary" @click="handlerSubmit">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>
<script>
import UploadImg from "@/components/uploadImg.vue";
import { getAction, postAction } from "@/api/request";

export default {
  name: "addedit",
  components: { UploadImg },
  data() {
    return {
      showinfo: false,
      form: {
        enterprise_name: "",
        phone: "",
        business_url: "",
        logo: "",
        hy: [],
        apply_name: "",
        apply_job: "",
        status: "",
        enterprise_type: "",
        user_id:'',
      },
      cateSelect: [],
      rules: {
        user_id: [
          { required: true, message: "请选择用户", trigger: "change" },
        ],
        enterprise_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        hy: [
          { required: true, message: "请选择经营品类", trigger: "blur" },
        ],
        apply_name: [
          { required: true, message: "请输入申请人姓名", trigger: "blur" },
        ],
        apply_job: [
          { required: true, message: "请输入申请人职务", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择申请状态", trigger: "blur" },
        ],

      },
    };
  },
  watch:{
    showinfo(val){
      if(!val){
        this.$refs.form.resetFields()
        this.closeimg('business_url')
        this.closeimg('logo')
        this.change([])
        this.form={
          enterprise_name: "",
          phone: "",
          business_url: "",
          logo: "",
          hy: [],
          apply_name: "",
          apply_job: "",
          status: "",
          enterprise_type:'',
          user_id:'',
        }
      }
    },
  },
  created() {
    this.handlerselect()
  },
  methods: {
    handlerSubmit(){
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.hy = data.hy.join(',')
          const res = await postAction('/audit/card/edit',this.form)
          if(res.request_code==200){
            this.$message.success('操作成功')
            this.showinfo = false
            this.$emit('getList')
          }else{
            this.$message.error(res.msg)
          }
        } else {
          return false;
        }
      })
    },
    handlerinfo(row){
      this.form =JSON.parse(JSON.stringify(row))
      if(row.hy){
        this.form.hy = row.hy.split(',')
      }else {
        this.form.hy = []
      }

      this.showinfo = true
      this.$nextTick(() => {
        if(row.business_url){
          this.setImgArray(row.business_url,'business_url')
        }
        if(row.logo){
          this.setImg(row.logo,'logo')
        }

      })
    },
    async handlerselect(){
      const cate =await getAction("/user/index/cate-tree")
      this.cateSelect=cate.data
    },
    handlerphone(queryString, cb) {
      if (queryString == '') {
        cb([])
        this.form.user_id = ''
      } else {
        getAction('/user/index/search-user', { phone: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelectphone(list) {
      console.log(list)
      this.form.user_id = list.id
    },
    // 处理禁用状态
    change(value) {
      console.log(value)
      // 处理禁用状态
      this.$nextTick(() => {
        var options = []
        var disabled = value.length >= 3 // 大于等于3时禁用
        this.cateSelect.forEach(option => {
          option.children.forEach(child => {
            // 如果是选中的则不需禁用
            if (value.find(array => array === child.id)) {
              child.disabled = false
            } else {
              child.disabled = disabled
            }
          })
          options.push(option)
        })
        // 重新进行赋值
        this.cateSelect = options
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    setImgArray(v, prop) {
      if (v[0]!='') this.$refs[prop].img = v
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style>
.ssaassd{
  .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
    display: none !important;
  }
}

</style>
