var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "一级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "一级分类" },
                  on: { change: _vm.handlercat },
                  model: {
                    value: _vm.form.one_cate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "one_cate", $$v)
                    },
                    expression: "form.one_cate",
                  },
                },
                _vm._l(_vm.cateselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "二级分类" },
                  model: {
                    value: _vm.form.two_cate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "two_cate", $$v)
                    },
                    expression: "form.two_cate",
                  },
                },
                _vm._l(_vm.catselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "default-time": ["12:00:00"],
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("创建选品方案")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "copybtnappid",
                  attrs: {
                    type: "primary",
                    "data-clipboard-text": _vm.clipboardtext,
                  },
                  on: { click: _vm.handlerCopy },
                },
                [_vm._v(" 复制小程序appid ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "沟通图"
                        ? _c(
                            "div",
                            _vm._l(row[item.prop], function (list, index) {
                              return _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        "preview-src-list": [list],
                                        src: list,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : item.label == "小程序路径"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "copybtn",
                                  attrs: {
                                    type: "primary",
                                    "data-clipboard-text": _vm.clipboardtext,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyurl(row[item.prop])
                                    },
                                  },
                                },
                                [_vm._v(" 复制小程序路径 ")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("addedit", { ref: "addedit", on: { getlist: _vm.handlerInquire } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }