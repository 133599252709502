var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-autocomplete", {
    attrs: {
      "fetch-suggestions": _vm.handlerarticle,
      "value-key": _vm.autoparam.valueKey,
      placeholder: _vm.autoparam.placeholder,
      clearable: "",
    },
    on: { select: _vm.handleSelectarticle },
    model: {
      value: _vm.keyword,
      callback: function ($$v) {
        _vm.keyword = $$v
      },
      expression: "keyword",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }