import request from '@/utils/request'
export function getChannelList(params) {
  return request({
    url: '/article/channel/lists',
    method: 'get',
    params,
  })
}
export function setChannellist(params) {
  return request({
    url: '/article/channel/edit',
    method: 'post',
    params,
  })
}
export function getinfotagslist(params) {
  return request({
    url: '/auth/tags/tree',
    method: 'post',
    params,
  })
}
export function getinfotagsdetail(params) {
  return request({
    url: '/auth/tags-relation/detail',
    method: 'post',
    params,
  })
}

export function getCommonChannelList(params) {
  return request({
    url: '/api/common/common/channel-list',
    method: 'get',
    params,
  })
}
/**
 *专家列表
 * @param params
 * @returns {AxiosPromise}
 */
export function zjhList(params) {
  return request({
    url: '/users/mch-user/zjh-list',
    method: 'get',
    params,
  })
}
/**
 *专家号编辑
 * @param params
 * @returns {AxiosPromise}
 */
export function zjhEdit(params) {
  return request({
    url: '/users/mch-user/zjh-edit',
    method: 'get',
    params,
  })
}
/**
 * 运营/头条号领域列表
 * @param params
 * @returns {AxiosPromise}
 */
export function scopeList(params) {
  return request({
    url: '/api/common/common/scope-list',
    method: 'get',
    params,
  })
}
