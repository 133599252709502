<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <!-- <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.inviter_name"
          clearable
          placeholder="业务人员(邀请人)"
        />
      </el-form-item> -->
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <!--      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>-->
    </el-form>
    <div style="max-width: 1000px">
      <div id="Echarts" style="width: 100%; height: 400px"></div>
    </div>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      :default-sort="{ prop: 'created', order: 'descending' }"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺名称'">
            {{ row.mall.mall_name }}
          </div>
          <div v-else-if="item.label == '业务人员（邀请人）'">
            {{ row.mall.inviter_name }}
          </div>
          <div v-else-if="item.label == '管理员姓名'">
            {{ row.mallManager.name }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dateFormat from '@/utils/Timeformat'
  import echarts from 'echarts'
  export default {
    name: 'Index',
    data() {
      return {
        choiceDate0: '',
        pickerOptions: {
          //设置不能选择的日期
          onPick: ({ maxDate, minDate }) => {
            this.choiceDate0 = minDate.getTime()
            if (maxDate) {
              this.choiceDate0 = ''
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime()
            const minTime = new Date(choiceDateTime).setMonth(
              new Date(choiceDateTime).getMonth() - 1
            )
            const maxTime = new Date(choiceDateTime).setMonth(
              new Date(choiceDateTime).getMonth() + 1
            )
            const min = minTime
            const newDate =
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            const max = newDate < maxTime ? newDate : maxTime
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate
          },
        },
        form: {
          mall_name: '',
          inviter_name: '',
          start_time: dateFormat(new Date() - 3600 * 1000 * 24 * 7),
          end_time: dateFormat(new Date()),
          page: 1,
          limit: 10,
        },
        time: [
          dateFormat(new Date() - 3600 * 1000 * 24 * 7),
          dateFormat(new Date()),
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        datelist: [],
        datalist: [],
        columns: [
          {
            label: '时间',
            prop: 'created',
            width: '',
          },
          {
            label: 'PV',
            prop: 'pv',
            width: '',
          },
          {
            label: 'UV',
            prop: 'uv',
            width: '',
          },
        ],
        total: 0,
        total_uv: 0,
        total_pv: 0,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '时间') {
            sums[index] = '总计'
          }
          if (column.label === 'PV') {
            sums[index] = this.total_pv
          }
          if (column.label === 'UV') {
            sums[index] = this.total_uv
          }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.datelist = []
        this.datalist = []
        this.handlerlist()
      },
      handlerlist() {
        this.datelist = []
        this.datalist = []
        const myChart = echarts.init(document.getElementById('Echarts'))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#fff',
              },
            },
          },
          legend: {
            show: true,
            data: ['PV', 'UV'],
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          dataZoom: [
            {
              id: 'dataZoomX',
              type: 'slider',
              xAxisIndex: [0],
              filterMode: 'filter',
            },
          ],
          series: [
            {
              data: [],
              type: 'line',
              name: 'PV',
              itemStyle: {
                normal: {
                  color: '#5087ec', //改变折线点的颜色
                  lineStyle: {
                    color: '#5087ec', //改变折线颜色
                  },
                },
              },
            },
            {
              data: [],
              type: 'line',
              name: 'UV',
              itemStyle: {
                normal: {
                  color: '#f00', //改变折线点的颜色
                  lineStyle: {
                    color: '#f00', //改变折线颜色
                  },
                },
              },
            },
          ],
        }
        getAction('/api/system/wechat-article/tj', this.form).then((res) => {
          this.tabledata = res.data.list
          this.total_uv = res.data.total_uv || 0
          this.total_pv = res.data.total_pv || 0
          this.total = Number(res.totalCount)
          let datalistuv = []
          this.tabledata.map((list) => {
            console.log('list ------', list)
            this.datelist.push(Object.values(list)[0])
            this.datalist.push(Object.values(list)[1])
            datalistuv.push(Object.values(list)[2])
          })
          console.log(this.datelist, this.datalist, datalistuv)
          option.xAxis.data = this.datelist
          option.series[0].data = this.datalist
          option.series[1].data = datalistuv
          option.legend.data = ['wwww', 'pppp']
          myChart.setOption(option)
        })
      },
      handlerExport() {
        postAction('/api/form/form/mall-match-list-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
