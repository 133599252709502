var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showadd, width: "600px" },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                "label-suffix": "：",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "日期", prop: "date_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "日程日期", clearable: "" },
                      model: {
                        value: _vm.form.date_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "date_id", $$v)
                        },
                        expression: "form.date_id",
                      },
                    },
                    _vm._l(_vm.dataList, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { value: list.id, label: list.title },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "标题", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "举办时间", prop: "time" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入日期时间", clearable: "" },
                    model: {
                      value: _vm.form.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "举办地点", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "举办地点", clearable: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详情页图片", prop: "img_detail" } },
                [
                  _c("upload-img", {
                    ref: "img_detail",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "img_detail")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议亮点", prop: "meet_light" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "会议亮点",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.meet_light,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "meet_light", $$v)
                      },
                      expression: "form.meet_light",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议流程", prop: "meet_process" } },
                [_c("wang-editor", { ref: "meet_process" })],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "状态", clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "1", label: "启用" } }),
                      _c("el-option", { attrs: { value: "0", label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }