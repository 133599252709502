var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "扣除云会议", visible: _vm.showedit, width: "1200px" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "云会议总数" } }, [
                _vm._v(
                  _vm._s(
                    Number(_vm.showform.setmeal_referrals_num) +
                      Number(_vm.showform.give_setmeal_referrals_num)
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "赠送剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.give_surplus_referrals_num))),
              ]),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(_vm._s(Number(_vm.showform.surplus_referrals_num))),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "大商直通车总次数" } },
                [
                  _vm._v(
                    _vm._s(
                      Number(_vm.showform.setmeal_through_train_num) +
                        Number(_vm.showform.give_setmeal_through_train_num)
                    )
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "赠送剩余次数" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      Number(_vm.showform.give_surplus_through_train_num)
                    ) +
                    " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "套餐剩余次数" } }, [
                _vm._v(
                  " " +
                    _vm._s(Number(_vm.showform.surplus_through_train_num)) +
                    " "
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "本次扣除云会议次数" } },
                [_vm._v(_vm._s(_vm.setmeal))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "本次扣除大商直通车次数" } },
                [_vm._v(_vm._s(_vm.surplus))]
              ),
            ],
            1
          ),
          _c("p", { staticStyle: { color: "red" } }, [
            _vm._v("请认真填写，不可恢复，需谨慎操作"),
          ]),
          _vm._l(_vm.form.content, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("el-divider"),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    refInFor: true,
                    attrs: {
                      model: item,
                      "label-width": "120px",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "扣除类型", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: item.type,
                              callback: function ($$v) {
                                _vm.$set(item, "type", $$v)
                              },
                              expression: "item.type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                disabled:
                                  _vm.setmeal >=
                                  Number(_vm.showform.setmeal_referrals_num) +
                                    Number(
                                      _vm.showform.give_setmeal_referrals_num
                                    ),
                                label: "云会议",
                                value: 3,
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                disabled:
                                  _vm.surplus >=
                                  Number(
                                    _vm.showform.setmeal_through_train_num
                                  ) +
                                    Number(
                                      _vm.showform
                                        .give_setmeal_through_train_num
                                    ),
                                label: "大商直通车",
                                value: 4,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商意愿凭证", prop: "prove_img" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("upload-img", {
                              ref: "prove_img",
                              refInFor: true,
                              attrs: {
                                limit: 5,
                                "info-text": "",
                                maxSize: 100,
                              },
                              on: {
                                getImgs: function ($event) {
                                  return _vm.getGoods_imgImg(
                                    $event,
                                    "prove_img",
                                    index
                                  )
                                },
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "经销商意愿凭证(说明:请上传能证明经销商云会议意愿的截图)",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("el-form-item", { attrs: { label: "会议截图" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("upload-img", {
                            ref: "meeting_img",
                            refInFor: true,
                            attrs: { limit: 5, "info-text": "", maxSize: 100 },
                            on: {
                              getImgs: function ($event) {
                                return _vm.getGoods_imgImg(
                                  $event,
                                  "meeting_img",
                                  index
                                )
                              },
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "会议截图(说明:请上传会议中的截图，要求显示全部参会人员)",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning",
                                staticStyle: { "margin-left": "10px" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "会议云录制链接", prop: "video_url" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入会议云录制链接",
                              },
                              model: {
                                value: item.video_url,
                                callback: function ($$v) {
                                  _vm.$set(item, "video_url", $$v)
                                },
                                expression: "item.video_url",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "会议云录制链接(说明:请上传腾讯会议云录制文件链接)",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "提报人", prop: "staff_id" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-autocomplete", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                "fetch-suggestions": (queryString, cb) => {
                                  _vm.handlerstaff(queryString, cb, index)
                                },
                                "value-key": "staff_name",
                                placeholder: "请输入提报人",
                              },
                              on: {
                                select: (list) => {
                                  _vm.handleSelectstaff(list, index)
                                },
                              },
                              model: {
                                value: item.staff_name,
                                callback: function ($$v) {
                                  _vm.$set(item, "staff_name", $$v)
                                },
                                expression: "item.staff_name",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "提报人(说明:请选择厂商云会议的提报人)",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商", prop: "dealer_id" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-autocomplete", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                "fetch-suggestions": (queryString, cb) => {
                                  _vm.handlerdealer(queryString, cb, index)
                                },
                                "value-key": "dealer_name",
                                placeholder: "请输入昵称/手机号",
                              },
                              on: {
                                select: (list) => {
                                  _vm.handleSelectdealer(list, index)
                                },
                              },
                              model: {
                                value: item.dealer_name,
                                callback: function ($$v) {
                                  _vm.$set(item, "dealer_name", $$v)
                                },
                                expression: "item.dealer_name",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "经销商(说明:请与提报人确认经销商的微信昵称或手机号，通过昵称或手机号搜索指定)",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "会议时间", prop: "open_time" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            format: "yyyy-MM-dd HH:mm:ss",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            placeholder: "选择会议时间",
                          },
                          model: {
                            value: item.open_time,
                            callback: function ($$v) {
                              _vm.$set(item, "open_time", $$v)
                            },
                            expression: "item.open_time",
                          },
                        }),
                      ],
                      1
                    ),
                    item.type == 4
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "大商凭证", prop: "business_img" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("upload-img", {
                                  ref: "business_img",
                                  refInFor: true,
                                  attrs: {
                                    limit: 5,
                                    "info-text": "",
                                    maxSize: 100,
                                  },
                                  on: {
                                    getImgs: function ($event) {
                                      return _vm.getGoods_imgImg(
                                        $event,
                                        "business_img",
                                        index
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "大商凭证(说明:请上传能证明大商身份的凭证，包括但不限于公司规模、公司官网介绍等)",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-warning",
                                      staticStyle: { "margin-left": "10px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "会议备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "160px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请输入会议备注",
                          },
                          model: {
                            value: item.remark,
                            callback: function ($$v) {
                              _vm.$set(item, "remark", $$v)
                            },
                            expression: "item.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index == _vm.form.content.length - 1,
                        expression: "index == form.content.length - 1",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.handleradd },
                  },
                  [_vm._v("添加")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.content.length != 1,
                        expression: "form.content.length!=1",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handlerdelect(index)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }