<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    destroy-on-close
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="100px" label-suffix="：">
        <el-form-item label="展馆名称">
          <el-input clearable v-model="form.house_name" placeholder="展馆名称" :disabled="disabled"/>
        </el-form-item>
        <el-form-item label="栏目1">
          <el-input clearable v-model="form.house_column1" placeholder="栏目1" :disabled="disabled"/>
        </el-form-item>
        <el-form-item label="栏目2">
          <el-input clearable v-model="form.house_column2" placeholder="栏目2" :disabled="disabled"/>
        </el-form-item>
        <el-form-item label="栏目3">
          <el-input clearable v-model="form.house_column3" placeholder="栏目3" :disabled="disabled"/>
        </el-form-item>
        <el-form-item label="背景色值">
          <el-input clearable v-model="form.house_color1" placeholder="背景色值" :disabled="disabled"/>
        </el-form-item>
        <el-form-item label="背景图">
          <upload-img :disab="disabled" ref="background" :limit="1" info-text=""  @getImgs="getGoods_imgImg($event, 'background')"/>
        </el-form-item>
        <el-form-item label="金刚区行数">
<!--          <el-input clearable v-model="form.menu_columns" placeholder="金刚区行数" :disabled="disabled"/>-->
          <el-select v-model="form.menu_columns" clearable style="width: 160px;" placeholder="金刚区行数" :disabled="disabled">
            <el-option value="1" label="一行"/>
            <el-option value="2" label="两行"/>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.is_check" clearable style="width: 160px;" placeholder="状态" :disabled="disabled">
            <el-option v-for="list in shopSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" :disabled="disabled" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { getAction, postAction } from "@/api/request";
export default {
  name: "AddEdit",
  components: { UploadImg },
  data(){
    return{
      title:'',
      showadd:false,
      disabled:false,
      form:{
        id:'',
        is_check:'0',
      },
      shopSelect: [
        {
          id: '1',
          name: '启用'
        }, {
          id: '0',
          name: '禁用'
        }
      ],
    }
  },
  watch:{
    showadd(old){
      if(!old){
        this.close()
        this.disabled=false
      }
    }
  },
  methods:{
    handlersave(){
      getAction("/api/system/house/edit",this.form).then(res=>{
        this.close()
        this.$message({
          type:'success',
          message:res.msg
        })
        this.$emit("getlist")
      })
    },
    handlerinfo(row){
      getAction("/api/system/house/info",{id:row}).then(res=>{
        this.form=res.data
        this.setImg(res.data.background,"background")
      })
    },
    close(){
      this.showadd=false
      this.form={
        id:'',
        is_check:'0',
      }
      this.closeimg('background')
    },
    show(){
      this.showadd=true
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
