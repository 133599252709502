<template>
  <el-dialog
    content
    :visible.sync="showEditPoster"
    width="400px">
    <div>
      <el-form ref="form" :model="form" inline label-width="110px">
        <el-form-item label="栏目名称" prop="name">
          <el-input v-model="form.type_name" placeholder="名称字数5个以内" maxlength="5"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input v-model="form.sort_order" placeholder="数字越大，栏目越靠前显示"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
         <el-switch v-model="form.status" active-value="1" inactive-value="0" active-text="启用" inactive-text="禁用"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      showEditPoster:false,
      form:{
        type_name:'',
        sort_order:'',
        status:'',
        id:'',
      },
    }
  },
  watch:{
    showEditPoster(val){
      if(!val){
        this.form={
          type_name:'',
          sort_order:'',
          status:'',
          id:'',
        },
        this.$refs.form.resetFields()
      }
    },
  },
  methods:{
    handlersave(){
          getAction("/api/system/staff-console-type/edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.$emit("getlist")
            this.showEditPoster=false
          })

    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
    },
  }
};
</script>

<style scoped>

</style>
