var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "页面"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(row.type == 1 ? "经销商" : "企业") +
                                  " "
                              ),
                            ])
                          : item.label == "分享图"
                          ? _c(
                              "div",
                              [
                                row[item.prop]
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "80px",
                                        height: "80px",
                                      },
                                      attrs: {
                                        src: row[item.prop],
                                        "preview-src-list": [row[item.prop]],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "220",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "editposter", on: { getlist: _vm.handlerInquire } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }