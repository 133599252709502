<template>
  <el-dialog
    title="热门精选关联品类"
    :visible.sync="showcat"
    v-if="showcat"
    width="60%">
    <div>
      <el-form ref="form" :model="form" >
        <el-form-item prop="name">
          <el-tree
            ref="menuTree"
            :data="TreeData"
            :default-checked-keys="form.cat_ids"
            default-expand-all
            highlight-current
            node-key="id"
            :check-on-click-node="true"
            :props="menuDefaultProps"
            show-checkbox
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showcat = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import { nextTick } from "vue";

export default {
  name: "category",
  data(){
    return{
      showcat:false,
      title:'',
      shopSelect:[],
      TreeData:[],
      form:{
        id:null,
        cat_ids:[],
      },
      menuDefaultProps: {
        children: 'children',
        label: function (data) {
          return data.name
        },
      },
    }
  },
  watch:{
    showcat(val){
      if(!val){
        this.form={
          id:null,
          cat_ids:'',
        }
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
    getAction('/user/index/cate-tree').then((res) => {
      this.TreeData=res.data
    })
  },
  methods:{
    handlerinfo(row){
      this.form= Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(row.share_poster,'share_poster')
      })

    },
    handlersave(){
      const datalist = JSON.parse(JSON.stringify(this.form))
      datalist.cat_ids = this.$refs.menuTree.getCheckedKeys()
      datalist.cat_ids = datalist.cat_ids.join(',')
      console.log(datalist)
      postAction("/api/system/category-act/edit",datalist).then(res=>{
        this.$message({
          type: 'success',
          message: res.msg,
        })
        this.$refs.form.resetFields()
        this.$emit("getlist")
        this.showcat=false
      })
    },
  }
};
</script>

<style scoped>
::v-deep .el-tree-node > .el-tree-node__children {
  display: flex;
  flex-wrap: wrap;
}
</style>
