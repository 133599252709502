<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="50%">
    <div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="标题" clearable style="width: 160px"/>
        </el-form-item>
        <el-form-item label="来源"  prop="source_id">
          <el-select
            v-model="form.source_id"
            clearable
            placeholder="来源"
            style="width: 160px"
          >
            <el-option
              label="云交会"
              value="1"
            />
            <el-option
              label="头条拉取"
              value="2"
            />
            <el-option
              label="企业文章"
              value="3"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="作者" prop="author">
          <el-input v-model="form.author" placeholder="标题" style="width: 160px"/>
        </el-form-item>
        <el-form-item label="头像" prop="author_img">
          <upload-img ref="author_img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'author_img')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="封面图" prop="cover_img">
          <upload-img ref="cover_img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'cover_img')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="内容" prop="content">
<!--          <wang-editor ref="content" :height="300" :upload-img-size="300"/>-->
          <vue-ueditor-wrap v-model="form.content"  />
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="form.is_check"
            clearable
            placeholder="设置状态"
            style="width: 160px"
          >
            <el-option
              label="通过"
              value="1"
            />
            <el-option
              label="未通过"
              value="0"
            />
          </el-select>
        </el-form-item>
<!--        <el-form-item label="创建时间">-->
<!--          <el-date-picker-->
<!--            v-model="value1"-->
<!--            value-format="yyyy-MM-dd HH:mm:ss"-->
<!--            format="yyyy-MM-dd HH:mm:ss"-->
<!--            type="datetime"-->
<!--            placeholder="选择日期时间">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.push_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="虚拟浏览量">
          <el-input-number v-model="form.virtual_view_count" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="关联品类">
          <el-select
            v-model="form.cat_id"
            clearable
            placeholder="设置关联品类"
            style="width: 160px"
          >
            <el-option
              v-for="list in shopSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="置顶">
          <el-select
            v-model="form.is_top"
            clearable
            placeholder="设置置顶"
            style="width: 160px"
          >
            <el-option
              label="置顶"
              value="1"
            />
            <el-option
              label="取消置顶"
              value="0"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import uploadImg from "@/components/uploadImg";
import WangEditor from "@/components/WangEditor";
import { nextTick } from "vue";

export default {
  name: "AddEdit",
  components:{
    uploadImg,
    WangEditor
  },
  data(){
    return{
      showadd:false,
      title:'',
      shopSelect:[],
      form:{
        id:null,
        title:'',
        source_id:'',
        cover_img:'',
        content:'',
        author:'',
        author_img:'',
        push_time:'',
        virtual_view_count:'',
        cat_id:'0',
        is_check:'0',
        is_top:'0',
      },
      rules:{
        title:[
          {required:true,message:'请输入标题',trigger:'blur'}
        ],
        source_id:[
          {required:true,message:'请选择来源',trigger:'blur'}
        ],
        author:[
          {required:true,message:'请输入作者',trigger:'blur'}
        ],
        author_img:[
          {required:true,message:'请上传作者头像',trigger:'blur'}
        ],
        cover_img:[
          {required:true,message:'请上传封面图',trigger:'blur'}
        ],
        content:[
          // {required:true,message:'请输入内容',validator:this.handelriskong,trigger:'blur'}
          {required:true,message:'请输入内容',trigger:'blur'}
        ]
      },
      myConfig: {
        zIndex: 1000,
        toolbars: [
          [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'fontborder',
            'strikethrough',
            'superscript',
            'subscript',
            'removeformat',
            'formatmatch',
            'autotypeset',
            'blockquote',
            'pasteplain',
            '|',
            'forecolor',
            'backcolor',
            'insertorderedlist',
            'insertunorderedlist',
            'selectall',
            'cleardoc',
            '|',
            'rowspacingtop',
            'rowspacingbottom',
            'lineheight',
            '|',
            'customstyle',
            'paragraph',
            'fontfamily',
            'fontsize',
            '|',
            'directionalityltr',
            'directionalityrtl',
            'indent',
            '|',
            'justifyleft',
            'justifycenter',
            'justifyright',
            'justifyjustify',
            '|',
            'touppercase',
            'tolowercase',
            '|',
            'imagenone',
            'imageleft',
            'imageright',
            'imagecenter',
            '|',
            'simpleupload',
            // 'insertcode',
            'attachment',
            'pagebreak',
            '|',
            'horizontal',
            'date',
            'time',
            '|',
            'inserttable',
            'deletetable',
            'insertparagraphbeforetable',
            'insertrow',
            'deleterow',
            'insertcol',
            'deletecol',
            'mergecells',
            'mergeright',
            'mergedown',
            'splittocells',
            'splittorows',
            'splittocols',
            '|',
            'drafts',
          ],
        ],
      },
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
          id:null,
          title:'',
          source_id:'',
          cover_img:'',
          content:'',
          author:'',
          author_img:'',
          push_time:'',
          virtual_view_count:'',
          cat_id:'0',
          is_check:'0',
          is_top:'0',
        }
        this.closeimg('author_img')
        this.closeimg('cover_img')
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
    this.handlerSelect()
  },
  methods:{
    /*handelriskong(rule, value, cb){
      if (this.$refs.content.isKong()) {
        cb(new Error('请输入内容'))
      } else {
        this.form.content=this.$refs.content.getHtmlM()
        cb()
      }
    },*/
    handlerinfo(row){
      this.form= Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(row.author_img,'author_img')
        this.setImg(row.cover_img,'cover_img')
        /*setTimeout(()=>{*/
          // const html = document.createElement('div')
          // html.innerHTML = row.content
          // const allDivs = html.getElementsByTagName('div')
          // for (let i = 0; i < allDivs.length; i++) {
          //   allDivs[i].style.fontSize = '18px'; // 设置字号
          //   allDivs[i].style.lineHeight = '32px';
          //   allDivs[i].style.marginBottom = '16px';
          //   allDivs[i].style.marginTop = '16px';
          //   const newP = document.createElement('p')
          //   newP.innerHTML = allDivs[i].innerHTML
          //   allDivs[i].parentNode.replaceChild(newP, allDivs[i]);
          // }
          // const allImages = html.getElementsByTagName('img')
          // // 遍历所有img标签并添加 alt 和 data-href 属性
          // for (let i = 0; i < allImages.length; i++) {
          //   if (!allImages[i].getAttribute('alt')) {
          //     allImages[i].setAttribute('alt', '');
          //   }
          //   if (!allImages[i].getAttribute('data-href')) {
          //     allImages[i].setAttribute('data-href', '');
          //   }
          // }
     /*   },100)*/
      })

    },
    handlersave(){
      delete this.form.created
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 创建一个临时div元素
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = this.form.content;

          // 获取除了img标签外的所有子元素
          const elements = tempDiv.querySelectorAll(':not(img)');

          // 遍历每个子元素并添加行内样式
          elements.forEach(element => {
            if(element.tagName=='P'||element.tagName=='SPAN'||element.tagName=='DIV'||element.tagName=='SECTION'){
              element.style.fontSize = '18px'; // 设置字号
              element.style.lineHeight = '32px';
              element.style.marginBottom = '16px';
              element.style.marginTop = '16px';
            }// 设置行间距
          });
          // 获取处理后的富文本字符串
          const processedRichTextString = tempDiv.innerHTML;
          this.form.content=processedRichTextString
          // this.form.content=this.$refs.content.getHtmlM()
          console.log(this.form.content)
          postAction("/api/stroll/article/edit",this.form).then(res=>{
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$refs.form.resetFields()
            this.$emit("getlist")
            this.showadd=false
          })
        } else {
          return false;
        }
      });



    },

    async handlerSelect() {
      await getAction('/user/index/cate',{pid:0}).then((res) => {
        this.shopSelect = res.data
        this.shopSelect.push({
          id: '0',
          name: "关联品类",
        })
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
