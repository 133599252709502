var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "append-to-body": "",
        title: "调查问卷详情",
        visible: _vm.showinfo,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showinfo = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-suffix": "：",
                "label-width": "140px",
                model: _vm.surveyinfo,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否满意", prop: "total" } },
                [_vm._v(" " + _vm._s(_vm.surveyinfo.degree_type) + " ")]
              ),
              _vm.surveyinfo.degree_reason &&
              _vm.surveyinfo.degree_reason.length > 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原因", prop: "total" } },
                    [_vm._v(" " + _vm._s(_vm.surveyinfo.degree_reason) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }