<template>
<div>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="300px">
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="展位号" prop="booth_no">
          <el-input v-model="form.booth_no" placeholder="展位号" style="width: 160px;"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="company">
          <el-input v-model="form.company" placeholder="企业名称" style="width: 160px;"></el-input>
        </el-form-item>
        <el-form-item  label="所属模块" prop="type">
          <el-select v-model="form.type" clearable style="width: 160px;" placeholder="所属模块">
            <el-option v-for="list in typeSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>
        <el-form-item  label="关联店铺">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.mall_name"
            :fetch-suggestions="handlerarticle"
            value-key="mall_name"
            placeholder="关联店铺"
            @select="handleSelectarticle"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item  label="状态"  prop="status">
          <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
            <el-option
              value="1"
              label="启用"/>
            <el-option
              value="0"
              label="禁用"/>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input-number v-model="form.sort_order" style="width: 160px;" :min="0"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>`
</div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      title:'',
      showadd:false,
      form:{
        mall_name:'',
        booth_no:'',
        company:'',
        mall_id:'',
        type:'',
        status:'1',
        sort_order:0,
        id:''
      },
      rules:{
        booth_no:[
          { required: true, message: '请输入展位号', trigger: 'change' },
        ],
        company:[
          { required: true, message: '请输入公司名称', trigger: 'change' },
        ],
        type:[
          { required: true, message: '请选择所属模块', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请输入状态', trigger: 'blur' },
        ],
        sort_order:[
          { required: true, message: '请输入排序', trigger: 'blur' },
        ]
      },
      typeSelect: [
        {
          id: '1',
          name: '中国酒类精品展'
        }, {
          id: '2',
          name: '中国饮品食业综合展'
        }, {
          id: '4',
          name: '中国量贩零食展'
        }, {
          id: '5',
          name: '中国预制菜展+中国品牌农业展+中国原辅料机械展'
        }
      ],
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
          mall_name:'',
          booth_no:'',
          company:'',
          mall_id:'',
          type:'',
          status:'1',
          sort_order:0,
          id:''
        }
      }
    }
  },
  methods:{
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction("/api/afbc/company/edit",this.form).then(res=>{
            this.$message.success(res.msg)
            this.showadd = false
            this.$emit("getlist")
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    handlerarticle(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectarticle(list){
      console.log(list)
      this.form.mall_id=list.id
      this.form.mall_name=list.mall_name
    },
    show(){
      this.showadd=true
    }
  }
};
</script>

<style scoped>

</style>
