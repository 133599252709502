<template>
  <el-dialog
    :visible.sync="showadd"
    append-to-body
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="专区名称">
          <el-input v-model="form.type_name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="关联品类">
          <el-select
            v-model="form.type_id"
            class="input"
            clearable
            placeholder="请选择所属品类"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="开启" value="1" />
            <el-option label="关闭" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="报名跳转图片">
          <upload-img ref="jump_img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'jump_img')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="报名跳转链接">
          <el-input v-model="form.jump_url" placeholder="请输入报名跳转链接"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import uploadImg from "@/components/uploadImg";
import { nextTick } from "vue";
export default {
  components:{
    uploadImg
  },
  name: "AddZone",
  data(){
    return{
      showadd:false,
      catSelect:[],
      form:{
        type_name:'',
        type_id:'',
        conf_id:'',
        status:'1',
        sort:'',
        jump_img:'',
        jump_url:'',
        id:'',
      }
    }
  },
  watch:{
    showadd(v){
      if(!v){
        this.form={
          type_name:'',
        type_id:'',
        conf_id:'',
        status:'1',
        sort:'',
        jump_img:'',
        jump_url:'',
        id:'',

      }
      this.closeimg('jump_img')
      }
    }
  },
  mounted() {
    this.handlerselect()
  },
  methods: {
    handlersave(){
      postAction("/api/hotel/type/edit",this.form).then(res=>{
          this.$message.success(res.msg)
          this.showadd=false
          this.$emit('handlerlist')

      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(this.form.jump_img, "jump_img")
      })
    },
    handlerselect(){
      getAction("/user/index/cate").then(res=>{
        this.catSelect=res.data
      })
    },
    handleradd(){
      this.showadd=true
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
