var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.show, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称", prop: "mall_name" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlermell,
                      placeholder: "请输入店铺名称",
                      "value-key": "mall_name",
                    },
                    on: { select: _vm.handleSelectmall },
                    model: {
                      value: _vm.form.mall_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mall_name", $$v)
                      },
                      expression: "form.mall_name",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "公司名称" } }, [
                _vm._v(" " + _vm._s(_vm.company_name) + " "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlerphone,
                      placeholder: "请输入手机号",
                      "value-key": "phone",
                    },
                    on: { select: _vm.handleSelectphone },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管理员姓名", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "", placeholder: "管理员姓名" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管理员职务", prop: "job" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "", placeholder: "管理员职务" },
                    model: {
                      value: _vm.form.job,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "job", $$v)
                      },
                      expression: "form.job",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否主管理员", prop: "is_master" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "是否主管理员" },
                      model: {
                        value: _vm.form.is_master,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_master", $$v)
                        },
                        expression: "form.is_master",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }