var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showadd, width: "300" },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-suffix": ":",
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "160px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "company_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          placeholder: "请输入公司名称",
                          maxlength: "30",
                        },
                        on: { blur: _vm.findCompany },
                        model: {
                          value: _vm.form.company_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "company_name", $$v)
                          },
                          expression: "form.company_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺名称", prop: "mall_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          placeholder: "请输入店铺名称",
                          maxlength: "20",
                        },
                        on: { blur: _vm.findMall },
                        model: {
                          value: _vm.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mall_name", $$v)
                          },
                          expression: "form.mall_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营业执照" } },
                    [
                      _c("upload-img", {
                        ref: "business_url",
                        attrs: { "info-text": "", limit: 1, "max-size": 0.5 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.get_imgImg($event, "business_url")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业员工身份证明材料" } },
                    [
                      _c("upload-img", {
                        ref: "employee_materials_url",
                        attrs: { "info-text": "", limit: 3, "max-size": 0.5 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.get_imgImg(
                              $event,
                              "employee_materials_url"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "佐证材料" } },
                    [
                      _c("upload-img", {
                        ref: "evidence_url",
                        attrs: { "info-text": "", limit: 10, "max-size": 0.5 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.get_imgImg($event, "evidence_url")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属行业", prop: "trade" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          "popper-class": "ssaassd",
                          placeholder: "经营品类",
                          options: _vm.cateSelect,
                          clearable: "",
                          "show-all-levels": false,
                          props: {
                            multiple: true,
                            emitPath: false,
                            value: "id",
                            label: "name",
                          },
                        },
                        on: { change: _vm.change },
                        model: {
                          value: _vm.form.trade,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "trade", $$v)
                          },
                          expression: "form.trade",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请人姓名", prop: "apply_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: "请输入申请人姓名",
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.form.apply_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "apply_name", $$v)
                          },
                          expression: "form.apply_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请人职务" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: "请输入申请人职务",
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.form.apply_job,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "apply_job", $$v)
                          },
                          expression: "form.apply_job",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主管理员手机号", prop: "mobile" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlerphone,
                          placeholder: "请输入手机号",
                          "value-key": "phone",
                        },
                        on: {
                          select: _vm.handleSelectphone,
                          change: _vm.findUser,
                        },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺log" } },
                    [
                      _c("upload-img", {
                        ref: "logo",
                        attrs: { "info-text": "", limit: 1, "max-size": 0.5 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.get_imgImg($event, "logo")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }