var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "指定店铺" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "300px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  placeholder: "可输入关键词模糊搜索",
                  "value-key": "mall_name",
                },
                on: { select: _vm.handleSelectmall },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _vm.form.mall_id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "一级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "一级分类" },
                      on: { change: _vm.handlercat },
                      model: {
                        value: _vm.form.one_cate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "one_cate", $$v)
                        },
                        expression: "form.one_cate",
                      },
                    },
                    _vm._l(_vm.cateselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.mall_id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "二级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        placeholder: "二级分类",
                        multiple: "",
                      },
                      on: { change: _vm.handlertwo },
                      model: {
                        value: _vm.form.two_cate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "two_cate", $$v)
                        },
                        expression: "form.two_cate",
                      },
                    },
                    _vm._l(_vm.twocatselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showpricelist
            ? _c(
                "el-form-item",
                { attrs: { label: "价格带" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        clearable: "",
                        placeholder: "价格带",
                        multiple: "",
                      },
                      model: {
                        value: _vm.form.price_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "price_id", $$v)
                        },
                        expression: "form.price_id",
                      },
                    },
                    _vm._l(_vm.pricelist, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "需求详情" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder:
                    "此信息对企业可见，请规范填写：我在（地区），做（渠道），想找（价位、细分品类）的产品。",
                },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onclose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }