var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showadd, width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "标题", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "source_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "来源" },
                      model: {
                        value: _vm.form.source_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "source_id", $$v)
                        },
                        expression: "form.source_id",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "云交会", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "头条拉取", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "企业文章", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "作者", prop: "author" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author", $$v)
                      },
                      expression: "form.author",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "头像", prop: "author_img" } },
                [
                  _c("upload-img", {
                    ref: "author_img",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "author_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "cover_img" } },
                [
                  _c("upload-img", {
                    ref: "cover_img",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "cover_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c("vue-ueditor-wrap", {
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "设置状态" },
                      model: {
                        value: _vm.form.is_check,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_check", $$v)
                        },
                        expression: "form.is_check",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "通过", value: "1" } }),
                      _c("el-option", {
                        attrs: { label: "未通过", value: "0" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.push_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "push_time", $$v)
                      },
                      expression: "form.push_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟浏览量" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.virtual_view_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "virtual_view_count", $$v)
                      },
                      expression: "form.virtual_view_count",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "设置关联品类" },
                      model: {
                        value: _vm.form.cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cat_id", $$v)
                        },
                        expression: "form.cat_id",
                      },
                    },
                    _vm._l(_vm.shopSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "置顶" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "设置置顶" },
                      model: {
                        value: _vm.form.is_top,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_top", $$v)
                        },
                        expression: "form.is_top",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "置顶", value: "1" } }),
                      _c("el-option", {
                        attrs: { label: "取消置顶", value: "0" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }