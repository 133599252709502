<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item>
        <el-input v-model="form.booth_no" placeholder="展位号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.company" placeholder="企业名称"></el-input>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.type" clearable style="width: 160px;" placeholder="所属展馆">
          <el-option v-for="list in typeSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>

      <el-form-item >
        <el-autocomplete
          style="width: 160px;"
          v-model="form.mall_name"
          :fetch-suggestions="handlerarticle"
          value-key="mall_name"
          placeholder="关联店铺"
          @select="handleSelectarticle"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
          <el-option
                     value="1"
                     label="启用"/>
          <el-option
            value="0"
            label="禁用"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>

    </el-form>
    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='所属展馆'">
<!--            <el-image
              style="width: 50px; height: 50px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>-->
            <div>{{typeSelect.filter(list=>list.id==row[item.prop])[0].name}}</div>
          </div>
          <div  v-else-if="item.label=='状态'">
            <p v-if="row[item.prop]==1">启用</p>
            <p v-else>禁用</p>
          </div>

          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">

          <el-popconfirm
            title="确认删除？"
            @confirm="handlerdelete(row)"
          >
            <el-button
              style="margin: 10px;"
              slot="reference"
              type="text"
              size="small">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <addedit ref="add" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit },
  data(){
    return{
      form:{
        page:1,
        limit:10,
        type:'',
        status:'',
        article_id:'',
        mall_name:'',
        title:''
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "80",
        },

        {
          label: "展位号",
          prop: "booth_no",
          width: "",
        },
        {
          label: "企业名称",
          prop: "company",
          width: "",
        },
        {
          label: "所属展馆",
          prop: "type",
          width: "",
        },
        {
          label: "关联店铺",
          prop: "mall_name",
          width: "",
        },

        {
          label: "排序",
          prop: "sort_order",
          width: "",
        },
        {
          label: "状态",
          prop: "status",
          width: "",
        },
      ],
      typeSelect: [
        {
          id: '1',
          name: '中国酒类精品展'
        }, {
          id: '2',
          name: '中国饮品食业综合展'
        }, {
          id: '4',
          name: '中国量贩零食展'
        }, {
          id: '5',
          name: '中国预制菜展+中国品牌农业展+中国原辅料机械展'
        }
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleradd(){
      this.$refs.add.show()
      this.$refs.add.title="新增"
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerarticle(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectarticle(list){
      console.log(list)
      this.form.mall_id=list.id
      this.form.mall_name=list.mall_name
    },
    handlerlist(){
      getAction("/api/afbc/company/index",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handlerdelete(row){
      postAction("/api/afbc/company/delete",{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },

    async handleredit(row){
      console.log(row)
      this.$refs.add.show()
      this.$refs.add.form=Object.assign(this.$refs.add.form,JSON.parse(JSON.stringify(row)))
      this.$refs.add.title="编辑"
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
