<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="申请人姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.inviter_name"
          clearable
          placeholder="邀请人"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="手机号码"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-cascader
          v-model="trades"
          :options="options"
          collapse-tags
          placeholder="品类"
          :props="{ multiple: true, checkStrictly: true,label: 'name', value: 'id' }"
          clearable></el-cascader>
      </el-form-item>
      <el-form-item label="审核时间">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_lock"
          clearable
          placeholder="账号状态"
          style="width: 160px"
        >
          <el-option
            label="正常"
            value="0"
          />
          <el-option
            label="自动锁定"
            value="1"
          />
          <el-option
            label="手动锁定"
            value="1"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.op_name"
          clearable
          placeholder="审核人"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.source"
          clearable
          filterable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option
            v-for="(list,index) in source"
            :key="index"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '营业执照'">
            <span v-for="list in row.business_url">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '企业员工身份证明材料'">
            <el-image
              :preview-src-list="[row.employee_materials_url]"
              :src="row.employee_materials_url"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '姓名(个人信息)'">
            <span v-if="row.users">{{ row.users.realname }}</span>
          </div>
          <div v-else-if="item.label == '账号状态'">
            <span v-if="row.users">{{ row.users.is_lock==0?'正常':row.users.is_lock==1?'自动锁定':'手动锁定' }}</span>
          </div>
          <div v-else-if="item.label == '昵称(个人信息)'">
            <span v-if="row.users">{{ row.users.nickname }}</span>
          </div>
          <div v-else-if="item.label == '电话(个人信息)'">
            <span v-if="row.users">{{ row.users.phone }}</span>
          </div>
<!--          <div v-else-if="item.label == '来源'">-->
<!--            {{source.filter((list)=>list.id==row.type)[0].name}}-->
<!--          </div>-->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-popconfirm
            v-show="row.status == 2"
            title="确认审核拒绝？"
            @confirm="deleteRow(row.id, 3)"
          >
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.status == 1"
            title="确认审核拒绝？"
            @confirm="deleteRow2(row.id)"
          >
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.status == 2"
            title="确认审核通过？"
            @confirm="deleteRow(row.id, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              通过
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerbusiness(row)"
          >
            工商信息
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerlock(row)"
          >
            {{row.users.is_lock==0?'设为手动锁定':'设为正常'}}
          </el-button>
          <el-button @click="handleredit(row)" size="small" type="text">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <addedit ref="edit" @refresh="handlerlist"/>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <business ref="business" />
    <el-dialog
      title="设为手动锁定"
      center
      :visible.sync="show"
      width="30%">
      <div style="display: flex;align-items: center;flex-direction: column">
        <p>确认设为手动锁定？</p>
        <p>锁定后用户将不能使用该账号</p>
        <div>
          <span>锁定原因：</span>
          <el-select v-model="reason" clearable placeholder="请选择锁定原因">
            <el-option value="广告公司" label="广告公司" />
            <el-option value="活动作弊" label="活动作弊" />
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="show = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Business from '../../enterprise/storereview/components/business'
  import Addedit from "./components/addedit";
  export default {
    name: 'EnterpriseCertification',
    components: { Addedit, Business },
    data() {
      return {
        show:false,
        reason:'',
        id:'',
        options:[],
        form: {
          type: 2,
          status: 0,
          company: '',
          op_name:'',
          inviter_name:'',
          name: '',
          mobile: '',
          page: 1,
          limit: 10,
          start_check_date: '',
          end_check_date: '',

        },
        trades:[],
        source: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },

          {
            label: '营业执照',
            prop: 'business_url',
            width: '',
          },
          {
            label: '企业员工身份证明材料',
            prop: 'employee_materials_url',
            width: '',
          },
          {
            label: '经营品类',
            prop: 'trade_txt',
            width: '120',
          },
          {
            label: '地区',
            prop: 'area',
            width: '120',
          },
          {
            label: '渠道',
            prop: 'channel_txt',
            width: '120',
          },
          {
            label: '代理品牌',
            prop: 'brand',
            width: '120',
          },
          {
            label: '申请人姓名',
            prop: 'name',
            width: '110',
          },
          {
            label: '申请人职务',
            prop: 'job',
            width: '110',
          },
          {
            label: '邀请人',
            prop: 'inviter_name',
            width: '120',
          },
          {
            label: '姓名(个人信息)',
            prop: 'users',
            width: '120',
          },
          {
            label: '昵称(个人信息)',
            prop: 'users',
            width: '120',
          },
          {
            label: '电话(个人信息)',
            prop: 'users',
            width: '120',
          },
          {
            label: '手机号码',
            prop: 'mobile',
            width: '80',
          },
          {
            label: '状态',
            prop: 'status_txt',
            width: '80',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'check_time',
            width: '',
          },
          {
            label: '审核人',
            prop: 'op_name',
            width: '',
          },
          {
            label: '来源',
            prop: 'source',
            width: '',
          },
          {
            label: '账号状态',
            prop: 'is_lock',
            width: '',
          },
        ],
        shopSelect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 2,
            name: '已审核',
          },
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_check_date = v[0]
          this.form.end_check_date = v[1]
        } else {
          this.form.start_check_date = ''
          this.form.end_check_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      getAction("/user/index/cate-tree").then(res=>{
        this.options=res.data
      })
      getAction('/audit/identity/source').then(res=>{
        this.source=res.data
      })
    },
    methods: {
      handleredit(row){
        console.log(row)
        this.$refs.edit.handlerinfo(row)
        this.$refs.edit.showadd=true
      },
      handleradd(){
        this.$refs.edit.showadd=true
      },
      handlerlock(row){
        // postAction("",{})
        if(row.users.is_lock==0){
            this.show=true
            this.id=row.id

            }else if(row.users.is_lock!=0){
          this.$confirm('确认设置为正常', '设置正常', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            postAction("/audit/identity/change-lock-dealer",{id:row.id,lock:0}).then(res=>{
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.handlerlist()
            })
          })
            }
      },
      handlersave(){
        postAction("/audit/identity/change-lock-dealer",{id:this.id,lock:1,reason:this.reason}).then(res=>{
          this.show=false
          this.$message({
            type: 'success',
            message: res.msg
          })
          this.handlerlist()
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerinfo(row) {
        this.$refs.info.form = row
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        this.form.trades=this.trades.join(',')
        getAction('/audit/identity/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      deleteRow2(id) {
        this.$prompt('理由', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          postAction('/audit/identity/two-reject', {
            id: id,
            reason: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        })
      },
      deleteRow(id, status) {
        if (status == 3) {
          this.$prompt('理由', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            postAction('/audit/identity/audit', {
              id: id,
              status: status,
              reason: value,
            }).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            })
          })
        } else {
          postAction('/audit/identity/audit', { id: id, status }).then(
            (res) => {
              if (res.request_code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.handlerInquire()
              }
            }
          )
        }
      },
      handlerbusiness(row) {
        console.log(row)
        this.$refs.business.handlerinfo(row.company)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
