<template>
  <div class="index-container center">
    <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
    </el-form>
    <el-radio-group
      v-model="tabPosition"
      style="margin-bottom: 30px"
      @change="handlertabchange"
      :disabled="time == null"
    >
      <el-radio-button :label="0">上新产品数量统计</el-radio-button>
      <el-radio-button :label="1">产品变更日志更新数量统计</el-radio-button>
      <el-radio-button :label="2">上新企业数量统计</el-radio-button>
      <el-radio-button :label="3">企业变更日志更新数量统计</el-radio-button>
    </el-radio-group>
    <component :is="comname"
               ref="myCecharts"
               :stime="form.start_date"
               :etime="form.end_date">
    </component>
  </div>
</template>

<script>
import upNew from "./components/upNew";//上新产品
import upChange from "./components/upChange";//产品更新
import upEnterprise from "./components/upEnterprise";//上新企业
import upRecord from "./components/upRecord";//企业更新
import { dateForm } from "@/utils/time";
import { nextTick } from "vue";

export default {
  name: "index",
  components:{
    upNew,upChange,upEnterprise,upRecord
  },
  data(){
    return{
      comname:'upNew',
      tabPosition:0,
      form:{
        start_date:dateForm(new Date(new Date() - 3600*1000*24*7)),
        end_date:dateForm(new Date()),
        type:'',
      },
      time:[dateForm(new Date(new Date() - 3600*1000*24*7)),dateForm(new Date())],
    }
  },
  watch:{
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ""
        this.form.end_date = ""
      }
    },
  },
  methods:{
    handlerInquire(){
      if (this.time != null) {
        nextTick(()=>{
          this.$refs.myCecharts.handlerbingtu();
          this.$refs.myCecharts.handlerbingtutow();
        })

      } else {
        this.$message({
          message: "请选择一段时间后再次进行查询",
          type: "warning",
        });
      }
    },
    handlertabchange(val){
      if (this.time != null) {
        switch (val){
          case 0:
            this.comname='upNew'
          break;
            case 1:
              this.comname='upChange'
              break;
              case 2:
                this.comname='upEnterprise'
                break;
                case 3:
                  this.comname='upRecord'
                  break;
        }
      } else {
        this.$message({
          message: "请选择一段时间后再次进行查询",
          type: "warning",
        });
      }
    },
  }
};
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
}

</style>
