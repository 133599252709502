<template>
  <el-dialog title="批量导入" :visible.sync="showBatch" width="30%">
    <el-form  ref="form" label-width="100px">
      <el-form-item label="选择文件:">
          <input v-show="false" type="file" ref="file" @change="handleraddfile" accept=".csv,.xls,.xlsx"/>
          <el-button type="primary" @click="handlersave">选择文件</el-button>
        <span>{{filename}}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerdownload">下载模板</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handlerimport">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/request";
import axios from "axios";

export default {
  // name: "batch",
  data(){
    return{
      showBatch:false,
      filename:'',
      csvUrl:'',
    }
  },
  methods:{
    async getOssData() {
      return postAction("/api/common/common/oss-sign").then(res=>{
        console.log(res)
        return res.data
      })
    },
    close(){
      this.showBatch = false
      this.filename=''
      this.csvUrl=''
    },
    generateFileName(ossData, file){
      console.log("走到这了吗",file)
      // const suffix = file.name.slice(file.name.lastIndexOf('.'));
      console.log(ossData.dir)
      return ossData.dir +'/'+ file.name;
    },
    handlersave(){
     this.$refs.file.click()
    },
    handlerimport(){
      postAction("/audit/import-cert/import", { in_file: this.csvUrl,yjhasync:1 }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '已开始上传，请耐心等待',
          })
          this.showBatch = false
          this.filename=''
          this.csvUrl=''
        }
      })
    },
    async handleraddfile(e){
      console.log("文件上传",e.target.files[0])
      this.filename=e.target.files[0].name
      const file = e.target.files[0]
        const ossData = await this.getOssData()
        const key = this.generateFileName(ossData, file)
      console.log("走到这了吗2")
        const formdata = new FormData()

        // 注意参数的顺序，key 必须是第一位，表示OSS存储文件的路径
        formdata.append('key', key)
        formdata.append('OSSAccessKeyId', ossData.accessid)
        formdata.append('policy', ossData.policy)
        formdata.append('signature', ossData.signature)
        // 文件上传成功默认返回 204 状态码，可根据需要修改为 200
        formdata.append('success_action_status', '200')
        // file 必须放在最后一位
        formdata.append('file', file)
      console.log("走到这了吗1")
        await axios.post(ossData.host, formdata,{
          headers:{
            'Content-Type': "multipart/form-data",
          }
        }).then(res=>{
          if(res.status === 200) {
            e.target.value = ""
            this.csvUrl = 'https://res.httoutiao.com/'+key

          }

        })
    },
    handlerdownload(){
      const url= 'https://res.httoutiao.com/1/后台导入经销商库模板.csv'
      const DomA = document.createElement('a')
      DomA.href = url
      DomA.download = '后台导入经销商库模板.csv'
      DomA.click()
      DomA.remove()
      this.$message({
        type: 'success',
        message: '下载成功'
      })
    }
  }
};
</script>

<style scoped>

</style>
