<template>
  <div class="orderTest-container">
    <FormMaker
      ref="formMaker"
      :labels="formOpt"
      :resetBtn="true"
      :label-width="80"
      :disabled="false"
      @getFormData="handleQuery"
    >
      <template #btn>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </template>
    </FormMaker>
    <el-table border :data="list" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
         <template #default="{ row }">
          <div v-if="item.prop == 'send_type'">
            {{row[item.prop]==1?'立即发送':'定时发送'}}
          </div>
           <div v-else-if="item.prop == 'type'">
             {{row[item.prop]==1?"企业":row[item.prop]==0?'全部':row[item.prop]==2?'经销商':'其他'}}
           </div>
           <div v-else-if="item.prop == 'jump_type'">
             {{row.jump.jump_page}}
           </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="160" fixed="right">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
          <el-button type="text" @click="handleData(row)">数据统计</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.pagesize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="margin-top: 10px; text-align: center"
    ></el-pagination>
    <AddEdit ref="addEdit" @refresh="fetchData"></AddEdit>
    <DataSummary ref="dataSummary"></DataSummary>
  </div>
</template>
<script>
import FormMaker from "@/components/formMaker"
import AddEdit from "./components/add&edit.vue"
import DataSummary from "./components/dataSummary.vue"
import { getAction, postAction } from "@/api/request";

export default {
  name: "TangMeiren",
  components: { FormMaker, AddEdit, DataSummary },
  data() {
    return {
      form: { page: 1, pagesize: 10 },
      list: [],
      total: 0,
      loading: false,
      layout: "total, sizes, prev, pager, next, jumper",
      formOpt: [
        {
          // * 为必填
          type: "input", // * 表单类型
          label: "消息标题", // label显示
          place: "请输入标题", // 占位显示
          prop: "title", // * prop
          width: "150", // 宽度
        },
        {
          // * 为必填
          type: "input", // * 表单类型
          label: "通知内容", // label显示
          place: "请输入通知内容", // 占位显示
          prop: "content", // * prop
          width: "150", // 宽度
        },
        {
          // * 为必填
          type: "select", // * 表单类型
          label: "接收方", // label显示
          place: "请输入接收方", // 占位显示
          prop: "type", // * prop
          selectLabel: "name", // 下拉框lable  默认name
          selectListName: "receiveSelect", // 下拉列表名字  el-option循环selectList.nameSelect里面的数据
          width: "150", // 宽度
        },
        {
          type: "select", //*
          label: "发送类型",
          place: "发送类型",
          prop: "send_type", //*
          selectLabel: "id", // 下拉框lable  默认name
          selectId: "id", // 下拉框value   默认  id
          selectListName: "typeSelect", // 下拉列表名字  el-option循环selectList.nameSelect里面的数据
          width: "150", // 宽度
        },
      ],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "70",
        },
        {
          label: "消息标题",
          prop: "title",
          width: "260",
        },
        {
          label: "通知内容",
          prop: "content",
          width: "290",
        },
        {
          label: "接收方",
          prop: "type",
          width: "",
        },
        {
          label: "跳转类型",
          prop: "jump_type",
          width: "",
        },
        {
          label: "跳转详情",
          prop: "jump_val_name",
          width: "",
        },
        {
          label: "发送类型",
          prop: "send_type",
          width: "",
        },
        {
          label: "定时发送时间",
          prop: "send_time",
          width: "230",
        },
        {
          label: "最后操作人",
          prop: "last_admin",
          width: "100",
        },
        {
          label: "最后操作时间",
          prop: "last_admin_time",
          width: "90",
        },
      ],
      typeSelect: [
        { id: "1", name: "立即发送" },
        { id: "2", name: "定时发送" },
      ],
      // 接收方，0全部，1企业，2经销商
      receiveSelect: [
        { id: 0, name: "全部" },
        { id: 1, name: "企业" },
        { id: 2, name: "经销商" },
        { id: 3, name: "其他" },
      ],
      jumpTypeSelect:[],
    }
  },
  created() {},
  mounted() {
    this.initSelect()
    this.fetchData()
  },
  computed: {},
  watch: {},
  methods: {
   async initSelect() {
      this.$refs.formMaker.addSelect("typeSelect", this.typeSelect)
      this.$refs.formMaker.addSelect("receiveSelect", this.receiveSelect)
      let jumplist = await postAction("/audit/advert/jump-type")
      this.jumpTypeSelect = jumplist.data
    },
    async fetchData() {
      try {
        this.loading = true
        let data = await getAction("/user/message/lists", this.form)
        console.log("data", data)
        this.list = data.data
        this.total = Number(data.totalCount)
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    handleQuery(val = {}) {
      if (Array.isArray(val.time)) {
        this.form.stime = val.time[0]
        this.form.etime = val.time[1]
      } else {
        this.form.stime = ""
        this.form.etime = ""
      }
      this.form.page = 1
      this.form = { ...this.form, ...val }
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.fetchData()
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.pagesize = val
      this.fetchData()
    },
    handleAdd() {
      this.$refs.addEdit.type = 1
      this.$refs.addEdit.showDialog = true
    },
    handleEdit(row) {
      this.$refs.addEdit.id = row.id
      this.$refs.addEdit.type = 2
      this.$refs.addEdit.showDialog = true
      this.$refs.addEdit.fetchData(row)
    },
    handleDelete(row) {
      this.$confirm("删除后不可恢复，请谨慎操作", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          postAction("/user/message/delete", { id: row.id }).then((r) => {
            this.fetchData()
            this.$message({
              type: "success",
              message: "删除成功!",
            })
          })
        })
        .catch(() => {})
    },
    handleData(row) {
      this.$refs.dataSummary.id = row.id
      this.$refs.dataSummary.showDialog = true
    },
  },
}
</script>
<style lang="scss" scoped></style>
