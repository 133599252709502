<template>
  <el-dialog
    :visible.sync="showadd"
    width="80%">
    <div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="选择用户" prop="user_id">
          <el-autocomplete
            v-model="form.phone"
            :fetch-suggestions="handlerphone"
            placeholder="请输入手机号"
            style="width: 160px"
            value-key="phone"
            @select="handleSelectphone"
          />
        </el-form-item>
        <el-form-item label="选择身份" prop="dealer_type">
          <el-select v-model="form.dealer_type" placeholder="选择身份">
            <el-option
              v-for="item in shenoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="company_name">
          <el-input v-model="form.company_name" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="business_url">
          <upload-img  ref="business_url" :infoText="'格式jpg、png，大小5MB以内，请上传与您相关的图片'" :limit="1" @getImgs="getGoods_imgImg($event, 'business_url')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="企业员工身份证明材料" prop="employee_materials_url">
          <upload-img  ref="employee_materials_url" :infoText="'格式jpg、png，大小5MB以内，名片、工作证、门头、企业微信截图、钉钉截图等'" :limit="1" @getImgs="getGoods_imgImg($event, 'employee_materials_url')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="经营品类" prop="trade">
          <el-cascader
            v-model="form.trade"
            popper-class="ssaassd"
            placeholder="经营品类"
            :options="cateSelect"
            clearable
            :show-all-levels="false"
            :props="{ multiple:true,emitPath:false, value: 'id',label:'name', }"
            @change="change"></el-cascader>
        </el-form-item>
        <el-form-item label="地区" prop="province_id">
          <el-form-item prop="province_id">
            <el-select
              v-model="form.province_id"
              clearable
              placeholder="省"
              style="width: 160px"
              @change="handleProChange"
            >
              <el-option
                v-for="list in provinceSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="city_id">
            <el-select
              v-model="form.city_id"
              clearable
              placeholder="市"
              style="width: 160px"
              @change="handlercidChange"
            >
              <el-option
                v-for="list in citylist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="area_id">
            <el-select
              v-model="form.area_id"
              clearable
              placeholder="区"
              style="width: 160px"
            >
              <el-option
                v-for="list in areaSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="渠道" prop="channel">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group v-model="form.channel" @change="handleCheckChange">
            <el-checkbox
              v-for="(city, idx) in citySelect"
              :key="idx"
              :label="city.id"
            >
              {{ city.name }}
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>
        <el-form-item label="代理品牌" prop="brand">
          <el-input v-model="form.brand" placeholder="代理品牌"></el-input>
        </el-form-item>
        <el-form-item label="申请人姓名" prop="apply_name">
          <el-input v-model="form.apply_name" placeholder="申请人姓名"></el-input>
        </el-form-item>
        <el-form-item label="申请人职务" prop="apply_job">
          <el-input v-model="form.apply_job" placeholder="申请人职务"></el-input>
        </el-form-item>
        <el-form-item label="邀请人" prop="inviter_name">
          <el-input v-model="form.inviter_name" placeholder="邀请人"></el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-select v-model="form.status" placeholder="审核状态">
            <el-option label="已审核" value="1"/>
            <el-option label="审核中" value="2"/>
            <el-option label="已驳回" value="3"/>
          </el-select>
        </el-form-item>
        <el-form-item label="拒绝原因" prop="reason" v-show="form.status == 3">
          <el-input v-model="form.reason" placeholder="拒绝原因"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import UploadImg from "@/components/uploadImg";

export default {
  name: "addedit",
  components: { UploadImg },
  data(){
    return{
      showadd:false,
      cateSelect:[],
      provinceSelect:[],
      citylist:[],
      areaSelect:[],
      citySelect:[],
      checkAll: false,
      isIndeterminate: false,
      shenoptions:[
        {
          name:'经销商',
          id:'1'
        },
        {
          name:'采购商',
          id:'2'
        },
        {
          name:'零售店',
          id:'3'
        }
      ],
      form:{
        user_id:'',
        type:'2',
        dealer_type:'',
        province_id:'',
        city_id:'',
        area_id:'',
        channel:[],
        company_name:'',
        business_url:'',
        employee_materials_url:'',
        trade:[],
        brand:'',
        apply_name:'',
        apply_job:'',
        inviter_name:'',
        status:'',
        reason:'',
      },
      rules:{
        dealer_type:[
          {required:true,message:'请选择经销商类型',trigger:'blur'}
        ],
        user_id:[
          {required:true,message:'请选择用户',trigger:'blur'}
        ],
        company_name:[
          {required:true,message:'请输入公司名称',trigger:'blur'}
        ],
        business_url:[
          {required:true,message:'请上传营业执照',trigger:'blur'}
        ],
        employee_materials_url:[
          {required:true,message:'请上传企业员工身份证明材料',trigger:'blur'}
        ],
        trade:[
          {required:true,message:'请选择经营品类',trigger:'blur'}
        ],
        province_id:[
          {required:true,message:'请选择省份',trigger:'blur'}
        ],
        city_id:[
          {required:true,message:'请选择市',trigger:'blur'}
        ],
        area_id:[
          {required:true,message:'请选择区',trigger:'blur'}
        ],
        channel:[
          {required:true,message:'请选择渠道',trigger:'blur'}
        ],
        apply_name:[
          {required:true,message:'请输入申请人姓名',trigger:'blur'}
        ],
        apply_job:[
          {required:true,message:'请输入申请人职务',trigger:'blur'}
        ],
        status:[
          {required:true,message:'请选审核状态',trigger:'blur'}
        ],
      }
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.form={
          id:'',
          user_id:'',
          type:'2',
          dealer_type:'',
          province_id:'',
          city_id:'',
          area_id:'',
          channel:[],
          company_name:'',
          business_url:'',
          employee_materials_url:'',
          trade:[],
          brand:'',
          apply_name:'',
          apply_job:'',
          inviter_name:'',
          status:'',
          reason:'',
        }
        this.closeimg('business_url')
        this.closeimg('employee_materials_url')
        this.$refs.form.resetFields()
      }
    }
  },
  mounted() {
    this.getProvince()
    this.handlerselect()
  },
  methods: {
    handlerinfo(row){
      console.log(row)
      this.getProvince()
      this.getCityList(row.province_id)
      this.getAreaList(row.city_id)
      this.form = Object.assign(this.form,row)
      this.form.phone=row.users.phone
      this.form.channel = row.channel.split(",")
      this.form.trade=row.trade.split(",")
      this.change(this.form.trade)
      this.$nextTick(() => {
        this.setImgArray(row.business_url,'business_url')
        this.setImg(row.employee_materials_url,'employee_materials_url')
      })
    },
    handlersave(){
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.channel=data.channel.join(",")
          postAction("/audit/identity/edit",data).then(res=>{
            if(res.request_code==200){
              this.$message.success(res.msg)
              this.$emit("refresh")
              this.showadd = false
            }
          })
        }
      })
    },




    // 处理禁用状态
    change(value) {
      console.log(value)
      // 处理禁用状态
      this.$nextTick(() => {
        var options = []
        var disabled = value.length >= 3 // 大于等于3时禁用
        this.cateSelect.forEach(option => {
          option.children.forEach(child => {
            // 如果是选中的则不需禁用
            if (value.find(array => array === child.id)) {
              child.disabled = false
            } else {
              child.disabled = disabled
            }
          })
          options.push(option)
        })
        // 重新进行赋值
        this.cateSelect = options
      })
    },


    async handlerselect(){
      const channel =await getAction("/user/index/channel")
      console.log(channel.data,"渠道")
      this.citySelect=channel.data
      const cate =await getAction("/user/index/cate-tree")
      this.cateSelect=cate.data
    },
    handleCheckAllChange(val) {
      const city = this.citySelect.map((c) => c.id)
      this.form.channel = val ? city : []
      this.isIndeterminate = false
    },
    handleCheckChange(val) {
      const checkLength = val.length
      this.checkAll = checkLength === this.citySelect.length
      this.isIndeterminate =
        checkLength > 0 && checkLength < this.citySelect.length
    },
    handleProChange(e) {
      this.form.city_id = ''
      this.form.area_id = ''
      if(e!=''){
        this.getCityList(e)
      }

    },
    handlercidChange(e){
      this.form.area_id = ''
      if(e!=''){
        this.getAreaList(e)
      }

    },
    // 获取省份
    async getProvince() {
      const provin = await getAction('/user/index/area', { pid: 0 })
      this.provinceSelect = provin.data
      console.log('省份')
    },
    // 获取市
    async getCityList(e) {
      const citydata = await getAction('/user/index/area', { pid: e })
      this.citylist = citydata.data
    },
    //获取区
    async getAreaList(e) {
      const areadata = await getAction('/user/index/area', { pid: e })
      this.areaSelect = areadata.data
    },
    handlerphone(queryString, cb) {
      if (queryString == '') {
        cb([])
        this.form.user_id = ''
      } else {
        getAction('/user/index/search-user', { phone: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelectphone(list) {
      console.log(list)
      this.form.user_id = list.id
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    setImgArray(v, prop) {
      if (v[0]!='') this.$refs[prop].img = v
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>
.ssaassd{
  .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
    display: none !important;
  }
}
</style>
