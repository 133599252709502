<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>

      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>


    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='banner图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
                    <div v-else-if="item.label=='H5分享图'">
                      <el-image
                        style="width: 70px; height: 30px"
                        :src="row[item.prop]"
                        :preview-src-list="[row[item.prop]]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
          <div v-else-if="item.label=='小程序分享图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
          <el-button
            style="margin: 10px;"
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  <add-live ref="live"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddLive from "@/views/pages/systemSet/livetelecast/LiveCollection/components/addLive";

export default {
  name: "index",
  components: { AddLive },
  data(){
    return{
      form: {
        type:1,
        status: -1,
        title:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "id",
          prop: "id",
          width: "",
        },
        {
          label: "banner图",
          prop: "banner_pic",
          width: "",
        },
        {
          label: "背景色",
          prop: "bg_color",
          width: "",
        },
        {
          label: "分享标题",
          prop: "title",
          width: "",
        },
        {
          label: "分享描述",
          prop: "content",
          width: "",
        },{
          label: "H5分享图",
          prop: "h5_pic",
          width: "",
        },
        {
          label: "小程序分享图",
          prop: "mini_pic",
          width: "",
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods:{
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerlist(){
      getAction("/live/index/config",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleredit(row){
      console.log(row)
      this.$refs.live.show()
      this.$refs.live.handlerinfo(row)

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
