<template>
  <div class="index-container">
    <addbanner ref="banner"  @getlist="handlerInquire"/>
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-select v-model="form.type" clearable style="width: 160px;" placeholder="类型">
          <el-option v-for="list in identitySelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
          <el-option v-for="list in shopSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="标题" clearable v-model="form.title" style="width: 110px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>

    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='banner图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row.pic"
              :preview-src-list="[row.pic]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <!--          <div v-else-if="item.label=='店铺logo'">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row.logo"
                        :preview-src-list="[row.logo]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>-->
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
          <el-popconfirm
            title="确认禁用？"
            @confirm="deleteRow(row.id, 0)"
            v-show="row.status==1"
          >
            <el-button
              slot="reference"
              type="text"
              size="small" >
              禁用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            title="确认启用？"
            @confirm="deleteRow(row.id, 1)"
            v-show="row.status!=1"
          >
            <el-button
              slot="reference"
              type="text"
              size="small">
              启用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            title="确认删除该banner图？"
            @confirm="handlerdelete(row)"
          >
            <el-button
              slot="reference"
              type="text"
              size="small">
              删除
            </el-button>
          </el-popconfirm>
                    <el-button
                      style="margin: 10px;"
                      @click.native.prevent="handleredit(row)"
                      type="text"
                      size="small">
                      编辑
                    </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>

  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addbanner from "./components/addbanner";
export default {
  name: "index",
  components: { Addbanner },
  data(){
    return {
      form: {
        type:1,
        status: -1,
        title:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      identitySelect:[
        {
          id: 1,
          name: '企业版首页顶部'
        }, {
          id: 2,
          name: '经销商版首页顶部'
        }, {
          id: 3,
          name: '综合版首页顶部'
        }
      ],
      columns: [
        {
          label: "标题",
          prop: "title",
          width: "",
        },

        {
          label: "banner图",
          prop: "pic",
          width: "160",
        },

        {
          label: "跳转方式",
          prop: "jump_type_txt",
          width: "",
        },
        {
          label: "排序",
          prop: "sort",
          width: "80",
        },

        {
          label: "状态",
          prop: "status_txt",
          width: "80",
        },
      ],
      shopSelect: [
        {
          id: -1,
          name: '全部'
        }, {
          id: 1,
          name: '启用'
        }, {
          id: 0,
          name: '禁用'
        }
      ],
      total: 0,
    }
  },
  mounted() {
    // this.handlerSelect()
    this.handlerInquire()
  },
  methods:{

    handlerSelect(){
      getAction("/audit/identity/identity",).then(res=>{
        this.identitySelect=res.data
      })
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerAdd(){
      this.$refs.banner.addbanner=true
    },
    handlerinfo(row){
      this.$refs.info.form=row
      this.$refs.info.showinfo=true
    },
    handlerlist(){
      getAction("/api/index/banner-list",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handlerdelete(row){
      postAction("/api/index/banner-del",{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    deleteRow(id,status){
      postAction("/api/index/banner-set",{id:id,status}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    handleredit(row){
      console.log(row)
      this.$refs.banner.addbanner=true
      this.$refs.banner.handlerinfo(row)

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
