<template>
  <el-dialog :title="title" :visible.sync="showDialog" width="50%">
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="一级品类">
        {{ form.cat_pname }}
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级品类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.root_cat_id == 1" label="价格带">
        <el-checkbox-group v-model="wine_price_id" @change="handleCheckChange">
          <el-checkbox
            v-for="(item, idx) in pricelist"
            :key="idx"
            :label="item.id"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="需求详情">
        <el-input v-model="form.detail" type="textarea" />
      </el-form-item>
      <el-form-item label="付费企业推荐">
        <el-input v-model="form.rec" type="textarea" />
      </el-form-item>
      <el-form-item label="沟通截图">
        <upload-img
          ref="uploadImage"
          :info-text="infoText"
          :max-size="100"
          @uploadImg="uploadImg"
        />
      </el-form-item>
      <el-form-item label="需求形式">
        <el-radio-group v-model="form.type">
          <el-radio label="1">找产品代理</el-radio>
          <el-radio label="2">找代工</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提报人">
        {{ form.staff_user_name }}
      </el-form-item>
      <el-form-item label="提报时间">
        {{ form.created }}
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
    },
    props: {
      rowid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showDialog: false,
        title: '编辑',
        infoText: '',
        catselect: [], //二级品类
        pricelist: [], //价格带
        reasonlist: [],
        remark: [],
        wine_price_id: [],
        other: '',
        form: {
          id: '',
          product_id: '',
          goods_name: '',
          sort: 0,
          status: '2',
          wine_price_id: '',
        },
      }
    },
    watch: {
      showDialog(val) {
        if (!val) {
          this.remark = []
          this.other = ''
        }
        this.wine_price_id = this.form.wine_price_id.split(',')
      },
    },
    mounted() {
      this.remark = []
      this.other = ''
      this.getPriceList()
    },

    methods: {
      handleCheckChange() {},
      uploadImg(val) {
        console.log(val)
        if (val.length < 1) {
          this.form.screenshot = []
        } else {
          this.form.screenshot = val
        }
      },
      handlercat(pid) {
        console.log('父级品类，', pid)
        getAction('/user/index/cate', { pid: pid }).then((res) => {
          this.catselect = res.data
        })
        setTimeout(() => {
          if (this.form.screenshot.length == 1) {
            this.$refs.uploadImage.img = [this.form.screenshot]
          } else {
            this.$refs.uploadImage.img = this.form.screenshot
          }
        }, 100)
        // this.form = Object.assign(this.form, String())
      },
      getPriceList() {
        getAction('/audit/biz-clue/wine-price').then((res) => {
          if (res.request_code == 200) {
            this.pricelist = res.data
          }
        })
      },
      handlersave() {
        console.log('确定', this.form, this.wine_price_id)
        postAction('/audit/biz-clue/edit-biz', {
          id: this.rowid,
          type: this.form.type, //1找产品代理，2找代工
          two_cate: this.form.cat_id,
          wine_price: this.wine_price_id.join(','),
          screenshot: this.form.screenshot.join(','),
          detail: this.form.detail,
          rec: this.form.rec,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.showDialog = false
          this.$emit('getlist')
        })
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
</style>
