<template>
  <el-dialog content :visible.sync="isShowStatis" width="900px">
    <div>
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="goods_name">
          <el-input
            style="width: 250px"
            v-model="form.goods_name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tablenum" style="width: 40%">
        <el-table-column
          label="总点击次数(PV)"
          prop="pv"
          align="center"
        ></el-table-column>
        <el-table-column
          label="总访客数(UV)"
          prop="uv"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            {{ row[item.prop] }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'addedit',
    data() {
      return {
        isShowStatis: false,
        form: {
          goods_name: '',
          page: 1,
          limit: 10,
        },
        total: 0,
        pv: 0,
        uv: 0,
        tabledata: [],
        tablenum: [],
        columns: [
          {
            label: '产品ID',
            prop: 'id',
            width: '',
          },
          {
            label: '产品标题',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '品类',
            prop: 'pcat_name',
            width: '',
          },
          {
            label: '店铺',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '点击数',
            prop: 'click_num',
            width: '',
          },
        ],

        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      isShowStatis(v) {
        if (!v) {
          this.onReset()
        }
      },
    },
    methods: {
      onSearch() {
        this.form.page = 1
        this.handlerlist()
      },
      onReset() {
        this.time = []
        this.form.goods_name = ''
        this.form.start_time = ''
        this.form.end_time = ''
      },
      handlerlist() {
        getAction('/api/form/search-rank/tj', this.form).then((res) => {
          if (res.request_code == 200) {
            this.tabledata = res.data.list
            this.tablenum = [{ pv: res.data.pv, uv: res.data.uv }]
            this.total = Number(res.totalCount)
            console.log('-------', this.tablenum)
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>
