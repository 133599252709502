<template>
  <el-dialog
    :title="title"
    :visible.sync="showedit"
    width="30%">
    <el-form ref="form" :model="form" label-width="180px" :rules="rules">
      <el-form-item label="选择店铺">
        <el-autocomplete
          style="width: 160px;"
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          placeholder="请输入店铺名称"
          @select="handleSelectmall"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="公司名称">
        {{company_name}}
      </el-form-item>
      <el-form-item label="变动方式" prop="operation_type">
        <el-select v-model="form.operation_type" clearable style="width: 160px;" placeholder="变动方式">
          <el-option label="增加" value="1"/>
          <el-option label="减少" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="变动类型" prop="operation_position">
        <el-select v-model="form.operation_position" clearable style="width: 160px;" placeholder="变动类型">
          <el-option label="套餐内" value="1"/>
          <el-option label="赠送" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="线上匹配查看次数">
        <el-input-number v-model="form.num" :min="0" label="查看次数"></el-input-number>
      </el-form-item>
      <el-form-item label="云会议次数">
        <el-input-number v-model="form.referrals_num" :min="0" label="查看次数"></el-input-number>
      </el-form-item>
      <el-form-item label="大商直通车次数">
        <el-input-number v-model="form.through_train_num" :min="0" label="查看次数"></el-input-number>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="3" placeholder="请输入内容" maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="图片留存">
        <upload-img ref="pic" :infoText="''" :limit="5" @getImgs="getGoods_imgImg($event, 'pic')" :maxSize="100"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showedit = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>


  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import UploadImg from "@/components/uploadImg.vue";

export default {
  name: "AddEdit",
  components: { UploadImg },

  data(){
    return{
      showedit:false,
      title:'新增',
      company_name:'',
      form:{
        mall_id:'',
        num:'',
        mall_name:'',
        through_train_num:'',
        referrals_num:'',
        operation_type:'',
        operation_position:'',
        pic:'',
      },
      rules:{
        operation_type: [
          { required: true, message: "请选择变动方式", trigger: "blur" }
        ],
        operation_position: [
          { required: true, message: "请选择变动类型", trigger: "blur" }
        ],
      }
    }
  },
  watch:{
    showedit(val){
      if(!val){
        this.form={
          mall_id:'',
          num:'',
          mall_name:'',
          through_train_num:'',
          referrals_num:'',
          change_type:'',
          operation_type:''
        }
        this.title="新增"
        this.company_name=''
        this.closeimg('pic')
      }
    }
  },
  methods:{
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction("/mall/give/create",this.form).then(res=>{
            if(res.request_code==200){
              this.showedit=false
              this.$message({
                type:'success',
                message:res.msg
              })
              this.$emit("getlist")
            }
          })
        }
      });

    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
      this.company_name=list.certification.company_name
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v.join(',')
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
