var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.showadd, width: "300px" },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展位号", prop: "booth_no" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: { placeholder: "展位号" },
                        model: {
                          value: _vm.form.booth_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "booth_no", $$v)
                          },
                          expression: "form.booth_no",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称", prop: "company" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: { placeholder: "企业名称" },
                        model: {
                          value: _vm.form.company,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "company", $$v)
                          },
                          expression: "form.company",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属模块", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "所属模块" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.typeSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联店铺" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          "fetch-suggestions": _vm.handlerarticle,
                          "value-key": "mall_name",
                          placeholder: "关联店铺",
                        },
                        on: { select: _vm.handleSelectarticle },
                        model: {
                          value: _vm.form.mall_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mall_name", $$v)
                          },
                          expression: "form.mall_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "1", label: "启用" },
                          }),
                          _c("el-option", {
                            attrs: { value: "0", label: "禁用" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort_order" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "160px" },
                        attrs: { min: 0 },
                        model: {
                          value: _vm.form.sort_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort_order", $$v)
                          },
                          expression: "form.sort_order",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v("` "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }