var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showAddstore,
        width: "350px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showAddstore = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "选择产品", prop: "object_id" } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      clearable: "",
                      "fetch-suggestions": _vm.handlergoods,
                      "value-key": "goods_name",
                      placeholder: "请输入商品名称",
                    },
                    on: { select: _vm.handleSelectgoods },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              )
            : _vm.form.type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "选择店铺", prop: "object_id" } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      clearable: "",
                      "fetch-suggestions": _vm.handlermell,
                      "value-key": "mall_name",
                      placeholder: "请输入店铺名称",
                    },
                    on: { select: _vm.handleSelectmall },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "政策", prop: "policy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.policy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "policy", $$v)
                  },
                  expression: "form.policy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort_order" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.sort_order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort_order", $$v)
                  },
                  expression: "form.sort_order",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showAddstore = !_vm.showAddstore
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }