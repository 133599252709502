<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="900px"
    top="7vh"
    :close-on-click-modal="false"
    :modal="true"
  >
    <div class="orderTest-container" style="width: 800px; margin: 0 auto" v-loading="loading">
      <el-form
        :model="form"
        ref="form"
        label-position="right"
        label-width="160px"
        :rules="rules"
        label-suffix=":"
      >
        <el-form-item label="消息标题" prop="title">
          <el-input
            v-model="form.title"
            maxlength="30"
            show-word-limit
            placeholder="最多30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="content">
          <el-input
            type="textarea"
            rows="4"
            v-model="form.content"
            placeholder="最多200个字"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="接收方" prop="type">
          <el-select v-model="form.type" style="width: 100%" clearable placeholder="请选择接收方">
            <el-option
              v-for="(i, idx) in receiveSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接收地区" prop="area">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group v-model="form.area" @change="handleCheckChange">
            <el-checkbox v-for="(city, idx) in citySelect" :label="city.id" :key="idx">{{
              city.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="接收行业" prop="">
          <el-checkbox
            :indeterminate="isIndeterminate2"
            v-model="checkAll2"
            @change="handleCheckAllChange2"
          >
            全选
          </el-checkbox>
          <el-checkbox-group v-model="form.industry" @change="handleCheckChange2">
            <el-checkbox v-for="(city, idx) in hangyeSelect" :label="city.id" :key="idx">{{
              city.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="接收渠道" prop="channel">
          <el-checkbox
            :indeterminate="isIndeterminate4"
            v-model="checkAll4"
            @change="handleCheckAllChange4"
          >
            全选
          </el-checkbox>
          <el-checkbox-group v-model="form.channel" @change="handleCheckChange4">
            <el-checkbox v-for="(sf, idx) in qudaoSelect" :label="sf.id" :key="idx">{{
              sf.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <!--//! End  -->
        <el-form-item label="跳转类型" prop="jump_type">
          <el-select
            v-model="form.jump_type"
            style="width: 100%"
            clearable
            filterable
            placeholder="请选择跳转类型"
            @change="jumpTypeChange"
          >
            <el-option
              v-for="(i, idx) in jumpTypeSelect"
              :key="idx"
              :value="i.jump_id"
              :label="i.jump_page"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="form.jump_type!=6" label="跳转链接文字内容" prop="button_title">
          <el-input
            v-model="form.button_title"
            clearable
            placeholder="最多20个字"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="form.jump_type==2||form.jump_type==10||form.jump_type==1||form.jump_type==3||form.jump_type==12" :label="jumpLabel" prop="jump_val">
            <el-input clearable v-if="form.jump_type==3 || form.jump_type==12"  v-model="form.jump_val"  :placeholder="jump_name" style="width: 160px;"/>
            <el-autocomplete
              v-if="form.jump_type==1"
              style="width: 160px;"
              clearable
              v-model="form.jump_val_name"
              :fetch-suggestions="handlermell"
              value-key="mall_name"
              placeholder="请输入店铺名称"
              @select="handleSelectmall"
              suffix-icon="el-icon-arrow-down"
            ></el-autocomplete>
            <el-autocomplete
              v-else-if="form.jump_type==2"
              style="width: 160px;"
              clearable
              v-model="form.jump_val_name"
              :fetch-suggestions="handlergoods"
              value-key="goods_name"
              placeholder="请输入商品名称"
              @select="handleSelectgoods"
              suffix-icon="el-icon-arrow-down"
            ></el-autocomplete>
          <el-autocomplete
            v-else-if="form.jump_type==10"
            style="width: 160px;"
            clearable
            v-model="form.jump_val_name"
            :fetch-suggestions="handlerhouse"
            value-key="house_name"
            placeholder="请输入展馆名称"
            @select="handleSelecthouse"
            suffix-icon="el-icon-arrow-down"
          ></el-autocomplete>
          <div v-else></div>
        </el-form-item>

        <el-form-item label="发送类型" prop="send_type">
          <el-radio :label="1" v-model="form.send_type">立即发送</el-radio>
          <el-radio :label="2" v-model="form.send_type">定时发送</el-radio>
        </el-form-item>
        <el-form-item v-if="form.send_type == 2" label="定时发送时间" prop="send_time">
          <el-date-picker
            v-model="form.send_time"
            type="datetime"
            clearable
            placeholder="选择发送时间"
            default-time="12:00:00"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button type="success" @click="handlePreview">预览</el-button>
        <el-button @click="showDialog = false">取消</el-button>
      </div>
      <Preview ref="preview"></Preview>
    </div>
  </el-dialog>
</template>
<script>
import Preview from "./preview"
import { getAction, postAction } from "@/api/request";
import { dateForm } from "@/utils/time";
export default {
  name: "AddEdit",
  components: { Preview },
  data() {
    const checkJumpType = (rules, val, cb) => {
      if (val != "") {
        cb()
      } else {
        if ([1, 2, 3, 4, 5, 8, 9, 10, 11, 12].includes(this.form.jump_type)) {
          cb(new Error("请选择"))
        } else {
          cb()
        }
      }
    }
    return {
      type: 1,
      loading: false,
      id: null,
      showDialog: false,
      jumpLabel:'',
      jumpTypeSelect: [],
      jump_name:'',
      // 接收方，0全部，1企业，2经销商
      receiveSelect: [
        { id: 0, name: "全部" },
        { id: 1, name: "企业" },
        { id: 2, name: "经销商" },
        { id: 3, name: "其他" },
      ],
      form: {
        type: 0, // 接收方
        jump_type: '', //跳转类型
        send_type: 1,
        jump_val: "",
        area: [],
        channel: [],
        industry: [],
      },
      rules: {
        title: [{ required: true, message: "请输入标题" }],
        content: [{ required: true, message: "请输入通知内容" }],
        type: [{ required: true, message: "请选择接收方" }],
        jump_type: [{ required: true, message: "请输入跳转类型" }],
        button_title: [{ required: true, message: "请输入跳转链接文字内容" }],
        jump_val: [{ required: true, validator: checkJumpType }],
      },
      qudaoSelect: [],
      citySelect: [],
      checkAll: false,
      checkAll2: false,
      checkAll4: false,
      isIndeterminate: false,
      isIndeterminate2: false,
      isIndeterminate4: false,
      hangyeSelect: [],
    }
  },
  created() {},
  mounted() {
    this.initSelect()
  },
  computed: {
    title() {
      if (this.type == 1) {
        return "新增"
      } else {
        return "编辑"
      }
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        if (this.type == 2) {
          // this.fetchData()
        }
      } else {
        this.$refs.form.resetFields()
        this.checkAll = false
        this.checkAll2 = false
        this.checkAll4 = false
        this.isIndeterminate = false
        this.isIndeterminate2 = false
        this.isIndeterminate4 = false
        this.jump_name='',
        this.form = {
          type: 0, // 接收方
          jump_type: '', //跳转类型
          send_type: 1,
          jump_val: "",
          area: [],
          channel: [],
          industry: [],
        }
      }
    },
  },
  methods: {
    currSelect(type) {
      return this["select" + type]
    },
    async initSelect() {
      let jumplist = await postAction("/audit/advert/jump-type")
      this.jumpTypeSelect = jumplist.data
      let city = await getAction("/user/index/area",{pid:0})
      this.citySelect = city.data
      let hangyeSelect = await getAction("/user/index/cate",{pid:0})
      this.hangyeSelect = hangyeSelect.data
      let qudao = await getAction("/user/index/channel")
      this.qudaoSelect = qudao.data
    },
    fetchData(data) {
        this.loading = true
        console.log(data)
      let datas=JSON.parse(JSON.stringify(data))
      if(datas.area==''){
        datas.area=[]
      }else{
        datas.area=datas.area.split(',')
      }
      if(datas.industry==''){
        datas.industry=[]
      }else{
        datas.industry=datas.industry.split(',')
      }
      if(datas.channel==''){
        datas.channel=[]
      }else{
        datas.channel=datas.channel.split(',')
      }


      datas.send_time=dateForm(new Date(datas.send_time))
      console.log(datas)
      this.form = Object.assign(this.form,datas)
        // this.form = { ...this.form, ...data }
        // console.log("this.form.jump_type", this.form.jump_type)
        // // if (this.form.jump_type != 3 && this.form.jump_type != 4) {
          this.form.jump_val = String(this.form.jump_val)
        this.form.jump_type = String(this.form.jump_type)
        // }
        if (this.form.area.length < this.citySelect.length && this.form.area.length != 0) {
          this.isIndeterminate = true
        } else if (this.form.area.length == this.citySelect.length) {
          this.checkAll = true
        }
        if (
          this.form.industry.length < this.hangyeSelect.length &&
          this.form.industry.length != 0
        ) {
          this.isIndeterminate2 = true
        } else if (this.form.industry.length == this.hangyeSelect.length) {
          this.checkAll2 = true
        }
        if (this.form.channel.length < this.qudaoSelect.length && this.form.channel.length != 0) {
          this.isIndeterminate4 = true
        } else if (this.form.channel.length == this.qudaoSelect.length) {
          this.checkAll4 = true
        }
        this.loading = false

    },
    verifyForm(cb) {
      this.$refs.form.validate((v) => {
        if (v) {
          cb()
        }
      })
    },
    jumpTypeChange(v){
      console.log(v)
      this.form.jump_val = ''
     this.jump_name= this.jumpTypeSelect.filter(list=>list.jump_id==v)[0].jump_page
      this.jumpLabel=this.jumpTypeSelect.filter(list=>list.jump_id==v)[0].jump_page
    },
    async handleSubmit() {
      console.log("form", this.form)
      this.verifyForm(async () => {
        try {
          this.loading = true
          const industry = this.form.industry.length == 0 ? "" : this.form.industry.join()
          const area = this.form.area.length == 0 ? "" : this.form.area.join()

          let res = await postAction("/user/message/edit", {
            ...this.form,
            ...{ area, industry },
            channel: this.form.channel.join(),
          })
          console.log("res", res)
          this.$message.success(res.msg)
          this.showDialog = false
          this.$emit("refresh")
          this.loading = false
        } catch (err) {
          console.log("提交出错")
          this.loading = false
        }
      })
    },




    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_val=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_val=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlerhouse(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.jump_val=''
      }else{
        getAction("/api/index/house",{house_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelecthouse(list){
      console.log(list)
      this.form.jump_val=list.id
    },
    handleSelectmall(list){
      console.log(list)
      this.form.jump_val=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.jump_val=list.id
    },









    handlePreview() {
      if (this.form.title && this.form.content) {
        this.$refs.preview.form = this.form
        this.$refs.preview.showDialog = true
      } else {
        this.$message.error("请输入标题和内容")
      }
    },
    handleCheckChange(val) {
      const checkLength = val.length
      this.checkAll = checkLength === this.citySelect.length
      this.isIndeterminate = checkLength > 0 && checkLength < this.citySelect.length
    },
    handleCheckAllChange(val) {
      const city = this.citySelect.map((c) => c.id)
      this.form.area = val ? city : []
      this.isIndeterminate = false
    },

    handleCheckChange2(val) {
      const checkLength = val.length
      this.checkAll2 = checkLength === this.hangyeSelect.length
      this.isIndeterminate2 = checkLength > 0 && checkLength < this.hangyeSelect.length
    },
    handleCheckAllChange2(val) {
      const hangye = this.hangyeSelect.map((c) => c.id)
      this.form.industry = val ? hangye : []
      this.isIndeterminate2 = false
    },


    handleCheckChange4(val) {
      const checkLength = val.length
      this.checkAll4 = checkLength === this.qudaoSelect.length
      this.isIndeterminate4 = checkLength > 0 && checkLength < this.qudaoSelect.length
    },
    handleCheckAllChange4(val) {
      const qudao = this.qudaoSelect.map((c) => c.id)
      this.form.channel = val ? qudao : []
      this.isIndeterminate4 = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
