var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "封号记录",
        visible: _vm.showDialog,
        width: "500px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c("div", { staticStyle: { padding: "10px 0" } }, [
            _vm._v("共锁定次数：" + _vm._s(_vm.total)),
          ]),
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "el-card",
              { key: index, staticStyle: { "margin-bottom": "10px" } },
              [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(item.mode) + "时间:")]),
                  _c("span", [_vm._v(_vm._s(item.add_time))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v(_vm._s(item.mode) + "方式:")]),
                  _c("span", [_vm._v(_vm._s(item.method))]),
                ]),
                item.method == "手动锁定" || item.method == "自动锁定"
                  ? _c("div", [
                      _c("span", [_vm._v("锁定原因:")]),
                      _c("span", [_vm._v(_vm._s(item.reason))]),
                    ])
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "bottom-button" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }