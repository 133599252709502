<template>
  <el-dialog
    :title="title"
    :visible.sync="showkong"
    width="80%">
    <div>
      <el-form ref="form" :model="form" label-suffix=":" inline>
        <el-form-item >
          <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
            <el-option v-for="list in shopSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>

        <el-form-item >
          <el-input placeholder="标题" clearable v-model="form.title" style="width: 160px;"/>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handleradd">新 增</el-button>
        </el-form-item>

      </el-form>

      <el-table
        border
        :data="tabledata"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{row}">
            <div v-if="item.label=='图标'">
              <el-image
                style="width: 30px; height: 30px"
                :src="row.pic"
                :preview-src-list="[row.pic]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <!--          <div v-else-if="item.label=='店铺logo'">
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="row.logo"
                          :preview-src-list="[row.logo]">
                          <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                          </div>
                        </el-image>
                      </div>-->
            <div v-else>{{row[item.prop]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="220">
          <template  #default="{row}">
            <el-popconfirm
              title="确认删除该金刚区图标？"
              @confirm="handlerdelete(row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small">
                删除
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin: 10px;"
              @click.native.prevent="handleredit(row)"
              type="text"
              size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <addkong ref="addkong" @getlist="handlerInquire"/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showkong = false">取 消</el-button>
      <el-button type="primary" @click="showkong = false">确 定</el-button>
    </span>

  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addkong from "./addkong";

export default {
  name: "konglist",
  components: { Addkong },
  data(){
    return {
      title:'菜单设置',
      showkong:false,
      form: {
        house_id:'',
        type:4,
        status: '',
        title:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "标题",
          prop: "title",
          width: "",
        },

        {
          label: "图标",
          prop: "pic",
          width: "120",
        },

        {
          label: "跳转方式",
          prop: "jump_type_txt",
          width: "110",
        },
        {
          label: "排序",
          prop: "sort",
          width: "110",
        },

        {
          label: "状态",
          prop: "status_txt",
          width: "80",
        },

      ],
      shopSelect: [
       {
          id: 1,
          name: '启用'
        }, {
          id: 0,
          name: '禁用'
        }
      ],
      total: 0,
    }
  },

  methods:{

    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerinfo(row){
      this.$refs.addkong.form=row
      this.$refs.addkong.addkong=true
    },
    handleradd(){
      this.$refs.addkong.addkong=true
      this.$refs.addkong.form.house_id=this.form.house_id
    },
    handlerlist(){
      getAction("/api/index/menu-list",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handlerdelete(row){
      postAction("/api/index/menu-del",{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    async handleredit(row){
      console.log(row)
      this.$refs.addkong.addkong=true
      await this.$refs.addkong.handlerinfo(row)

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
