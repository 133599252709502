<template xmlns="http://www.w3.org/1999/html">
  <div>
    <el-dialog :title="title" :visible.sync="showEditPoster" width="50%">
      <div>
        <el-form ref="form" label-width="120px" :model="form">
          <el-form-item label="一级品类" prop="cat_id">
            <el-select
              v-model="form.cat_id"
              clearable
              placeholder="分类"
              style="width: 160px"
            >
              <el-option
                v-for="list in shopSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标签名称" prop="label">
            <el-input
              v-model="form.label"
              placeholder="请输入"
              style="width: 220px"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditPoster = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    components: {},
    props:{
      shopSelect:{
        type:Array,
        default() {
          return []
        },
      }
    },
    data() {
      return {
        title: '新增',
        showEditPoster: false,
        form: {
          id: null,
          label: '',
          cat_id: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          this.resetForm()
          this.title = '新增'
        }
      },
    },
    mounted() {},
    methods: {
      resetForm() {
        this.$refs.form.resetFields()
        this.form = {
          id: null,
          label: '',
          cat_id: '',
        }
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
      },
      handlersave() {
            postAction('/api/system/category-labels/edit', this.form).then((res) => {
              if (res.request_code == 200) {
                this.showEditPoster = false
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.$emit('getlist')
              }
            })
      },
    },
  }
</script>

<style scoped></style>
