<template>
  <div>
    <el-dialog title="详情" :visible.sync="showinfo" width="60%">
      <div>
        <el-row  :gutter="10" justify="space-between">
          <el-col :span="12">
            <h4>已付款订单明细：</h4>
            <el-table :data="pay_list" style="width: 100%">
              <el-table-column label="订单编号" prop="id" width="80" />
              <el-table-column align="center" label="提交时间" prop="created" />
              <el-table-column
                align="center"
                label="订单金额"
                prop="order_price"
              />
            </el-table>
            <div style="margin-top: 10px">总计：{{ pay_money }}</div>
          </el-col>
          <el-col :span="12">
            <el-row>
              <h4>公对公付款明细：</h4>
              <el-table :data="exchange_list" style="width: 100%">
                <el-table-column align="center" label="付款时间" prop="created" />
                <el-table-column align="center" label="付款金额" prop="trxamt" />
              </el-table>
              <div style="margin-top: 10px">总计：{{ exchange_money }}</div>
            </el-row>
            <el-row>
              <div>
                <h4>溢缴款退款明细：</h4>
                <el-descriptions
                  v-for="(item, index) in back_list"
                  :key="index"
                  :column="3"
                >
                  <el-descriptions-item label="退款时间">
                    {{ item.created }}
                  </el-descriptions-item>
                  <el-descriptions-item label="退款金额">
                    {{ item.back_money }}
                  </el-descriptions-item>
                  <el-descriptions-item label="操作人">
                    {{ item.admin_user }}
                  </el-descriptions-item>
                </el-descriptions>
                <h4>溢缴款待退款：</h4>
                <p>待退款金额：{{ more_money }}</p>
              </div>
            </el-row>
          </el-col>
        </el-row>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showinfo = false">取 消</el-button>
        <el-button type="primary" @click="handlerback">全部退款</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'Infocom',
    data() {
      return {
        showinfo: false,
        form: {},
        back_list: [],
        pay_list: [],
        exchange_list: [],
        pay_money: '', //付款总计
        exchange_money: '', //公对公付款总计
        more_money: '', //溢缴款待退款金额
        mall_id: '',
      }
    },
    methods: {
      handlerinfo(mall_id) {
        this.mall_id = mall_id
        postAction('/mall/more-money/info', { mall_id: mall_id }).then(
          (res) => {
            this.form = res.data
            this.back_list = res.data.back_list
            this.pay_list = res.data.pay_list
            this.exchange_list = res.data.exchange_list
            this.more_money = res.data.more_money
            this.pay_money = res.data.pay_money
            this.exchange_money = res.data.exchange_money
          }
        )
      },
      handlerback() {
        postAction('/mall/more-money/back', { mall_id: this.mall_id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
            }
          }
        )
      },
    },
  }
</script>

<style scoped></style>
