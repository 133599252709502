<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="客户姓名">
        <el-input
          v-model="form.company_apply_name"
          clearable
          placeholder="客户姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          v-model="form.nickname"
          clearable
          placeholder="昵称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="客户电话">
        <el-input
          v-model="form.company_mobile"
          clearable
          placeholder="客户电话"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="提报人">
        <el-input
          v-model="form.plan_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item>

      <el-form-item label="引荐状态">
        <el-select
          v-model="form.introduction_status"
          clearable
          placeholder="引荐状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="云会议">
        <el-select
          v-model="form.introduction_meet_type"
          clearable
          placeholder="云会议"
          style="width: 160px"
        >
          <el-option
            v-for="list in yhylist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '头像'">
            <el-image
              :preview-src-list="[row.headimg]"
              :src="row.headimg"
              style="width: 80px; height: 80px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '用户状态'">
            {{ row.is_delaer == 1 ? '已认证' : '未认证' }}
          </div>
          <div v-else-if="item.label == '云会议是否有效'">
            {{ row[item.prop] == 1 ? '有效' : '无效' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template #default="{ row }">
          <el-popconfirm
            v-if="row.introduction_status !== 2"
            style="margin-left: 10px"
            title="确认取消引荐吗？"
            @confirm="handlerrefuse(row)"
          >
            <el-button slot="reference" size="small" type="text">
              取消引荐
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import dateFormat from '@/utils/Timeformat'
  import * as excel from '@/utils/excel'

  import vueQr from 'vue-qr'
  export default {
    components: {
      vueQr,
    },
    data() {
      return {
        form: {
          goods_name: '', //需求客户
          cat_pid: '', //一级品类id
          cat_id: '', //二级品类id
          status: null, //1 启用  2禁用
          page: 1,
          limit: 10,
        },
        cateselect: [],
        catselect: [],
        showSelsect: [
          {
            id: 1,
            name: '已申请引荐',
          },
          {
            id: 2,
            name: '已取消引荐',
          },
          {
            id: 3,
            name: '已完成引荐',
          },
        ],
        yhylist: [
          {
            id: 3,
            name: '普通云会议',
          },
          {
            id: 4,
            name: '大商直通车',
          },
        ],

        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '头像',
            prop: 'headimg',
            width: '220px',
          },
          {
            label: '客户姓名',
            prop: 'company_apply_name',
            width: '120px',
          },

          {
            label: '客户电话',
            prop: 'company_mobile',
            width: '160',
          },
          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },

          {
            label: '用户状态',
            prop: 'is_delaer',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '220px',
          },
          {
            label: '引荐状态',
            prop: 'introduction_status_txt',
            width: '',
          },
          {
            label: '会议',
            prop: 'introduction_meet_type_txt',
            width: '',
          },
          {
            label: '提报人',
            prop: 'plan_name',
            width: '',
          },
          {
            label: '引荐时间',
            prop: 'introduction_time',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
        timelist: [], // dateFormat(new Date() - 3600 * 1000 * 24 * 30),dateFormat(new Date()),
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      //查看云会议详情
      handlercloudinfo(row) {
        this.$refs.cloudinfo.form.id = row.meeting_id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloudinfo.showcloud = true
      },
      //查看调查问卷详情
      handlersurveyinfo(row) {
        this.$refs.survey.showinfo = true
        this.$refs.survey.surveyinfo = row
      },
      handlerInquire() {
        this.form.id = ''
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerExport() {
        postAction('/api/work/sel-plan/introduction-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      handlerlist() {
        postAction('/api/work/sel-plan/introduction-lists', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            this.total = Number(res.data.total)
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },

      handlerreedit(row) {
        this.$refs.add.showedit = true
        // this.$refs.add.handlerinfo(row)
        this.$refs.add.form = {
          id: row.id,
          mall_id: row.mall_id,
          goods_id: row.goods_id,
          mall_name: row.mall_name,
          goods_name: row.goods_name,
          sort: row.sort,
          status: row.status,
        }
        this.$refs.add.mall_name = row.mall_name
        this.$refs.add.title = '编辑'
      },
      handlerrefuse(row) {
        postAction('/api/work/sel-plan/cancel-introduction', {
          id: row.id,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerfurm(row) {
        this.showValid = true
        this.$refs.invaildreason.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.invaildreason.showValid = true
        // postAction('/audit/biz-clue/set-unvalid', {
        //   id: row.id,
        //   reason: this.validreason,
        // }).then((res) => {
        //   this.$message({
        //     type: 'success',
        //     message: res.msg,
        //   })
        //   this.handlerlist()
        // })
      },
    },
  }
</script>

<style scoped></style>
