<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.kind"
          clearable
          filterable
          placeholder="一级事业部"
          style="width: 160px"
        >
          <el-option
            v-for="list in kindselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.dept"
          clearable
          filterable
          placeholder="二级部门"
          style="width: 160px"
        >
          <el-option
            v-for="list in departselect"
            :key="list"
            :label="list"
            :value="list"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.staff_name"
          clearable
          placeholder="员工姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          :clearable="false"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">
          导出全部标签数据
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerExport(row)"
          >
            导出标签数据
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dateFormat from '@/utils/Timeformat'
  // import { param } from 'dist/static/UEditor/third-party/jquery-1.10.2'

  export default {
    data() {
      return {
        form: {
          staff_name: '', //员工姓名
          page: 1,
          limit: 10,
          start_date: dateFormat(new Date() - 3600 * 1000 * 24), //dateFormat(new Date() - 3600 * 1000 * 24),
          end_date: dateFormat(new Date() - 3600 * 1000 * 24), //dateFormat(new Date() - 3600 * 1000 * 24),
          dept: '', //部门
          kind: '', //事业部
        },
        tableloading: false,
        time: [
          dateFormat(new Date() - 3600 * 1000 * 24),
          dateFormat(new Date() - 3600 * 1000 * 24),
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '员工姓名',
            prop: 'staff_name',
            width: '',
          },
          {
            label: '一级事业部',
            prop: 'htyun_kind',
            width: '',
          },
          {
            label: '二级部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '企微打标签人数',
            prop: 'people_uv_nums',
            width: '',
          },
          {
            label: '企微打标签次数',
            prop: 'people_pv_nums',
            width: '',
          },
          {
            label: '标签已完善人数',
            prop: 'is_ok',
            width: '',
          },
          {
            label: '标签未完善人数',
            prop: 'is_no',
            width: '',
          },
        ],
        total: 0,
        departselect: [],
        kindselect: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getKind()
      this.getDepart()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      getKind() {
        postAction('/api/whale/tag/get-kind').then((res) => {
          if (res.request_code == 200) {
            this.kindselect = res.data
          }
        })
      },
      getDepart() {
        postAction('/api/whale/tag/get-dept').then((res) => {
          if (res.request_code == 200) {
            this.departselect = res.data
          }
        })
      },
      handlerExport(row) {
        console.log('---', row)
        let params = {}
        if (row) {
          params.staff_name = row.staff_name
          params.start_date = this.form.start_date
          params.end_date = this.form.end_date
        } else {
          params.kind = this.form.kind
          params.start_date = this.form.start_date
          params.end_date = this.form.end_date
          params.dept = this.form.dept
          params.staff_name = this.form.staff_name
        }
        getAction('/api/whale/tag/lists-export', params).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        this.tableloading = true
        await postAction('/api/whale/tag/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
