var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "500px",
        top: "22vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "orderTest-container" }, [
        _c(
          "div",
          { staticStyle: { "text-align": "center", "line-height": "2" } },
          [
            _c("div", { staticStyle: { "font-size": "16px" } }, [
              _vm._v(_vm._s(_vm.info1)),
            ]),
            _c("div", { staticStyle: { "font-size": "16px" } }, [
              _vm._v(_vm._s(_vm.info2)),
            ]),
            _vm.type == 1
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "15px" } },
                  [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("锁定原因:"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "可选" },
                        model: {
                          value: _vm.val,
                          callback: function ($$v) {
                            _vm.val = $$v
                          },
                          expression: "val",
                        },
                      },
                      _vm._l(_vm.dialogSelect, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "bottom-button center" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }