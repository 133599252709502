<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="一级品类">
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="产品名称"
          style="width: 160px"
        />
      </el-form-item>

      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              :preview-src-list="[row.goods_img]"
              :src="row.goods_img"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
          <!-- <div v-else-if="item.label == '操作'">
            <el-button size="small" type="text" @click="handlerreedit(row)">
              编辑
            </el-button>
            <el-button
              v-show="row.need_connect_status != 1"
              size="small"
              type="text"
              @click="handlerrefuse(row)"
            >
              删除
            </el-button>
          </div> -->
          <div v-else-if="item.label == '云会议是否有效'">
            {{ row[item.prop] == 1 ? '有效' : '无效' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template #default="{ row }">
          <el-button size="small" type="text" @click="handlerreedit(row)">
            编辑
          </el-button>
          <el-popconfirm
            style="margin-left: 10px"
            title="确认删除？"
            @confirm="handlerrefuse(row)"
          >
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-select ref="add" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import AddSelect from './components/addedit'
  import vueQr from 'vue-qr'
  export default {
    components: {
      AddSelect,
      vueQr,
    },
    data() {
      return {
        form: {
          goods_name: '', //需求客户
          cat_pid: '', //一级品类id
          cat_id: '', //二级品类id
          status: null, //1 启用  2禁用
          page: 1,
          limit: 10,
        },
        cateselect: [],
        catselect: [],
        showSelsect: [
          {
            id: 1,
            name: '启用',
          },
          {
            id: 2,
            name: '禁用',
          },
        ],

        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'good_id',
            width: '',
          },
          {
            label: '产品名称',
            prop: 'goods_name',
            width: '220px',
          },
          {
            label: '封面图',
            prop: 'goods_img',
            width: '120px',
          },

          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '160',
          },
          {
            label: '企业名称',
            prop: 'company_name',
            width: '',
          },

          {
            label: '品类',
            prop: 'pcat',
            width: '220px',
          },
          {
            label: '二级品类',
            prop: 'cat',
            width: '',
          },
          {
            label: '排序',
            prop: 'sort',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handleraddcloud(row) {
        this.$refs.cloud.form.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloud.showcloud = true
      },
      //查看云会议详情
      handlercloudinfo(row) {
        this.$refs.cloudinfo.form.id = row.meeting_id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloudinfo.showcloud = true
      },
      //查看调查问卷详情
      handlersurveyinfo(row) {
        this.$refs.survey.showinfo = true
        this.$refs.survey.surveyinfo = row
      },
      handlerInquire() {
        this.form.id = ''
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-selection', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      handlerlist() {
        postAction('/api/work/sel-plan-goods/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.add.showedit = true
        this.$refs.add.form.created_txt = new Date()
      },
      handlerreedit(row) {
        this.$refs.add.showedit = true
        // this.$refs.add.handlerinfo(row)
        this.$refs.add.form = {
          id: row.id,
          mall_id: row.mall_id,
          goods_id: row.goods_id,
          mall_name: row.mall_name,
          goods_name: row.goods_name,
          sort: row.sort,
          status: row.status,
          created_txt: row.created_txt,
        }
        console.log('-00-0-0-0-', this.getDate())
        this.$refs.add.mall_name = row.mall_name
        this.$refs.add.title = '编辑'
      },
      getDate() {
        var now = new Date()
        var year = now.getFullYear() //得到年份
        var month = now.getMonth() //得到月份
        var date = now.getDate() //得到日期
        var hour = ' 00:00:00' //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
        month = month + 1
        month = month.toString().padStart(2, '0')
        date = date.toString().padStart(2, '0')
        var defaultDate = `${year}-${month}-${date}` //
        return defaultDate
      },

      handlerrefuse(row) {
        postAction('/api/work/sel-plan-goods/delete', { id: row.id }).then(
          (res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          }
        )
      },
      handlerfurm(row) {
        this.showValid = true
        this.$refs.invaildreason.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.invaildreason.showValid = true
        // postAction('/audit/biz-clue/set-unvalid', {
        //   id: row.id,
        //   reason: this.validreason,
        // }).then((res) => {
        //   this.$message({
        //     type: 'success',
        //     message: res.msg,
        //   })
        //   this.handlerlist()
        // })
      },
    },
  }
</script>

<style scoped></style>
