var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v("调查问卷（2024春糖）统计"),
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            border: "",
            data: _vm.tabledata,
            "span-method": _vm.objectTplSpanMethod,
          },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                item.label == "操作"
                  ? {
                      key: "default",
                      fn: function ({ row, $index }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlerInit(row)
                                },
                              },
                            },
                            [_vm._v("答题详情")]
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("info", { ref: "info" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }