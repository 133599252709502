var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { attrs: { modal: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleradd } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: item.width,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                item.label == "banner图"
                  ? {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.banner
                            ? _c("el-image", {
                                staticStyle: { width: "150px", height: "70px" },
                                attrs: {
                                  src: row.banner,
                                  "preview-src-list": [row.banner],
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    }
                  : item.label == "分享图"
                  ? {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row[item.prop]
                            ? _c("el-image", {
                                staticStyle: { width: "80px", height: "80px" },
                                attrs: {
                                  src: row[item.prop],
                                  "preview-src-list": [row[item.prop]],
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    }
                  : item.label == "操作"
                  ? {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlerEdit(row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.handlerdelet(row)
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0px 10px" },
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlerview(row)
                                },
                              },
                            },
                            [_vm._v("栏目设置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlerstatus(row)
                                },
                              },
                            },
                            [_vm._v("小程序码")]
                          ),
                        ]
                      },
                    }
                  : {
                      key: "default",
                      fn: function ({ row }) {
                        return [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                      },
                    },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add-edit", {
        ref: "addedit",
        attrs: { title: _vm.title },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("listinfo", { ref: "listinfo" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "小程序码",
            visible: _vm.qrcodeshow,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.qrcodeshow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c("el-image", {
                staticStyle: { width: "180px", height: "180px" },
                attrs: { src: _vm.qrcode, "preview-src-list": [_vm.qrcode] },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.qrcodeshow = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }