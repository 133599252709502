import request from '@/utils/request'
export function getList(params) {
  return request({
    url: '/users/mch-user/index',
    method: 'get',
    params,
  })
}
export function setList(params) {
  return request({
    url: '/users/mch-user/add',
    method: 'post',
    params,
  })
}
export function getoption(params) {
  return request({
    url: '/common/common/scope-list',
    method: 'get',
    params,
  })
}
