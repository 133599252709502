<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="需求客户" clearable v-model="form.external_name" style="width: 180px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="会议管理员" clearable v-model="form.staff_name" style="width: 180px;"/>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.meeting_status" clearable style="width: 160px;" placeholder="会议状态">

          <el-option value="0" label="全部"/>
          <el-option value="1" label="已创建"/>
          <el-option value="2" label="已完成"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.audit_status" clearable style="width: 160px;" placeholder="审核状态">
          <el-option value="0" label="全部"/>
          <el-option value="2" label="待审核"/>
          <el-option value="3" label="有效"/>
          <el-option value="4" label="无效"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>

    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='沟通图'">
           <span v-for="(list,index) in row[item.prop]" :key="index">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="list"
                  :preview-src-list="[list]">
                 <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
                 </div>
                </el-image>
              </span>
          </div>
          <div v-else-if="item.label=='会议室截图'">
           <span v-for="(list,index) in row[item.prop]" :key="index">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="list"
                  :preview-src-list="[list]">
                 <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
                 </div>
                </el-image>
              </span>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="160">
        <template  #default="{row}">
          <div v-if="row.meeting_status==2&&row.audit_status==2">
            <el-button
              @click="handlerpass(row,3)"
              type="text"
              size="small">
              有效
            </el-button>
            <el-button
              @click="handlerpass(row,4)"
              type="text"
              size="small" >
              无效
            </el-button>
          </div>
          <div v-if="row.audit_status==3">
            <el-button
              @click="handlerrefuse(row)"
              type="text"
              size="small">
              查看奖励
            </el-button>

          </div>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  data(){
    return {
      form: {
        external_name: '',
        staff_name: "",
        meeting_status:'0',
        audit_status:'0',
        biz_clue_id:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "会议标题",
          prop: "title",
          width: "",
        },
        {
          label: "会议管理员",
          prop: "staff_name",
          width: "220px",
        },
        {
          label: "需求客户",
          prop: "external_name",
          width: "",
        },
        {
          label: "参会同事",
          prop: "other_staff",
          width: "",
        },
        {
          label: "开始时间",
          prop: "meeting_start",
          width: "160",
        },
        {
          label: "持续时间",
          prop: "meeting_duration",
          width: "",
        },
        {
          label: "会议描述",
          prop: "desc",
          width: "",
        },
        {
          label: "关联店铺",
          prop: "mall_name",
          width: "220px",
        },
        {
          label: "关联产品",
          prop: "goods_name",
          width: "",
        },
        {
          label: "备注",
          prop: "remark",
          width: "",
        },
        {
          label: "沟通图",
          prop: "connect_img",
          width: "600",
        },
        {
          label: "提报时间",
          prop: "created",
          width: "160",
        },
        {
          label: "会议室截图",
          prop: "room_img",
          width: "600",
        },
        {
          label: "满意度调查",
          prop: "degree_type",
          width: "220px",
        },
        {
          label: "会议状态",
          prop: "meeting_status_txt",
          width: "",
        },
        {
          label: "审核状态",
          prop: "audit_status_txt",
          width: "",
        },
        {
          label: "操作人",
          prop: "audit_name",
          width: "",
        },
        /*{
          label: "持续时间",
          prop: "mall_name",
          width: "",
        }*/
      ],

      total: 0,
    }
  },
  mounted() {
    console.log(this.$route.query.id)
    if(this.$route.query.id){
      this.form.biz_clue_id=this.$route.query.id
      this.handlerlist()
    }else{
      this.handlerInquire()
    }

  },
  methods:{
    handlerInquire(){
      this.form.biz_clue_id=''
      this.form.page=1
      this.handlerlist()
    },
    handlerpass(row,type){
      postAction("/audit/biz-clue/meeting-audit",{id:row.id,status:type,}).then(res=>{
        this.handlerInquire()
        this.$message({
          type: 'success',
          message: res.msg
        });
      })
    },
    handlerrefuse(row){
      this.$router.push({ path: "/WeCom/reward",query: { id:row.id }})
    },
    handlerlist(){
      postAction("/audit/biz-clue/meeting-lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
