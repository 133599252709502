<template>
  <el-dialog
    title="二级菜单"
    :visible.sync="secondaryshow"
    width="70%"
    :destroy-on-close="true"
  >
    <div>
      <el-form ref="form" :model="form" label-suffix=":" inline>
        <el-form-item >
          <el-input placeholder="主题活动" clearable v-model="form.title" style="width: 160px;"/>
        </el-form-item>
        <el-form-item >
          <el-select v-model="form.type" placeholder="关联类型" clearable  style="width: 160px;">
            <el-option label="产品" value="1"/>
            <el-option label="店铺" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerAdd">新 增</el-button>
        </el-form-item>
      </el-form>

      <el-table
        border
        :data="tabledata"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{row}">
            <div v-if="item.label=='首页栏目图'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label=='二级页banner'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label=='关联类型'">
              <p v-if="row[item.prop]==1">产品</p>
              <p v-else>店铺</p>
            </div>
            <div v-else>{{row[item.prop]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="160">
          <template  #default="{row}">
            <el-button
              @click.native.prevent="handleredit(row)"
              type="text"
              size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="handlerdelete(row)"
              type="text"
              size="small">
              删除
            </el-button>
            <el-button
              @click.native.prevent="handlersecondary(row)"
              type="text"
              size="small">
              关联
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <association ref="association" @getlist="handlerInquire"/>
      <add-tow ref="addtow" @getlist="handlerInquire"/>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="secondaryshow = false">取 消</el-button>
    <el-button type="primary" @click="secondaryshow = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Association from "@/views/pages/systemSet/theme/components/association";
import AddTow from "@/views/pages/systemSet/theme/components/addTow";

export default {
  name: "secondary",
  components: { AddTow, Association },
  data(){
    return{
      secondaryshow:false,
      form:{
        actid:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "id",
          prop: "id",
          width: "",
        },

        {
          label: "标题",
          prop: "title",
          width: "160",
        },

        {
          label: "首页栏目图",
          prop: "home_img",
          width: "",
        },
        {
          label: "二级页banner",
          prop: "banner",
          width: "",
        },

        {
          label: "二级页背景色",
          prop: "bg_color",
          width: "",
        },{
          label: "底部按钮文字颜色",
          prop: "button_text_color",
          width: "",
        },
        {
          label: "底部按钮颜色",
          prop: "button_color",
          width: "",
        },

        {
          label: "底部按钮文字",
          prop: "button_text",
          width: "",
        }, {
          label: "关联类型",
          prop: "type",
          width: "",
        },{
          label: "排序",
          prop: "sort_order",
          width: "",
        },
      ],
      total: 0,
    }
  },
  methods:{
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerAdd(){
      this.$refs.addtow.show()
      this.$refs.addtow.form.actid=this.form.actid
    },
    handleredit(row){
      this.$refs.addtow.show()
      this.$refs.addtow.form.actid=this.form.actid
      this.$refs.addtow.form.id=row.id
      this.$refs.addtow.handlerinfo(row)

    },
    handlersecondary(row){
      this.$refs.association.form.menuid=row.id
      this.$refs.association.handlerInquire()
      this.$refs.association.show()
    },
    handlerdelete(row){
      postAction("/api/theme/menu/delete",{id:row.id,actid:this.form.actid}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerInquire()
      })
    },
    handlerlist(){
      getAction("/api/theme/menu/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    show(){
      this.secondaryshow=true
    },
    close(){
      this.secondaryshow=false
      this.form={
        actid:""
      }
    }
  }
};
</script>

<style scoped>

</style>
