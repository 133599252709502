var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        visible: _vm.showDialog,
        width: "600px",
        top: "4vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "orderTest-container",
          staticStyle: { "padding-right": "90px" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "160px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名（用户名）", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "头像", prop: "headimg" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: {
                      src: _vm.form.headimg,
                      "preview-src-list": [_vm.form.headimg],
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "昵称", prop: "nickname" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "company" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company", $$v)
                      },
                      expression: "form.company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地区", prop: "region" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份", prop: "identity" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.identity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "identity", $$v)
                      },
                      expression: "form.identity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "行业", prop: "industry" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.industry,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "industry", $$v)
                      },
                      expression: "form.industry",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "channel" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.channel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channel", $$v)
                      },
                      expression: "form.channel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册电话", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有无锁定记录", prop: "lock_status_text" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.lock_status_text,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lock_status_text", $$v)
                      },
                      expression: "form.lock_status_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最近锁定时间", prop: "lock_time" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.lock_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lock_time", $$v)
                      },
                      expression: "form.lock_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最近锁定原因", prop: "lock_reason" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.lock_reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lock_reason", $$v)
                      },
                      expression: "form.lock_reason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "memo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      maxlength: 200,
                    },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号状态", prop: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_lock,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_lock", $$v)
                        },
                        expression: "form.is_lock",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("正常"),
                      ]),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("手动锁定"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("白名单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.is_lock == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "锁定原因", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: false,
                            clearable: "",
                            placeholder: "可选",
                          },
                          model: {
                            value: _vm.form.reason,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "reason", $$v)
                            },
                            expression: "form.reason",
                          },
                        },
                        _vm._l(_vm.dialogSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-button center" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }