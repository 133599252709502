<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="需求客户">
        <el-input
          v-model="form.name"
          clearable
          placeholder="需求客户"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="一级品类">
        <el-select
          v-model="form.root_cat_id"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          placeholder="需求形式"
          style="width: 160px"
        >
          <el-option
            v-for="list in xuqiutypelist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="需求电话">
        <el-input
          v-model="form.telephone"
          clearable
          maxlength="11"
          placeholder="需求电话"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="提报人">
        <el-input
          v-model="form.tibao_name"
          clearable
          placeholder="提报人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="审核人">
        <el-input
          v-model="form.shenhe_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="企业服务人员">
        <el-input
          v-model="form.enterprise_server"
          clearable
          placeholder="企业服务人员"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="选品方案状态">
        <el-select
          v-model="form.sel_status"
          clearable
          placeholder="选品方案状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in selStatusList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="云会议状态">
        <el-select
          v-model="form.meeting_status"
          clearable
          placeholder="云会议状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in meetingStatusList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          v-has="'xuanpinexport'"
          type="primary"
          @click="handlerExport"
        >
          导 出
        </el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '云会议状态'">
            <!--             -->
            <div>
              <el-button
                v-show="row[item.prop] == 1"
                type="text"
                @click="handleraddcloud(row)"
              >
                待创建
              </el-button>
              <el-button
                v-show="row[item.prop] == 3 && row.ask_status == 2"
                type="text"
              >
                已完成
              </el-button>
              <el-button
                v-show="row[item.prop] == 3 && row.ask_status == 1"
                type="text"
              >
                会议已结束
              </el-button>
              <el-button
                v-show="row[item.prop] > 1"
                type="text"
                @click="handlercloudinfo(row)"
              >
                详情
              </el-button>
            </div>

            <!--              <el-image
                v-show="row[item.prop]==2"
                style="width: 100px; height: 100px"
                :src="row.meeting_qr"
                :preview-src-list="[row.meeting_qr]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>-->
            <vue-qr
              v-show="row[item.prop] == 2"
              :margin="10"
              :size="100"
              :text="row.meeting_qr"
            />
            <p v-show="row[item.prop] == 2">请用企业微信扫码</p>
          </div>
          <div v-else-if="item.label == '调查问卷'">
            <div>
              <el-button v-show="row[item.prop] == 2" type="text">
                已完成
              </el-button>
              <el-button
                v-show="row[item.prop] == 2"
                type="text"
                @click="handlersurveyinfo(row)"
              >
                详情
              </el-button>
            </div>
            <!--              <el-image
                v-show="row[item.prop]==1"
                style="width: 100px; height: 100px"
                :src="row.ask_qr"
                :preview-src-list="[row.ask_qr]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>-->
            <vue-qr
              v-show="row[item.prop] == 1"
              :margin="10"
              :size="100"
              :text="row.ask_qr"
            />
            <p v-show="row[item.prop] == 1">请用企业微信扫码</p>
          </div>
          <div v-else-if="item.label == '操作'">
            <el-button
              v-show="row.need_connect_status != 1"
              size="small"
              type="text"
              @click="handlerreedit(row)"
            >
              编辑
            </el-button>
            <el-button
              v-show="row.need_connect_status != 1"
              size="small"
              type="text"
              @click="handlerrefuse(row)"
            >
              删除
            </el-button>
            <el-popconfirm
              confirm-button-text="设置无效"
              title="设置会议无效"
              @confirm="handlerfurm(row)"
            >
              <el-button
                v-show="row.is_valid == 1"
                style="margin-left: 10px"
                type="text"
                slot="reference"
              >
                云会议状态
              </el-button>
            </el-popconfirm>
          </div>
          <div v-else-if="item.label == '云会议是否有效'">
            {{ row[item.prop] == 1 ? '有效' : '无效' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-select ref="add" @getlist="handlerlist" />
    <invaildreason ref="invaildreason" :rowid="rowid" @getlist="handlerlist" />
    <add-cloudinfo ref="cloudinfo" @getlist="handlerlist" />
    <surveyinfo ref="survey" @getlist="handlerlist" />
    <add-cloud ref="cloud" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import AddSelect from '@/views/pages/review/clouddemand/components/AddSelect'
  import invaildreason from '@/views/pages/review/clouddemand/components/InvaildReason'
  import AddCloudinfo from '@/views/pages/review/clouddemand/components/AddCloudinfo'
  import surveyinfo from '@/views/pages/review/clouddemand/components/surveyinfo'
  import AddCloud from '@/views/pages/review/clouddemand/components/AddCloud'
  import vueQr from 'vue-qr'
  export default {
    components: {
      AddSelect,
      invaildreason,
      AddCloudinfo,
      surveyinfo,
      AddCloud,
      vueQr,
    },
    data() {
      return {
        form: {
          name: '', //需求客户
          root_cat_id: '', //一级品类id
          cat_id: '', //二级品类id
          status: 1, //审核状态
          telephone: '', //客户电话
          company: '', //公司名称
          page: 1,
          limit: 10,
          tibao_name: '', //提报人
          shenhe_name: '', //审核人
          enterprise_server: '', //企业服务人员
          sel_status: null, //选品方案状态
          meeting_status: '', //云会议状态
        },
        cateselect: [],
        catselect: [],
        showSelsect: [
          {
            id: -1,
            name: '待定',
          },
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '审核通过',
          },
          {
            id: 3,
            name: '审核拒绝',
          },
          {
            id: 4,
            name: '已失效',
          },
          {
            id: 5,
            name: '已过期',
          },
          {
            id: 6,
            name: '匹配完',
          },
        ],
        meetingStatusList: [
          {
            id: 1,
            name: '不可创建',
          },
          {
            id: 2,
            name: '待创建',
          },
          {
            id: 3,
            name: '已创建',
          },
          {
            id: 4,
            name: '已完成',
          },
          {
            id: 5,
            name: '无效',
          },
        ],
        xuqiutypelist: [
          {
            id: 1,
            name: '找产品代理',
          },
          {
            id: 2,
            name: '找代工',
          },
        ],
        selStatusList: [
          {
            id: 1,
            name: '不可创建',
          },
          {
            id: 2,
            name: '待创建',
          },
          {
            id: 3,
            name: '已创建',
          },
          {
            id: 4,
            name: '已发送',
          },
          {
            id: 5,
            name: '已被查看',
          },
          {
            id: 6,
            name: '已确认',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '需求客户',
            prop: 'name',
            width: '',
          },
          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '220px',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },

          {
            label: '需求详情',
            prop: 'detail',
            width: '160',
          },
          {
            label: '需求形式',
            prop: 'type_txt',
            width: '',
          },

          {
            label: '客户电话',
            prop: 'telephone',
            width: '220px',
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },
          {
            label: '行业所在地区',
            prop: 'area',
            width: '',
          },
          {
            label: '渠道',
            prop: 'channel_name',
            width: '',
          },
          {
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '提报时间',
            prop: 'created',
            width: '',
          },
          {
            label: '企业服务人员',
            prop: 'enterprise_server',
            width: '',
          },
          {
            label: '选品方案名称',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '所属店铺',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            label: '选品状态',
            prop: 'need_connect_status_text',
            width: '',
          },
          {
            label: '云会议状态',
            prop: 'meeting_status',
            width: '160',
          },
          {
            label: '调查问卷',
            prop: 'ask_status',
            width: '160',
          },
          {
            label: '云会议是否有效',
            prop: 'meeting_valid',
            width: '',
          },
          {
            label: '无效说明',
            prop: 'invalid_reason',
            width: '',
          },
          {
            label: '审核人',
            prop: 'admin_user_name',
            width: '',
          },
          {
            label: '操作',
            prop: '',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handleraddcloud(row) {
        this.$refs.cloud.form.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloud.showcloud = true
      },
      //查看云会议详情
      handlercloudinfo(row) {
        this.$refs.cloudinfo.form.id = row.meeting_id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloudinfo.showcloud = true
      },
      //查看调查问卷详情
      handlersurveyinfo(row) {
        this.$refs.survey.showinfo = true
        this.$refs.survey.surveyinfo = row
      },
      handlerInquire() {
        this.form.id = ''
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-selection', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      handlerlist() {
        postAction('/audit/biz-clue/selection-plan', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handlerreedit(row) {
        this.$refs.add.showedit = true
        // this.$refs.add.handlerinfo(row)
        this.$refs.add.form = {
          id: row.biz_clue_id,
          mall_id: row.mall_id,
          plan_id: row.id,
          goods_id: row.goods_id,
          mall_name: row.mall_name,
          goods_name: row.goods_name,
          sort: row.sort,
          status: row.status,
        }
        this.$refs.add.mall_name = row.mall_name
        this.$refs.add.title = '编辑'
      },
      handlerrefuse(row) {
        postAction('/audit/biz-clue/del-plan', { id: row.id }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerfurm(row) {
        this.showValid = true
        this.$refs.invaildreason.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.invaildreason.showValid = true
        // postAction('/audit/biz-clue/set-unvalid', {
        //   id: row.id,
        //   reason: this.validreason,
        // }).then((res) => {
        //   this.$message({
        //     type: 'success',
        //     message: res.msg,
        //   })
        //   this.handlerlist()
        // })
      },
    },
  }
</script>

<style scoped></style>
