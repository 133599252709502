<template>
  <el-dialog :title="title" :visible.sync="showChangeLog" width="70%">
    <el-form ref="form" inline label-width="80px" :model="form">
      <el-form-item>
        <el-input
          v-model="form.id"
          clearable
          placeholder="需求ID"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.nickname"
          clearable
          placeholder="用户昵称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="权限类型">
        <el-select
          v-model="form.staff_type"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in typeList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="修改类型">
        <el-select
          v-model="form.position"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in changeList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerlist()">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listloading"
      border
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '修改内容'" style="text-align: left">
            <div v-for="(con, index) in row.content" :key="index">
              {{ con }}
            </div>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="showChangeLog = false">取 消</el-button> -->
      <el-button type="primary" @click="showChangeLog = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'AddEdit',
    props: {
      rowid: {
        type: String,
        default: '',
      },
      username: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showChangeLog: false,
        title: '修改记录',
        timelist: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          id: '',
          limit: 10,
          page: 1,
          nickname: '',
        },
        tabledata: [],
        listloading: false,
        columns: [
          {
            label: '权限类型',
            prop: 'staff_type_txt',
            width: '',
          },
          {
            label: '姓名',
            prop: 'staff_name',
            width: '',
          },
          {
            label: '修改类型',
            prop: 'position_txt',
            width: '',
          },
          {
            label: '修改内容',
            prop: 'content',
            width: '300',
          },
          {
            label: '修改时间',
            prop: 'time',
            width: '',
          },
        ],
        typeList: [
          {
            id: 1,
            name: '提报人',
          },
          {
            id: 2,
            name: '审核人',
          },
        ],
        changeList: [
          {
            id: 1,
            name: '客户画像',
          },
          {
            id: 2,
            name: '需求',
          },
        ],
      }
    },
    watch: {
      showChangeLog(val) {},
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      rowid(val) {
        this.form.id = val
      },
      username(val) {
        this.form.nickname = val
      },
    },
    mounted() {
      this.handlerlist()
    },

    methods: {
      handleCheckChange() {},
      handlerlist(type, id, name) {
        this.listloading = true
        let params = {
          id: type == 'info' ? id : this.form.id,
          nickname: type == 'info' ? name : this.form.nickname,
          name: this.form.name,
          staff_type: this.form.staff_type,
          position: this.form.position,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          page: this.form.page,
          limit: this.form.limit,
        }
        getAction('/audit/biz-clue/modify-lists', params)
          .then((res) => {
            if (res.request_code == 200) {
              this.tabledata = res.data
              this.total = res.totalCount
            }
          })
          .finally(() => {
            this.listloading = false
          })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
</style>
