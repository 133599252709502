var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showDialog, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckChange },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                },
                _vm._l(_vm.reasonlist, function (item, idx) {
                  return _c(
                    "el-checkbox",
                    { key: idx, attrs: { label: item } },
                    [
                      _vm._v(" " + _vm._s(item) + " "),
                      item == "其他"
                        ? _c(
                            "span",
                            { staticStyle: { "line-height": "60px" } },
                            [
                              _c("el-input", {
                                attrs: { min: 0, type: "textarea" },
                                model: {
                                  value: _vm.other,
                                  callback: function ($$v) {
                                    _vm.other = $$v
                                  },
                                  expression: "other",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }