<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="管理员姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.phone"
          clearable
          placeholder="手机号"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name	"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>

        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="(item, index) in statusSelect"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handlerExport">导 出</el-button>
            </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label=='操作'" >
            <div v-if="row.status==1">
              <el-button type="text" @click="handlerEdit(row)">编辑</el-button>
              <el-button type="text" @click="handlerUnbind(row)">解绑</el-button>
              <el-button type="text" @click="handlerresetting(row)">重置密码</el-button>
            </div>
            <div v-if="row.status==2">
              <el-button type="text" @click="handlestatus(row,1)" >通过</el-button>
              <el-button type="text" @click="handlestatus(row,3)" >拒绝</el-button>
            </div>
          </div>
          <div v-else-if="item.label == '身份证明材料'">
            <el-row :gutter="10" type="flex" justify="center">
             <el-col v-for="(item, index) in row.employee_materials_url" :key="index">
               <el-image
                 :preview-src-list="[item]"
                 :src="item"
                 style="width: 100px; height: 100px"
               >
                 <div slot="placeholder" class="image-slot">
                   加载中
                   <span class="dot">...</span>
                 </div>
                 <div slot="error" class="image-slot"></div>
               </el-image>
             </el-col>
            </el-row>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="add" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from '@/utils/excel'
import AddEdit from "@/views/pages/enterprise/bindaccount/components/AddEdit";
import { parseTime } from "@/utils";

export default {
  name: 'Index',
  components: { AddEdit },
  data() {
    return {
      form: {
        company_name: '',
        mall_name: '',
        name: '',
        phone: '',
        page: 1,
        limit: 10,
      },
      statusSelect:[{
        id:1,
        name:'已审核'
      },{
        id:3,
        name:'已驳回'
      },{
        id:2,
        name:'申请中'
      }],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '用户ID',
          prop: 'id',
          width: '',
        },
        {
          label: '管理员姓名',
          prop: 'name',
          width: '',
        },
        {
          label: '管理员职务',
          prop: 'job',
          width: '',
        },
        {
          label: '身份证明材料',
          prop: 'employee_materials_url',
          width: '',
        },
        {
          label: '手机号',
          prop: 'phone',
          width: '',
        },
        {
          label: '是否主管理员',
          prop: 'is_master_txt',
          width: '',
        },
        {
          label: '旧平台账号',
          prop: 'admin_user',
          width: '',
        },
        {
          label: '所属店铺',
          prop: 'mall_name',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },
        {
          label: '审核人',
          prop: 'op_name',
          width: '',
        },
        {
          label: '操作',
          prop: 'op_name',
          width: '',
        },
      ],
      total: 0,
      time: [],

    }
  },

  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlestatus(row,status){
      this.$baseConfirm('你确定要对该账号进行审核吗', null, async () => {
        const { msg } = await postAction('/mall/manager/audit',{ id: row.id,status:status })
        this.$baseMessage(msg, 'success', 'vab-hey-message-success')
        await this.handlerlist()
      })
    },
    handlerAdd(){
      this.$refs.add.show=true
    },
    handlerEdit(row){
      this.$refs.add.handlerinfo(row)
      this.$refs.add.show=true
    },
    handlerUnbind(row){
      if(row.is_master==1){
        this.$alert('解绑失败不允许直接解绑主管理员', '解绑失败', {
          confirmButtonText: '返回',
          center: true,
          callback: action => {
            // this.$message({
            //   type: 'info',
            //   message: `action: ${ action }`
            // });
          }
        });
      }else{
        this.$confirm('解绑后，用户不再是该店铺的子管理员', '确认解绑', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          postAction("/mall/manager/remove",{id:row.id}).then(res=>{
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.handlerlist()
          })
        }).catch(() => {});
      }


    },
    handlerresetting(row){
      this.$confirm('确认重置密码？重置后，密码为123456', '确认重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        postAction("/mall/manager/reset-pwd",{id:row.id}).then(res=>{
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.handlerlist()
        })
      }).catch(() => {});
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/mall/manager/lists', this.form).then((res) => {
        this.tabledata = res.data.map(list=>{
          if(list.employee_materials_url.length==0){
            list.employee_materials_url=new Array()
          }else{
            list.employee_materials_url=list.employee_materials_url.split(',')
          }
          return list
        })
        this.total = Number(res.totalCount)
      })
    },
    handlerExport() {
      const header = this.columns.map(list=>{
        return list.prop
      })
      const headerlab = this.columns.map(list=>{
        return list.label
      })
      getAction('/mall/manager/export', this.form).then((res) => {
        const data = this.formatJson(header, res.data)
        excel.export_json_to_excel({
          header: headerlab,
          data: data,
          filename: '绑定账号列表',
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            console.log(v[j])
            return v[j]
          }
        })
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
