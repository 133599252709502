<template>
  <div class="index-container">
    <h2 style="text-align: center">调查问卷（2024春糖）抽奖统计</h2>
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in deptSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.htyun_name"
          clearable
          filterable
          placeholder="邀约人"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in staffSelect"
            :key="index"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="手机号"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.prize_id"
          clearable
          filterable
          placeholder="奖品"
          style="width: 160px"
        >
          <el-option
            v-for="list in prizeSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          filterable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in statusSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_complete"
          clearable
          filterable
          placeholder="核销状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in completeSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_dealer"
          clearable
          filterable
          placeholder="经销商身份认证"
          style="width: 200px"
        >
          <el-option
            v-for="list in dealerSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      />
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlercheck(row, 1)"
          >
            通过
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlercheck(row, 2)"
          >
            拒绝
          </el-button>
          <!-- <el-popconfirm title="确认审核拒绝？" @confirm="handlercheck(row, 2)">
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Refusereason ref="refusereason" :rowid="rowid" @getlist="handlerInquire" />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import Refusereason from '@/views/pages/datastatis/survey/chuntang/chuntang2024/components/RefuseReason'
  export default {
    name: 'Index',
    components: {
      Refusereason,
    },
    data() {
      return {
        time: [],
        form: {
          start_time: '',
          end_time: '',
          htyun_name: '',
          htyun_dept: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'htyun_name',
            width: '',
          },
          {
            label: '姓名',
            prop: 'name',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '手机号',
            prop: 'mobile',
            width: '',
          },
          {
            label: '奖品名称',
            prop: 'prize_name',
            width: '160',
          },
          {
            label: '中奖时间',
            prop: 'created',
            width: '',
          },
          {
            label: '经销商身份认证',
            prop: 'is_dealer',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_name',
            width: '',
          },
          {
            label: '核销状态',
            prop: 'is_complete_text',
            width: '',
          },
          {
            label: '审核人',
            prop: 'check_user_name',
            width: '',
          },
        ],
        deptSelect: [],
        staffSelect: [],
        sumtotal: {}, //合计
        rowid: '',
        prizeSelect: [],
        completeSelect: [
          {
            id: 1,
            name: '已核销',
          },
          {
            id: 0,
            name: '待核销',
          },
          {
            id: 3,
            name: '无核销资格',
          },
        ],
        statusSelect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '已通过',
          },
          {
            id: 3,
            name: '未通过',
          },
        ],
        dealerSelect: [
          {
            id: 1,
            name: '已认证',
          },
          {
            id: 2,
            name: '无认证',
          },
        ],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
      this.getPrizelist()
    },
    methods: {
      // 审核操作 通过or拒绝
      handlercheck(row, type) {
        if (type == 2) {
          this.$confirm('是否确认审核不通过？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            // 填写拒绝理由
            this.$refs.refusereason.showRefuseReason = true
            this.rowid = row.id
          })
        } else if (type == 1) {
          this.$confirm('是否确认审核通过？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            let param = {
              id: row.id,
              status: 2,
            }
            getAction('/api/form/research-spring/award-audit', param).then(
              (res) => {
                this.tabledata = res.data
                // this.sumtotal = res.data.sum
                this.total = Number(res.totalCount)
                this.$message.success(res.msg)
                this.handlerlist()
              }
            )
          })
        }
      },
      getPrizelist() {
        getAction('/api/form/research-spring/prize', this.form).then((res) => {
          this.prizeSelect = res.data
        })
      },
      async handlerSelect() {
        const dept = await getAction('/form/form/dept')
        this.deptSelect = dept.data
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
        this.staffSelect.unshift({ htyun_name: '无邀请人' })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/api/form/research-spring/award-list', this.form).then(
          (res) => {
            this.tabledata = res.data
            // this.sumtotal = res.data.sum
            this.total = Number(res.totalCount)
          }
        )
      },
      handlerExport() {
        postAction(
          '/api/form/research-spring/award-list-export',
          this.form
        ).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },
    },
  }
</script>
