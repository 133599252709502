<template>
  <el-dialog
    :visible.sync="showhotel"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="列表分享图">
          <upload-img ref="list_shareimg" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'list_shareimg')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="详情页分享图">
          <upload-img ref="info_shareimg" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'info_shareimg')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="开启" value="1" />
            <el-option label="关闭" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option label="春糖" value="1" />
            <el-option label="秋糖" value="2" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showhotel = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import uploadImg from "@/components/uploadImg";
import { postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "AddHotel",
  components:{
    uploadImg,
  },
  data(){
    return{
      showhotel:false,
      form:{
        title:'',
        list_shareimg:'',
        info_shareimg:'',
        status:'',
        type:''
      }
    }
  },
  watch:{
    showhotel(val){
      if(!val){
        this.form={
          title:'',
          list_shareimg:'',
          info_shareimg:'',
          status:'',
          type:''
        }
        this.closeimg('list_shareimg')
        this.closeimg('info_shareimg')
      }
    }
  },
  methods:{
    handlerinfo(row){
      this.form= Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(row.list_shareimg,'list_shareimg')
        this.setImg(row.info_shareimg,'info_shareimg')
      })
    },
    handlersave(){
      postAction("/api/hotel/config/edit",this.form).then(res=>{
        this.$message({
          type: 'success',
          message: res.msg,
        })
        this.$emit("getlist")
        this.showhotel = false
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
