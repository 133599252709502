<template>
  <div>
  <el-form
    ref="form"
    :model="form"
    :inline="inline"
    label-position="right"
    :label-width="labelWidth ? labelWidth + 'px' : null"
    :label-suffix="labelSuffix"
    :disabled="disable"
    :rules="rules"
  >
    <slot name="start"></slot>
    <el-form-item
      v-for="(item, index) in labels"
      :key="index"
      :label="item.label"
      :prop="item.prop"
    >
      <template v-if="item.type == 'input'">
        <area-select
          v-if="item.label == '地区'"
          @select="changeCity"
          style="width: 200px"
        ></area-select>

        <el-input
          v-else
          v-model="form[item.prop]"
          :type="item.type_"
          :clearable="item.clear || true"
          :disabled="item.disable"
          :placeholder="item.place || ''"
          :style="item.width && 'width:' + item.width + 'px'"
          @change="
            ($event) => {
              item.onChange && item.onChange($event)
            }
          "
          @input="
            ($event) => {
              item.onInput && item.onInput($event)
            }
          "
        ></el-input>
      </template>
      <template v-else-if="item.type == 'select'">
        <el-select
          v-model="form[item.prop]"
          :clearable="item.clear || true"
          :disabled="item.disable"
          :multiple="item.multiple"
          :style="item.width && 'width:' + item.width + 'px'"
          :placeholder="item.place || ''"
          @change="
            ($event) => {
              item.onChange && item.onChange($event)
            }
          "
        >
          <el-option
            v-for="(i, idx) in selectList[item.selectListName]"
            :key="idx"
            :value="i.id || i[item.selectId]"
            :label="i.name || i[item.selectLabel]"
          ></el-option>
        </el-select>
      </template>
      <template v-else-if="item.type == 'cascader'">
        <el-cascader
          v-model="form[item.prop]"
          :clearable="item.clear || true"
          :disabled="item.disable"
          :style="item.width && 'width:' + item.width + 'px'"
          :placeholder="item.place || ''"
          :options="selectList[item.selectListName]"
          :props="item.props"
          :show-all-levels="item.showAllLevels || false"
          :filterable="item.filterable || false"
          @change="
            ($event) => {
              item.onChange && item.onChange($event)
            }
          "
        ></el-cascader>
      </template>
      <template v-else-if="item.type == 'date'">
        <el-date-picker
          v-model="form[item.prop]"
          :clearable="item.clear || true"
          :disabled="item.disable"
          :style="item.width && 'width:' + item.width + 'px'"
          :type="item.type_ || 'date'"
          :format="item.format || 'yyyy 年 MM 月 dd 日'"
          :value-format="item.valueFormat || 'yyyy-MM-dd'"
          :placeholder="item.place || '选择日期'"
          :default-time="item.defaultTime"
          :range-separator="item._ || '-'"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="
            ($event) => {
              item.onChange && item.onChange($event)
            }
          "
        >
          >
        </el-date-picker>
      </template>
    </el-form-item>
    <el-form-item label="" prop="">
      <el-button type="primary" @click="getData">{{ queryWord }}</el-button>
    </el-form-item>
    <el-form-item v-show="resetBtn" label="" prop="">
      <el-button type="warning" @click="handleReset">重置</el-button>
    </el-form-item>
    <slot name="btn"></slot>
    <el-form-item label="" prop="">
      <slot name="end"></slot>
    </el-form-item>

        <el-form-item label="" prop="">
          <slot name="endbut"></slot>
        </el-form-item>


  </el-form>

  </div>
</template>
<script>
import AreaSelect from "@/components/areaSelect"
export default {
  name: "FormMaker",
  components: { AreaSelect },
  props: {
    queryWord: {
      type: String,
      default: "查询",
    },
    labelWidth: {
      type: [Number, String, Boolean, null],
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    labelSuffix: {
      type: String,
      default: ":",
    },
    resetBtn: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: [Object, null],
      default: () => null,
    },
  },
  data() {
    return {
      form: {},
      selectList: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initForm()
  },
  mounted() {},
  methods: {
    onInput(v) {
      console.log("v", v)
    },
    onChange(v) {
      console.log("v", v)
    },

    getData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("getFormData", this.form)
        }
      })
      // if (this.rules.length == 0) {
      //   this.$emit('getFormData', this.form)
      // } else {

      // }
    },
    handleReset() {
      this.initForm()
    },
    changeCity(e) {
      console.log("seses", e)
      Object.assign(this.form, e)
    },
    // 初始化form数据
    initForm(name) {
      if (name) {
        this.$set(this.form, name, "")
      } else {
        this.labels.forEach((label) => {
          this.$set(this.form, label.prop, "")
        })
      }
    },
    // 添加下拉列表数据
    addSelect(selectName, data) {
      this.$set(this.selectList, selectName, data)
    },
  },
}
</script>
<style lang="scss" scoped></style>
