<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">

      <el-form-item>
        <el-input
          v-model="form.company_name	"
          clearable
          placeholder="公司名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>

        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.op_name"
          clearable
          placeholder="审核人"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button v-has="'export'" type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">

          <div>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction } from '@/api/request'
import * as excel from '@/utils/excel'

export default {
  name: 'Index',
  data() {
    return {
      form: {
        company_name: '',
        mall_name: '',
        op_name: '',
        start_date: '',
        end_date: '',
        page: 1,
        limit: 10,
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '店铺名称',
          prop: 'mall_name',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },
        {
          label: '变更前状态',
          prop: 'prev_status',
          width: '',
        },
        {
          label: '变更后状态',
          prop: 'status',
          width: '',
        },
        {
          label: '审核时间',
          prop: 'created',
          width: '',
        },
        {
          label: '审核人',
          prop: 'op_name',
          width: '',
        },

      ],
      total: 0,
      time: [],

    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/mall/op-log/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handlerExport() {
      getAction('/mall/proxy/export-lists', this.form).then((res) => {
        excel.export_json_to_excel({
          header: res.data.header,
          data: res.data.list,
          filename: '代理意向',
        })
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
