<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-select v-model="form.type" clearable style="width: 160px;" placeholder="类型">
          <el-option
                     value="1"
                     label="经销商"/>
          <el-option
            value="2"
            label="企业"/>
        </el-select>
      </el-form-item>

      <el-form-item >
        <el-input placeholder="标题" clearable v-model="form.title" style="width: 110px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>

    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">

          <div v-if="item.label=='封面图'">
            <el-image
              style="width: 70px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '分类'">
            {{ row.type == 1 ? '经销商' : '企业' }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
                    <div v-else-if="item.label=='分享图'">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row[item.prop]"
                        :preview-src-list="[row[item.prop]]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
<!--          <el-popconfirm
            title="确认禁用？"
            @confirm="deleteRow(row.id, 0)"
            v-show="row.status==1"
          >
            <el-button
              slot="reference"
              type="text"
              size="small" >
              禁用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            title="确认启用？"
            @confirm="deleteRow(row.id, 1)"
            v-show="row.status!=1"
          >
            <el-button
              slot="reference"
              type="text"
              size="small">
              启用
            </el-button>
          </el-popconfirm>-->
          <el-popconfirm
            title="确认删除该视频？"
            @confirm="handlerdelete(row)"
          >
            <el-button
              slot="reference"
              type="text"
              size="small">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            style="margin: 10px;"
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add-video ref="video" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddVideo from "@/views/pages/systemSet/playing/videolist/components/AddVideo";

export default {
  name: "index",
  components: { AddVideo },
  data(){
    return {
      form: {
        type:'',
        title:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "分类",
          prop: "type",
          width: "",
        },

        {
          label: "封面图",
          prop: "cover",
          width: "160",
        },

        {
          label: "标题",
          prop: "title",
          width: "",
        },

        {
          label: "分享标题",
          prop: "share_title",
          width: "",
        },

        {
          label: "分享图",
          prop: "share_img",
          width: "",
        },
        {
          label: "排序",
          prop: "sort_order",
          width: "80",
        },

        {
          label: "状态",
          prop: "status",
          width: "80",
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods:{
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerAdd(){
      this.$refs.video.showvideo=true
    },
    handlerinfo(row){
      this.$refs.info.form=row
      this.$refs.info.showinfo=true
    },
    handlerlist(){
      getAction("/api/system/direction/video-lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handlerdelete(row){
      postAction("/api/system/direction/video-delete",{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    deleteRow(id,status){
      postAction("/api/index/banner-set",{id:id,status}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    handleredit(row){
      console.log(row)
      this.$refs.video.showvideo=true
      this.$refs.video.handlerinfo(row)

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
