<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="一级品类">
        <el-select
          v-model="form.one_cate"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.two_cate"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          :default-time="['12:00:00']"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          style="width: 340px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">创建选品方案</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :data-clipboard-text="clipboardtext"
          class="copybtnappid"
          @click="handlerCopy"
        >
          复制小程序appid
        </el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '小程序路径'">
            <el-button
              class="copybtn"
              type="primary"
              :data-clipboard-text="clipboardtext"
              @click="copyurl(row[item.prop])"
            >
              复制小程序路径
            </el-button>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="addedit" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import Clipboard from 'clipboard'
  import addedit from './components/addedit.vue'
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    components: {
      addedit,
    },
    data() {
      return {
        clipboardtext: '', //剪切板存放的内容
        form: {
          page: 1,
          limit: 10,
          start_date: '',
          end_date: '',
          type: '', //1指定店铺 2品类
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '类型',
            prop: 'type',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '一级品类',
            prop: 'pcat_name',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },

          {
            label: '价格带',
            prop: 'price',
            width: '',
          },
          {
            label: '需求详情',
            prop: 'detail',
            width: '',
          },
          {
            label: '时间',
            prop: 'created',
            width: '',
          },
          {
            label: '小程序路径',
            prop: 'path',
            width: '150px',
          },
        ],
        total: 0,
        sumtotal: 0,
        cateselect: [],
        catselect: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerCopy() {
        this.clipboardtext = 'wx2f1c99b675539752'
        // 执行复制操作
        var clipboard = new Clipboard('.copybtnappid')
        console.log('复制', clipboard)
        clipboard.on('success', (e) => {
          console.log(1, e)
          this.$message.success('复制成功')
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          console.log(2, e)
          // 不支持复制
          this.$message.error('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy()
        })
      },
      copyurl(text) {
        // 获取将要复制的文案信息
        // let text = document.getElementById('copy_text').innerText
        this.clipboardtext = text
        // 执行复制操作
        var clipboard = new Clipboard('.copybtn')
        console.log('复制', clipboard)
        clipboard.on('success', (e) => {
          console.log(1, e)
          this.$message.success('复制成功')
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          console.log(2, e)
          // 不支持复制
          this.$message.error('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy()
        })
      },
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '时间') {
            sums[index] = '合计'
          }
          if (column.label == '创建方案数量') {
            sums[index] = this.sumtotal.sel_num
          }
          if (column.label == '发送人数') {
            sums[index] = this.sumtotal.send_num
          }
          if (column.label == '浏览数') {
            sums[index] = this.sumtotal.view_num
          }
          if (column.label == '喜欢数') {
            sums[index] = this.sumtotal.like_num
          }
          if (column.label == '引荐数') {
            sums[index] = this.sumtotal.introduction_num
          }
          if (column.label == '已认证经销商数') {
            sums[index] = this.sumtotal.dealer_num
          }
          console.log('合计数', this.sumtotal)
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.addedit.showedit = true
      },

      async handlerlist() {
        await postAction('/api/work/sel-plan/mass-lists', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            this.total = Number(res.totalCount)
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
