<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.is_check"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="form.house_name"
          clearable
          placeholder="名称关键字"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '背景图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 50px; height: 50px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '状态'">
            <p v-if="row[item.prop] == 1">开启</p>
            <p v-else>禁用</p>
          </div>

          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="260">
        <template #default="{ row }">
          <el-popconfirm title="确认删除该展馆？" @confirm="handlerdelete(row)">
            <el-button
              size="small"
              style="margin: 10px"
              slot="reference"
              type="text"
            >
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlerinfo(row)"
          >
            详情
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlergoodsmall(row, 1)"
          >
            添加产品
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlergoodsmall(row, 2)"
          >
            添加企业
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlermenu(row)"
          >
            菜单设置
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlerbanner(row)"
          >
            轮播图设置
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlerzhida(row)"
          >
            直达按钮设置
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="add" @getlist="handlerInquire" />
    <add-goods ref="goods" @getlist="handlerInquire" />
    <konglist ref="list" />
    <bannerlist ref="banner" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import AddEdit from './components/AddEdit'
  import AddGoods from './components/AddGoods'
  import Konglist from '@/views/pages/systemSet/characteristic/components/konglist'
  import Bannerlist from '@/views/pages/systemSet/characteristic/components/bannerlist'

  export default {
    name: 'Index',
    components: { Bannerlist, Konglist, AddGoods, AddEdit },
    data() {
      return {
        form: {
          is_check: '',
          house_name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '展馆id',
            prop: 'id',
            width: '80',
          },

          {
            label: '名称',
            prop: 'house_name',
            width: '',
          },
          {
            label: '状态',
            prop: 'is_check',
            width: '80',
          },
          {
            label: '栏目1',
            prop: 'house_column1',
            width: '',
          },
          {
            label: '栏目2',
            prop: 'house_column2',
            width: '',
          },
          {
            label: '栏目3',
            prop: 'house_column3',
            width: '',
          },
          {
            label: '展馆背景色',
            prop: 'house_color1',
            width: '110',
          },
          {
            label: '背景图',
            prop: 'background',
            width: '110',
          },
          {
            label: '金刚区行数',
            prop: 'menu_columns',
            width: '100',
          },
        ],
        shopSelect: [
          {
            id: 1,
            name: '启用',
          },
          {
            id: 0,
            name: '禁用',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerzhida(row) {
        this.$refs.banner.show = true
        this.$refs.banner.form.advert_no = row.ad_zhida
        this.$refs.banner.title='直达按钮设置'
        this.$refs.banner.handlerInquire()
      },
      handlerbanner(row) {
        this.$refs.banner.show = true
        this.$refs.banner.form.advert_no = row.ad_code
        this.$refs.banner.title='轮播图设置'
        this.$refs.banner.handlerInquire()
      },
      handlermenu(row) {
        this.$refs.list.showkong = true
        this.$refs.list.form.house_id = row.id
        this.$refs.list.handlerInquire()
      },
      handlergoodsmall(row, type) {
        ;(this.$refs.goods.form.type = type),
          (this.$refs.goods.form.house_id = row.id)
        this.$refs.goods.handlerInquire()
        this.$refs.goods.goodsmall = true
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerinfo(row) {
        this.$refs.add.show()
        this.$refs.add.handlerinfo(row.id)
        this.$refs.add.title = '详情'
        this.$refs.add.disabled = true
      },
      handleradd() {
        this.$refs.add.show()
        this.$refs.add.title = '添加'
      },
      handlerlist() {
        getAction('/api/system/house/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/api/system/house/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },

      async handleredit(row) {
        console.log(row)
        this.$refs.add.show()
        await this.$refs.add.handlerinfo(row.id)
        this.$refs.add.title = '编辑'
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
