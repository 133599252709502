var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.showEditPoster,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditPoster = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "120px", model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级品类", prop: "cat_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "分类" },
                          model: {
                            value: _vm.form.cat_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cat_id", $$v)
                            },
                            expression: "form.cat_id",
                          },
                        },
                        _vm._l(_vm.shopSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签名称", prop: "label" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "label", $$v)
                          },
                          expression: "form.label",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showEditPoster = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }