<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="品类"
          style="width: 120px"
        >
          <el-option
            v-for="list in cateSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="文章标题"
          style="width: 220px"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="form.is_check"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option label="开启" value="1" />
          <el-option label="禁用" value="0" />
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">上 传</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerstatus(row)"
          >
            {{ row.status == 1 ? '禁用' : '启用' }}
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdel(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit
      ref="add"
      :cate-select="cateSelect"
      :source-select="sourceSelect"
      @getlist="handlerlist"
    />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import AddEdit from './components/addEdit'

  export default {
    name: 'Index',
    components: { AddEdit },
    data() {
      return {
        form: {
          title: '',
          source: '',
          cat_id: '',
          status: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
          },
          {
            label: '品类',
            prop: 'cat_name',
          },
          {
            label: '封面图',
            prop: 'pic',
            width: '160',
          },
          {
            label: '文章标题',
            prop: 'title',
            width: '110',
          },

          {
            label: '上传时间',
            prop: 'add_time',
            width: '120',
          },
          {
            label: '操作人',
            prop: 'user_name',
            width: '80',
          },
          // {
          //   label: '操作',
          //   prop: 'is_top',
          //   width: '',
          // },
        ],
        time: [],
        total: 0,
        areaselect: [],
        classselect: [],
        cateSelect: [],
        sourceSelect: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      // this.handlerSelect()
      // 获取品类
      getAction('/user/index/cate', { pid: 0 }).then((res) => {
        this.cateSelect = res.data
      })
      this.getSource()
    },
    methods: {
      //获取来源
      getSource() {
        getAction('/api/system/wechat-article/source').then((res) => {
          this.sourceSelect = res.data
        })
      },
      //启用禁用
      handlerstatus(row) {
        let tips = row.status == 1 ? '确定禁用改资讯吗？' : '确定启用该资讯吗？'
        this.$confirm(tips, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getAction('/api/system/wechat-article/stroll-status', {
            id: row.id,
          }).then((res) => {
            if (res.request_code == 200) {
              this.$message.success('操作成功')
              this.handlerlist()
            } else {
              this.$message.info(res.msg)
            }
          })
        })
      },
      // 删除
      handlerdel(row) {
        this.$confirm('确定要删除该资讯吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getAction('/api/system/wechat-article/stroll-delete', {
            id: row.id,
          }).then((res) => {
            if (res.request_code == 200) {
              this.$message.success('操作成功')
              this.handlerlist()
            } else {
              this.$message.info(res.msg)
            }
          })
        })
      },
      handleradd() {
        this.$refs.add.showadd = true
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.add.showadd = true
        this.$refs.add.handlerinfo(row)
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      handlerlist() {
        getAction('/api/system/wechat-article/stroll-lists', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
