var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editTitle,
            visible: _vm.is_show,
            width: "800px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.is_show = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "edit" },
            [
              _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级品类" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "60%" },
                        attrs: { placeholder: "请输入一级品类" },
                        model: {
                          value: _vm.form.reply,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reply", $$v)
                          },
                          expression: "form.reply ",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "二级品类" } },
                    _vm._l(_vm.form.child, function (item, index) {
                      return _c(
                        "p",
                        { key: "index", staticClass: "answer" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "60%" },
                            attrs: { placeholder: "请输入二级品类" },
                            model: {
                              value: item.reply,
                              callback: function ($$v) {
                                _vm.$set(item, "reply", $$v)
                              },
                              expression: "item.reply",
                            },
                          }),
                          index == 0
                            ? _c("e", {
                                staticClass: "icon el-icon-circle-plus-outline",
                                on: {
                                  click: function ($event) {
                                    return _vm.addanswer()
                                  },
                                },
                              })
                            : _vm._e(),
                          index != 0
                            ? _c("e", {
                                staticClass: "icon el-icon-remove-outline",
                                on: {
                                  click: function ($event) {
                                    return _vm.plusanswer(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: { "margin-top": "60px", "text-align": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }