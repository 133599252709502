<template>
  <el-dialog
    title="编辑"
    :visible.sync="showedit"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="店铺名称" prop="mall_name">
          <el-input v-model="form.mall_name" placeholder="请输入公司名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="company_name">
          <el-input v-model="form.company_name" placeholder="请输入公司名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="邀请人" prop="inviter_name">
          <el-input v-model="form.inviter_name" placeholder="请输入邀请人" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            clearable
            placeholder="状态"
            style="width: 160px"
          >
            <el-option
              label="已审核"
              value="1"
            />
            <el-option
              label="待审核"
              value="2"
            />
            <el-option
              label="已拒绝"
              value="3"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="拒绝原因" v-if="form.status==3" prop="reason">
          <el-input v-model="form.reason" placeholder="拒绝原因" clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showedit = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/request";
export default {
  name: "edit",
  data(){
    return{
      form:{
        id:'',
        inviter_name:'',
        mall_name:'',
        status:'',
        reason:'',
      },
      rules:{
        mall_name: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
        reason: [
          { required: true, message: "请输入拒绝原因", trigger: "blur" },
        ]
      },
      showedit:false
    }
  },
  watch:{
    showedit(v){
      if(!v){
        this.$refs.form.resetFields()
        this.form={
          id:'',
          inviter_name:'',
          mall_name:'',
          company_name:'',
          status:'',
          reason:'',
        }
      }
    }
  },
  methods: {
    handlerinfo(row){
      this.form= Object.assign(this.form,row)
    },
    handlersave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction('/audit/shop/mall-edit', this.form).then(res => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.$emit("getlist")
              this.showedit = false
            }
          })
        }
      })
    }


  }
};
</script>

<style scoped>

</style>
