<template>
  <el-dialog
    title="图片裁剪"
    :visible.sync="showcorpper"
    width="1200px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    v-if="showcorpper"
  >
    <div>
      <!--      <h3 style="color: red">小提示：</h3>-->
      <!--      <h3 style="color: red">1、鼠标滚轮可快速调整图片大小</h3>-->
      <!--      <h3 style="color: red">2、若裁切效果不理想，点击“放大”和“缩小”按钮进行微调</h3>-->
      <el-row :gutter="10" type="flex" justify="space-between">
        <el-col :span="12">
          <vue-cropper
            ref="cropper"
            class="cropper"
            style="width: 500px; height: 500px"
            :img="option.img"
            :outputType="option.outputType"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :full="option.full"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :enlarge="option.enlarge"
            :mode="option.mode"
            :canScale="option.canScale"
            @realTime="handlerRealTime"
          />

          <!--         <div class="footer-btn">
           <el-button type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
           <el-button type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>

           <el-button type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
           <el-button type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
         </div>-->
        </el-col>
        <el-col :span="12">
          <div :style="previewStyle">
            <img :src="previews.url" :style="previews.img" />
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handlerclose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { VueCropper } from 'vue-cropper'
  export default {
    name: 'corpper',
    props: {
      autoCropWidth: {
        type: Number,
        default: 0,
      },
      enlarge: {
        type: Number,
        default: 0,
      },
      autoCropHeight: {
        type: Number,
        default: 0,
      },
    },
    components: {
      VueCropper,
    },
    data() {
      return {
        showcorpper: false,
        previews: {},
        previewStyle: {},
        option: {
          img: '',
          full: false, //输出原图比例截图 props名full
          outputType: 'png',
          canScale: true, //是否允许图片缩放
          canMove: false,
          original: false,
          canMoveBox: true,
          autoCrop: true,
          autoCropWidth: this.autoCropWidth, //默认生成截图框宽度	容器的80%	0~max
          autoCropHeight: this.autoCropHeight, //默认生成截图框高度	容器的80%	0~max
          fixedBox: false,
          fixed: true,
          fixedNumber: [1, this.autoCropHeight / this.autoCropWidth],
          centerBox: true,
          infoTrue: true,
          enlarge: this.enlarge, //图片根据截图框输出比例倍数
          mode: '500px 500px',
        },
      }
    },
    watch: {
      showcorpper(val) {
        if (!val) {
          this.handlerclose()
        }
      },
    },
    methods: {
      handlerclose() {
        this.showcorpper = false
        this.$emit('close')
      },
      handlersave() {
        this.$refs.cropper.getCropData((data) => {
          this.$emit('uploadImg', data)
          this.handlerclose()
        })
      },
      setAutoCrop() {
        this.$refs.cropper.goAutoCrop()
      },
      handlerRealTime(data) {
        this.previewStyle = {
          width: data.w + 'px',
          height: data.h + 'px',
          overflow: 'hidden',
          margin: '0',
        }
        this.previews = data
      },
      //图片缩放
      changeScale(num) {
        num = num || 1
        this.$refs.cropper.changeScale(num)
      },
      //向左旋转
      rotateLeft() {
        this.$refs.cropper.rotateLeft()
      },
      //向右旋转
      rotateRight() {
        this.$refs.cropper.rotateRight()
      },
    },
  }
</script>

<style scoped>
  .cropper {
    width: 500px;
    height: 500px;
  }
  .footer-btn {
    margin-top: 20px;
  }
</style>
