<template>
  <el-dialog
    :title="title"
    :visible.sync="showedit"
    width="30%">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="选择店铺">
        <el-autocomplete
          style="width: 160px;"
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          placeholder="请输入店铺名称"
          @select="handleSelectmall"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="所属公司">
        {{company_name}}
      </el-form-item>

      <el-form-item label="排序">
        <el-input-number v-model="form.sort" :min="0" label="排序"></el-input-number>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.status" clearable style="width: 160px;" placeholder="状态">
          <el-option v-for="list in mealselect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showedit = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "AddEdit",

  data(){
    return{
      showedit:false,
      title:'新增',
      company_name:'',
      mealselect:[
        {
          id:'1',
          name:'启用'
        },
        {
          id:'2',
          name:'禁用'
        },
      ],
      form:{
        id:'',
        product_id:'',
        mall_name:'',
        sort:0,
        status:'1',
      }
    }
  },
  watch:{
    showedit(val){
      if(!val){
        this.form={
          id:'',
          product_id:'',
          mall_name:'',
          sort:0,
          status:'1',
        }
        this.title="新增"
        this.company_name=''
      }
    }
  },
  methods:{
    handlersave(){
      getAction("/mall/plate-product/mall-edit",this.form).then(res=>{
        if(res.request_code==200){
          this.showedit=false
          this.$message({
            type:'success',
            message:res.msg
          })
          this.$emit("getlist")
        }
      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      this.company_name=row.company_name
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.product_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.product_id=list.id
      this.company_name=list.certification.company_name
    },
  }
};
</script>

<style scoped>

</style>
