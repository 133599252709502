<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="900px"
    top="7vh"
    :close-on-click-modal="false"
    :modal="true"
  >
    <div>
      <el-form ref="form" :model="form" label-width="180px" :rules="rules">
        <el-form-item label="活动名称" prop="name">
          <el-input
            v-model="form.name"
            clearable
            placeholder="活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="首页banner" prop="banner_pic">
          <upload-img ref="banner_pic" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'banner_pic')"/>
        </el-form-item>
        <el-form-item label="首页背景色" prop="bg_color">
          <el-input
            v-model="form.bg_color"
            clearable
            placeholder="首页背景色"
          ></el-input>
        </el-form-item>
        <el-form-item label="首页栏目背景色" prop="menu_bg_color">
          <el-input
            v-model="form.menu_bg_color"
            clearable
            placeholder="首页栏目背景色"
          ></el-input>
        </el-form-item>
        <el-form-item label="首页栏目标题文字颜色" prop="menu_title_color">
          <el-input
            v-model="form.menu_title_color"
            clearable
            placeholder="首页栏目标题文字颜色"
          ></el-input>
        </el-form-item>
        <el-form-item label="首页栏目副标题文字颜色" prop="menu_subtitle_color">
          <el-input
            v-model="form.menu_subtitle_color"
            clearable
            placeholder="首页栏目副标题文字颜色"
          ></el-input>
        </el-form-item>
        <el-form-item label="二级页背景色" prop="sub_bg_color">
          <el-input
            v-model="form.sub_bg_color"
            clearable
            placeholder="二级页背景色"
          ></el-input>
        </el-form-item>
        <el-form-item label="海报" prop="poster_pic">
          <upload-img ref="poster_pic" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'poster_pic')"/>
        </el-form-item>
        <el-form-item label="分享标题" prop="share_title">
          <el-input
            v-model="form.share_title"
            clearable
            placeholder="分享标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="分享图" prop="share_pic">
          <upload-img ref="share_pic" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'share_pic')"/>
        </el-form-item>
        <el-form-item label="二维码横坐标" prop="qrcode_x">
          <el-input
            v-model="form.qrcode_x"
            type="number"
            clearable
            placeholder="二维码横坐标"
          ></el-input>
        </el-form-item>
        <el-form-item label="二维码纵坐标" prop="qrcode_y">
          <el-input
            v-model="form.qrcode_y"
            type="number"
            clearable
            placeholder="二维码纵坐标"
          ></el-input>
        </el-form-item>
        <el-form-item label="二维码宽度" prop="qrcode_width">
          <el-input
            v-model="form.qrcode_width"
            type="number"
            clearable
            placeholder="二维码宽度"
          ></el-input>
        </el-form-item>
        <el-form-item label="二维码高度" prop="qrcode_height">
          <el-input
            v-model="form.qrcode_height"
            type="number"
            clearable
            placeholder="二维码高度"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: center">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { getAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "AddEdit",
  components: { UploadImg },
  data(){
    return{
      showDialog:false,
      title:'',
      form:{
        id:'',
        name:'',
        banner_pic:'',
        bg_color:'',
        menu_bg_color:'',
        menu_title_color:'',
        menu_subtitle_color:'',
        sub_bg_color:'',
        poster_pic:'',
        share_title:'',
        share_pic:'',
        qrcode_x:'',
        qrcode_y:'',
        qrcode_width:'',
        qrcode_height:'',
      },
      rules:{
        name:[{ required: true, message: "活动名称" }],
        banner_pic:[{ required: true, message: "请上传首页banner" }],
        bg_color:[{ required: true, message: "请输入背景颜色" }],
        menu_bg_color:[{ required: true, message: "请输入首页栏目背景色" }],
        menu_title_color:[{ required: true, message: "请输入首页栏目标题文字颜色" }],
        menu_subtitle_color:[{ required: true, message: "请输入首页栏目副标题文字颜色" }],
        sub_bg_color:[{ required: true, message: "请输入二级页背景色" }],
        poster_pic:[{ required: true, message: "请上传海报图" }],
        share_title:[{ required: true, message: "请输入分享语" }],
        share_pic:[{ required: true, message: "请上传分享图" }],
        qrcode_x:[{ required: true, message: "请输入二维码横坐标" }],
        qrcode_y:[{ required: true, message: "请输入二维码纵坐标" }],
        qrcode_width:[{ required: true, message: "请输入二维码宽度" }],
        qrcode_height:[{ required: true, message: "请输入二维码高度" }],
      }
    }
  },
  watch:{
    showDialog(val){
      if(!val){
        this.close()
      }
    }
  },
  methods:{
    verifyForm(cb) {
      this.$refs.form.validate((v) => {
        if (v) {
          cb()
        }
      })
    },
    handlerinfo(row){
      getAction("/api/system/policy/conf-info",{id:row.id}).then(res=>{
        this.form=res.data
        nextTick(()=>{
          this.setImg(res.data.banner_pic,'banner_pic')
          this.setImg(res.data.poster_pic,'poster_pic')
          this.setImg(res.data.share_pic,'share_pic')
        })
      })
    },
    handleSubmit(){
      this.verifyForm(async () => {
          getAction("/api/system/policy/conf-edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.close()
            this.$emit("getlist")
          })
      })
    },
    show(){
      this.showDialog=true
    },
    close(){
      this.showDialog=false
      this.$refs.form.resetFields()
      this.form={
        id:'',
        name:'',
        banner_pic:'',
        bg_color:'',
        menu_bg_color:'',
        menu_title_color:'',
        menu_subtitle_color:'',
        sub_bg_color:'',
        poster_pic:'',
        share_title:'',
        share_pic:'',
        qrcode_x:'',
        qrcode_y:'',
        qrcode_width:'',
        qrcode_height:'',
      }
      },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
