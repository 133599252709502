<template>
  <el-dialog content :visible.sync="showEditPoster" width="500px">
    <div>
      <el-form
        ref="form"
        :model="form"
        inline
        label-width="110px"
        :rules="rules"
      >
        <el-form-item label="活动标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            style="width: 280px"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="分享标题" prop="share_title">
          <el-input
            v-model="form.share_title"
            placeholder="请输入"
            style="width: 280px"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="小程序分享图" prop="friend_pic">
          <upload-img
            ref="friend_pic"
            :info-text="'建议尺寸 500 * 400，jpg或png格式图片'"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'friend_pic')"
          />
        </el-form-item>
        <el-form-item label="朋友圈分享图" prop="circle_pic">
          <upload-img
            ref="circle_pic"
            :info-text="'建议尺寸 400 * 400，png格式图片 '"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'circle_pic')"
          />
        </el-form-item>
        <el-form-item label="中转图片" prop="bg_img">
          <upload-img
            ref="bg_img"
            :info-text="'建议尺寸 750 * 1560'"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'bg_img')"
          />
        </el-form-item>
        <el-form-item label="按钮图片" prop="button_pic">
          <upload-img
            ref="button_pic"
            :info-text="'建议尺寸 20 *50'"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'button_pic')"
          />
        </el-form-item>
        <el-form-item label="链接地址" prop="jump_url">
          <el-input
            v-model="form.jump_url"
            placeholder="请输入"
            style="width: 280px"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="'1'">启用</el-radio>
            <el-radio :label="'0'">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showEditPoster = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  import { nextTick } from 'vue'

  export default {
    name: 'addedit',
    components: { UploadImg },
    data() {
      return {
        showEditPoster: false,
        staffOption: [],
        roleSelect: [
          {
            id: '1',
            name: '所有人',
          },
          {
            id: '2',
            name: '部分人',
          },
        ],
        jumpSelect: [
          {
            id: '1',
            name: 'H5链接',
          },
          {
            id: '2',
            name: '报名H5',
          },
          {
            id: '3',
            name: '小程序链接',
          },
          {
            id: '4',
            name: '开通店铺绿色通道H5',
          },
        ],
        form: {
          title: '',
          bg_img: '',
          button_pic: [],
          jump_url: '',
          status: '1',
        },
        typeSelect: [],
        rules: {
          title: [
            { required: true, message: '请输入活动标题', trigger: 'change' },
          ],
          share_title: [
            { required: true, message: '请输入分享标题', trigger: 'change' },
          ],
          bg_img: [
            {
              required: true,
              message: '请上传中转图片',
              trigger: 'change',
            },
          ],
          button_pic: [
            {
              required: true,
              message: '请上传按钮图片',
              trigger: 'change',
            },
          ],
          friend_pic: [
            {
              required: true,
              message: '请上传分享小程序图',
              trigger: 'change',
            },
          ],
          circle_pic: [
            {
              required: true,
              message: '请上传分享朋友圈图片',
              trigger: 'change',
            },
          ],
          jump_url: [
            { required: true, message: '请输入链接地址', trigger: 'change' },
          ],
        },
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          console.log('重置----')
          this.form = {
            title: '',
            bg_img: '',
            button_pic: [],
            status: '1',
          }
          this.closeimg('friend_pic')
          this.closeimg('circle_pic')
          this.closeimg('bg_img')
          this.closeimg('button_pic')
          this.$refs.form.resetFields()
        }
      },
    },
    mounted() {
      getAction('/form/form/staff').then((res) => {
        this.staffOption = res.data
      })
      getAction('/api/system/staff-console-type/lists', {
        limit: -1,
        status: 1,
      }).then((res) => {
        this.typeSelect = res.data.list
      })
    },
    methods: {
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.form))
            delete data.created
            getAction('/api/system/transfer/edit', data).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.$emit('getlist')
              this.showEditPoster = false
            })
          } else {
            this.$message.error('请检查必填信息是否填写完整')
          }
        })
      },
      handlerinfo(row) {
        this.form = row
        nextTick(() => {
          this.setImg(this.form.friend_pic, 'friend_pic')
          this.setImg(this.form.circle_pic, 'circle_pic')
          this.setImg(this.form.bg_img, 'bg_img')
          this.setImg(this.form.button_pic, 'button_pic')
        })
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>

<style scoped></style>
