<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerlist">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="10">
        <el-table
          :data="tabledata"
          style="width: 100%">
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            width="">
          </el-table-column>
          <el-table-column
            prop="name"
            label="标题"
            align="center"
            width="">
          </el-table-column>
          <el-table-column
            prop="pic"
            align="center"
            label="图片">
            <template #default="{row}">
              <el-image
                style="width: 100px; height: 100px"
                :src="row.pic"
                :preview-src-list="[row.pic]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="sort_order"
            label="排序"
            align="center"
            width="">
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="操作">
            <template #default="{row}">
              <el-button type="text" @click="handlerdelete(row)">删除</el-button>
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          style="text-align: center; margin-top: 10px"
        ></el-pagination>
      </el-col>
    </el-row>
    <add ref="add" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import add from './components/add'

export default {
  components:{
    add
  },
  name: "index",
  data(){
    return{
      tabledata:[],
      form: {
        page: 1,
        limit: 10,
        title:'',
      },
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleredit(row){
      this.$refs.add.showAdd=true
      this.$refs.add.handlerinfo(row)
    },
    handleradd(){
      this.$refs.add.showAdd=true
    },
    handlerdelete(row){
      postAction("/api/afbc/hot/delete",{id:row.id}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerlist()
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.limit = val
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/afbc/hot/index",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    }
  }
};
</script>

<style scoped>

</style>
