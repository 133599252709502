<!--专区-->
<template>
  <el-dialog
    title="精选布展企业"
    :visible.sync="zoneshow"
    append-to-body
    width="80%">
    <div>
      <el-form ref="form" :model="form" label-width="80px" inline>
        <el-form-item>
          <el-button type="primary" @click="handlerAdd">新 增</el-button>
        </el-form-item>
        <el-form-item>
          <el-autocomplete
            style="width: 160px;"
            v-model="form.live_name"
            :fetch-suggestions="handlermell"
            value-key="mall_name"
            clearable
            placeholder="请输入店铺名称"
            @select="handleSelectmall"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.hotels_id1"
            class="input"
            clearable
            placeholder="请选择酒店1"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.hotels_no1"
            clearable
            placeholder="展位号1"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.hotels_id2"
            class="input"
            clearable
            placeholder="请选择酒店2"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.hotels_no2"
            clearable
            placeholder="展位号2"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tabledata"
                style="width: 100%"
                ref="ElTable">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '类型'">
              {{row[item.prop]==1?'春糖':'秋糖'}}
            </div>
            <div v-else-if="item.label == '状态'">
              {{ row[item.prop] == 1 ? '开启' : '关闭' }}
            </div>
            <div v-else-if="item.label == '报名跳转图片'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else-if="item.label == '列表分享图'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template #default="{ row }">
            <el-button
              size="small"
              style="margin-left: 10px"
              type="text"
              @click.native.prevent="handleredit(row)"
            >
              编辑
            </el-button>
            <el-button
              size="small"
              style="margin-left: 10px"
              type="text"
              @click.native.prevent="handlerdelete(row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <add-live ref="addzone" @handlerlist="handlerInquire" :catSelect="catSelect"/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="zoneshow = false">取 消</el-button>
      <el-button type="primary" @click="zoneshow = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddLive from "./components/AddLive";

export default {
  name: "index",
  components: { AddLive },
  data(){
    return{
      zoneshow:false,
      form: {
        conf_id:'',
        hotels_no1:'',
        hotels_no2:'',
        mall_id:'',
        hotels_id1:'',
        hotels_id2:'',
        limit: 10,
        page: 1,
      },
      catSelect:[],
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '',
          align:'center'
        },
        {
          label: '关联店铺',
          prop: 'mall_name',
          width: '',
          align:'center'
        },
        {
          label: '酒店名称1',
          prop: 'hotels_name1',
          width: '',
          align:'center'
        },
        {
          label: '展位号1',
          prop: 'hotels_no1',
          width: '',
          align:'center'
        },
        {
          label: '酒店名称2',
          prop: 'hotels_name2',
          width: '',
          align:'center'
        },
        {
          label: '展位号2',
          prop: 'hotels_no2',
          width: '',
          align:'center'
        },
        {
          label: '排序',
          prop: 'sort_order',
          width: '',
          align:'center'
        },

      ],
      total: 0,
    }
  },
  watch:{
    zoneshow(val){
      if(!val){
        this.form={
          conf_id:'',
          hotels_no1:'',
          hotels_no2:'',
          mall_id:'',
          hotels_id1:'',
          hotels_id2:'',
          limit: 10,
          page: 1,
        }
      }
    }
  },
  methods:{
    handlerAdd(){
      this.$refs.addzone.form.conf_id=this.form.conf_id
      this.$refs.addzone.showadd=true
    },
    handleredit(row){
      this.$refs.addzone.handlerinfo(row)
      this.$refs.addzone.showadd=true
    },
    handlerdelete(row){
      this.$confirm('此操作将删除该酒店布展企业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/hotel/mall/delete",{id:row.id}).then(res=>{
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
   async handlerlist() {
     await getAction('/api/hotel/mall/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
     await getAction("/api/hotel/hotels/select",{conf_id:this.form.conf_id}).then((res) => {
        this.catSelect = res.data
      })
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{searchtext:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }

};
</script>

<style scoped>

</style>
