<template>
  <div>
      <el-dialog
        :title="title"
        :visible.sync="is_show"
        width="70%"
        :before-close="handleClose" align="center">
        <div class="related">
          <!-- <div style="margin-bottom:20px">
            <el-button type="primary" @click="handlerEdit()">新增题目</el-button>
          </div> -->
          <el-table
            :data="list"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName">
            <el-table-column
              prop="reply"
              label="一级品类"
              width="120"
              align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.child!=undefined">{{scope.row.reply}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="二级品类"
              prop='reply'>
              <template slot-scope="scope">
                <span v-if="scope.row.child==undefined">{{scope.row.reply}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="排序"
              width="100" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="move(scope.row,'-1')">上移</el-button>
                <el-button type="text" @click="move(scope.row,'1')">下移</el-button>
              </template>
            </el-table-column>

            <el-table-column
              prop="option"
              label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div style="display:flex;justify-content:flex-end;align-items:center;">
                  <el-button type="text" v-if="scope.row.child!=undefined" @click="handlerEdit(scope.row)">编辑</el-button>
                  <el-button type="text" @click="del(scope.row)">删除</el-button>
                  <el-button type="text" @click="changestate(scope.row,'is_hide')">{{scope.row.is_hide==1 ? '禁用' : '启用'}}</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <editcategory ref="editcategory" @getinfo='getinfo'></editcategory>
    
  </div>
  
  
</template>
<script>
import { getAction, postAction } from "@/api/request";
import editcategory from "./editcategory";
export default {
  name: 'category',
  components: { 
    editcategory,
  },
  props:{
      is_show: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      id:{
        type:String,
        default:'',
      },
    },
  data() {
    return {
      list:[],
      
    }
  },
  watch:{
    
  },
  mounted() {
  },
  methods: {
    getinfo() {
      // 获取详情
      let that = this;
      postAction("/api/system/research/category-index",{conf_id:that.id}).then(res=>{
          that.is_show=true;
          if(res.code==0){
            var d = res.data;
            var list=[];
            for(var i=0;i<d.length;i++){
              var data = d[i].child;
              list.push(d[i]);
              for(var m=0;m<data.length;m++){
                list.push(data[m])
              }
            }
            that.list = list;
            // console.log(list)
          }
      })
    },
    tableRowClassName({row, rowIndex}) {
        if(row.child!=undefined){
          return 'row-question';
        }
        return '';
    },
    move(row,type){
      let that = this;
      var data = {
        source: row.answer_list!=undefined ? '1' : '2',
        type:type,
        id:row.id
      }
      console.log(data)
      postAction("/api/system/research/set-sort",data).then(res=>{
        if(res.code==0){
          that.$message({
            message: '操作成功',
            type: 'success'
          });
          that.getinfo(that.id)
        }else{
          that.$message.error(res.msg);
        }
      })
    },
    del(row){
      let that = this;
      var data={
        source: row.answer_list!=undefined ? '1' : '2',
        id:row.id
      }
      console.log(data)
      postAction("/api/system/research/question-del",data).then(res=>{
        if(res.code==0){
          that.$message({
            message: '操作成功',
            type: 'success'
          });
          that.getinfo(that.id)
        }else{
          that.$message.error(res.msg);
        }
      })
    },
    changestate(row,text){
      let that = this;

      var value = row.is_hide=='0' ? '1' : '0'

      console.log(text,value)
      var data={
        source: row.answer_list!=undefined ? '1' : '2',
        id:row.id,
        field:text,
        value:value
      }
      console.log(data)
      postAction("/api/system/research/update-field",data).then(res=>{
        if(res.code==0){
          that.$message({
            message: '操作成功',
            type: 'success'
          });
          that.getinfo(that.id)
        }else{
          that.$message.error(res.msg);
        }
      })
    },
    handlerEdit(row){
      console.log(row)
      if(row){
        this.$refs.editcategory.editTitle='编辑调查品类'
      }
      this.$refs.editcategory.title = this.title;
      this.$refs.editcategory.form.id = row.id;
      this.$refs.editcategory.form.conf_id=this.id;
      this.$refs.editcategory.getinfo();
    },
    
    
  }
}
</script>

<style lang="scss" scoped>
  .related{
    text-align:left;
  }
  :deep() .el-table tr.row-question{
    background:#fbfbfb;
  }
  // :deep() .el-table--small .el-table__cell{
  //   padding: 0;
  // }
  // :deep() .el-table_2_column_22{
  //   padding:8px 0;
  // }
</style>