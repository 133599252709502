<template>
  <el-dialog content :visible.sync="showEditPoster" width="400px">
    <div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['12:00:00']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input v-model="form.code" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tabledata" style="width: 40%">
        <el-table-column
          label="总点击次数(PV)"
          prop="pv"
          align="center"
        ></el-table-column>
        <el-table-column
          label="总访客数(UV)"
          prop="pv"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            {{ row[item.prop] }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="220">
          <template #default="{ row }">
            <el-button
              size="small"
              style="margin: 10px"
              type="text"
              @click.native.prevent="handlerRecovery(row)"
            >
              恢复
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'addedit',
    data() {
      return {
        isShowStatis: false,
        form: {
          code: '',
          value: '',
          name: '',
          id: '',
          page: 1,
          limit: 10,
        },
        total: 0,
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'id',
            width: '',
          },
          {
            label: '产品标题',
            prop: 'id',
            width: '',
          },
          {
            label: '品类',
            prop: 'id',
            width: '',
          },
          {
            label: '店铺',
            prop: 'id',
            width: '',
          },
        ],

        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    methods: {
      handlerlist() {},
      //恢复
      handlerRecovery() {},
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>
