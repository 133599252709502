var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: _vm.title,
        visible: _vm.showedit,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属店铺" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "160px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  placeholder: "请输入店铺名称",
                  "suffix-icon": "el-icon-arrow-down",
                  "value-key": "mall_name",
                },
                on: { select: _vm.handleSelectmall },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { class: item.is_pay == 1 ? "payname" : "" },
                          [_vm._v(" " + _vm._s(item.mall_name) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择产品" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "160px" },
                attrs: {
                  disabled: _vm.form.mall_id == "",
                  "fetch-suggestions": _vm.handlergoods,
                  placeholder: "请输入产品名称",
                  "suffix-icon": "el-icon-arrow-down",
                  "value-key": "goods_name",
                },
                on: { select: _vm.handleSelectgoods },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                attrs: { label: "排序", min: 0 },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.mealselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }