<template>
  <el-dialog  :title="title"
              :visible.sync="showAddstore"
              width="450px"
              :append-to-body="true"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <el-form-item label="选择主题活动" prop="theme_id" v-if="title=='栏目设置——选择主题活动'">
        <el-select
          style="width: 100%"
          v-model="form.theme_id"
          placeholder="主题活动"
          filterable
          clearable
          @change="handlertheme"
        >
          <el-option
            v-for="item in product"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="选择政策大放送" prop="policy_id" v-else-if="title=='栏目设置——选择政策大放送'">
        <el-select
          style="width: 100%"
          v-model="form.policy_id"
          placeholder="政策大放送"
          filterable
          clearable
          @change="handlerpolicy"
        >
          <el-option
            v-for="item in malllist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="关联栏目"  prop="menu_id">
        <el-select
          style="width: 100%"
          v-model="form.menu_id"
          placeholder="政策大放送"
          filterable
          clearable
        >
          <el-option
            v-for="item in menulist"
            :key="item.menu_id"
            :label="item.menu_name"
            :value="item.menu_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <p v-if="title=='栏目设置——选择主题活动'">默认使用主题活动中的列表排序，修改顺序请至主题活动修改</p>
        <p v-else>默认使用政策大放送中的列表排序，修改顺序请至主题活动修改</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer center">
      <el-button @click="showAddstore=!showAddstore">取消</el-button>
      <el-button type="primary" @click="handlersave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/request";

export default {
  name: "policy",
  props:{
    title:{
      type:String,
      default:()=>{
        return "新增"
      }
    },
  },
  data(){
    return{
      showAddstore:false,
      form:{
        column_id	:"",
        theme_id:'',
        theme_menu_id:'',
        policy_id:'',
        policy_menu_id:'',
        menu_id:''
      },
      malllist:[],//政策大放送列表
      product:[],//主题活动列表
      menulist:[],//栏目列表
      rules:{
        theme_id:[
          { required: true, message: '请选择主题活动', trigger: 'change' }
        ],
        policy_id:[
          { required: true, message: '请选择政策大放送', trigger: 'change' }
        ],
        menu_id:[
          { required: true, message: '请选择栏目', trigger: 'change' }
        ]
        // theme_menu_id:[
        //   { required: true, message: '请选择主题活动栏目', trigger: 'change' }
        // ],
        // policy_menu_id:[
        //   { required: true, message: '请选择政策大放送栏目', trigger: 'change' }
        // ],
      }
    }
  },
  watch:{
    showAddstore(old){
      if(!old){
        this.$refs.form.resetFields()
        this.form={
            column_id:"",
            theme_id:'',
            theme_menu_id:'',
            policy_id:'',
            policy_menu_id:'',
            menu_id:''
        }
      }
    }
  },
  mounted() {
    // this.handlermalllist()
    this.handlerProduct()
  },
  methods:{
    // handlermalllist(){
    //   postAction("/Live/Policy", {}).then(res=>{
    //     this.malllist=res.result
    //   })
    // },
    handlerProduct(){
      postAction("/live/index/theme-act", {}).then(res=>{
        this.product=res.data
      })
    },
    handlersave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.title=='栏目设置——选择主题活动'){
            let {column_id,theme_id,menu_id}=this.form
            postAction("/live/index/edit-areatheme", {column_id,theme_id,theme_menu_id:menu_id}).then(res => {
              this.$message.success(res.msg)
              this.showAddstore = false
              this.$emit("getlist")
            })
          }else {
            let {column_id,policy_id,menu_id}=this.form
            postAction("/Live/Area_policy_submit", {column_id,policy_id,policy_menu_id:menu_id}).then(res => {
              this.$message.success(res.msg)
              this.showAddstore = false
              this.$emit("getlist")
            })
          }

        }
      })
    },
    handlertheme(e){
      console.log(e)
      this.form.menu_id=''
      this.menulist=this.product.filter(list=>list.id==e)[0].menu_list
    },
    handlerpolicy(e){
      console.log(e)
      this.form.menu_id=''
      this.menulist=this.malllist.filter(list=>list.id==e)[0].menu_list
    },
    handlerinittheme(){
      postAction("/live/index/area-theme",{column_id:this.form.column_id}).then(res=>{
        this.form = Object.assign(this.form,res.data)
        this.form.menu_id=this.form.theme_menu_id
        this.menulist=this.product.filter(list=>list.id==this.form.theme_id)[0].menu_list
      })
    },
    handlerinitpolicy(){
      postAction("/Live/Area_policy",{column_id:this.form.column_id}).then(res=>{
        this.form = Object.assign(this.form,res.result[0])
        this.form.menu_id=this.form.policy_menu_id
        this.menulist=this.malllist.filter(list=>list.id==this.form.policy_id)[0].menu_list
      })
    }
  }
}
</script>

<style scoped>

</style>
