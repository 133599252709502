var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderTest-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "imgList",
        staticStyle: { display: "inline-block" },
      },
      [
        _vm._l(_vm.img, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "dragging",
                  rawName: "v-dragging",
                  value: {
                    item: item,
                    list: _vm.img,
                    group: "item",
                    key: index,
                  },
                  expression:
                    "{ item: item, list: img, group: 'item', key: index }",
                },
              ],
              key: index,
              staticClass: "imgList",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "div",
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "148px",
                      height: "148px",
                      "margin-right": "10px",
                    },
                    attrs: {
                      src: item,
                      fit: "cover",
                      "preview-src-list": _vm.img,
                    },
                  }),
                  !_vm.loading
                    ? _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disab,
                            expression: "!disab",
                          },
                        ],
                        staticClass: "el-icon-error close",
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteImg(index)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        _c(
          "el-upload",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.img.length < _vm.limit,
                expression: "img.length < limit",
              },
            ],
            staticClass: "upload-demo",
            attrs: {
              "list-type": "picture-card",
              multiple: "",
              "show-file-list": false,
              "http-request": _vm.uploadImg,
              "on-exceed": _vm.limitLong,
              name: "file",
              accept: ".png, .jpg, .jpeg",
              action: "#",
              disabled: _vm.disab,
            },
          },
          [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
        ),
        _c("cropper", {
          ref: "corpper",
          attrs: {
            autoCropWidth: _vm.autoCropWidth,
            autoCropHeight: _vm.autoCropHeight,
            enlarge: _vm.enlarge,
            mode: _vm.mode,
          },
          on: { close: _vm.closecorpper, uploadImg: _vm.uploadImgcorpper },
        }),
      ],
      2
    ),
    _vm.showInfo
      ? _c(
          "div",
          {
            staticStyle: {
              "line-height": "1.2",
              "margin-top": "10px",
              color: "red",
              "font-size": "12px",
            },
          },
          [_vm._v(" " + _vm._s(_vm.infoText) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }