<template>
  <el-cascader
    v-model="value"
    ref="com"
    popper-class="panelClass"
    filterable
    :props="props"
    clearable
    @change="change"
    placeholder="请选择地区"
  ></el-cascader>
</template>
<script>
import { getAction } from "@/api/request";
export default {
  name: "AreaSelect",
  components: {},
  data() {
    return {
      value: "",
      props: {
        checkStrictly: true,
        lazy: true,
        value: "id",
        label: "name",
        lazyLoad: async (node, res) => {
          const { level } = node
          const pid = node.data?.id
          console.log("node", node)
          // this.value = node.value
          if (level !== 3) {
            let data = await getAction("/user/index/area", { type: level, pid })
            console.log(data)
            data.data.forEach((i) => {
              i.leaf = level >= 2
            })
            res(data.data)
          } else {
            node.hasChildren = false
            node.children = undefined
            node.loaded = undefined
            node.loading = undefined
          }
        },
      },
    }
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    change(e) {
      const data = {}
      data.prov = e[0]
      data.city = e[1]
      data.county = e[2]
      Object.keys(data).forEach((key) => {
        if (data[key] == undefined) {
          data[key] = null
        }
      })
      this.$emit("select", data)
      this.$refs.com.dropDownVisible = false
    },
  },
}
</script>
<style lang="scss">
//.panelClass {
//  height: 300px !important;
//  .el-cascader-panel {
//    height: 300px !important;
//  }
//}
</style>
