<template>
  <div>
<!--    <el-button type="primary" @click="showadd=true">新 增</el-button>-->
  <el-dialog :visible.sync="showadd" width="300">
    <div>
      <el-form ref="form" label-suffix=":" :model="form" :rules="rules" label-width="160px">
        <el-form-item label="公司名称" prop="company_name">
          <el-input style="width: 260px" v-model="form.company_name" placeholder="请输入公司名称" @blur="findCompany" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称" prop="mall_name">
          <el-input style="width: 260px"  v-model="form.mall_name" placeholder="请输入店铺名称" @blur="findMall" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="营业执照">
          <upload-img
            ref="business_url"
            info-text=""
            :limit="1"
            :max-size="0.5"
            @getImgs="get_imgImg($event, 'business_url')"
          />
        </el-form-item>
        <el-form-item label="企业员工身份证明材料">
          <upload-img
            ref="employee_materials_url"
            info-text=""
            :limit="3"
            :max-size="0.5"
            @getImgs="get_imgImg($event, 'employee_materials_url')"
          />
        </el-form-item>
        <el-form-item label="佐证材料">
          <upload-img
            ref="evidence_url"
            info-text=""
            :limit="10"
            :max-size="0.5"
            @getImgs="get_imgImg($event, 'evidence_url')"
          />
        </el-form-item>
        <el-form-item label="所属行业" prop="trade">
          <el-cascader
            v-model="form.trade"
            popper-class="ssaassd"
            placeholder="经营品类"
            :options="cateSelect"
            clearable
            :show-all-levels="false"
            :props="{ multiple:true,emitPath:false, value: 'id',label:'name', }"
            @change="change"></el-cascader>
        </el-form-item>
        <el-form-item label="申请人姓名" prop="apply_name">
          <el-input style="width: 160px"  v-model="form.apply_name" placeholder="请输入申请人姓名" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="申请人职务">
          <el-input style="width: 160px"  v-model="form.apply_job" placeholder="请输入申请人职务" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="主管理员手机号" prop="mobile">
          <el-autocomplete
            v-model="form.mobile"
            :fetch-suggestions="handlerphone"
            placeholder="请输入手机号"
            style="width: 160px"
            value-key="phone"
            @select="handleSelectphone"
            @change="findUser"
          />
        </el-form-item>
        <el-form-item label="店铺log">
          <upload-img
            ref="logo"
            info-text=""
            :limit="1"
            :max-size="0.5"
            @getImgs="get_imgImg($event, 'logo')"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  components: {
    uploadImg: () => import("@/components/uploadImg"),
  },
  name: "newStore",
  data() {
    return {
      showadd: false,
      form: {
        mall_name: "",
        company_name: "",
        business_url: "",
        employee_materials_url: "",
        evidence_url: "",
        trade: [],
        apply_name: "",
        apply_job: "",
        mobile: "",
        user_id: "",
        logo: ""
      },
      cateSelect: [],
      rules: {
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        mall_name: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
        ],
        apply_name: [
          { required: true, message: "请输入申请人姓名", trigger: "change" },
        ],
        apply_job: [
          { required: true, message: "请输入申请人职务", trigger: "change" },
        ],
        trade: [
          { required: true, message: "请选择经营品类", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入主管理员手机号", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    showadd(val) {
      if (!val) {
        this.form = {
          mall_name: "",
          company_name: "",
          business_url: "",
          employee_materials_url: "",
          evidence_url: "",
          trade: "",
          apply_name: "",
          apply_job: "",
          mobile: "",
          user_id: "",
          logo: ""
        };
        this.closeimg('logo')
        this.closeimg('evidence_url')
        this.closeimg('business_url')
        this.closeimg('employee_materials_url')
        this.change([])
        this.$refs.form.resetFields();
      }
    }
  },
  mounted() {
    this.getSelection()
  },
  methods: {
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.trade=data.trade.join(",")
          postAction('/audit/shop/apply-admin-channel', data).then((res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: '添加成功',
              })
              this.showadd = false
              this.$emit('getList')
            }
          })
        }
      })
    },
    findUser(e){
      console.log(this.form.mobile,e)
      if(e!=undefined){
        this.form.user_id = ''
      }
      if(/^1[2-9]\d{9}$/.test(this.form.mobile)){
        postAction('/audit/shop/check-user', { mobile: this.form.mobile,user_id: this.form.user_id }).then((res) => {
          if(res.data.is_ok){
            this.$message({
              type: 'warning',
              message: '该手机号已是其他店铺管理员',
            })
          }

        })
     /* }else{
        this.$message({
          type: 'warning',
          message: '请输入正确的手机号',
        })*/
      }
    },
    findMall(){
      if(this.form.mall_name){
      postAction('/audit/shop/check-mall-name', { mall_name: this.form.mall_name }).then((res) => {
        if(res.data.is_ok){
          this.$message({
            type: 'warning',
            message: '该店铺已存在',
          })
        }
      })}
    },
    findCompany(){
      if(this.form.company_name){
        postAction('/audit/shop/check-apply-company', { company_name: this.form.company_name }).then((res) => {
          if(res.data.is_ok){
            this.$message({
              type: 'warning',
              message: '公司已存在',
            })
          }
        })
      }
    },
    getSelection(){
      // 获取品类
      getAction('/user/index/cate-tree', { pid: 0 }).then((res) => {
        this.cateSelect = res.data
      })
    },
    // 处理禁用状态
    change(value) {
      console.log(value)
      // 处理禁用状态
      this.$nextTick(() => {
        var options = []
        var disabled = value.length >= 3 // 大于等于3时禁用
        this.cateSelect.forEach(option => {
          option.children.forEach(child => {
            // 如果是选中的则不需禁用
            if (value.find(array => array === child.id)) {
              child.disabled = false
            } else {
              child.disabled = disabled
            }
          })
          options.push(option)
        })
        // 重新进行赋值
        this.cateSelect = options
      })
    },
    handlerphone(queryString, cb) {
      if (queryString == '') {
        cb([])
        this.form.user_id = ''
      } else {
        getAction('/user/index/search-user', { phone: queryString }).then(
          (res) => {
            cb(res.data)
          }
        )
      }
    },
    handleSelectphone(list) {
      console.log(list)
      this.form.user_id = list.id
      this.form.mobile = list.phone
      this.findUser()
    },
    // 获取商品展示图写入form
    get_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v.join(',')
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    setImgArray(v, prop) {
      if (v[0]!='') this.$refs[prop].img = v
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style >
.ssaassd{
  .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
    display: none !important;
  }
}

</style>
