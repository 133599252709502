var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showSelection, width: "900px" },
      on: {
        "update:visible": function ($event) {
          _vm.showSelection = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { inline: "", "label-width": "80px", model: _vm.form },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "产品名称" },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "所属店铺" },
                    model: {
                      value: _vm.form.mall_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mall_name", $$v)
                      },
                      expression: "form.mall_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerlist },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleradd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            [
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    align: "center",
                    label: item.label,
                    prop: item.prop,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            item.label == "云会议状态"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] == 1,
                                            expression: "row[item.prop] == 1",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleraddcloud(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 待创建 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              row[item.prop] == 3 &&
                                              row.ask_status == 2,
                                            expression:
                                              "row[item.prop] == 3 && row.ask_status == 2",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v(" 已完成 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              row[item.prop] == 3 &&
                                              row.ask_status == 1,
                                            expression:
                                              "row[item.prop] == 3 && row.ask_status == 1",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v(" 会议已结束 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] > 1,
                                            expression: "row[item.prop] > 1",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlercloudinfo(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 详情 ")]
                                    ),
                                    _c("vue-qr", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] == 2,
                                          expression: "row[item.prop] == 2",
                                        },
                                      ],
                                      attrs: {
                                        margin: 10,
                                        size: 100,
                                        text: row.meeting_qr,
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] == 2,
                                            expression: "row[item.prop] == 2",
                                          },
                                        ],
                                      },
                                      [_vm._v("请用企业微信扫码")]
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "调查问卷"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] == 2,
                                            expression: "row[item.prop] == 2",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v(" 已完成 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] == 2,
                                            expression: "row[item.prop] == 2",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlersurveyinfo(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 详情 ")]
                                    ),
                                    _c("vue-qr", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] == 1,
                                          expression: "row[item.prop] == 1",
                                        },
                                      ],
                                      attrs: {
                                        margin: 10,
                                        size: 100,
                                        text: row.ask_qr,
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row[item.prop] == 1,
                                            expression: "row[item.prop] == 1",
                                          },
                                        ],
                                      },
                                      [_vm._v("请用企业微信扫码")]
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "云会议是否有效"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row[item.prop] == 1 ? "有效" : "无效"
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.need_connect_status != 1,
                                    expression: "row.need_connect_status != 1",
                                  },
                                ],
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerreedit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.need_connect_status != 1,
                                    expression: "row.need_connect_status != 1",
                                  },
                                ],
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerrefuse(row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  "confirm-button-text": "设置无效",
                                  title: "设置会议无效",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handlerfurm(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.is_valid == 1,
                                        expression: "row.is_valid == 1",
                                      },
                                    ],
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 云会议状态 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
          _c("add-select", { ref: "add", on: { getlist: _vm.handlerlist } }),
          _c("add-cloud", { ref: "cloud", on: { getlist: _vm.handlerlist } }),
          _c("add-cloudinfo", {
            ref: "cloudinfo",
            on: { getlist: _vm.handlerlist },
          }),
          _c("surveyinfo", { ref: "survey", on: { getlist: _vm.handlerlist } }),
          _c("invaildreason", {
            ref: "invaildreason",
            attrs: { rowid: _vm.rowid },
            on: { getlist: _vm.handlerlist },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showSelection = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showSelection = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }