var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tablelist, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "名称", width: "" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "操作", width: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.showedit, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showedit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示内容" } },
                    [
                      _c("wang-editor", {
                        ref: "wangcon",
                        attrs: { height: 300, "upload-img-size": 300 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务协议" } },
                    [
                      _c("wang-editor", {
                        ref: "wangxie",
                        attrs: { height: 300, "upload-img-size": 300 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showedit = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }