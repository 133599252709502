<template>
  <el-dialog
    :visible.sync="showadd"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="标题：" prop="title">
          <el-input placeholder="标题名称" v-model="form.title" clearable style="width: 220px;" maxlength="20"/>
        </el-form-item>
        <el-form-item label="海报图：" prop="poster_pic">
          <upload-img ref="poster_pic" :infoText="'(尺寸750*1025像素)'" :limit="1" @getImgs="getGoods_imgImg($event, 'poster_pic')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="分享图：" prop="share_pic">
          <upload-img ref="share_pic" :infoText="'(尺寸325*105像素)'" :limit="1" @getImgs="getGoods_imgImg($event, 'share_pic')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="浮动图标：" prop="float_pic">
          <upload-img ref="float_pic" :infoText="'(尺寸240*240像素)'" :limit="1" @getImgs="getGoods_imgImg($event, 'float_pic')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="背景颜色：" prop="bg_color">
          <el-input placeholder="背景颜色" v-model="form.bg_color" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="按钮颜色：" prop="button_color">
          <el-input placeholder="按钮颜色" v-model="form.button_color" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="主题文字颜色：" prop="title_color">
          <el-input placeholder="主题文字颜色" v-model="form.title_color" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="排序：" prop="sort_order">
          <el-input-number v-model="form.sort_order" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item>
          <el-switch inactive-value="2" active-value="1" v-model="form.status"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { getAction, postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "AddEdit",
  components:{
    UploadImg
  },
  data(){
    return{
      showadd:false,
      form:{
        id:'',
        title:'',
        poster_pic:'',
        share_pic:'',
        float_pic:'',
        bg_color:'',
        button_color:'',
        title_color:'',
        sort_order:'',
        status:'1',
      }
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.closeimg("poster_pic")
        this.closeimg("share_pic")
        this.closeimg("float_pic")
        this.form={
          id:'',
          title:'',
          poster_pic:'',
          share_pic:'',
          float_pic:'',
          bg_color:'',
          button_color:'',
          title_color:'',
          sort_order:'',
          status:'1',
        }
      }
    }
  },
  methods:{
    handlerinfo(id){
      getAction("/api/system/shop-poster/info",{id:id}).then(res=>{
        this.form=res.data
        nextTick(()=>{
          this.setImg(this.form.poster_pic,"poster_pic")
          this.setImg(this.form.share_pic,"share_pic")
          this.setImg(this.form.float_pic,"float_pic")
        })

      })
    },
    handlersave(){
      getAction("/api/system/shop-poster/edit",this.form).then((res=>{
        if(res.request_code==200){
          this.$emit("getlist")
          this.showadd=false
        }
      }))
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
