var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "400px",
        top: "13vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-suffix": ":",
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "栏目设置", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "",
                        disabled: _vm.editType == 2 && !_vm.form.flag,
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "普通样式", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "选品样式", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "竖版直播样式", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: "1" } }),
                      _c("el-option", { attrs: { label: "禁用", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合集页归属", prop: "conf_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.form.conf_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "conf_id", $$v)
                        },
                        expression: "form.conf_id",
                      },
                    },
                    _vm._l(_vm.livelist, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.title, value: String(list.id) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-button center" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }