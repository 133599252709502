<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.nickname" clearable placeholder="姓名"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.phone" clearable placeholder="手机号"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.company_name" clearable placeholder="公司名称"/>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.trade_id"
          clearable
          placeholder="品类"
          style="width: 160px"
        >
          <el-option
            v-for="list in pllist"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="province_id">
        <el-select
          v-model="form.province_id"
          clearable
          placeholder="省"
          style="width: 160px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in provinceSelect"
            :key="list.id"
            :label="list.name"
            :value="Number(list.id)"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="city_id">
        <el-select
          v-model="form.city_id"
          clearable
          placeholder="市"
          style="width: 160px"
          @change="handlercidChange"
        >
          <el-option
            v-for="list in citylist"
            :key="list.id"
            :label="list.name"
            :value="Number(list.id)"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="area_id">
        <el-select
          v-model="form.area_id"
          clearable
          placeholder="区"
          style="width: 160px"
        >
          <el-option
            v-for="list in areaSelect"
            :key="list.id"
            :label="list.name"
            :value="Number(list.id)"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_staff" clearable placeholder="是否员工" style="width: 110px">
          <el-option label="是" value="1"/>
          <el-option label="否" value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_qw" clearable placeholder="是否被添加企微"  style="width: 160px">
          <el-option label="是" value="1"/>
          <el-option label="否" value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_invite" clearable placeholder="是否被员工邀请过"  style="width: 160px">
          <el-option label="是" value="1"/>
          <el-option label="否" value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_export" clearable placeholder="是否被导出过"  style="width: 160px">
          <el-option label="是" value="1"/>
          <el-option label="否" value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导出用户列表</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExportUserAction">导出全部用户的行为明细</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '是否员工'">
            {{row[item.prop]==1?'是':'否'}}
          </div>
          <div v-else-if="item.label == '是否被添加企微'">
            {{row[item.prop]?'是':'否'}}
          </div>
          <div v-else-if="item.label == '是否被邀请'">
            {{row[item.prop]==1?'是':'否'}}
          </div>
          <div v-else-if="item.label == '是否被导出'">
            {{row[item.prop]==1?'是':'否'}}
          </div>
          <div v-else-if="item.label == '操作'">
            <el-button type="text" @click="getActionInfo(row)">行为明细</el-button>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <detailed ref="detailed"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import dayjs from "dayjs";
import Detailed from "@/views/pages/datastatis/UAction/components/detailed.vue";
import loading from "@/views/other/loading/index.vue";
export default {
  name: 'Index',
  components: { Detailed },
  data() {
    return {
      form: {
        nickname:'',
        company_name:'',
        province_id:'',
        city_id:'',
        area_id:'',
        trade_id:'',
        phone:'',
        start_date: dayjs(new Date().getTime()-1000*3600*24).format('YYYY-MM-DD'),
        end_date:dayjs(new Date()).format('YYYY-MM-DD'),
        page: 1,
        limit: 10,
        /**
         * 是否被导出过1是0否
         */
        is_export: '',
        /**
         * 是否被邀请过1是0否
         */
        is_invite: '',
        /**
         * 是否被添加企微1是0否
         */
        is_qw: '',
        /**
         * 是否是员工1是0否
         */
        is_staff: '',
      },
      pllist:[],
      areaSelect:[],
      citylist:[],
      provinceSelect:[],
      time:[dayjs(new Date().getTime()-1000*3600*24).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '姓名',
          prop: 'nickname',
          width: '',
        },

        {
          label: '是否员工',
          prop: 'is_staff',
          width: '',
        },
        {
          label: '是否被邀请',
          prop: 'is_invite',
          width: '',
        },
        {
          label: '是否被导出',
          prop: 'is_export',
          width: '',
        },
        {
          label: '是否被添加企微',
          prop: 'qw_staff',
          width: '',
        },

        {
          label: '员工企微',
          prop: 'qw_staff',
          width: '',
        },
        {
          label: '手机号码',
          prop: 'phone',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },

        {
          label: '代理品牌',
          prop: 'brand',
          width: '',
        },

        {
          label: '品类',
          prop: 'trade',
          width: '',
        },
        {
          label: '渠道',
          prop: 'channel',
          width: '',
        },
        {
          label: '省',
          prop: 'province',
          width: '',
        },

        {
          label: '市',
          prop: 'city',
          width: '',
        },
        {
          label: '区',
          prop: 'area',
          width: '',
        },
        {
          label: '浏览次数',
          prop: 'view_num',
          width: '',
        },
        {
          label: '点击次数',
          prop: 'click_num',
          width: '',
        },
        {
          label: '搜索次数',
          prop: 'search_num',
          width: '',
        },
        {
          label: '留言（产品）次数',
          prop: 'goods_suggest_num',
          width: '',
        },
        {
          label: '留言（店铺）次数',
          prop: 'shop_suggest_num',
          width: '',
        },
        {
          label: '操作',
          prop: 'pv8',
          width: '',
        },
      ],
      total: 0,
      loading: false,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
   async handlerSelect(){
      let pinlei = await getAction('/user/index/cate')
      this.pllist = pinlei.data
      this.getProvince()
    },
    handlerlist() {
      this.loading = true
      getAction('/api/statistics/buried-point/users-behavior-lists', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.loading = false
        }
      )
    },
    /*
    * @desc 查看用户行为数据明细
    * */
    getActionInfo(row){
      this.$refs.detailed.form.user_key = row.user_key
      this.$refs.detailed.form.start_date = this.form.start_date
      this.$refs.detailed.form.end_date = this.form.end_date
      this.$refs.detailed.dialogFormVisible = true
      this.$refs.detailed.handlerlist()
    },
    /*
    * @desc 导出用户行为数据
    * */
    handlerExport() {
      postAction('/api/statistics/buried-point/users-behavior-lists-export', this.form).then(
        (res) => {
          // excel.export_json_to_excel({
          //   header: res.data.header,
          //   data: res.data.data,
          //   filename: res.data.name,
          // })
          if (res.request_code==200){
            this.$baseMessage(res.msg, 'success')
          }else{
            this.$baseMessage(res.msg, 'error')
          }
        }
      )
    },
    /*
    * @desc 导出全部用户行为数据
    * */
    handlerExportUserAction() {
      postAction('/api/statistics/buried-point/users-behavior-lists-log-export', this.form).then(
        (res) => {
          // excel.export_json_to_excel({
          //   header: res.data.header,
          //   data: res.data.data,
          //   filename: res.data.name,
          // })
          if (res.request_code==200){
            this.$baseMessage(res.msg, 'success')
          }else{
            this.$baseMessage(res.msg, 'error')
          }
        }
      )
    },
    // 获取省份
    async getProvince() {
      const provin = await getAction('/user/index/area', { pid: 0 })
      this.provinceSelect = provin.data
      console.log('省份')
    },
    // 获取市
    async getCityList(e) {
      const citydata = await getAction('/user/index/area', { pid: e })
      this.citylist = citydata.data
    },
    //获取区
    async getAreaList(e) {
      const areadata = await getAction('/user/index/area', { pid: e })
      this.areaSelect = areadata.data
    },
    handleProChange(e) {
      this.form.city_id = ''
      this.form.area_id = ''
      if(e!=''){
        this.getCityList(e)
      }
    },
    handlercidChange(e){
      this.form.area_id = ''
      if(e!=''){
        this.getAreaList(e)
      }
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
