<template>
  <div class="index-container">
    <h2 style="text-align: center">调查问卷（2024春糖）统计</h2>
    <p v-if="answer_id.length > 0">
      {{ question_info.question }} {{ question_info.answer }}
      {{ question_info.second_answer }}
    </p>
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="姓名/公司名称/手机号"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in deptSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.htyun_name"
          clearable
          filterable
          placeholder="邀约人"
          style="width: 160px"
        >
          <el-option
            v-for="(list, index) in staffSelect"
            :key="index"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.role"
          clearable
          filterable
          placeholder="身份"
          style="width: 160px"
        >
          <el-option
            v-for="list in roleSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.province_id"
          clearable
          filterable
          placeholder="省"
          style="width: 150px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in provinceSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.city_id"
          clearable
          filterable
          placeholder="市"
          style="width: 150px"
        >
          <el-option
            v-for="list in citySelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.prize_id"
          clearable
          filterable
          placeholder="奖品"
          style="width: 260px"
        >
          <el-option
            v-for="list in prizeSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click="handlerExport">
          导 出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      />
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        form: {
          start_time: '',
          end_time: '',
          city_id: '',
          province_id: '',
          name: '',
          htyun_name: '',
          htyun_dept: '',
          role: '',
          prize_id: '',
        },
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        columns: [
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'htyun_name',
            width: '',
          },
          {
            label: '手机号',
            prop: 'mobile',
            width: '',
          },
          {
            label: '姓名',
            prop: 'name',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },
          {
            label: '职务',
            prop: 'job',
            width: '',
          },
          {
            label: '所在地省',
            prop: 'province_name',
            width: '',
          },
          {
            label: '所在地市',
            prop: 'city_name',
            width: '',
          },
          {
            label: '经营品类',
            prop: 'category',
            width: '',
          },
          {
            label: '代理品牌',
            prop: 'brand',
            width: '',
          },
          {
            label: '渠道',
            prop: 'channel',
            width: '',
          },
          {
            label: '主营品牌',
            prop: 'more_brand',
            width: '',
          },
          {
            label: '主营业务',
            prop: 'business',
            width: '',
          },
          {
            label: '时间',
            prop: 'created',
            width: '',
          },
          {
            label: '抽奖',
            prop: 'prize',
            width: '',
          },
          {
            label: '身份',
            prop: 'role_name',
            width: '',
          },
          {
            label: '寻找什么品类产品',
            prop: 'category_answer',
            width: '',
          },
        ],
        deptSelect: [],
        staffSelect: [],
        roleSelect: [
          {
            id: 403,
            name: '经销商',
          },
          {
            id: 404,
            name: '生产厂家',
          },
          {
            id: 405,
            name: '其他',
          },
        ],
        prizeSelect: [],
        provinceSelect: [],
        citySelect: [],
        answer_id: this.$route.query.answer_id || '',
        question_info: {
          question: '您的身份是？',
          answer: '是',
          second_answer: '',
        },
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
      this.getProvince()
      this.getPrizelist()
    },
    methods: {
      getPrizelist() {
        getAction('/api/form/research-spring/prize', this.form).then((res) => {
          this.prizeSelect = res.data
        })
      },
      handleProChange(e) {
        this.form.city_id = ''
        this.getCityList(e)
      },
      // 获取省份
      async getProvince() {
        // const { data } = await getAction('/user/index/area', { pid: 0 })
        // this.areaselect = data

        const provin = await getAction('/user/index/area', { pid: 0 })
        this.provinceSelect = provin.data
        console.log('省份')
      },
      // 获取市
      async getCityList(e) {
        const citydata = await getAction('/user/index/area', { pid: e })
        this.citySelect = citydata.data
      },
      async handlerSelect() {
        const dept = await getAction('/form/form/dept')
        this.deptSelect = dept.data
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
        this.staffSelect.unshift({ htyun_name: '无邀请人' })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        let param = { ...this.form, answer_id: this.answer_id }
        getAction('/api/form/research-spring/form-info', param).then((res) => {
          this.tabledata = res.data.list
          this.question_info = res.data.question_info
          this.total = Number(res.totalCount)
        })
      },
      handlerExport() {
        let param = { ...this.form, answer_id: this.answer_id }
        this.loading = true
        postAction('/api/form/research-spring/form-info-export', param)
          .then((res) => {
            console.log('请求，', res.data.header, res.data.list[0])
            if (res.request_code == 200) {
              excel.export_json_to_excel({
                header: res.data.header,
                data: res.data.list,
                filename: res.data.name,
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
