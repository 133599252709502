var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "编辑", visible: _vm.showedit, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称", prop: "mall_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司名称", clearable: "" },
                    model: {
                      value: _vm.form.mall_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mall_name", $$v)
                      },
                      expression: "form.mall_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "company_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司名称", clearable: "" },
                    model: {
                      value: _vm.form.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_name", $$v)
                      },
                      expression: "form.company_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邀请人", prop: "inviter_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入邀请人", clearable: "" },
                    model: {
                      value: _vm.form.inviter_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "inviter_name", $$v)
                      },
                      expression: "form.inviter_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "已审核", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "待审核", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "已拒绝", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.status == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拒绝原因", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "拒绝原因", clearable: "" },
                        model: {
                          value: _vm.form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reason", $$v)
                          },
                          expression: "form.reason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }