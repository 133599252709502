var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showinfo, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showinfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户", prop: "user_id" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlerphone,
                      placeholder: "请输入手机号",
                      "value-key": "phone",
                    },
                    on: { select: _vm.handleSelectphone },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "enterprise_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "公司名称" },
                    model: {
                      value: _vm.form.enterprise_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "enterprise_name", $$v)
                      },
                      expression: "form.enterprise_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "business_url" } },
                [
                  _c("upload-img", {
                    ref: "business_url",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "business_url")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "logo", prop: "logo" } },
                [
                  _c("upload-img", {
                    ref: "logo",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "logo")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经营品类", prop: "hy" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      "popper-class": "ssaassd",
                      placeholder: "经营品类",
                      options: _vm.cateSelect,
                      clearable: "",
                      "show-all-levels": false,
                      props: {
                        multiple: true,
                        emitPath: false,
                        value: "id",
                        label: "name",
                      },
                    },
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.hy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hy", $$v)
                      },
                      expression: "form.hy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请人姓名", prop: "apply_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "申请人姓名" },
                    model: {
                      value: _vm.form.apply_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apply_name", $$v)
                      },
                      expression: "form.apply_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请人职务", prop: "apply_job" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "申请人职务" },
                    model: {
                      value: _vm.form.apply_job,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apply_job", $$v)
                      },
                      expression: "form.apply_job",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(
                      [
                        { label: "待审核", value: "0" },
                        { label: "已审核", value: "1" },
                        { label: "已拒绝", value: "2" },
                      ],
                      function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showinfo = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }