<template>
  <div class="index-container">
    <el-table border :data="tabledata" style="width: 100%" v-loading="loading">
      <el-table-column
        align="center"
        label="企微昵称"
        prop="corp_staff_name"
        width="220"
      ></el-table-column>
      <el-table-column
        align="center"
        label="云交会账号"
        prop="user_name"
        width="450"
      >
        <template #default="{ row }">
          <div>
            <el-autocomplete
              v-model="row.user_name"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              @select="handleSelect($event, row)"
              style="width: 380px"
            ></el-autocomplete>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    data() {
      return {
        loading: false,
        form: {
          company_id: '',
          mall_id: '',
          company_name: '',
          mall_name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司',
            prop: 'company_name',
            width: '160',
          },
          {
            label: '排序',
            prop: 'sort',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
        ],
        total: 0,
        typeSelect: [],
        toSelect: [],
        user_name: '',
        user_id: '',
      }
    },
    async mounted() {
      await this.handlerInquire()
    },
    methods: {
      handleSelect(item, row) {
        console.log('用户id,', item, row)
        postAction('/user/corp/save-mapping', {
          user_id: item.id,
          corp_staff_id: row.corp_staff_id,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      },

      querySearchAsync(queryString, cb) {
        this.getproduct(queryString, cb)
      },
      getproduct(queryString, cb) {
        let that = this
        if (queryString == '') {
          var val = ''
        } else {
          var val = queryString
        }
        postAction('/user/corp/lists', { user_name: val }).then((res) => {
          if (res.code == 0) {
            that.restaurants = res.data.map((item) => {
              return {
                value: item.user_depart + '  ' + item.user_name,
                id: item.user_id,
              }
            })
            console.log('整合之后，', that.restaurants)
            if (cb != undefined) {
              var restaurants = that.restaurants
              var results = queryString
                ? restaurants.filter(that.createStateFilter(queryString))
                : restaurants
              console.log('筛选之后，', results)
              clearTimeout(that.timeout)
              that.timeout = setTimeout(() => {
                cb(results)
              }, 3000 * Math.random())
            }
          }
        })
      },
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          )
        }
      },
      async handlerSelect() {
        await getAction('/audit/identity/identity').then((res) => {
          this.identitySelect = res.data
        })
        await getAction('/audit/advert/position').then((res) => {
          this.typeSelect = res.data
        })
        let typeSelect = await getAction('/audit/advert/jump-type')
        console.log(typeSelect.data)
        this.toSelect = typeSelect.data
      },
      handlerInquire() {
        this.form.page = 1

        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.addedit.showedit = true
      },
      handlerlist() {
        this.loading = true
        getAction('/user/corp/index', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.loading = false
        })
      },
      handlerdelete(row) {
        postAction('/mall/plate-product/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleredit(row) {
        console.log(row)
        this.$refs.addedit.showedit = true
        this.$refs.addedit.handlerinfo(row)
        this.$refs.addedit.title = '编辑'
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
