<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="400px"
    top="13vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form
        :model="form"
        ref="form"
        label-position="right"
        label-suffix=":"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="栏目设置" prop="name">
          <el-input style="width: 220px" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="跳转类型" prop="type">
          <!-- 1普通样式2选品样式3竖版直播样式  -->
          <el-select v-model="form.type" placeholder="" :disabled="editType == 2 && !form.flag">
            <el-option label="普通样式" value="1"></el-option>
            <el-option label="选品样式" value="2"></el-option>
            <el-option label="竖版直播样式" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="">
            <!-- 0禁用，1启用 -->
            <el-option label="启用" :value="'1'"></el-option>
            <el-option label="禁用" :value="'0'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合集页归属" prop="conf_id">
          <el-select v-model="form.conf_id" placeholder="">
            <!-- 0禁用，1启用 -->
            <el-option v-for="list in livelist" :label="list.title" :value="String(list.id)" :key="list.id"/>

          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number
            style="width: 220px"
            v-model="form.sort"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-button center">
      <el-button @click="showDialog = false">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "AddEdit",
  components: {},
  data() {
    return {
      editType: 1, //1新增2编辑
      showDialog: false,
      form: {},
      loading: false,
      livelist:[],
      rules: {
        name: [{ required: true, message: "请输入栏目名称" }],
        type: [{ required: true, message: "请选择跳转类型" }],
        status: [{ required: true, message: "请选择状态" }],
        sort: [{ required: true, message: "请输入排序" }],
      },
    }
  },
  created() {},
  mounted() {
    this.handlerlivelist()
  },
  computed: {
    title() {
      if (this.editType == 1) {
        return "新增"
      } else {
        return "编辑"
      }
    },
  },
  watch: {
    showDialog(v) {
      if (!v) {
        this.$refs.form.resetFields()
        this.form={}
      }
    },
  },
  methods: {
    handlerlivelist(){
      getAction("/live/index/config",this.form).then(res=>{
        this.livelist=res.data
      })
    },
    handleSubmit() {
      this.$refs.form.validate((v) => {
        if (v) {
          postAction("/live/index/edit-type", { ...this.form }).then((res) => {
            this.showDialog = false
            this.$emit("refresh")
            this.$message.success(res.msg)
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
