import { render, staticRenderFns } from "./Addcolumn.vue?vue&type=template&id=1df60dfe&scoped=true"
import script from "./Addcolumn.vue?vue&type=script&lang=js"
export * from "./Addcolumn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df60dfe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\yunjiaohui\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1df60dfe')) {
      api.createRecord('1df60dfe', component.options)
    } else {
      api.reload('1df60dfe', component.options)
    }
    module.hot.accept("./Addcolumn.vue?vue&type=template&id=1df60dfe&scoped=true", function () {
      api.rerender('1df60dfe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/systemSet/livetelecast/SelectionLive/components/Addcolumn.vue"
export default component.exports