<template>
  <el-dialog title="栏目设置" :visible.sync="showvideo"  width="100%" top="1vh">
    <el-form :inline="true" :model="form">

<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="handlerInquire">查询</el-button>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- </el-row> -->
    <el-table
      :data="tabledata"
      border
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">

          <div v-if="item.prop == 'title_pic'">
            <el-image
              v-if="row[item.prop]"
              style="width: 150px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
            </el-image>
          </div>
          <div v-else>
            <p>{{ row[item.prop] }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="420px" fixed="right" >
        <template #default="{ row }">
        <el-button type="text" @click="handleredit(row)">编辑</el-button>
        <el-popconfirm
          title="确定删除吗？"
          @confirm="handlerdelet(row)"
        >
          <el-button style="margin:0px 10px" slot="reference" type="text">删除</el-button>
        </el-popconfirm>
        <el-button type="text" @click="handlerstore(row,1)">选择产品</el-button>
        <el-button type="text" @click="handlerstore(row,2)">选择店铺</el-button>
        <el-button type="text" @click="handlerpolicy(row,1)">选择主题活动</el-button>
<!--        <el-button type="text" @click="handlerpolicy(row,2)">选择政策大放送</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-row class="row-bg" justify="end">
      <el-pagination
        :current-page="form.page"
        :page-size="form.limit"
        background
        small
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-row>
    <Addcolumn ref="add" @getlist="handlerInquire" :title="title"/>
    <ProductStore ref="store"/>
    <policy ref="policy" @getlist="handlerInquire" :title="title"/>
  </el-dialog>
</template>

<script>
import Addcolumn from "./Addcolumn"
import ProductStore from "./ProductStore"
import Policy from "./policy"
import { getAction, postAction } from "@/api/request";

export default {
  name: "columnlist",
  components:{
    Policy,
    Addcolumn,
    ProductStore
  },
  data(){
    return{
      showvideo:false,
      title:'新增',
      form:{
        area_id:null,
        page:1,
        limit:10,
      },
      tabledata:[],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "60px",
        },
        {
          label: "栏目标题模版",
          prop: "title_type_txt",
          width: "",
        },
        {
          label: "栏目主标题",
          prop: "title",
          width: "220px",
        },
        {
          label: "栏目副标题",
          prop: "subtitle",
          width: "",
        },
        {
          label: "栏目标题图片",
          prop: "title_pic",
          width: "180px",
        },{
          label: "栏目内容排列方式",
          prop: "display_type_txt",
          width: "",
        },
        {
          label: "关联类型",
          prop: "content_type_txt",
          width: "160px",
        },
        {
          label: "排序",
          prop: "sort_order",
          width: "",
        },{
          label: "状态",
          prop: "status_txt",
          width: "",
        }
      ],
      total:0
    }
  },
  methods:{
    handlerlist(){
     getAction("/live/index/arealive-column",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
        this.loading=false
      })
    },
    handlerstore(row,type){
      console.log(row,type)
      this.$refs.store.form.type=type
      this.$refs.store.form.column_id=row.id
      this.$refs.store.form.area_id=this.form.area_id
      this.$refs.store.handlerInquire()
      this.$refs.store.showstore=true
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerpolicy(row,type){
      this.$refs.policy.form.column_id=row.id


      if(type==1){
        this.title="栏目设置——选择主题活动"
        this.$refs.policy.handlerinittheme()
      }else{
        this.title="栏目设置——选择政策大放送"
        this.$refs.policy.handlerinitpolicy()
      }
      this.$refs.policy.showAddstore=true
    },
    handlerAdd(){
      this.$refs.add.form.area_id=this.form.area_id
      this.title="新增"
      this.$refs.add.showadd=true
    },
    handleredit(row){
      this.title="编辑"
      this.$refs.add.handlerinfo(row)
      this.$refs.add.form.area_id=this.form.area_id
      this.$refs.add.showadd=true
    },
    handlerdelet(row){
      postAction("/live/index/del-arealivecolumn",{id:row.id}).then(res=>{
        this.$message.success(res.msg)
        this.handlerlist()
      })

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
}
</script>

<style scoped>

</style>
