<template>
  <div>
    <el-dialog :title="title" :visible.sync="showEditPoster" width="50%">
      <div>
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="排序" prop="sort_order">
            <el-input
              v-model="form.sort_order"
              placeholder="排序"
              style="width: 160px"
              type="number"
            />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              clearable
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in statusSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditPoster = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    components: {},
    data() {
      return {
        title: '新增',
        showEditPoster: false,
        form: {
          id: null,
          name: '',
          sort_order: '',
          status: '1',
        },
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          sort_order: [
            { required: true, message: '请输入排序顺序', trigger: 'blur' },
          ],
          status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        },
        statusSelect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '2',
            name: '禁用',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          this.resetForm()
          this.title = '新增'
        }
      },
    },
    mounted() {},
    methods: {
      resetForm() {
        this.$refs.form.resetFields()
        this.form = {
          id: null,
          name: '',
          sort_order: '',
          status: '1',
        }
      },
      handlerinfo(row) {
        this.title = '编辑'
        this.form = Object.assign(this.form, row)
        this.form.status = String(row.status)
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/poster/type/edit', this.form).then((res) => {
              if (res.request_code == 200) {
                this.showEditPoster = false
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.$emit('getlist')
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
