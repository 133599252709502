<template>
  <el-dialog
    :title="title"
    :visible.sync="addshow"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
          <el-form-item label="标题">
            <el-input  placeholder="标题" v-model="form.title"/>
          </el-form-item>
        <el-form-item label="背景色">
          <el-input  v-model="form.bg_color"  placeholder="背景色" />
        </el-form-item>
        <el-form-item label="查看更多按钮颜色">
          <el-input  v-model="form.more_button"  placeholder="查看更多按钮颜色" />
        </el-form-item>
        <el-form-item label="小程序分享图">
            <upload-img ref="share_img" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'share_img')"/>
        </el-form-item>
        <el-form-item label="H5分享图">
          <upload-img ref="h5_share_img" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'h5_share_img')"/>
        </el-form-item>
        <el-form-item label="分享标题">
          <el-input  v-model="form.share_title"  placeholder="分享标题" />
        </el-form-item>
        <el-form-item label="分享描述">
          <el-input  v-model="form.share_desc"  placeholder="分享描述" />
        </el-form-item>
        <el-form-item label="底部logo">
          <upload-img ref="foot_logo" info-text="" :limit="1" @getImgs="getGoods_imgImg($event, 'foot_logo')"/>
        </el-form-item>
        <el-form-item label="签到开始时间">
          <el-date-picker
            v-model="form.integral_stime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="签到结束时间">
          <el-date-picker
            v-model="form.integral_etime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadImg from "@/components/uploadImg";
import { postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "addsecond",
  components: { UploadImg },
  data(){
    return{
      title:'',
      addshow:false,
      form:{
        id:0,
        title:'',
        bg_color:'',
        integral_stime:'',
        integral_etime:'',
        share_title:'',
        share_desc:'',
        more_button:'',
      },
      rules:{}
    }
  },
  methods:{
    show(){
      this.addshow=true
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
    close(){
      console.log("走了吗")
      this.form={
        id:0,
        title:'',
        bg_color:'',
        integral_stime:'',
        integral_etime:'',
        share_title:'',
        share_desc:'',
        more_button:'',
      }
      nextTick(()=> {
        this.closeimg( 'share_img')
        this.closeimg('h5_share_img')
        this.closeimg('foot_logo')
      })
      this.addshow=false
    },
    handlerinfo(row){
      console.log(row)
      nextTick(()=>{
        this.setImg(row.share_img,'share_img')
        this.setImg(row.h5_share_img,'h5_share_img')
        this.setImg(row.foot_logo,'foot_logo')
      })
      this.form=Object.assign(this.form,row)
    },
    handlersave(){
      postAction("/api/theme/act/edit",this.form).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.$emit("getlist")
        this.close()
      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
  }
};
</script>

<style scoped>

</style>
