var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "conf_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "合集页归属" },
                  model: {
                    value: _vm.form.conf_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "conf_id", $$v)
                    },
                    expression: "form.conf_id",
                  },
                },
                _vm._l(_vm.livelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: String(list.id) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("新建")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "10px" },
          attrs: { border: "", stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "120px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.tableEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.tableDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.tableLink(row)
                          },
                        },
                      },
                      [_vm._v("关联直播间")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("AddEdit", { ref: "addEdit", on: { refresh: _vm.fetchData } }),
      _c("LinkLive", { ref: "LinkLive", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }