<script>
import { getAction } from "@/api/request";

export default {
  name: "ReportInfo",
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
    dialogVisible(v) {
      if(!v){
        this.form={
          page: 1,
          limit: 10,
          /**
           * 结束时间
           */
          end_time: '',
          /**
           * 员工部门
           */
          htyun_dept: '',
          /**
           * 员工姓名
           */
          htyun_name: '',
          /**
           * 报告id
           */
          id: '',
          /**
           * 是否员工1是2否
           */
          is_staff: '',
          /**
           * 昵称
           */
          nickname: '',
          /**
           * 排序字段 pv,uv
           */
          order: '',
          /**
           * 电话
           */
          phone: '',
          /**
           * desc 降序 ，asc 升序
           */
          sort: '',
          /**
           * 开始时间
           */
          start_time: '',
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      time:[],
      form: {
        page: 1,
        limit: 10,
        /**
         * 结束时间
         */
        end_time: '',
        /**
         * 员工部门
         */
        htyun_dept: '',
        /**
         * 员工姓名
         */
        htyun_name: '',
        /**
         * 报告id
         */
        id: '',
        /**
         * 是否员工1是2否
         */
        is_staff: '',
        /**
         * 昵称
         */
        nickname: '',
        /**
         * 排序字段 pv,uv
         */
        order: '',
        /**
         * 电话
         */
        phone: '',
        /**
         * desc 降序 ，asc 升序
         */
        sort: '',
        /**
         * 开始时间
         */
        start_time: '',
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '一级部门',
          prop: 'htyun_kind',
          width: '',
        },
        {
          label: '二级部门',
          prop: 'htyun_dept',
          width: '',
        },
        {
          label: '员工姓名',
          prop: 'htyun_name',
          width: '',
        },
        {
          label: '手机号',
          prop: 'phone',
          width: '',
        },
        {
          label: '昵称',
          prop: 'nickname',
          width: '',
        },

        {
          label: '是否员工',
          prop: 'is_staff',
          width: '',
        },
        {
          label: '分享好友数',
          prop: 'friend_num',
          width: '',
          sort: 'custom',
        },
        {
          label: '分享朋友圈数',
          prop: 'circle_num',
          width: '',
          sort: 'custom',
        },
        {
          label: '下载海报数',
          prop: 'save_num',
          width: '',
          sort: 'custom',
        },
        {
          label: '邀请来PV',
          prop: 'pv',
          width: '',
          sort: 'custom',
        },
        {
          label: '邀请来UV',
          prop: 'uv',
          width: '',
          sort: 'custom',
        },
      ],
      total: 0,
      deptSelect: [],
      deptTowSelect: [],
      nameSelect:[]
    }
  },
  mounted() {
    this.handlerSelect()
  },
  methods:{
    async handlerSelect() {
      const dept = await getAction("/api/form/form/dept-one");
      this.deptSelect = dept.data;
      const staff = await getAction("/form/form/staff");
      this.nameSelect = staff.data;
    },
    async setNameSelect(e) {
      this.form.htyun_name = "";
      this.form.htyun_dept = [];
      const staTow = await getAction("/form/form/dept", { htyun_kind: e });
      this.deptTowSelect= staTow.data
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    //排序
    setSort({ column, prop, order }) {
      console.log('column, prop, order', column, prop, order)
      this.form.order = prop
      // 值 asc 小到大  和  desc  大到小]
      if (order === 'descending') {
        this.form.sort = 'desc'
      } else if (order === 'ascending') {
        this.form.sort = 'asc'
      } else {
        this.form.sort = ''
        this.form.order = ''
      }
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/system/set-plan-report/invite-tj', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      getAction('/api/system/set-plan-report/invite-tj-export', { ...this.form }).then(
        (res) => {
          if (res.request_code == 200){
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<template>
  <el-dialog
    title="报告统计详情"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="80%">
    <span>
      <el-form ref="form" :model="form" inline>
        <el-form-item prop="htyun_kind">
        <el-select v-model="form.htyun_kind" clearable filterable style="width: 160px;" placeholder="一级事业部"
                   @change="setNameSelect">
          <el-option v-for="(list,index) in deptSelect"
                     :key="index"
                     :value="list.htyun_kind"
                     :label="list.htyun_kind" />
        </el-select>
      </el-form-item>
      <el-form-item prop="htyun_dept">
        <el-select v-model="form.htyun_dept" clearable filterable style="width: 220px;" placeholder="二级部门">
          <el-option v-for="(list,index) in deptTowSelect"
                     :key="index"
                     :value="list.htyun_dept"
                     :label="list.htyun_dept" />
        </el-select>
      </el-form-item>
<!--        <el-form-item>
          <el-input v-model="form.htyun_name" placeholder="请输入员工姓名" />
        </el-form-item>-->
      <el-form-item>
        <el-select v-model="form.htyun_name" clearable filterable style="width: 160px;" placeholder="员工姓名">
          <el-option v-for="(list,index) in nameSelect"
                     :key="index"
                     :value="list.htyun_name"
                     :label="list.htyun_name" />
        </el-select>
      </el-form-item>
        <el-form-item>
          <el-input v-model="form.phone" placeholder="手机号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.nickname" placeholder="昵称" />
        </el-form-item>

        <el-form-item>
          <el-select v-model="form.is_staff" placeholder="是否员工">
            <el-option label="是" value="1" />
            <el-option label="否" value="2" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="time"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            style="width: 340px"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerExport">导出</el-button>
        </el-form-item>
      </el-form>
       <el-table border :data="tabledata" style="width: 100%" @sort-change="setSort">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :sortable="item.sort"

      >
          <template slot="header" slot-scope="scope">
          <div slot="header" v-if="item.label=='邀请来PV'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">用户访问报告的次数，同一用户访问N次记录N次，即PV数<br/>转发他人链接，他人邀请来的PV数加1,<br/>N个邀请人邀请同一用户，邀请来的PV数各加1</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else-if="item.label=='邀请来UV'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">访问报告的用户人数，同一用户访问N次只记录1人，即UV数<br/>转发他人链接，他人邀请来的UV数加1,<br/>N个邀请人邀请同一用户，邀请来的UV数各加1</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else>
            {{item.label}}
          </div>
        </template>
      </el-table-column>
       </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </span>
  </el-dialog>
</template>

<style scoped>

</style>
