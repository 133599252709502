<template>
  <div class="index-container">
    <el-row>
      <el-col :span="24">
        <el-table border :data="tablelist" style="width: 100%">
          <el-table-column label="标语" prop="name" width="" />
          <el-table-column label="说明" prop="value" width="600px">
            <template #default="{ row }">
              <div v-html="row.value"></div>
            </template>
          </el-table-column>
          <el-table-column label="二级页标题" prop="sub_title" width="" />
          <el-table-column
            label="二级页标签标题"
            prop="sub_tab_title"
            width=""
          />
          <el-table-column label="操作" prop="" width="">
            <template #default="{ row }">
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="编辑" :visible.sync="showedit" width="40%">
      <div>
        <el-form ref="form" label-width="120px" :model="form">
          <el-form-item label="标语">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="说明">
            <wang-editor
              ref="editorvalue"
              :height="300"
              :upload-img-size="300"
            />
          </el-form-item>
          <el-form-item label="二级页标题">
            <el-input v-model="form.sub_title" />
          </el-form-item>
          <el-form-item label="二级页标签标题">
            <el-input v-model="form.sub_tab_title" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import WangEditor from '@/components/WangEditor'

  export default {
    name: 'Index',
    components: { WangEditor },
    data() {
      return {
        tablelist: [],
        showedit: false,
        form: {},
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handleredit(row) {
        this.showedit = true
        this.form = JSON.parse(JSON.stringify(row))
        setTimeout(() => {
          this.$refs.editorvalue.editor.setHtml(row.value)
        }, 100)
      },
      handlerlist() {
        getAction('/api/system/hot/hot-conf').then((res) => {
          this.tablelist = [res.data]
        })
      },
      handlersave() {
        this.form.value = this.$refs.editorvalue.getHtmlM()
        postAction('/api/system/hot/set-hot-conf', this.form)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showedit = false
            this.handlerlist()
          })
          .catch((err) => {
            console.error(err)
          })
      },
    },
  }
</script>

<style scoped></style>
