var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择店铺" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "160px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  "value-key": "mall_name",
                  placeholder: "请输入店铺名称",
                },
                on: { select: _vm.handleSelectmall },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "所属公司" } }, [
            _vm._v(" " + _vm._s(_vm.company_name) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "排序" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.mealselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { value: list.id, label: list.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }