var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { content: "", visible: _vm.showEditPoster, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-width": "110px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入", maxlength: "32" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "share_title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入", maxlength: "32" },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享图", prop: "friend_pic" } },
                [
                  _c("upload-img", {
                    ref: "friend_pic",
                    attrs: {
                      "info-text": "建议尺寸 500 * 400，jpg或png格式图片",
                      limit: 1,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "friend_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "朋友圈分享图", prop: "circle_pic" } },
                [
                  _c("upload-img", {
                    ref: "circle_pic",
                    attrs: {
                      "info-text": "建议尺寸 400 * 400，png格式图片 ",
                      limit: 1,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "circle_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中转图片", prop: "bg_img" } },
                [
                  _c("upload-img", {
                    ref: "bg_img",
                    attrs: { "info-text": "建议尺寸 750 * 1080", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "bg_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "按钮图片", prop: "button_pic" } },
                [
                  _c("upload-img", {
                    ref: "button_pic",
                    attrs: { "info-text": "建议尺寸 30 *60", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "button_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "jump_url" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.jump_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_url", $$v)
                      },
                      expression: "form.jump_url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("启用"),
                      ]),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("禁用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditPoster = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }