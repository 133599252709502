var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showcorpper
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "图片裁剪",
            visible: _vm.showcorpper,
            width: "1200px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcorpper = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  attrs: { gutter: 10, type: "flex", justify: "space-between" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("vue-cropper", {
                        ref: "cropper",
                        staticClass: "cropper",
                        staticStyle: { width: "500px", height: "500px" },
                        attrs: {
                          img: _vm.option.img,
                          outputType: _vm.option.outputType,
                          fixed: _vm.option.fixed,
                          fixedNumber: _vm.option.fixedNumber,
                          full: _vm.option.full,
                          centerBox: _vm.option.centerBox,
                          infoTrue: _vm.option.infoTrue,
                          canMove: _vm.option.canMove,
                          canMoveBox: _vm.option.canMoveBox,
                          original: _vm.option.original,
                          autoCrop: _vm.option.autoCrop,
                          autoCropWidth: _vm.option.autoCropWidth,
                          autoCropHeight: _vm.option.autoCropHeight,
                          fixedBox: _vm.option.fixedBox,
                          enlarge: _vm.option.enlarge,
                          mode: _vm.option.mode,
                          canScale: _vm.option.canScale,
                        },
                        on: { realTime: _vm.handlerRealTime },
                      }),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { style: _vm.previewStyle }, [
                      _c("img", {
                        style: _vm.previews.img,
                        attrs: { src: _vm.previews.url },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlerclose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }