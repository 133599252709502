<template>
  <el-autocomplete
    v-model="keyword"
    :fetch-suggestions="handlerarticle"
    :value-key="autoparam.valueKey"
    :placeholder="autoparam.placeholder"
    @select="handleSelectarticle"
    clearable
  ></el-autocomplete>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  props:{
    autoparam:{
      type:Object,
      default:()=>({
        placeholder:'',
        valueKey:'',
        dataUrl:'',
        name:'',
        listkey:''
      })
    },
    keywords:{
      type:String,
      default:()=>{
        return ''
      }
    }
  },
  name: "autoInput",
  watch:{
    keywords(val){
      if(val){
        this.keyword=val
      }else{
        this.keyword=''
      }
    }
  },
  data(){
    return{
      keyword:''
    }
  },
  methods:{
    handlerarticle(queryString,cb){
      if(queryString==''){
        cb([])
        // this.form.article_id=''
      }else{

        getAction(this.autoparam.dataUrl,{[this.autoparam.name]:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectarticle(list){
      console.log(list)
      // this.form.article_id=list.id
      // this.form.title=list.title
      this.keyword=list[this.autoparam.listkey]
      this.$emit('selectArticle',list)
    },
  }
};
</script>

<style scoped>

</style>
