<template>
  <el-dialog
    :visible.sync="showadd"
    append-to-body
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="店铺">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.mall_name"
            :fetch-suggestions="handlermell"
            value-key="mall_name"
            clearable
            placeholder="请输入店铺名称"
            @select="handleSelectmall"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item  label="酒店名称1">
          <el-select
            v-model="form.hotels_id1"
            class="input"
            clearable
            placeholder="请选择酒店1"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="展位号1">
          <el-input
            v-model="form.hotels_no1"
            clearable
            placeholder="展位号1"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item  label="酒店名称2">
          <el-select
            v-model="form.hotels_id2"
            class="input"
            clearable
            placeholder="请选择酒店2"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="展位号2">
          <el-input
            v-model="form.hotels_no2"
            clearable
            placeholder="展位号2"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort_order" placeholder="排序"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
export default {
  name: "AddLive",
  props: {
    catSelect: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return{
      showadd:false,
      form:{
        mall_name:'',
        mall_id:'',
        conf_id:'',
        hotels_id1:'',
        hotels_no1:'',
        hotels_id2:'',
        hotels_no2:'',
        sort_order:'',
        id:'',
      }
    }
  },
 watch:{
    showadd(v){
      if(!v){
        this.form={
          mall_name:'',
          mall_id:'',
          conf_id:'',
          hotels_id1:'',
          hotels_no1:'',
          hotels_id2:'',
          hotels_no2:'',
          sort_order:'',
          id:'',
        }
      }
    }
  },
  methods: {
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
    },
    handlersave(){
      postAction("/api/hotel/mall/edit",this.form).then(res=>{
          this.$message.success(res.msg)
          this.showadd=false
          this.$emit('handlerlist')

      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
    },
  }
};
</script>

<style scoped>

</style>
