<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="活动主题"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '分享图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row.status == 1 ? '正常' : '禁用' }}
          </div>
          <!--          <div v-else-if="item.label=='店铺logo'">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row.logo"
                        :preview-src-list="[row.logo]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>-->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="handlerdelete(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-edit ref="add" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import AddEdit from '@/views/pages/enterprise/festival/components/AddEdit'

  export default {
    name: 'Index',
    components: { AddEdit },
    data() {
      return {
        form: {
          title: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },

          {
            label: '分享图',
            prop: 'share_pic',
            width: '',
          },

          {
            label: '活动主题',
            prop: 'title',
            width: '',
          },
          {
            label: '添加时间',
            prop: 'created',
            width: '',
          },

          {
            label: '排序',
            prop: 'sort_order',
            width: '80',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerdelete(row) {
        postAction('/api/system/shop-poster/delete', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            }
          }
        )
      },
      handlerAdd() {
        this.$refs.add.showadd = true
      },
      handleredit(row) {
        this.$refs.add.handlerinfo(row.id)
        this.$refs.add.showadd = true
      },

      handlerlist() {
        getAction('/api/system/shop-poster/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
