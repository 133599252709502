<template>
  <el-dialog
    content
    :visible.sync="showEditPoster"
    width="800px">
    <div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" clearable @blur="checkMobile" maxlength="11" />
        </el-form-item>
        <el-form-item label="选择身份" prop="dealer_type">
          <el-select v-model="form.dealer_type" placeholder="选择身份">
            <el-option
              v-for="item in shenoptions"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="company">
          <el-input v-model="form.company" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" >
          <upload-img  ref="business_url" :infoText="'格式jpg、png，大小5MB以内，请上传与您相关的图片'" :limit="1" @getImgs="getGoods_imgImg($event, 'business_url')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="企业员工身份证明材料" >
          <upload-img  ref="employee_materials_url" :infoText="'格式jpg、png，大小5MB以内，名片、工作证、门头、企业微信截图、钉钉截图等'" :limit="1" @getImgs="getGoods_imgImg($event, 'employee_materials_url')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="经营品类" prop="trade_ids">
          <el-cascader
            v-model="form.trade_ids"
            popper-class="ssaassd"
            placeholder="经营品类"
            :options="cateSelect"
            clearable
            :show-all-levels="false"
            :props="{ multiple:true,emitPath:false, value: 'id',label:'name', }"
            @change="change"></el-cascader>
        </el-form-item>
        <el-form-item label="地区" prop="province_id">
          <el-form-item prop="province_id">
            <el-select
              v-model="form.province_id"
              clearable
              placeholder="省"
              style="width: 160px"
              @change="handleProChange"
            >
              <el-option
                v-for="list in provinceSelect"
                :key="list.id"
                :label="list.name"
                :value="Number(list.id)"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="city_id">
            <el-select
              v-model="form.city_id"
              clearable
              placeholder="市"
              style="width: 160px"
              @change="handlercidChange"
            >
              <el-option
                v-for="list in citylist"
                :key="list.id"
                :label="list.name"
                :value="Number(list.id)"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="area_id">
            <el-select
              v-model="form.area_id"
              clearable
              placeholder="区"
              style="width: 160px"
            >
              <el-option
                v-for="list in areaSelect"
                :key="list.id"
                :label="list.name"
                :value="Number(list.id)"
              />
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="渠道" prop="channel_ids">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group v-model="form.channel_ids" @change="handleCheckChange">
            <el-checkbox
              v-for="(city, idx) in citySelect"
              :key="idx"
              :label="city.id"
            >
              {{ city.name }}
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>
        <el-form-item label="代理品牌" prop="proxy">
          <el-input v-model="form.proxy" placeholder="代理品牌"></el-input>
        </el-form-item>
        <el-form-item label="申请人姓名" prop="name">
          <el-input v-model="form.name" placeholder="申请人姓名"></el-input>
        </el-form-item>
        <el-form-item label="申请人职务" prop="job">
          <el-input v-model="form.job" placeholder="申请人职务"></el-input>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input v-model="form.source" placeholder="来源"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import UploadImg from "@/components/uploadImg.vue";

export default {
  name: "addedit",
  components: { UploadImg },
  data(){
    return{
      showEditPoster:false,
      cateSelect:[],
      provinceSelect:[],
      citylist:[],
      areaSelect:[],
      citySelect:[],
      checkAll: false,
      isIndeterminate: false,
      shenoptions:[
        {
          name:'经销商',
          id:'1'
        },
        {
          name:'采购商',
          id:'2'
        },
        {
          name:'零售店',
          id:'3'
        }
      ],
      form:{
        id:'',
        dealer_type:'',
        province_id:'',
        city_id:'',
        area_id:'',
        channel_ids:[],
        company:'',
        business_url:'',
        employee_materials_url:'',
        trade_ids:[],
        name:'',
        job:'',
        source:'',
        status:'',
        mobile:'',
        proxy:'',
      },
      rules:{
        mobile:[
          {required:true,message:'请输入手机号',trigger:'blur'}
        ],
        dealer_type:[
          {required:true,message:'请选择经销商类型',trigger:'blur'}
        ],
        company:[
          {required:true,message:'请输入公司名称',trigger:'blur'}
        ],
       /* business_url:[
          {required:true,message:'请上传营业执照',trigger:'blur'}
        ],
        employee_materials_url:[
          {required:true,message:'请上传企业员工身份证明材料',trigger:'blur'}
        ],*/
        trade_ids:[
          {required:true,message:'请选择经营品类',trigger:'blur'}
        ],
        province_id:[
          {required:true,message:'请选择省份',trigger:'blur'}
        ],
        city_id:[
          {required:true,message:'请选择市',trigger:'blur'}
        ],
        area_id:[
          {required:true,message:'请选择区',trigger:'blur'}
        ],
        channel_ids:[
          {required:true,message:'请选择渠道',trigger:'blur'}
        ],
        name:[
          {required:true,message:'请输入申请人姓名',trigger:'blur'}
        ],
        job:[
          {required:true,message:'请输入申请人职务',trigger:'blur'}
        ],
        source:[
          {required:true,message:'请输入来源',trigger:'blur'}
        ]
      }
    }
  },
  watch:{
    showEditPoster(val){
      if(!val){
        this.change([])
        this.form={
          id:'',
            dealer_type:'',
            province_id:'',
            city_id:'',
            area_id:'',
            channel_ids:[],
            company:'',
            business_url:'',
            employee_materials_url:'',
            trade_ids:[],
            name:'',
            job:'',
            source:'',
            status:'',
            mobile:'',
        },
        this.$refs.form.resetFields()
        this.closeimg('business_url')
        this.closeimg('employee_materials_url')
      }
    },
  },
  async mounted() {
   await this.getProvince()
    await this.handlerselect()
    await getAction("/form/form/staff").then(res=>{
      this.staffOption=res.data
    })
  },
  methods:{
    checkMobile(e){

      postAction("/audit/import-cert/check-is-dealer",{mobile:e.target.value}).then(res=>{
        if(res.data.is!=1){
          this.$message({
            type:'error',
            message:res.data.msg
          })
        }
    })
    },
    handlersave(){
      this.$refs.form.validate((valid) => {
        let data=JSON.parse(JSON.stringify(this.form))
        if(data.trade_ids){
          data.trade_ids=data.trade_ids.join(',')
        }
        if(data.channel_ids){
          data.channel_ids=data.channel_ids.join(',')
        }
        if (valid) {
          getAction("/audit/import-cert/edit",data).then(res=>{
            if(res.request_code!=200){
              this.$message({
                type:'error',
                message:res.msg
              })

            }else{
              this.showEditPoster=false
              this.$emit("getlist")
              this.$message({
                type:'success',
                message:res.msg
              })
            }
          })
        }
      })

    },
    handlerinfo(row){
      console.log(row)
      this.getProvince()
      this.getCityList(row.province_id)
      this.getAreaList(row.city_id)
      this.form = Object.assign(this.form,row)
      if(this.form.channel_ids){
        this.form.channel_ids = row.channel_ids.split(",")
      }else {
        this.form.channel_ids=[]
      }

      this.form.trade_ids=row.trade_ids.split(",")
      this.change(this.form.trade_ids)
      this.$nextTick(() => {
        this.setImg(row.business_url,'business_url')
        this.setImg(row.employee_materials_url,'employee_materials_url')
      })
    },


    // 处理禁用状态
    change(value) {
      console.log(value)
      // 处理禁用状态
      this.$nextTick(() => {
        var options = []
        var disabled = value.length >= 3 // 大于等于3时禁用
        this.cateSelect.forEach(option => {
          option.children.forEach(child => {
            // 如果是选中的则不需禁用
            if (value.find(array => array === child.id)) {
              child.disabled = false
            } else {
              child.disabled = disabled
            }
          })
          options.push(option)
        })
        // 重新进行赋值
        this.cateSelect = options
      })
    },


    async handlerselect(){
      const channel =await getAction("/user/index/channel")
      console.log(channel.data,"渠道")
      this.citySelect=channel.data
      const cate =await getAction("/user/index/cate-tree")
      this.cateSelect=cate.data
    },
    handleCheckAllChange(val) {
      console.log(val)
      const city = this.citySelect.map((c) => c.id)
      this.form.channel_ids = val ? city : []
      this.isIndeterminate = false
    },
    handleCheckChange(val) {
      const checkLength = val.length
      this.checkAll = checkLength === this.citySelect.length
      this.isIndeterminate =
        checkLength > 0 && checkLength < this.citySelect.length
    },
    handleProChange(e) {
      this.form.city_id = ''
      this.form.area_id = ''
      if(e!=''){
        this.getCityList(e)
      }

    },
    handlercidChange(e){
      this.form.area_id = ''
      if(e!=''){
        this.getAreaList(e)
      }

    },
    // 获取省份
    async getProvince() {
      const provin = await getAction('/user/index/area', { pid: 0 })
      this.provinceSelect = provin.data
      console.log('省份')
    },
    // 获取市
    async getCityList(e) {
      const citydata = await getAction('/user/index/area', { pid: e })
      this.citylist = citydata.data
    },
    //获取区
    async getAreaList(e) {
      const areadata = await getAction('/user/index/area', { pid: e })
      this.areaSelect = areadata.data
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    setImgArray(v, prop) {
      if (v[0]!='') this.$refs[prop].img = v
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>
<style scoped >
.ssaassd{
  .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
    display: none !important;
  }
}
</style>
