<template>
  <div>
    <el-dialog
    :title="editTitle"
    :visible.sync="is_show"
    width="800px"
    :before-close="handleClose">
      <div class="edit">
        <p class="title">{{title}}</p>
        <el-form class="form" ref="form" :model="form" label-width="80px">
           
          <el-form-item label="一级品类">
            <el-input style="width:60%" v-model="form.reply " placeholder="请输入一级品类"></el-input>
          </el-form-item>
          <el-form-item label="二级品类">
            <p class="answer" v-for="(item,index) in form.child" key="index">
              <el-input style="width:60%" v-model="item.reply" placeholder="请输入二级品类"></el-input>
              <e class="icon el-icon-circle-plus-outline" @click="addanswer()" v-if="index==0"></e>
              <e class="icon el-icon-remove-outline" @click="plusanswer(index)" v-if="index!=0"></e>
            </p>
          </el-form-item>
        </el-form>
        <p style="margin-top:60px;text-align:center">
          <el-button size="medium" type="primary" @click="handleClose">取消</el-button>
          <el-button size="medium" type="primary" @click="submit">确定</el-button>
        </p>
      </div>
    </el-dialog>
    
  </div>
  
  
</template>
<script>
import { getAction, postAction } from "@/api/request";
export default {
  name: 'editcategory',
  components: { 
    // UploadImg,
  },
  data() {
    return {
      is_show:false,
      editTitle:'',
      title:'',
      form:{
        conf_id:'',
        id:'',
        reply:'',
        child:[
            {
              id:'',
              reply:''
            }
          ]
      },
    }
  },
  watch:{
    
  },
  mounted() {
    
  },
  methods: {
    handleClose(){
      this.is_show=false;
      this.$refs['form'].resetFields();
    },
    addanswer(){
      this.form.child.push({
        id:'',
        reply:''
      })
    },
    plusanswer(index){
      this.form.child.splice(index,1)
    },
    getinfo() {
      // 获取详情
      let that = this;
      postAction("/api/system/research/category-one-info",{id:that.form.id}).then(res=>{
          that.is_show = true;
          if(res.code==0){
            var d = that.form;
            that.form = res.data;
            that.form.conf_id = d.conf_id;
            if(res.data.child.length==0){
              that.form.child = [
                {
                  id:'',
                  reply:''
                }
              ]
            }
          }else{
            that.$message.error(res.msg);
          }
      })
    },
    submit(){
      let that = this;
      if(that.form.reply==''){
        that.$message.error('请输入一级品类');
        return
      }
      
      // console.log(that.form)
      var form = JSON.stringify(that.form);
      form = JSON.parse(form)
      form.child = JSON.stringify(form.child)
      console.log(form)
      // return 
      postAction("/api/system/research/category-one-edit",form).then(res=>{
        if(res.code==0){
          that.$message({
            message: '操作成功',
            type: 'success'
          });
          this.$emit('getinfo')
          that.is_show = false
        }else{
          that.$message.error(res.msg);
          
        }
      }).catch(res =>{
        
      })
    },
    
    
  }
}
</script>

<style lang="scss" scoped>
  :deep() .el-dialog__body{
    padding-top:0;
  }
  .edit .title{
    text-align:center;
    font-size:20px;
  }
  .form{
    width:60%;
    margin:40px auto;
    padding:20px 0;
    border-radius:10px;
    background: rgb(241, 241, 241);
    border:1px solid rgb(187, 187, 187);
  }
  .form .icon{
    font-size:20px;
    margin-left:10px;
  }
  .answer{
    display:flex;
    align-items:center;
    margin:0;
    margin-bottom:10px;
  }
  .tips{
    color:red;
    font-size:12px;
    margin-left:80px;
  }
</style>