<template>
  <div class="orderTest-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item prop="conf_id">
        <el-select v-model="form.conf_id" placeholder="合集页归属">
          <!-- 0禁用，1启用 -->
          <el-option v-for="list in livelist" :label="list.title" :value="String(list.id)" :key="list.id"/>

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleAdd">新建</el-button>
      </el-form-item>
    </el-form>

    <el-table border stripe :data="list" v-loading="loading" style="margin-top: 10px">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
      <el-table-column prop="" align="center" label="操作" fixed="right" min-width="120px">
        <template #default="{ row }">
          <el-button type="text" @click.native.prevent="tableEdit(row)">编辑</el-button>
          <el-button type="text" @click.native.prevent="tableDelete(row)">删除</el-button>
          <el-button type="text" @click.native.prevent="tableLink(row)">关联直播间</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center"
    ></el-pagination>
    <AddEdit ref="addEdit" @refresh="fetchData"></AddEdit>
    <LinkLive ref="LinkLive" @refresh="fetchData"></LinkLive>
  </div>
</template>
<script>
import AddEdit from "./components/addEdit.vue"
import LinkLive from "./components/LinkLive.vue"
import { getAction, postAction } from "@/api/request";
export default {
  name: "index",
  components: { AddEdit ,LinkLive},
  data() {
    return {
      loading: false,
      list: [],
      form: {
        page: 1,
        limit: 10,
        conf_id:''
      },
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      columns: [
        {
          order: 1,
          label: "ID",
          prop: "id",
          width: "",
        },
        {
          order: 2,
          label: "栏目名称",
          prop: "name",
          width: "",
        },
        {
          order: 3,
          label: "跳转类型",
          prop: "type_txt",
          width: "",
        },
        {
          order: 4,
          label: "排序",
          prop: "sort",
          width: "",
        },
        {
          order: 5,
          label: "状态",
          prop: "status_txt",
          width: "",
        },
      ],
      livelist:[]
    }
  },
  created() {},
  mounted() {
    this.fetchData()
    this.handlerlivelist()
  },
  watch: {},
  methods: {
    handlerlivelist(){
      getAction("/live/index/config",this.form).then(res=>{
        this.livelist=res.data
      })
    },
    async fetchData() {
      this.loading = true
      try {
        let res = await getAction("/live/index/type", { ...this.form })
        this.list = res.data
        this.total = Number(res.totalCount) || 0
        this.loading = false
      } catch (err) {
        console.log("接口调用错误", err)
        this.loading = false
      }
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.fetchData()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.fetchData()
    },
    handleAdd() {
      this.$refs.addEdit.editType = 1
      this.$refs.addEdit.showDialog = true
    },
    tableEdit(row) {
      this.$refs.addEdit.editType = 2
      this.$refs.addEdit.form=JSON.parse(JSON.stringify(row))
      this.$refs.addEdit.showDialog = true
    },
    tableDelete(row) {
      this.$confirm("确定删除此项吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await postAction("/live/index/del-type", { id: row.id })
          this.$message.success(res.msg)
          this.fetchData()
        })
        .catch(() => {
          console.log("no")
        })
    },
    tableLink(row) {
      this.$refs.LinkLive.type = row.id
      this.$refs.LinkLive.showDialog = true
      this.$refs.LinkLive.params=row
    },
  },
}
</script>
<style lang="scss" scoped></style>
