var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "精选布展企业",
        visible: _vm.zoneshow,
        "append-to-body": "",
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.zoneshow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "80px", inline: "" },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerAdd },
                    },
                    [_vm._v("新 增")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlermell,
                      "value-key": "mall_name",
                      clearable: "",
                      placeholder: "请输入店铺名称",
                    },
                    on: { select: _vm.handleSelectmall },
                    model: {
                      value: _vm.form.live_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "live_name", $$v)
                      },
                      expression: "form.live_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input",
                      attrs: { clearable: "", placeholder: "请选择酒店1" },
                      model: {
                        value: _vm.form.hotels_id1,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hotels_id1", $$v)
                        },
                        expression: "form.hotels_id1",
                      },
                    },
                    _vm._l(_vm.catSelect, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.name, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { clearable: "", placeholder: "展位号1" },
                    model: {
                      value: _vm.form.hotels_no1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hotels_no1", $$v)
                      },
                      expression: "form.hotels_no1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input",
                      attrs: { clearable: "", placeholder: "请选择酒店2" },
                      model: {
                        value: _vm.form.hotels_id2,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hotels_id2", $$v)
                        },
                        expression: "form.hotels_id2",
                      },
                    },
                    _vm._l(_vm.catSelect, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.name, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { clearable: "", placeholder: "展位号2" },
                    model: {
                      value: _vm.form.hotels_no2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hotels_no2", $$v)
                      },
                      expression: "form.hotels_no2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查 询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "ElTable",
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            [
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    align: "center",
                    label: item.label,
                    prop: item.prop,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            item.label == "类型"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row[item.prop] == 1 ? "春糖" : "秋糖"
                                      ) +
                                      " "
                                  ),
                                ])
                              : item.label == "状态"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row[item.prop] == 1 ? "开启" : "关闭"
                                      ) +
                                      " "
                                  ),
                                ])
                              : item.label == "报名跳转图片"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          "preview-src-list": [row[item.prop]],
                                          src: row[item.prop],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "列表分享图"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          "preview-src-list": [row[item.prop]],
                                          src: row[item.prop],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: { align: "center", fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small", type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleredit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small", type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handlerdelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
          _c("add-live", {
            ref: "addzone",
            attrs: { catSelect: _vm.catSelect },
            on: { handlerlist: _vm.handlerInquire },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.zoneshow = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.zoneshow = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }