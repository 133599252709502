var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-descriptions",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.deptloading,
                  expression: "deptloading",
                },
              ],
              attrs: { title: "当前总量看板", colon: false },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "经销商数量" } }, [
                _vm._v(_vm._s(_vm.deptSelect.dealer_num)),
              ]),
              _c("el-descriptions-item", { attrs: { label: " " } }),
              _c("el-descriptions-item", { attrs: { label: " " } }),
              _c(
                "el-descriptions-item",
                { attrs: { label: "厂家数量（总数）" } },
                [_vm._v(_vm._s(_vm.deptSelect.mall_num))]
              ),
              _c("el-descriptions-item", { attrs: { label: "收费厂家" } }, [
                _vm._v(_vm._s(_vm.deptSelect.mall_no_free_num)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "免费厂家" } }, [
                _vm._v(_vm._s(_vm.deptSelect.mall_free_num)),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "产品数量（总数）" } },
                [_vm._v(_vm._s(_vm.deptSelect.product_num))]
              ),
              _c("el-descriptions-item", { attrs: { label: "收费产品" } }, [
                _vm._v(_vm._s(_vm.deptSelect.product_no_free_num)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "免费产品" } }, [
                _vm._v(_vm._s(_vm.deptSelect.product_free_num)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h4", [_vm._v("活跃用户看板")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getActive },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerExport(1)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.staffloading,
                  expression: "staffloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.staffSelect },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "日期", align: "center" },
              }),
              _c(
                "el-table-column",
                { attrs: { prop: "name", label: "访问数据", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "uv", label: "登录UV数", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "staff", label: "员工", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "有手机号&用户",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "新用户", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "cert",
                      label: "有身份信息&用户",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "new", label: "新用户", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "authorized",
                      label: "注册授权手机号",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "私域情况", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "complete",
                      label: "新用户拥有完善的身份信息",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "wechat_num",
                      label: "在私域用户",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "wechat_no_cert",
                      label: "私域无身份资料用户数",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.Activetotal,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e, 1)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e, 1)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h4", [_vm._v("一级品类看板")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerlist },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerExport(2)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableloading,
                  expression: "tableloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tabledata },
              on: {
                "sort-change": ({ column, prop, order }) => {
                  _vm.setSort({ column, prop, order }, 1)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", align: "center", label: "日期" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cat_name", align: "center", label: "品类名称" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "users_num",
                  align: "center",
                  sortable: "custom",
                  label: "在线用户数量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_num",
                  align: "center",
                  sortable: "custom",
                  label: "产品数量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scan_num",
                  align: "center",
                  sortable: "custom",
                  label: "浏览产品用户数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contact_num",
                  align: "center",
                  sortable: "custom",
                  label: "实际互动数",
                },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e, 2)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e, 2)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h4", [_vm._v("二级品类看板")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getTowCategory },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerExport(3)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.categoryloading,
                  expression: "categoryloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.category },
              on: {
                "sort-change": ({ column, prop, order }) => {
                  _vm.setSort({ column, prop, order }, 2)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "日期", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属一级品类",
                  prop: "cat_pname",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "cat_name", label: "品类名称", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "users_num",
                  align: "center",
                  sortable: "custom",
                  label: "在线用户数量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_num",
                  align: "center",
                  sortable: "custom",
                  label: "产品数量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scan_num",
                  align: "center",
                  sortable: "custom",
                  label: "浏览产品用户数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contact_num",
                  align: "center",
                  sortable: "custom",
                  label: "实际互动数",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "action", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-view" },
                            on: {
                              click: function ($event) {
                                return _vm.getviewsTop(scope.row)
                              },
                            },
                          },
                          [_vm._v("产品浏览TOP5")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.categorytotal,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e, 3)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e, 3)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "max-width": "780px" } },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h4", [_vm._v("互动看板")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getInteraction },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerExport(4)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.Interactionloading,
                  expression: "Interactionloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.Interaction },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "日期", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone_num",
                  label: "电话咨询数量",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_num",
                  label: "样品申请数量",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "suggest_num", label: "留言数量", width: "180" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.Interactiontotal,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e, 4)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e, 4)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "max-width": "780px" } },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h4", [_vm._v("实际互动看板")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getActualInteraction },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerExport(5)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.ActualInteractionloading,
                  expression: "ActualInteractionloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.ActualInteraction },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "日期", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone_num",
                  label: "电话咨询数量",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_num",
                  label: "样品申请数量",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "suggest_num", label: "留言数量", width: "180" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.ActualInteractiontotal,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e, 5)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e, 5)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExporttow },
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.toploading,
                      expression: "toploading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.topdata },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "cat_name",
                      label: "二级品类名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_name",
                      label: "产品名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contact_num",
                      label: "实际互动数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pv", label: "PV", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "uv", label: "UV", align: "center" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }