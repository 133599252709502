<template>
  <el-dialog title="匹配列表" :visible.sync="show" width="1200px">
    <div>
      <el-form ref="form" inline label-suffix=":" :model="form">
        <el-form-item>
          <el-input
            v-model="form.name"
            clearable
            placeholder="姓名"
            style="width: 110px"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.company"
            clearable
            placeholder="公司名称"
            style="width: 110px"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.province_id"
            clearable
            placeholder="省份"
            style="width: 120px"
            @change="handlerprovince"
          >
            <el-option
              v-for="list in provinceSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.city_id"
            clearable
            placeholder="城市"
            style="width: 120px"
            @change="handlercity"
          >
            <el-option
              v-for="list in citySelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="form.area_id"
            clearable
            placeholder="地区"
            style="width: 120px"
          >
            <el-option
              v-for="list in districtSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.status"
            clearable
            placeholder="状态"
            style="width: 100px"
          >
            <el-option
              v-for="list in statusSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.channel_id"
            clearable
            placeholder="渠道"
            style="width: 120px"
          >
            <el-option
              v-for="list in channelSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.cate_id"
            clearable
            placeholder="品类"
            style="width: 120px"
          >
            <el-option
              v-for="list in cateSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.deduct_type"
            clearable
            placeholder="扣除类型"
            style="width: 120px"
          >
            <el-option
              v-for="list in typeSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="匹配开始日期"
            end-placeholder="匹配结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time2"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="查看开始日期"
            end-placeholder="查看结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
      </el-form>

      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :sortable="item.sort"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '展示图'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label == '相册'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label == '留言内容'">
              <span v-html="row[item.prop]"></span>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
        <!--        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="220">
          <template  #default="{row}"  >
            <div v-if="row.type==2">
              <el-button
                slot="reference"
                type="text"
                size="small"
                v-if="row.status!=1"
                @click="handlertong(row,1)"
              >
                查看
              </el-button>
              <el-button
                @click.native.prevent="handlerexamine(row)"
                type="text"
                size="small"
                v-if="row.is_reply==1"
              >
                回复
              </el-button>

              <el-button
                slot="reference"
                type="text"
                size="small"
                v-if="row.status!=1&&row.status!=2"
                @click="handlertong(row,2)">
                放弃
              </el-button>
            </div>

          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="show = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'Matelist',
    data() {
      return {
        show: false,
        form: {
          page: 1,
          limit: 10,
          mall_id: '',
          name: '',
          province_id: '',
          city_id: '',
          area_id: '',
          status: '',
          company: '',
          channel_id: '',
          cate_id: '',
          deduct_type:''
        },
        typeSelect:[
          {
            id: 1,
            name: '扣除套餐次数',
          },
          {
            id: 2,
            name: '扣除赠送次数',
          },
          {
            id: 3,
            name: '旧留言不扣除次数',
          },
          {
            id: 4,
            name: '云会议不扣除次数',
          },
          {
            id: 5,
            name: '邀请不扣除次数',
          },
        ],
        statusSelect: [
          {
            id: 1,
            name: '已查看',
          },
          {
            id: 2,
            name: '已放弃',
          },
          {
            id: 0,
            name: '未处理',
          },
          {
            id: -1,
            name: '全部',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        time:[],
        time2:[],
        columns: [
          {
            label: '姓名',
            prop: 'name',
            width: '',
            sort: false,
          },
          {
            label: '职务',
            prop: 'job',
            width: '',
            sort: false,
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
            sort: false,
          },
          {
            label: '电话',
            prop: 'phone',
            width: '',
            sort: false,
          },
          {
            label: '区域',
            prop: 'region',
            width: '',
            sort: false,
          },
          {
            label: '主营渠道',
            prop: 'channel',
            width: '',
            sort: false,
          },
          {
            label: '关注品类',
            prop: 'category',
            width: '',
            sort: false,
          },
          {
            label: '代理品牌',
            prop: 'brand',
            width: '',
            sort: false,
          },
          {
            label: '类型',
            prop: 'type_txt',
            width: '',
            sort: false,
          },
          {
            label: '匹配日期',
            prop: 'created',
            width: '',
            sort: false,
          },
          {
            label: '查看日期',
            prop: 'scan_time',
            width: '',
            sort: false,
          },
          {
            label: '扣除类型',
            prop: 'deduct_type_txt',
            width: '',
            sort: false,
          },
          {
            label: '留言内容',
            prop: 'content',
            width: '160',
            sort: false,
          },
          {
            label: '状态',
            prop: 'status_txt',
            width: '',
            sort: false,
          },
        ],
        total: 0,
        provinceSelect: [],
        citySelect: [],
        districtSelect: [],
        channelSelect: [],
        cateSelect: [],
      }
    },
    watch:{
      time(v){
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ""
          this.form.end_time = ""
        }
      },
      time2(v){
        if (v) {
          this.form.scan_start_time = v[0]
          this.form.scan_end_time = v[1]
        } else {
          this.form.scan_start_time = ""
          this.form.scan_end_time = ""
        }
      }
    },
    mounted() {
      getAction('/user/index/area', { pid: 0 }).then((res) => {
        this.provinceSelect = res.data
      })
      getAction('/user/index/channel').then((res) => {
        this.channelSelect = res.data
      })
      getAction('/user/index/cate', { pid: 0 }).then((res) => {
        this.cateSelect = res.data
      })
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerexamine(row) {
        this.$prompt('回复', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          postAction('/dealer/index/match-reply', {
            id: row.message_id,
            reply_content: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        })
      },
      handlertong(row, status) {
        let msg = ''
        status == 1
          ? (msg = '确认查看, 是否继续?')
          : (msg = '确认放弃, 是否继续?')
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            postAction('/dealer/index/match-oper', {
              id: row.id,
              status: status,
            }).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            })
          })
          .catch(() => {})
      },
      handlerprovince(e) {
        getAction('/user/index/area', { pid: e }).then((res) => {
          this.citySelect = res.data
          this.form.city_id = ''
          this.form.area_id = ''
          this.districtSelect = []
        })
      },
      handlercity(e) {
        getAction('/user/index/area', { pid: e }).then((res) => {
          this.districtSelect = res.data
          this.form.area_id = ''
        })
      },
      handlerlist() {
        getAction('/mall/setmeal/match-dealer', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
