var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "数据统计",
        visible: _vm.showDialog,
        width: "1250px",
        top: "9vh",
        "close-on-click-modal": false,
        modal: true,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "140px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接收人数", prop: "total" } },
                [_vm._v(_vm._s(_vm.form.receive_nums))]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "阅读人数", prop: "read" } },
                [_vm._v(_vm._s(_vm.form.receive_read_nums))]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有无跳转链接", prop: "is_jump" } },
                [_vm._v(_vm._s(_vm.form.is_jump))]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接点击人数", prop: "click" } },
                [_vm._v(_vm._s(_vm.form.click_nums))]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接点击率", prop: "click_rate" } },
                [
                  _vm._v(_vm._s(_vm.form.click_rate) + " "),
                  _c(
                    "span",
                    { staticStyle: { color: "#ccc", "margin-left": "30px" } },
                    [_vm._v("（计算公式：链接点击人数/阅读人数）")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }