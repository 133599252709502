<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-card>
      <el-descriptions title="当前总量看板" :colon="false" v-loading="deptloading">
        <el-descriptions-item label="经销商数量">{{deptSelect.dealer_num}}</el-descriptions-item>
        <el-descriptions-item label=" "></el-descriptions-item>
        <el-descriptions-item label=" "></el-descriptions-item>
        <el-descriptions-item label="厂家数量（总数）">{{deptSelect.mall_num}}</el-descriptions-item>
        <el-descriptions-item label="收费厂家">{{deptSelect.mall_no_free_num}}</el-descriptions-item>
        <el-descriptions-item label="免费厂家">{{deptSelect.mall_free_num}}</el-descriptions-item>
        <el-descriptions-item label="产品数量（总数）">{{deptSelect.product_num}}</el-descriptions-item>
        <el-descriptions-item label="收费产品">{{deptSelect.product_no_free_num}}</el-descriptions-item>
        <el-descriptions-item label="免费产品">{{ deptSelect.product_free_num }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card>
      <el-row type="flex" justify="space-between">
       <el-col :span="6"><h4>活跃用户看板</h4></el-col>
        <el-col :span="3">
          <el-button type="primary" @click="getActive">刷新</el-button>
          <el-button type="primary" @click="handlerExport(1)">导出</el-button>
        </el-col>
      </el-row>

      <el-table :data="staffSelect" style="width: 100%" v-loading="staffloading">
        <el-table-column prop="date" label="日期" align="center"></el-table-column>
        <el-table-column prop="name" label="访问数据" align="center">
          <el-table-column prop="uv" label="登录UV数" align="center"></el-table-column>
          <el-table-column prop="staff" label="员工" align="center"></el-table-column>
          <el-table-column prop="phone" label="有手机号&用户" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="新用户" align="center">
          <el-table-column prop="cert" label="有身份信息&用户" align="center"></el-table-column>
          <el-table-column prop="new" label="新用户" align="center"></el-table-column>
          <el-table-column prop="authorized" label="注册授权手机号" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="私域情况" align="center">
          <el-table-column prop="complete" label="新用户拥有完善的身份信息" align="center"></el-table-column>
          <el-table-column prop="wechat_num" label="在私域用户" align="center"></el-table-column>
          <el-table-column prop="wechat_no_cert" label="私域无身份资料用户数" align="center"></el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="Activetotal"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="(e)=>{handleCurrentChange(e,1)} "
        @size-change="(e)=>{handleSizeChange(e,1)}"
      ></el-pagination>
    </el-card>
    <el-card>
      <el-row type="flex" justify="space-between">
        <el-col :span="6"><h4>一级品类看板</h4></el-col>
        <el-col :span="3">
          <el-button type="primary" @click="handlerlist">刷新</el-button>
          <el-button type="primary" @click="handlerExport(2)">导出</el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="tableloading"
        :data="tabledata"
        @sort-change="({ column,prop, order})=>{setSort({column,prop, order},1)}"
        style="width: 100%">
        <el-table-column
          prop="date"
          align="center"
          label="日期">
        </el-table-column>
        <el-table-column
          prop="cat_name"
          align="center"
          label="品类名称">
        </el-table-column>
        <el-table-column
          prop="users_num"
          align="center"
          sortable="custom"
          label="在线用户数量">
        </el-table-column>
        <el-table-column
          prop="goods_num"
          align="center"
          sortable="custom"
          label="产品数量">
        </el-table-column>
        <el-table-column
          prop="scan_num"
          align="center"
          sortable="custom"
          label="浏览产品用户数">
        </el-table-column>
        <el-table-column
          prop="contact_num"
          align="center"
          sortable="custom"
          label="实际互动数">
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="(e)=>{handleCurrentChange(e,2)} "
        @size-change="(e)=>{handleSizeChange(e,2)}"
      ></el-pagination>
    </el-card>
    <el-card>

      <el-row type="flex" justify="space-between">
        <el-col :span="6"><h4>二级品类看板</h4></el-col>
        <el-col :span="3">
          <el-button type="primary" @click="getTowCategory">刷新</el-button>
          <el-button type="primary" @click="handlerExport(3)">导出</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="category"
        v-loading="categoryloading"
        @sort-change="({ column,prop, order})=>{setSort({column,prop, order},2)}"
        style="width: 100%">
        <el-table-column
          prop="date"
          label="日期"
          align="center">
        </el-table-column>
        <el-table-column label="所属一级品类"
        prop="cat_pname"
                         align="center"></el-table-column>
        <el-table-column
          prop="cat_name"
          label="品类名称"
          align="center">
        </el-table-column>
        <el-table-column
          prop="users_num"
          align="center"
          sortable="custom"
          label="在线用户数量">
        </el-table-column>
        <el-table-column
          prop="goods_num"
          align="center"
          sortable="custom"
          label="产品数量">
        </el-table-column>
        <el-table-column
          prop="scan_num"
          align="center"
          sortable="custom"
          label="浏览产品用户数">
        </el-table-column>
        <el-table-column
          prop="contact_num"
          align="center"
          sortable="custom"
          label="实际互动数">
        </el-table-column>
        <el-table-column
          prop="action"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-view" @click="getviewsTop(scope.row)">产品浏览TOP5</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="categorytotal"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="(e)=>{handleCurrentChange(e,3)} "
        @size-change="(e)=>{handleSizeChange(e,3)}"
      ></el-pagination>
    </el-card>
    <el-card style="max-width: 780px">

      <el-row type="flex" justify="space-between">
        <el-col :span="6"><h4>互动看板</h4></el-col>
        <el-col :span="6">
          <el-button type="primary" @click="getInteraction">刷新</el-button>
          <el-button type="primary" @click="handlerExport(4)">导出</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="Interaction"
        v-loading="Interactionloading"
        style="width: 100%">
        <el-table-column
          prop="date"
          label="日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="phone_num"
          label="电话咨询数量"
          width="180">
        </el-table-column>
        <el-table-column
          prop="order_num"
          label="样品申请数量"
          width="180">
        </el-table-column>
        <el-table-column
          prop="suggest_num"
          label="留言数量"
          width="180">
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="Interactiontotal"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="(e)=>{handleCurrentChange(e,4)} "
        @size-change="(e)=>{handleSizeChange(e,4)}"
      ></el-pagination>
    </el-card>
    <el-card style="max-width: 780px">
      <el-row type="flex" justify="space-between">
        <el-col :span="6"><h4>实际互动看板</h4></el-col>
        <el-col :span="6">
          <el-button type="primary" @click="getActualInteraction">刷新</el-button>
          <el-button type="primary" @click="handlerExport(5)">导出</el-button>
        </el-col>
      </el-row>

      <el-table
        :data="ActualInteraction"
        v-loading="ActualInteractionloading"
        style="width: 100%">
        <el-table-column
          prop="date"
          label="日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="phone_num"
          label="电话咨询数量"
          width="180">
        </el-table-column>
        <el-table-column
          prop="order_num"
          label="样品申请数量"
          width="180">
        </el-table-column>
        <el-table-column
          prop="suggest_num"
          label="留言数量"
          width="180">
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="ActualInteractiontotal"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="(e)=>{handleCurrentChange(e,5)} "
        @size-change="(e)=>{handleSizeChange(e,5)}"
      ></el-pagination>
    </el-card>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
        <el-button type="primary" @click="handlerExporttow">导出</el-button>
        <el-table
          :data="topdata"
          v-loading="toploading"
          style="width: 100%">
          <el-table-column
            prop="cat_name"
            label="二级品类名称"
          align="center">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="产品名称"
          align="center">
          </el-table-column>
          <el-table-column
            prop="contact_num"
            label="实际互动数"
          align="center">
          </el-table-column>
          <el-table-column
            prop="pv"
            label="PV"
          align="center">
          </el-table-column>
          <el-table-column
            prop="uv"
            label="UV"
          align="center">
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
import dayjs from "dayjs";
export default {
  name: "index",
  data(){
    return {
      form: {
        start_date: dayjs(new Date().getTime()-3600*24*1000).format('YYYY-MM-DD'),
        end_date:dayjs(new Date()).format('YYYY-MM-DD'),
        page: 1,
        limit: 10,
        sort:'',
        order:''
      },
      time:[dayjs(new Date().getTime()-3600*24*1000).format('YYYY-MM-DD'),dayjs(new Date()).format('YYYY-MM-DD')],
      layout: "total, prev, pager, next, jumper",
      tabledata: [],
      deptSelect: {
        /**
         * 经销商数量
         */
        dealer_num: '',
        /**
         * 免费厂家
         */
        mall_free_num: '',
        /**
         * 收费厂家
         */
        mall_no_free_num: '',
        /**
         * 厂家数量总数
         */
        mall_num: '',
        /**
         * 免费产品
         */
        product_free_num: '',
        /**
         * 收费产品
         */
        product_no_free_num: '',
        /**
         * 产品数量总数
         */
        product_num: '',
      },
      staffSelect: [],
      category:[],
      Interaction:[],
      ActualInteraction:[],
      total: 0,
      Activetotal:0,
      Interactiontotal:0,
      categorytotal:0,
      ActualInteractiontotal:0,
      title:'',
      dialogVisible:false,
      topdata:[],
      topform:{},
      deptloading:false,
      tableloading:false,
      staffloading:false,
      categoryloading:false,
      Interactionloading:false,
      ActualInteractionloading:false,
      toploading:false,
    }
  },
  watch:{
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ""
        this.form.end_date = ""
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()

  },
  methods:{
    setSort({ column, prop, order},type){
      console.log('column, prop, order', column, prop, order)
      this.form.sort = prop
      // 值 asc 小到大  和  desc  大到小]
      if (order === 'descending') {
        this.form.order = 'desc'
      } else if (order === 'ascending') {
        this.form.order = 'asc'
      } else {
        this.form.order_field = ''
        this.form.order = ''
      }
      if(type==1){
        this.handlerlist()
      }else{
        this.getTowCategory()
      }

    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
      this.getActive()
      this.getTowCategory()
      this.getInteraction()
      this.getActualInteraction()
    },
    handlerExporttow(){
      getAction('/api/form/day-data/category-two-top-export',this.topform).then(res=>{
        this.$message.success('导出任务已添加到后台导出队列，请稍后查看')
      })
    },
    getviewsTop(row){
      this.toploading=true
      this.topform = { date:row.date,cat_id:row.id }
      getAction("/api/form/day-data/category-two-top",{date:row.date,cat_id:row.id}).then(res=>{
        this.topdata=res.data
        this.toploading=false
        this.dialogVisible=true
        this.title=row.cat_name
      })
    },
    async handlerSelect(){
      this.deptloading=true
      const dept =await getAction("/api/form/day-data/main")
     this.deptSelect=dept.data
      this.deptloading=false

    },
    /*
    * @description: 获取活跃用户
    * */
    getActive(){
      this.staffloading=true
      getAction("/api/form/day-data/user",{...this.form}).then(res=>{
        this.staffSelect=res.data
        this.Activetotal= Number(res.totalCount)
        this.staffloading=false
      })
    },
    /*
    * @description: 一级品类列表
    * */
    handlerlist(){
      this.tableloading=true
      getAction("/api/form/day-data/category-one",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
        this.tableloading=false
      })
    },
    /*
    * @description: 二级品类列表
    * */
    getTowCategory(){
      this.categoryloading=true
      getAction("/api/form/day-data/category-two",this.form).then(res=>{
        this.category=res.data
        this.categorytotal= Number(res.totalCount)
        this.categoryloading=false
      })
    },
    /*
    * @description: 互动看板
    * */
    getInteraction(){
      this.Interactionloading=true
      getAction("/api/form/day-data/contact",this.form).then(res=>{
        this.Interaction=res.data
        this.Interactiontotal= Number(res.totalCount)
        this.Interactionloading=false
      })
    },
    getActualInteraction(){
      this.ActualInteractionloading=true
      getAction("/api/form/day-data/contact-true",this.form).then(res=>{
        this.ActualInteraction=res.data
        this.ActualInteractiontotal= Number(res.totalCount)
        this.ActualInteractionloading=false
      })
    },

    handlerExport(type) {
      var url = ''
      switch (type){
        case 1:
          url = '/api/form/day-data/user-export'
          break;
        case 2:
          url = '/api/form/day-data/category-one-export'
          break;
        case 3:
          url = '/api/form/day-data/category-two-export'
          break;
        case 4:
          url = '/api/form/day-data/contact-export'
          break;
        case 5:
          url = '/api/form/day-data/contact-true-export'
          break;
        default:
      }

      getAction(url,this.form).then(res=>{
        this.$message.success('导出任务已添加到后台导出队列，请稍后查看')
      })
    },
    handleCurrentChange(val,type) {
      this.form.page = val
      switch (type){
        case 1:
          this.getActive()
          break;
          case 2:
            this.handlerlist()
            break;
        case 3:
          this.getTowCategory()
          break;
        case 4:
          this.getInteraction()
          break;
        case 5:
          this.getActualInteraction()
          break;

      }
    },
    handleSizeChange(val,type) {
      this.form.limit = val
      switch (type) {
        case 1:
          this.getActive()
          break;
        case 2:
          this.handlerlist()
          break;
        case 3:
          this.getTowCategory()
          break;
        case 4:
          this.getInteraction()
          break;
        case 5:
          this.getActualInteraction()
          break;
      }
    },
  }
};
</script>

<style scoped>

</style>
