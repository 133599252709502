<template>
  <div class="wrap">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item label="原始密码：" label-width="100px" prop="old_pwd">
        <el-input
          v-model="ruleForm.old_pwd"
          placeholder="请输入原始密码"
          show-password
          style="width: 250px"
          @keyup.enter.native="updatedFn"
        />
      </el-form-item>
      <el-form-item label="新密码：" label-width="100px" prop="new_pwd">
        <el-input
          v-model="ruleForm.new_pwd"
          placeholder="请输入密码"
          show-password
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item label="确认密码：" label-width="100px" prop="confirm_pwd">
        <el-input
          v-model="ruleForm.confirm_pwd"
          placeholder="请再次输入密码"
          show-password
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          class="button"
          type="primary"
          @click="submitForm('ruleForm')"
        >
          保存修改
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import { changePwd } from '@/api/auth/userManagement'
  import { mapActions } from 'vuex'
  import { toLoginRoute } from '@/utils/routes'
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          if (this.ruleForm.confirm_pwd !== '') {
            this.$refs.ruleForm.validateField('confirm_pwd')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.new_pwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        ruleForm: {
          old_pwd: '',
          new_pwd: '',
          confirm_pwd: '',
        },

        rules: {
          old_pwd: [
            { required: true, message: '请输入原始密码', trigger: 'blur' },
          ],
          new_pwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
              min: 6,
              max: 16,
              message: '长度在 6 到 16 个字符',
              trigger: 'blur',
            },
            { validator: validatePass, trigger: 'blur' },
          ],
          confirm_pwd: [
            { required: true, message: '请确认密码', trigger: 'blur' },
            {
              min: 6,
              max: 16,
              message: '长度在 6 到 16 个字符',
              trigger: 'blur',
            },
            { validator: validatePass2, trigger: 'blur', required: true },
          ],
        },
      }
    },
    created() {},
    methods: {
      ...mapActions({
        resetAll: 'user/resetAll',
      }),
      submitForm() {
        changePwd(this.ruleForm).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
            this.resetAll()
            this.$router.push(toLoginRoute(this.$route.path))
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrap {
    padding: 20px;
  }
</style>
