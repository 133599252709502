var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "栏目设置",
        visible: _vm.showvideo,
        width: "100%",
        top: "1vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showvideo = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tabledata, border: "" } },
        [
          _vm._l(_vm.columns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.prop == "title_pic"
                          ? _c(
                              "div",
                              [
                                row[item.prop]
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        src: row[item.prop],
                                        "preview-src-list": [row[item.prop]],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("p", [_vm._v(_vm._s(row[item.prop]))]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "420px",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确定删除吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.handlerdelet(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0px 10px" },
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerstore(row, 1)
                          },
                        },
                      },
                      [_vm._v("选择产品")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerstore(row, 2)
                          },
                        },
                      },
                      [_vm._v("选择店铺")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerpolicy(row, 1)
                          },
                        },
                      },
                      [_vm._v("选择主题活动")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.page,
              "page-size": _vm.form.limit,
              background: "",
              small: "",
              "page-sizes": [10, 20, 30, 40],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("Addcolumn", {
        ref: "add",
        attrs: { title: _vm.title },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("ProductStore", { ref: "store" }),
      _c("policy", {
        ref: "policy",
        attrs: { title: _vm.title },
        on: { getlist: _vm.handlerInquire },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }