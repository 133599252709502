<template>
  <div class="htmember">
    <el-form ref="form" :model="form">
      <div class="formHeader">
        <el-form-item label="">
        <el-input v-model="form.name" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="payTime"
          value-format='yyyy-MM-dd'
          type="daterange"
          range-separator="至"
          start-placeholder="开通开始时间"
          end-placeholder="开通结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="expireTime"
          value-format='yyyy-MM-dd'
          type="daterange"
          range-separator="至"
          start-placeholder="到期开始时间"
          end-placeholder="到期结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button type="primary" @click="onEdit()">新增</el-button>
        <el-button type="primary" @click="onExport()">导出</el-button>
      </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="list"
      border
      ref="table"
      v-loading="loading"
      row-key="user_id"
    >
      <el-table-column prop="name" label="姓名" align='center' width="90"></el-table-column>
      <el-table-column prop="mobile" label="手机号码" align='center'></el-table-column>
      <el-table-column prop="company" label="公司名称" align='center'></el-table-column>
      <el-table-column prop="main_product" label="主营产品" align='center'></el-table-column>
      <el-table-column prop="" label="所在地区" align='center'>
        <template slot-scope="scope">
          {{scope.row.province}}{{scope.row.city}}{{scope.row.area}}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地址" align='center'></el-table-column>
      <el-table-column prop="" label="获取行业资讯" align='center'>
        <template slot-scope="scope">
          {{scope.row.get_industry_information==1?'酒类':'食品类'}}
        </template>
      </el-table-column>
      <el-table-column prop="message" label="留言"></el-table-column>
      <el-table-column prop="invite_user" label="邀请人" align='center'></el-table-column>
      <el-table-column prop="total_price" label="价格" align='center'></el-table-column>
      <el-table-column prop="pay_time" label="开通时间" align='center'></el-table-column>
      <el-table-column prop="expire_time" label="到期时间" align='center'></el-table-column>
      <el-table-column prop="status" label="来源" align='center' width="90">
        <template slot-scope="scope">
          {{scope.row.platform==5?'后台添加':'前台支付'}}
        </template>
      </el-table-column>
      <el-table-column prop="operating_user" label="添加人" align='center' width="90"></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center; padding: 20px 0"
    ></el-pagination>
    <el-dialog
      title="新增"
      :visible.sync="editShow"
      width="40%"
      :before-close="handleClose">
      <div class="editBox">
        <el-form ref="editform" :model="editform" :rules="rules" label-width="120px">
          <el-form-item label="选择用户" prop="mobile">
            <el-input placeholder="请输入手机号" v-model="editform.mobile" style="width: 260px;"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model="editform.name" style="width: 260px;"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="company">
            <el-input placeholder="请输入公司名称" v-model="editform.company" style="width: 260px;"></el-input>
          </el-form-item>
          <el-form-item label="主营产品" prop="main_product">
            <el-input placeholder="请输入主营产品" v-model="editform.main_product" style="width: 260px;"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="province">
            <el-select
              v-model="editform.province"
              clearable
              placeholder="省份"
              style="width: 120px"
              @change="handlerprovince"
            >
              <el-option
                v-for="list in provinceSelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="city">
            <el-select
              v-model="editform.city"
              clearable
              placeholder="城市"
              style="width: 120px"
              @change="handlercity"
            >
              <el-option
                v-for="list in citySelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>

          <el-form-item prop="area">
            <el-select
              v-model="editform.area"
              clearable
              placeholder="地区"
              style="width: 120px"
            >
              <el-option
                v-for="list in districtSelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input placeholder="详细地址" v-model="editform.address" style="width: 260px;"></el-input>
          </el-form-item>
          <el-form-item label="获赠行业资讯" prop="get_industry_information">
            <el-radio v-model="editform.get_industry_information" label="1">酒类</el-radio>
            <el-radio v-model="editform.get_industry_information" label="2">食品类</el-radio>
          </el-form-item>
          <el-form-item prop="club_vip_id" label="选择会员">
            <el-select
              v-model="editform.club_vip_id"
              clearable
              placeholder="选择会员"
              style="width: 200px"
            >
              <el-option
                v-for="list in clubvipSelect"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editSbmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { postAction } from "@/api/request";
  import * as excel from '@/utils/excel'
export default {
  name: "htmember",
  components: { },
  data() {
    return {
      rules: {
        mobile:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        main_product: [
          { required: true, message: '请输入主营产品', trigger: 'blur' }
        ],
        province:[
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city:[
          { required: true, message: '请选择市', trigger: 'blur' }
        ],
        area:[
          { required: true, message: '请选择区', trigger: 'blur' }
        ],
        address:[
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        get_industry_information:[
          { required: true, message: '请选择获赠行业资讯', trigger: 'blur' }
        ],
        club_vip_id:[
          { required: true, message: '请选择会员', trigger: 'blur' }
        ],
      },
      loading: false,
      form:{
        name:'',
        mobile:'',
        pay_start_time:'',
        pay_end_time:'',
        expire_start_time:'',
        expire_end_time:'',
        page:1,
        limit:10
      },
      payTime:'',
      expireTime:'',
      list: [],
      total:0,
      layout: "total, sizes, prev, pager, next , jumper",
      total: 0,
      editShow:false,
      editform:{
        club_vip_id:'',
        name:'',
        mobile:'',
        company:'',
        main_product:'',
        province:'',
        city:'',
        area:'',
        address:'',
        get_industry_information:'',
      },
      provinceSelect: [],
      citySelect: [],
      districtSelect: [],
      clubvipSelect:[],
    }
  },
  mounted() {
    this.getlist();
    this.getprov();
    this.getclubvip();
  },
  methods: {
    getlist(){
      let that = this;
      postAction("/user/business-club/vip-user-list",that.form).then(res=>{
        // console.log('ztq',res.data)
        if(res.code == 0){
          that.list = res.data;
          that.total = res.totalCount;
        }else{
          that.$message.error(res.msg)
        }
      }).catch(res=>{
        that.$message.error(res.msg)
      })
    },
    onSubmit(){
      console.log(this.payTime)
      if(this.payTime!=null && this.payTime!=''){
        this.form.pay_start_time=this.payTime[0];
        this.form.pay_end_time = this.payTime[1];
      }else{
        this.form.pay_start_time='';
      this.form.pay_end_time = '';
      }
      if(this.expireTime!=null && this.expireTime!=''){
        this.form.expire_start_time = this.expireTime[0];
        this.form.expire_end_time = this.expireTime[1];
      }else{
        this.form.expire_start_time = ''
        this.form.expire_end_time = ''
      }
      
      this.form.page = 1;
      this.getlist();
    },
    handleCurrentChange(val){
      console.log(val)
      this.form.page = val;
      this.getlist();
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.limit = val
      this.getlist();
    },
    onExport(){
      this.loading = true
        postAction('/user/business-club/vip-user-export', this.form).then((res) => {
          console.log('ztq',res)
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
          this.loading = false
        })
    },
    onEdit(){
      this.editShow=true;
    },
    getclubvip(){
      postAction('/user/business-club/vip-info',{}).then((res) => {
        this.clubvipSelect = res.data
      })
    },
    getprov(){
      postAction('/user/index/area', { pid: 0 }).then((res) => {
        this.provinceSelect = res.data
      })
    },
    handlerprovince(e) {
      console.log(e)
      var pid = 0;
      this.provinceSelect.forEach(item => {
        if(item.name == e){
          pid = item.id
        }
      });
      postAction('/user/index/area', { pid: pid }).then((res) => {
          this.citySelect = res.data
          this.editform.city = ''
          this.editform.area = ''
          this.districtSelect = []
        })
      },
      handlercity(e) {
        let that = this;
        var pid = 0;
        that.citySelect.forEach(item => {
          if(item.name == e){
            pid = item.id
          }
        });
        postAction('/user/index/area', { pid: pid }).then((res) => {
          that.districtSelect = res.data
          that.editform.area = ''
        })
      },
      editSbmit(){
        let that = this;
        console.log('that.editform',that.editform)
        this.$refs['editform'].validate((valid) => {
          console.log('valid',valid)
          if (valid) {
            console.log('submit!',that.editform);
            var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(that.editform.mobile)) {
              that.$message.error('请输入正确的手机号码')
              return false
            }
            postAction('/user/business-club/open-vip', that.editform).then((res) => {
              if (res.code == 0) {
                that.$message.success('保存成功')
                that.getlist();
                that.editShow = false;
                that.editform={
                  club_vip_id:'',
                  name:'',
                  mobile:'',
                  company:'',
                  main_product:'',
                  province:'',
                  city:'',
                  area:'',
                  address:'',
                  get_industry_information:'',
                }
              } else {
                that.$message.error(res.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleClose(){
        this.editShow = false;
        this.$refs['editform'].resetFields();
        this.editform={
          club_vip_id:'',
          name:'',
          mobile:'',
          company:'',
          main_product:'',
          province:'',
          city:'',
          area:'',
          address:'',
          get_industry_information:'',
        }
      },
  }
}
</script>
<style lang="scss" scoped>
  .formHeader{
    display: flex;
    padding: 20px;
    .el-form-item{
      margin-right: 15px;
    }
  }
</style>