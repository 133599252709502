<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
          @change="handlerbumen"
        >
          <el-option
            v-for="(list,index) in bumenSelect"
            :key="index"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.invite_name"
          clearable
          filterable
          placeholder="邀请人"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.userid"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="奖励结束时间"
          format="yyyy-MM"
          start-placeholder="奖励开始时间"
          style="width: 340px"
          type="monthrange"
          value-format="yyyy-MM"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        htyun_dept:'',
        htyun_name:'',
        invite_id:'',
        invite_name:'',
        start_time: '',
        end_time: '',
        page: 1,
        limit: 10,
      },
      time: [],
      staffSelect:[],
      bumenSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '部门',
          prop: 'htyun_dept',
          width: '',
        },
        {
          label: '邀请人',
          prop: 'invite_name',
          width: '',
        },
        {
          label: '已提交需求数量',
          prop: 'commit_count',
          width: '',
        },
        {
          label: '待审核需求数量',
          prop: 'nopass_count',
          width: '',
        },
        {
          label: '已通过需求数量',
          prop: 'pass_count',
          width: '',
        },
        {
          label: '需求符合奖励数量',
          prop: 'award_find_count',
          width: '',
        },
        {
          label: '邀请留言数量',
          prop: 'invite_count',
          width: '',
        },
        {
          label: '留言被查看数量',
          prop: 'scan_count',
          width: '',
        },
        {
          label: '留言符合奖励数量',
          prop: 'award_message_count',
          width: '',
        },
        {
          label: '获得奖励数量',
          prop: 'award_count',
          width: '',
        },
        {
          label: '获得奖励金额',
          prop: 'award_money',
          width: '',
        },
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    async handlerSelect(){
     await getAction("/form/form/dept").then(res=>{
        console.log(res)
        this.bumenSelect=res.data
      })


    },
    handlerbumen(v){
      this.form.invite_name=''
      getAction("/form/form/staff",{htyun_dept:v}).then(res=>{
        console.log(res)
        this.staffSelect=res.data
      })
    },
    handlerlist() {
      getAction('/api/form/form/find-invite-tj', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      getAction('/api/form/form/find-invite-tj-export', { ...this.form,yjhasync:1 }).then(
        (res) => {
          if (res.request_code == 200){
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
