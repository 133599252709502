<template>
  <div class="index-container">
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '页面'">
            {{ row.type == 1 ? '经销商' : '企业' }}
          </div>
          <div v-else-if="item.label == '分享图'">
            <el-image
              v-if="row[item.prop]"
              style="width: 80px; height: 80px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">

<!--          <el-popconfirm title="确认删除？" @confirm="handlerdelete(row)">-->
<!--            <el-button slot="reference" size="small" type="text">-->
<!--              删除-->
<!--            </el-button>-->
<!--          </el-popconfirm>-->
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="editposter" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import edit from './components/edit'
export default {
  name: "index",
  components:{
    edit
  },
  data() {
    return {
      form: {

        page: 1,
        limit: 10,
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '80',
        },
        {
          label: '页面',
          prop: 'type',
          width: '',
        },
        {
          label: '分享标题',
          prop: 'share_title',
          width: '',
        },
        {
          label: '分享图',
          prop: 'share_img',
          width: '',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerAdd() {
      this.$refs.editposter.showEditPoster = true
    },
    handlerlist() {
      getAction('/api/system/direction/conf-lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handlerdelete(row) {
      postAction('/api/system/vote/delete', { id: row.id }).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    // 编辑分类
    handleredit(row) {
      this.$refs.editposter.showEditPoster = true
      this.$refs.editposter.handlerinfo(row)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
};
</script>

<style scoped>

</style>
