<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_id"
          clearable
          placeholder="产品ID"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="form.is_check"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in checkselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_on_sale"
          clearable
          placeholder="上架状态"
          style="width: 160px"
        >
          <el-option label="上架" value="1" />
          <el-option label="下架" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_show"
          clearable
          placeholder="是否关停"
          style="width: 160px"
        >
          <el-option
            v-for="list in showSelsect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="商品名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_douyin_show_info"
          clearable
          placeholder="抖音开关"
          style="width: 160px"
        >
          <el-option label="显示" value="1" />
          <el-option label="不显示" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.op_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '展示图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '相册'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '店铺名称'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '店铺审核状态'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '抖音开关'">
            {{ row[item.prop] == 0 ? '不显示' : '显示' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <div>
            <template v-if="row.is_check == 0">
              <el-button
                size="small"
                type="text"
                @click.native.prevent="handlerexamine(row)"
              >
                拒绝
              </el-button>
              <el-popconfirm title="确认审核通过？" @confirm="handlertong(row)">
                <el-button
                  size="small"
                  style="margin: 10px"
                  slot="reference"
                  type="text"
                >
                  通过
                </el-button>
              </el-popconfirm>
            </template>
            <el-button size="small" type="text" @click="browseRow(row)">
              详情
            </el-button>
            <el-button
              size="small"
              type="text"
              @click.native.prevent="handlerdouyin(row)"
            >
              {{
                row.is_douyin_show_info == 0 ? '开启抖音图片' : '关闭抖音图片'
              }}
            </el-button>
            <el-button
              v-has="'views'"
              icon="el-icon-edit"
              type="text"
              @click.native.prevent="setViews(row)"
            >
              设置浏览量
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <browse ref="browse" />
    <tasklog ref="taskLog"></tasklog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import browse from './components/browse'
  import tasklog from '@/views/pages/datastatis/Information/components/tasklog.vue'
  export default {
    name: 'Index',
    components: { browse, tasklog },
    data() {
      return {
        form: {
          goods_name: '',
          mall_id: '',
          name: '',
          cat_pid: '',
          cat_id: '',
          is_show: '',
          is_check: '',
          start_time: '',
          end_time: '',
          is_douyin_show_info: '',
          op_name: '',
          page: 1,
          limit: 10,
        },
        mallSelsect: [],
        showSelsect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '是',
          },
          {
            id: 1,
            name: '否',
          },
        ],
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审核',
          },
          {
            id: 1,
            name: '通过',
          },
          {
            id: 2,
            name: '未通过',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'id',
            width: '80',
          },
          {
            label: '产品名称',
            prop: 'goods_name',
            width: '110',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '110',
          },
          {
            label: '店铺审核状态',
            prop: 'mall_status_txt',
            width: '220px',
          },
          {
            label: '企业名称',
            prop: 'company_name',
            width: '120',
          },
          {
            label: '业务员',
            prop: 'yewu',
            width: '80',
          },
          {
            label: '一级分类',
            prop: 'cat_p_txt',
            width: '110',
          },
          {
            label: '二级分类',
            prop: 'cat_txt',
            width: '110',
          },
          {
            label: '展示图',
            prop: 'goods_img',
            width: '160',
          },
          {
            label: '相册',
            prop: 'list_img',
            width: '120',
          },
          {
            label: '上架状态',
            prop: 'is_on_sale_txt',
            width: '80',
          },
          {
            label: '抖音开关',
            prop: 'is_douyin_show_info',
            width: '80',
          },
          {
            label: '排序',
            prop: 'sort_order',
            width: '160',
          },
          {
            label: '浏览量',
            prop: 'browser_count',
            width: '140',
          },
          {
            label: '虚拟浏览量',
            prop: 'browser_count_virtual',
            width: '80',
          },
          {
            label: '是否关停',
            prop: 'mall_is_show',
            width: '80',
          },
          {
            label: '添加时间',
            prop: 'add_time_txt',
            width: '',
          },
          {
            label: '审核状态',
            prop: 'is_check_txt',
            width: '200',
          },
          {
            label: '审核不通过原因',
            prop: 'check_reason',
            width: '80',
          },
          {
            label: '审核人',
            prop: 'op_name',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'check_time',
            width: '',
          },
        ],
        total: 0,
        cateselect: [],
        catselect: [],
        timelist: [],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_check_date = v[0]
          this.form.end_check_date = v[1]
        } else {
          this.form.start_check_date = ''
          this.form.end_check_date = ''
        }
      },
    },
    mounted() {
      if (this.$route.query.goods_id) {
        this.form.goods_id = this.$route.query.goods_id
      }
      if (this.$route.query.mall_id) {
        this.form.mall_id = this.$route.query.mall_id
      }
      console.log(this.$route.query.goods_id, '參數')
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      setViews(row) {
        this.$refs.taskLog.showData({
          task_type: 2,
          data_id: row.id,
          title: row.mall_name,
          virtual_num: row.virtual_num,
          views_num: row.views_num,
        })
      },
      handlerdouyin(row) {
        let statys = row.is_douyin_show_info
        if (statys == 1) {
          postAction('/audit/shop/goods-douyin-show-info', {
            id: row.id,
            is_douyin_show_info: 0,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        } else {
          postAction('/audit/shop/goods-douyin-show-info', {
            id: row.id,
            is_douyin_show_info: 1,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        }
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      browseRow(row) {
        console.log(row)
        this.$refs.browse.handlerinfo(row.id)
        this.$refs.browse.diashow()
      },
      handlerexamine(row) {
        this.$prompt('理由', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          postAction('/audit/shop/goods-audit', {
            id: row.id,
            status: 2,
            reason: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        })
      },
      handlertong(row) {
        postAction('/audit/shop/goods-audit', {
          id: row.id,
          status: 1,
          reason: '',
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      handlerlist() {
        getAction('/mall/product/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
