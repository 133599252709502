<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.apply_name"
          clearable
          placeholder="申请人名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.phone"
          clearable
          placeholder="主管理员手机号"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.inviter_name"
          clearable
          placeholder="邀约人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺名称'">
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop]==1?'已处理':'未处理' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "index",
  data() {
    return {
      dialogVisible:false,
      form: {
        mall_name: '',
        inviter_name: '',
        company: '',
        apply_name:'',
        phone:'',
        page: 1,
        limit: 10,
      },
      callselect: [

        {
          id: 0,
          name: '未处理',
        },
        {
          id: 1,
          name: '已处理',
        },
      ],
      goodsselect: [],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '店铺ID',
          prop: 'id',
          width: '',
        },
        {
          label: '公司名称',
          prop: 'company_name',
          width: '',
        },
        {
          label: '店铺名称',
          prop: 'mall_name',
          width: '',
        },
        {
          label: '申请人姓名',
          prop: 'apply_name',
          width: '',
        },
        {
          label: '主管理员手机号',
          prop: 'phone',
          width: '',
        },
        {
          label: '邀约人',
          prop: 'inviter_name',
          width: '',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/mall/shop/no-goods-mall', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handlerExport() {
      getAction('/mall/shop/no-goods-mall-export', this.form).then((res) => {
        console.log(res)
        import('@/utils/excel').then((excel) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
};
</script>

<style scoped>

</style>
