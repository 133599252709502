var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "13vh",
        "close-on-click-modal": false,
        "append-to-body": "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "right",
                "label-suffix": ":",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属栏目", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.type_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type_name", $$v)
                      },
                      expression: "form.type_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.jump_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_type", $$v)
                      },
                      expression: "form.jump_type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.liveTitle, prop: "live_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "390px" },
                      attrs: { filterable: "", clearable: "", placeholder: "" },
                      model: {
                        value: _vm.form.live_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "live_id", $$v)
                        },
                        expression: "form.live_id",
                      },
                    },
                    _vm._l(_vm.selectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "390px" },
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: "1" } }),
                      _c("el-option", { attrs: { label: "禁用", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-button" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }