<template>
  <div class="index-container">
    <el-row>
      <el-col :span="12">
        <el-table border :data="tablelist" style="width: 100%">
          <el-table-column label="id" prop="id" width="" />
          <el-table-column label="名称" prop="name" width="" />
          <el-table-column label="时间" prop="value" width="180px" />
          <el-table-column label="操作" prop="" width="">
            <template #default="{ row }">
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="编辑" :visible.sync="showedit" width="30%">
      <div>
        <el-form ref="form" label-width="40px" :model="form">
          <el-form-item label="时间">
            <el-date-picker
              v-model="form.value"
              start-placeholder="开始日期"
              style="width: 250px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  // import WangEditor from '@/components/WangEditor'

  export default {
    name: 'Index',
    // components: { WangEditor },
    data() {
      return {
        tablelist: [],
        showedit: false,
        form: {},
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handleredit(row) {
        this.showedit = true
        this.form = JSON.parse(JSON.stringify(row))
      },
      handlerlist() {
        getAction('/api/system/hot/conf', { code: 'hot_start_time' }).then(
          (res) => {
            this.tablelist = [res.data]
          }
        )
      },
      handlersave() {
        let params = {
          code: this.form.code,
          value: this.form.value,
        }
        postAction('/api/system/hot/set-conf', params)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showedit = false
            this.handlerlist()
          })
          .catch((err) => {
            console.error(err)
          })
      },
    },
  }
</script>

<style scoped></style>
