<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="relatedShow"
      width="70%"
      :before-close="handleClose"
      align="center"
    >
      <div class="related">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="handlerEdit()">新增题目</el-button>
        </div>
        <el-table
          :data="list"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="id" label="ID" width="80" align="center">
            <template slot-scope="scope">
              <span>
                {{ scope.row.answer_list != undefined ? scope.row.id : '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="类型" width="80" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.is_single == 1">单选</span>
              <span v-if="scope.row.is_single == 2">多选</span>
              <span v-if="scope.row.is_single == 3">文本框</span>
              <span v-if="scope.row.is_single == 0">选品题</span>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="题目"></el-table-column>
          <el-table-column label="答案" prop="reply">
            <!-- <template slot-scope="scope">
            <div>{{scope.row.reply}}</div>
          </template> -->
          </el-table-column>
          <el-table-column label="关联下题" align="center" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.is_single == 2 && !scope.row.answer_list">
                {{ scope.row.next_question }}
              </span>
              <span
                v-else
                @click="
                  nextShow = true
                  next_question = scope.row.next_question
                  row = scope.row
                "
              >
                {{ scope.row.next_question }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="是否结束答题" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="changestate(scope.row, 'is_end')">
                {{ scope.row.is_end == 1 ? '是' : '否' }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="排序" width="100" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="move(scope.row, '-1')">
                上移
              </el-button>
              <el-button type="text" @click="move(scope.row, '1')">
                下移
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
            prop="option"
            label="操作"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <el-button
                  type="text"
                  v-if="scope.row.title != undefined && scope.row.answer_list"
                  @click="handlerEdit(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  v-if="scope.row.is_sort == 1"
                  @click="getcate"
                >
                  查看品类
                </el-button>
                <el-button
                  type="text"
                  v-if="scope.row.answer_list"
                  @click="del(scope.row)"
                >
                  删除
                </el-button>
                <el-button
                  type="text"
                  v-if="scope.row.answer_list != undefined"
                  @click="changestate(scope.row, 'status')"
                >
                  {{ scope.row.status == 0 ? '禁用' : '启用' }}
                </el-button>
                <el-button
                  type="text"
                  v-if="scope.row.answer_list == undefined"
                  @click="changestate(scope.row, 'is_hide')"
                >
                  {{ scope.row.is_hide == 1 ? '禁用' : '启用' }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      title="关联下一题"
      :visible.sync="nextShow"
      width="300px"
      :before-close="handleClose"
    >
      <div>
        <el-input
          v-model="next_question"
          placeholder="请输入要关联的下一题ID"
        ></el-input>
        <p style="margin-top: 20px; text-align: right">
          <el-button
            type="primary"
            @click="
              nextShow = false
              next_question = ''
            "
          >
            取消
          </el-button>
          <el-button type="primary" @click="changestate(row, 'next_question')">
            确定
          </el-button>
        </p>
      </div>
    </el-dialog>
    <editquestion ref="editquestion" @getinfo="getinfo"></editquestion>
    <category ref="category"></category>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import editquestion from './editquestion'
  import category from './components/category'
  export default {
    name: 'related',
    components: {
      editquestion,
      category,
    },
    props: {
      relatedShow: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        list: [],
        nextShow: false,
        next_question: '',
        row: '',
      }
    },
    watch: {},
    mounted() {},
    methods: {
      getinfo() {
        // 获取详情
        let that = this
        postAction('/api/system/research/question-contact', {
          conf_id: that.id,
        }).then((res) => {
          if (res.code == 0) {
            var d = res.data
            var list = []
            for (var i = 0; i < d.length; i++) {
              var data = d[i].answer_list
              list.push(d[i])
              for (var m = 0; m < data.length; m++) {
                list.push(data[m])
              }
            }
            that.list = list
            console.log(list)
          }
        })
      },
      tableRowClassName({ row, rowIndex }) {
        if (row.answer_list != undefined) {
          return 'row-question'
        }
        return ''
      },
      move(row, type) {
        let that = this
        var data = {
          source: row.answer_list != undefined ? '1' : '2',
          type: type,
          id: row.id,
        }
        console.log(data)
        postAction('/api/system/research/set-sort', data).then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '操作成功',
              type: 'success',
            })
            that.getinfo(that.id)
          } else {
            that.$message.error(res.msg)
          }
        })
      },
      del(row) {
        let that = this
        var data = {
          source: row.answer_list != undefined ? '1' : '2',
          id: row.id,
        }
        console.log(data)
        postAction('/api/system/research/question-del', data).then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '操作成功',
              type: 'success',
            })
            that.getinfo(that.id)
          } else {
            that.$message.error(res.msg)
          }
        })
      },
      changestate(row, text) {
        let that = this
        if (text == 'is_end') {
          var value = row.is_end == '0' ? '1' : '0'
        } else if (text == 'is_hide') {
          var value = row.is_hide == '0' ? '1' : '0'
        } else if (text == 'status') {
          console.log(row.status)
          var value = row.status == '0' ? '1' : '0'
        } else if (text == 'next_question') {
          var value = that.next_question
        }
        console.log(text, value)
        var data = {
          source: row.answer_list != undefined ? '1' : '2',
          id: row.id,
          field: text,
          value: value,
        }
        console.log(data)
        postAction('/api/system/research/update-field', data).then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '操作成功',
              type: 'success',
            })
            that.getinfo(that.id)
            if ((text = 'next_question')) {
              that.nextShow = false
              that.next_question = ''
            }
          } else {
            that.$message.error(res.msg)
          }
        })
      },
      handlerEdit(row) {
        console.log(row)
        if (row) {
          this.$refs.editquestion.editTitle = '编辑题目'
          var data = {
            title: row.title,
            id: row.id,
            is_single: row.is_single,
            answer: row.answer_list,
          }
        } else {
          var data = {
            title: '',
            id: '',
            is_single: '',
            answer: [
              {
                id: '',
                reply: '',
              },
            ],
          }
        }
        this.$refs.editquestion.title = this.title
        this.$refs.editquestion.form.data = data
        this.$refs.editquestion.form.conf_id = this.id
        this.$refs.editquestion.is_show = true
      },
      getcate() {
        this.$refs.category.getinfo()
        this.$refs.category.id = this.id
        this.$refs.category.title = this.title
      },
    },
  }
</script>

<style lang="scss" scoped>
  .related {
    text-align: left;
  }
  :deep() .el-table tr.row-question {
    background: #fbfbfb;
  }
  // :deep() .el-table--small .el-table__cell{
  //   padding: 0;
  // }
  // :deep() .el-table_2_column_22{
  //   padding:8px 0;
  // }
</style>
