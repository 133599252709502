var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showcat
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "热门精选关联品类",
            visible: _vm.showcat,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcat = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-tree", {
                        ref: "menuTree",
                        attrs: {
                          data: _vm.TreeData,
                          "default-checked-keys": _vm.form.cat_ids,
                          "default-expand-all": "",
                          "highlight-current": "",
                          "node-key": "id",
                          "check-on-click-node": true,
                          props: _vm.menuDefaultProps,
                          "show-checkbox": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showcat = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }