<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="showValid"
    width="30%"
  >
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="">
        <el-radio v-model="radio" label="1">企业已拒绝</el-radio>
        <el-radio v-model="radio" label="2">会议不成功</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showValid = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'AddEdit',
    props: {
      rowid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showValid: false,
        title: '无效原因',
        reasonlist: [
          {
            id: 1,
            name: '企业已拒绝',
          },
          {
            id: 2,
            name: '会议不成功',
          },
        ],
        reason: '',
        id: '',
        other: '',
        form: {
          id: '',
          product_id: '',
          goods_name: '',
          sort: 0,
          status: '1',
        },
        radio: '',
      }
    },
    watch: {
      showValid(val) {
        if (!val) {
          this.reason = ''
          this.id = ''
        }
      },
      radio(val) {
        console.log('---', val)
      },
    },
    mounted() {
      this.reason = ''
      this.id = ''
    },

    methods: {
      handleCheckChange() {},
      handlersave() {
        console.log('确定', this.reason, this.id)
        postAction('/audit/biz-clue/set-unvalid', {
          id: this.id,
          reason: this.radio == 1 ? '企业已拒绝' : '会议不成功',
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.showValid = false
          this.$emit('getlist')
        })
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
</style>
