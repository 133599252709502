<template>
  <div>
    <el-dialog :title="title" :visible.sync="showSetPoster" width="50%">
      <div>
        <el-form ref="form" inline label-suffix=":" :model="form">
          <el-form-item>
            <el-select
              v-model="form.status"
              clearable
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in shopSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.name"
              clearable
              placeholder="分类名称"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerAdd">新 增</el-button>
          </el-form-item>
        </el-form>
        <el-table border :data="tabledata" style="width: 100%">
          <el-table-column
            v-for="(item, tableIndex) in columns"
            :key="tableIndex"
            align="center"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
            <template #default="{ row }">
              <div v-if="item.label == '状态'">
                {{ row.status == 1 ? '启用' : '禁用' }}
              </div>
              <div v-else-if="item.label == '模板'">
                {{ row.template.name }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="220"
          >
            <template #default="{ row }">
              <el-popconfirm
                v-show="row.status == 1"
                title="确认禁用？"
                @confirm="deleteRow(row, 2)"
              >
                <el-button slot="reference" size="small" type="text">
                  禁用
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                v-show="row.status != 1"
                title="确认启用？"
                @confirm="deleteRow(row, 1)"
              >
                <el-button slot="reference" size="small" type="text">
                  启用
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                title="确认删除该分类？"
                @confirm="handlerdelete(row)"
              >
                <el-button slot="reference" size="small" type="text">
                  删除
                </el-button>
              </el-popconfirm>
              <el-button
                size="small"
                style="margin: 10px"
                type="text"
                @click.native.prevent="handleredit(row)"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-dialog>
    <editsetposter
      ref="editsetposter"
      :typeid="form.type_id"
      @getlist="handlerInquire"
    />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import editsetposter from './editsetposter'
  export default {
    components: {
      editsetposter,
    },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        title: '设置海报',
        showSetPoster: false,
        tabledata: [],
        total: 0,
        form: {
          page: 1,
          limit: 5,
          id: null,
          name: '', //名称
          status: '', // '' 全部 1-启用 2-禁用
          type_id: '',
        },
        shopSelect: [
          {
            id: '',
            name: '全部',
          },
          {
            id: 1,
            name: '启用',
          },
          {
            id: 2,
            name: '禁用',
          },
        ],
        columns: [
          {
            label: '海报名称',
            prop: 'name',
            width: '',
          },
          {
            label: '文案',
            prop: 'desc',
            width: '',
          },
          {
            label: '模板',
            prop: 'template_no',
            width: '',
          },
          {
            label: '排序',
            prop: 'sort_order',
            width: '80',
          },

          {
            label: '状态',
            prop: 'status',
            width: '80',
          },
        ],
      }
    },
    methods: {
      // 设置禁用or启用
      deleteRow(row, status) {
        let params = row
        params.status = status
        postAction('/api/poster/index/edit', params).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist(row) {
        if (row) {
          this.form.type_id = row.id
        }
        getAction('/api/poster/index/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerAdd() {
        this.$refs.editsetposter.showEditSetPoster = true
      },
      handleredit(row) {
        this.$refs.editsetposter.showEditSetPoster = true
        this.$refs.editsetposter.handlerinfo(row)
      },
      handlerdelete(row) {
        postAction('/api/poster/index/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>
<style scoped></style>
