<template>
  <div class="index-container">
    <el-table
      :data="tabledata">
      <el-table-column
        type="index"
        label="编号"
        align="center"
        width="80">
      </el-table-column>
      <el-table-column
        prop="name"
        label="类型"
        align="center"
        width="180">
      </el-table-column>
      <el-table-column
        prop="content"
        label="规则与教程"
        align="center"
        width="">
        <template #default="{row}">
          <span v-html="row.content"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        width="110"
        align="center"
        label="操作">
        <template #default="{row}">
        <el-button type="text" @click="handerledit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit" @getlist="handlerlist"/>
  </div>
</template>

<script>
import edit from './components/edit'
import { getAction } from "@/api/request";

export default {
  components:{
    edit
  },
  name: "index",
  data(){
    return{
      tabledata:[],
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handerledit(row){
      this.$refs.edit.showedit=true
      this.$refs.edit.handlerinfo(row)
    },
    handlerlist(){
      getAction("/api/system/integral/conf").then(res=>{
        console.log(res)
        this.tabledata=[{
          id:res.data.id,
          name:'云币规则',
          content:res.data.content,
          value:'content'
        },
          {
            id:res.data.id,
            name:'付费教程',
            content:res.data.exchange_note,
            value:'exchange_note'
          },
          {
            id:res.data.id,
            name:'免费教程',
            content:res.data.scan_note,
            value:'scan_note'
          }]
      })
    }
  }
};
</script>

<style scoped>

</style>
