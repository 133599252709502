var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "会议列表", visible: _vm.show, width: "1200px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { inline: "", "label-suffix": ":", model: _vm.form },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { clearable: "", placeholder: "状态" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.statusSelect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "staff_id" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": (queryString, cb) => {
                        _vm.handlerstaff(queryString, cb)
                      },
                      "value-key": "staff_name",
                      placeholder: "请输入提报人",
                    },
                    on: {
                      select: (list) => {
                        _vm.handleSelectstaff(list)
                      },
                    },
                    model: {
                      value: _vm.form.staff_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "staff_name", $$v)
                      },
                      expression: "form.staff_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dealer_id" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": (queryString, cb) => {
                        _vm.handlerdealer(queryString, cb)
                      },
                      "value-key": "dealer_name",
                      placeholder: "请输入经销商",
                    },
                    on: {
                      select: (list) => {
                        _vm.handleSelectdealer(list)
                      },
                    },
                    model: {
                      value: _vm.form.dealer_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealer_name", $$v)
                      },
                      expression: "form.dealer_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { clearable: "", placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查 询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  sortable: item.sort,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          item.label == "经销商意愿凭证"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "大商凭证"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "会议截图"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                      attrs: { gutter: 10 },
                                    },
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  "preview-src-list": [list],
                                                  src: list,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: {
                                                      slot: "placeholder",
                                                    },
                                                    slot: "placeholder",
                                                  },
                                                  [
                                                    _vm._v(" 加载中 "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dot" },
                                                      [_vm._v("...")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : item.label == "类型"
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row[item.prop] == 3
                                        ? "云会议"
                                        : "大商直通车"
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }