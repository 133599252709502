<template>
  <div class="orderTest-container">
    <el-form :model="form" inline ref="form" label-position="right">
      <el-form-item prop="">
        <el-input v-model="form.goods_name" clearable placeholder="商品名称"></el-input>
      </el-form-item>
      <el-form-item prop="">
        <el-select class="input" v-model="form.type" clearable placeholder="分类">
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="">
        <el-select v-model="form.status" style="width: 110px" clearable placeholder="状态">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop=""
        ><el-button type="primary" @click="handleAdd">新建</el-button></el-form-item
      >
      <el-form-item label="" prop=""
        ><el-button type="primary" @click="handleQuery">查询</el-button></el-form-item
      >
    </el-form>
    <el-table border :data="list" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'img'">
            <el-image :src="row.img" :preview-src-list="[row.img]"></el-image>
          </div>
          <div v-else-if="item.prop == 'goods_info'">
            {{ filterInfo(row.goods_info) }}
          </div>
          <div v-else-if="item.prop == 'status'">
            <p v-if="row[item.prop]==1">启用</p>
            <p v-else>禁用</p>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="140" align="center">
        <template #default="{ row }">
          <el-button type="text" @click="tableEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center"
    ></el-pagination>
    <AddEdit ref="addEdit" @refresh="fetchData"></AddEdit>
  </div>
</template>
<script>
import AddEdit from "./components/addEdit.vue"
import { getAction, postAction } from "@/api/request";
export default {
  name: "JifenShangcheng",
  components: { AddEdit },
  data() {
    return {
      loading: false,
      list: [],
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      form: {
        goods_name: "",
        type: "",
        status:'',
        page: 1,
        limit: 10,
      },
      typeSelect: [
        { id: 1, name: "行业报告" },
        { id: 2, name: "线上课程" },
        { id: 3, name: "爆款好物" },
      ],
      columns: [
        {
          order: 1,
          label: "ID",
          prop: "id",
          width: "90",
        },
        {
          order: 2,
          label: "商品名称",
          prop: "goods_name",
          width: "210",
        },
        {
          order: 3,
          label: "分类",
          prop: "type_name",
          width: "140",
        },
        {
          order: 4,
          label: "简介",
          prop: "goods_info",
          width: "300",
        },
        {
          order: 5,
          label: "商品图片",
          prop: "img",
          width: "100",
        },
        {
          order: 6,
          label: "商品数量",
          prop: "num",
          width: "140",
        },
        {
          order: 7,
          label: "兑换所需云币",
          prop: "integral",
          width: "140",
        },
        {
          order: 7,
          label: "排序",
          prop: "sort_order",
          width: "140",
        },
        {
          order: 7,
          label: "状态",
          prop: "status",
          width: "150",
        },
      ],
    }
  },
  created() {},
  mounted() {
    this.fetchData()
  },
  computed: {},
  watch: {},
  methods: {
    async fetchData() {
      this.loading = true
      let res = await getAction("/api/system/integral/goods-list", this.form)
      this.list = res.data
      this.total = Number(res.totalCount)
      this.loading = false
    },
    filterInfo(info) {
      let max = 40
      if (info && info.length > max) {
        return info.slice(0, max) + "..."
      } else {
        return info
      }
    },
    handleQuery() {
      this.form.page = 1
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.fetchData()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.fetchData()
    },
    handleAdd() {
      this.$refs.addEdit.type = 1
      this.$refs.addEdit.id = ""
      this.$refs.addEdit.showDialog = true
    },

    tableEdit(row) {
      this.$refs.addEdit.type = 2
      this.$refs.addEdit.id = row.id
      this.$refs.addEdit.showDialog = true
    },
  },
}
</script>
<style lang="scss" scoped></style>
