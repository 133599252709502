<template>
  <el-dialog  :title="title"
              :visible.sync="showadd"
              :append-to-body="true"
              width="650px"
              top="15vh">
    <div>
      <el-form ref="form" :model="form" label-width="220px" :rules="rules">
<!--        :rules="rules"-->
        <el-form-item label="栏目标题模板：" prop="title_type">
          <el-select v-model="form.title_type" clearable @change="handlertitletype">
            <el-option label="仅主题" value="1">仅主题</el-option>
            <el-option label="主标题和副标题" value="2">主标题和副标题</el-option>
            <el-option label="图片" value="3">图片</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="栏目主标题：" prop="title" v-if="form.title_type==1||form.title_type==2" >
          <el-input v-model="form.title" maxlength="30" style="width: 220px" clearable/>
        </el-form-item>
        <el-form-item label="栏目副标题：" prop="subtitle" v-if="form.title_type==2">
          <el-input v-model="form.subtitle" maxlength="30" style="width: 220px" clearable/>
        </el-form-item>
        <el-form-item label="栏目标题图片：" prop="title_pic" v-if="form.title_type==3">
          <upload-img :imgstyle="'width: 100px; height: 100px; margin-right: 10px'" ref="uploadImgpic" :infoText="''" :limit="1" @uploadImg="uploadImg" :maxSize="100"/>
          <p>尺寸: 750*120</p>
        </el-form-item>
        <el-form-item label="栏目内容排列方式：" prop="display_type">
        <el-select v-model="form.display_type" clearable>
          <el-option label="一行四列" value="1">一行四列</el-option>
          <el-option label="一行三列" value="2">一行三列</el-option>
          <el-option label="一行两列" value="3">一行两列</el-option>
          <el-option label="一行一列" value="4">一行一列</el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="关联类型：" prop="content_type">
          <el-select v-model="form.content_type" clearable>
            <el-option label="产品" value="1">产品</el-option>
            <el-option label="店铺" value="2">店铺</el-option>
            <el-option label="政策大放送" value="3">政策大放送</el-option>
            <el-option label="主题活动" value="4">主题活动</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort_order">
          <el-input-number v-model="form.sort_order" style="width: 220px" clearable min="0"/>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select v-model="form.status">
            <el-option value="1" label="禁用"/>
            <el-option value="0" label="启用"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer center">
      <el-button @click="showadd=!showadd">取消</el-button>
      <el-button type="primary" @click="handlersave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import UploadImg from '@/components/uploadImg.vue'
import { postAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "Addcolumn",
  components:{
    UploadImg
  },
  props:{
    title:{
      type:String,
      default:()=>{
        return "新增"
      }
    },
  },
  data(){
    var validatortitle = (rule, value, callback)=>{
      if (this.form.title_type === 1) {
        if (this.form.title=='') {
          callback(new Error('请填写栏目主标题'));
        }else{
          callback();
        }
      }else if (this.form.title_type === 2) {
        if(this.form.title==''||this.form.subtitle==''){
          callback(new Error('请填写栏目主标题和副标题'))
        }else{
          callback();
        }
      }else if (this.form.title_type === 3){
        if(this.form.title_pic==''){
          callback(new Error('请填上传栏目标题图片'))
        }else{
          callback();
        }
      }else{
        callback();
      }
    }
    return{
      showadd:false,
      form:{
        area_id:null,
        id:null,
        title_type:'',
      },
      rules:{
        title_type:[
          { required: true, message: '请选择栏目标题模板', trigger: 'blur' }
        ],
        title:[
          { validator:validatortitle,  trigger: 'blur',required: true, }
        ],
        subtitle:[{ validator:validatortitle,  trigger: 'blur',required: true, }],
        title_pic:[{ validator:validatortitle,  trigger: 'blur',required: true, }]

      }
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.$refs.form.resetFields()
        this.form.title=''
        this.form.subtitle=''
        this.form.id=''
        if(this.$refs.uploadImgpic!=undefined){
          this.$refs.uploadImgpic.img=[]
        }

      }
    },

  },
  methods:{

    uploadImg(val){
      if(val.length<1){
        this.form.title_pic=''
      }else{
        this.form.title_pic=val[0]

      }
    },
    handlerinfo(res){
      console.log(res)
      this.form.sort_order=Number(res.sort_order)
        this.form={...this.form,...res}
      this.form.sort_order=Number(this.form.sort_order)
      nextTick(()=>{
        if(this.form.title_pic){
          this.$refs.uploadImgpic.img=[this.form.title_pic]
        }
      })
    },
    handlertitletype(e){
      if(e==1){
        this.form.subtitle=''
        this.form.title_pic=''
      }else if(e==2){
        this.form.title_pic=''
      }else {
        this.form.title=''
        this.form.subtitle=''
      }
    },
    handlersave(){
      this.$refs.form.validate((valid) => {
        console.log(valid)
        if (valid) {
          postAction("/live/index/edit-arealivecolumn", this.form).then(res => {
            this.$message.success(res.msg)
            this.showadd = false
            this.$emit("getlist")
          })
        }else{
          console.log("进来了吗1")
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
