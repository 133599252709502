<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showadd"
    width="50%"
  >
    <div>
      <el-form ref="form" label-width="120px" :model="form" :rules="rules">
        <el-form-item label="品类" prop="checkedCates">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="form.checkedCates"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="item in cateSelect"
              :label="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="关联逛逛文章" prop="stroll_title">
          <el-autocomplete
            v-model="form.stroll_title"
            clearable
            :fetch-suggestions="fetchGoods"
            placeholder="请输入关联逛逛文章名称"
            style="width: 260px"
            value-key="title"
            @select="handleSelectGoods"
          />
        </el-form-item>
        <el-form-item label="文章标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="文章标题"
            style="width: 260px"
          />
        </el-form-item>

        <el-form-item label="封面图" prop="pic">
          <upload-img
            ref="pic"
            :info-text="''"
            :limit="1"
            :max-size="100"
            @getImgs="uploadImg($event, 'pic')"
          />
          <p style="color: #f00; font-size: 12px">尺寸：400*260像素</p>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="form.sort_order"
            placeholder="排序"
            style="width: 160px"
            type="number"
          />
          <span>
            （默认后上传的排在上边。支持手动排序，数字小的在前，大的在后。最小有效数字为1。）
          </span>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            clearable
            placeholder="设置状态"
            style="width: 160px"
          >
            <el-option label="启用" value="1" />
            <el-option label="禁用" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.add_time"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          <span>（默认为当前时间，可修改）</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import { nextTick } from 'vue'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
    },
    props: {
      cateSelect: {
        type: Array,
        default: [],
      },
      sourceSelect: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        showadd: false,
        title: '',
        shopSelect: [],
        form: {
          id: null,
          cat_id: '',
          source: '',
          title: '',
          stroll_title: '',
          sort_order: '',
          status: '1',
          add_time: '',
          pic: '',
          checkedCates: [],
        },
        rules: {
          checkedCates: [
            {
              type: 'array',
              required: true,
              message: '请选择品类',
              trigger: 'change',
            },
          ],
          source: [
            { required: true, message: '请选择来源', trigger: 'change' },
          ],
          title: [
            { required: true, message: '请输入文章标题', trigger: 'blur' },
          ],
          stroll_title: [
            { required: true, message: '请选择关联逛逛文章', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'change' },
          ],
          pic: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        },
        checkAll: false,
        isIndeterminate: false,
        id: '',
      }
    },
    watch: {
      showadd(val) {
        if (!val) {
          this.form = {
            id: null,
            cat_id: '',
            source: '',
            title: '',
            url: '',
            sort_order: '',
            status: '1',
            add_time: '',
            pic: '',
            checkedCates: [],
          }
          this.isIndeterminate = false
          this.closeimg('pic')
          this.$refs.form.resetFields()
        }
      },
    },
    methods: {
      handleCheckAllChange(val) {
        console.log('选择-------', val)
        // this.checkedCates = val ? this.cateSelect : []
        if (val) {
          this.form.checkedCates = this.cateSelect.map((item) => {
            return item.id
          })
        } else {
          this.form.checkedCates = []
        }
        this.isIndeterminate = false
      },
      fetchGoods(queryString, cb) {
        console.log('------------', queryString)
        if (queryString == '') {
          console.log('------------1111')
          cb([])
          this.form.stroll_id = ''
        } else {
          console.log('------------1111222')
          getAction('/api/system/wechat-article/stroll-select', {
            title: queryString,
          }).then((res) => {
            console.log('----res', res.data)
            cb(res.data)
          })
        }
      },
      handleSelectGoods(goods) {
        console.log('handleSelectGoods', goods)
        this.form.stroll_id = goods.id
        this.form.stroll_title = goods.title
        this.form.title = goods.title
        this.form.pic = goods.cover_img
        nextTick(() => {
          this.setImg(this.form.pic, 'pic')
        })
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length
        this.checkAll = checkedCount === this.cateSelect.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.cateSelect.length
      },
      handlerinfo(row) {
        // this.form = Object.assign(this.form, row)
        this.id = row.id
        this.fetchDetail()
        nextTick(() => {
          this.setImg(row.pic, 'pic')
        })
      },
      fetchDetail() {
        getAction('/api/system/wechat-article/stroll-info', {
          id: this.id,
        }).then((res) => {
          if (res.request_code == 200) {
            this.form = res.data
            this.form.checkedCates = res.data.cat_id.split(',')
            // this.form.status = parseInt(res.data.status)
          }
        })
      },
      handlersave() {
        console.log('图片', this.form.pic)

        this.$refs.form.validate((valid) => {
          console.log('图片', valid)
          if (valid) {
            this.form.cat_id = this.form.checkedCates.join(',')
            postAction(
              '/api/system/wechat-article/stroll-edit',
              this.form
            ).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.$refs.form.resetFields()
              this.$emit('getlist')
              this.showadd = false
            })
          }
        })
      },
      // 上传图片
      uploadImg(v, prop) {
        console.log('删除，', v)
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
        // if (val.length < 1) {
        //   console.log(1111)
        //   this.form.pic = ''
        // } else {
        //   console.log(1112)
        //   this.form.pic = val[0]
        // }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>

<style scoped></style>
