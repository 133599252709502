var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-option", { attrs: { value: "11", label: "android" } }),
                  _c("el-option", { attrs: { value: "12", label: "ios" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "版本号" },
                model: {
                  value: _vm.form.buildnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "buildnum", $$v)
                  },
                  expression: "form.buildnum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "app名称" },
                model: {
                  value: _vm.form.appname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appname", $$v)
                  },
                  expression: "form.appname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v(" 新建 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: item.width,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "下载链接"
                        ? _c("div", [
                            _c(
                              "a",
                              { attrs: { href: row[item.prop] } },
                              [
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _vm._v("下载"),
                                ]),
                              ],
                              1
                            ),
                          ])
                        : item.label == "操作"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleredit(row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            1
                          )
                        : item.label == "强制升级"
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(row[item.prop] == 1 ? "是" : "否") +
                                " "
                            ),
                          ])
                        : item.label == "显示"
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row[item.prop] == 1 ? "显示" : "不显示"
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("addedit", { ref: "add", on: { getlist: _vm.handlerInquire } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }