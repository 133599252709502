<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="店铺名称" clearable v-model="form.mall_name" style="width: 110px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="公司名称" clearable v-model="form.company" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="邀请人" clearable v-model="form.inviter_name" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.version" clearable style="width: 160px;" placeholder="套餐版本" @change="handlerversion">
          <el-option v-for="list in versionSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.set_meal_id" clearable style="width: 160px;" placeholder="套餐名称">
          <el-option v-for="list in mealSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.title"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-input placeholder="业务对接人" clearable v-model="form.order_invite_id " style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.status" clearable style="width: 160px;" placeholder="套餐状态">
          <el-option v-for="list in statusSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.match_views_status" clearable style="width: 160px;" placeholder="线上匹配+电话">
          <el-option v-for="list in matchSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.send_invite_status" clearable style="width: 160px;" placeholder="主动邀请">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.study_tour_status" clearable style="width: 160px;" placeholder="区域游学考察">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.referrals_status" clearable style="width: 160px;" placeholder="云会议">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.explosive_products_status" clearable style="width: 160px;" placeholder="抖音直播">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.mp_video_status" clearable style="width: 160px;" placeholder="视频号直播">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.mp_article_status" clearable style="width: 160px;" placeholder="公众号专稿推">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select v-model="form.miniwecat_live_status" clearable style="width: 160px;" placeholder="热品推荐视频">
          <el-option v-for="list in referralsSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
      <el-select v-model="form.through_train_status" clearable style="width: 160px;" placeholder="大商直通车">
        <el-option v-for="list in throughSelect"
                   :key="list.id"
                   :value="list.id"
                   :label="list.name"/>
      </el-select>
    </el-form-item>
      <el-form-item >
        <el-select v-model="form.advert_status" clearable style="width: 160px;" placeholder="广告曝光">
          <el-option v-for="list in throughSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>

    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}" >
          <div v-if="item.label=='套餐版本'">
           {{row[item.prop]==1?'云交会第一版套餐':'云交会第二版套餐'}}
          </div>
          <div v-else-if="item.prop=='ggy'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.guanggao.handlerlist(row.id)}">
            {{row.surplus_advert_exposure}}
          </div>
          <div v-else-if="item.prop=='gg'">
            {{row.setmeal_advert_exposure-row.surplus_advert_exposure}}
          </div>
          <div v-else-if="item.prop=='pp'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.dianhua.handlerlist(row.id,row.mall_id,row.mall_name)}">
            {{row.setmeal_match_views_num-row.surplus_match_views_num}}
          </div>
          <div v-else-if="item.prop=='zd'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.yaoqing.handlerinfo(row.mall_id)}">
            {{row.setmeal_send_invite_num-row.surplus_send_invite_num}}
          </div>
          <div v-else-if="item.prop=='zdyqsy'">
            {{row.surplus_send_invite_num<0?'无限':row.surplus_send_invite_num}}
          </div>
          <div v-else-if="item.prop=='zdyq'">
            {{row.setmeal_send_invite_num==-1?'无限':row.setmeal_send_invite_num}}
          </div>
          <div v-else-if="item.prop=='yx'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.xianxia.handlerinfo(row,7)}">
            {{row.setmeal_offline_study_tour-row.surplus_offline_study_tour}}
          </div>
          <div v-else-if="item.prop=='surplus_offline_study_tour'" >
            {{row.surplus_offline_study_tour<0?'0':row.surplus_offline_study_tour}}
          </div>
          <div v-else-if="item.prop=='yhy'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.huiyi.handlerinfo(row,3)}">
            {{row.setmeal_referrals_num-row.surplus_referrals_num}}
          </div>
          <div v-else-if="item.prop=='surplus_referrals_num'" >
            {{row.surplus_referrals_num<0?'0':row.surplus_referrals_num}}
          </div>
          <div v-else-if="item.prop=='dy'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.xianxia.handlerinfo(row,11)}">
            {{row.setmeal_offline_explosive_products-row.surplus_offline_explosive_products}}
          </div>
          <div v-else-if="item.prop=='surplus_offline_explosive_products'" >
            {{row.surplus_offline_explosive_products<0?'0':row.surplus_offline_explosive_products}}
          </div>
          <div v-else-if="item.prop=='rmsp'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.xianxia.handlerinfo(row,10)}">
            {{row.setmeal_offline_mp_video-row.surplus_offline_mp_video}}
          </div>
          <div v-else-if="item.prop=='surplus_offline_mp_video'" >
            {{row.surplus_offline_mp_video<0?'0':row.surplus_offline_mp_video}}
          </div>
          <div v-else-if="item.prop=='gzh'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.xianxia.handlerinfo(row,9)}">
            {{row.setmeal_offline_mp_article-row.surplus_offline_mp_article}}
          </div>
          <div v-else-if="item.prop=='surplus_offline_mp_article'" >
            {{row.surplus_offline_mp_article<0?'0':row.surplus_offline_mp_article}}
          </div>
          <div v-else-if="item.prop=='sph'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.xianxia.handlerinfo(row,8)}" >
            {{row.setmeal_offline_miniwecat_live-row.surplus_offline_miniwecat_live}}
          </div>
          <div v-else-if="item.prop=='surplus_offline_miniwecat_live'" >
            {{row.surplus_offline_miniwecat_live<0?'0':row.surplus_offline_miniwecat_live}}
          </div>
          <div v-else-if="item.prop=='ds'" style="color: #00a0e9;cursor: pointer;" @click="()=>{$refs.huiyi.handlerinfo(row,4)}">
            {{row.setmeal_through_train_num-row.surplus_through_train_num}}
          </div>
          <div v-else-if="item.prop=='surplus_through_train_num'" >
            {{row.surplus_through_train_num<0?'0':row.surplus_through_train_num}}
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="220">
        <template  #default="{row}">
          <el-button
            v-if="row.status==1"
            slot="reference"
            type="text"
            @click="handleredit(row)"
            size="small" >
            添加云会议次数
          </el-button>
          <el-button
            slot="reference"
            type="text"
            @click="handlerinfo(row)"
            size="small" >
            历史套餐
          </el-button>
<!--          <el-button
            slot="reference"
            type="text"
            @click="handlermate(row)"
            size="small" >
            匹配列表
          </el-button>-->
          <el-button
            v-if="row.status==1"
            slot="reference"
            type="text"
            @click="handlerfuwu(row)"
            size="small" >
            添加线下服务内容
          </el-button>
          <el-button
            v-if="row.status==1"
            slot="reference"
            type="text"
            @click="handlerstop(row)"
            size="small" >
            终止套餐
          </el-button>
          <el-button
            type="text"
            @click="()=>{formdialog.id=row.id;formdialog.value='';dialogVisible=true;formdialog.value=row.is_display_count}">
            显示开关
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <editcom ref="edit" @getlist="handlerInquire"/>
    <infocom ref="info" @getlist="handlerInquire"/>
    <matelist ref="mate" />
    <huiyilist ref="huiyi"/>
    <guanggao ref="guanggao"/>
    <yaoqing ref="yaoqing"/>
    <xianxia ref="xianxia"/>
    <dianhua ref="dianhua"/>
    <add-fu-wu ref="addfuwu" @getlist="handlerlist"/>
    <el-dialog
      title="显示开关"
      :visible.sync="dialogVisible"
      width="30%">
      <span>
        <el-form ref="formdialog" :model="formdialog"  :rules="rules">
          <el-form-item label="显示开关" prop="value">
            <el-select v-model="formdialog.value" placeholder="显示开关">
            <el-option
              label="开"
              value="1"/>
              <el-option
              label="关"
              value="0"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <p>
              关闭后，前端页面的“套餐统计”中，不显示套餐名称、套餐到期时间、各内容的剩余数量和各内容的总数量（该开关主要应用于24年9月推出的“视频直播套餐”）
            </p>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setdialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Editcom from "@/views/pages/datastatis/packagestatis/packagedata/components/editcom";
import Infocom from "@/views/pages/datastatis/packagestatis/packagedata/components/infocom";
import Matelist from "@/views/pages/datastatis/packagestatis/packagedata/components/matelist";
import Huiyilist from "@/views/pages/datastatis/packagestatis/packagedata/components/huiyilist";
import Guanggao from "@/views/pages/datastatis/packagestatis/packagedata/components/guanggao.vue";
import Yaoqing from "@/views/pages/datastatis/packagestatis/packagedata/components/yaoqing.vue";
import Xianxia from "@/views/pages/datastatis/packagestatis/packagedata/components/xianxai.vue";
import Dianhua from "@/views/pages/datastatis/packagestatis/packagedata/components/dianhua.vue";
import AddFuWu from "@/views/pages/datastatis/packagestatis/packagedata/components/addFuWu.vue";
export default {
  name: "EnterpriseCertification",
  components: { AddFuWu, Dianhua, Xianxia, Yaoqing, Guanggao, Huiyilist, Matelist, Infocom, Editcom  },
  data(){
    return {
      formdialog:{
        value:'',
        id:'',
      },
      rules:{
        value:[
          {required:true,message:'请选择显示开关',trigger:'change'}
        ]
      },
      dialogVisible:false,
      form: {
        set_meal_id:'',
        status: '',
        match_views_status:'',
        company: '',
        mall_name:'',
        referrals_status: "",
        through_train_status:'',
        inviter_name:'',
        order_invite_id:'',
        advert_status:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "店铺名称",
          prop: "mall_name",
          width: "",
        },

        {
          label: "公司名称",
          prop: "company_name",
          width: "120",
        },
        {
          label: "邀请人",
          prop: "inviter_name",
          width: "110",
        },
        {
          label: "套餐版本",
          prop: "version",
          width: "110",
        },
        {
          label: "套餐名称",
          prop: "set_meal_name",
          width: "110",
        },
        {
          label: "业务对接人",
          prop: "order_invite_id",
          width: "110",
        },
        {
          label: "套餐状态",
          prop: "status_txt",
          width: "110",
        },

        {
          label: "当前套餐开始时间",
          prop: "effective_time_txt",
          width: "",
        },
        {
          label: "当前套餐结束时间",
          prop: "failure_time_txt",
          width: "",
        },
        {
          label: "广告曝光",
          prop: "advert_status_txt",
          width: "",
        },
        {
          label: "已完成",
          prop: "ggy",
          width: "110",
        },
        {
          label: "剩余",
          prop: "gg",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_advert_exposure",
          width: "110",
        },
        {
          label: "线上匹配+电话资讯+审核样品申请",
          prop: "match_views_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "pp",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_match_views_num",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_match_views_num",
          width: "110",
        },
        {
          label: "主动邀请",
          prop: "send_invite_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "zd",
          width: "110",
        },
        {
          label: "剩余",
          prop: "zdyqsy",
          width: "110",
        },
        {
          label: "总量",
          prop: "zdyq",
          width: "110",
        },
        {
          label: "区域游学考察",
          prop: "study_tour_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "yx",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_offline_study_tour",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_offline_study_tour",
          width: "110",
        },
        {
          label: "云会议",
          prop: "referrals_status_txt",
          width: "80",
        },
        {
          label: "已完成",
          prop: "yhy",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_referrals_num",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_referrals_num",
          width: "110",
        },
        {
          label: "抖音直播",
          prop: "explosive_products_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "dy",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_offline_explosive_products",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_offline_explosive_products",
          width: "110",
        },
        {
          label: "视频号直播",
          prop: "miniwecat_live_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "sph",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_offline_miniwecat_live",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_offline_miniwecat_live",
          width: "110",
        },
        {
          label: "公众号专稿推介",
          prop: "mp_article_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "gzh",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_offline_mp_article",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_offline_mp_article",
          width: "110",
        },
        {
          label: "热品推荐视频",
          prop: "mp_video_status_txt",
          width: "110",
        },
        {
          label: "已完成",
          prop: "rmsp",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_offline_mp_video",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_offline_mp_video",
          width: "110",
        },
        {
          label: "大商直通车",
          prop: "through_train_status_txt",
          width: "",
        },
        {
          label: "已完成",
          prop: "ds",
          width: "110",
        },
        {
          label: "剩余",
          prop: "surplus_through_train_num",
          width: "110",
        },
        {
          label: "总量",
          prop: "setmeal_through_train_num",
          width: "110",
        },
      ],
      statusSelect: [//是否过期
        {
          id: 2,
          name: '已失效'
        }, {
          id: 1,
          name: '生效中'
        },{
          id: 0,
          name: '未生效'
        },{
          id: 3,
          name: '手动终止'
        },
      ],
      versionSelect:[
        {
          id: 2,
          name: '云交会第二版套餐'
        }, {
          id: 1,
          name: '云交会第一版套餐'
        },
      ],
      mealSelect: [],
      matchSelect:[//精准匹配
        {
          id: 2,
          name: '已失效'
        }, {
          id: 1,
          name: '已完成'
        },{
          id: 0,
          name: '未获得'
        },
      ],
      referralsSelect:[//1v1引荐
        {
          id: 2,
          name: '未完成'
        }, {
          id: 1,
          name: '已完成'
        },{
          id: 0,
          name: '未获得'
        },
      ],
      throughSelect:[//大商直通车
        {
          id: 2,
          name: '未完成'
        }, {
          id: 1,
          name: '已完成'
        },{
          id: 0,
          name: '未获得'
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods:{
    setdialog(){
      this.$refs.formdialog.validate(valid => {
        if (valid) {
          postAction("/mall/setmeal/change-display-count",this.formdialog).then(res=>{
            this.$message.success(res.msg)
            this.dialogVisible=false
            this.handlerlist()
          })
        }
      })
    },
    handlerfuwu(row){
      this.$refs.addfuwu.showform=row
      this.$refs.addfuwu.showedit=true
      this.$refs.addfuwu.form.mall_id=row.mall_id
    },
    /*
    * @description: 导出
    * */
    handlerExport(){
      postAction('/mall/setmeal/lists-export',this.form).then(res=>{
        if(res.request_code==200){
          this.$message.success(res.msg)
        }
      })
    },
    /*
    * @description: 套餐版本选择获取套餐
    * */
    handlerversion(val){
      getAction("/mall/index/meal-list",{version:val}).then(res=>{
        this.form.set_meal_id=''
        this.mealSelect=res.data
      })
    },
    handlerstop(row){
      this.$confirm('是否终止套餐?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
       postAction("/mall/setmeal/end",{meal_id:row.id}).then(res=>{
         this.$message({
           type: 'success',
           message: res.msg
         });
         this.handlerlist()
       })
      });

    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },

    handlerlist(){
      getAction("/mall/setmeal/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleredit(row){
      this.$refs.edit.showedit=true
      this.$refs.edit.form.mall_id=row.mall_id
      this.$refs.edit.handlerinfo()
    },
    handlerinfo(row){
      this.$refs.info.showinfo=true
      this.$refs.info.handlerinfo(row.mall_id,row.id)
    },
    handlermate(row){
      this.$refs.mate.show=true
      this.$refs.mate.form.mall_id=row.mall_id
      this.$refs.mate.handlerInquire()
    },
/*    handlerhuiyi(row){
      this.$refs.huiyi.show=true
      this.$refs.huiyi.form.mall_id=row.mall_id
      this.$refs.huiyi.handlerInquire()
    },*/
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
