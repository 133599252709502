<template>
  <el-dialog :title="title" :visible.sync="showSelection" width="900px">
    <div>
      <el-form ref="form" inline label-width="80px" :model="form">
        <el-form-item>
          <el-input v-model="form.goods_name" placeholder="产品名称" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.mall_name" placeholder="所属店铺" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerlist">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleradd">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '云会议状态'">
              <!--             -->
              <el-button
                v-show="row[item.prop] == 1"
                type="text"
                @click="handleraddcloud(row)"
              >
                待创建
              </el-button>
              <el-button
                v-show="row[item.prop] == 3 && row.ask_status == 2"
                type="text"
              >
                已完成
              </el-button>
              <el-button
                v-show="row[item.prop] == 3 && row.ask_status == 1"
                type="text"
              >
                会议已结束
              </el-button>
              <el-button
                v-show="row[item.prop] > 1"
                type="text"
                @click="handlercloudinfo(row)"
              >
                详情
              </el-button>

              <!--              <el-image
                v-show="row[item.prop]==2"
                style="width: 100px; height: 100px"
                :src="row.meeting_qr"
                :preview-src-list="[row.meeting_qr]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>-->
              <vue-qr
                v-show="row[item.prop] == 2"
                :margin="10"
                :size="100"
                :text="row.meeting_qr"
              />
              <p v-show="row[item.prop] == 2">请用企业微信扫码</p>
            </div>
            <div v-else-if="item.label == '调查问卷'">
              <el-button v-show="row[item.prop] == 2" type="text">
                已完成
              </el-button>
              <el-button
                v-show="row[item.prop] == 2"
                type="text"
                @click="handlersurveyinfo(row)"
              >
                详情
              </el-button>
              <!--              <el-image
                v-show="row[item.prop]==1"
                style="width: 100px; height: 100px"
                :src="row.ask_qr"
                :preview-src-list="[row.ask_qr]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>-->
              <vue-qr
                v-show="row[item.prop] == 1"
                :margin="10"
                :size="100"
                :text="row.ask_qr"
              />
              <p v-show="row[item.prop] == 1">请用企业微信扫码</p>
            </div>
            <div v-else-if="item.label == '云会议是否有效'">
              {{ row[item.prop] == 1 ? '有效' : '无效' }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="160">
          <template #default="{ row }">
            <div>
              <el-button
                v-show="row.need_connect_status != 1"
                size="small"
                type="text"
                @click="handlerreedit(row)"
              >
                编辑
              </el-button>
              <el-button
                v-show="row.need_connect_status != 1"
                size="small"
                type="text"
                @click="handlerrefuse(row)"
              >
                删除
              </el-button>
              <el-popconfirm
                confirm-button-text="设置无效"
                title="设置会议无效"
                @confirm="handlerfurm(row)"
              >
                <el-button
                  v-show="row.is_valid == 1"
                  style="margin-left: 10px"
                  type="text"
                  slot="reference"
                >
                  云会议状态
                </el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <add-select ref="add" @getlist="handlerlist" />
      <add-cloud ref="cloud" @getlist="handlerlist" />
      <add-cloudinfo ref="cloudinfo" @getlist="handlerlist" />
      <surveyinfo ref="survey" @getlist="handlerlist" />
      <invaildreason
        ref="invaildreason"
        :rowid="rowid"
        @getlist="handlerlist"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showSelection = false">取 消</el-button>
      <el-button type="primary" @click="showSelection = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'
  import AddSelect from '@/views/pages/review/clouddemand/components/AddSelect'
  import AddCloud from '@/views/pages/review/clouddemand/components/AddCloud'
  import AddCloudinfo from '@/views/pages/review/clouddemand/components/AddCloudinfo'
  import surveyinfo from '@/views/pages/review/clouddemand/components/surveyinfo'
  import invaildreason from '@/views/pages/review/clouddemand/components/InvaildReason'
  import vueQr from 'vue-qr'
  export default {
    name: 'Selection',
    components: {
      AddCloud,
      AddSelect,
      vueQr,
      AddCloudinfo,
      surveyinfo,
      invaildreason,
    },
    data() {
      return {
        showSelection: false,
        title: '',
        form: {
          biz_clue_id: '',
          goods_name: '',
          mall_name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '产品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '所属店铺',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '排序',
            prop: 'sort',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            label: '选品状态',
            prop: 'need_connect_status_text',
            width: '',
          },
          {
            label: '云会议状态',
            prop: 'meeting_status', //0是该选品方案还未确认，1是选品方案确认了但还没创建云会议,2是创建云会议了但是会议结束时间还没到, 3是会议结束时间过了
            width: '140',
          },
          {
            label: '调查问卷',
            prop: 'ask_status',
            width: '140',
          },
          {
            label: '云会议是否有效',
            prop: 'is_valid',
            width: '100',
          },
          {
            label: '无效原因',
            prop: 'invalid_reason',
            width: '',
          },
        ],
        total: 0,
        validreason: '',
        rowid: '',
      }
    },
    watch: {
      showSelection(val) {
        if (!val) {
          this.$emit('getlist')
        }
        this.form.goods_name = ''
        this.form.mall_name = ''
      },
    },
    methods: {
      // 设置有效or无效
      setinvalid(row) {
        console.log('设置有效无效', row)
        // let tiptext =
        //   row.meeting_valid == 1
        //     ? '是否确认设置为无效？'
        //     : '是否确认设置为有效？'
        // this.$confirm(tiptext, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // }).then(() => {
        //   postAction('/audit/biz-clue/set-unvalid', { id: row.id }).then(
        //     (res) => {}
        //   )
        // })
      },
      handlerfurm(row) {
        this.showValid = true
        this.$refs.invaildreason.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.invaildreason.showValid = true
        // postAction('/audit/biz-clue/set-unvalid', {
        //   id: row.id,
        //   reason: this.validreason,
        // }).then((res) => {
        //   this.$message({
        //     type: 'success',
        //     message: res.msg,
        //   })
        //   this.handlerlist()
        // })
      },
      handleraddcloud(row) {
        this.$refs.cloud.id = row.id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloud.showcloud = true
      },
      //查看云会议详情
      handlercloudinfo(row) {
        this.$refs.cloudinfo.form.id = row.meeting_id
        // this.$refs.cloud.hanlderinfo()
        this.$refs.cloudinfo.showcloud = true
      },
      //查看调查问卷详情
      handlersurveyinfo(row) {
        this.$refs.survey.showinfo = true
        this.$refs.survey.surveyinfo = row
      },
      handlerreedit(row) {
        this.$refs.add.showedit = true
        // this.$refs.add.handlerinfo(row)
        this.$refs.add.form = {
          id: this.form.biz_clue_id,
          mall_id: row.mall_id,
          plan_id: row.id,
          goods_id: row.goods_id,
          mall_name: row.mall_name,
          goods_name: row.goods_name,
          sort: row.sort,
          status: row.status,
        }
        this.$refs.add.mall_name = row.mall_name
        this.$refs.add.title = '编辑'
      },
      handlerrefuse(row) {
        postAction('/audit/biz-clue/del-plan', { id: row.id }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handleradd() {
        this.$refs.add.showedit = true
        this.$refs.add.form.id = this.form.biz_clue_id
      },
      handlerlist() {
        postAction('/audit/biz-clue/plan-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
