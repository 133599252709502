<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.name" clearable placeholder="海报名称" />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.type_id"
          clearable
          filterable
          placeholder="海报分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  export default {
    name: 'Index',
    data() {
      return {
        form: {
          name: '',
          start_date: '',
          end_date: '',
          type_id: '',
          page: 1,
          limit: 10,
        },
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '海报名称',
            prop: 'name',
            width: '',
          },

          {
            label: '海报分类',
            prop: 'htyun_name',
            width: '',
          },

          {
            label: '海报背景',
            prop: 'bg_image',
            width: '',
          },
          {
            label: '生成次数',
            prop: 'nums',
            width: '',
          },
        ],
        staffSelect: [],
        total: 0,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        const staff = await getAction('/api/poster/type/select')
        this.staffSelect = staff.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/api/statistics/buried-point/poster', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
