<template>
  <div>
    <el-dialog
      :title="editTitle"
      :visible.sync="is_show"
      width="1000px"
      :before-close="handleClose"
    >
      <div class="edit">
        <p class="title">{{ title }}</p>
        <el-form class="form" ref="form" :model="form" label-width="80px">
          <el-form-item label="类型" prop="type">
            <el-select
              v-model="form.data.is_single"
              placeholder="请选择题目类型"
              @change="selectChange"
            >
              <el-option
                v-for="item in typelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题目" prop="type">
            <el-input
              style="width: 80%"
              v-model="form.data.title"
              placeholder="请输入问题"
            ></el-input>
          </el-form-item>

          <el-form-item
            label=""
            prop="type"
            v-if="form.data.is_single == 1 || form.data.is_single == 2"
          >
            <p
              class="answer"
              v-for="(item, index) in form.data.answer"
              :key="index"
            >
              <el-form-item label="二级题目" prop="type">
                <el-input
                  style="width: 100%"
                  v-model="item.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="答案" prop="type">
                <el-input
                  style="width: 100%"
                  v-model="item.reply"
                  placeholder="请输入答案"
                ></el-input>
              </el-form-item>
              <e
                class="icon el-icon-circle-plus-outline"
                @click="addanswer()"
                v-if="index == 0"
              ></e>
              <e
                class="icon el-icon-remove-outline"
                @click="plusanswer(index)"
                v-if="index != 0"
              ></e>
            </p>
          </el-form-item>
          <p
            class="tips"
            v-if="form.data.is_single == 0 && form.data.is_single != ''"
          >
            导入品类库品类
          </p>
        </el-form>
        <p style="margin-top: 60px; text-align: center">
          <el-button size="medium" type="primary" @click="handleClose">
            取消
          </el-button>
          <el-button size="medium" type="primary" @click="submit">
            确定
          </el-button>
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'editquestion',
    components: {
      // UploadImg,
    },
    data() {
      return {
        is_show: false,
        editTitle: '新增题目',
        title: '2024春季糖酒会有奖调查',
        typelist: [
          {
            id: '1',
            name: '单选',
          },
          {
            id: '2',
            name: '多选',
          },
          {
            id: '3',
            name: '文本框',
          },
          {
            id: 0,
            name: '选品题',
          },
        ],
        typeIndex: '',
        form: {
          conf_id: '',
          data: {
            title: '',
            id: '',
            is_single: '',
            answer: [
              {
                id: '',
                reply: '',
              },
            ],
          },
        },
      }
    },
    watch: {},
    mounted() {},
    methods: {
      handleClose() {
        this.is_show = false
        this.$refs['form'].resetFields()
      },
      addanswer() {
        this.form.data.answer.push({
          id: '',
          reply: '',
          title: this.form.data.answer[0].title,
        })
      },
      plusanswer(index) {
        this.form.data.answer.splice(index, 1)
      },
      getinfo(id) {
        // 获取详情
        let that = this
        postAction('/api/system/research/question-contact', {
          conf_id: id,
        }).then((res) => {
          if (res.code == 0) {
            var d = res.data
            var list = []
            for (var i = 0; i < d.length; i++) {
              var data = d[i].answer_list
              list.push(d[i])
              for (var m = 0; m < data.length; m++) {
                list.push(data[m])
              }
            }
            that.list = list
            console.log(list)
          }
        })
      },
      submit() {
        let that = this
        console.log(that.form.data.is_single)
        if (that.form.data.is_single == '' && that.form.data.is_single != 0) {
          that.$message.error('请选择题目类型')
          return
        }
        if (that.form.data.title == '') {
          that.$message.error('请输入题目')
          return
        }
        if (
          that.form.data.is_single == '1' ||
          that.form.data.is_single == '2'
        ) {
          for (var i = 0; i < that.form.data.answer.length; i++) {
            if (that.form.data.answer[i].reply == '') {
              that.$message.error('请输入答案')
              return
            }
          }
        }

        // console.log(that.form)
        var form = JSON.stringify(that.form)
        form = JSON.parse(form)
        if (form.data.is_single == 0 || form.data.is_single == 3) {
          form.data.answer = []
        }
        form.data = JSON.stringify(form.data)
        console.log(form)
        // return
        postAction('/api/system/research/question-edit', form)
          .then((res) => {
            if (res.code == 0) {
              that.$message({
                message: '操作成功',
                type: 'success',
              })
              this.$emit('getinfo')
              that.is_show = false
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((res) => {})
      },
    },
  }
</script>

<style lang="scss" scoped>
  :deep() .el-dialog__body {
    padding-top: 0;
  }
  .edit .title {
    text-align: center;
    font-size: 20px;
  }
  .form {
    width: 90%;
    margin: 40px auto;
    padding: 20px 0;
    border-radius: 10px;
    background: rgb(241, 241, 241);
    border: 1px solid rgb(187, 187, 187);
  }
  .form .icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .answer {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 10px;
  }
  .tips {
    color: red;
    font-size: 12px;
    margin-left: 80px;
  }
</style>
