var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showAddstore,
        width: "450px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showAddstore = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "130px" },
        },
        [
          _vm.title == "栏目设置——选择主题活动"
            ? _c(
                "el-form-item",
                { attrs: { label: "选择主题活动", prop: "theme_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "主题活动",
                        filterable: "",
                        clearable: "",
                      },
                      on: { change: _vm.handlertheme },
                      model: {
                        value: _vm.form.theme_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "theme_id", $$v)
                        },
                        expression: "form.theme_id",
                      },
                    },
                    _vm._l(_vm.product, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm.title == "栏目设置——选择政策大放送"
            ? _c(
                "el-form-item",
                { attrs: { label: "选择政策大放送", prop: "policy_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "政策大放送",
                        filterable: "",
                        clearable: "",
                      },
                      on: { change: _vm.handlerpolicy },
                      model: {
                        value: _vm.form.policy_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "policy_id", $$v)
                        },
                        expression: "form.policy_id",
                      },
                    },
                    _vm._l(_vm.malllist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "关联栏目", prop: "menu_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "政策大放送",
                    filterable: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.menu_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "menu_id", $$v)
                    },
                    expression: "form.menu_id",
                  },
                },
                _vm._l(_vm.menulist, function (item) {
                  return _c("el-option", {
                    key: item.menu_id,
                    attrs: { label: item.menu_name, value: item.menu_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", [
            _vm.title == "栏目设置——选择主题活动"
              ? _c("p", [
                  _vm._v(
                    "默认使用主题活动中的列表排序，修改顺序请至主题活动修改"
                  ),
                ])
              : _c("p", [
                  _vm._v(
                    "默认使用政策大放送中的列表排序，修改顺序请至主题活动修改"
                  ),
                ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showAddstore = !_vm.showAddstore
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }