<template>
  <el-dialog v-if="showstore" :title="form.type==1?'栏目设置选择产品':'栏目设置选择店铺'" :visible.sync="showstore"  width="60%" :append-to-body="true">
    <el-form :inline="true" :model="form" >
      <el-form-item :label="form.type==1?'产品名称':'店铺名称'">
        <el-input placeholder="" v-model="form.keyword" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tabledata"
      border
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template slot="header" >
          <div v-if="item.prop=='name'">
            {{form.type==1?'产品':'店铺'}}
          </div>
          <div v-else>
            {{item.label}}
          </div>
        </template>
        <template #default="{ row }">

          <div v-if="item.prop == 'title_pic'">
            <el-image
              style="width: 150px; height: 30px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
            </el-image>
          </div>
          <div v-else>
            <p>{{ row[item.prop] }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120px" fixed="right" >
        <template #default="{ row }">
          <el-button type="text" @click="handleredit(row)">编辑</el-button>
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handlerdelet(row)"
          >
            <el-button style="margin:0px 10px" slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="row-bg" justify="end">
      <el-pagination
        :current-page="form.page"
        :page-size="form.limit"
        background
        small
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-row>
    <AddStore ref="addstore" @getlist="handlerInquire" :title="title"/>
  </el-dialog>
</template>

<script>
import AddStore from "./AddStore"
import { getAction, postAction } from "@/api/request";

export default {
  name: "ProductStore",
  components:{
    AddStore
  },
  data(){
    return{
      showstore:false,
      title:"",
      form:{
        area_id:null,
        column_id:null,
        type:2,
        page:1,
        limit:10,
      },
      tabledata:[],
      columns: [
        {
          label: "ID",
          prop: "id",
          width: "60px",
        },
        {
          label: "产品",
          prop: "name",
          width: "",
        },
        {
          label: "排序",
          prop: "sort_order",
          width: "",
        }
      ],
      total:0
    }
  },
  methods:{

    handlerdelet(row){
      postAction("/live/index/del-areamallgoods",{id:row.id}).then(res=>{
        this.$message.success(res.msg)
        this.handlerlist()
      })

    },
    handlerAdd(){
      console.log("新增")
      this.title="新增"
      this.$refs.addstore.form.type= this.form.type
      this.$refs.addstore.form.area_id=this.form.area_id
      this.$refs.addstore.form.column_id=this.form.column_id
      this.$refs.addstore.showAddstore=true
    },
    handleredit(row){
      this.title="编辑"
      this.$refs.addstore.form.id=row.id
      this.$refs.addstore.handlerinit(row)
      this.$refs.addstore.form.type= this.form.type
      this.$refs.addstore.form.area_id=this.form.area_id
      this.$refs.addstore.form.column_id=this.form.column_id
      this.$refs.addstore.showAddstore=true
    },
    handlerlist(){
      getAction("/live/index/area-mallgoods",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
        this.loading=false
      })
    },

    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
}
</script>

<style scoped>

</style>
