<!--溢缴款管理-->
<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="账号">
        <el-input
          v-model="form.dyanbr"
          clearable
          placeholder="账号"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            slot="reference"
            size="small"
            type="text"
            @click="handlerback(row)"
          >
            全部退款
          </el-button>
          <el-button
            slot="reference"
            size="small"
            type="text"
            @click="handlerinfo(row)"
          >
            查看明细
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <info ref="info" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import info from '@/views/pages/datastatis/moremoney/components/info'
  export default {
    name: 'EnterpriseCertification',
    components: { info },
    data() {
      return {
        form: {
          company_name: '',
          dyanbr: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '账号',
            prop: 'dyanbr',
            width: '110',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },

          {
            label: '主管理员手机号',
            prop: 'mobile',
            width: '110',
          },

          {
            label: '溢缴款',
            prop: 'last_money',
            width: '80',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      // this.handlerSelect()
      this.handlerInquire()
    },
    methods: {
      /* handlerSelect(){
      getAction("/audit/identity/identity",).then(res=>{
        this.identitySelect=res.data
      })
    },*/
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },

      handlerlist() {
        getAction('/mall/more-money/list', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerback(row) {
        this.$confirm('确认全部退款？该操作不可撤销，请谨慎操作', '全部退款', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/mall/more-money/back', { mall_id: row.mall_id }).then(
              (res) => {
                if (res.request_code == 200) {
                  this.$message({
                    type: 'success',
                    message: res.msg,
                  })
                }
              }
            )
          })
          .catch(() => {})
      },
      handlerinfo(row) {
        this.$refs.info.showinfo = true
        this.$refs.info.handlerinfo(row.mall_id)
      },

      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
