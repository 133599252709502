var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.form.corp_name,
        visible: _vm.showbusiness,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showbusiness = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                [
                  _c("h5", [_vm._v(_vm._s(_vm.form.base.name))]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.form.base.data, "show-header": false },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "name", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "val", label: "val", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.type == "image"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: {
                                              src: row.val,
                                              "preview-src-list": [row.val],
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "placeholder" },
                                                slot: "placeholder",
                                              },
                                              [
                                                _vm._v(" 加载中"),
                                                _c(
                                                  "span",
                                                  { staticClass: "dot" },
                                                  [_vm._v("...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(" " + _vm._s(row.val) + " "),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("h5", [_vm._v(_vm._s(_vm.form.original_name.name))]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.form.original_name.data,
                        "show-header": false,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name_name", label: "name", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name_val", label: "val", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "date_name", label: "name", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "date_val", label: "val", width: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("h5", [_vm._v(_vm._s(_vm.form.revoke_info.name))]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.form.revoke_info.data,
                        "show-header": false,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "name", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "val", label: "val", width: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("h5", [_vm._v(_vm._s(_vm.form.area.name))]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.form.area.data, "show-header": false },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "name", width: "" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "val", label: "val", width: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showbusiness = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showbusiness = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }