var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "关联数据",
        visible: _vm.secondaryshow,
        width: "70%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.secondaryshow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-suffix": ":", inline: "" },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "名称", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查 询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerAdd },
                    },
                    [_vm._v("新 增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            [
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            item.label == "底部logo"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "70px",
                                          height: "70px",
                                        },
                                        attrs: {
                                          src: row[item.prop],
                                          "preview-src-list": [row[item.prop]],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中"),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "小程序分享图"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "70px",
                                          height: "70px",
                                        },
                                        attrs: {
                                          src: row[item.prop],
                                          "preview-src-list": [row[item.prop]],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中"),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "H5分享图"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "70px",
                                          height: "70px",
                                        },
                                        attrs: {
                                          src: row[item.prop],
                                          "preview-src-list": [row[item.prop]],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中"),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: "center",
                  width: "280",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handlerdelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handlersecondary(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.form.page,
              layout: _vm.layout,
              "page-size": _vm.form.limit,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加",
                visible: _vm.showadd,
                width: "300px",
                "append-to-body": "",
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showadd = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.formtow,
                        rules: _vm.rules,
                        "label-width": "80px",
                      },
                    },
                    [
                      _vm.type == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择产品", prop: "data_id" } },
                            [
                              _c("el-autocomplete", {
                                attrs: {
                                  clearable: "",
                                  "fetch-suggestions": _vm.handlergoods,
                                  "value-key": "goods_name",
                                  placeholder: "请输入商品名称",
                                },
                                on: { select: _vm.handleSelectgoods },
                                model: {
                                  value: _vm.formtow.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formtow, "name", $$v)
                                  },
                                  expression: "formtow.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.type == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择店铺", prop: "data_id" } },
                            [
                              _c("el-autocomplete", {
                                attrs: {
                                  clearable: "",
                                  "fetch-suggestions": _vm.handlermell,
                                  "value-key": "mall_name",
                                  placeholder: "请输入店铺名称",
                                },
                                on: { select: _vm.handleSelectmall },
                                model: {
                                  value: _vm.formtow.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formtow, "name", $$v)
                                  },
                                  expression: "formtow.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "sort_order" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.formtow.sort_order,
                              callback: function ($$v) {
                                _vm.$set(_vm.formtow, "sort_order", $$v)
                              },
                              expression: "formtow.sort_order",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showadd = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersave },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.secondaryshow = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.secondaryshow = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }