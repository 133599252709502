<template>
  <el-dialog  :title="title"
              :visible.sync="showAddstore"
              width="350px"
              :append-to-body="true"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="选择产品" prop="goods_id" v-if="form.type==1">
        <el-autocomplete
          v-model="form.goods_name"
          clearable
          :fetch-suggestions="handlergoods"
          value-key="goods_name"
          placeholder="请输入商品名称"
          @select="handleSelectgoods"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="选择企业" prop="mall_id" v-else-if="form.type==2">
        <el-autocomplete
          v-model="form.mall_name"
          clearable
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          placeholder="请输入企业名称"
          @select="handleSelectmall"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="状态"  prop="policy">
        <el-select v-model="form.is_check" clearable style="width: 220px;" placeholder="状态">
          <el-option v-for="list in shopSelect"
                     :key="list.id"
                     :value="list.id"
                     :label="list.name"/>
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sort_order" >
        <el-input-number v-model="form.sort" style="width: 220px;" :min="0"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer center">
      <el-button @click="showAddstore=!showAddstore">取消</el-button>
      <el-button type="primary" @click="handlersave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";
export default {
  name: "comedit",
  props:{
    title:{
      type:String,
      default:()=>{
        return "新增"
      }
    },
  },
  data(){
    var handlerobject=(rule, value, callback)=>{
      if(this.form.type==1){
        if(!value){
          callback(new Error("请选择产品"))
        }else{
          callback()
        }
      }else{
        if(!value){
          callback(new Error("请选择店铺"))
        }else{
          callback()
        }
      }
    };
    return{
      showAddstore:false,
      form:{
        house_id:'',
        goods_id:"",
        mall_id:"",
        goods_name:'',
        mall_name:'',
        id	:"",
        type	:"",
        is_check	:"",
        sort:'',
      },
      shopSelect: [
        {
          id: '1',
          name: '启用'
        }, {
          id: '0',
          name: '禁用'
        }
      ],
      malllist:[],
      product:[],
      rules:{
        goods_id:[
          { required: true,validator:handlerobject,  trigger: 'change' }
        ],
        mall_id:[
          { required: true,validator:handlerobject,  trigger: 'change' }
        ]
      }
    }
  },
  watch:{
    showAddstore(old){
      if(!old){
        this.$refs.form.resetFields()
        this.form={
          house_id:'',
          goods_id:'',
          mall_id:'',
          goods_name:'',
          mall_name:'',
          id	:"",
          type	:"",
          is_check	:"",
          sort:'',
        }
      }
    }
  },
  methods:{
    show(){
      this.showAddstore=true
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_name=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.goods_name=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.goods_id=list.id
    },
    handlersave(){
      let url
      this.form.type==1?url="/api/system/house/goods-edit":url="/api/system/house/mall-edit"
      this.$refs['form'].validate((valid) => {
        if (valid) {
          getAction(url, this.form).then(res => {
            this.$message.success(res.msg)
            this.showAddstore = false
            this.$emit("getlist")
          })
        }
      })
    },
    handlerinit(row){
      this.form = Object.assign(this.form,row)
    }
  }
}
</script>

<style scoped>

</style>
