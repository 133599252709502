<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="订单状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in typeselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="商品名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_pid"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.cat_id"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.user_name"
          clearable
          placeholder="联系人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="联系电话"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.province_id"
          clearable
          placeholder="省"
          style="width: 160px"
          @change="handleProChange"
        >
          <el-option
            v-for="list in areaselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.city_id"
          clearable
          placeholder="市"
          style="width: 160px"
          @change="handlerCity"
        >
          <el-option
            v-for="list in city"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.is_free_mall" clearable style="width: 160px;" placeholder="审核方">
          <el-option
            value="1"
            label="平台"/>
          <el-option
            value="0"
            label="企业"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 260px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '用户行业'">
            <p v-for="list in row.user[item.prop]">{{ list.cat_name }}</p>
          </div>
          <div v-else-if="item.label == '用户渠道'">
            <p v-for="list in row.user[item.prop]">{{ list.cat_name }}</p>
          </div>
          <div v-else-if="item.label == '营业执照'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '代理店铺'">
            <!--            {{row.mall.mall_name}}-->
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  // import * as excel from "@/utils/excel";

  export default {
    name: 'Index',
    data() {
      return {
        form: {
          user_name: '',
          mobile: '',
          name: '',
          province_id: '',
          city_id:'',
          is_free_mall:'',
          start_time: '',
          end_time: '',
          mall_name: '',
          status: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        catselect: [],
        cateselect: [],
        columns: [
          {
            label: '编号',
            prop: 'id',
            width: '110',
          },
          {
            label: '审核方',
            prop: 'is_free_mall_txt',
            width: '',
          },
          {
            label: '订单号',
            prop: 'order_sn',
            width: '220px',
          },
          {
            label: '店铺',
            prop: 'mall_name',
            width: '120',
          },
          {
            label: '商品名称',
            prop: 'goods_name',
            width: '80',
          },
          {
            label: '一级品类',
            prop: 'cat_pname',
            width: '120',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '120',
          },
          {
            label: '联系人',
            prop: 'user_name',
            width: '110',
          },
          {
            label: '联系电话',
            prop: 'mobile',
            width: '110',
          },
          {
            label: '省',
            prop: 'province_txt',
            width: '160',
          },
          {
            label: '市',
            prop: 'city_txt',
            width: '120',
          },
          {
            label: '区',
            prop: 'district_txt',
            width: '80',
          },
          {
            label: '用户身份',
            prop: 'cert',
            width: '160',
          },
          {
            label: '用户行业',
            prop: 'trade',
            width: '140',
          },
          {
            label: '用户渠道',
            prop: 'channel',
            width: '110',
          },
          {
            label: '代理产品',
            prop: 'daili',
            width: '160',
          },
          {
            label: '主销渠道',
            prop: 'qudao',
            width: '120',
          },
          {
            label: '申请时间',
            prop: 'created_txt',
            width: '80',
          },
          {
            label: '营业执照',
            prop: 'license',
            width: '160',
          },
          {
            label: '订单状态',
            prop: 'status_txt',
            width: '140',
          },
        ],
        total: 0,
        mallselect: [],
        goodsselect: [],
        typeselect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '待支付',
          },
          {
            id: 2,
            name: '已支付',
          },
          {
            id: 3,
            name: '已取消',
          },
          {
            id: 4,
            name: '已发货',
          },
          {
            id: 5,
            name: '已收货',
          },
          {
            id: 6,
            name: '退货中',
          },
          {
            id: 7,
            name: '已退货',
          },
          {
            id: 8,
            name: '待审核',
          },
          {
            id: 9,
            name: '审核未通过',
          },
        ],
        areaselect: [],
        city:[],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    async mounted() {
      if(this.$route.query){
        this.form.mall_name=this.$route.query.mall_name
      }
      await this.handlerInquire()
      await this.handlerSelect()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      async handlerSelect() {
        const { data } = await getAction('/user/index/area', { pid: 0 })
        this.areaselect = data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      // 获取市
      async getCityList(e) {
        const citydata = await getAction('/user/index/area', { pid: e })
        this.city = citydata.data
      },
      handleProChange(e){
        this.form.city_id = ''
        if(e!=''){
          this.getCityList(e)
        }
      },
      handlerExport() {
        getAction('/mall/product/export-sample', this.form).then((res) => {
          console.log(res)
          import('@/utils/excel').then((excel) => {
            // const data = this.formatJson(res.data.key, res.data.list)
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: '样品申请',
            })
            // this.downloadLoading = false
          })
          // excel.export_json_to_excel({
          //   header: res.data.header,
          //   data:res.data.list,
          //   filename: "样品申请",
          // })
        })
      },
      // formatJson(filterVal, jsonData) {
      //   console.log(jsonData.map((v) => filterVal.map((j) => v[j])))
      //   return jsonData.map((v) => filterVal.map((j) => v[j]))
      // },
      handlerlist() {
        getAction('/mall/product/sample-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
