<template>
<div>
  <el-dialog
    :title="title"
    :visible.sync="goodsmall"
    width="80%">
    <div>
      <el-form ref="form" :model="form" inline>
        <el-form-item v-if="form.type==1">
          <el-autocomplete
            v-model="form.name"
            clearable
            :fetch-suggestions="handlergoods"
            value-key="goods_name"
            placeholder="请输入商品名称"
            @select="handleSelectgoods"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item v-else-if="form.type==2">
          <el-autocomplete
            v-model="form.name"
            clearable
            :fetch-suggestions="handlermell"
            value-key="mall_name"
            placeholder="请输入店铺名称"
            @select="handleSelectmall"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handleradd">新 增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        border
        :data="tabledata"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{row}">
            <div v-if="item.label=='名称'">
              <p v-if="form.type==1">{{row.goods_name}}</p>
              <p v-else>{{row.mall_name}}</p>
            </div>
            <div  v-else-if="item.label=='状态'">
              <p v-if="row[item.prop]==1">开启</p>
              <p v-else>禁用</p>
            </div>
            <div v-else>{{row[item.prop]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="220">
          <template  #default="{row}">

            <el-popconfirm
              title="确认删除？"
              @confirm="handlerdelete(row)"
            >
              <el-button
                style="margin: 10px;"
                slot="reference"
                type="text"
                size="small">
                删除
              </el-button>
            </el-popconfirm>
            <el-button
              @click.native.prevent="handleredit(row)"
              type="text"
              size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <comedit ref="edit" :title="titleedit" @getlist="handlerInquire"/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="goodsmall = false">取 消</el-button>
      <el-button type="primary" @click="goodsmall = false">确 定</el-button>
    </span>

  </el-dialog>

</div>
</template>

<script>
import { getAction } from "@/api/request";
import Comedit from "@/views/pages/systemSet/characteristic/components/edit";

export default {
  name: "AddGoods",
  components: { Comedit },
  data(){
    return{
      goodsmall:false,
      form:{
        house_id:'',
        goods_id:'',
        mall_id:'',
        type:'',
        name:'',
        page: 1,
        limit: 10
      },
      title:"添加产品",
      titleedit:'添加',
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],

      columns: [
        {
          label: "展馆id",
          prop: "house_id",
          width: "80",
        },

        {
          label: "名称",
          prop: "house_name",
          width: "",
        },
        {
          label: "状态",
          prop: "is_check",
          width: "80",
        },
        {
          label: "排序",
          prop: "sort",
          width: "",
        },
      ],
      total: 0,
    }
  },
  watch:{
    'form.type'(val){
      if(val==1){
        this.title='添加产品'
      }else {
        this.title='添加企业'
      }
    },
    goodsmall(val){
      if(!val){
        this.form={
          house_id:'',
          goods_id:'',
          mall_id:'',
          type:'',
          name:'',
          page: 1,
          limit: 10
        }
      }
    }
  },
  methods:{
    handleradd(){
      this.$refs.edit.form.type=this.form.type
      this.$refs.edit.form.house_id=this.form.house_id
      this.$refs.edit.show()
      this.titleedit="添加"
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.goods_id=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.goods_id=list.id
    },
    handlerdelete(row){
      let url
      this.form.type==1?url="/api/system/house/goods-delete":url="/api/system/house/mall-delete"
      getAction(url,{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },

    async handleredit(row){
      this.$refs.edit.form.type=this.form.type
      this.$refs.edit.form.house_id=this.form.house_id
      this.$refs.edit.handlerinfo(row)
      this.$refs.edit.show()
      this.titleedit="编辑"
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerlist(){
      let url
      this.form.type==1?url="/api/system/house/goods-lists":url="/api/system/house/mall-lists"
      getAction(url,this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
