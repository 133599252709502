<template>
  <div class="index-container">
    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '备注'">
            <el-input
              v-model="row[item.prop]"
              @blur="onsave(row)"
              width="180px"
            />
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    data() {
      return {
        form: {
          is_lock: '',
          call_status: '',
          end_time: '',
          start_time: '',
          page: 1,
          limit: 10,
        },
        timelist: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '时间',
            prop: 'date',
            width: '220px',
          },
          {
            label: '发送条数',
            prop: 'total_num',
            width: '220px',
          },
          {
            label: '已接通',
            prop: 'success_num',
            width: '220px',
          },
          {
            label: '发送接通率%',
            prop: 'success_percent',
            width: '220px',
          },
          {
            label: '短信唤回过渡页PV',
            prop: 'middle_pv',
            width: '220px',
          },
          {
            label: '唤回率',
            prop: 'sms_percent',
            width: '',
          },
          {
            label: '小程序首页PV',
            prop: 'index_pv',
            width: '',
          },
          {
            label: '店铺页PV',
            prop: 'shop_pv',
            width: '',
          },
          {
            label: '备注',
            prop: 'remark',
            width: '220px',
          },
        ],
        total: 0,
        sum: 0, //合计
        spaceSelect: [],
        calstatus: [], //外呼状态列表
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      onsave(row) {
        console.log('测试----------', row)

        getAction('/api/form/form/out-total-remark', {
          id: row.id,
          remark: row.remark,
        }).then((res) => {
          console.log('接口返回', res)
          if (res.code == 0) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '时间') {
            sums[index] = '共计'
          }
          if (column.label === '发送条数') {
            sums[index] = this.sum.total_num
          }
          if (column.label === '已接通') {
            sums[index] = this.sum.success_num
          }
          if (column.label === '短信唤回过渡页PV') {
            sums[index] = this.sum.middle_pv
          }
          if (column.label === '小程序首页PV') {
            sums[index] = this.sum.index_pv
          }
          if (column.label === '店铺页PV') {
            sums[index] = this.sum.shop_pv
          }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },

      handlerlist() {
        getAction('/api/form/form/out-total-tj', this.form).then((res) => {
          this.tabledata = res.data.list
          this.sum = res.data.sum
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
