var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { content: "", visible: _vm.showEditPoster, width: "400px" },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, inline: "", "label-width": "110px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "栏目名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名称字数5个以内", maxlength: "5" },
                    model: {
                      value: _vm.form.type_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type_name", $$v)
                      },
                      expression: "form.type_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort_order" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "数字越大，栏目越靠前显示" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "1",
                      "inactive-value": "0",
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditPoster = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }