<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
       <el-input v-model="form.report_title" clearable placeholder="报告标题" style="width: 160px"/>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.report_type"
          clearable
          filterable
          placeholder="报告类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template slot="header" slot-scope="scope">
          <div slot="header" v-if="item.label=='总访问次数'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">用户访问报告的次数，同一用户访问N次记录N次，即PV数<br/>总访问次数 = 所有邀请人邀请来的PV之和</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else-if="item.label=='总访问人数'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">访问报告的用户人数，同一用户访问N次只记录1人，即UV数<br/>总访问人数（去重数据） ≠ 所有邀请人邀请来的UV之和</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else>
            {{item.label}}
          </div>
        </template>
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '操作'">
            <el-button type="text" size="small" @click="handlerinfo(row)">查看详情</el-button>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <report-info  ref="info"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import dayjs from "dayjs";
import ReportInfo from "@/views/pages/datastatis/Reportstatic/components/ReportInfo.vue";
export default {
  name: 'Index',
  components: { ReportInfo },
  data() {
    return {
      form: {
        report_title:'',
        report_type:'',
        start_date: dayjs(new Date().getTime()-3600*1000*24*30).format('YYYY-MM-DD'),
        end_date: dayjs(new Date().getTime()).format('YYYY-MM-DD'),
        page: 1,
        limit: 10,
      },
      time: [dayjs(new Date().getTime()-3600*1000*24*30).format('YYYY-MM-DD'),dayjs(new Date().getTime()).format('YYYY-MM-DD')],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '报告ID',
          prop: 'info_id',
          width: '',
        },
        {
          label: '报告标题',
          prop: 'info_title',
          width: '',
        },
        {
          label: '类型',
          prop: 'report_type',
          width: '',
        },
        {
          label: '总访问次数',
          prop: 'pv',
          width: '',
        },
        {
          label: '总访问人数',
          prop: 'uv',
          width: '',
        },
        {
          label: '分享好友数',
          prop: 'friend_num',
          width: '',
        },
        {
          label: '分享朋友圈数',
          prop: 'circle_num',
          width: '',
        },
        {
          label: '下载海报数',
          prop: 'save_num',
          width: '',
        },
        {
          label: '平台内跳转渠道',
          prop: 'pv1',
          width: '',
        },
        {
          label: '好友渠道',
          prop: 'pv6',
          width: '',
        },
        {
          label: '朋友圈渠道',
          prop: 'pv7',
          width: '',
        },
        {
          label: '海报渠道',
          prop: 'pv5',
          width: '',
        },
        {
          label: '其他渠道',
          prop: 'pv8',
          width: '',
        },
        {
          label: '操作',
          prop: 'pv8',
          width: '',
        },
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
  },
  methods: {
    handlerinfo(row){
      this.$refs.info.form.id=row.info_id
      this.$refs.info.dialogVisible = true
      this.$refs.info.handlerInquire()
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
     handlerSelect(){
       getAction("/api/system/set-plan-report/type").then(res=>{
         this.staffSelect=res.data
       })


    },
    handlerlist() {
      getAction('/api/system/set-plan-report/tj', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      getAction('/api/system/set-plan-report/tj-export', { ...this.form }).then(
        (res) => {
          if (res.request_code == 200){
            this.$message({
              type: 'success',
              message: '已添加导出任务',
            })
          }
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
