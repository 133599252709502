<template>
  <div class="index-container">
    <el-row type="flex" justify="center">
      <el-col :span="24" style="text-align: center">
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-date-picker
            v-model="form.time"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerecharts">查询</el-button>
        </el-form-item>
      </el-form>
      </el-col>

    </el-row>
<div style="width: 1600px;">
  <div id="Echarts" style="width: 100%;height: 600px"></div>
</div>

  </div>
</template>

<script>
import echarts from 'echarts'
import { getAction } from "@/api/request";
import dateFormat from "@/utils/Timeformat";
export default {
  name: "index",
  data(){
    return{
      form:{
        time:[dateFormat(new Date()-3600*1000*24*30),dateFormat(new Date())],
        start_date:dateFormat(new Date()-3600*1000*24*30),
        end_date:dateFormat(new Date()),
      },

    }
  },
  watch:{
    'form.time'(v){
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ""
        this.form.end_date = ""
      }
    }
  },
  mounted() {
    this.handlerecharts()
  },
  methods:{
    handlerecharts(){
      const myChart = echarts.init(
        document.getElementById("Echarts")
      )
      const dataform = JSON.parse(JSON.stringify(this.form))
      delete dataform.time
      getAction("/api/statistics/buried-point/index",dataform).then(res=>{
        console.log(res)
        let option = {
          tooltip:{
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#fff'
              }
            }
          },

          yAxis:{},
          // 解决文字下方被切掉
          grid: {
            // left: '18%',
            bottom: '38%'
          },
          xAxis:{
            axisLabel: {
              interval: 0, //全部显示x轴
              rotate: 20
            },
            data:['企业版首页', '经销商版首页', '产品页', '店铺页', '企业认证页', '经销商认证页', '开通店铺页', '企业版搜索', '经销商版名片', '经销商名片夹', '结合版首面', '其他身份证证页', '经销商版搜索', '结合版搜索', '经销商品类', '结合版品类', '结合版名片夹'],
          },
          series:[]
        }
        option.xAxis.data = res.data.map(list=>{
          return list.title
        })
        console.log( option.xAxis.data)
        const serdata = res.data.map(list=>{
          return Number(list.pv)
        })
        option.series.push({
          name:'pv',
          type:'bar',
          data:serdata,
          barGap:'0%',
          color:'#5087ec',
          label:{
            show:true,
            position:'top'
          },
          axisLabel: {
            formatter: '{value}'
          }
        })

        const serdata2 = res.data.map(list=>{
          return  Number(list.uv)
        })

        option.series.push({
          name:'uv',
          type:'bar',
          data:serdata2,
          color:'#68bbc4',
          label:{
            show:true,
            position:'top'
          },
          axisLabel: {
            formatter: '{value}'
          }
        })
        myChart.setOption(option);
      })

    }
  }
};
</script>

<style scoped>

</style>
