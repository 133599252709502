<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="500px"
    top="22vh"
    :close-on-click-modal="false"
  >
    <div class="orderTest-container">
      <div style="text-align: center; line-height: 2">
        <div style="font-size: 16px">{{ info1 }}</div>
        <div style="font-size: 16px">{{ info2 }}</div>
        <div style="margin-top: 15px" v-if="type == 1">
          <span style="margin-right: 10px">锁定原因:</span>
          <el-select v-model="val" clearable style="width: 140px" placeholder="可选">
            <el-option
              v-for="(i, idx) in dialogSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="bottom-button center">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "HandleBatch",
  components: {},
  data() {
    return {
      type: 1, // 1手动锁定  2白名单 3 正常
      row: null,
      val: "",
      showDialog: false,
      dialogSelect: [
        // 0正常，2白名单
        { id: "广告公司", name: "广告公司" },
        { id: "活动作弊", name: "活动作弊" },
      ],
    }
  },
  created() {},
  mounted() {},
  computed: {
    title() {
      if (this.type == 1) {
        return "设为手动锁定"
      } else if (this.type == 2) {
        return "设为白名单"
      } else {
        return "设为正常"
      }
    },
    info1() {
      if (this.type == 1) {
        return "确认设为手动锁定？"
      } else if (this.type == 2) {
        return "确认设为白名单？"
      } else {
        return "确认设为正常？"
      }
    },
    info2() {
      if (this.type == 1) {
        return "锁定后用户将不能使用该账号"
      } else if (this.type == 2) {
        return "设为白名单后，打电话次数将不受限制"
      } else {
        return "设为正常后，可以拨打电话，但是次数将受限制"
      }
    },
  },
  watch: {
    showDialog(v) {
      if (!v) {
        this.val = ""
        this.row = null
      }
    },
  },
  methods: {
    handleSubmit() {
      if (this.row == null) {
        // 批量操作
        if (this.type == 1) {
          this.$emit("handleBatchLock", { val: this.val })
          this.showDialog = false
        } else if (this.type == 2) {
          this.$emit("handleBatchWhileList")
          this.showDialog = false
        } else if (this.type == 3) {
          this.$emit("handleBatchNormal")
          this.showDialog = false
        } else {
          this.$message.error("操作错误")
          this.showDialog = false
          return false
        }
      } else {
        // 表格单个操作
        if (this.type == 1) {
          this.$emit("handleLock", { is_lock: "3", reason: this.val, user_id: this.row.user_id })
          this.showDialog = false
        } else if (this.type == 2) {
          this.$emit("handleWhileList", { is_lock: "2", user_id: this.row.user_id })
          this.showDialog = false
        } else if (this.type == 3) {
          this.$emit("handleNormal", { is_lock: "0", user_id: this.row.user_id })
          this.showDialog = false
        } else {
          this.$message.error("操作错误")
          this.showDialog = false
          return false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
