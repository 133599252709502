<template>
  <el-dialog
    :title="form.corp_name"
    :visible.sync="showbusiness"
    width="30%">
    <div>
      <el-form ref="form" :model="form"  >

        <el-form-item >
          <h5>{{form.base.name}}</h5>
          <el-table
            :data="form.base.data"
            :show-header="false"
            style="width: 100%">
            <el-table-column
              prop="name"
              label="name"
              width="">
            </el-table-column>
            <el-table-column
              prop="val"
              label="val"
              width="">
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <h5>{{form.original_name.name}}</h5>
          <el-table
            :data="form.original_name.data"
            :show-header="false"
            style="width: 100%">
            <el-table-column
              prop="name_name"
              label="name"
              width="">
            </el-table-column>
            <el-table-column
              prop="name_val"
              label="val"
              width="">
            </el-table-column>
            <el-table-column
              prop="date_name"
              label="name"
              width="">
            </el-table-column>
            <el-table-column
              prop="date_val"
              label="val"
              width="">
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <h5>{{form.revoke_info.name}}</h5>
          <el-table
            :data="form.revoke_info.data"
            :show-header="false"
            style="width: 100%">
            <el-table-column
              prop="name"
              label="name"
              width="">
            </el-table-column>
            <el-table-column
              prop="val"
              label="val"
              width="">
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <h5>{{form.area.name}}</h5>
          <el-table
            :data="form.area.data"
            :show-header="false"
            style="width: 100%">
            <el-table-column
              prop="name"
              label="name"
              width="">
            </el-table-column>
            <el-table-column
              prop="val"
              label="val"
              width="">
            </el-table-column>
          </el-table>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showbusiness = false">取 消</el-button>
    <el-button type="primary" @click="showbusiness = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "business",
  data(){
    return{
      showbusiness:false,
      form:{
        base:{
          name:'',
          data:[]
        },
        original_name:{
          name:'',
          data:[]
        },
        revoke_info:{
          name:'',
          data:[]
        },
        area:{
          name:'',
          data:[]
        }
      }
    }
  },
  methods:{
    handlerinfo(company){
      getAction("/audit/identity/business-info",{company:company}).then(res=>{
        this.form=res.data
        this.showbusiness=true
      })
    }
  }
};
</script>

<style scoped>

</style>
