<template>
  <el-dialog
    :visible.sync="showadd"
    append-to-body
    width="60%">
    <div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="酒店名称">
          <el-input v-model="form.name" placeholder="请输入酒店名称"></el-input>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input v-model="form.longitude" placeholder="请输入经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input v-model="form.latitude" placeholder="请输入纬度"></el-input>
        </el-form-item>
        <el-form-item label="专区">
          <el-select
            v-model="form.type"
            class="input"
            clearable
            placeholder="请选择专区"
          >
            <el-option
              v-for="i in catSelect"
              :key="i.id"
              :label="i.type_name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="logo">
          <upload-img ref="logo" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'logo')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="背景图">
          <upload-img ref="img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'img')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="开启" value="1" />
            <el-option label="关闭" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="布展企业名录">
          <wang-editor ref="content"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import uploadImg from "@/components/uploadImg";
import { nextTick } from "vue";
import WangEditor from "@/components/WangEditor";
export default {
  components:{
    WangEditor,
    uploadImg
  },
  props:{
    catSelect: {
  type: Array,
default: () => [],
},
  },
  name: "AddZone",
  data(){
    return{
      showadd:false,
      form:{
        name:'',
        address:'',
        conf_id:'',
        longitude:'',
        sort:'',
        latitude:'',
        type:'',
        logo:'',
        content:'',
        img:'',
        status:'',
        id:'',
      }
    }
  },
  watch:{
    showadd(v){
      if(!v){
        this.form={
          name:'',
          address:'',
          conf_id:'',
          longitude:'',
          sort:'',
          latitude:'',
          type:'',
          logo:'',
          content:'',
          img:'',
          status:'',
          id:'',
      }
      this.closeimg('logo')
      this.closeimg('img')
        this.$refs.content.clearHtml()
      }
    },
  },
  methods: {
    handlersave(){
      this.form.content=this.$refs.content.getHtmlM()
      postAction("/api/hotel/hotels/edit",this.form).then(res=>{
          this.$message.success(res.msg)
          this.showadd=false
          this.$emit('handlerlist')
      })
    },
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      nextTick(()=>{
        this.setImg(this.form.logo, "logo")
        this.setImg(this.form.img, "img")
        setTimeout(()=>{
          this.$refs.content.setHtml(row.content)
        },100)

      })
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
