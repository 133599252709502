<template>
<div>
  <wang-editor :content="content"/>
</div>
</template>

<script>
import WangEditor from "@/components/WangEditor";
export default {
  name: "customer",
  components: { WangEditor },
  data(){
    return{
      content:''
    }
  }
};
</script>

<style scoped>

</style>
