<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.author"
          clearable
          placeholder="名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabledata"
              style="width: 100%"
              ref="ElTable">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerdelete(row)"
          >
            删除
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerbanner(row)"
          >
            轮播图
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlermenu(row)"
          >
            金刚区
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerDirect(row)"
          >
            直达按钮
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerUpdate(row)"
          >
            今日上新
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerrecommend(row)"
          >
            名企推荐
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerproduct(row)"
          >
            人气产品
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerselected(row)"
          >
            热门精选
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlercat(row)"
          >
            热门精选关联品类
          </el-button>
        </template>
      </el-table-column>
    </el-table>
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
    <add-edit ref="addedit" @getlist="handlerInquire" />
    <konglist ref="list" />
    <bannerlist ref="banner" />
    <category ref="cat" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import AddEdit from "./components/AddEdit";
import Konglist from './components/konglist'
import Bannerlist from './components/bannerlist'
import Category from "./components/category";
export default {
  name: "index",
  components: { Category, AddEdit ,Konglist,Bannerlist},
  data(){
    return{
      form: {
        limit: 10,
        page: 1,
        name:'',
        status:'',
      },
      tabledata:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      columns: [
        {
          label: '展馆ID',
          prop: 'id',
          width: '110',
          align:'center'
        },
        {
          label: '名称',
          prop: 'name',
          width: '',
          align:'center'
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerdelete(row){
      this.$confirm('此操作将删除该品类节, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postAction("/api/system/category-act/delete",{id:row.id}).then(res=>{
          this.handlerInquire()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }).catch(() => {
      });
    },
    handlerbanner(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.slide_ad
      this.$refs.banner.title='轮播图设置'
      this.$refs.banner.handlerInquire()
    },
    handlerDirect(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.direct_ad
      this.$refs.banner.title='直达按钮设置'
      this.$refs.banner.handlerInquire()
    },
    handlerUpdate(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.new_ad
      this.$refs.banner.title='今日上新设置'
      this.$refs.banner.handlerInquire()
    },
    handlerrecommend(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.enterprise_ad
      this.$refs.banner.title='名企推荐设置'
      this.$refs.banner.handlerInquire()
    },
    handlerproduct(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.hot_ad
      this.$refs.banner.title='人气产品设置'
      this.$refs.banner.handlerInquire()
    },
    handlerselected(row) {
      this.$refs.banner.show = true
      this.$refs.banner.form.advert_no = row.select_ad
      this.$refs.banner.title='热门精选设置'
      this.$refs.banner.handlerInquire()
    },
    handlermenu(row) {
      this.$refs.list.showkong = true
      this.$refs.list.form.house_id = row.id
      this.$refs.list.handlerInquire()
    },
    handlercat(row){
      console.log(row)

      this.$refs.cat.form.id=row.id
      if(row.cat_ids==null){
        this.$refs.cat.form.cat_ids=[]
      }else{
        this.$refs.cat.form.cat_ids=row.cat_ids.split(',')
      }
      this.$refs.cat.showcat=true
    },
    handlerAdd(){
      this.$refs.addedit.showadd=true
    },
    handleredit(row){
      this.$refs.addedit.showadd=true
      this.$refs.addedit.handlerinfo(row)
    },
    handlerlist() {
      getAction('/api/system/category-act/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
