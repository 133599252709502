var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showChangeLog, width: "70%" },
      on: {
        "update:visible": function ($event) {
          _vm.showChangeLog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-width": "80px", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { clearable: "", placeholder: "需求ID" },
                model: {
                  value: _vm.form.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "id", $$v)
                  },
                  expression: "form.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "用户昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "姓名" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权限类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.staff_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_type", $$v)
                    },
                    expression: "form.staff_type",
                  },
                },
                _vm._l(_vm.typeList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "修改类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "position", $$v)
                    },
                    expression: "form.position",
                  },
                },
                _vm._l(_vm.changeList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timelist,
                  callback: function ($$v) {
                    _vm.timelist = $$v
                  },
                  expression: "timelist",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerlist()
                    },
                  },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listloading,
              expression: "listloading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "修改内容"
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "left" } },
                            _vm._l(row.content, function (con, index) {
                              return _c("div", { key: index }, [
                                _vm._v(" " + _vm._s(con) + " "),
                              ])
                            }),
                            0
                          )
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showChangeLog = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }