<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-button type="primary" @click="handlerlist">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleradd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
     <el-col :span="10">
       <el-table
         :data="tabledata"
         style="width: 100%">
         <el-table-column
           prop="id"
           label="ID"
           align="center"
           width="">
         </el-table-column>
         <el-table-column
           prop="title"
           label="日期（x月x日）"
           align="center"
           width="">
         </el-table-column>
         <el-table-column
           prop="status"
           align="center"
           label="状态">
           <template #default="{row}">
             <div>
               <p v-show="row.status==1">启用</p>
               <p v-show="row.status==0">禁用</p>
             </div>
           </template>
         </el-table-column>
         <el-table-column
           prop="address"
           align="center"
           label="操作">
           <template #default="{row}">
             <el-button type="text" @click="handlerdelete(row)">删除</el-button>
             <el-button type="text" @click="handleredit(row)">编辑</el-button>
           </template>
         </el-table-column>
       </el-table>
       <el-pagination
         :current-page="form.page"
         :layout="layout"
         :page-size="form.limit"
         :total="total"
         background
         @current-change="handleCurrentChange"
         @size-change="handleSizeChange"
         style="text-align: center; margin-top: 10px"
       ></el-pagination>
     </el-col>
    </el-row>
    <add ref="add" @getlist="handlerlist"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import add from './components/add'

export default {
  components:{
    add
  },
  name: "index",
  data(){
    return{
      tabledata:[],
      form: {
        page: 1,
        limit: 10,
        title:'',
      },
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleredit(row){
      this.$refs.add.showAdd=true
      this.$refs.add.form=row
    },
    handleradd(){
      this.$refs.add.showAdd=true
    },
    handlerdelete(row){
      postAction("/api/afbc/date/delete",{id:row.id}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerlist()
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.page = 1
      this.form.limit = val
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/afbc/date/index",this.form).then(res=>{
        console.log(res)
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    }
  }
};
</script>

<style scoped>

</style>
