<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          placeholder="标题"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.type" placeholder="类型" clearable style="width: 110px">
          <el-option
            v-for="item in typeSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '分享小程序图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '类型'">{{typeSelect.filter(item=>item.id==row.type)[0].name}}</div>
          <div v-else-if="item.label == '分享朋友圈图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handlerdelete(row)"
          >
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="editposter" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit },
  data() {
    return {
      form: {
        type: '',
        title: '',
        page: 1,
        limit: 10,
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      typeSelect:[],
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '',
        },
        {
          label: '类型',
          prop: 'type',
          width: '',
        },
        {
          label: '标题',
          prop: 'title',
          width: '',
        },
        {
          label: '分享标题',
          prop: 'share_title',
          width: '',
        },
        {
          label: '分享小程序图',
          prop: 'share_mini_pic',
          width: '',
        },
        {
          label: '分享朋友圈图',
          prop: 'share_friend_pic',
          width: '',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
    getAction("/api/system/set-plan-report/type").then(res=>{
      this.typeSelect=res.data
    })
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerAdd() {
      this.$refs.editposter.showEditPoster = true
    },
    handlerlist() {
      getAction('/api/system/set-plan-report/list', this.form).then((res) => {
        this.tabledata = res.data
        console.log(res.data,this.tabledata)
        this.total = Number(res.totalCount)
      })
    },
    handlerdelete(row) {
      postAction('/api/system/set-plan-report/delete', { id: row.id }).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    // 编辑分类
    handleredit(row) {
      this.$refs.editposter.showEditPoster = true
      this.$refs.editposter.handlerinfo(row)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
};
</script>

<style scoped>

</style>
