var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.show, width: "90%" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "max-height": "70vh", "overflow-y": "scroll" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                border: "",
                data: _vm.form.list,
                "span-method": _vm.objectTplSpanMethod,
              },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    item.label == "操作"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerInit(row)
                                    },
                                  },
                                },
                                [_vm._v(" 查看详情 ")]
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("infolike", { ref: "infolike" }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.show = !_vm.show
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }