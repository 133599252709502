<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.external_name"
          clearable
          placeholder="需求客户"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.staff_name"
          clearable
          placeholder="会议管理员"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'

  export default {
    data() {
      return {
        form: {
          external_name: '',
          staff_name: '',
          id: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '会议标题',
            prop: 'title',
            width: '',
          },
          {
            label: '会议管理员',
            prop: 'staff_name',
            width: '220px',
          },
          {
            label: '需求客户',
            prop: 'external_name',
            width: '',
          },

          {
            label: '开始时间',
            prop: 'meeting_start',
            width: '160',
          },
          {
            label: '持续时间',
            prop: 'meeting_duration',
            width: '',
          },

          {
            label: '满意度调查',
            prop: 'degree_type',
            width: '220px',
          },
          {
            label: '匹配用户',
            prop: 'match_ci',
            width: '',
          },
          {
            label: '第一个添加用户',
            prop: 'first_add',
            width: '',
          },
          {
            label: '合计',
            prop: 'total',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
      }
    },
    mounted() {
      if (this.$route.query.id) {
        this.form.id = this.$route.query.id
        this.handlerlist()
      } else {
        this.handlerInquire()
      }
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '会议标题') {
            sums[index] = '总价'
          }
          if (column.label === '合计') {
            sums[index] = this.sumtotal
          }
        })
        return sums
      },
      handlerInquire() {
        this.form.id = ''
        this.form.page = 1
        this.handlerlist()
      },
      handlerExport() {
        postAction('/audit/biz-clue/meetingaward-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      handlerlist() {
        postAction('/audit/biz-clue/meetingaward-lists', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            this.total = Number(res.totalCount)
            this.sumtotal = res.data.total_award
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
