<template>
  <el-dialog
    v-loading="loading"
    :close-on-click-modal="false"
    :title="title"
    top="8vh"
    :visible.sync="showDialog"
    width="900px"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        label-position="right"
        label-suffix=":"
        label-width="130px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="商品名称" prop="goods_name">
          <el-input v-model="form.goods_name" class="input" clearable />
        </el-form-item>
        <el-form-item label="分类" prop="type">
          <el-select
            v-model="form.type"
            class="input"
            clearable
            placeholder="请选择分类"
            @change="typeChange"
          >
            <el-option
              v-for="(i, idx) in typeSelect"
              :key="idx"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>

        <!--          @change="typeChange"-->
        <el-form-item
          v-if="form.type == 1"
          label="报告所属行业"
          prop="class_id"
        >
          <el-select
            v-model="form.class_id"
            class="input"
            clearable
            placeholder="请选择所属行业"
          >
            <el-option
              v-for="i in classSelect"
              :key="i.id"
              :label="i.name"
              :value="i.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.type == 1"
          label="公众号的关键字"
          prop="keyword"
        >
          <el-input v-model="form.keyword" class="input" clearable />
        </el-form-item>

        <el-form-item v-if="form.type == 2" label="类型" prop="is_free">
          <!-- 0否，1是 -->
          <el-select
            v-model="form.is_free"
            placeholder=""
            style="width: 300px"
            @change="freeTypeChange"
          >
            <el-option label="免费" value="1" />
            <el-option label="付费" value="0" />
          </el-select>
        </el-form-item>
        <template>
          <!-- 关联码库1 -->
          <el-form-item
            v-if="form.type == 2 && form.is_free == 0"
            :label="'关联码库'"
            prop="cdkeyhead"
          >
            <el-select
              v-model="form.cdkeyhead"
              class="input"
              clearable
              placeholder="请选择关联码库"
              @change="linkCodeChange"
            >
              <el-option
                v-for="(i, idx) in linkCodeSelect"
                :key="idx"
                :disabled="i.disabled"
                :label="i.name"
                :value="i.id"
              />
            </el-select>
          </el-form-item>
        </template>

        <el-form-item label="简介" prop="goods_info">
          <el-input v-model="form.goods_info" class="input" clearable />
        </el-form-item>
        <el-form-item label="详细介绍" prop="goods_desc">
          <wang-editor ref="editor" height="300" />
        </el-form-item>
        <el-form-item label="兑换说明" prop="goods_note">
          <wang-editor ref="editor2" height="300" />
        </el-form-item>
        <el-form-item label="商品图片" prop="img">
          <uploadImage
            ref="uploadImage"
            :limit="1"
            :max-size="3"
            :show-info="false"
          />
        </el-form-item>
        <el-form-item label="商品数量" prop="num">
          <el-input-number
            v-model="form.num"
            :min="0"
            :step="1"
            step-strictly
          />
          <!--            :max="form.type == 2 && form.is_free == 0 ? allT : 99999999999999"-->
        </el-form-item>
        <el-form-item label="兑换所需云币" prop="integral">
          <el-input-number
            v-model="form.integral"
            :disabled="form.type == 2 && form.is_free == 1"
            :min="0"
            :step="1"
            step-strictly
          />
        </el-form-item>
        <el-form-item label="最多兑换次数" prop="">
          <el-input-number
            v-model="form.add_count"
            :min="1"
            :step="1"
            step-strictly
          />
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input-number v-model="form.sort_order" :step="1" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="form.status"
            active-color="#13ce66"
            :active-value="1"
            inactive-color="#ccc"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-button center">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import WangEditor from '@/components/WangEditor.vue'
  import uploadImage from '@/components/uploadImg.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'AddEdit',
    components: { WangEditor, uploadImage },
    data() {
      return {
        type: 1,
        id: '',
        loading: false,
        showDialog: false,
        // 分类，1行业报告，2线上课程，3爆款好物
        form: { is_free: '1', status: 1, cdkey_list: '', class_id: '' },
        linkCodeSelect: [],
        classSelect: [],
        typeSelect: [
          { id: '1', name: '行业报告' },
          { id: '2', name: '线上课程' },
          { id: '3', name: '爆款好物' },
        ],
        rules: {
          //         name
          // type
          // keyword
          // info
          // desc 详细介绍
          // note兑换说明
          // img商品图片
          // num商品数量
          // integral兑换所需云币
          // sort_order排序
          // status状态
          goods_name: [{ required: true, message: '请输入商品名称' }],
          type: [{ required: true, message: '请选择分类' }],
          keyword: [{ required: true, message: '请输入公众号的关键字' }],
          goods_info: [{ required: true, message: '请输入简介' }],
          num: [{ required: true, message: '请输入商品数量' }],
          cdkeyhead: [{ required: true, message: '请选择关联库码' }],
          goods_desc: [
            {
              validator: this.verifyXiangxijieshao,
              required: true,
              message: '请输入详细介绍',
            },
          ],
          goods_note: [
            {
              validator: this.verifyDuihuanshuoming,
              required: true,
              message: '请输入兑换说明',
            },
          ],
          img: [
            {
              validator: this.verifyImg,
              required: true,
              message: '请选择商品图片',
            },
          ],
          integral: [{ required: true, message: '请输入兑换所需云币' }],
          sort_order: [{ required: true, message: '请输入排序' }],
          status: [{ required: true, message: '请选择状态' }],
        },
      }
    },
    computed: {
      // 未兑换总计
      // allT() {
      //   let num = 0
      //   this.form.cdkey_list.forEach((i) => {
      //     if (i.cdkeyhead) {
      //       num += Number(i.yun_left)
      //     }
      //   })
      //   return num
      // },
      title() {
        if (this.type == 1) {
          return '添加'
        } else {
          return '编辑'
        }
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          if (this.type == 2) {
            this.fetchData()
          }
        } else {
          this.clearForm()
        }
      },
      // 'form.type'(val){
      //
      // }
    },
    created() {},
    mounted() {
      postAction('/user/index/cate', { pid: 0 }).then((res) => {
        this.classSelect = res.data
      })
      this.initSelect()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let res = await getAction('/api/system/integral/goods-info', {
          id: this.id,
        })
        this.form = res.data
        this.form.status = parseInt(res.data.status)
        // 设置编辑器内容
        console.log('设置编辑器内容')
        this.$refs.editor.setHtml(this.form.goods_desc) // desc
        this.$refs.editor2.setHtml(this.form.goods_note) //note
        // 设置默认图片
        this.$refs.uploadImage.img = [this.form.img]
        this.loading = false
      },
      async initSelect() {
        let res = await getAction('/api/system/integral/cdkey-list')
        this.linkCodeSelect = res.data
      },

      clearForm() {
        this.form.status = 1
        this.$refs.form.resetFields()
        // this.$refs.form.clearValidate()
        this.$refs.editor.clearHtml()
        this.$refs.editor2.clearHtml()
        this.$refs.uploadImage.img = []
      },
      // 关联码库下拉修改
      linkCodeChange(e, item) {
        // this.form.num = ""
        // if (e) {
        //   item.left = this.linkCodeSelect.filter((i) => i.cdkeyhead == e)[0].left
        //   item.yun_left = this.linkCodeSelect.filter((i) => i.cdkeyhead == e)[0].yun_left
        //   item.id = this.linkCodeSelect.filter((i) => i.cdkeyhead == e)[0].id
        // } else {
        //   item.yun_left = ""
        //   item.left = ""
        // }
      },
      getHtmlM() {
        let a = this.$refs.editor.getHtmlM()
        console.log('a', a)
      },
      handleSubmit() {
        let data = { ...this.form }
        if (this.type == 1) {
          // 新增
          data.id = 0
        } else {
          // 编辑
          data.id = this.id
        }
        data.goods_desc = this.$refs.editor.getHtmlM()
        data.goods_note = this.$refs.editor2.getHtmlM()
        data.img = this.$refs.uploadImage.img[0]
        this.$refs.form.validate((v) => {
          if (v) {
            getAction('/api/system/integral/goods-edit', data).then((re) => {
              this.$message.success(this.type == 1 ? '添加成功' : '修改成功')
              this.$emit('refresh')
              this.showDialog = false
            })
          }
        })
      },
      verifyXiangxijieshao(rule, val, cb) {
        if (this.$refs.editor.isKong()) {
          cb(new Error('请输入详细介绍'))
        } else {
          cb()
        }
      },
      verifyDuihuanshuoming(rule, val, cb) {
        if (this.$refs.editor2.isKong()) {
          cb(new Error('请输入兑换说明'))
        } else {
          cb()
        }
      },
      verifyImg(rule, val, cb) {
        if (this.$refs.uploadImage.img[0]) {
          cb()
        } else {
          cb(new Error('请选择商品图片'))
        }
      },
      freeTypeChange(v) {
        if (v == 1) {
          this.form.integral = 0
        }
      },
      typeChange(v) {
        this.form.class_id = ''
        this.form.is_free = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .input {
    width: 300px;
  }
</style>
