<template>
  <el-dialog
    v-loading="loading"
    append-to-body
    :title="'创建云会议' + '' + form.title"
    :visible.sync="showcloud"
    width="30%"
  >
    <div>
      <el-form
        ref="form"
        label-position="right"
        label-suffix="："
        label-width="140px"
        :model="form"
      >
        <el-form-item label="需求客户" prop="total">
          {{ form.name }}
        </el-form-item>
        <el-form-item label="会议管理员" prop="read">
          <el-select v-model="form.admin_id" @change="handlerSelect">
            <el-option
              v-for="list in form.admin_list"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="参会客户" prop="is_jump">
          {{ form.add_meeting_username }}
        </el-form-item>
        <el-form-item label="需求提报人" prop="click">
          {{ form.tibao }}
        </el-form-item>
        <el-form-item label="会议标题" prop="click">
          <el-input
            v-model="form.meeting_title"
            placeholder="请输入会议标题"
            :rows="2"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="会议开始时间" prop="click">
          <el-date-picker
            v-model="form.meeting_start"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="会议持续时间" prop="click">
          <el-select v-model="form.meeting_duration">
            <el-option label="20分钟" value="1" />
            <el-option label="30分钟" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="会议描述" prop="click">
          <el-input
            v-model="form.desc"
            placeholder="请输入内容"
            :rows="2"
            type="textarea"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showcloud = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/request'

  export default {
    name: 'AddCloud',
    data() {
      return {
        showcloud: false,
        loading: false,
        id: '',
        form: {
          id: '',
          meeting_start: '',
          meeting_duration: '',
          desc: '',
        },
      }
    },
    watch: {
      showcloud(val) {
        if (val) {
          this.hanlderinfo()
        } else {
          this.form = {
            id: '',
            meeting_start: '',
            meeting_duration: '',
            desc: '',
            admin_id: '',
            admin_name: '',
          }
        }
      },
    },
    methods: {
      handlerSelect(e) {
        this.form.admin_list.forEach((item, index) => {
          if (item.id == e) {
            this.form.admin_name = item.name
          }
        })
      },
      hanlderinfo() {
        this.loading = true
        postAction('/audit/biz-clue/createmeeting-info', {
          id: this.id,
        }).then((res) => {
          // this.form = Object.assign(res.data, this.form)
          this.form = res.data
          this.showcloud = true
          this.loading = false
        })
      },
      handlersave() {
        let params = { ...{ id: this.id }, ...this.form }
        postAction('/audit/biz-clue/create-meeting', params).then((res) => {
          if (res.request_code == 200) {
            this.showcloud = false
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
    },
  }
</script>

<style scoped></style>
