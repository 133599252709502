<template>
  <div class="index-container center">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>
    <el-row  type="flex" justify="space-between">
      <el-col :span="4" v-for="item in toubu" :key="item.name" class="card">
        <el-card >
          <h3>{{ item.name }}</h3>
            <div style="display: flex;align-items: center;justify-content: space-between">
              <div>
                <p>昨天访问人数</p>
                <p>{{ item.ye.uv }}</p>
              </div>
              <div>
                <p>累计访问人数</p>
                <p>{{ item.cat.uv }}</p>
              </div>
            </div>
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div>
              <p>昨天访问次数</p>
              <p>{{ item.ye.pv }}</p>
            </div>
            <div>
              <p>累计访问次数</p>
              <p>{{ item.cat.pv }}</p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-radio-group v-model="radio" @change="handlerInquire">
      <el-radio-button label="1">访问人数</el-radio-button>
      <el-radio-button label="0">访问次数</el-radio-button>
    </el-radio-group>
<el-row>
  <div style="max-width: 1600px;">
    <div id="Echarts" style="width: 100%;height: 400px"></div>
  </div>
</el-row>

  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import dateFormat from "@/utils/Timeformat";
import echarts from "echarts";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        start_date:dateFormat(new Date()-3600*1000*24*30),
        end_date:dateFormat(new Date()),
      },
      formdata:{},
      radio:'1',
      time:[dateFormat(new Date()-3600*1000*24*30),dateFormat(new Date())],
      option:{
        tooltip:{
          trigger: 'axis',
        },
        legend: {
          data: ['小程序', '安卓App', '苹果App', '安卓App（头条）', '苹果App（头条）','鸿蒙云交会']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        dataZoom:[
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
          },
        ],
        series: [

        ]
      }
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  computed: {
    toubu(){
      let toudata=[]
      let formkeys =  Object.keys(this.formdata)
      let yedata=[]
      let catdata=[]
      if(formkeys.length>0){
        console.log(formkeys)
        formkeys.forEach(list=>{
          if(list=='yesterday'){
            yedata=Object.values(this.formdata[list])
          }else if(list=='counts'){
            catdata=Object.values(this.formdata[list])
          }
        })

        for(let i=0;i<5;i++){
          let yelist={
              pv:0,
              uv:0
          }
          let catlist={
              pv:0,
              uv:0
          }
          let name=''
          switch (i){
            case 0:
              name='安卓app（头条）访问数据'
              break
            case 1:
              name='苹果app（头条）访问数据'
              break
            case 2:
              name='小程序访问数据'
              break
            case 3:
              name='安卓app访问数据'
              break
            case 4:
              name='苹果app访问数据'
              break
          }
          if(yedata[i]!=null||yedata[i]!=undefined){
            yelist=yedata[i]
          }
          if(catdata[i]!=null||catdata[i]!=undefined){
            catlist=catdata[i]
          }
          let ye={name:name,ye:yelist,cat:catlist}
          toudata.push(ye)
        }
      }
      return toudata
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.handlerlist()
    },
    handlerlist() {
      this.option.series=[]
      const myChart = echarts.init(
        document.getElementById("Echarts")
      )
      getAction('/api/statistics/buried-point/active-users', this.form).then(
        (res) => {
          this.formdata=JSON.parse(JSON.stringify(res.data))
          let datelist=[]
          let puv=[]
          res.data.range.map(list=>{
            puv.push(Object.values(list)[1])
            datelist.push(Object.values(list)[0])
            console.log(puv)
          })
          let name=''
          let puvkeys=Object.keys(puv[0])
            puvkeys.forEach(list=>{
              console.log(list)
              let puvvalue={
                data: [],
                name: '',
                type: 'line',
              }
              switch (list){
                case '1':
                  name='安卓App（头条）'
                  break
                case '2':
                  name='苹果App（头条）'
                  break
                case '3':
                  name='小程序'
                  break
                case '11':
                  name='安卓App'
                  break
                case '12':
                  name='苹果App'
                  break
                case '13':
                  name='鸿蒙云交会'
                  break
              }
              console.log(name)
              puv.map(item=>{

                puvvalue.name=name
              if(this.radio=='1'){
                puvvalue.data.push(item[list].uv)
              }else{
                puvvalue.data.push(item[list].pv)
              }
            })
            console.log(puvvalue)
            this.option.series.push(puvvalue)
          })
          this.option.xAxis.data=datelist
          // option.series[0].data=this.datalist
          myChart.setOption(this.option);
        }
      )
    },
  },
}
</script>

<style scoped>
.center{
  text-align: center;
  place-items: center;
}

</style>
