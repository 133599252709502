var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: { clearable: "", placeholder: "店铺名称" },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: { clearable: "", placeholder: "公司名称" },
                model: {
                  value: _vm.form.company_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "company_name", $$v)
                  },
                  expression: "form.company_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "default-time": ["12:00:00"],
                  "end-placeholder": "提交结束日期",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "提交开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.statusSelect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { value: list.id, label: list.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "变动方式" },
                  model: {
                    value: _vm.form.operation_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operation_type", $$v)
                    },
                    expression: "form.operation_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "增加", value: "1" } }),
                  _c("el-option", { attrs: { label: "减少", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "operation_position" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "变动类型" },
                  model: {
                    value: _vm.form.operation_position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operation_position", $$v)
                    },
                    expression: "form.operation_position",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "套餐内", value: "1" } }),
                  _c("el-option", { attrs: { label: "赠送", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("新 增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "广告图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "70px",
                                      height: "30px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row.img],
                                      src: row.img,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label == "图片留存"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 10 } },
                                  _vm._l(row.pic, function (item, index) {
                                    return _c(
                                      "el-col",
                                      { key: index, attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticStyle: {
                                              width: "70px",
                                              height: "30px",
                                            },
                                            attrs: {
                                              "preview-src-list": [item],
                                              src: item,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "placeholder" },
                                                slot: "placeholder",
                                              },
                                              [
                                                _vm._v(" 加载中 "),
                                                _c(
                                                  "span",
                                                  { staticClass: "dot" },
                                                  [_vm._v("...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : item.label == "类型"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.statusSelect.filter(
                                      (list) => list.id == row[item.prop]
                                    )[0].name
                                  ) +
                                  " "
                              ),
                            ])
                          : item.label == "变动类型"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 1,
                                      expression: "row[item.prop] == 1",
                                    },
                                  ],
                                },
                                [_vm._v("套餐内")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 2,
                                      expression: "row[item.prop] == 2",
                                    },
                                  ],
                                },
                                [_vm._v("赠送")]
                              ),
                            ])
                          : item.label == "状态"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 1,
                                      expression: "row[item.prop] == 1",
                                    },
                                  ],
                                },
                                [_vm._v("有效")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 2,
                                      expression: "row[item.prop] == 2",
                                    },
                                  ],
                                },
                                [_vm._v("失效")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 3,
                                      expression: "row[item.prop] == 3",
                                    },
                                  ],
                                },
                                [_vm._v("已过期")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 4,
                                      expression: "row[item.prop] == 4",
                                    },
                                  ],
                                },
                                [_vm._v("已完成")]
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "220",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认取消？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handlerdelete(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 取消 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add-edit", { ref: "addedit", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }