<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="提报人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="form.start_time"
          type="month"
          placeholder="选择月"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '经销商意愿凭证'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '大商凭证'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot"></div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import AutoInput from '@/components/autoInput'

  export default {
    components: { AutoInput },
    data() {
      return {
        form: {
          staff_name: '',
          dealer_name: '',
          page: 1,
          limit: 10,
          start_time: '',
          end_time: '',
        },
        time: '',
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total_money: '',
        columns: [
          {
            label: '需求ID',
            prop: 'id',
            width: '',
          },
          {
            label: '提报人',
            prop: 'staff_user_name',
            width: '',
          },
          {
            label: '店铺',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '选品客户',
            prop: 'name',
            width: '',
          },
          {
            label: '喜欢时间',
            prop: 'like_time',
            width: '120',
          },
          {
            label: '合计',
            prop: 'heji',
            width: '120',
          },
        ],
        total: 0,
        sumtotal: '',
      }
    },
    watch: {
      // time(v) {
      //   if (v) {
      //     this.form.start_time = v[0]
      //     this.form.end_time = v[1]
      //   } else {
      //     this.form.start_time = ''
      //     this.form.end_time = ''
      //   }
      // },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '需求ID') {
            sums[index] = '共计'
          }
          if (column.label === '合计') {
            sums[index] = this.total_money
          }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-selplanreward', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },

      async handlerlist() {
        await postAction('/audit/biz-clue/selplanreward-lists', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = res.totalCount
          }
        )
        await postAction('/audit/biz-clue/all-selplanreward', this.form).then(
          (res) => {
            this.total_money = res.data
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
