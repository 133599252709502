var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.addbanner, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.addbanner = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "版别", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "版别" },
                          on: { change: _vm.handlerjuptype },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.identitySelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "首页logo", prop: "logo" } },
                    [
                      _c("upload-img", {
                        ref: "logo",
                        attrs: {
                          infoText: _vm.logo_text,
                          limit: 1,
                          maxSize: 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "logo")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "首页背景图", prop: "pic_content" } },
                    [
                      _c("upload-img", {
                        ref: "pic_content",
                        attrs: { infoText: "", limit: 5, maxSize: 100 },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImgs($event, "pic_content")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "背景色", prop: "bg_color" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: { placeholder: "背景色" },
                        model: {
                          value: _vm.form.bg_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bg_color", $$v)
                          },
                          expression: "form.bg_color",
                        },
                      }),
                      _c(
                        "p",
                        { staticStyle: { color: "red", "font-size": "12px" } },
                        [_vm._v(_vm._s(_vm.bg_color_text))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addbanner = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }