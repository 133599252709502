export function dateFormat(Date) {
  var Y = Date.getFullYear()
  var M = Date.getMonth() + 1
  M = M < 10 ? '0' + M : M // 不够两位补充0
  var D = Date.getDate()
  D = D < 10 ? '0' + D : D
  var H = Date.getHours()
  H = H < 10 ? '0' + H : H
  var Mi = Date.getMinutes()
  Mi = Mi < 10 ? '0' + Mi : Mi
  var S = Date.getSeconds()
  S = S < 10 ? '0' + S : S
  return Y + '-' + M + '-' + D
  // + ' ' + H + ':' + Mi + ':' + S
}
export function dateForm(Date) {
  var Y = Date.getFullYear()
  var M = Date.getMonth() + 1
  M = M < 10 ? '0' + M : M // 不够两位补充0
  var D = Date.getDate()
  D = D < 10 ? '0' + D : D
  var H = Date.getHours()
  H = H < 10 ? '0' + H : H
  var Mi = Date.getMinutes()
  Mi = Mi < 10 ? '0' + Mi : Mi
  var S = Date.getSeconds()
  S = S < 10 ? '0' + S : S
  return Y + '-' + M + '-' + D + ' ' + H + ':' + Mi + ':' + S
  // + ' ' + H + ':' + Mi + ':' + S
}
export function day_30() {
  let nowTime = new Date() // 当前时间戳
  let time30 = new Date().setMonth(new Date().getMonth() - 1) //日期是30天前的对应时间戳
  // this.form.start_time = dateFormat(new Date(time30))
  // this.form.end_time = dateFormat(nowTime)
  let time = [dateFormat(new Date(time30)), dateFormat(nowTime)]
  return time
}
// 时间戳转日期格式
export function formatTimeStamp(timeStamp) {
  let time = new Date(timeStamp)
  var Y = time.getFullYear()
  var M = time.getMonth() + 1
  M = M < 10 ? '0' + M : M // 不够两位补充0
  var D = time.getDate()
  D = D < 10 ? '0' + D : D
  var H = time.getHours()
  H = H < 10 ? '0' + H : H
  var Mi = time.getMinutes()
  Mi = Mi < 10 ? '0' + Mi : Mi
  var S = time.getSeconds()
  S = S < 10 ? '0' + S : S
  return Y + '-' + M + '-' + D
}
// return [1天前时间，当前时间]
export function day_n(day = 1) {
  let nowTime = new Date() // 当前时间戳
  let time1 = nowTime - day * (60 * 60 * 24 * 1000)
  let time = [dateFormat(new Date(time1)), dateFormat(nowTime)]
  return time
}
export function day_s(day = 1) {
  let nowTime = new Date() // 当前时间戳
  let time1 = nowTime - day * (60 * 60 * 24 * 1000)
  let time = [dateForm(new Date(time1)), dateForm(nowTime)]
  return time
}
