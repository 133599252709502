var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: _vm.title,
        visible: _vm.showCheckinfo,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showCheckinfo = $event
        },
      },
    },
    [
      _c("div", { staticClass: "clipwrap", attrs: { id: "copy_text" } }, [
        _vm.form.company != ""
          ? _c("p", [_vm._v("公司名称：" + _vm._s(_vm.form.company))])
          : _vm._e(),
        _vm.form.name != ""
          ? _c("p", [_vm._v("客户姓名：" + _vm._s(_vm.form.name))])
          : _vm._e(),
        _vm.form.area != ""
          ? _c("p", [_vm._v("区域：" + _vm._s(_vm.form.area))])
          : _vm._e(),
        _vm.form.channel_name != ""
          ? _c("p", [_vm._v("渠道：" + _vm._s(_vm.form.channel_name))])
          : _vm._e(),
        _vm.form.proxy_three != ""
          ? _c("p", [_vm._v("代理知名品牌：" + _vm._s(_vm.form.proxy_three))])
          : _vm._e(),
        _vm.form.draw_proxy != ""
          ? _c("p", [_vm._v("代理品牌：" + _vm._s(_vm.form.draw_proxy))])
          : _vm._e(),
        _vm.form.remark != ""
          ? _c("p", [_vm._v("备注" + _vm._s(_vm.form.remark))])
          : _vm._e(),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "copybtn",
              attrs: {
                "data-clipboard-text": _vm.sourcecontent,
                type: "primary",
              },
              on: { click: _vm.copy },
            },
            [_vm._v(" 一键复制 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }