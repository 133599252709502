var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.showSetPoster, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.showSetPoster = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: "", "label-suffix": ":", model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.shopSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "分类名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerInquire },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerAdd },
                        },
                        [_vm._v("新 增")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tabledata },
                },
                [
                  _vm._l(_vm.columns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        align: "center",
                        label: item.label,
                        prop: item.prop,
                        width: item.width,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                item.label == "状态"
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.status == 1 ? "启用" : "禁用"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : item.label == "模板"
                                  ? _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row.template.name) + " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      label: "操作",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.status == 1,
                                    expression: "row.status == 1",
                                  },
                                ],
                                attrs: { title: "确认禁用？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteRow(row, 2)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 禁用 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.status != 1,
                                    expression: "row.status != 1",
                                  },
                                ],
                                attrs: { title: "确认启用？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteRow(row, 1)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 启用 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除该分类？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handlerdelete(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "10px" },
                                attrs: { size: "small", type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c("el-pagination", {
                staticStyle: { "text-align": "center", "margin-top": "10px" },
                attrs: {
                  background: "",
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("editsetposter", {
        ref: "editsetposter",
        attrs: { typeid: _vm.form.type_id },
        on: { getlist: _vm.handlerInquire },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }