<template>
  <div style="width: 100%">
    <el-row justify="center">
      <el-col :span="12" class="centerbox">
        <p>企业变更日志更新数量：{{ table.sum }}</p>
        <p v-show="table.list.length==0">暂无数据</p>
        <div v-show="table.list.length!=0" id="Echarts" style="width: 550px;height: 550px"></div>
      </el-col>
      <el-col :span="12" >
        <div class="centerbox">
          <p>企业变更日志更新数量：{{ tabletype.sum }}</p>
          <el-select
            v-model="pid"
            placeholder=""
            style="width: 120px"
            clearable
            @change="handlercatchange"
          >
            <el-option
              v-for="(list, index) in Catelist"
              :key="index"
              :label="list.name"
              :value="list.id"
            ></el-option>
          </el-select>
          <p v-show="tabletype.list.length==0">暂无数据</p>
          <div v-show="tabletype.list.length!=0" id="Echarts1" style="width: 550px;height: 550px">
          </div>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAction } from "@/api/request";
import echarts from "echarts";

export default {
  name: "upRecord",
  props:{
    stime:{
      type:String,
      default:()=>{
        return ''
      }
    },
    etime:{
      type:String,
      default:()=>{
        return ''
      }
    }
  },
  data(){
    return{
      pid:'',
      table:{
        list:[],
      },
      tabletype:{
        list:[],
      },
      Catelist:[]
    }
  },
  mounted() {
    this.handlercate()
    this.handlerbingtu()
  },
  methods:{
    handlercate(){
      getAction("/user/index/cate",{pid:0}).then(res=>{
        this.Catelist=res.data
        this.pid=res.data[0].id
        this.handlerbingtutow()
      })
    },
    handlercatchange(){
      this.handlerbingtutow()
    },
    handlerbingtu(){

      getAction("/api/form/form/update-mall-lists",{type:1,start_time:this.stime,end_time:this.etime}).then(res=>{
        console.log(res)
        this.table=res.data
        const myChart = echarts.init(
          document.getElementById("Echarts")
        )
        let option = {
          title: {
            text: "一级品类占比饼状图",
            bottom: 0,
            x: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b}\n({d}%)",
          },
          series: [
            {
              name: "上新产品数量",
              type: "pie", // 设置图表类型为饼图
              radius: "35%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
              label: {
                show: true,
                formatter: "{b}\n{d}%",
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                alignTo: "edge",
                rich: {
                  time: {
                    fontSize: 10,
                    color: "#999",
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              tooltip: { show: true },

              data: [
                // 数据数组，name 为数据项名称，value 为数据项值
              ],
            },
          ],
        }


        option.series[0].data=res.data.list.map(list=>{
          return {
            name:list.name,
            value:list.count
          }
        })

        myChart.setOption(option);
      })
    },
    handlerbingtutow(){
      getAction("/api/form/form/update-mall-lists",{type:2,start_time:this.stime,end_time:this.etime,pid:this.pid}).then(res=>{
        console.log(res)
        this.tabletype=res.data
        const myChart1 = echarts.init(
          document.getElementById("Echarts1")
        )
        let option = {
          title: {
            text: "二级品类占比饼状图",
            bottom: 0,
            //@ts-ignore
            x: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b}\n({d}%)",
          },
          series: [
            {
              name: "上新产品数量",
              type: "pie", // 设置图表类型为饼图
              radius: "30%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
              label: {
                show: true,
                formatter: "{b}\n{d}%",
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                alignTo: "edge",
                rich: {
                  time: {
                    fontSize: 10,
                    color: "#999",
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              tooltip: { show: true },
              data: [
                // 数据数组，name 为数据项名称，value 为数据项值
              ],
            },
          ],
        }
        option.series[0].data=res.data.list.map(list=>{
          return {
            name:list.name,
            value:list.count
          }
        })

        myChart1.setOption(option);
      })
    }
  }
};
</script>

<style scoped>
.centerbox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.centerbox > p {
  font-weight: 600;
  font-size: 25px;
}
</style>
