<template>
  <div class="index-container">
    <el-row>
      <el-col :span="12">
        <el-table border :data="tablelist" style="width: 100%">
          <el-table-column label="id" prop="id" width="" />
          <el-table-column label="名称" prop="name" width="" />
          <el-table-column label="图片" prop="value" width="180px">
            <template #default="{ row }">
              <el-image :src="row.value" style="width: 70px; height: 30px" />
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="" width="">
            <template #default="{ row }">
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="编辑" :visible.sync="showedit" width="30%">
      <div>
        <el-form ref="form" label-width="80px" :model="form">
          <el-form-item label="banner图" prop="value">
            <upload-img
              ref="uploadImage"
              info-text="图片格式： jpg、png"
              :limit="1"
              :max-size="100"
              @uploadImg="uploadImg"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showedit = false">取 消</el-button>
        <el-button type="primary" @click="handlersave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  export default {
    name: 'Index',
    components: { uploadImg },
    data() {
      return {
        tablelist: [],
        showedit: false,
        form: {},
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      uploadImg(val) {
        if (val.length < 1) {
          this.form.value = ''
        } else {
          this.form.value = val[0]
        }
      },
      handleredit(row) {
        this.showedit = true
        this.form = JSON.parse(JSON.stringify(row))
        //回显图片
        setTimeout(() => {
          this.$refs.uploadImage.img = [row.value]
        }, 100)
      },
      handlerlist() {
        getAction('/api/system/hot/conf', { code: '3d_pic' }).then((res) => {
          this.tablelist = [res.data]
        })
      },
      handlersave() {
        let params = {
          code: this.form.code,
          value: this.form.value,
        }
        postAction('/api/system/hot/set-conf', params)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showedit = false
            this.handlerlist()
          })
          .catch((err) => {
            console.error(err)
          })
      },
    },
  }
</script>

<style scoped></style>
