<template>
  <el-dialog
    title="关联直播间"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="orderTest-container">
      <el-form ref="form" inline label-position="right" label-suffix=":">
        <el-form-item prop="title">
          <el-input v-model="form.title" clearable placeholder="直播标题"></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button type="primary" @click="handleAdd">新建</el-button>
        </el-form-item>
      </el-form>
      <el-table border stripe :data="list" v-loading="loading">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        ></el-table-column>
        <el-table-column prop="" align="center" label="操作" fixed="right" min-width="100px">
          <template #default="{ row }">
            <el-button type="text" @click.native.prevent="tableEdit(row)">编辑</el-button>
            <el-button type="text" @click.native.prevent="tableDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        style="text-align: center"
      ></el-pagination>
      <AddEdit ref="addEdit" @refresh="fetchData"></AddEdit>
    </div>
  </el-dialog>
</template>
<script>
import AddEdit from "./components/addEdit.vue"
import { getAction, postAction } from "@/api/request";
export default {
  name: "LinkLive",
  components: { AddEdit },
  data() {
    return {
      showDialog: false,
      form: {
        title: "",
        page: 1,
        limit: 10,
      },
      // 获取详情参数
      params: {},
      loading: false,
      total: 0,
      type: "",
      list: [],
      layout: "total, sizes, prev, pager, next, jumper",
      columns: [
        {
          order: 1,
          label: "ID",
          prop: "id",
          width: "100",
        },
        {
          order: 2,
          label: "直播标题",
          prop: "title",
          width: "",
        },
        {
          order: 3,
          label: "所属栏目",
          prop: "type_name",
          width: "",
        },
        {
          order: 4,
          label: "跳转类型",
          prop: "jump_type",
          width: "",
        },
        {
          order: 5,
          label: "状态",
          prop: "status_txt",
          width: "130",
        },
      ],
    }
  },
  created() {},
  mounted() {},

  watch: {
    showDialog(v) {
      if (v) {
        if (this.type) {
          this.fetchData()
        }
      } else {
        this.form.title = ""
      }
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.form.page = val
      this.fetchData()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      try {
        let res = await getAction("/live/index/rel", { ...this.form, type_id: this.type })
        this.list = res.data
        this.total = Number(res.totalCount) || 0
        this.loading = false
      } catch (err) {
        console.log("接口调用错误", err)
        this.loading = false
      }
    },
    handleQuery() {
      this.form.page = 1
      this.fetchData()
    },
    handleAdd() {
      // this.$refs.addEdit.form.jump_type_id=this.params.type
      this.$refs.addEdit.editType = 1
      this.$refs.addEdit.showDialog = true
      this.$refs.addEdit.params.type = this.type
      this.$refs.addEdit.fetchData({type_name:this.params.name,jump_type:this.params.type_txt,jump_type_id:this.params.type})
    },
    // tablehandle
    tableEdit(row) {
      this.$refs.addEdit.form.jump_type_id=this.params.type
      this.$refs.addEdit.editType = 2
      this.$refs.addEdit.showDialog = true

      this.$refs.addEdit.params.id = row.id
      this.$refs.addEdit.params.type = this.type
      this.$refs.addEdit.fetchData(row)
    },
    tableDelete(row) {
      this.$confirm("确定删除此项吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await postAction("/live/index/del-rel", { id: row.id })
          this.$message.success("删除成功")
          this.fetchData()
        })
        .catch(() => {
          console.log("no")
        })
    },
  },
}
</script>
<!--<style lang="scss" scoped></style>-->
