<template>
<div>
  <el-dialog
    title="详情"
    :visible.sync="showinfo"
    width="60%"
    center
    >
    <div>
      <el-form ref="form" :model="form" label-width="130px" label-suffix="：">
        <el-form-item label="荣获专利" v-show="form.patent">{{form.patent}}</el-form-item>
        <!-- <el-form-item label="店铺名称">{{form.mall_name}}</el-form-item>
         <el-form-item label="店铺简称">{{form.mall_name_short}}</el-form-item>
         <el-form-item label="所属行业"><span v-for="list in form.trade" :key="list">{{list}}、</span></el-form-item>&lt;!&ndash; 没有对应上&ndash;&gt;
         <el-form-item label="招商省份">{{form.province}}</el-form-item>-->
        <el-form-item label="店铺logo" v-show="form.logo">
          <el-image
            v-show="form.logo"
            style="width: 100px; height: 100px"
            :src="form.logo"
            :preview-src-list="[form.logo]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </el-form-item>
        <el-form-item label="店铺背景" v-show="form.bg_image">
          <el-image
            v-show="form.bg_image"
            style="width: 100px; height: 100px"
            :src="form.bg_image"
            :preview-src-list="[form.bg_image]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </el-form-item>
        <el-form-item label="企业介绍" v-show="form.introduction">
          <el-input
            style="overflow: auto"
            type="textarea"
            :rows="6"
            v-html="form.introduction">
          </el-input>
        </el-form-item>
        <el-form-item label="企业展厅" v-show="form.honors">
          <el-input
            style="overflow: auto"
          type="textarea"
          :rows="6"
          v-html="form.honors">
        </el-input>
        </el-form-item><!-- 没有对应上-->
        <el-form-item label="扶持政策" v-show="form.supportive_policy">
          <el-input
            style="overflow: auto"
            type="textarea"
            :rows="6"
            v-html="form.supportive_policy">
          </el-input>
        </el-form-item>
<!--        <el-form-item label="企业视频">
          <video v-show="form.video_url" :src="form.video_url" controls style="width: 400px;height: 200px"/>
        </el-form-item>
        <el-form-item label="视频封面">
          <el-image
            v-show="form.video_cover"
            style="width: 100px; height: 100px"
            :src="form.video_cover"
            :preview-src-list="[form.video_cover]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </el-form-item>-->
        <el-form-item label="产线展示" v-show="form.line_display"><!-- 没有对应上-->
          <el-input
            style="overflow: auto"
            type="textarea"
            :rows="6"
            v-html="form.line_display">
          </el-input>
        </el-form-item>
        <el-form-item label="店铺海报" v-show="form.share_img">
          <el-image
            v-show="form.share_img"
            style="width: 100px; height: 100px"
            :src="form.share_img"
            :preview-src-list="[form.share_img]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-show="form.status!=1" @click="deleteRow(form,3)">拒绝</el-button>
      <el-button v-show="form.status!=1" type="primary" @click="deleteRow(form,1)">通过</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import { postAction } from "@/api/request";

export default {
  name: "info",
  data(){
    return{
      showinfo:false,
      form:{}
    }
  },
  methods:{
    deleteRow(id,status){
      postAction("/audit/shop/item-audit",{id:id,status}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.$emit("getlist")
        }
      })
    },
  }
};
</script>

<style scoped>

</style>
