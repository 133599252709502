var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "来源" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "来源" },
                  model: {
                    value: _vm.form.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "source", $$v)
                    },
                    expression: "form.source",
                  },
                },
                _vm._l(_vm.sourcelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需求类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "需求类型" },
                  model: {
                    value: _vm.form.genre,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "genre", $$v)
                    },
                    expression: "form.genre",
                  },
                },
                _vm._l(_vm.genrelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需求客户" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "需求客户" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "昵称" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "一级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "一级分类" },
                  on: { change: _vm.handlercat },
                  model: {
                    value: _vm.form.root_cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "root_cat_id", $$v)
                    },
                    expression: "form.root_cat_id",
                  },
                },
                _vm._l(_vm.cateselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "二级分类" },
                  model: {
                    value: _vm.form.cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cat_id", $$v)
                    },
                    expression: "form.cat_id",
                  },
                },
                _vm._l(_vm.catselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提报人" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "提报人" },
                model: {
                  value: _vm.form.tibao_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tibao_name", $$v)
                  },
                  expression: "form.tibao_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核人" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "审核人" },
                model: {
                  value: _vm.form.shenhe_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "shenhe_name", $$v)
                  },
                  expression: "form.shenhe_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需求电话" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: {
                  clearable: "",
                  maxlength: "11",
                  placeholder: "需求电话",
                },
                model: {
                  value: _vm.form.telephone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "telephone", $$v)
                  },
                  expression: "form.telephone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "公司名称" },
                model: {
                  value: _vm.form.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "审核状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.showSelsect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选品方案状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "选品方案状态" },
                  model: {
                    value: _vm.form.sel_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sel_status", $$v)
                    },
                    expression: "form.sel_status",
                  },
                },
                _vm._l(_vm.selStatusList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "云会议状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "云会议状态" },
                  model: {
                    value: _vm.form.meeting_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "meeting_status", $$v)
                    },
                    expression: "form.meeting_status",
                  },
                },
                _vm._l(_vm.meetingStatusList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "匹配形式" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "匹配形式" },
                  model: {
                    value: _vm.form.match_way,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "match_way", $$v)
                    },
                    expression: "form.match_way",
                  },
                },
                _vm._l(_vm.matchtypelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "查询时间" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.timetypelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "需求形式" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.xuqiutypelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timelist,
                  callback: function ($$v) {
                    _vm.timelist = $$v
                  },
                  expression: "timelist",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "价格带" },
                  model: {
                    value: _vm.form.wine_price,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "wine_price", $$v)
                    },
                    expression: "form.wine_price",
                  },
                },
                _vm._l(_vm.winepricelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "省份" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "省" },
                  on: { change: _vm.handleProChange },
                  model: {
                    value: _vm.form.pid,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pid", $$v)
                    },
                    expression: "form.pid",
                  },
                },
                _vm._l(_vm.provinceselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "市" },
                  model: {
                    value: _vm.form.cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cid", $$v)
                    },
                    expression: "form.cid",
                  },
                },
                _vm._l(_vm.citylist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务人员" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "服务人员" },
                model: {
                  value: _vm.form.service_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "service_name", $$v)
                  },
                  expression: "form.service_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否被查看过" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "是否被查看过" },
                  model: {
                    value: _vm.form.be_view,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "be_view", $$v)
                    },
                    expression: "form.be_view",
                  },
                },
                _vm._l(_vm.beviewlist, function (list) {
                  return _c("el-option", {
                    key: list.name,
                    attrs: { label: list.name, value: list.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _vm.userid == 797 ||
          _vm.userid == 37 ||
          _vm.userid == 10000025 ||
          _vm.userid == 10000010 ||
          _vm.userid == 10000013
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerExport },
                    },
                    [_vm._v("导 出")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata, height: _vm.height },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "沟通截图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                    attrs: { gutter: 10 },
                                  },
                                  _vm._l(
                                    row[item.prop],
                                    function (list, index) {
                                      return _c(
                                        "span",
                                        { key: index },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                "preview-src-list": [list],
                                                src: list,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: {
                                                    slot: "placeholder",
                                                  },
                                                  slot: "placeholder",
                                                },
                                                [
                                                  _vm._v(" 加载中 "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dot" },
                                                    [_vm._v("...")]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          : item.label == "沟通视频"
                          ? _c("div", [
                              row.screenshot_type == 2
                                ? _c(
                                    "div",
                                    _vm._l(
                                      row[item.prop],
                                      function (list, index) {
                                        return _c("span", { key: index }, [
                                          _c("video", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100px",
                                            },
                                            attrs: { controls: "", src: list },
                                          }),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : item.label == "沟通语音"
                          ? _c("div", [
                              row.screenshot_type == 3
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(row.audio_time) + "s"),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showVideoText(row)
                                          },
                                        },
                                      },
                                      [_vm._v("语音转文字")]
                                    ),
                                    _c("br"),
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.playVideo(row[item.prop])
                                          },
                                        },
                                      },
                                      [_vm._v("播放")]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : item.label == "沟通语音-图片说明"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                    attrs: { gutter: 10 },
                                  },
                                  _vm._l(
                                    row[item.prop],
                                    function (list, index) {
                                      return _c(
                                        "span",
                                        { key: index },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                "preview-src-list": [list],
                                                src: list,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: {
                                                    slot: "placeholder",
                                                  },
                                                  slot: "placeholder",
                                                },
                                                [
                                                  _vm._v(" 加载中 "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dot" },
                                                    [_vm._v("...")]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          : item.label == "提审头像"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                    attrs: { gutter: 10 },
                                  },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                        },
                                        attrs: {
                                          "preview-src-list": [row.then_avatar],
                                          src: row.then_avatar,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [_vm._v("暂无头像")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : item.label == "实时头像"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                    attrs: { gutter: 10 },
                                  },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                        },
                                        attrs: {
                                          "preview-src-list": [row.avatar],
                                          src: row.avatar,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(" 加载中 "),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [_vm._v("暂无头像")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : item.label == "需求分类"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 1,
                                        expression: "row[item.prop] == 1",
                                      },
                                    ],
                                  },
                                  [_vm._v("选品需求")]
                                ),
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 2,
                                        expression: "row[item.prop] == 2",
                                      },
                                    ],
                                    attrs: { type: "success" },
                                  },
                                  [_vm._v(" 会议需求 ")]
                                ),
                              ],
                              1
                            )
                          : item.label == "选品方案预览"
                          ? _c(
                              "div",
                              [
                                _c("vue-qr", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.sel_status >= 2,
                                      expression: "row.sel_status >= 2",
                                    },
                                  ],
                                  attrs: {
                                    margin: 10,
                                    size: 100,
                                    text: row.plan_qr,
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.sel_status >= 2,
                                        expression: "row.sel_status >= 2",
                                      },
                                    ],
                                  },
                                  [_vm._v("请用企业微信扫码")]
                                ),
                              ],
                              1
                            )
                          : item.label == "信息复核"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCheckinfo(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看 ")]
                                ),
                              ],
                              1
                            )
                          : item.label == "级别"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: row.status != 2 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handlerlevel($event, row)
                                      },
                                    },
                                    model: {
                                      value: row[item.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, item.prop, $$v)
                                      },
                                      expression: "row[item.prop]",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "无级别", value: "0" },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "一级（小商）",
                                        value: "1",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "二级（中商）",
                                        value: "2",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "三级（大商）",
                                        value: "3",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : item.label == "选品方案"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 0,
                                        expression: "row[item.prop] == 0",
                                      },
                                    ],
                                  },
                                  [_vm._v("不可创建")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 1,
                                        expression: "row[item.prop] == 1",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 待创建 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 2,
                                        expression: "row[item.prop] == 2",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 已创建可编辑预览 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 3,
                                        expression: "row[item.prop] == 3",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 已被查看 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 4,
                                        expression: "row[item.prop] == 4",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 已确认 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] > 1,
                                        expression: "row[item.prop] > 1",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerfasong(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.is_send == 1 ? "已发送" : "发送"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label == "云会议"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 0,
                                        expression: "row[item.prop] == 0",
                                      },
                                    ],
                                  },
                                  [_vm._v("不可创建")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 1,
                                        expression: "row[item.prop] == 1",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 待创建 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row[item.prop] == 2,
                                        expression: "row[item.prop] == 2",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerplan(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 已创建可查看 ")]
                                ),
                              ],
                              1
                            )
                          : item.label == "已指定店铺"
                          ? _c(
                              "div",
                              _vm._l(row.this_mall_name, function (list) {
                                return _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [_vm._v(" " + _vm._s(list) + " ")]
                                )
                              }),
                              0
                            )
                          : item.label == "不匹配店铺"
                          ? _c(
                              "div",
                              _vm._l(row.no_match_mall_name, function (list) {
                                return _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [_vm._v(" " + _vm._s(list) + " ")]
                                )
                              }),
                              0
                            )
                          : item.label == "是否云交会用户"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(row[item.prop] == 1 ? "是" : "不是") +
                                  " "
                              ),
                            ])
                          : item.label == "经销商身份"
                          ? _c(
                              "div",
                              [
                                row[item.prop] == "审"
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row[item.prop]) + " "
                                        ),
                                      ]
                                    )
                                  : [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: { trigger: "hover" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "reference",
                                                fn: function () {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(row[item.prop])
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.identity_reason) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "220",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.is_invalid == 1 && row.is_expire != 2
                      ? _c(
                          "div",
                          [
                            row.status == 1 || row.status == -2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small", type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handlerexamine(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 拒绝 ")]
                                    ),
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确认审核通过？" },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handlertong(row, 2)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "10px" },
                                            attrs: {
                                              slot: "reference",
                                              size: "small",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v(" 通过 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            row.status == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确认失效？" },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handlertong(row, 4)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "10px" },
                                            attrs: {
                                              slot: "reference",
                                              size: "small",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v(" 失效 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "10px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerperfect(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 待完善 ")]
                                )
                              : _vm._e(),
                            row.status == -2
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "10px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerEditperfect(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            row.status == -1 || row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "10px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.disappointMall(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 不匹配店铺 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "10px" },
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLog(row)
                          },
                        },
                      },
                      [_vm._v(" 修改记录 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("selection", { ref: "select", on: { getlist: _vm.handlerInquire } }),
      _c("Refusereason", {
        ref: "refusereason",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "append-to-body": "",
            title: "编辑客户画像",
            visible: _vm.showCustomer,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCustomer = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "150px", model: _vm.customerInfo },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入姓名", type: "text" },
                        model: {
                          value: _vm.customerInfo.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "name", $$v)
                          },
                          expression: "customerInfo.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户电话" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.customerInfo.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "telephone", $$v)
                          },
                          expression: "customerInfo.telephone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.customerInfo.corp_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "corp_name", $$v)
                          },
                          expression: "customerInfo.corp_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "行业所在地区", prop: "pid" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "省" },
                          on: { change: _vm.handlerpro },
                          model: {
                            value: _vm.customerInfo.pid,
                            callback: function ($$v) {
                              _vm.$set(_vm.customerInfo, "pid", $$v)
                            },
                            expression: "customerInfo.pid",
                          },
                        },
                        _vm._l(_vm.provinceselect, function (list, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "市" },
                          model: {
                            value: _vm.customerInfo.cid,
                            callback: function ($$v) {
                              _vm.$set(_vm.customerInfo, "cid", $$v)
                            },
                            expression: "customerInfo.cid",
                          },
                        },
                        _vm._l(_vm.aceselect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "年营业额（万元）" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.customerInfo.biz_trade,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "biz_trade", $$v)
                          },
                          expression: "customerInfo.biz_trade",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "终端网点数" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.customerInfo.biz_terminal_network,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.customerInfo,
                              "biz_terminal_network",
                              $$v
                            )
                          },
                          expression: "customerInfo.biz_terminal_network",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "代理品牌数" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.customerInfo.biz_proxy,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "biz_proxy", $$v)
                          },
                          expression: "customerInfo.biz_proxy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "代理知名品牌" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.customerInfo.biz_proxy_three,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "biz_proxy_three", $$v)
                          },
                          expression: "customerInfo.biz_proxy_three",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "代理品牌" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.customerInfo.proxy,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "proxy", $$v)
                          },
                          expression: "customerInfo.proxy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.customerInfo.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerInfo, "remark", $$v)
                          },
                          expression: "customerInfo.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.qdchannellist,
                            callback: function ($$v) {
                              _vm.qdchannellist = $$v
                            },
                            expression: "qdchannellist",
                          },
                        },
                        _vm._l(_vm.qdcheckList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.id } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品类" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.plcatelist,
                            callback: function ($$v) {
                              _vm.plcatelist = $$v
                            },
                            expression: "plcatelist",
                          },
                        },
                        _vm._l(_vm.plcheckList, function (pl, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: pl.id } },
                            [_vm._v(" " + _vm._s(pl.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { margin: "0 auto" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showCustomer = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersave },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("AppointMall", {
        ref: "appointmall",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("DisappointMall", {
        ref: "disappointmall",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("ChangeLog", {
        ref: "changelog",
        attrs: { rowid: _vm.rowid, username: _vm.username },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("Checkinfo", { ref: "checkinfo" }),
      _c("Noperfect", {
        ref: "noperfect",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
      _c("Editnoperfect", {
        ref: "editnoperfect",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerInquire },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowVideoText,
            "append-to-body": "",
            width: "40%",
          },
          on: { close: _vm.closeVideoText },
        },
        [_c("div", [_vm._v(_vm._s(_vm.videoText))])]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowVideoPlayer,
            "append-to-body": "",
            width: "40%",
          },
          on: { close: _vm.closeVideoPlayer },
        },
        [
          _c("div", [
            _c("audio", {
              ref: "videoplayer",
              attrs: { src: _vm.videoUrl, controls: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }