<template>
  <el-dialog content :visible.sync="isShowDelete" width="900px">
    <div>
      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template #default="{ row }">
            {{ row[item.prop] }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="220">
          <template #default="{ row }">
            <el-button
              size="small"
              style="margin: 10px"
              type="text"
              @click.native.prevent="handlerRecovery(row)"
            >
              恢复
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>
<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'addedit',
    data() {
      return {
        isShowDelete: false,
        form: {
          goods_name: '',
          is_del: 1,
          page: 1,
          limit: 10,
        },
        tablenum: [],
        total: 0,
        tabledata: [],
        columns: [
          {
            label: '产品ID',
            prop: 'id',
            width: '',
          },
          {
            label: '产品标题',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '品类',
            prop: 'pcat_name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
        ],

        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    methods: {
      handlerlist() {
        getAction('/api/form/search-rank/lists', this.form).then((res) => {
          this.tabledata = res.data.list
          console.log(res.data, this.tabledata)
          this.total = Number(res.totalCount)
        })
      },
      //恢复
      handlerRecovery(row) {
        getAction('/api/form/search-rank/renew', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
            this.handlerlist()
          }
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>
