import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4a0d5afa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0d5afa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\yunjiaohui\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a0d5afa')) {
      api.createRecord('4a0d5afa', component.options)
    } else {
      api.reload('4a0d5afa', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=4a0d5afa&scoped=true", function () {
      api.rerender('4a0d5afa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/datastatis/packagestatis/giftsPackage/index.vue"
export default component.exports