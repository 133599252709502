<template>
  <!-- NAME[epic=组件] 上传多图 -->
  <div class="orderTest-container">
    <div class="imgList" style="display: inline-block" v-loading="loading">
      <div
        style="position: relative"
        class="imgList"
        v-for="(item, index) in imgVideoArr"
        :key="index"
        v-dragging="{ item: item, list: imgVideoArr, group: 'item', key: index }"
      >
        <div v-if="/\.(mp4|avi|mov|flv|wmv|mkv)$/i.test(item)">
          <video
            style="width: 208px; height: 148px; margin-right: 10px"
            :src="item"
            controls
          >
          </video>
          <i class="el-icon-error close"  v-show="!disab" v-if="!loading" @click="handleDeleteVideo(index)"></i>
        </div>
        <div v-else>
          <el-image
            style="width: 148px; height: 148px; margin-right: 10px"
            :src="item"
            fit="cover"
            :preview-src-list="img"
          ></el-image>
          <i
            class="el-icon-error close"
            v-show="!disab"
            v-if="!loading"
            @click="handleDeleteVideo(index)"
          ></i>
        </div>
      </div>

      <el-upload
        v-show="imgVideoArr.length < limit"
        class="upload-demo"
        list-type="picture-card"
        multiple
        :show-file-list="false"
        :http-request="uploadImg"
        :on-exceed="limitLong"
        name="file"
        accept="video/mp4,.png, .jpg, .jpeg"
        action="#"
        :disabled="disab"
      >
        <i class="el-icon-plus avatar-uploader-icon" ></i>
      </el-upload>
    </div>
    <div style="line-height: 1.2; margin-top: 10px; color: red; font-size: 12px" v-if="showInfo">
      {{ infoText }}
    </div>
  </div>
</template>
<script>
import { postAction, uploadAction } from "@/api/request";
import axios from "axios";
export default {
  name: "uploadImgVideo",
  components: {},
  props: {
    limit: {
      type: Number,
      default: 5,
    },
    maxSize: {
      type: Number,
      default: 1,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    disab: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default:
        "（可拖拽进行排序，视频大小控制在100MB以内，视频格式：MP4）",
    },
  },
  data() {
    return {
      dialogVisible: false,
      imgVideoArr: [],
      loading: false,
      img: [],
    }
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    imgVideoArr(v) {
      if (v.length > this.limit) {
        this.$message.error(`最多上传${this.limit}一个视频或图片`)
        this.imgVideoArr = this.imgVideoArr.splice(0, this.limit)
      }
      this.img = []
      v.forEach(list=>{
        if(!/\.(mp4|avi|mov|flv|wmv|mkv)$/i.test(list)){
          this.img.push(list)
        }
      })
      console.log(v,"watch检测")
      this.$emit("getImgVideos", v)
    },
  },
  methods: {
    async getOssData() {
      return postAction("/api/common/common/oss-sign").then(res=>{
        console.log(res)
        return res.data
      })
    },
    // 生成文件名，作为 key 使用
    generateFileName(ossData, file){
      const suffix = file.name.slice(file.name.lastIndexOf('.'));
      const filename = Date.now() + suffix;
      return ossData.dir +'/'+ filename;
    },
    async uploadImg(file) {
      console.log(file)
      if (file.file.size > this.maxSize * 1024 * 1024) {
        this.loading = false
        this.$message.error('图片/视频大小不能超过' + this.maxSize + 'M')
      } else {
        this.loading = true
        const ossData = await this.getOssData()
        const key = this.generateFileName(ossData, file.file)
        const formdata = new FormData()

        // 注意参数的顺序，key 必须是第一位，表示OSS存储文件的路径
        formdata.append('key', key)
        formdata.append('OSSAccessKeyId', ossData.accessid)
        formdata.append('policy', ossData.policy)
        formdata.append('signature', ossData.signature)
        // 文件上传成功默认返回 204 状态码，可根据需要修改为 200
        formdata.append('success_action_status', '200')
        // file 必须放在最后一位
        formdata.append('file', file.file)

        await axios.post(ossData.host, formdata, {
          headers: {
            'Content-Type': "multipart/form-data",
          }
        }).then(res => {
          console.log(res)
          if (res.status === 200) {
            this.imgVideoArr.push('https://res.httoutiao.com/' + key)
            this.$emit("uploadImgVideo", this.imgVideoArr)
            this.loading = false
          }
        })
      }
    },
    // 文件超出
    limitLong() {
      this.$message.error(`最多上传${this.limit}张图片`)
      return false
    },
    handleDeleteVideo(index) {
      this.imgVideoArr.splice(index, 1)
      this.$emit("uploadVideo", this.imgVideoArr)
    },
    handlePictureCardPreview(file) {
      this.dialogVideoUrl = file.url
      this.dialogVisible = true
    },
    onEnd(e) {
      console.log(e)
    },
  },
}
</script>
<style lang="scss" scoped>
.imgList {
  display: flex !important;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.close {
  position: absolute;
  top: -9px;
  right: -4px;
  font-size: 22px;
  cursor: pointer;
}
</style>
