<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.user_id" clearable placeholder="用户id" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.nickname" clearable placeholder="用户昵称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.phone" clearable placeholder="用户手机号" />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_click_staff"
          clearable
          filterable
          placeholder="被邀请人是否员工"
          style="width: 160px"
        >
          <el-option
            label="是"
            value="1"
          />
          <el-option
            label="否"
            value="2"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_staff"
          clearable
          filterable
          placeholder="邀请人是否员工"
          style="width: 160px"
        >
          <el-option
            label="是"
            value="1"
          />
          <el-option
            label="否"
            value="2"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.htyun_kind" clearable filterable style="width: 160px;" placeholder="员工一级事业部"
                   @change="setNameSelect">
          <el-option v-for="(list,index) in deptSelect"
                     :key="index"
                     :value="list.htyun_kind"
                     :label="list.htyun_kind" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.htyun_dept" clearable filterable style="width: 160px;" placeholder="员工二级部门"
                   @change="setTowSelect">
          <el-option v-for="(list,index) in deptTowSelect"
                     :key="index"
                     :value="list.htyun_dept"
                     :label="list.htyun_dept" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.htyun_name" clearable filterable style="width: 160px;" placeholder="员工姓名">
          <el-option v-for="(list,index) in nameSelect"
                     :key="index"
                     :value="list.htyun_name"
                     :label="list.htyun_name" />
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-autocomplete
          style="width: 160px;"
          v-model="form.mall_name"
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          clearable
          placeholder="请输入店铺名称"
          @select="handleSelectmall"
        ></el-autocomplete>

      </el-form-item>
      <el-form-item>
        <el-autocomplete
          style="width: 160px;"
          v-model="form.goods_name"
          :fetch-suggestions="handlergoods"
          value-key="goods_name"
          clearable
          placeholder="请输入商品名称"
          @select="handleSelectgoods"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.page_type"
          clearable
          filterable
          placeholder="页面类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
<!--    <h6>备注：按店铺搜索时展示店铺主页和该店铺下的所有产品页的相关信息</h6>-->
    <el-table border :data="tabledata" style="width: 100%" @sort-change="sortChange" v-loading="loading">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :sortable="item.sort"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";

export default {
  name: "Index",
  data() {
    return {
      loading: false,
      form: {
        /**
         * 结束时间
         */
        end_time: "",
        /**
         * 商品id
         */
        goods_id: "",
        /**
         * 员工部门
         */
        htyun_dept: "",
        /**
         * 员工姓名
         */
        htyun_name: "",
        /**
         * 是否员工1是2否
         */
        is_staff: "",
        is_click_staff:'',
        /**
         * 用户昵称
         */
        nickname: "",
        /**
         * 升序降序 asc降序，desc升序
         */
        order: "",
        /**
         * 页面类型
         */
        page_type: "",
        /**
         * 用户手机号
         */
        phone: "",
        /**
         * 店铺id
         */
        shop_id: "",
        /**
         * 排序方式pv，uv
         */
        sort: "",
        /**
         * 开始时间
         */
        start_time: "",
        /**
         * 用户id
         */
        user_id: "",
        page: 1,
        limit: 10
      },
      time: [],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "浏览时间",
          prop: "created",
          width: ""
        },

        {
          label: "用户ID",
          prop: "user_id",
          width: ""
        },

        {
          label: "用户昵称",
          prop: "nickname",
          width: ""
        },
        {
          label: "用户手机号",
          prop: "phone",
          width: ""
        },
        {
          label: "被邀请人是否员工",
          prop: "click_staff",
          width: ""
        },
        {
          label: "浏览页面类型",
          prop: "page_type",
          width: ""
        },
        {
          label: "页面具体名称",
          prop: "page_name",
          width: ""
        },

        {
          label: "产品所属店铺",
          prop: "shop_name",
          width: ""
        },

        {
          label: "页面PV",
          prop: "pv",
          width: "",
          sort: 'custom',
        },
        {
          label: "页面UV",
          prop: "uv",
          width: "",
          sort: 'custom',
        },
        {
          label: "分享者昵称",
          prop: "share_nickname",
          width: ""
        },
        {
          label: "分享者手机号码",
          prop: "share_phone",
          width: ""
        },
        {
          label: "邀请人是否员工",
          prop: "is_staff",
          width: ""
        },
        {
          label: "员工姓名",
          prop: "htyun_name",
          width: ""
        },
        {
          label: "员工一级事业部",
          prop: "htyun_kind",
          width: ""
        },
        {
          label: "员工二级部门",
          prop: "htyun_dept",
          width: ""
        },
      ],
      staffSelect: [],
      total: 0,
      deptSelect: [],
      deptTowSelect:[],
      nameSelect: []
    };
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0];
        this.form.end_time = v[1];
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    }
  },
  mounted() {
    this.handlerInquire();
    this.handlerSelect();
  },
  methods: {
    //排序
    sortChange({ column, prop, order }) {
      console.log('column, prop, order', column, prop, order)
      this.form.sort = prop
      // 值 asc 小到大  和  desc  大到小]
      if (order === 'descending') {
        this.form.order = 'desc'
      } else if (order === 'ascending') {
        this.form.order = 'asc'
      } else {
        this.form.sort = ''
        this.form.order = ''
      }
      this.handlerlist()
    },
    async handlerSelect() {
      const dept = await getAction("/api/form/form/dept-one");
      this.deptSelect = dept.data;
      const staff = await getAction("/api/form/form/share-type");
      this.staffSelect = staff.data;

    },
    async setTowSelect(e) {
      this.form.htyun_name = "";
      const staff = await getAction("/form/form/staff", { htyun_dept: e, htyun_kind: this.form.htyun_kind });
      this.nameSelect = staff.data;
    },
    async setNameSelect(e) {
      this.form.htyun_name = "";
      this.form.htyun_dept = "";
      const staff = await getAction("/form/form/staff", { htyun_dept: this.form.htyun_dept ,htyun_kind:e});
      this.nameSelect = staff.data;
      const staTow = await getAction("/form/form/dept", { htyun_kind: e });
      this.deptTowSelect= staTow.data
    },
    handlerInquire() {
      this.form.page = 1;
      this.handlerlist();
    },
    handlerlist() {
      this.loading = true;
      getAction("/form/form/share-tj", this.form).then(
        (res) => {
          this.tabledata = res.data;
          this.total = Number(res.totalCount);
          this.loading = false;
        }
      );
    },
    handlerExport() {
      postAction("/form/form/share-tj-export", this.form).then(
        (res) => {
         this.$message({
           message: "已加入导出队列，请稍后查看",
           type: "success",
         })
        }
      );
    },
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.shop_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.goods_id=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.form.shop_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.goods_id=list.id
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.handlerlist();
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.handlerlist();
    }
  }
};
</script>

<style scoped></style>
