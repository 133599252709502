<template>
  <el-dialog
    v-loading="loading"
    append-to-body
    title="调查问卷详情"
    :visible.sync="showinfo"
    width="30%"
  >
    <div>
      <el-form
        ref="form"
        label-position="right"
        label-suffix="："
        label-width="140px"
        :model="surveyinfo"
      >
        <el-form-item label="是否满意" prop="total">
          {{ surveyinfo.degree_type }}
        </el-form-item>
        <el-form-item
          v-if="surveyinfo.degree_reason && surveyinfo.degree_reason.length > 1"
          label="原因"
          prop="total"
        >
          {{ surveyinfo.degree_reason }}
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'AddInfo',
    data() {
      return {
        showinfo: false,
        loading: false,
        surveyinfo: {
          degree_type: '',
          degree_reason: '',
        },
      }
    },
  }
</script>
