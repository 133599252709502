<template>
  <div class="index-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入产品标题"
          clearable
          v-model="form.keywords"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item v-if="tabtype == 3">
        <el-select v-model="form.cate_id">
          <el-option
            v-for="list in cateList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '封面图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template #default="{ row }">
          <el-button
            @click.native.prevent="onSort(row)"
            v-if="tabtype == 1"
            type="text"
            size="small"
          >
            分拣
          </el-button>
          <el-button
            @click.native.prevent="onEdit(row)"
            v-if="tabtype == 2 || tabtype == 3"
            type="text"
            size="small"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认删除？" @confirm="onDelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <editcom ref="editcom" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import editcom from './editCom'
  export default {
    name: 'index',
    components: { editcom },
    props: {
      tabtype: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        form: {
          keywords: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '',
          },

          {
            label: '新品标题',
            prop: 'title',
            width: '160',
          },

          {
            label: '封面图',
            prop: 'cover_img',
            width: '',
          },
          {
            label: '品类',
            prop: 'category',
            width: '',
          },
          {
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            label: '抓取时间',
            prop: 'addtime',
            width: '',
          },
        ],
        total: 0,
        cateList: [], //品类列表
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      // this.handlerInquire()
      this.getList()
    },
    methods: {
      // 查询
      onSearch() {
        this.form.page = 1
        this.getList()
      },
      //获取新品列表
      getList() {
        let params = this.form
        getAction('/api/daily/new-daily/food-caiji-list', params).then(
          (res) => {
            console.log('获取新品----', res)
            if (res.request_code == 200) {
              this.tabledata = res.data.list
              this.total = parseInt(res.data.total)
            }
          }
        )
      },
      // 待编辑列表
      getEditList() {
        getAction('/api/daily/new-daily/edit-new-daily-list', params).then(
          (res) => {
            if (res.request_code == 200) {
            }
          }
        )
      },
      // 发布新品列表
      getPublishList() {
        getAction('/api/daily/new-daily/publish-new-daily-list', params).then(
          (res) => {
            if (res.request_code == 200) {
            }
          }
        )
      },
      // 分拣操作
      onSort(row) {
        getAction('/api/daily/new-daily/food-caiji-sorting', {
          id: row.id,
        }).then((res) => {
          if (res.request_code == 200) {
            this.$message.success(res.msg)
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 抓取新品删除操作
      onDelete(row) {
        getAction('/api/daily/new-daily/food-caiji-del', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message.success('删除成功')
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      // 选项卡点击操作
      handleClick(tab, event) {
        console.log(tab, event)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getList()
      },
    },
  }
</script>

<style scoped>
  .round {
    width: 380px;
    height: 380px;
  }
  .img {
    max-width: 100%;
  }
</style>
