var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showadd, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                "label-suffix": "：",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "系统" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: 11, label: "android" },
                      }),
                      _c("el-option", { attrs: { value: 12, label: "ios" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "app名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.appname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appname", $$v)
                      },
                      expression: "form.appname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "version", $$v)
                      },
                      expression: "form.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.buildnum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "buildnum", $$v)
                      },
                      expression: "form.buildnum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "强制升级" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.form.upgrade,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upgrade", $$v)
                      },
                      expression: "form.upgrade",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.form.isshow,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isshow", $$v)
                      },
                      expression: "form.isshow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "升级描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.form.info,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "info", $$v)
                      },
                      expression: "form.info",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载路径" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "包体大小" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.size,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "size", $$v)
                      },
                      expression: "form.size",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "升级时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.releasedate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "releasedate", $$v)
                      },
                      expression: "form.releasedate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "定时发布" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.push_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "push_time", $$v)
                      },
                      expression: "form.push_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }