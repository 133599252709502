<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    append-to-body
    width="900px"
  >
    <el-table v-loading="listLoading" :data="list" border>
      <el-table-column
        align="center"
        label="序号"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="队列事件ID"
        prop="event_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="增长次数"
        prop="num"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="队列状态">
        <template #default="{ row }">
          <el-tag v-if="row.status == 0" type="warning">待执行</el-tag>
          <el-tag v-if="row.status == 1" type="success">执行完毕</el-tag>
          <el-tag v-if="row.status == 2" type="info">执行终止</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" width="180" label="执行时间">
        <template #default="{ row }">
          <span v-if="row.status == 1">{{ row.modified }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="created"
        show-overflow-tooltip
        width="180"
      />
      <template #empty>
        <el-image
          :src="require('@/assets/empty_images/data_empty.png')"
          class="vab-data-empty"
        />
      </template>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-dialog>
</template>
<script>
  // import { getLiveTaskQueueList } from '@/api/toutiao/live'
  import { getAction } from '@/api/request'
  export default {
    name: 'LiveTaskQueue',
    data() {
      return {
        list: [],
        title: '',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          task_id: '',
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        // const { data, totalCount } = await getLiveTaskQueueList(this.queryForm)
        getAction(
          '/api/task/view-plan-task/task-queue-list',
          this.queryForm
        ).then((res) => {
          this.list = res.data.list
          this.total = res.totalCount
          this.listLoading = false
        })
      },
      showData(row) {
        this.dialogFormVisible = true
        this.listLoading = true
        this.queryForm.task_id = row.task_id
        this.fetchData()
      },
    },
  }
</script>
