<template>
  <el-dialog
    :title="title"
    :visible.sync="isShowDialog"
    width="800px"
    top="13vh"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
    @close="onClose"
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-suffix=":"
        label-width="120px"
      >
        <el-form-item label="产品标题" prop="">
          <el-input
            style="width: 390px"
            v-model="form.title"
            placeholder="最多32个字"
            maxlength="32"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="卖点" prop="">
          <el-input
            style="width: 390px"
            v-model="form.sell_point"
            placeholder="最多32个字"
            maxlength="32"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="封面图" prop="cover_img">
          <upload-img
            ref="cover_img"
            :infoText="'图片尺寸750*750，仅支持jpg和png格式图片'"
            :limit="50"
            @getImgs="getGoods_imgImg($event, 'cover_img', index)"
            :maxSize="100"
            :booleancorpper="true"
            :enlarge="4"
            :auto-crop-width="187.5"
            :auto-crop-height="187.5"
            :mode="'750px,750px'"
          />
        </el-form-item>
        <el-form-item label="产品属性">
          <div
            v-for="(item, i) in productAttrList"
            :key="i"
            style="margin-bottom: 10px"
          >
            <div class="productcard">
              <span>标题：</span>
              <el-input
                v-model="productAttrList[i].title"
                style="width: 30%"
                placeholder="请输入产品名称"
              />
              <span style="margin-left: 20px">内容：</span>
              <el-input
                v-model="productAttrList[i].content"
                style="width: 30%"
                placeholder="请输入内容"
              />
              <span v-if="i > 0" class="btnicon min" @click="subList(i)">
                <i class="el-icon-delete" style="font-size: 18px"></i>
              </span>
              <span v-show="i > 0" class="btnicon min" @click="moveUp(i)">
                <i
                  class="el-icon-top"
                  style="font-size: 18px; font-weight: bold; margin-right: 5px"
                ></i>
              </span>
              <span
                v-show="i < productAttrList.length - 1"
                class="btnicon min"
                @click="moveDown(i)"
              >
                <i
                  class="el-icon-bottom"
                  style="font-size: 18px; font-weight: bold"
                ></i>
              </span>
            </div>
          </div>
          <div class="addbtn" @click="addList">
            <i class="el-icon-circle-plus-outline" style="font-size: 28px"></i>
          </div>
        </el-form-item>

        <el-form-item label="营养成分表" prop="ingredient_list">
          <upload-img
            ref="ingredient_list"
            :infoText="''"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'ingredient_list')"
            :maxSize="100"
          />
        </el-form-item>
        <el-form-item label="所属分类">
          <el-select
            style="width: 120px"
            v-model="form.category_id"
            filterable
            clearable
            placeholder=""
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联云交会产品" prop="">
          <el-autocomplete
            style="width: 390px"
            v-model="form.goods_title"
            :fetch-suggestions="handlergoods"
            value-key="goods_name"
            clearable
            placeholder="请输入产品名称"
            @select="handleSelectgoods"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="发布时间" prop="status">
          <el-date-picker
            v-model="form.publish_time"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="品牌" prop="">
          <el-input
            style="width: 390px"
            v-model="form.brand_name"
            placeholder="请输入品牌名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="排序" prop="">
          <el-input
            style="width: 190px"
            v-model="form.sort"
            placeholder=""
          ></el-input>
        </el-form-item> -->
        <el-form-item label="首页显示" prop="status">
          <el-select
            style="width: 120px"
            v-model="form.index_show"
            filterable
            clearable
            placeholder="位置"
          >
            <el-option
              v-for="item in showList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg'
  import { nextTick } from 'vue'
  export default {
    name: 'AddEditLive',
    components: { UploadImg },
    data() {
      return {
        editType: '',
        loading: false,
        isShowDialog: false,
        selectList: [],
        // 获取详情参数
        params: {},

        // 提交参数
        form: {
          id: '',
          cover_img: [],
        },
        showList: [
          {
            id: '0',
            title: '不显示',
          },
          {
            id: '1',
            title: '位置一',
          },
          {
            id: '2',
            title: '位置二',
          },
        ],
        drinkLlist: [
          {
            id: '0',
            title: '否',
          },
          {
            id: '1',
            title: '是',
          },
        ],
        cateList: [], //云交会自建分类
        productAttrList: [
          { title: '所属品类', content: '' },
          { title: '上市时间', content: '' },
          { title: '所含口味', content: '' },
          { title: '规格/售价', content: '' },
          { title: '配料表', content: '' },
          { title: '保存条件', content: '' },
          { title: '保质期', content: '' },
          { title: '产地国', content: '' },
          { title: '是否即食/即饮', content: '' },
          { title: '上市渠道/区域', content: '' },
        ], //产品属性
      }
    },
    created() {},
    mounted() {},

    computed: {
      title() {
        if (this.editType == 1) {
          return '新增'
        } else {
          return '编辑'
        }
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
        } else {
          this.form = { id: '', type: '', live_id: '', status: '' }
          this.params = {}
        }
      },
    },
    mounted() {
      this.getCateList()
    },
    methods: {
      handlergoods(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.goods_id = ''
        } else {
          getAction('/mall/product/goods', { goods_name: queryString }).then(
            (res) => {
              cb(res.data)
            }
          )
        }
      },
      handleSelectgoods(list) {
        console.log(list)
        this.form.goods_id = list.id
      },
      // 增加属性
      addList() {
        this.productAttrList.push({ title: '', name: '' })
      },
      subList(index) {
        this.productAttrList.splice(index, 1)
      },
      // 上移
      moveUp(i) {
        let temp = this.productAttrList[i]
        this.$set(this.productAttrList, i, this.productAttrList[i - 1])
        this.$set(this.productAttrList, i - 1, temp)
      },
      // 下移
      moveDown(i) {
        let temp = this.productAttrList[i]
        this.$set(this.productAttrList, i, this.productAttrList[i + 1])
        this.$set(this.productAttrList, i + 1, temp)
      },
      onClose() {
        this.isShowDialog = false
        this._onReset()
      },
      _onReset() {
        this.form = {}

        this.productAttrList = [
          { title: '所属品类', content: '' },
          { title: '上市时间', content: '' },
          { title: '所含口味', content: '' },
          { title: '规格/售价', content: '' },
          { title: '配料表', content: '' },
          { title: '保存条件', content: '' },
          { title: '保质期', content: '' },
          { title: '产地国', content: '' },
          { title: '是否即食/即饮', content: '' },
          { title: '上市渠道/区域', content: '' },
        ]
      },
      getCateList() {
        getAction('/api/daily/new-daily/category').then((res) => {
          if (res.request_code == 200) {
            this.cateList = res.data
          }
        })
      },
      getInfo(row) {
        console.log('---------11111', row)
        getAction('/api/daily/new-daily/new-daily-detail', { id: row.id }).then(
          (res) => {
            if (res.request_code == 200) {
              this.form = res.data
              this.productAttrList = res.data.goods_attr
              nextTick(() => {
                this.setImg(this.form.ingredient_list, 'ingredient_list')
                // this.setImg(this.form.cover_img.join(','), 'cover_img')
                this.setImgArray(this.form.cover_img, 'cover_img')
              })
            }
          }
        )
      },
      setImg(v, prop) {
        console.log('复制--')
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        console.log('复制--arr', v)
        if (v[0] != '') this.$refs[prop].img = v
      },

      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
          console.log('----------------------------------', v, this.form, prop)
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImg_arr(v, prop, index) {
        console.log('this.form--', this.form, index)
        if (v[0]) {
          this.form.cover_img[index][prop] = v.join(',')
        } else {
          this.form.cover_img[index][prop] = ''
        }
        console.log('执行了吗？====', prop)
      },
      async fetchData(row) {
        this.loading = true
        try {
          this.form = Object.assign(this.form, row)
          if (this.form.live_id) {
            this.form.live_id = Number(this.form.live_id)
          }
          let toutiaolist = await postAction('/live/index/live-search', {
            type_id: this.params.type,
          })
          this.selectList = toutiaolist.data
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      handleSubmit() {
        console.log('---------------', this.form, this.editType)

        let params = this.form
        if (this.editType == 1) {
          console.log('kdkkf d')
          delete params.id
        }
        params.goods_attr = JSON.stringify(this.productAttrList)
        this.$refs.form.validate((v) => {
          if (v) {
            postAction('/api/daily/new-daily/new-daily-edit', params).then(
              (res) => {
                this.$message.success(res.msg)
                this.$emit('refresh')
                this.onClose()
              }
            )
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-icon-delete {
    margin: 0 5px;
  }
</style>
