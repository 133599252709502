<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">

      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="视频标题"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">

          <el-popconfirm title="确认删除？" @confirm="handlerdelete(row)">
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerstate(row,)"
          >
            {{row.status==1?"禁用":"启用"}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="editposter" @getlist="handlerInquire"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit },
  data() {
    return {
      form: {
        title: '',
        page: 1,
        limit: 10,
      },
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: '',
        },
        {
          label: '标题',
          prop: 'title',
          width: '',
        },
        {
          label: '排序',
          prop: 'sort_order',
          width: '',
        },
        {
          label: '状态',
          prop: 'status',
          width: '80',
        },
      ],
      typeSelect:[
        {
          id: 2,
          name: '投票',
        },
        {
          id: 1,
          name: '话题',
        },
      ],
      shopSelect: [
        {
          id: 1,
          name: '启用',
        },
        {
          id: 0,
          name: '禁用',
        },
      ],
      total: 0,
    }
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerAdd() {
      this.$refs.editposter.showEditPoster = true
    },
    handlerstate(row){
      getAction('/api/system/study-video/status', { id: row.id }).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    handlerlist() {
      getAction('/api/system/study-video/lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handlerdelete(row) {
      postAction('/api/system/study-video/delete', { id: row.id }).then((res) => {
        if (res.request_code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerInquire()
        }
      })
    },
    // 编辑分类
    handleredit(row) {
      this.$refs.editposter.showEditPoster = true
      this.$refs.editposter.handlerinfo(row)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.pagesize = val
      this.handlerlist()
    },
  },
};
</script>

<style scoped>

</style>
