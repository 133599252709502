import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/api/common/dict/index',
    method: 'get',
    params,
  })
}

export function doAdd(data) {
  return request({
    url: '/api/common/dict/create',
    method: 'post',
    data,
  })
}

export function doUpdate(data) {
  return request({
    url: '/api/common/dict/update',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/api/common/dict/delete',
    method: 'post',
    data,
  })
}

export function getDataById(params) {
  return request({
    url: '/api/common/dict-data/index',
    method: 'get',
    params,
  })
}

export function doDataAdd(data) {
  return request({
    url: '/api/common/dict-data/create',
    method: 'post',
    data,
  })
}

export function doDataUpdate(data) {
  return request({
    url: '/api/common/dict-data/update',
    method: 'post',
    data,
  })
}

export function doDataDelete(data) {
  return request({
    url: '/api/common/dict-data/delete',
    method: 'post',
    data,
  })
}
