var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("新 增")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "ElTable",
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "类型"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1 ? "春糖" : "秋糖"
                                  ) +
                                  " "
                              ),
                            ])
                          : item.label == "状态"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1 ? "开启" : "关闭"
                                  ) +
                                  " "
                              ),
                            ])
                          : item.label == "详情页分享图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label == "列表分享图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "500",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleredit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerbanner(row)
                          },
                        },
                      },
                      [_vm._v(" 专区管理 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerDirect(row)
                          },
                        },
                      },
                      [_vm._v(" 关联直播间 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" 酒店列表 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerrecommend(row)
                          },
                        },
                      },
                      [_vm._v(" 精选布展企业 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handlerproduct(row)
                          },
                        },
                      },
                      [_vm._v(" 广告位 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add-hotel", { ref: "addedit", on: { getlist: _vm.handlerInquire } }),
      _c("zone", { ref: "zone" }),
      _c("live-room", { ref: "live" }),
      _c("hotel-list", { ref: "hotel" }),
      _c("ent-list", { ref: "cat", on: { getlist: _vm.handlerInquire } }),
      _c("bannerlist", { ref: "banner" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }