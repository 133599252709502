<template>
  <router-view />
</template>

<script>
  export default {
    name: 'OtherMan',
  }
</script>

<style scoped></style>
