var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showDialog, width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c("el-form-item", { attrs: { label: "一级品类" } }, [
            _vm._v(" " + _vm._s(_vm.form.cat_pname) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "二级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "二级品类" },
                  model: {
                    value: _vm.form.cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cat_id", $$v)
                    },
                    expression: "form.cat_id",
                  },
                },
                _vm._l(_vm.catselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.root_cat_id == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "价格带" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange },
                      model: {
                        value: _vm.wine_price_id,
                        callback: function ($$v) {
                          _vm.wine_price_id = $$v
                        },
                        expression: "wine_price_id",
                      },
                    },
                    _vm._l(_vm.pricelist, function (item, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "需求详情" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付费企业推荐" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.rec,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rec", $$v)
                  },
                  expression: "form.rec",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "沟通截图" } },
            [
              _c("upload-img", {
                ref: "uploadImage",
                attrs: { "info-text": _vm.infoText, "max-size": 100 },
                on: { uploadImg: _vm.uploadImg },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需求形式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("找产品代理"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("找代工")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "提报人" } }, [
            _vm._v(" " + _vm._s(_vm.form.staff_user_name) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "提报时间" } }, [
            _vm._v(" " + _vm._s(_vm.form.created) + " "),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }