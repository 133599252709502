var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "append-to-body": "",
        title: "编辑客户画像",
        visible: _vm.showCustomer,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showCustomer = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名", type: "text" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showCustomer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }