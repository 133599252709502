<template>
  <el-dialog
    :title="title"
    :visible.sync="showAdd"
    width="400px">
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="日期：">
          <el-input placeholder="x月x日" v-model="form.title" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select placeholder="" v-model="form.status" clearable style="width: 220px;">
            <el-option value="1" label="启用"></el-option>
            <el-option value="0" label="禁用"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showAdd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/request";

export default {
  name: "add",
  data(){
    return{
      showAdd:false,
      title:'',
      form:{
        status:'1',
        title:'',
        sort:'',
        id:'',
      }
    }
  },
  watch:{
    showAdd(val){
      if(!val){
        this.form={
          status:'1',
          title:'',
          sort:'',
          id:'',
        }
        this.title=''
      }
    }
  },
  methods:{
    handlersave(){
      postAction('/api/afbc/date/edit',this.form).then(res=>{
        this.$emit("getlist")
        this.$message({
          type:'success',
          message:res.msg
        })
        this.showAdd=false
      })
    }
  }
};
</script>

<style scoped>

</style>
