<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '店铺海报'">
<!--            <el-row :gutter="10" style="display: flex; flex-wrap: wrap">-->
<!--            <span v-for="(list, index) in row[item.prop]" :key="index">-->
              <el-image
                v-show="row[item.prop]!=''"
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
<!--            </span>-->
<!--            </el-row>-->
          </div>
          <div v-else-if="item.label == '店铺logo'">
            <el-image
              :preview-src-list="[row.logo]"
              :src="row.logo"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '授权书/合作协议'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '商标注册证/商标注册申请受理通知书'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '食品经营许可证'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '食品生产许可证'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '企业展厅'">
            <span v-html="row[item.prop]"></span>
          </div>
          <div v-else-if="item.label == '产线展示'">
            <span v-html="row[item.prop]"></span>
          </div>
          <div v-else-if="item.label == '扶持政策'">
            <span v-html="row[item.prop]"></span>
          </div>
          <div v-else-if="item.label == '企业介绍'">
            <span v-html="row[item.prop]"></span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">

          <el-popconfirm
            v-show="row.status == 2"
            title="确认审核通过？"
            @confirm="deleteRow(row, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              通过
            </el-button>
          </el-popconfirm>

          <el-button
            v-show="row.status == 2"
            size="small"
            style="margin-left: 10px"
            type="text"
            @click="deleteRow(row, 3)"
          >
            拒绝
          </el-button>
          <el-button
            size="small"
            type="text"
            @click.native.prevent="handlerbusiness(row)"
          >
            工商信息
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handlerinfo(row)"
          >
            详情
          </el-button>
          <el-button v-has="'need'" type="text" @click="openenterprise(row)">企业后台</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <info ref="info" @getlist="handlerInquire" />
    <business ref="business" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import info from './components/info'
  import Business from './components/business'
  export default {
    name: 'Index',
    components: {
      Business,
      info,
    },
    data() {
      return {
        form: {
          status: 0,
          company_name: '',
          mall_name: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_txt',
            width: '',
          },
          {
            label: '店铺logo',
            prop: 'logo',
            width: '',
          },
          {
            label: '店铺背景',
            prop: 'bg_image',
            width: '',
          },
          {
            label: '企业介绍',
            prop: 'introduction',
            width: '220',
          },
          {
            label: '企业展厅',
            prop: 'honors',
            width: '300',
          },
          {
            label: '扶持政策',
            prop: 'supportive_policy',
            width: '300',
          },
          {
            label: '产线展示',
            prop: 'line_display',
            width: '300',
          },
          {
            label: '店铺海报',
            prop: 'share_img',
            width: '160',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
            width: '',
          },
        ],
        shopSelect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '已审核',
          },
          {
            id: 2,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      if(this.$route.query.mall_id){
        this.form.mall_id=this.$route.query.mall_id
      }
      this.handlerInquire()
    },
    methods: {
      openenterprise(row){
        postAction("/mall/shop/jump-login-dealer",{mall_id:row.mall_id}).then(res=>{
          console.log(res.data)
          // window.open('http://192.168.9.46:10001/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          if(process.env.NODE_ENV === 'development'){
            window.open('http://testqi.htyunjiaohui.com/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          }else{
            window.open('http://qi.htyunjiaohui.com/#/enterprise/storeManagement?access_token='+res.data.token,'_blank')
          }

        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerinfo(row) {
        this.$refs.info.form = row
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        getAction('/audit/shop/item-lists', this.form).then((res) => {
          this.tabledata=res.data.map(list=>{
            // if(list.evidence_url.length==0){
            //   list.evidence_url=new Array()
            // }else{
            //   list.evidence_url=list.evidence_url.split(',')
            // }
            return list
          })
          this.total = Number(res.totalCount)
        })
      },
      deleteRow(row, status) {
        if (status == 3) {
          this.$prompt('理由', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            postAction('/audit/shop/item-audit', {
              id: row.id,
              status: status,
              reason: value,
            }).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            })
          })
        } else {
          postAction('/audit/shop/item-audit', { id: row.id, status }).then(
            (res) => {
              if (res.request_code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.handlerInquire()
              }
            }
          )
        }
      },
      handlerbusiness(row) {
        console.log(row)
        this.$refs.business.handlerinfo(row.company_name)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
