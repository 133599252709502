var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { content: "", visible: _vm.showEditPoster, width: "400px" },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["12:00:00"],
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "code" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSearch } },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "40%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            [
              _c("el-table-column", {
                attrs: { label: "总点击次数(PV)", prop: "pv", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "总访客数(UV)", prop: "pv", align: "center" },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tabledata },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.form.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.form.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }