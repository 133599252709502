<template>
  <el-dialog title="会议列表" :visible.sync="show" width="1200px">
    <div>
      <el-form ref="form" inline label-suffix=":" :model="form">
        <el-form-item>
          <el-select
            v-model="form.type"
            clearable
            placeholder="状态"
            style="width: 100px"
          >
            <el-option
              v-for="list in statusSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
            <el-autocomplete
              style="width: 160px;"
              v-model="form.staff_name"
              :fetch-suggestions="(queryString,cb)=>{handlerstaff(queryString,cb)}"
              value-key="staff_name"
              placeholder="请输入提报人"
              @select="(list)=>{handleSelectstaff(list)}"
            ></el-autocomplete>
        </el-form-item>
        <el-form-item prop="dealer_id">
            <el-autocomplete
              style="width: 160px;"
              v-model="form.dealer_name"
              :fetch-suggestions="(queryString,cb)=>{handlerdealer(queryString,cb)}"
              value-key="dealer_name"
              placeholder="请输入经销商"
              @select="(list)=>{handleSelectdealer(list)}"
            ></el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-input clearable v-model="form.remark" placeholder="备注"  style="width: 160px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
      </el-form>

      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :sortable="item.sort"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '经销商意愿凭证'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
                <span v-for="(list, index) in row[item.prop]" :key="index">
                  <el-image
                    :preview-src-list="[list]"
                    :src="list"
                    style="width: 100px; height: 100px"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '大商凭证'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
                <span v-for="(list, index) in row[item.prop]" :key="index">
                  <el-image
                    :preview-src-list="[list]"
                    :src="list"
                    style="width: 100px; height: 100px"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '会议截图'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '类型'">
              {{ row[item.prop]==3?'云会议':'大商直通车' }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="show = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from '@/api/request'

export default {
  name: 'huiyilist',
  data() {
    return {
      show: false,
      form: {
        page: 1,
        limit: 10,
        mall_id: '',
        type: '',
        remark: '',
        staff_id: '',
        staff_name: '',
        dealer_id: '',
        dealer_name: '',
      },
      statusSelect: [
        {
          id: 3,
          name: '云会议',
        },
        {
          id: 4,
          name: '大商直通车',
        },
      ],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '类型',
          prop: 'type',
          width: '',
          sort: false,
        },
        {
          label: '经销商意愿凭证',
          prop: 'prove_img',
          width: '',
          sort: false,
        },
        {
          label: '会议截图',
          prop: 'meeting_img',
          width: '',
          sort: false,
        },
        {
          label: '云会议录制链接',
          prop: 'video_url',
          width: '',
          sort: false,
        },
        // {
        //   label: '云会议明细链接',
        //   prop: 'detail_url',
        //   width: '',
        //   sort: false,
        // },
        {
          label: '提报人',
          prop: 'staff_name',
          width: '',
          sort: false,
        },
        {
          label: '经销商',
          prop: 'dealer_name',
          width: '',
          sort: false,
        },
        {
          label: '会议时间',
          prop: 'open_time',
          width: '',
          sort: false,
        },
        {
          label: '大商凭证',
          prop: 'business_img',
          width: '',
          sort: false,
        },
        {
          label: '备注',
          prop: 'remark',
          width: '',
          sort: false,
        },
        {
          label: '提交时间',
          prop: 'created',
          width: '',
          sort: false,
        },
        {
          label: '提交人',
          prop: 'admin_user',
          width: '',
          sort: false,
        },

      ],
      total: 0,
    }
  },
  watch:{
    show(val){
      if(!val){
        this.form={
          page: 1,
          limit: 10,
          mall_id: '',
          type: '',
          remark: '',
          staff_id: '',
          staff_name: '',
          dealer_id: '',
          dealer_name: '',
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    handlerinfo(row ,type){
      this.form.type=type
      this.form.mall_id=row.mall_id
      this.handlerInquire()
      this.show=true
    },
    handlerstaff(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.staff_id=''
      }else{
        getAction("/mall/setmeal/staff-crm",{staff_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectstaff(list){
      console.log(list)
      this.form.staff_id=list.staff_id
      this.form.staff_name=list.staff_name
    },
    handlerdealer(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.dealer_id=''
      }else{
        getAction("/mall/setmeal/dealer-crm",{staff_id:this.form.staff_id,dealer_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectdealer(list,){
      console.log(list)
      this.form.dealer_id=list.dealer_id
      this.form.dealer_name=list.dealer_name
    },
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/mall/setmeal/deduct-setmeal-reason', this.form).then((res) => {
        this.tabledata = res.data.map(list=>{
          if(list.prove_img.length==0){
            list.prove_img = []
          }else{
            list.prove_img = list.prove_img.split(',')
          }
          console.log(list.prove_img)
          // business_img
          if(list.meeting_img.length==0){
            list.meeting_img = []
          }else{
            list.meeting_img = list.meeting_img.split(',')
          }

          if(list.business_img.length==0){
            list.business_img = []
          }else{
            list.business_img = list.business_img.split(',')
          }
          return list
        })
        console.log(this.tabledata)
        this.total = Number(res.totalCount)

      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
