<template>
  <el-dialog :title="title" :visible.sync="showAppointMall" width="30%">
    <el-form ref="form" :model="form">
      <el-form-item v-if="is_this_mall == 0" label="">
        <div
          v-for="(mall, index) in mallLimit"
          :key="index"
          style="margin-bottom: 10px"
        >
          <el-autocomplete
            v-model="mall.name"
            :fetch-suggestions="handlermell"
            placeholder="请输入店铺名称"
            style="width: 280px"
            value="id"
            value-key="mall_name"
            @focus="handleFocusMall(index)"
            @select="handleSelectmall"
          >
            <template slot-scope="{ item }">
              <div :class="item.is_pay == 1 ? 'payname' : ''">
                {{ item.mall_name }}
              </div>
            </template>
          </el-autocomplete>
          <i
            v-show="mallLimit.length < 3"
            class="el-icon-circle-plus-outline"
            style="font-size: 24px; margin-left: 15px"
            @click="handleAdd"
          ></i>
          <i
            v-show="mallLimit.length > 1"
            class="el-icon-remove-outline"
            style="font-size: 24px"
            @click="handledelet(index)"
          ></i>
        </div>
      </el-form-item>
      <el-form-item v-else label="">
        <div v-for="(m, i) in this_mall_name" :key="i">{{ m }}</div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showAppointMall = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    name: 'AddEdit',
    props: {
      rowid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showAppointMall: false,
        title: '指定店铺',
        reasonlist: [],
        remark: [],
        other: '',
        form: {
          id: '',
        },
        mallvalue: '',
        malloptions: [],
        mallLimit: [
          {
            name: null,
          },
        ],
        is_this_mall: 0, //1-指定了店铺，0 未指定店铺   待审核、通过、待定状态，均可以指定店铺。
        this_mall_name: '',
        mallidlist: [],
        currentid: '',
      }
    },
    watch: {
      showAppointMall(val) {
        if (!val) {
          this.remark = []
          this.other = ''
        }
      },
    },
    watch: {
      showAppointMall(val) {
        this.mallLimit = [
          {
            name: null,
          },
        ]
      },
    },
    mounted() {
      this.remark = []
      this.other = ''
      this.getList()
    },
    methods: {
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.mall_id = ''
        } else {
          getAction('/audit/biz-clue/mall', {
            mall_name: queryString,
            id: this.rowid,
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      // 根据索引获取当前选择店铺的id
      handleSelectmall(list) {
        if (list.sel_is_like == 1) {
          this.mallLimit[this.currentid].name = ''
          this.$message.error('客户已匹配该店铺，请勿重复匹配。')
        } else {
          this.mallidlist[this.currentid] = list.id
        }
      },
      // 用来获取当前操作店铺的索引
      handleFocusMall(index) {
        this.currentid = index
      },
      handlerlist(row) {
        this.mallidlist = []
        this.is_this_mall = row.is_this_mall
        this.this_mall_name = row.this_mall_name
      },
      handleAdd() {
        if (this.mallLimit.length < 3) {
          this.mallLimit.push({
            name: null,
          })
        }
      },
      // 删除指定店铺
      handledelet(index) {
        this.mallLimit.splice(index, 1)
      },
      getList() {
        getAction('/mall/index/mall').then((res) => {
          if (res.request_code == 200) {
            this.malloptions = res.data
          }
        })
      },
      handlersave() {
        let mall_id = this.mallidlist.join(',')
        postAction('/audit/biz-clue/this-mall', {
          id: this.rowid,
          mall_id: mall_id,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.showAppointMall = false
          // this.handlerInquire()
          this.$emit('getlist')
        })
      },
    },
  }
</script>

<style scoped>
  .vab-theme-blue-black .el-checkbox {
    display: block !important;
  }
  .payname {
    color: #f00;
  }
</style>
