<template>
  <div class="index-container">
    <el-form inline :modal="form">
      <el-form-item>
        <el-input v-model="form.keywords" placeholder="请输入企业名称或姓名" />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option label="待审核" value="0" />
          <el-option label="已审核" value="1" />
          <el-option label="不通过" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" border :data="tabledata">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
      >
        <template v-if="item.label == '照片'" #default="{ row }">
          <span v-for="list in row[item.prop]" :key="list">
            <el-image
              :preview-src-list="[list]"
              :src="list"
              style="width: 50px; height: 50px"
            />
          </span>
        </template>
        <template v-else-if="item.label == '状态'" #default="{ row }">
          <span v-show="row.status == 0">待审核</span>
          <span v-show="row.status == 1">已审核</span>
          <span v-show="row.status == 2">不通过</span>
        </template>
        <template v-else-if="item.label == '操作'" #default="{ row }">
          <el-button
            v-show="row.status == 0"
            type="text"
            @click="handlerstatus(row)"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    data() {
      return {
        loading: false,
        form: {
          keywords: '',
          status: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'enterprise_name',
            width: '',
          },
          {
            label: '姓名',
            prop: 'name',
            width: '',
          },
          {
            label: '电话',
            prop: 'phone',
            width: '',
          },
          {
            label: '照片',
            prop: 'organization_img',
            width: '110px',
          },
          {
            label: '留言',
            prop: 'remark',
            width: '',
          },
          {
            label: '申请时间',
            prop: 'created',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'modified',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },
          {
            label: '操作',
            prop: 'cat_pname',
            width: '120px',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlerInquire() {
        this.loading = true
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/audit/card/lists', this.form).then((res) => {
          console.log(res)
          this.tabledata = res.data
          this.total = Number(res.totalCount)
          this.loading = false
        })
      },

      handlerstatus(row) {
        this.$confirm(
          row.enterprise_name + '     ' + row.name + '     ' + row.phone,
          '审核',
          {
            confirmButtonText: '通过',
            cancelButtonText: '拒绝',
            showClose: false,
            distinguishCancelAndClose: false,
            closeOnClickModal: false,
            // type: 'warning'
          }
        )
          .then(() => {
            postAction('/audit/card/approved', { id: row.id, status: 1 }).then(
              (res) => {
                this.$message.success(res.msg)
                this.handlerlist()
              }
            )
          })
          .catch(() => {
            postAction('/audit/card/approved', { id: row.id, status: 2 }).then(
              (res) => {
                this.$message.success(res.msg)
                this.handlerlist()
              }
            )
          })
      },

      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
