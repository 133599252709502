<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="70%">
    <div>
      <addbanner ref="banner"  @getlist="handlerInquire"/>
      <el-form ref="form" :model="form" label-suffix=":" inline>
        <el-form-item >
          <el-select v-model="form.is_open" clearable style="width: 160px;" placeholder="状态">
            <el-option v-for="list in shopSelect"
                       :key="list.id"
                       :value="list.id"
                       :label="list.name"/>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-input placeholder="标题" clearable v-model="form.title" style="width: 110px;"/>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="handlerAdd">新 增</el-button>
        </el-form-item>

      </el-form>

      <el-table
        border
        :data="tabledata"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{row}">
            <div v-if="item.label=='广告图'">
              <el-image
                style="width: 70px; height: 30px"
                :src="row.img"
                :preview-src-list="[row.img]">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <!--          <div v-else-if="item.label=='店铺logo'">
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="row.logo"
                          :preview-src-list="[row.logo]">
                          <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                          </div>
                        </el-image>
                      </div>-->
            <div v-else>{{row[item.prop]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="220">
          <template  #default="{row}">
            <el-popconfirm
              title="确认禁用？"
              @confirm="deleteRow(row.id, 0)"
              v-show="row.is_open==1"
            >
              <el-button
                slot="reference"
                type="text"
                size="small" >
                禁用
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              title="确认启用？"
              @confirm="deleteRow(row.id, 1)"
              v-show="row.is_open!=1"
            >
              <el-button
                slot="reference"
                type="text"
                size="small">
                启用
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              title="确认删除该广告图？"
              @confirm="handlerdelete(row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small">
                删除
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin: 10px;"
              @click.native.prevent="handleredit(row)"
              type="text"
              size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="show = false">取 消</el-button>
    <el-button type="primary" @click="show = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import Addbanner from "./addbanner";
import { getAction, postAction } from "@/api/request";

export default {
  name: "bannerlist",
  components: { Addbanner },
  watch: {
    show(val) {
      if (!val) {
        this.form= {
          advert_no:'',
            is_open: null,
            title:'',
            page: 1,
            limit: 10
        }
      }
    },
  },
  data(){
    return {
      show:false,
      form: {
        advert_no:'',
        is_open: null,
        title:'',
        page: 1,
        limit: 10
      },
      title:'轮播图设置',
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "标题",
          prop: "advert_name",
          width: "",
        },

        {
          label: "广告图",
          prop: "img",
          width: "160",
        },
        {
          label: "关联名称",
          prop: "ref_name",
          width: "",
        },
        // {
        //   label: "公司名称",
        //   prop: "company",
        //   width: "",
        // },
        {
          label: "曝光量",
          prop: "exposure_num",
          width: "80",
        },

        {
          label: "状态",
          prop: "is_open_txt",
          width: "80",
        },
      ],
      shopSelect: [
        {
          id: 1,
          name: '上架'
        }, {
          id: 0,
          name: '下架'
        }
      ],
      total: 0,
    }
  },

  methods:{

    handlerSelect(){
      getAction("/audit/identity/identity",).then(res=>{
        this.identitySelect=res.data
      })
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerAdd(){
      this.$refs.banner.addbanner=true
      this.$refs.banner.form.advert_no=this.form.advert_no
    },
    handlerinfo(row){
      this.$refs.info.form=row
      this.$refs.info.showinfo=true
    },
    handlerlist(){
      getAction("/audit/advert/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handlerdelete(row){
      postAction("/audit/advert/delete",{id:row.id}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    deleteRow(id,status){
      postAction("/audit/advert/open",{id:id,open:status}).then(res=>{
        if(res.request_code==200){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.handlerInquire()
        }
      })
    },
    handleredit(row){
      console.log(row)
      this.$refs.banner.addbanner=true
      this.$refs.banner.handlerinfo(row)

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
