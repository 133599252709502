<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.is_check"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in checkselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="姓名"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="手机号"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.province_id"
          clearable
          placeholder="省"
          style="width: 160px"
        >
          <el-option
            v-for="list in areaselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.class_id"
          clearable
          placeholder="一级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in classselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.invite_name"
          clearable
          filterable
          placeholder="邀请人"
          style="width: 160px"
        >
          <el-option
            v-for="list in yqoselect"
            :key="list.htyun_name"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          filterable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option
            v-for="list in source"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 260px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '营业执照'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '店铺logo'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片1'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片2'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片3'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '来源'">
            {{row[item.prop]==1?'自主发布':row[item.prop]==2?'企微需求':''}}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import Edit from './components/edit'

  export default {
    name: 'Index',
    components: { Edit },
    data() {
      return {
        form: {
          is_check: -1,
          class_id: '',
          invite_name:'',
          name: '',
          mobile: '',
          province_id: '',
          company:'',
          page: 1,
          limit: 10,
        },
        yqoselect:[],
        time:[],
        source: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 2,
            name: '企微需求',
          },
          {
            id: 1,
            name: '自主发布',
          },
        ],
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'find_id',
            width: '110',
          },
          {
            label: '姓名',
            prop: 'name',
            width: '220px',
          },
          {
            label: '一级分类',
            prop: 'class_txt',
            width: '120',
          },
          {
            label: '二级分类',
            prop: 'class_child_txt',
            width: '80',
          },
          {
            label: '公司',
            prop: 'company',
            width: '110',
          },
          {
            label: '电话',
            prop: 'mobile',
            width: '160',
          },
          {
            label: '内容',
            prop: 'intro',
            width: '120',
          },
          {
            label: '省',
            prop: 'province_name',
            width: '80',
          },
          {
            label: '市',
            prop: 'region_name',
            width: '160',
          },
          {
            label: '审核状态',
            prop: 'check_txt',
            width: '140',
          },
          {
            label: '营业执照',
            prop: 'license',
            width: '130',
          },
          {
            label: '发布时间',
            prop: 'created_txt',
            width: '80',
          },
          {
            label: '图片1',
            prop: 'list_img1',
            width: '130',
          },
          {
            label: '图片2',
            prop: 'list_img2',
            width: '130',
          },
          {
            label: '图片3',
            prop: 'list_img3',
            width: '130',
          },
          {
            label: '邀请人',
            prop: 'invite_name',
            width: '',
          },
          {
            label: '是否发送站内信',
            prop: 'sms_txt',
            width: '',
          },
          {
            label: '来源',
            prop: 'type',
            width: '',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.edit.show()
        this.$refs.edit.handlerinfo(row)
      },
      async handlerSelect() {
        const { data } = await getAction('/user/index/area', { pid: 0 })
        this.areaselect = data
        await getAction('/user/index/cate').then((res) => {
          this.classselect = res.data
        })
        await getAction('/form/form/staff').then((res) => {
          this.yqoselect = res.data
        })
      },
      handlerlist() {
        getAction('/mall/proxy/purchase-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
