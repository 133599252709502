<template>
  <el-dialog :title="title" :visible.sync="showedit" width="60%">
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="产品名称">
        <el-autocomplete
          v-model="form.goods_name"
          :fetch-suggestions="handlermell"
          placeholder="请输入产品名称"
          style="width: 300px"
          value-key="goods_name"
          @select="handleSelectmall"
        />
        <span>可输入关键词模糊搜索</span>
      </el-form-item>
      <el-form-item v-if="pinlei_name.length > 0" label="品类">
        {{ pinlei_name }}
      </el-form-item>
      <el-form-item v-if="pinlei_name.length > 0" label="店铺名称">
        {{ mall_name }}
      </el-form-item>
      <el-form-item v-if="pinlei_name.length > 0" label="企业名称">
        {{ company_name }}
      </el-form-item>
      <el-form-item v-if="pinlei_name.length > 0" label="封面图">
        <el-image :src="coverimg" style="width: 100px; height: 100px" />
        <span>默认显示封面图</span>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.sort" label="排序" :min="0" />
        <span>
          默认后上传的排在上边。支持手动排序，数字小的在前，大的在后。最小的有效数字为1。
        </span>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in mealselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发布时间">
        <!-- <el-input v-model="form.created" label="发布时间" /> -->
        <el-date-picker
          v-model="form.created_txt"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
        <span>默认为当前时间，可修改</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showedit = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction } from '@/api/request'

  export default {
    name: 'AddEdit',

    data() {
      return {
        showedit: false,
        title: '上传',
        mall_name: '', //店铺名称 回显
        pinlei_name: '', //品类 回显
        company_name: '', //企业名称 回显
        coverimg: '', //封面图 回显
        mealselect: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '2',
            name: '禁用',
          },
        ],
        form: {
          id: '',
          good_id: '',
          goods_name: '',
          mall_name: '',
          sort: 0,
          status: '1',
          created: new Date(),
          created_txt: '',
        },
      }
    },
    watch: {
      showedit(val) {
        console.log(val, 'xianshi')

        if (!val) {
          this.form = {
            id: '',
            good_id: '',
            goods_name: '',
            sort: 0,
            status: '1',
            mall_name: '',
          }
          this.title = '新增'
          this.mall_name = ''
          this.pinlei_name = ''
          this.coverimg = ''
          this.company_name = ''
        } else if (this.form.goods_name.length > 0) {
          getAction('/mall/product/goods', {
            goods_name: this.form.goods_name,
            is_on_sale: 1, //只搜索已审核、已上架的店铺
          }).then((res) => {
            console.log('--------------', res)
            let list = res.data[0]
            this.form.good_id = list.id
            this.mall_name = list.mall && list.mall.mall_name //店铺名称
            this.pinlei_name = `${list.pcat.cat_name}  ${list.cat.cat_name}`
            this.coverimg = list.goods_img
            console.log('回显---------', this.mall_name)
            this.company_name =
              list.mall &&
              list.mall.certification &&
              list.mall.certification.company_name
          })
        }
      },
    },

    methods: {
      handlersave() {
        getAction('/api/work/sel-plan-goods/edit', this.form).then((res) => {
          if (res.request_code == 200) {
            this.showedit = false
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.$emit('getlist')
          }
        })
      },
      handlermell(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.good_id = ''
        } else {
          getAction('/mall/product/goods', {
            goods_name: queryString,
            is_on_sale: 1, //只搜索已审核、已上架的店铺
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleSelectmall(list) {
        console.log(list)
        this.form.good_id = list.id
        this.mall_name = list.mall && list.mall.mall_name //店铺名称
        this.pinlei_name = `${list.pcat.cat_name}  ${list.cat.cat_name}`
        this.coverimg = list.goods_img
        this.company_name =
          list.mall &&
          list.mall.certification &&
          list.mall.certification.company_name
      },
    },
  }
</script>

<style scoped></style>
