<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="关键词"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.advert_no"
          clearable
          placeholder="广告位置"
          style="width: 160px"
        >
                    <el-option v-for="list in spaceSelect"
                     :key="list.advert_no"
                     :value="list.advert_no"
                     :label="list.position_name"/>
<!--          <el-option label="置顶幻灯片" value="1004" />
          <el-option label="热展" value="1015" />-->
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 160px"
        >
          <el-option label="全部" value="0" />
          <el-option label="待审核" value="1" />
          <el-option label="已审核" value="2" />
          <el-option label="已拒绝" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_open"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option label="禁用" value="0" />
          <el-option label="启用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template #default="{ row }">
          <div v-if="row.status == 2">
            <el-button size="small" type="text" @click="handlerpass(row, 1)">
              通过
            </el-button>
            <el-button size="small" type="text" @click="handlerpass(row, 3)">
              拒绝
            </el-button>
          </div>
          <div v-if="row.status == 1">
            <el-button size="small" v-show="row.is_open == 0" type="text" @click="handlerrefuse(row, 1)">
              启用
            </el-button>
            <el-button size="small" v-show="row.is_open == 1" type="text" @click="handlerrefuse(row, 0)">
              禁用
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!--    <space-add ref="add" @getlist="handlerInquire"/>-->
    <!--    <spaceedit ref="edit"/>-->
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'

  export default {
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    data() {
      return {
        form: {
          title: '',
          advert_no: '',
          is_open:'',
          status: '',
          page: 1,
          limit: 10,
        },
        timelist:[],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '广告标题',
            prop: 'advert_name',
            width: '',
          },
          {
            label: '广告图',
            prop: 'img',
            width: '220px',
          },
          {
            label: '广告位置',
            prop: 'position_name',
            width: '220px',
          },
          {
            label: '添加时间',
            prop: 'created_txt',
            width: '220px',
          },
          {
            label: '审核时间',
            prop: 'check_time',
            width: '220px',
          },
          {
            label: '拒绝原因',
            prop: 'reason',
            width: '220px',
          },
          {
            label: '审核状态',
            prop: 'status_txt',
            width: '',
          },
          {
            label: '公司',
            prop: 'company',
            width: '',
          },
          {
            label: '曝光量',
            prop: 'exposure_num',
            width: '',
          },
          {
            label: "点击量",
            prop: "click_num",
          },
          {
            label: '店铺名',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '状态',
            prop: 'is_open_txt',
            width: '',
          },
        ],
        shopSelect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '已审核',
          },
          {
            id: 2,
            name: '待审核',
          },
          {
            id: 3,
            name: '已拒绝',
          },
        ],
        total: 0,
        spaceSelect: [],
      }
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      // async handlerSelect() {
      //   await getAction('/audit/advert/position-config').then((res) => {
      //     this.shopSelect = res.data
      //   })
      // },
      handlerSelect() {
        getAction('/audit/advert/position-config').then((res) => {
          console.log(res)
          this.spaceSelect = res.data
        })
      },
      handlerpass(row, type) {
       let messagedata
        type==1?messagedata='确认审核通过':messagedata='确认审核拒绝'
        this.$confirm(messagedata, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          if(type==1){
            postAction('/audit/advert/audit', { id: row.id, status: type }).then(
              (res) => {
                this.handlerInquire()
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
              }
            )
          }else{
            this.$prompt('请输入理由', '理由', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(({ value }) => {
              postAction('/audit/advert/audit', { id: row.id, status: type ,reason:value}).then(
                (res) => {
                  this.handlerInquire()
                  this.$message({
                    type: 'success',
                    message: res.msg,
                  })
                }
              )
            }).catch(() => {

            });
          }


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });


      },
      handlerrefuse(row, type) {
        postAction('/audit/advert/open', { id: row.id, open: type }).then(
          (res) => {
            this.handlerInquire()
            this.$message({
              type: 'success',
              message: res.msg,
            })
          }
        )
      },

      handlerlist() {
        getAction('/audit/advert/audit-lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
