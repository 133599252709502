var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tabledata } },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "编号",
              align: "center",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "类型",
              align: "center",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "content",
              label: "规则与教程",
              align: "center",
              width: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(row.content) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "", width: "110", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handerledit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }