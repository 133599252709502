<template>
  <el-dialog
    title="编辑"
    :visible.sync="showDialog"
    width="600px"
    top="4vh"
    :close-on-click-modal="false"
  >
    <div class="orderTest-container" style="padding-right: 90px">
      <el-form :model="form" ref="form" label-position="right" label-width="160px" label-suffix=":">
        <el-form-item label="姓名（用户名）" prop="username">
          <el-input disabled v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="headimg">
          <!-- <el-input disabled v-model="form.headimg"></el-input> -->
          <el-image
            style="width: 50px; height: 50px"
            :src="form.headimg"
            :preview-src-list="[form.headimg]"
          ></el-image>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input disabled v-model="form.nickname"></el-input>
        </el-form-item>
<!--        <el-form-item label="姓名" prop="realname">-->
<!--          <el-input disabled v-model="form.realname"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="公司名称" prop="company">
          <el-input disabled v-model="form.company"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-input disabled v-model="form.region"></el-input>
        </el-form-item>
        <el-form-item label="身份" prop="identity">
          <el-input disabled v-model="form.identity"></el-input>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input disabled v-model="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="渠道" prop="channel">
          <el-input disabled v-model="form.channel"></el-input>
        </el-form-item>
        <el-form-item label="注册电话" prop="mobile">
          <el-input disabled v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="有无锁定记录" prop="lock_status_text">
          <el-input disabled v-model="form.lock_status_text"></el-input>
        </el-form-item>
        <el-form-item label="最近锁定时间" prop="lock_time">
          <el-input disabled v-model="form.lock_time"></el-input>
        </el-form-item>
        <el-form-item label="最近锁定原因" prop="lock_reason">
          <el-input disabled v-model="form.lock_reason"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="memo">
          <el-input type="textarea" show-word-limit :maxlength="200" v-model="form.memo"></el-input>
        </el-form-item>
        <el-form-item label="账号状态" prop="">
          <el-radio-group v-model="form.is_lock">
            <el-radio label="0">正常</el-radio>
            <el-radio label="3">手动锁定</el-radio>
            <el-radio label="2">白名单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="锁定原因" v-if="form.is_lock == 3" prop="">
          <el-select :disabled="false" v-model="form.reason" clearable placeholder="可选">
            <el-option
              v-for="(i, idx) in dialogSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom-button center">
      <el-button type="primary" @click="handleSubmit">保存</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { postAction } from "@/api/request";
export default {
  name: "Edit",
  components: {},
  data() {
    return {
      showDialog: false,
      form: {
        is_lock: "0",
        reason: "",
        memo: "",
      },
      dialogSelect: [
        // 0正常，2白名单
        { id: "广告公司", name: "广告公司" },
        { id: "活动作弊", name: "活动作弊" },
      ],
    }
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    showDialog(v) {
      if (!v) {
        this.form.memo = ""
        // 帐号状态，0正常，2白名单，3手动锁定
        this.form.is_lock = "0"
        this.form.reason = ""
      }
    },
  },
  methods: {
    handleSubmit() {
      postAction("/Users/Lock_submit", {
        user_id: this.form.user_id,
        memo: this.form.memo,
        is_lock: this.form.is_lock,
        reason: this.form.reason,
      }).then((r) => {
        this.$emit("refresh")
        this.$message.success(r.msg)
        this.showDialog = false
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
