var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showvideo, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showvideo = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { clearable: "", placeholder: "分类" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "经销商" } }),
                      _c("el-option", { attrs: { value: 2, label: "企业" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "cover" } },
                [
                  _c("upload-img", {
                    ref: "cover",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "cover")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "视频文件", prop: "video" } },
                [
                  _c("upload-video", {
                    ref: "video",
                    attrs: { limit: 1, maxSize: 100 },
                    on: {
                      getVideos: function ($event) {
                        return _vm.getVideos($event, "video")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "share_title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.form.share_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "share_title", $$v)
                      },
                      expression: "form.share_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享图", prop: "share_img" } },
                [
                  _c("upload-img", {
                    ref: "share_img",
                    attrs: { infoText: "", limit: 1, maxSize: 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "share_img")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort_order" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "220px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { clearable: "", placeholder: "状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "启用" } }),
                      _c("el-option", { attrs: { value: 0, label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showvideo = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }