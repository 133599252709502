<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.depart_id"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="list in bumenSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.htyun_name"
          clearable
          placeholder="提报人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="奖励结束时间"
          format="yyyy-MM"
          start-placeholder="奖励开始时间"
          style="width: 340px"
          type="monthrange"
          value-format="yyyy-MM"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      v-loading="listLoading"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        form: {
          depart_id: '',
          htyun_name: '',
          invite_id: '',
          invite_name: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        time: [],
        bumenSelect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '姓名',
            prop: 'htyun_name',
            width: '',
          },
          {
            label: '创建需求已通过',
            prop: 'biz_pass_num',
            width: '',
          },
          {
            label: '选品方案已喜欢',
            prop: 'sel_like_num',
            width: '',
          },
          {
            label: '有效留言数',
            prop: 'message_count',
            width: '',
          },
          {
            label: '经销商找品数',
            prop: 'find_count',
            width: '',
          },
          {
            label: '合计',
            prop: 'kpi',
            width: '',
          },
        ],
        total: 0,
        sum: 0,
        listLoading: true,
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getDepart()
    },
    methods: {
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '部门') {
            sums[index] = '共计'
          }
          if (column.label === '创建需求已通过') {
            sums[index] = this.sum.biz_pass_num
          }
          if (column.label === '选品方案已喜欢') {
            sums[index] = this.sum.sel_like_num
          }
          if (column.label === '有效留言数') {
            sums[index] = this.sum.message_count
          }
          if (column.label === '经销商找品数') {
            sums[index] = this.sum.find_count
          }
          if (column.label === '合计') {
            sums[index] = this.sum.kpi
          }
        })
        return sums
      },
      handlerInquire() {
        this.listLoading = true
        this.form.page = 1
        this.handlerlist()
      },
      getDepart() {
        postAction('/audit/biz-clue/diy-department').then((res) => {
          if (res.request_code == 200) {
            this.bumenSelect = res.data
          }
        })
      },
      handlerlist() {
        getAction('/audit/biz-clue/workstation-count', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
            this.listLoading = false
          }
        )
        getAction('/audit/biz-clue/all-workstationcount', this.form).then(
          (res) => {
            console.log('统计合计-', res)
            this.sum = res.data
          }
        )
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-workstationcount', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
