<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          style="width: 160px"
          placeholder="版别"
        >
          <el-option
            v-for="item in identitySelect"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          style="width: 160px"
          placeholder="状态"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :value="list.id"
            :label="list.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>
    <addbanner
      ref="banner"
      @getlist="handlerInquire"
      :identitySelect="identitySelect"
    />
    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == 'logo图'">
            <el-image
              style="width: 70px; height: 30px"
              fit="scale-down"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else-if="item.label == '背景图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="list"
                :preview-src-list="[list]"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '版别'">
            {{
              identitySelect.filter((list) => list.id == row[item.prop])[0].name
            }}
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="220">
        <template #default="{ row }">
          <el-popconfirm title="确认删除？" @confirm="handlerdelete(row)">
            <el-button slot="reference" type="text" size="small">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            style="margin: 10px"
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import Addbanner from './components/addbanner'
  export default {
    name: 'index',
    components: { Addbanner },
    data() {
      return {
        form: {
          type: null,
          status: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        identitySelect: [],
        columns: [
          {
            label: '版别',
            prop: 'type',
            width: '',
          },

          {
            label: 'logo图',
            prop: 'logo',
            width: '160',
          },

          {
            label: '背景图',
            prop: 'pic_content',
            width: '',
          },
          {
            label: '背景色',
            prop: 'bg_color',
            width: '80',
          },

          {
            label: '状态',
            prop: 'status',
            width: '80',
          },
        ],
        shopSelect: [
          {
            id: 1,
            name: '启用',
          },
          {
            id: 2,
            name: '禁用',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerSelect()
      this.handlerInquire()
    },
    methods: {
      handlerSelect() {
        getAction('/api/system/skin/select').then((res) => {
          console.log(res)
          this.identitySelect = res.data
        })
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.banner.addbanner = true
      },
      handlerinfo(row) {
        this.$refs.info.form = row
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        getAction('/api/system/skin/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        getAction('/api/system/skin/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      handleredit(row) {
        console.log(row)
        this.$refs.banner.addbanner = true
        this.$refs.banner.handlerinfo(row)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
