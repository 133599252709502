<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item>
        <el-select v-model="form.type">
          <el-option value="11" label="android"/>
          <el-option value="12" label="ios"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.buildnum" placeholder="版本号"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.appname" placeholder="app名称"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">
          新建
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tabledata"
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='下载链接'">
            <a :href="row[item.prop]"><el-button type="text">下载</el-button></a>
          </div>
          <div v-else-if="item.label=='操作'">
           <el-button type="text" @click="handleredit(row)">编辑</el-button>
          </div>
          <div v-else-if="item.label=='强制升级'">
            {{row[item.prop]==1?'是':'否'}}
          </div>
          <div v-else-if="item.label=='显示'">
            {{row[item.prop]==1?'显示':'不显示'}}
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <addedit ref="add" @getlist="handlerInquire"/>
  </div>
</template>x

<script>
import { postAction } from "@/api/request";
import Addedit from "./components/addedit";

export default {
  name: "index",
  components: { Addedit  },
  data(){
    return {
      form: {
        buildnum: '',
        appname:'',
        type:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "编号",
          prop: "id",
          width: "",
        },
        {
          label: "系统",
          prop: "appname",
          width: "",
        },
        {
          label: "版本",
          prop: "version",
          width: "",
        },
        {
          label: "版本号",
          prop: "buildnum",
          width: "",
        },
        {
          label: "强制升级",
          prop: "upgrade",
          width: "",
        },{
          label: "显示",
          prop: "isshow",
          width: "",
        },
        {
          label: "下载链接",
          prop: "url",
          width: "",
        },{
          label: "更新信息",
          prop: "info",
          width: "",
        },{
          label: "备注",
          prop: "memo",
          width: "",
        },{
          label: "包体大小",
          prop: "size",
          width: "",
        },
        {
          label: "升级时间",
          prop: "push_time",
          width: "",
        },
        {
          label: "操作",
          prop: "heji",
          width: "",
        },
      ],
      total: 0,
    }
  },
  mounted() {

    this.handlerInquire()
  },
  methods:{
    handleredit(row){
      this.$refs.add.handlerinfo(row)
      this.$refs.add.showadd=true
    },
    handlerAdd(){
      this.$refs.add.showadd=true
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },

    async handlerlist(){
      await postAction("/api/system/version/index",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)

      })

    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.pagesize = val
      this.handlerlist()
    },
  }
};
</script>

<style scoped>

</style>
