<template>
  <el-dialog
    title="封号记录"
    :visible.sync="showDialog"
    width="500px"
    top="5vh"
    :close-on-click-modal="false"
  >
    <div class="orderTest-container" v-loading="loading">
      <div style="padding: 10px 0">共锁定次数：{{ total }}</div>
      <el-card v-for="(item, index) in list" :key="index" style="margin-bottom: 10px">
        <div>
          <span>{{ item.mode }}时间:</span>
          <span>{{ item.add_time }}</span>
        </div>
        <div>
          <span>{{ item.mode }}方式:</span>
          <span>{{ item.method }}</span>
        </div>
        <div v-if="item.method == '手动锁定' || item.method == '自动锁定'">
          <span>锁定原因:</span>
          <span>{{ item.reason }}</span>
        </div>
      </el-card>
    </div>
    <div class="bottom-button">
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { postAction } from "@/api/request";
export default {
  name: "BanLog",
  components: {},
  data() {
    return {
      showDialog: false,
      loading: false,
      user_id: "",
      total: 0,
      form: {},
      list: [],
    }
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    showDialog(v) {
      if (v) {
        this.fetchData()
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true
      let data = await postAction("/Users/Lock_log", { user_id: this.user_id })
      console.log("data", data)
      this.total = data.result[0].total
      this.list = data.result[0].list
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
