<template>
  <div class="index-container">
    <el-row :gutter="10">
     <el-col :span="12">
       <el-table :data="tabledata" border>
         <el-table-column prop="green_channel_start_time" label="绿色通道开始时间" align="center"></el-table-column>
         <el-table-column prop="green_channel_end_time" label="绿色通道结束时间"  align="center"></el-table-column>
         <el-table-column prop="green_channel_act" label="展会名称" align="center"></el-table-column>
         <el-table-column label="操作" align="center">
           <template #default="{row}">
             <el-button type="text" size="mini" @click="handleredit(row)">编辑</el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-col>
    </el-row>
    <el-dialog :visible.sync="edit" title="编辑绿色通道" width="30%">
      <el-form :model="form" label-width="130px">
        <el-form-item label="绿色通道开始时间">
          <el-date-picker
            v-model="form.green_channel_start_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="startpickerOptions"
          />
        </el-form-item>
        <el-form-item label="绿色通道结束时间">
          <el-date-picker
            v-model="form.green_channel_end_time"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="展会名称">
          <el-input v-model="form.green_channel_act" placeholder="请输入展会名称" style="width: 220px" maxlength="50"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button @click="edit=false">取 消</el-button>
        <el-button type="primary" @click="handlereditsave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "index",
  data(){
    return{
      edit:false,
      tabledata:[],
      pickerOptions:{
        disabledDate:(time) =>{
          if(this.form.green_channel_start_time){
           return time.getTime() < new Date(this.form.green_channel_start_time).getTime()
          }
        },
      },
      startpickerOptions:{
        disabledDate:(time) =>{
          if(this.form.green_channel_end_time){
            return time.getTime() > new Date(this.form.green_channel_end_time).getTime()
          }
        },
      },
      form:{
        green_channel_start_time:'',
        green_channel_end_time:'',
        green_channel_act:''
      }
    }
  },
  mounted() {
    this.handlerlist()
  },
  methods:{
    handleredit(row){
      this.edit=true
      this.form=row
    },
    handlerlist(){
      this.tabledata=[]
      getAction("/api/system/conf/green-conf",{}).then(res=>{
        this.tabledata.push(res.data)
      })
    },
    handlereditsave(){
      postAction("/api/system/conf/set-green-conf",this.form).then(res=>{
        this.edit=false
        this.handlerlist()
      })
    }
  }
};
</script>

<style scoped>

</style>
