<template>
  <div class="index-container">
    <h2 style="text-align: center">调查问卷（2024春糖）统计</h2>
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.htyun_dept"
          clearable
          filterable
          placeholder="部门"
          style="width: 160px"
        >
          <el-option
            v-for="list in deptSelect"
            :key="list.htyun_dept"
            :label="list.htyun_dept"
            :value="list.htyun_dept"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.htyun_name"
          clearable
          filterable
          placeholder="邀约人"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.htyun_name"
            :label="list.htyun_name"
            :value="list.htyun_name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      />
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'Index',
    data() {
      return {
        time: [],
        form: {
          start_time: '',
          end_time: '',
          htyun_name: '',
          htyun_dept: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        total: 0,
        columns: [
          {
            label: '部门',
            prop: 'htyun_dept',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'htyun_name',
            width: '',
          },
          {
            label: '小程序分享次数',
            prop: 'share_count',
            width: '',
          },
          {
            label: '浏览次数',
            prop: 'scan_count',
            width: '',
          },
          {
            label: '未完成',
            prop: 'no_end_count',
            width: '',
          },
          {
            label: '已完成',
            prop: 'end_count',
            width: '',
          },
          {
            label: '已抽奖',
            prop: 'award_count',
            width: '',
          },
        ],
        deptSelect: [],
        staffSelect: [],
        sumtotal: {}, //合计
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        const dept = await getAction('/form/form/dept')
        this.deptSelect = dept.data
        const staff = await getAction('/form/form/staff')
        this.staffSelect = staff.data
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '部门') {
            sums[index] = '共计'
          }
          // if (column.label == '小程序分享次数') {
          //   sums[index] = this.sumtotal.share_count
          // }
          // if (column.label == '浏览次数') {
          //   sums[index] = this.sumtotal.scan_count
          // }
          if (column.label == '未完成') {
            sums[index] = this.sumtotal.no_end_count
          }
          if (column.label == '已完成') {
            sums[index] = this.sumtotal.end_count
          }
          if (column.label == '已抽奖') {
            sums[index] = this.sumtotal.award_count
          }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        getAction('/api/form/research-spring/staff-tj', this.form).then(
          (res) => {
            this.tabledata = res.data.list
            if (this.form.page == 1) {
              this.sumtotal = res.data.sum
            }

            this.total = Number(res.totalCount)
          }
        )
      },
      handlerExport() {
        postAction('/api/form/research-spring/staff-tj-export', this.form).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.list,
              filename: res.data.name,
            })
          }
        )
      },
    },
  }
</script>
