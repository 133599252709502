<template>
  <el-dialog title="编辑" :visible.sync="showedit" width="780px">
    <div>
      <el-form
        ref="form"
        inline
        label-suffix="："
        label-width="140px"
        :model="form"
      >
        <el-form-item label="姓名">
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入姓名"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="一级品类">
          <el-select
            v-model="form.class_id"
            clearable
            placeholder="一级分类"
            style="width: 220px"
            @change="handlercat"
          >
            <el-option
              v-for="list in cateselect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="二级品类">
          <el-select
            v-model="form.class_child_id"
            clearable
            placeholder="二级分类"
            style="width: 220px"
          >
            <el-option
              v-for="list in catselect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司">
          <el-input
            v-model="form.company"
            clearable
            placeholder="请输入公司"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="form.mobile"
            clearable
            placeholder="请输入电话"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="form.intro"
            clearable
            placeholder="请输入内容"
            rows="2"
            style="width: 220px"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="省">
          <el-select
            v-model="form.province_id"
            clearable
            placeholder="省"
            style="width: 220px"
            @change="handlerpro"
          >
            <el-option
              v-for="list in provinceselect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="市">
          <el-select
            v-model="form.region_id"
            clearable
            placeholder="市"
            style="width: 220px"
          >
            <el-option
              v-for="list in aceselect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>

        <!--        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form."
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>-->
        <el-form-item label="营业执照">
          <upload-img
            ref="license"
            info-text=""
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'license')"
          />
        </el-form-item>
        <el-form-item label="图片1">
          <upload-img
            ref="list_img1"
            info-text=""
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'list_img1')"
          />
        </el-form-item>
        <el-form-item label="图片2">
          <upload-img
            ref="list_img2"
            info-text=""
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'list_img2')"
          />
        </el-form-item>
        <el-form-item label="图片3">
          <upload-img
            ref="list_img3"
            info-text=""
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'list_img3')"
          />
        </el-form-item>
        <el-form-item label="是否发送站内信">
          <el-select
            v-model="form.is_sms"
            clearable
            placeholder="是否发送站内信"
            style="width: 160px"
          >
            <el-option label="是" value="1" />
            <el-option label="否" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select
            v-model="form.is_check"
            clearable
            placeholder="审核状态"
            style="width: 160px"
          >
            <el-option label="审核" value="1" />
            <el-option label="未审核" value="0" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg'
  import { nextTick } from 'vue'

  export default {
    name: 'Edit',
    components: { UploadImg },
    data() {
      return {
        showedit: false,
        form: {
          is_sms: '',
          is_check: '',
          province_id: '',
          region_id: '',
          class_id: '',
          class_child_id: '',
        },
        cateselect: [],
        catselect: [],
        provinceselect: [],
        aceselect: [],
      }
    },
    mounted() {
      this.handlerSelect()
    },
    methods: {
      handlersave() {
        postAction('/mall/proxy/purchase-update', this.form).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.close()
          this.$emit('getlist')
        })
      },
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
        if (this.form.class_child_id == 0) {
          this.form.class_child_id = ''
        }
        this.getAreaList(row.province_id)
        // this.handlerpro(row.province_id)
        this.handlercat(row.class_id)
        nextTick(() => {
          this.setImg(row.license, 'license')
          this.setImg(row.list_img1, 'list_img1')
          this.setImg(row.list_img2, 'list_img2')
          this.setImg(row.list_img3, 'list_img3')
        })
      },
      handlerpro(e) {
        this.form.region_id = ''
        this.getAreaList(e)
      },
      getAreaList(e) {
        if (this.form.region_id == 0) {
          this.form.region_id = ''
        }
        getAction('/user/index/area', { pid: e }).then((res) => {
          console.log('获取市区-', res)
          this.aceselect = res.data
        })
      },
      async handlerSelect() {
        const provin = await getAction('/user/index/area', { pid: 0 })
        this.provinceselect = provin.data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      show() {
        this.showedit = true
      },
      close() {
        this.showedit = false
        this.form = {
          find_id: '',
        }
        nextTick(() => {
          this.closeimg('license')
          this.closeimg('list_img1')
          this.closeimg('list_img2')
          this.closeimg('list_img3')
        })
      },
    },
  }
</script>

<style scoped></style>
