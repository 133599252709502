var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.relatedShow,
            width: "70%",
            "before-close": _vm.handleClose,
            align: "center",
          },
          on: {
            "update:visible": function ($event) {
              _vm.relatedShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "related" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerEdit()
                        },
                      },
                    },
                    [_vm._v("新增题目")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    border: "",
                    "row-class-name": _vm.tableRowClassName,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "ID",
                      width: "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.answer_list != undefined
                                      ? scope.row.id
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "类型", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.is_single == 1
                              ? _c("span", [_vm._v("单选")])
                              : _vm._e(),
                            scope.row.is_single == 2
                              ? _c("span", [_vm._v("多选")])
                              : _vm._e(),
                            scope.row.is_single == 3
                              ? _c("span", [_vm._v("文本框")])
                              : _vm._e(),
                            scope.row.is_single == 0
                              ? _c("span", [_vm._v("选品题")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "题目" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "答案", prop: "reply" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "关联下题", align: "center", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.is_single == 2 && !scope.row.answer_list
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(scope.row.next_question) + " "
                                  ),
                                ])
                              : _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.nextShow = true
                                        _vm.next_question =
                                          scope.row.next_question
                                        _vm.row = scope.row
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.next_question) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否结束答题",
                      align: "center",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changestate(scope.row, "is_end")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.is_end == 1 ? "是" : "否"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "排序", width: "100", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.move(scope.row, "-1")
                                  },
                                },
                              },
                              [_vm._v(" 上移 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.move(scope.row, "1")
                                  },
                                },
                              },
                              [_vm._v(" 下移 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "option",
                      label: "操作",
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-end",
                                  "align-items": "center",
                                },
                              },
                              [
                                scope.row.title != undefined &&
                                scope.row.answer_list
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerEdit(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                scope.row.is_sort == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: { click: _vm.getcate },
                                      },
                                      [_vm._v(" 查看品类 ")]
                                    )
                                  : _vm._e(),
                                scope.row.answer_list
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  : _vm._e(),
                                scope.row.answer_list != undefined
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changestate(
                                              scope.row,
                                              "status"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.status == 0
                                                ? "禁用"
                                                : "启用"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.answer_list == undefined
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changestate(
                                              scope.row,
                                              "is_hide"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.is_hide == 1
                                                ? "禁用"
                                                : "启用"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联下一题",
            visible: _vm.nextShow,
            width: "300px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nextShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入要关联的下一题ID" },
                model: {
                  value: _vm.next_question,
                  callback: function ($$v) {
                    _vm.next_question = $$v
                  },
                  expression: "next_question",
                },
              }),
              _c(
                "p",
                {
                  staticStyle: { "margin-top": "20px", "text-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.nextShow = false
                          _vm.next_question = ""
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changestate(_vm.row, "next_question")
                        },
                      },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("editquestion", { ref: "editquestion", on: { getinfo: _vm.getinfo } }),
      _c("category", { ref: "category" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }