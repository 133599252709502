var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "append-to-body": "",
        title: "创建云会议" + "" + _vm.form.title,
        visible: _vm.showcloud,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showcloud = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-suffix": "：",
                "label-width": "140px",
                model: _vm.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "需求客户", prop: "total" } },
                [_vm._v(" " + _vm._s(_vm.form.external_user_name) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议管理员", prop: "read" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.handlerSelect },
                      model: {
                        value: _vm.form.admin_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "admin_id", $$v)
                        },
                        expression: "form.admin_id",
                      },
                    },
                    _vm._l(_vm.form.admin_list, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "参会客户", prop: "is_jump" } },
                [_vm._v(" " + _vm._s(_vm.form.external_user_name) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "需求提报人", prop: "click" } },
                [_vm._v(" " + _vm._s(_vm.form.staff_user_name) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议标题", prop: "click" } },
                [_vm._v(" " + _vm._s(_vm.form.sub_title || "暂无") + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议开始时间", prop: "click" } },
                [_vm._v(" " + _vm._s(_vm.form.meeting_start || "暂无") + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议持续时间", prop: "click" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.form.meeting_duration || "暂无") + " "
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会议描述", prop: "click" } },
                [_vm._v(" " + _vm._s(_vm.form.desc || "暂无") + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }