<template>
  <div class="index-container">
    <el-form ref="form" :model="form" label-suffix=":" inline>
      <el-form-item >
        <el-input placeholder="主题活动" clearable v-model="form.title" style="width: 160px;"/>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{row}">
          <div v-if="item.label=='底部logo'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
                    <div v-else-if="item.label=='小程序分享图'">
                      <el-image
                        style="width: 70px; height: 70px"
                        :src="row[item.prop]"
                        :preview-src-list="[row[item.prop]]">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                      </el-image>
                    </div>
          <div v-else-if="item.label=='H5分享图'">
            <el-image
              style="width: 70px; height: 70px"
              :src="row[item.prop]"
              :preview-src-list="[row[item.prop]]">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div v-else>{{row[item.prop]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="280">
        <template  #default="{row}">
          <el-button
            @click.native.prevent="handleredit(row)"
            type="text"
            size="small">
            编辑
          </el-button>
          <el-button
            @click.native.prevent="handlerdelete(row)"
            type="text"
            size="small">
            删除
          </el-button>
          <el-button
            @click.native.prevent="handlersecondary(row)"
            type="text"
            size="small">
            二级菜单
          </el-button>
          <el-button
            @click.native.prevent="handleReview(row)"
            type="text"
            size="small">
            小程序码
          </el-button>
          <el-button
            @click.native.prevent="handlerbanner(row)"
            type="text"
            size="small">
            banner图
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      style="text-align: center; margin-top: 10px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <secondary ref="secondary"/>
    <addsecond ref="add" @getlist="handlerInquire"/>
    <bannerlist ref="banner"/>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import Secondary from "@/views/pages/systemSet/theme/components/secondary";
import Addsecond from "@/views/pages/systemSet/theme/components/addsecond";
import Bannerlist from "@/views/pages/systemSet/theme/components/bannerlist";

export default {
  name: "index",
  components: { Bannerlist, Addsecond, Secondary },
  data(){
    return{
      form: {
        title:'',
        page: 1,
        limit: 10
      },
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "id",
          prop: "id",
          width: "",
        },

        {
          label: "活动主题",
          prop: "title",
          width: "160",
        },

        {
          label: "底部logo",
          prop: "foot_logo",
          width: "",
        },
        {
          label: "背景色",
          prop: "bg_color",
          width: "",
        },

        {
          label: "查看更多按钮背景",
          prop: "more_button",
          width: "",
        },{
          label: "小程序分享图",
          prop: "share_img",
          width: "",
        },
        {
          label: "H5分享图",
          prop: "h5_share_img",
          width: "",
        },

        {
          label: "分享标题",
          prop: "share_title",
          width: "",
        }, {
          label: "分享描述",
          prop: "share_desc",
          width: "",
        },
      ],
      total: 0,
    }
  },
  methods:{
    handlerbanner(row){
      this.$refs.banner.show()
      this.$refs.banner.form.actid=row.id
      this.$refs.banner.handlerInquire()
    },
    handleredit(row){
      console.log(row)
      this.$refs.add.show()
      this.$refs.add.title='编辑'
      this.$refs.add.handlerinfo(row)
    },
    handlerAdd(){
      this.$refs.add.show()
      this.$refs.add.title='新增'
    },
    handleReview(row){
      let code= row.mini_code
      this.$alert(`<img src="${code}" style="width: 380px;height: 380px">`, '小程序码', {
        dangerouslyUseHTMLString: true
      });
    },
    handlersecondary(row){
      this.$refs.secondary.form.actid=row.id
      this.$refs.secondary.show()
      this.$refs.secondary.handlerInquire()
    },
    handlerdelete(row){
      postAction("/api/theme/act/delete",{id:row.id}).then(res=>{
        this.$message({
          type:'success',
          message:res.msg
        })
        this.handlerInquire()
      })
    },
    handlerInquire(){
      this.form.page=1
      this.handlerlist()
    },
    handlerlist(){
      getAction("/api/theme/act/lists",this.form).then(res=>{
        this.tabledata=res.data
        this.total= Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
  mounted() {
    this.handlerInquire()
  }
};
</script>

<style scoped>

</style>
