<template>
  <el-dialog :title="title" :visible.sync="show" width="90%">
    <div style="max-height: 70vh; overflow-y: scroll">
      <el-table
        v-loading="listLoading"
        border
        :data="form.list"
        :span-method="objectTplSpanMethod"
      >
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template v-if="item.label == '操作'" #default="{ row }">
            <el-button type="text" @click="handlerInit(row)">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <infolike ref="infolike" />
    </div>
    <div slot="footer" class="dialog-footer center">
      <!-- <el-button @click="handlerExtents" type="primary">导出</el-button> -->
      <el-button @click="show = !show">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
  // import Infolike from './infolike'
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'Info',
    // components: { Infolike },
    data() {
      return {
        listLoading: true,
        show: false,
        form: {
          answer_id: null,
          list: [],
        },
        columns: [
          {
            label: '问题',
            prop: 'question',
            width: '220',
          },
          {
            label: '答案',
            prop: 'answer',
            width: '',
          },

          {
            label: '二级答案',
            prop: 'second_answer',
            width: '',
          },
          {
            label: '人数',
            prop: 'num',
            width: '',
          },
          {
            label: '本题占比',
            prop: 'percent',
            width: '',
          },
          {
            label: '操作',
            prop: 'percent',
            width: '',
          },
        ],
      }
    },
    computed: {
      title() {
        return this.form.answer_id == 403
          ? '经销商'
          : this.form.answer_id == 404
          ? '生产厂家'
          : '其他'
      },
    },
    watch: {
      // 'form.type'(val) {
      //   if (val == 1) {
      //     this.columns.splice(this.columns.length - 3, 0, {
      //       label: '二级答案',
      //       prop: 'second_answer',
      //       width: '',
      //     })
      //   } else {
      //     this.columns = [
      //       {
      //         label: '问题',
      //         prop: 'question',
      //         width: '220',
      //       },
      //       {
      //         label: '答案',
      //         prop: 'answer',
      //         width: '',
      //       },
      //       {
      //         label: '二级答案',
      //         prop: 'second_answer',
      //         width: '',
      //       },
      //       {
      //         label: '人数',
      //         prop: 'num',
      //         width: '',
      //       },
      //     ]
      //   }
      // },
    },
    methods: {
      handlerlist() {
        let param = {
          role: this.form.answer_id,
          conf_id: 33,
        }
        getAction('/api/form/research/answer-tj', param).then((res) => {
          if (res.request_code == 200) {
            this.form.list = res.data
            // this.sumtotal = res.data.sum
            this.total = Number(res.totalCount)
            this.listLoading = false
          }
        })
      },
      handlerInit(row) {
        // path: '/datastatis/survey/chuntang2024/detail',
        let answer_id =
          row.second_answer_id == 0 ? row.answer_id : row.second_answer_id
        this.$router.push({
          path: '/datastatis/survey/qiutang/qiutang2024/detail',
          query: { answer_id: answer_id },
        })
        // this.$refs.infolike.form.answer_id = row.answer_id
        // this.$refs.infolike.form.question_id = row.question_id
        // this.$refs.infolike.handlerInquire()
        // this.$refs.infolike.show = true
      },
      handlerinfo() {
        // post_('/Tongji/Years_Answer_detail', { type: this.form.type }).then(
        //   (res) => {
        //     console.log(res)
        //     this.form.list = res.result[0]
        //   }
        // )
      },
      handlerExtents() {
        // exportFile({ type: this.form.type }, '/Tongji/Answer_type_export')
      },
      /**
       * 合并列
       */
      objectTplSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.form.list, 'question').one[
            rowIndex
          ]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
        if (columnIndex === 1) {
          const _row = this.flitterData(this.form.list, 'answer').one[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
       * 合并单元格
       */
      flitterData(arr, column) {
        let spanOneArr = [],
          concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item[column] === arr[index - 1][column]) {
              //第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },
    },
  }
</script>

<style scoped></style>
