var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: "", "label-suffix": ":", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "需求客户" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "需求客户" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "一级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "一级分类" },
                  on: { change: _vm.handlercat },
                  model: {
                    value: _vm.form.root_cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "root_cat_id", $$v)
                    },
                    expression: "form.root_cat_id",
                  },
                },
                _vm._l(_vm.cateselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二级品类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "二级分类" },
                  model: {
                    value: _vm.form.cat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cat_id", $$v)
                    },
                    expression: "form.cat_id",
                  },
                },
                _vm._l(_vm.catselect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "需求形式" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.xuqiutypelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需求电话" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: {
                  clearable: "",
                  maxlength: "11",
                  placeholder: "需求电话",
                },
                model: {
                  value: _vm.form.telephone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "telephone", $$v)
                  },
                  expression: "form.telephone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "公司名称" },
                model: {
                  value: _vm.form.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提报人" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "提报人" },
                model: {
                  value: _vm.form.tibao_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tibao_name", $$v)
                  },
                  expression: "form.tibao_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核人" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "审核人" },
                model: {
                  value: _vm.form.shenhe_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "shenhe_name", $$v)
                  },
                  expression: "form.shenhe_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业服务人员" } },
            [
              _c("el-input", {
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "企业服务人员" },
                model: {
                  value: _vm.form.enterprise_server,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "enterprise_server", $$v)
                  },
                  expression: "form.enterprise_server",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "审核状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.showSelsect, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选品方案状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "选品方案状态" },
                  model: {
                    value: _vm.form.sel_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sel_status", $$v)
                    },
                    expression: "form.sel_status",
                  },
                },
                _vm._l(_vm.selStatusList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "云会议状态" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "云会议状态" },
                  model: {
                    value: _vm.form.meeting_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "meeting_status", $$v)
                    },
                    expression: "form.meeting_status",
                  },
                },
                _vm._l(_vm.meetingStatusList, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "xuanpinexport",
                      expression: "'xuanpinexport'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v(" 导 出 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      item.label == "云会议状态"
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] == 1,
                                          expression: "row[item.prop] == 1",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleraddcloud(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 待创建 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            row[item.prop] == 3 &&
                                            row.ask_status == 2,
                                          expression:
                                            "row[item.prop] == 3 && row.ask_status == 2",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v(" 已完成 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            row[item.prop] == 3 &&
                                            row.ask_status == 1,
                                          expression:
                                            "row[item.prop] == 3 && row.ask_status == 1",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v(" 会议已结束 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] > 1,
                                          expression: "row[item.prop] > 1",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlercloudinfo(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 详情 ")]
                                  ),
                                ],
                                1
                              ),
                              _c("vue-qr", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row[item.prop] == 2,
                                    expression: "row[item.prop] == 2",
                                  },
                                ],
                                attrs: {
                                  margin: 10,
                                  size: 100,
                                  text: row.meeting_qr,
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 2,
                                      expression: "row[item.prop] == 2",
                                    },
                                  ],
                                },
                                [_vm._v("请用企业微信扫码")]
                              ),
                            ],
                            1
                          )
                        : item.label == "调查问卷"
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] == 2,
                                          expression: "row[item.prop] == 2",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v(" 已完成 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row[item.prop] == 2,
                                          expression: "row[item.prop] == 2",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlersurveyinfo(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 详情 ")]
                                  ),
                                ],
                                1
                              ),
                              _c("vue-qr", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row[item.prop] == 1,
                                    expression: "row[item.prop] == 1",
                                  },
                                ],
                                attrs: {
                                  margin: 10,
                                  size: 100,
                                  text: row.ask_qr,
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row[item.prop] == 1,
                                      expression: "row[item.prop] == 1",
                                    },
                                  ],
                                },
                                [_vm._v("请用企业微信扫码")]
                              ),
                            ],
                            1
                          )
                        : item.label == "操作"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.need_connect_status != 1,
                                      expression:
                                        "row.need_connect_status != 1",
                                    },
                                  ],
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerreedit(row)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.need_connect_status != 1,
                                      expression:
                                        "row.need_connect_status != 1",
                                    },
                                  ],
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerrefuse(row)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    "confirm-button-text": "设置无效",
                                    title: "设置会议无效",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.handlerfurm(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.is_valid == 1,
                                          expression: "row.is_valid == 1",
                                        },
                                      ],
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(" 云会议状态 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : item.label == "云会议是否有效"
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(row[item.prop] == 1 ? "有效" : "无效") +
                                " "
                            ),
                          ])
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add-select", { ref: "add", on: { getlist: _vm.handlerlist } }),
      _c("invaildreason", {
        ref: "invaildreason",
        attrs: { rowid: _vm.rowid },
        on: { getlist: _vm.handlerlist },
      }),
      _c("add-cloudinfo", {
        ref: "cloudinfo",
        on: { getlist: _vm.handlerlist },
      }),
      _c("surveyinfo", { ref: "survey", on: { getlist: _vm.handlerlist } }),
      _c("add-cloud", { ref: "cloud", on: { getlist: _vm.handlerlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }