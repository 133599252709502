<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="13vh"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-position="right"
        label-suffix=":"
        label-width="120px"
      >
        <el-form-item label="所属栏目" prop="">
          <el-input style="width: 390px" v-model="form.type_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="跳转类型" prop="">
          <el-input style="width: 390px" v-model="form.jump_type" disabled></el-input>
        </el-form-item>
        <el-form-item :label="liveTitle" prop="live_id">
          <el-select
            style="width: 390px"
            v-model="form.live_id"
            filterable
            clearable
            placeholder=""
          >
            <el-option
              v-for="item in selectList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select style="width: 390px" v-model="form.status" placeholder="">
            <!-- 0禁用，1启用 -->
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="bottom-button">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getAction, postAction } from "@/api/request";
export default {
  name: "AddEditLive",
  components: {},
  data() {
    return {
      editType: 1,
      loading: false,
      showDialog: false,
      selectList: [],
      // 获取详情参数
      params: {},

      // 提交参数
      form: {
        id: "",
        type: "",
        live_id: "",
        status: "",
        jump_type_id:''
      },
      rules: {
        status: [{ required: true, message: "请选择状态" }],
        live_id: [{ required: true, message: "请选择标题" }],
      },
      liveTitle:''
    }
  },
  created() {},
  mounted() {},

  computed: {
    // liveTitle() {
    //   //  <el-option label="普通样式" :value="1"></el-option>
    //   // <el-option label="选品样式" :value="2"></el-option>
    //   // <el-option label="竖版直播样式" :value="3"></el-option>
    //   console.log(this.form.jump_type_id)
    //   // if(this.form.jump_type_id!=undefined){
    //     if (this.form.jump_type_id == 2) {
    //       return "选品日标题"
    //     } else {
    //       return "头条直播标题"
    //     // }
    //   }

    // },
    title() {
      if (this.editType == 1) {
        return "新增"
      } else {
        return "编辑"
      }
    },
  },
  watch: {
    'form.jump_type_id'(val){
      console.log()
      if (val == 2) {
        this.liveTitle= "选品日标题"
      } else {
        this.liveTitle= "头条直播标题"
      }
    },
    showDialog(v) {
      if (v) {
      } else {
        this.form = { id: "", type: "", live_id: "", status: "" }
        this.params = {}
      }
    },
  },
  methods: {
    async fetchData(row) {
      this.loading = true
      try {
        this.form=Object.assign(this.form,row)
        if(this.form.live_id){
          this.form.live_id=Number(this.form.live_id)
        }
          let toutiaolist = await postAction("/live/index/live-search",{type_id:this.params.type})
          this.selectList = toutiaolist.data
        this.loading = false
      } catch (err) {
        console.log("接口调用错误", err)
        this.loading = false
      }
    },
    handleSubmit() {
      let params = {
        id: this.form.id,
        type_id: this.params.type,
        live_id: this.form.live_id,
        status: this.form.status,
        title:this.selectList.filter(list=>list.id==this.form.live_id)[0].title
      }
      if (this.editType == 1) {
       delete params.id
      }
      this.$refs.form.validate((v) => {
        if (v) {
          postAction("/live/index/edit-rel", params).then((res) => {
            this.$message.success(res.msg)
            this.$emit("refresh")
            this.showDialog = false
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
