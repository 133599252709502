var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showtow,
        width: "60%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showtow = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "160px",
                "label-suffix": "：",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "banner图" } },
                [
                  _c("upload-img", {
                    ref: "banner_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "banner_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小程序分享图" } },
                [
                  _c("upload-img", {
                    ref: "mini_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "mini_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "H5分享图" } },
                [
                  _c("upload-img", {
                    ref: "h5_pic",
                    attrs: { "info-text": "", limit: 1 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "h5_pic")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享描述" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分享描述" },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "背景色" },
                    model: {
                      value: _vm.form.bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bg_color", $$v)
                      },
                      expression: "form.bg_color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }