var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.showadd,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "品类", prop: "checkedCates" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckedCitiesChange },
                      model: {
                        value: _vm.form.checkedCates,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "checkedCates", $$v)
                        },
                        expression: "form.checkedCates",
                      },
                    },
                    _vm._l(_vm.cateSelect, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "source" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "来源" },
                      model: {
                        value: _vm.form.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "source", $$v)
                        },
                        expression: "form.source",
                      },
                    },
                    _vm._l(_vm.sourceSelect, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文章标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: { placeholder: "文章标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公众号链接", prop: "url" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: { placeholder: "公众号链接" },
                    model: {
                      value: _vm.form.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "pic" } },
                [
                  _c("upload-img", {
                    ref: "pic",
                    attrs: { "info-text": "", limit: 1, "max-size": 100 },
                    on: {
                      getImgs: function ($event) {
                        return _vm.uploadImg($event, "pic")
                      },
                    },
                  }),
                  _c(
                    "p",
                    { staticStyle: { color: "#f00", "font-size": "12px" } },
                    [_vm._v("尺寸：400*260像素")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { placeholder: "排序", type: "number" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " （默认后上传的排在上边。支持手动排序，数字小的在前，大的在后。最小有效数字为1。） "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "设置状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: "1" } }),
                      _c("el-option", { attrs: { label: "禁用", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.form.add_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "add_time", $$v)
                      },
                      expression: "form.add_time",
                    },
                  }),
                  _c("span", [_vm._v("（默认为当前时间，可修改）")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }