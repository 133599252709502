<template>
  <el-dialog center title="新增" :visible.sync="addshow" width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="选择店铺">
          <el-autocomplete
            v-model="form.name"
            :disabled="edit"
            :fetch-suggestions="handlermell"
            placeholder="请输入店铺名称"
            style="width: 160px"
            value-key="mall_name"
            @select="handleSelectmall"
          />
        </el-form-item>
        <el-form-item label="公司名称">
          {{ company_name }}
        </el-form-item>
        <el-form-item label="选择套餐版本">
          <el-select :disabled="edit" v-model="form.version" clearable style="width: 160px;" placeholder="选择套餐版本" @change="handlerselect">
            <el-option value="1" label="第一版"/>
            <el-option value="2" label="第二版"/>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐内容">
          <el-select
            v-model="form.set_meal_id"
            clearable
            :disabled="edit"
            placeholder="套餐内容"
            style="width: 160px"
          >
            <el-option
              v-for="list in mealselect"
              :key="list.id"
              :label="list.title"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="数量">
          <el-input-number
            v-model="form.number"
            :disabled="edit"
            label="数量"
            :min="1"
          />
        </el-form-item>
        <el-form-item label="业务对接人">
<!--          <el-input :disabled="edit" placeholder="业务对接人" clearable v-model="form.invite_id" style="width: 160px;"/>-->
            <el-select v-model="form.invite_id" clearable filterable style="width: 160px;" placeholder="业务对接人" :disabled="edit">
              <el-option v-for="list in staffSelect"
                         :key="list.htyun_name"
                         :value="list.htyun_name"
                         :label="list.htyun_name"/>
            </el-select>
        </el-form-item>
        <el-form-item label="企业服务人员">
<!--          <el-input placeholder="企业服务人员" clearable v-model="form.service_name" style="width: 160px;"/>-->
          <el-select v-model="form.service_name" clearable filterable style="width: 160px;" placeholder="企业服务人员">
            <el-option v-for="list in staffSelect"
                       :key="list.htyun_name"
                       :value="list.htyun_name"
                       :label="list.htyun_name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remarks"
            clearable
            maxlength="50"
            placeholder="备注"
            :rows="4"
            type="textarea"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addshow = false">取 消</el-button>
      <el-button v-show="!edit" type="primary" @click="handlersave">
        确 定
      </el-button>
      <el-button v-show="edit" type="primary" @click="handlersaveedit">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'


export default {
  name: "addorder",
  data(){
    return{
      edit:false,
      addshow:false,
      form:{
        version:'',
        set_meal_id:'',
        number:'',
        name:'',
        mall_id:'',
        invite_id:'',
        remarks:'',
        service_name:'',
      },
      id:'',
      mealselect:[],
      company_name:'',
      staffSelect:[]
    }
  },
  watch:{
    addshow(val){
      if(!val){
        this.form={
          version:'',
          set_meal_id:'',
          number:'',
          name:'',
          mall_id:'',
          invite_id:'',
          remarks:'',
          service_name:'',
        }
        this.company_name = ''
        this.edit = false
      }
    }
  },
 async mounted() {
    // this.handlerselect()
    const staff =await getAction("/form/form/staff")
    this.staffSelect=staff.data
  },
  methods:{
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.mall_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handleSelectmall(list){
      console.log(list)
      this.form.mall_id=list.id
      this.company_name=list.certification.company_name
    },
    handlersave() {
      postAction("/mall/order/add", this.form).then(res => {
        if (res.request_code == 200) {
          this.addshow = false
          this.$message({
            type: 'success',
            message: res.msg
          })
          this.$emit("getlist")
        }
      })
    },
    handlersaveedit(){
      postAction("/mall/order/edit",{id:this.id,remarks:this.form.remarks,service_name:this.form.service_name}).then(res=>{
        if(res.request_code==200){
          this.addshow=false
          this.$message({
            type:'success',
            message:res.msg
          })
          this.$emit("getlist")
        }
      })
    },
    handlerselect(e){
      if (!e){
        this.mealselect=[]

      }else{
        getAction("/mall/index/meal",{version:e}).then(res=>{
          this.mealselect=res.data
          this.form.set_meal_id=''
        })
      }

    },
  }
}
</script>

<style scoped></style>
