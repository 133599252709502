<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="form.enterprise_name"
          clearable
          placeholder="公司名称"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item label="申请人姓名">
        <el-input
          v-model="form.name"
          clearable
          placeholder="申请人姓名"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="手机号码"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="审核时间">
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="申请时间">
        <el-date-picker
          v-model="timelist"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '营业执照'">
            <span v-for="list in row.business_url">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '照片'">
            <span v-for="list in row.organization_img">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '状态'">
            <span v-if="row.status == 0">待审核</span>
            <span v-else-if="row.status == 1">已审核</span>
            <span v-else-if="row.status == 2">已拒绝</span>
          </div>
          <div v-else-if="item.label == '企业员工身份证明材料'">
            <el-image
              :preview-src-list="[row.employee_materials_url]"
              :src="row.employee_materials_url"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template #default="{ row }">
          <el-popconfirm
            v-show="row.status == 0"
            title="确认审核拒绝？"
            @confirm="deleteRow(row.id, 2)"
          >
            <el-button slot="reference" size="small" type="text">
              拒绝
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.status == 0"
            title="确认审核通过？"
            @confirm="deleteRow(row.id, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              通过
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerbusiness(row)"
          >
            工商信息
          </el-button>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <business ref="business" />
    <add-edit ref="addEdit" @getList="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Business from '../../enterprise/storereview/components/business.vue'
  import AddEdit from "@/views/pages/review/jingxiaoshangIdCard/components/addedit.vue";
  export default {
    components: { AddEdit, Business },
    data() {
      return {
        form: {
          status: '',
          name: '',
          page: 1,
          limit: 10,
          enterprise_type: 3, //企业属性，1生产企业，2代理商，3其他
          start_time: '',
          end_time: '',
          mobile: '',
          enterprise_name: '', //公司名称
        },
        timelist: [],
        time:[],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '公司名称',
            prop: 'enterprise_name',
            width: '',
          },
          {
            label: '营业执照',
            prop: 'business_url',
            width: '110',
          },
          {
            label: '企业员工身份证明材料',
            prop: 'employee_materials_url',
            width: '',
          },
          {
            label: '申请人姓名',
            prop: 'name',
            width: '110',
          },
          {
            label: '申请人职务',
            prop: 'apply_job',
            width: '110',
          },

          {
            label: '电话',
            prop: 'phone',
            width: '',
          },
          {
            label: '照片',
            prop: 'organization_img',
            width: '',
          },
          {
            label: '留言',
            prop: 'remark',
            width: '',
          },
          {
            label: '申请时间',
            prop: 'created',
            width: '',
          },
          {
            label: '审核时间',
            prop: 'modified',
            width: '',
          },
          {
            label: '状态',
            prop: 'status',
            width: '',
          },

          {
            label: '拒绝原因',
            prop: 'handle_remark',
            width: '',
          },
        ],
        shopSelect: [
          {
            id: '',
            name: '全部',
          },
          {
            id: 0,
            name: '待审核',
          },
          {
            id: 1,
            name: '已审核',
          },

          {
            id: 2,
            name: '已拒绝',
          },
        ],
        total: 0,
      }
    },
    // mounted() {
    //   this.handlerInquire()
    //   console.log("mounted生命周期没有走")
    // },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      time(v) {
        if (v) {
          this.form.start_check = v[0]
          this.form.end_check = v[1]
        } else {
          this.form.start_check = ''
          this.form.end_check = ''
        }
      },
    },
    mounted() {
      console.log('mounted生命周期没有走')
      this.handlerInquire()
      console.log('mounted生命周期没有走1')
    },
    methods: {
      handlerAdd(){
        this.$refs.addEdit.form.enterprise_type=3
        this.$refs.addEdit.showinfo = true
      },
      handlerEdit(row){
        row.enterprise_type = 3
        this.$refs.addEdit.handlerinfo(row)
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerinfo(row) {
        this.$refs.info.form = row
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        getAction('/audit/card/lists', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      deleteRow(id, status) {
        if (status == 2) {
          this.$prompt('理由', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            postAction('/audit/card/approved', {
              id: id,
              status: status,
              handle_remark: value,
            }).then((res) => {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            })
          })
        } else {
          postAction('/audit/card/approved', { id: id, status }).then((res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerInquire()
            }
          })
        }
      },
      handlerbusiness(row) {
        console.log(row)
        this.$refs.business.handlerinfo(row.enterprise_name)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
