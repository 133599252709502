<template>
  <el-dialog
    :visible.sync="showvideo"
    width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="分类" prop="type">
          <el-select v-model="form.type" clearable style="width: 220px;" placeholder="分类">
            <el-option
              :value="1"
              :label="'经销商'"/>
            <el-option
              :value="2"
              :label="'企业'"/>
          </el-select>
        </el-form-item>
        <el-form-item label="封面图"  prop="cover">
          <upload-img  ref="cover" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'cover')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="标题"  style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="视频文件" prop="video">
          <upload-video ref="video" :limit="1" @getVideos="getVideos($event, 'video')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="分享标题" prop="share_title">
          <el-input v-model="form.share_title" placeholder="标题"  style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="分享图" prop="share_img">
          <upload-img  ref="share_img" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'share_img')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input-number v-model="form.sort_order" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" clearable style="width: 220px;" placeholder="状态">
            <el-option
              :value="1"
              :label="'启用'"/>
            <el-option
              :value="0"
              :label="'禁用'"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showvideo = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import UploadVideo from "@/components/uploadVideo";
import UploadImg from "@/components/uploadImg";
import { getAction } from "@/api/request";
import { nextTick } from "vue";
export default {
  name: "AddVideo",
  components: { UploadImg, UploadVideo },
  data(){
    return{
      showvideo:false,
      form:{
        id:'',
        type:'',
        title:'',
        cover:'',
        video:'',
        share_title:'',
        share_img:'',
        sort_order:0,
        status:1,
      },
      rules:{
        type:[
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        title:[
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        cover:[
          { required: true, message: '请上传视频文件封面', trigger: 'blur' }
        ],
        video:[
          { required: true, message: '请上传视频文件', trigger: 'blur' }
        ],
        share_title:[
          { required: true, message: '请输入分享标题', trigger: 'blur' }
        ],
        share_img:[
          { required: true, message: '请上传分享图片', trigger: 'blur' }
        ],
        sort_order:[
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
      }
    }
  },
  watch:{
    showvideo(val){
      if(!val){
        this.form={
          id:'',
          type:'',
          title:'',
          cover:'',
          video:'',
          share_title:'',
          share_img:'',
          sort_order:0,
          status:1,
        }
        this.closeVideo("video")
        this.closeimg("cover")
        this.closeimg("share_img")
      }
    }
  },
  methods:{
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          getAction("/api/system/direction/video-edit",this.form).then(res=>{
            if(res.request_code==200){
              this.$message({
                type:'success',
                message:res.msg
              })
              this.$emit("getlist")
              this.showvideo=false
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
   async handlerinfo(row){
     await getAction("/api/system/direction/video-info",{id:row.id}).then(res=>{
        this.form=res.data
        nextTick(()=>{
          this.setVideo(res.data.video,"video")
          this.setImg(res.data.cover,"cover")
          this.setImg(res.data.share_img,"share_img")
        })

      })
    },
    getVideos(v, prop){
      console.log(v)
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setVideo(v, prop) {
      if (v) this.$refs[prop].Video = [v]
    },
    closeVideo(prop){
      this.$refs[prop].Video=[]
    },
// 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
  }
};
</script>

<style scoped>

</style>
