<script>
import { getAction } from "@/api/request";
import * as excel from "@/utils/excel";

export default {
  name: "detailed",
  computed: {
    tablelist() {
      return this.tabledata.map((item) => {
        if(item){
          item.action_name = this.actionlist.filter(list=>list.value==item.action)[0].label
        }
        return item
      })
    }
  },
  watch: {
    dialogFormVisible (val) {
      if (!val) {
       this.form = {
          page: 1,
          limit: 10,
          start_date: "",
          end_date: "",
          user_key: "",
          action: "",
       }
      }
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        page: 1,
        limit: 10,
        start_date: "",
        end_date: "",
        user_key: "",
        action: "",
      },
      actionlist: [
        {
          label: "访问",
          value: "1",
        },
        {
          label: "点击",
          value: "2",
        },
        {
          label: "留言（产品）",
          value: "3",
        },
        {
          label: "留言（店铺）",
          value: "4",
        },
        {
          label: "搜索",
          value: "5",
        },
      ],
      layout: "total, sizes, prev, pager, next, jumper",
      tabledata: [],
      columns: [
        {
          label: "时间",
          prop: "created",
          width: "",
        },
        {
          label: "行为类型",
          prop: "action_name",
          width: "",
        },
        {
          label: "名称",
          prop: "title",
          width: "",
        },
        {
          label: "具体名称",
          prop: "subtitle",
          width: "",
        },
        {
          label: "产品/店铺ID",
          prop: "info_id",
          width: "",
        },
        {
          label: "店铺名称",
          prop: "mall_name",
          width: "",
        },
        {
          label: "店铺是否付费",
          prop: "is_pay",
          width: "",
        },
        {
          label: "产品/店铺一级品类",
          prop: "root_cate",
          width: "",
        },
        {
          label: "产品/店铺二级品类",
          prop: "cate",
          width: "",
        },
      ],
      total: 0,
    }
  },
  methods: {
    handlerSearch() {
      this.handlerlist()
    },
    handlerlist() {
      getAction("/api/statistics/buried-point/users-behavior-lists-log", this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
    getDownload() {
      getAction("/api/statistics/buried-point/users-behavior-lists-log-one-export", this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
  },
};
</script>

<template>
  <el-dialog :visible.sync="dialogFormVisible">
    <div>
      <el-form :model="form" inline>
        <el-form-item>
          <el-select v-model="form.action" placeholder="行为类型">
            <el-option label="访问" value="1"></el-option>
            <el-option label="点击" value="2"></el-option>
            <el-option label="留言（产品）" value="3"></el-option>
            <el-option label="留言（店铺）" value="4"></el-option>
            <el-option label="搜索" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getDownload">导出该用户的访问明细</el-button>
        </el-form-item>
      </el-form>
      <el-table
        border
        :data="tablelist"
        style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
        </el-table-column>

      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<style scoped>

</style>
