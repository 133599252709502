<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-select
          v-model="form.type"
          clearable
          filterable
          placeholder="公众号"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
<!--      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>-->
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
<!--    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />-->
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
export default {
  name: 'Index',
  data() {
    return {
      form: {

        start_time: '',
        end_time: '',
        // page: 1,
        // limit: 10,
      },
      time: [],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '来源',
          prop: 'type_text',
          width: '',
        },
        {
          label: 'APPID',
          prop: 'app_id',
          width: '',
        },
        {
          label: '数量',
          prop: 'num',
          width: '',
        },

      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
    this.handlerSelect()
    console.log('-')
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerSelect(){
      getAction("/api/form/form/wechat-select").then(res=>{
        console.log(res)
        this.staffSelect=res.data
      })

    },
    handlerlist() {
      getAction('/api/form/form/wechat-list', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      postAction('/api/form/form/mall-match-list-export', this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
