var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.addkong, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.addkong = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "120px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          maxlength: "20",
                          placeholder: "最多输入20个字",
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图标", prop: "pic" } },
                    [
                      _c("upload-img", {
                        ref: "uploadImage",
                        attrs: {
                          "info-text": "尺寸：120*120",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: { uploadImg: _vm.uploadImg },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转方式", prop: "jump_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "跳转方式" },
                          on: { change: _vm.handlerjuptype },
                          model: {
                            value: _vm.form.jump_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "jump_type", $$v)
                            },
                            expression: "form.jump_type",
                          },
                        },
                        _vm._l(_vm.toSelect, function (list) {
                          return _c("el-option", {
                            key: list.jump_id,
                            attrs: {
                              label: list.jump_page,
                              value: list.jump_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.jump_type == 12 ||
                  _vm.form.jump_type == 3 ||
                  _vm.form.jump_type == 22
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.jump_name, prop: "jump_object" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: { placeholder: _vm.jump_name },
                            model: {
                              value: _vm.form.jump_object,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object", $$v)
                              },
                              expression: "form.jump_object",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择店铺", prop: "jump_object" } },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.handlermell,
                              placeholder: "请输入店铺名称",
                              "value-key": "mall_name",
                            },
                            on: { select: _vm.handleSelectmall },
                            model: {
                              value: _vm.form.jump_object_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object_name", $$v)
                              },
                              expression: "form.jump_object_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 18
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "品类", prop: "jump_object" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择品类",
                              },
                              model: {
                                value: _vm.form.jump_object,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jump_object", $$v)
                                },
                                expression: "form.jump_object",
                              },
                            },
                            _vm._l(_vm.pllist, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 10
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择特色展馆", prop: "jump_object" },
                        },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.handlerhuose,
                              placeholder: "请输入特色展馆名称",
                              "value-key": "house_name",
                            },
                            on: { select: _vm.handleSelecthuose },
                            model: {
                              value: _vm.form.jump_object_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object_name", $$v)
                              },
                              expression: "form.jump_object_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择商品", prop: "jump_object" } },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.handlergoods,
                              placeholder: "请输入商品名称",
                              "value-key": "goods_name",
                            },
                            on: { select: _vm.handleSelectgoods },
                            model: {
                              value: _vm.form.jump_object_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object_name", $$v)
                              },
                              expression: "form.jump_object_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 20
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择选品日", prop: "jump_object" } },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              "fetch-suggestions": _vm.handlerarea,
                              placeholder: "请输入选品日名称",
                              clearable: "",
                              "value-key": "title",
                            },
                            on: { select: _vm.handleSelectarea },
                            model: {
                              value: _vm.form.jump_object_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object_name", $$v)
                              },
                              expression: "form.jump_object_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 21
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "线上品类节", prop: "jump_object" } },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              "fetch-suggestions": _vm.handleract,
                              placeholder: "请输入线上品类节",
                              clearable: "",
                              "value-key": "name",
                            },
                            on: { select: _vm.handleSelectact },
                            model: {
                              value: _vm.form.jump_object_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jump_object_name", $$v)
                              },
                              expression: "form.jump_object_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 18 || _vm.form.jump_type == 7
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "品类", prop: "jump_object" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择品类",
                              },
                              model: {
                                value: _vm.form.jump_object,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jump_object", $$v)
                                },
                                expression: "form.jump_object",
                              },
                            },
                            _vm._l(_vm.pllist, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.form.jump_type == 5
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "身份认证", prop: "jump_object" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择认证类型",
                              },
                              model: {
                                value: _vm.form.jump_object,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jump_object", $$v)
                                },
                                expression: "form.jump_object",
                              },
                            },
                            _vm._l(_vm.renlist, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "类型" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.typeSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: { placeholder: "排序", type: "number" },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addkong = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }