<template>
  <div class="index-container">
    <addbanner ref="banner" @getlist="handlerlist" />
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.is_open"
          clearable
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in shopSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="标题"
          style="width: 110px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company_name"
          clearable
          placeholder="公司名称"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item label="广告位置" prop="advert_no">
        <el-select
          v-model="form.advert_no"
          clearable
          placeholder="类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in typeSelect"
            :key="list.advert_no"
            :label="list.position_name"
            :value="list.advert_no"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '广告图'">
            <el-image
              :preview-src-list="[row.img]"
              :src="row.img"
              style="width: 70px; height: 30px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <!--                    <div v-else-if="item.label=='位置'">-->
          <!--                      {{typeSelect.filter(list=>list.advert_no==row[item.prop])[0].position_name}}-->
          <!--                    </div>-->
          <!--          <div v-else-if="item.label=='跳转方式'">-->
          <!--            {{toSelect.filter(list=>list.jump_id==row[item.prop])[0].jump_page}}-->
          <!--          </div>-->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-popconfirm
            v-show="row.is_open == 1"
            title="确认禁用？"
            @confirm="deleteRow(row.id, 0)"
          >
            <el-button slot="reference" size="small" type="text">
              禁用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-show="row.is_open != 1"
            title="确认启用？"
            @confirm="deleteRow(row.id, 1)"
          >
            <el-button slot="reference" size="small" type="text">
              启用
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            title="确认删除该广告图？"
            @confirm="handlerdelete(row)"
          >
            <el-button slot="reference" size="small" type="text">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Addbanner from './components/addbanner'
  export default {
    name: 'Index',
    components: { Addbanner },
    data() {
      return {
        form: {
          is_open: null,
          title: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '标题',
            prop: 'advert_name',
            width: '',
          },

          {
            label: '广告图',
            prop: 'img',
            width: '160',
          },
          {
            label: '位置',
            prop: 'position_name',
            width: '',
          },
          {
            label: '跳转方式',
            prop: 'jump_page',
            width: '',
          },
          // {
          //   label: "关联名称",
          //   prop: "ref_name",
          //   width: "",
          // },
          {
            label: "点击量",
            prop: "click_num",
            width: "110",
          },
          {
            label: '曝光量',
            prop: 'exposure_num',
            width: '80',
          },
          {
            label: '排序',
            prop: 'advert_sort',
            width: '',
          },
          {
            label: '状态',
            prop: 'is_open_txt',
            width: '80',
          },
        ],
        shopSelect: [
          {
            id: 1,
            name: '上架',
          },
          {
            id: 0,
            name: '下架',
          },
        ],
        total: 0,
        typeSelect: [],
        toSelect: [],
        advertPosition: [
          {
            id: 1004,
            name: '置顶幻灯片',
          },
          {
            id: 1015,
            name: '热展',
          },
          {
            id: 1001,
            name: '首页弹窗广告',
          },
          {
            id: 1002,
            name: '首页蒙层浮窗广告',
          },
        ],
      }
    },
    async mounted() {
      await this.handlerSelect()
      await this.handlerInquire()
    },
    methods: {
      async handlerSelect() {
        await getAction('/audit/identity/identity').then((res) => {
          this.identitySelect = res.data
        })
        await getAction('/audit/advert/position').then((res) => {
          this.typeSelect = res.data
        })
        let typeSelect = await getAction('/audit/advert/jump-type')
        console.log(typeSelect.data)
        this.toSelect = typeSelect.data
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.banner.addbanner = true
      },
      handlerinfo(row) {
        this.$refs.info.form = row
        this.$refs.info.showinfo = true
      },
      handlerlist() {
        getAction('/audit/advert/lists', this.form).then((res) => {
          // this.tabledata=res.data
          this.tabledata = res.data.map((item) => {
            this.typeSelect.forEach((list) => {
              if (list.advert_no == item.advert_no) {
                item.position_name = list.position_name
              }
            })
            this.toSelect.forEach((list) => {
              if (list.jump_id == item.advert_jump) {
                item.jump_page = list.jump_page
              }
            })
            // item.position_name=this.typeSelect.filter(list=>list.advert_no==item.advert_no)[0]['position_name']
            // item.jump_page=this.toSelect.filter(list=>list.jump_id==item.advert_jump)[0]['jump_page']
            return item
          })
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/audit/advert/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      deleteRow(id, status) {
        postAction('/audit/advert/open', { id: id, open: status }).then(
          (res) => {
            if (res.request_code == 200) {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.handlerlist()
            }
          }
        )
      },
      handleredit(row) {
        console.log(row)
        this.$refs.banner.addbanner = true
        this.$refs.banner.handlerinfo(row)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
