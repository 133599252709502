var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showadd, width: "80%" },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户", prop: "user_id" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlerphone,
                      placeholder: "请输入手机号",
                      "value-key": "phone",
                    },
                    on: { select: _vm.handleSelectphone },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择身份", prop: "dealer_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择身份" },
                      model: {
                        value: _vm.form.dealer_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealer_type", $$v)
                        },
                        expression: "form.dealer_type",
                      },
                    },
                    _vm._l(_vm.shenoptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "company_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "公司名称" },
                    model: {
                      value: _vm.form.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_name", $$v)
                      },
                      expression: "form.company_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "business_url" } },
                [
                  _c("upload-img", {
                    ref: "business_url",
                    attrs: {
                      infoText:
                        "格式jpg、png，大小5MB以内，请上传与您相关的图片",
                      limit: 1,
                      maxSize: 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "business_url")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "企业员工身份证明材料",
                    prop: "employee_materials_url",
                  },
                },
                [
                  _c("upload-img", {
                    ref: "employee_materials_url",
                    attrs: {
                      infoText:
                        "格式jpg、png，大小5MB以内，名片、工作证、门头、企业微信截图、钉钉截图等",
                      limit: 1,
                      maxSize: 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg(
                          $event,
                          "employee_materials_url"
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经营品类", prop: "trade" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      "popper-class": "ssaassd",
                      placeholder: "经营品类",
                      options: _vm.cateSelect,
                      clearable: "",
                      "show-all-levels": false,
                      props: {
                        multiple: true,
                        emitPath: false,
                        value: "id",
                        label: "name",
                      },
                    },
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.trade,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "trade", $$v)
                      },
                      expression: "form.trade",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地区", prop: "province_id" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "province_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "省" },
                          on: { change: _vm.handleProChange },
                          model: {
                            value: _vm.form.province_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "province_id", $$v)
                            },
                            expression: "form.province_id",
                          },
                        },
                        _vm._l(_vm.provinceSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "city_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "市" },
                          on: { change: _vm.handlercidChange },
                          model: {
                            value: _vm.form.city_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "city_id", $$v)
                            },
                            expression: "form.city_id",
                          },
                        },
                        _vm._l(_vm.citylist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "area_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { clearable: "", placeholder: "区" },
                          model: {
                            value: _vm.form.area_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "area_id", $$v)
                            },
                            expression: "form.area_id",
                          },
                        },
                        _vm._l(_vm.areaSelect, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "channel" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckChange },
                      model: {
                        value: _vm.form.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channel", $$v)
                        },
                        expression: "form.channel",
                      },
                    },
                    _vm._l(_vm.citySelect, function (city, idx) {
                      return _c(
                        "el-checkbox",
                        { key: idx, attrs: { label: city.id } },
                        [_vm._v(" " + _vm._s(city.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "代理品牌", prop: "brand" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "代理品牌" },
                    model: {
                      value: _vm.form.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "brand", $$v)
                      },
                      expression: "form.brand",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请人姓名", prop: "apply_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "申请人姓名" },
                    model: {
                      value: _vm.form.apply_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apply_name", $$v)
                      },
                      expression: "form.apply_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请人职务", prop: "apply_job" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "申请人职务" },
                    model: {
                      value: _vm.form.apply_job,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apply_job", $$v)
                      },
                      expression: "form.apply_job",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邀请人", prop: "inviter_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "邀请人" },
                    model: {
                      value: _vm.form.inviter_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "inviter_name", $$v)
                      },
                      expression: "form.inviter_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "审核状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "已审核", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "审核中", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "已驳回", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.status == 3,
                      expression: "form.status == 3",
                    },
                  ],
                  attrs: { label: "拒绝原因", prop: "reason" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "拒绝原因" },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }