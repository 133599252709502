<template>
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-select
          v-model="form.source"
          clearable
          placeholder="来源"
          style="width: 160px"
        >
          <el-option
            v-for="list in sourceselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.nickname"
          clearable
          placeholder="昵称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          clearable
          placeholder="客户姓名"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mobile"
          clearable
          placeholder="电话"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          clearable
          placeholder="公司名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item label="一级品类">
        <el-select
          v-model="form.one_cate"
          clearable
          placeholder="一级分类"
          style="width: 160px"
          @change="handlercat"
        >
          <el-option
            v-for="list in cateselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="二级品类">
        <el-select
          v-model="form.two_cate"
          clearable
          placeholder="二级分类"
          style="width: 160px"
        >
          <el-option
            v-for="list in catselect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.goods_name"
          clearable
          placeholder="产品名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.mall_name"
          clearable
          placeholder="店铺名称"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.mall_type"
          clearable
          placeholder="店铺类型"
          style="width: 160px"
        >
          <el-option
            v-for="list in mallTypeList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.invite_name"
          clearable
          placeholder="邀请人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.blp"
          clearable
          placeholder="业务对接人"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.service_name"
          clearable
          placeholder="企业服务人员"
          style="width: 180px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timelist"
          :default-time="['12:00:00']"
          end-placeholder="结束日期"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.deal_status"
          clearable
          placeholder="处理状态"
          style="width: 160px"
        >
          <el-option
            v-for="list in dealStatusList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      show-summary
      style="width: 100%"
      :summary-method="getSummaries"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '沟通图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else-if="item.label == '会议室截图'">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import dateFormat from '@/utils/Timeformat'

  export default {
    data() {
      return {
        form: {
          name: '',
          source: '',
          page: 1,
          limit: 10,
          depart_id: '',
          start_time: dateFormat(new Date() - 3600 * 1000 * 24 * 29),
          end_time: dateFormat(new Date()),
        },
        sourceselect: [
          {
            id: '1',
            name: '指定店铺',
          },
          {
            id: '2',
            name: '选品方案',
          },
          {
            id: '3',
            name: '产品留言',
          },
          {
            id: '4',
            name: '店铺留言',
          },
          {
            id: '5',
            name: '样品申请',
          },
        ],
        mallTypeList: [
          {
            id: '1',
            name: '付费',
          },
          {
            id: '2',
            name: '免费',
          },
        ],
        timelist: [
          dateFormat(new Date() - 3600 * 1000 * 24 * 29),
          dateFormat(new Date()),
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '来源',
            prop: 'source_text',
            width: '',
          },
          {
            label: '昵称',
            prop: 'nickname',
            width: '',
          },
          {
            label: '客户姓名',
            prop: 'name',
            width: '',
          },
          {
            label: '电话',
            prop: 'mobile',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company',
            width: '',
          },
          {
            label: '一级品类',
            prop: 'one_cate',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'two_cate',
            width: '',
          },
          {
            label: '产品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '店铺类型',
            prop: 'mall_type',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'invite_name',
            width: '',
          },
          {
            label: '业务对接人',
            prop: 'blp',
            width: '',
          },
          {
            label: '企业服务人员',
            prop: 'service_name',
            width: '',
          },
          {
            label: '时间',
            prop: 'time',
            width: '',
          },
          {
            label: '处理状态',
            prop: 'deal_status_text',
            width: '',
          },
        ],
        total: 0,
        sumtotal: '',
        departselect: [],
        cateselect: [],
        catselect: [],
        dealStatusList: [
          {
            id: '1',
            name: '已处理',
          },
          {
            id: '2',
            name: '未处理',
          },
        ],
        shopTypeList: [
          {
            id: 1,
            name: '免费',
          },
          {
            id: 2,
            name: '付费',
          },
        ],
      }
    },
    watch: {
      timelist(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.getDepart()
      this.handlerSelect()
    },
    methods: {
      async handlerSelect() {
        // const {data} = await getAction("/mall/index/mall")
        // this.mallSelsect=data
        await getAction('/user/index/cate').then((res) => {
          this.cateselect = res.data
        })
      },
      handlercat(id) {
        console.log(id)
        if (id) {
          getAction('/user/index/cate', { pid: id }).then((res) => {
            this.catselect = res.data
          })
        } else {
          this.catselect = []
        }
      },
      getSummaries({ columns, data }) {
        const sums = []
        columns.forEach((column, index) => {
          if (column.label === '提报人') {
            sums[index] = '共计'
          }
          if (column.label == '点击匹配查看按钮次数') {
            sums[index] = this.sumtotal.click_match_view_btn_num
          }
          if (column.label == '已匹配已查看') {
            sums[index] = this.sumtotal.match_num
          }

          console.log('合计数', this.sumtotal)
          // if (column.label === '合计') {
          //   sums[index] = this.sumtotal
          // }
        })
        return sums
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      getDepart() {
        postAction('/audit/biz-clue/diy-department').then((res) => {
          if (res.request_code == 200) {
            this.departselect = res.data
          }
        })
      },
      handlerExport() {
        postAction('/audit/biz-clue/export-matchcount', {
          name: this.form.name,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          depart_id: this.form.depart_id,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.list,
            filename: res.data.name,
          })
        })
      },

      async handlerlist() {
        await postAction('/audit/biz-clue/thismall-count', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          }
        )
        await postAction('/audit/biz-clue/matchcount-all', {
          name: this.form.name,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          depart_id: this.form.depart_id,
        }).then((res) => {
          this.sumtotal = res.data
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
