var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "55%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.disabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, inline: true, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.mall_name,
                  callback: function ($$v) {
                    _vm.mall_name = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "任务模式", prop: "plan_type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.plan_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "plan_type", $$v)
                    },
                    expression: "form.plan_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("单次任务"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("分时任务"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "miaoshu" }, [
                _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: { "font-size": "15px", "padding-right": "5px" },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.form.plan_type == 1
                        ? _vm.form.task_type == 1
                          ? "单次任务生效后直接增加到店铺虚拟浏览量"
                          : "单次任务生效后直接增加到产品虚拟浏览量"
                        : _vm.form.task_type == 1
                        ? "分时任务生效后在设置的时长内逐渐缓升到对应的店铺虚拟浏览量"
                        : "分时任务生效后在设置的时长内逐渐缓升到对应的产品虚拟浏览量"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.form.plan_type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "执行模式", prop: "increase_mode" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.model,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "model", $$v)
                        },
                        expression: "form.model",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("立即执行"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("延时执行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.plan_type == 2 && _vm.form.model == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                      "picker-options": _vm.pickerOptions,
                      "default-time": "23:59:59",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.form.delay_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "delay_time", $$v)
                      },
                      expression: "form.delay_time",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.plan_type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "任务时长", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.minute,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "minute", $$v)
                      },
                      expression: "form.minute",
                    },
                  }),
                  _vm._v(" 分 "),
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    model: {
                      value: _vm.form.second,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "second", $$v)
                      },
                      expression: "form.second",
                    },
                  }),
                  _vm._v(" 秒 "),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "增加虚拟量", prop: "increase_num" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                model: {
                  value: _vm.form.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "num", $$v)
                  },
                  expression: "form.num",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "当前虚拟量", prop: "virtual_num" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { disabled: true },
                model: {
                  value: _vm.virtual_num,
                  callback: function ($$v) {
                    _vm.virtual_num = $$v
                  },
                  expression: "virtual_num",
                },
              }),
              _c("div", { staticClass: "miaoshu" }, [
                _vm._v(
                  " 真实累计" +
                    _vm._s(_vm.form.task_type == 1 ? "店铺" : "产品") +
                    "浏览量" +
                    _vm._s(_vm.views_num) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }