<template>
  <el-dialog
    content
    :visible.sync="showEditPoster"
    width="400px">
    <div>
      <el-form ref="form" :model="form" inline label-width="110px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="名称" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="栏目类型" prop="type_id">
          <el-select v-model="form.type_id" placeholder="设置栏目">
            <el-option
              v-for="item in typeSelect"
              :key="item.id"
              :label="item.type_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示权限" prop="role_type">
          <el-select v-model="form.role_type" placeholder="显示权限">
            <el-option
              v-for="item in roleSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择显示人员" prop="role_staff" v-if="form.role_type=='2'">
          <el-cascader
            v-model="form.role_staff"
            placeholder="选择显示人员"
            :options="staffOption"
            :props="{ multiple: true ,value: 'userid',label:'htyun_name',}"
            filterable
            collapse-tags
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="跳转类型" prop="jump_type" >
          <el-select v-model="form.jump_type" placeholder="跳转类型">
            <el-option
              v-for="item in jumpSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="form.jump_type==4?'展会名称':'跳转链接'" prop="jump_url">
          <el-input v-model="form.jump_url" :placeholder="form.jump_type==4?'展会名称':'跳转链接'"></el-input>
        </el-form-item>
        <el-form-item label="显示开始时间" prop="start_time">
          <el-date-picker
            v-model="form.start_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :picker-options="startpickerOptions"
          />
        </el-form-item>
        <el-form-item label="显示结束时间" prop="end_time">
          <el-date-picker
            v-model="form.end_time"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input v-model="form.sort_order" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="'1'">启用</el-radio>
            <el-radio :label="'0'">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showEditPoster = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/request";

export default {
  name: "addedit",
  data(){
    return{
      showEditPoster:false,
      staffOption:[],
      roleSelect:[
        {
          id:'1',
          name:'所有人'
        },{
          id:'2',
          name:'部分人'
        }
      ],
      jumpSelect:[
        {
          id:'1',
          name:'H5链接'
        },{
          id:'2',
          name:'报名H5'
        },
        {
          id:'3',
          name:'小程序链接'
        },
        {
          id:'4',
          name:'开通店铺绿色通道H5'
        },
      ],
      form:{
        name:'',
        role_type:'',
        role_staff:[],
        start_time:'',
        end_time:'',
        jump_url:'',
        sort_order:0,
        status:'1',
        jump_type:'',
        id:''
      },
      typeSelect:[],
      pickerOptions:{
        disabledDate:(time) =>{
          if(this.form.start_time){
            return time.getTime() < new Date(this.form.start_time).getTime()
          }
        },
      },
      startpickerOptions:{
        disabledDate:(time) =>{
          if(this.form.end_time){
            return time.getTime() > new Date(this.form.end_time).getTime()
          }
        },
      },
      rules:{
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        role_type: [
          { required: true, message: '请选择显示权限', trigger: 'change' }
        ],
        type_id: [
          { required: true, message: '请选择栏目类型', trigger: 'change' }
        ],
        role_staff: [
          { required: true, message: '请选择显示人员', trigger: 'change' }
          ],
        jump_url: [
          { required: true, message: '请输入跳转链接', trigger: 'blur' }
        ],
        sort_order: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        jump_type: [
          { required: true, message: '请选择跳转类型', trigger: 'change' }
        ],
        start_time: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        end_time: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ],
      },
    }
  },
  watch:{
    showEditPoster(val){
      if(!val){
        this.form={
          name:'',
            role_type:'',
            role_staff:[],
            start_time:'',
            end_time:'',
            jump_url:'',
            sort_order:0,
            jump_type:'',
            id:'',
            status:'1',
        },
        this.$refs.form.resetFields()
      }
    },
  },
  mounted() {
    getAction("/form/form/staff").then(res=>{
      this.staffOption=res.data
    })
    getAction("/api/system/staff-console-type/lists",{limit:-1 ,status:1}).then(res=>{
      this.typeSelect=res.data.list
    })
  },
  methods:{
    handlersave(){
      this.$refs.form.validate((valid) => {
        let data=this.form
        if(data.role_type=='2'){
          data.role_staff=data.role_staff.join(',')
        }
        if (valid) {
          getAction("/api/system/staff-console/edit",this.form).then(res=>{
            this.$message({
              type:'success',
              message:res.msg
            })
            this.$emit("getlist")
            this.showEditPoster=false
          })
        }
      })

    },
    handlerinfo(row){
      getAction('/api/system/staff-console/info',{id:row.id}).then(res=>{
        this.form=res.data
        this.form.role_staff=res.data.role_staff.split(',')
    })
    },
  }
};
</script>

<style scoped>

</style>
