var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "180px", rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择店铺" } },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "160px" },
                attrs: {
                  "fetch-suggestions": _vm.handlermell,
                  "value-key": "mall_name",
                  placeholder: "请输入店铺名称",
                },
                on: { select: _vm.handleSelectmall },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "公司名称" } }, [
            _vm._v(" " + _vm._s(_vm.company_name) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "变动方式", prop: "operation_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "变动方式" },
                  model: {
                    value: _vm.form.operation_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operation_type", $$v)
                    },
                    expression: "form.operation_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "增加", value: "1" } }),
                  _c("el-option", { attrs: { label: "减少", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "变动类型", prop: "operation_position" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "变动类型" },
                  model: {
                    value: _vm.form.operation_position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operation_position", $$v)
                    },
                    expression: "form.operation_position",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "套餐内", value: "1" } }),
                  _c("el-option", { attrs: { label: "赠送", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "线上匹配查看次数" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "查看次数" },
                model: {
                  value: _vm.form.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "num", $$v)
                  },
                  expression: "form.num",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "云会议次数" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "查看次数" },
                model: {
                  value: _vm.form.referrals_num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "referrals_num", $$v)
                  },
                  expression: "form.referrals_num",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "大商直通车次数" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "查看次数" },
                model: {
                  value: _vm.form.through_train_num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "through_train_num", $$v)
                  },
                  expression: "form.through_train_num",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: "请输入内容",
                  maxlength: "100",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片留存" } },
            [
              _c("upload-img", {
                ref: "pic",
                attrs: { infoText: "", limit: 5, maxSize: 100 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "pic")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showedit = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }