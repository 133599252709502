<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
<!--      <el-form-item>
        <el-select
          v-model="form.meal_type"
          clearable
          filterable
          placeholder="公众号"
          style="width: 160px"
        >
          <el-option
            v-for="list in staffSelect"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-input
          v-model="form.op_name"
          clearable
          placeholder="审核人"
          style="width: 160px"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
<!--      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>-->
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        op_name:'',
        start_date: '',
        end_date: '',
        page: 1,
        limit: 10,
      },
      time: [],
      staffSelect:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '审核人姓名',
          prop: 'op_name',
          width: '',
        },

        {
          label: '产品审核次数总计',
          prop: 'goods_total',
          width: '',
        },

        {
          label: '产品审核通过次数',
          prop: 'goods_ok',
          width: '',
        },
        {
          label: '产品审核拒绝次数',
          prop: 'goods_no',
          width: '',
        },
        {
          label: '店铺审核次数总计',
          prop: 'mall_total',
          width: '',
        },
        {
          label: '店铺审核通过次数',
          prop: 'mall_ok',
          width: '',
        },
        {
          label: '店铺审核拒绝次数',
          prop: 'mall_no',
          width: '',
        }
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  created() {
    let Month,Year,Day
    if(new Date().getMonth()==0){
      Month = 12
      Year=new Date().getFullYear()-1
      Day = new Date(Year, Month, 0).getDate();
    }else{
      Month = new Date().getMonth()
      Year=new Date().getFullYear()
      Day = new Date(Year, Month, 0).getDate();
    }
    this.time=[Year+'-'+Month+'-'+1,Year+'-'+Month+'-'+Day]
    this.form.start_date = Year+'-'+Month+'-'+1
    this.form.end_date = Year+'-'+Month+'-'+Day
  },
  mounted() {

    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/api/statistics/op-log/lists-of-staff', this.form).then(
        (res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        }
      )
    },
    handlerExport() {
      postAction('/api/form/form/mall-match-list-export', this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
