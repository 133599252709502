var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.goodsmall, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.goodsmall = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _vm.form.type == 1
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.handlergoods,
                              "value-key": "goods_name",
                              placeholder: "请输入商品名称",
                            },
                            on: { select: _vm.handleSelectgoods },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.form.type == 2
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.handlermell,
                              "value-key": "mall_name",
                              placeholder: "请输入店铺名称",
                            },
                            on: { select: _vm.handleSelectmall },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerInquire },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleradd },
                        },
                        [_vm._v("新 增")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tabledata },
                },
                [
                  _vm._l(_vm.columns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                item.label == "名称"
                                  ? _c("div", [
                                      _vm.form.type == 1
                                        ? _c("p", [
                                            _vm._v(_vm._s(row.goods_name)),
                                          ])
                                        : _c("p", [
                                            _vm._v(_vm._s(row.mall_name)),
                                          ]),
                                    ])
                                  : item.label == "状态"
                                  ? _c("div", [
                                      row[item.prop] == 1
                                        ? _c("p", [_vm._v("开启")])
                                        : _c("p", [_vm._v("禁用")]),
                                    ])
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      align: "center",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确认删除？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handlerdelete(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "10px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleredit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c("el-pagination", {
                staticStyle: { "text-align": "center", "margin-top": "10px" },
                attrs: {
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
              _c("comedit", {
                ref: "edit",
                attrs: { title: _vm.titleedit },
                on: { getlist: _vm.handlerInquire },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.goodsmall = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.goodsmall = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }