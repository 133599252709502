<template>
  <el-dialog  :title="title"
              :visible.sync="showAddstore"
              width="350px"
              :append-to-body="true"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="选择产品" prop="object_id" v-if="form.type==1">
        <el-autocomplete
          v-model="form.name"
          clearable
          :fetch-suggestions="handlergoods"
          value-key="goods_name"
          placeholder="请输入商品名称"
          @select="handleSelectgoods"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="选择店铺" prop="object_id" v-else-if="form.type==2">
        <el-autocomplete
          v-model="form.name"
          clearable
          :fetch-suggestions="handlermell"
          value-key="mall_name"
          placeholder="请输入店铺名称"
          @select="handleSelectmall"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="政策"  prop="policy">
        <el-input v-model="form.policy"/>
      </el-form-item>
      <el-form-item label="排序" prop="sort_order" >
        <el-input v-model="form.sort_order"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer center">
      <el-button @click="showAddstore=!showAddstore">取消</el-button>
      <el-button type="primary" @click="handlersave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/request";

export default {
  name: "AddStore",
  props:{
    title:{
      type:String,
      default:()=>{
        return "新增"
      }
    },
  },

  data(){
    var handlerobject=(rule, value, callback)=>{
      if(this.form.type==1){
        if(!value){
          callback(new Error("请选择产品"))
        }else{
          callback()
        }
      }else{
        if(!value){
          callback(new Error("请选择店铺"))
        }else{
          callback()
        }
      }
    };
    return{
      showAddstore:false,
      form:{
        area_id	:"",
        column_id	:"",
        object_id:'',
        name:'',
        id	:"",
        type	:"",
        policy	:"",
        sort_order:"",
      },
      malllist:[],
      product:[],
      rules:{
        object_id:[
          { required: true,validator:handlerobject,  trigger: 'change' }
        ]
      }
    }
  },
  watch:{
    showAddstore(old){
      if(!old){
        this.$refs.form.resetFields()
        this.form={
          area_id	:"",
          column_id	:"",
          object_id:'',
          name:'',
          id	:"",
          type	:"",
          policy	:"",
          sort_order:"",
        }
      }
    }
  },
  mounted() {
    // this.handlermalllist()
    this.handlerProduct()
  },
  methods:{
    handlermell(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.object_id=''
      }else{
        getAction("/mall/index/mall",{mall_name:queryString}).then(res=>{
          cb(res.data);
        })
      }
    },
    handlergoods(queryString,cb){
      if(queryString==''){
        cb([])
        this.form.object_id=''
      }else{
        getAction("/mall/product/goods",{goods_name:queryString}).then(res=>{
          cb(res.data);
        })
      }

    },
    handleSelectmall(list){
      console.log(list)
      this.form.object_id=list.id
    },
    handleSelectgoods(list){
      console.log(list)
      this.form.object_id=list.id
    },
    // handlerobject(rule, value, callback){
    //   // if(this.form.type==1){
    //   //   if(!value){
    //   //     callback(new Error("请选择产品"))
    //   //   }else{
    //   //     callback()
    //   //   }
    //   // }else{
    //   //   if(!value){
    //   //     callback(new Error("请选择店铺"))
    //   //   }else{
    //   //     callback()
    //   //   }
    //   // }
    // },
    handlersave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          postAction("/live/index/edit-areamallgoods", this.form).then(res => {
            this.$message.success(res.msg)
            this.showAddstore = false
            this.$emit("getlist")
          })
        }
      })
    },
    handlerinit(row){
        this.form = Object.assign(this.form,row)
      this.form.policy=row.policy_text
    }
  }
}
</script>

<style scoped>

</style>
