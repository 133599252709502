<template>
  <!-- NAME[epic=组件] 上传多图 -->
  <div class="orderTest-container">
    <div class="imgList" style="display: inline-block" v-loading="loading">
      <div
        style="position: relative"
        class="imgList"
        v-for="(item, index) in img"
        :key="index"
        v-dragging="{ item: item, list: img, group: 'item', key: index }"
      >
        <div>
          <el-image
            style="width: 148px; height: 148px; margin-right: 10px"
            :src="item"
            fit="cover"
            :preview-src-list="img"
          ></el-image>
          <i
            class="el-icon-error close"
            v-show="!disab"
            v-if="!loading"
            @click="handleDeleteImg(index)"
          ></i>
        </div>
      </div>

      <el-upload
        v-show="img.length < limit"
        class="upload-demo"
        list-type="picture-card"
        multiple
        :show-file-list="false"
        :http-request="uploadImg"
        :on-exceed="limitLong"
        name="file"
        accept=".png, .jpg, .jpeg"
        action="#"
        :disabled="disab"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <cropper
        ref="corpper"
        :autoCropWidth="autoCropWidth"
        :autoCropHeight="autoCropHeight"
        :enlarge="enlarge"
        :mode="mode"
        @close="closecorpper"
        @uploadImg="uploadImgcorpper"
      />
    </div>
    <div
      style="line-height: 1.2; margin-top: 10px; color: red; font-size: 12px"
      v-if="showInfo"
    >
      {{ infoText }}
    </div>
  </div>
</template>
<script>
  import { postAction, uploadAction } from '@/api/request'
  import { base64ToFile } from '@/utils/fileto'
  import cropper from './corpper.vue'
  export default {
    name: 'uploadImg',
    components: { cropper },
    props: {
      booleancorpper: {
        type: Boolean,
        default: false,
      },
      autoCropWidth: {
        type: Number,
        default: 200,
      },
      autoCropHeight: {
        type: Number,
        default: 100,
      },
      mode: {
        type: String,
        default: '800px 800px',
      },
      enlarge: {
        type: Number,
        default: 4,
      },
      imgstyle: {
        type: String,
        default: 'width: 148px; height: 148px; margin-right: 10px',
      },
      limit: {
        type: Number,
        default: 5,
      },
      maxSize: {
        type: Number,
        default: 1,
      },
      showInfo: {
        type: Boolean,
        default: true,
      },
      disab: {
        type: Boolean,
        default: false,
      },
      infoText: {
        type: String,
        default:
          '（拖拽图片可进行排序，图片尺寸：800像素*800像素，图片格式： jpg、png，图片大小：1MB以内,普通展厅、一星展厅、最多上传3张图片。二星展厅及以上最多上传5张图片）',
      },
    },
    data() {
      return {
        dialogVisible: false,
        img: [],
        loading: false,
      }
    },
    created() {},
    mounted() {},
    computed: {},
    watch: {
      img(v) {
        if (v.length > this.limit) {
          this.$message.error(`最多上传${this.limit}张图片`)
          this.img = this.img.splice(0, this.limit)
        }
        this.$emit('getImgs', v)
      },
    },
    methods: {
      closecorpper() {
        this.loading = false
      },
      uploadImgcorpper(data) {
        const formData = new FormData()
        formData.append('file', base64ToFile(data))
        uploadAction('/api/index/upload', formData).then((e) => {
          console.log(e)
          if (e.request_code == 200) {
            console.log(this.img)
            this.img.push(e.data.url)
            this.$emit('uploadImg', this.img)
            this.loading = false
          }
        })
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          let fileResult = ''
          reader.readAsDataURL(file)
          //开始转
          reader.onload = function () {
            fileResult = reader.result
          }
          //转 失败
          reader.onerror = function (error) {
            reject(error)
          }
          //转 结束  咱就 resolve 出去
          reader.onloadend = function () {
            resolve(fileResult)
          }
        })
      },
      async uploadImg(file) {
        console.log(file.file)
        const formData = new FormData()
        formData.append('file', file.file)
        this.loading = true

        console.log(file)
        if (this.booleancorpper) {
          file = file.file
          this.$refs.corpper.option.img = await this.getBase64(file)
          this.$refs.corpper.showcorpper = true
        } else {
          uploadAction('/api/index/upload', formData).then((e) => {
            console.log(e)
            if (e.request_code == 200) {
              this.img.push(e.data.url)
              this.$emit('uploadImg', this.img)
              this.loading = false
            }
          })
        }
      },
      // 文件超出
      limitLong() {
        this.$message.error(`最多上传${this.limit}张图片`)
        return false
      },
      handleDeleteImg(index) {
        this.img.splice(index, 1)
        console.log('this.img---', this.img)
        this.$emit('uploadImg', this.img)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      onEnd(e) {
        console.log(e)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .imgList {
    display: flex !important;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .close {
    position: absolute;
    top: -9px;
    right: -4px;
    font-size: 22px;
    cursor: pointer;
  }
</style>
