<template>
  <div class="index-container">
    <h3>产品热搜榜</h3>
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-button type="primary" @click="handlerStatis">统计</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerDelete">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">刷新</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </div>
          <div v-else-if="item.label == '活动类型'">
            {{ row.type == 1 ? '话题' : '投票' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="editposter" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import Addedit from './components/addedit'

  export default {
    name: 'index',
    components: { Addedit },
    data() {
      return {
        form: {
          type: '',
          status: '', //  1-启用 2-禁用
          title: '',
          page: 1,
          limit: 10,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '排名',
            prop: 'id',
            width: '',
          },
          {
            label: '产品标题',
            prop: 'code',
            width: '',
          },
          {
            label: '品类',
            prop: 'name',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'code',
            width: '',
          },
          {
            label: '浏览次数',
            prop: 'name',
            width: '',
          },
          {
            label: '搜索次数',
            prop: 'name',
            width: '',
          },
        ],
        total: 0,
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      // 统计
      handlerStatis() {},
      // 删除
      handlerDelete() {},
      // 刷新
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.editposter.showEditPoster = true
      },
      handlerlist() {
        getAction('/api/system/conf/index', this.form).then((res) => {
          this.tabledata = res.data
          console.log(res.data, this.tabledata)
          this.total = Number(res.totalCount)
        })
      },
      handlerdelete(row) {
        postAction('/api/system/vote/delete', { id: row.id }).then((res) => {
          if (res.request_code == 200) {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          }
        })
      },
      // 编辑分类
      handleredit(row) {
        this.$refs.editposter.showEditPoster = true
        this.$refs.editposter.handlerinfo(row)
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
