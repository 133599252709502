<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.inviter_name" clearable placeholder="业务人员(邀请人)" />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
<!--      <el-form-item>
        <el-button type="primary" @click="handlerExport">导 出</el-button>
      </el-form-item>-->
    </el-form>
    <div style="max-width: 1600px;">
      <div id="Echarts" style="width: 100%;height: 400px"></div>
    </div>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '店铺名称'">
            {{row.mall.mall_name}}
          </div>
          <div v-else-if="item.label == '业务人员（邀请人）'">{{ row.mall.inviter_name }}</div>
          <div v-else-if="item.label == '管理员姓名'">{{ row.mallManager.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/request";
import * as excel from "@/utils/excel";
import dateFormat from "@/utils/Timeformat";
import echarts from "echarts";
export default {
  name: 'Index',
  data() {
    return {
      form: {
        mall_name:'',
        inviter_name:'',
        start_date:dateFormat(new Date()-3600*1000*24*30),
        end_date:dateFormat(new Date()),
        page: 1,
        limit: 10,
      },
      time:[dateFormat(new Date()-3600*1000*24*30),dateFormat(new Date())],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      datelist:[],
      datalist:[],
      columns: [
        {
          label: '店铺状态',
          prop: 'status',
          width: '',
        },

        {
          label: '店铺名称',
          prop: 'type_text',
          width: '',
        },

        {
          label: '业务人员（邀请人）',
          prop: 'pv',
          width: '',
        },
        {
          label: '管理员姓名',
          prop: 'uv',
          width: '',
        },
        {
          label: '查看时间',
          prop: 'created',
          width: '',
        },
        {
          label: '平台',
          prop: 'source',
          width: '',
        }
      ],
      total: 0,
    }
  },
  watch: {
    time(v) {
      if (v) {
        this.form.start_date = v[0]
        this.form.end_date = v[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
  },
  mounted() {
    this.handlerInquire()
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.datelist=[]
      this.datalist=[]
      this.handlerlist()
    },
    handlerlist() {
      this.datelist=[]
      this.datalist=[]
      const myChart = echarts.init(
        document.getElementById("Echarts")
      )
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#fff'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        dataZoom:[
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
          },
        ],
        series: [
          {
            data: [],
            type: 'line',
            itemStyle: {
              normal: {
                color: '#5087ec', //改变折线点的颜色
                lineStyle: {
                  color: '#5087ec' //改变折线颜色
                }
              }
            },
          }
        ]
      };
      getAction('/mall/manager/login-log', this.form).then(
        (res) => {
          this.tabledata = res.data.list
          this.total = Number(res.totalCount)

          res.data.fold_line.map(list=>{
            console.log()
            this.datelist.push(Object.values(list)[0])
            this.datalist.push(Object.values(list)[1])

          })
          console.log(this.datelist,this.datalist)
          option.xAxis.data=this.datelist
          option.series[0].data=this.datalist
          myChart.setOption(option);
        }
      )
    },
    handlerExport() {
      postAction('/api/form/form/mall-match-list-export', this.form).then(
        (res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        }
      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
