var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预览",
        visible: _vm.showDialog,
        width: "540px",
        top: "19vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "orderTest-container" }, [
        _c("div", { staticClass: "wrap" }, [
          _c("div", { staticClass: "header" }, [
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("el-image", {
                  staticStyle: { width: "45px", height: "45px" },
                  attrs: {
                    src: "https://res.httoutiao.com/%E4%BA%91%E4%BA%A4%E4%BC%9Alogo.png",
                  },
                }),
                _c("div", { staticClass: "title" }, [_vm._v("华糖云交会")]),
              ],
              1
            ),
            _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.nowTime))]),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("span", { staticClass: "tit" }, [
              _vm._v("【" + _vm._s(_vm.form.title) + "】"),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.form.content))]),
            _c(
              "span",
              {
                staticStyle: {
                  "margin-left": "5px",
                  "text-decoration": "underline",
                  color: "#409eff",
                },
              },
              [_vm._v(_vm._s(_vm.form.button_title))]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px", "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    _vm.showDialog = false
                  },
                },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }