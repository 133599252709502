<template>
  <el-dialog :title="title" :visible.sync="show" width="1200px">
    <div>
<!--      <el-form ref="form" inline label-suffix=":" :model="form">
        <el-form-item>
          <el-select
            v-model="form.type"
            clearable
            placeholder="状态"
            style="width: 100px"
          >
            <el-option
              v-for="list in statusSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.staff_name"
            :fetch-suggestions="(queryString,cb)=>{handlerstaff(queryString,cb)}"
            value-key="staff_name"
            placeholder="请输入提报人"
            @select="(list)=>{handleSelectstaff(list)}"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item prop="dealer_id">
          <el-autocomplete
            style="width: 160px;"
            v-model="form.dealer_name"
            :fetch-suggestions="(queryString,cb)=>{handlerdealer(queryString,cb)}"
            value-key="dealer_name"
            placeholder="请输入经销商"
            @select="(list)=>{handleSelectdealer(list)}"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-input clearable v-model="form.remark" placeholder="备注"  style="width: 160px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
      </el-form>-->

      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :sortable="item.sort"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '图片'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
                <span v-for="(list, index) in row[item.prop]" :key="index">
                  <el-image
                    :preview-src-list="[list]"
                    :src="list"
                    style="width: 100px; height: 100px"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '大商凭证'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
                <span v-for="(list, index) in row[item.prop]" :key="index">
                  <el-image
                    :preview-src-list="[list]"
                    :src="list"
                    style="width: 100px; height: 100px"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '会议截图'">
              <el-row :gutter="10" style="display: flex; flex-wrap: wrap">
            <span v-for="(list, index) in row[item.prop]" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span>
              </el-row>
            </div>
            <div v-else-if="item.label == '类型'">
              {{ title }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getAction } from '@/api/request'

export default {
  name: 'xianxia',
  data() {
    return {
      show: false,
      title:'',
      form: {
        page: 1,
        limit: 10,
        mall_id: '',
        type: '',
        remark: '',
        staff_id: '',
        staff_name: '',
        dealer_id: '',
        dealer_name: '',
      },
      statusSelect: [
        {
          id: 3,
          name: '云会议',
        },
        {
          id: 4,
          name: '大商直通车',
        },
      ],
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      columns: [
        {
          label: '类型',
          prop: 'type',
          width: '',
          sort: false,
        },
        {
          label: '次数',
          prop: 'count',
          width: '',
          sort: false,
        },
        {
          label: '添加时间',
          prop: 'created',
          width: '',
          sort: false,
        },
        {
          label: '备注',
          prop: 'remark',
          width: '',
          sort: false,
        },
        {
          label: '图片',
          prop: 'imgs',
          width: '',
          sort: false,
        },
      ],
      total: 0,
    }
  },
  watch:{
    show(val){
      if(!val){
        this.title=''
        this.form={
          page: 1,
          limit: 10,
          mall_id: '',
          type: '',
          remark: '',
          staff_id: '',
          staff_name: '',
          dealer_id: '',
          dealer_name: '',
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    handlerinfo(row,type){
      this.form.mall_id=row.mall_id
      this.form.deduct_id=row.id
      this.form.type=type
      this.handlerInquire()
      if(type==7){
        this.title='区域游学考察'
      }else if(type==8){
        this.title='视频号直播'
      }else if (type==9){
        this.title='公众号专稿推介'
      }else if (type==10){
        this.title='热品推荐视频'
      }else if (type==11){
        this.title='抖音直播'
      }
      this.show=true
    },

    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerlist() {
      getAction('/mall/setmeal/deduct-setmeal-reason', this.form).then((res) => {
        this.tabledata = res.data.map(list=>{
          if(list.imgs.length==0){
            list.imgs = []
          }else{
            list.imgs = list.imgs.split(',')
          }
          return list
        })
        this.total = Number(res.totalCount)

      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
