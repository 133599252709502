var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { content: "", visible: _vm.showEditPoster, width: "400px" },
      on: {
        "update:visible": function ($event) {
          _vm.showEditPoster = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-width": "110px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名称", maxlength: "20" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目类型", prop: "type_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "设置栏目" },
                      model: {
                        value: _vm.form.type_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type_id", $$v)
                        },
                        expression: "form.type_id",
                      },
                    },
                    _vm._l(_vm.typeSelect, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.type_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示权限", prop: "role_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "显示权限" },
                      model: {
                        value: _vm.form.role_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role_type", $$v)
                        },
                        expression: "form.role_type",
                      },
                    },
                    _vm._l(_vm.roleSelect, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.role_type == "2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择显示人员", prop: "role_staff" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          placeholder: "选择显示人员",
                          options: _vm.staffOption,
                          props: {
                            multiple: true,
                            value: "userid",
                            label: "htyun_name",
                          },
                          filterable: "",
                          "collapse-tags": "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.role_staff,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "role_staff", $$v)
                          },
                          expression: "form.role_staff",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型", prop: "jump_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "跳转类型" },
                      model: {
                        value: _vm.form.jump_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jump_type", $$v)
                        },
                        expression: "form.jump_type",
                      },
                    },
                    _vm._l(_vm.jumpSelect, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.form.jump_type == 4 ? "展会名称" : "跳转链接",
                    prop: "jump_url",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.form.jump_type == 4 ? "展会名称" : "跳转链接",
                    },
                    model: {
                      value: _vm.form.jump_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_url", $$v)
                      },
                      expression: "form.jump_url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示开始时间", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.startpickerOptions,
                    },
                    model: {
                      value: _vm.form.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "start_time", $$v)
                      },
                      expression: "form.start_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示结束时间", prop: "end_time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      "picker-options": _vm.pickerOptions,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.form.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "end_time", $$v)
                      },
                      expression: "form.end_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort_order" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "排序" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("启用"),
                      ]),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("禁用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditPoster = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }