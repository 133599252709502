<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="600px">
    <div>
      <el-form ref="form" :model="form" label-width="120px" label-suffix="：" :rules="rules">
          <el-form-item label="日期" prop="date_id">
            <el-select placeholder="日程日期" v-model="form.date_id" clearable style="width: 220px;">
              <el-option v-for="list in dataList" :key="list.id" :value="list.id" :label="list.title"/>
            </el-select>
          </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="标题" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="举办时间" prop="time">
          <el-input
            v-model="form.time"
            placeholder="请输入日期时间" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="举办地点" prop="address">
          <el-input v-model="form.address" placeholder="举办地点" clearable style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="详情页图片" prop="img_detail">
          <upload-img ref="img_detail" :infoText="''" :limit="1" @getImgs="getGoods_imgImg($event, 'img_detail')" :maxSize="100"/>
        </el-form-item>
        <el-form-item label="会议亮点" prop="meet_light">
          <el-input type="textarea" :rows="4" v-model="form.meet_light" placeholder="会议亮点" clearable style="width: 420px;"/>
        </el-form-item>
        <el-form-item label="会议流程" prop="meet_process">
          <wang-editor ref="meet_process"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="form.sort" :min="0" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select placeholder="状态" v-model="form.status" clearable style="width: 220px;">
            <el-option value="1" label="启用"></el-option>
            <el-option value="0" label="禁用"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showadd = false">取 消</el-button>
    <el-button type="primary" @click="handlersave">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import WangEditor from "@/components/WangEditor";
import UploadImg from "@/components/uploadImg";
import { nextTick } from "vue";
import { postAction } from "@/api/request";
export default {
  name: "addedit",
  components: { UploadImg, WangEditor },
  props:{
    dataList:{
      type:new Array(),
      default:()=>{
        return []
      }
    }
  },
  data(){
    return{
      title:'',
      showadd:false,
      form:{
        date_id:'',
        title:'',
        time:'',
        address:'',
        sort:0,
        status:'',
        img_detail:'',
        meet_light:'',
        meet_process:'',
        id:''
      },
      rules:{
        date_id:[{ required: true, message: '请选择日程日期', trigger: 'blur' }],
        title:[{ required: true, message: '请输入标题', trigger: 'blur' }],
        time:[{ required: true, message: '请选择举办时间', trigger: 'blur' }],
        address:[{ required: true, message: '请输入举办地点', trigger: 'blur' }],
        sort:[{ required: true, message: '请输入排序序号', trigger: 'blur' }],
        status:[{ required: true, message: '请选择状态', trigger: 'blur' }],
        img_detail:[{ required: true, message: '请上传详情页图片', trigger: 'blur' }],
      }
    }
  },
  watch:{
    showadd(val){
      if(!val){
        this.close()
      }
    }
  },
  methods:{
    handlerinfo(row){
      this.form=Object.assign(this.form,row)
      nextTick(()=> {
        this.setImg(row.img_detail, 'img_detail')
        this.$refs.meet_process.setHtml(row.meet_process)
      })
      this.show()
    },
    handlersave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.meet_process=this.$refs.meet_process.getHtmlM()
          postAction('/api/afbc/schedule/edit',this.form).then(res=>{
            this.$emit("getlist")
            this.$message({
              type:'success',
              message:res.msg
            })
            this.close()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取商品展示图写入form
    getGoods_imgImg(v, prop) {
      if (v[0]) {
        this.form[prop] = v[0]
      } else {
        this.form[prop] = ""
      }
    },
    setImg(v, prop) {
      if (v) this.$refs[prop].img = [v]
    },
    closeimg(prop){
      this.$refs[prop].img=[]
    },
    show(){
      this.showadd=true
    },
    close(){
      this.showadd=false
      this.form={
        date_id:'',
        title:'',
        time:'',
        address:'',
        sort:'',
        status:'',
        img_detail:'',
        meet_light:'',
        meet_process:'',
        id:''
      }
      nextTick(()=> {
        this.closeimg( 'img_detail')
        this.$refs.meet_process.clearHtml()
      })

    }
  }
};
</script>

<style scoped>

</style>
